import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { AuthContext } from '../contexts/AuthContext';
import { Header } from './Header';

export const Main = (): JSX.Element => {
  const authCtx = useContext(AuthContext);
  const location = useLocation();

  const pathName = location.pathname;

  useEffect(() => {
    const init = async() => {
      const idToken =  await authCtx.getIdToken();
      // request for user permissions and update auth ctx;
      
      const userperm = await authCtx.getUserDetails();
      if(userperm === null) {
        authCtx.login();
      }
    } 
    init();
  }, []);
  
  // const showNav = pathName.search(/order\/create/) === -1 ? true : false;   
  let showNav = true;
  if (pathName.search(/welcome/) !== -1 ){
    showNav= false;
  } else if (pathName.search(/order\/create/) !== -1 ){
    showNav= false;
  }else if (pathName.search(/order\/newcarprint/) !== -1 ){
    showNav= false;
  } else if (pathName.search(/order\/recalculation/) !== -1 ){
    showNav= false;
  } else if (pathName.search(/commission\/manualcommission/) !== -1 ){
    showNav= false;
  }
  else if (pathName.search(/order\/subsequent/) !== -1 ){
    showNav= false;
  }
  return (
    <>
      { pathName !== "/" && (
        <>
          { showNav && <Header /> }
        </>
      ) 
      }
    </>   
  );
};