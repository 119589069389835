import './i18n';

import React from 'react';
import { createRoot } from 'react-dom/client';

import { PorscheDesignSystemProvider } from '@porsche-design-system/components-react';

import { App } from './App';

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

root.render(
  <React.StrictMode>
    <PorscheDesignSystemProvider>
      <App />
    </PorscheDesignSystemProvider>
  </React.StrictMode>
);
