import { useTranslation } from 'react-i18next';

import { PorscheFont } from '../../assets/styles/CustomStyles';
import { getStatusColor, getStatusString } from '../../mock/helper';

interface Props {
  status: string;
}

export const CustomStatus = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const { status } = props;

  return (
    <div className={`order-status ${getStatusColor(status)}`}>
      <PorscheFont>{status === "InReview" ? t("In Review") : t(getStatusString(status))}</PorscheFont>
    </div>
  );
};
