import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import {
  PIcon
} from '@porsche-design-system/components-react';

import { PorscheFont } from '../../../assets/styles/CustomStyles';
import { CalculationDraftDTO } from '../../../interfaces/Calculation';
import { OrderDTO, SaveAsDraftDTO } from '../../../interfaces/Order';
import { UpsaleItemsTotal } from '../../../interfaces/Upsale';
import { UpsaleDetailsDTO } from '../../../interfaces/UpsaleNew';
import { GetRvm } from '../../../interfaces/Variables';
import {
  formatCurrencyWithNoUnit, getUpsaleItemsTotal, showDashToValue
} from '../../../mock/helper';
import { type } from '../../../services/Constants';
import { dcsgetOrderDetails, dcsgetOrderSummary, dcsgetRvm } from '../../../services/data/Helpers';
import getSessionStorage from '../../../helpers/getSessionStorage';
import { Constant } from '../../../configs/Constants';
import draftValueHandler from '../../../helpers/sessionDraftValueHandler';

export const UpsalePrint = React.forwardRef((props, ref: any) => {
  const { t } = useTranslation();
  const orderValues = getSessionStorage(Constant.SingleOrdDetail);
  const [searchParams, setSearchParams] = useSearchParams();
  const [orderData, setOrderData] = useState<CalculationDraftDTO>({} as CalculationDraftDTO)
  const [orderResult, setOrderResult] = useState<SaveAsDraftDTO>({} as SaveAsDraftDTO)
  const [order, setOrder] = useState<OrderDTO>({} as OrderDTO)
  const [totalCwt, setTotalCwt] = useState<UpsaleItemsTotal>({} as UpsaleItemsTotal)
  const [totalPds, setTotalPds] = useState<UpsaleItemsTotal>({} as UpsaleItemsTotal)
  const [upsale, setUpsale] = useState<UpsaleDetailsDTO>({} as UpsaleDetailsDTO)
  const [rvm, setRvm] = useState<GetRvm>({} as GetRvm)


  const mode = orderValues?.id ? orderValues?.id+'' :'' ;
  const draft = draftValueHandler(orderValues?.draft) ;

  useEffect(() => {
    const init = async () => {
      const apiResponse =
      draft === "true"
        ? await dcsgetOrderDetails(true, parseInt(mode))
        : await dcsgetOrderSummary(parseInt(mode));
      if(apiResponse.status===type.SUCCESS){
        const data= apiResponse.response
        setOrderData(data?.upsale);
        setOrder(data?.order);
        const usDetails = data?.upsale as UpsaleDetailsDTO;
        setUpsale(usDetails);
        const rvmResponse = await dcsgetRvm();
        if(rvmResponse.status===type.SUCCESS){
          const rvmData = rvmResponse.response as Array<GetRvm>;
          const rvm = rvmData.filter(x => x.seriesDTO.id === data?.order?.vehicleDetails?.series?.id);
          if (rvm.length > 0 && rvm[0].grossProfit === true) {
            setRvm(rvm[0]);
          }
        }
        
        if (usDetails?.upsaleItems) {
          setTotalCwt(getUpsaleItemsTotal(usDetails.upsaleItems.filter(x => x.partType === "tire")));
          setTotalPds(getUpsaleItemsTotal(usDetails.upsaleItems.filter(x => x.partType === "part")));
        }
      }
      
    }
    init();
  }, [])

  const formateDate = (strDate: string): string => {
    if (!strDate) return "";
    const date = new Date(strDate).toISOString().split('T')[0];
    const formatDate = date.toString().split("-")
    return `${formatDate[2]}.${formatDate[1]}.${formatDate[0]}`;
  };
  return (
    <>
      <div ref={ref}>
        <div className="print-header-container">
          <div className="header-left-side">
            <PorscheFont className="print-heading-name">
              2. {t("Upsale")}
            </PorscheFont>
            <div className="seller-info-label">
              <table>
                <tr>
                  <td className="table-label-td font-14">Seller</td>
                  <td className="font-14">{showDashToValue(order?.orderCreator?.seller)}</td>
                </tr>
                <tr>
                  <td className="table-label-td font-14">Comm No.</td>
                  <td className="font-14">{showDashToValue(order?.vehicleDetails?.commissionNumber)}</td>
                </tr>
                <tr>
                  <td className="table-label-td font-14">Act No.</td>
                  <td className="font-14">{showDashToValue(order?.vehicleDetails?.actNumber)}</td>
                </tr>
              </table>
            </div>
          </div>
          <div className="header-right-side">
            <Stack direction="column" className="right-header-container">
              <PorscheFont className="order-number">
                {t("Order No. 20120230001")}
              </PorscheFont>
              <PorscheFont className="order-date">
                {t("Creation date")}: {formateDate(order?.createdOn)}
              </PorscheFont>
              <PorscheFont className="order-date">
                {t("Last edited")}: {formateDate(order?.updatedOn)}
              </PorscheFont>
            </Stack>
          </div>
        </div>

        {/* Porsche Approved Warranty */}
        <div className="info-block">
          <div className="title">
            <PorscheFont> {t("Porsche Approved Warranty")}</PorscheFont>
          </div>
          <div>
            <FormControlLabel className='include-assistance-checkbox' control={
              <Checkbox sx={{ '&.Mui-checked': { color: "black" }, }} checked={upsale?.includeAssistance ? true : false} />} label={`${t("Incl. Assistance")}`}	  />
          </div>
          <div className="dflex">
            <PorscheFont className='duration'>{t("Duration in months")}:</PorscheFont>
            <PorscheFont className='duration-count'>
              {upsale?.durationInMonths || 0}
            </PorscheFont>
          </div>
        </div>

        <div>
          <div className="title-wrapper">
            <PorscheFont>
              <span className="title">{t("Complete Winter Tires")}</span>
            </PorscheFont>
          </div>
          <div>
            <table className='table'>
              <thead>
                <tr className='table-row-heading'>
                  <th className='table-heading text-left'>{t("Complete Winter Tires")}</th>
                  <th className='table-heading text-right'>{t("Price internal")}</th>
                  <th className='table-heading text-right'>{t("Selling price (net)")}</th>
                  <th className='table-heading text-right'>{t("Customer share")}</th>
                  <th className='table-heading text-right'>{t("Discount")}</th>
                  <th className='table-heading text-right'>{t("Comission")}</th>
                  <th className='table-heading text-right'></th>
                </tr>
              </thead>
              <tbody>
                {/* Complete Winter Tires  */}
                {/* CWT 1st Row start */}
                {upsale?.upsaleItems?.map(ele => {
                  if (ele.partType === 'tire') {
                    return (
                      <React.Fragment>
                        <tr className='table-body-row'>
                          <td className='table-col-bold td-margin-top text-left'>
                            <PorscheFont className=''>{ele.part?.part}</PorscheFont>
                          </td>
                          <td className='table-col-lable td-margin-top text-right'>
                            <PorscheFont>{`${formatCurrencyWithNoUnit(ele.part?.priceInternal ? ele.part?.priceInternal : 0)} €`}</PorscheFont>
                          </td>
                          <td className='table-col-lable td-margin-top text-right'>
                            <PorscheFont>{`${formatCurrencyWithNoUnit(ele.part?.priceBeforeCustomerNet ? ele.part?.priceBeforeCustomerNet : 0)} €`}</PorscheFont>
                          </td>
                          <td className='table-col-lable td-margin-top text-right'>
                            <PorscheFont>{`${formatCurrencyWithNoUnit(ele.customerShare)} €`}</PorscheFont>
                          </td>
                          <td className='table-col-lable td-margin-top text-right'>
                            <PorscheFont>
                                {`${formatCurrencyWithNoUnit(ele.discount ? ele.discount : 0)} €`}
                          </PorscheFont>
                          </td>
                          <td className='table-col-lable td-margin-top text-right'>
                            <PorscheFont>{`${formatCurrencyWithNoUnit(ele.commission)} €`}</PorscheFont>
                          </td>
                          <td className='table-col-lable td-margin-top text-right'></td>
                        </tr>
                        <tr>
                          <td className=''>
                            <PorscheFont className='serial-number'>{ele.part?.serialNumber}</PorscheFont>
                          </td>
                        </tr>
                      </React.Fragment>
                    )
                  }
                }
                )}
                {/* CWT 1st Row end */}
                {/* CWT 2st Row winter tires start */}
                {
                  upsale?.upsaleItems?.map(ele => {
                    if (ele.partType === 'tire') {
                      return (
                        <tr className='table-body-row'>
                          <td className='table-col-lable td-margin-top dflex text-left minwidth'>
                            <PIcon className='setting-icon' name="wrench" aria={{ 'aria-label': 'Wrench icon' }} />
                            <PorscheFont>
                              {ele.serviceItems[0]?.services?.serviceNameUpsale ? ele.serviceItems[0]?.services?.serviceNameUpsale : "Winter tires"} {"Winter tires"}
                            </PorscheFont>
                          </td>
                          <td className='table-col-lable td-margin-top text-right'>
                            <PorscheFont>
                              {`${formatCurrencyWithNoUnit(ele.serviceItems[0]?.services?.priceInternal ? ele.serviceItems[0]?.services?.priceInternal : 0)} €`}
                            </PorscheFont>
                          </td>
                          <td className='table-col-lable td-margin-top text-right'>
                            <PorscheFont>
                              {`${formatCurrencyWithNoUnit(ele.serviceItems[0]?.services?.priceBeforeCustomerNet ? ele.serviceItems[0]?.services?.priceBeforeCustomerNet : 0)} €`}
                            </PorscheFont>
                          </td>
                          <td className='table-col-lable td-margin-top text-right'>
                            <PorscheFont>
                              {`${formatCurrencyWithNoUnit(ele.serviceItems[0]?.customerShare ? ele.serviceItems[0]?.customerShare : 0)} €`}
                            </PorscheFont>
                          </td>
                          <td className='table-col-lable td-margin-top text-right'>
                            <PorscheFont>
                              {`${formatCurrencyWithNoUnit(ele.serviceItems[0]?.discount ? ele.serviceItems[0]?.discount : 0)} €`}
                            </PorscheFont>
                          </td>
                          <td className='table-col-lable td-margin-top text-right'>
                            <PorscheFont>
                              {`${formatCurrencyWithNoUnit(ele.serviceItems[0]?.commission ? ele.serviceItems[0]?.commission : 0)} €`}
                            </PorscheFont>
                          </td>
                          <td className='table-col-lable td-margin-top text-right'></td>
                        </tr>
                      )
                    }
                  })}
                {/* CWT 2st Row winter tires end */}
                {/* CWT 3rd Row summer tires start */}
                {upsale?.upsaleItems?.map(ele => {
                  if (ele.partType === 'tire')
                    return (
                      <tr className='table-body-row'>
                        <td className='table-col-lable td-margin-top td-margin-bottom text-left'>
                          <PorscheFont>
                            {ele.serviceItems[1]?.services?.serviceNameUpsale ? ele.serviceItems[1]?.services?.serviceNameUpsale : "Summer tires"} {"Summer tires"}
                          </PorscheFont>
                        </td>
                        <td className='table-col-lable td-margin-top td-margin-bottom text-right'>
                          <PorscheFont>
                            {`${formatCurrencyWithNoUnit(ele.serviceItems[1]?.services?.priceInternal ? ele.serviceItems[1]?.services?.priceInternal : 0)} €`}
                          </PorscheFont>
                        </td>
                        <td className='table-col-lable td-margin-top td-margin-bottom text-right'>
                          <PorscheFont>
                            {`${formatCurrencyWithNoUnit(ele.serviceItems[1]?.services?.priceBeforeCustomerNet ? ele.serviceItems[1]?.services?.priceBeforeCustomerNet : 0)} €`}
                          </PorscheFont>
                        </td>
                        <td className='table-col-lable td-margin-top td-margin-bottom text-right'>
                          <PorscheFont>
                            {`${formatCurrencyWithNoUnit(ele.serviceItems[1]?.customerShare ? ele.serviceItems[1]?.customerShare : 0)} €`}
                          </PorscheFont>
                        </td>
                        <td className='table-col-lable td-margin-top td-margin-bottom text-right'>
                          <PorscheFont>
                            {`${formatCurrencyWithNoUnit(ele.serviceItems[1]?.discount ? ele.serviceItems[1]?.discount : 0)} €`}
                          </PorscheFont>
                        </td>
                        <td className='table-col-lable td-margin-top td-margin-bottom text-right'>
                          <PorscheFont>
                            {`${formatCurrencyWithNoUnit(ele.serviceItems[1]?.commission ? ele.serviceItems[1]?.commission : 0)} €`}
                          </PorscheFont>
                        </td>
                        <td className='table-col-lable td-margin-top td-margin-bottom text-right'></td>
                      </tr>)
                })}
                {/* CWT 3rd Row summer tires end */}
                {/* Totals display start*/}
                {totalCwt.priceInternal > 0 && (
                  <tr className='table-body-row'>
                    <td className='table-col-bold td-margin-top total-col-border-top text-left'>
                      <PorscheFont>
                        {t("Total")}
                      </PorscheFont>
                    </td>
                    <td className='table-col-bold td-margin-top total-col-border-top text-right'>
                      <PorscheFont>
                        {`${formatCurrencyWithNoUnit(totalCwt.priceInternal)} €`}
                      </PorscheFont>
                    </td>
                    <td className='table-col-bold td-margin-top total-col-border-top text-right'>
                      <PorscheFont>
                        {`${formatCurrencyWithNoUnit(totalCwt.sellingPriceNet)} €`}
                      </PorscheFont>
                    </td>
                    <td className='table-col-bold td-margin-top total-col-border-top text-right'>
                      <PorscheFont>
                        {`${formatCurrencyWithNoUnit(totalCwt.customerShare)} €`}
                      </PorscheFont>
                    </td>
                    <td className='table-col-bold td-margin-top total-col-border-top text-right'>
                      <PorscheFont>
                        {`${formatCurrencyWithNoUnit(totalCwt.discount)}€`}
                      </PorscheFont>
                    </td>
                    <td className='table-col-bold td-margin-top total-col-border-top text-right'>
                      <PorscheFont>
                        {`${formatCurrencyWithNoUnit(totalCwt.commission)} €`}
                      </PorscheFont>
                    </td>
                    <td className='table-col-bold td-margin-top total-col-border-top text-right'></td>
                  </tr>
                )}
                {/* Totals display end*/}
              </tbody>
            </table>
          </div>
        </div>

        {/* PDS / Tequipment */}
        <div>
          <div className="title-wrapper">
            <PorscheFont>
              <span className="title">{t("PDS / Tequipment")}</span>
            </PorscheFont>
          </div>
          <div>
            <table className='table'>
              <thead>
                <tr className='table-row-heading'>
                  <th className='table-heading text-left'>{t("PDS / Tequipment")}</th>
                  <th className='table-heading text-right'>{t("Price internal")}</th>
                  <th className='table-heading text-right'>{t("Selling price (net)")}</th>
                  <th className='table-heading text-right'>{t("Customer share")}</th>
                  <th className='table-heading text-right'>{t("Discount")}</th>
                  <th className='table-heading text-right'>{t("Comission")}</th>
                  <th className='table-heading text-right'></th>
                </tr>
              </thead>
              <tbody>
                {/* PDS 1st row start */}
                {upsale?.upsaleItems?.map(ele => {
                  if (ele.partType === 'part')
                    return (
                      <React.Fragment>

                        <tr className='table-body-row'>
                          <td className='table-col-lable td-margin-top td-margin-bottom text-left'>
                            <PorscheFont className='table-col-bold'>{ele.part?.part}</PorscheFont>
                            <PorscheFont>{ele.part?.serialNumber}</PorscheFont>
                          </td>
                          <td className='table-col-lable td-margin-top td-margin-bottom text-right'>
                            <PorscheFont>{`${formatCurrencyWithNoUnit(ele.part?.priceInternal ? ele.part?.priceInternal : 0)} €`}</PorscheFont>
                          </td>
                          <td className='table-col-lable td-margin-top td-margin-bottom text-right'>
                            <PorscheFont>{`${formatCurrencyWithNoUnit(ele.part?.priceBeforeCustomerNet ? ele.part?.priceBeforeCustomerNet : 0)} €`}</PorscheFont>
                          </td>
                          <td className='table-col-lable td-margin-top td-margin-bottom text-right'>
                            <PorscheFont>{`${formatCurrencyWithNoUnit(ele.customerShare)} €`}</PorscheFont>
                          </td>
                          <td className='table-col-lable td-margin-top td-margin-bottom text-right'>
                            <PorscheFont>{`${formatCurrencyWithNoUnit(ele.discount)} €`}</PorscheFont>
                          </td>
                          <td className='table-col-lable td-margin-top td-margin-bottom text-right'>
                            <PorscheFont>{`${formatCurrencyWithNoUnit(ele.commission)} €`}</PorscheFont>
                          </td>
                          <td className='table-col-lable td-margin-top td-margin-bottom text-right'></td>
                        </tr>
                        {/* PDS 2nd row Installation start */}
                        {ele.serviceItems[0]?.timeUnits ? (
                          <tr className='table-body-row'>
                            <td className='table-col-lable td-margin-top14 total-col-border-bottom dflex text-left minwidth'>
                              <PIcon className='setting-icon' name="wrench" aria={{ 'aria-label': 'Wrench icon' }} />
                              <PorscheFont>{t("Installation: ")}</PorscheFont>
                              <PorscheFont className='table-col-bold'>{ele.serviceItems[0]?.timeUnits}</PorscheFont>
                              <PorscheFont>{t("Time units")}</PorscheFont>
                            </td>
                            <td className='table-col-lable td-margin-top14 total-col-border-bottom text-right'>
                              <PorscheFont>
                                {ele.serviceItems[0]?.timeUnits !== undefined
                                  ? formatCurrencyWithNoUnit(
                                    ele.serviceItems[0]?.services.priceInternal * ele.serviceItems[0]?.timeUnits / 100
                                  )
                                  : "0.00 €"}</PorscheFont>
                            </td>
                            <td className='table-col-lable td-margin-top14 total-col-border-bottom text-right'>
                              <PorscheFont>  {ele.serviceItems[0]?.timeUnits !== undefined
                                ? formatCurrencyWithNoUnit(
                                  ele.serviceItems[0]?.services
                                    .priceBeforeCustomerNet * ele.serviceItems[0]?.timeUnits / 100.00
                                )
                                : "0.00 €"}</PorscheFont>
                            </td>
                            <td className='table-col-lable td-margin-top14 total-col-border-bottom text-right'>
                              <PorscheFont>{`${formatCurrencyWithNoUnit(ele.serviceItems[0]?.customerShare ? ele.serviceItems[0]?.customerShare : 0)} €`}</PorscheFont>
                            </td>
                            <td className='table-col-lable td-margin-top14 total-col-border-bottom text-right'>
                              <PorscheFont>{`${formatCurrencyWithNoUnit(ele.serviceItems[0]?.discount ? ele.serviceItems[0]?.discount : 0)} €`}</PorscheFont>
                            </td>
                            <td className='table-col-lable td-margin-top14 total-col-border-bottom text-right'>
                              <PorscheFont>{`${formatCurrencyWithNoUnit(ele.serviceItems[0]?.commission ? ele.serviceItems[0]?.commission : 0)} €`}</PorscheFont>
                            </td>
                            <td className='table-col-lable td-margin-top14 total-col-border-bottom text-right'></td>
                          </tr>
                        ) : null}
                        {/* PDS 2nd row Installation end */}
                      </React.Fragment>
                    )
                })}
                {/* PDS 1st row end */}

                {/* Total PDS start */}
                {totalPds.priceInternal > 0 && (
                  <tr className='table-body-row'>
                    <td className='table-col-bold td-margin-top text-left'>
                      <PorscheFont>{"Total"}</PorscheFont>
                    </td>
                    <td className='table-col-bold td-margin-top text-right'>
                      <PorscheFont>{`${formatCurrencyWithNoUnit(totalPds.priceInternal)} €`}</PorscheFont>
                    </td>
                    <td className='table-col-bold td-margin-top text-right'>
                      <PorscheFont>{`${formatCurrencyWithNoUnit(totalPds.sellingPriceNet)} €`}</PorscheFont>
                    </td>
                    <td className='table-col-bold td-margin-top text-right'>
                      <PorscheFont>{`${formatCurrencyWithNoUnit(totalPds.customerShare)} €`}</PorscheFont>
                    </td>
                    <td className='table-col-bold td-margin-top text-right'>
                      <PorscheFont>{`${formatCurrencyWithNoUnit(totalPds.discount)}€`}</PorscheFont>
                    </td>
                    <td className='table-col-bold td-margin-top text-right'>
                      <PorscheFont>{`${formatCurrencyWithNoUnit(totalPds.commission)} €`}</PorscheFont>
                    </td>
                    <td className='table-col-bold td-margin-top text-right'></td>
                  </tr>
                )}
                {/* Total PDS End */}
              </tbody>
            </table>
          </div>
        </div>

        {/* Comment section */}
        <div>
          <div className="comment-section">
            <PorscheFont>
              <span className="title">{t("Comment")}</span>
            </PorscheFont>
          </div>
          <div>
            <PorscheFont className='table-col-lable'>{upsale?.comment}</PorscheFont>
          </div>
        </div>
      </div>
    </>
  );
})