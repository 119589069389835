import { useContext } from 'react';
import { OrderContext } from '../../../../contexts/OrderContext';
import { CalculationDraftDTO } from '../../../../interfaces/Calculation';
import { DemoCarRecalculation } from './DemoCar/DemoCarRecalculation';
import { DemoCarSingle } from './DemoCar/DemoCarSingle';
import { NewCarRecalculation } from './NewCarRecalculation';
import { NewCarSingle } from './NewCarSingle';
import { UsedCarRecalculation } from './UsedCar/UsedCarRecalculation';
import { UsedCarSingle } from './UsedCar/UsedCarSingle';

interface Props {
  onNextTabChange: (i: number) => void;
  changedMode: string;
  refreshHeader: (cdd: CalculationDraftDTO) => void;
  handleModified: () => void;
  setStateCalDraft: (state: CalculationDraftDTO) => void;
}

export const CalculationTab = (props: Props): JSX.Element => {
  const orderContext = useContext(OrderContext);
  const { onNextTabChange, changedMode, refreshHeader, handleModified, setStateCalDraft } = props;
  const display = () => {
    let order= orderContext.getCtxOrderDetails()
    let recalcStatus= order.reCalcStatus

    if(order?.vehicleDetails?.category?.type) {
      if(orderContext.getCtxOrderDetails().vehicleDetails?.category.type === "NewCar") {
        return recalcStatus && recalcStatus !== "notStarted" ? <NewCarRecalculation handleModified={handleModified}/> : <NewCarSingle onNextTabChange={onNextTabChange} changedMode={changedMode}  refreshHeader={refreshHeader} handleModified={handleModified} setStateCalDraft={setStateCalDraft}/>;
      } else if(order?.vehicleDetails?.category?.type === "DemoCar") {
        return recalcStatus && recalcStatus !== "notStarted" ? <DemoCarRecalculation handleModified={handleModified}/> : <DemoCarSingle onNextTabChange={onNextTabChange} changedMode={changedMode} refreshHeader={refreshHeader} handleModified={handleModified} setStateCalDraft={setStateCalDraft} />;
      } else if(order?.vehicleDetails?.category?.type === "UsedCar"){
        return recalcStatus && recalcStatus !== "notStarted" ? <UsedCarRecalculation handleModified={handleModified}/> : <UsedCarSingle onNextTabChange={onNextTabChange} changedMode={changedMode} refreshHeader={refreshHeader} handleModified={handleModified} setStateCalDraft={setStateCalDraft}/>;
      }
    } else {
      <></>;
    }
  }

  return (<>{display()}</>);
}