import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Stack } from '@mui/material';
import { PDivider, PFlex, PFlexItem, PIcon } from '@porsche-design-system/components-react';

import { PorscheFont } from '../../../assets/styles/CustomStyles';
import { ApprovalsResponseDTO, ApprovalStepDTO, LogBookDTO } from '../../../interfaces/Approvals';
import { type } from '../../../services/Constants';
import { dcsgetApprovals } from '../../../services/data/Helpers';

interface Props {
 handleClose: () => void;
 orderId: number;
 approval: ApprovalStepDTO;
 mode: string;
}
export const CommissionLog = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const { handleClose, orderId, approval, mode } = props;
    const [logBook,setLogBook] = useState<Array<LogBookDTO>>([]);

    useEffect(() => {
        const init = async () => {
          const apiResponses = await dcsgetApprovals(orderId)
          
          if(apiResponses.status===type.SUCCESS){
            const approvalResponse = apiResponses.response as ApprovalsResponseDTO;

            const appConfig = mode === "order"
                ? approvalResponse?.orderApprovalSteps
                : mode === "commission"
                    ? approvalResponse?.commissionApprovalSteps
                    : [];
            const filter = appConfig.filter(x => x.id === approval.id);
            if(filter.length > 0) {
                setLogBook(filter[0].logBook ? filter[0].logBook : []);
            }
          }
        };
        init();
    }, []);

    return (
        <>
            <div style={{height: "500px", width: "800px"}}>
                <PorscheFont className="dcs-section-heading" >{t("Logbook")}</PorscheFont><br></br>
                <Stack direction="row">
                    <PIcon name="arrow-left" aria={{ 'aria-label': 'Arrow Left icon' }} onClick={handleClose} />&nbsp;
                    <PorscheFont className="tn-text-16-400">{t("Approval overview")}</PorscheFont>
                </Stack><br></br>
                <PDivider color="neutral-contrast-low" /><br></br>
                <PFlex>
                    <PFlexItem style={{ width: '20%' }} className="sub-headding">
                        <PorscheFont className="dcs-heading">{t("Date")}</PorscheFont>
                    </PFlexItem>
                    <PFlexItem style={{ width: '20%' }} className="sub-headding">
                        <PorscheFont className="dcs-heading">{t("User")}</PorscheFont>
                    </PFlexItem>
                    <PFlexItem style={{ width: '20%' }} className="sub-headding">
                        <PorscheFont className="dcs-heading">{t("Action")}</PorscheFont>
                    </PFlexItem>
                    <PFlexItem style={{ width: '40%' }} className="sub-headding">
                        <PorscheFont className="dcs-heading">{t("Comment")}</PorscheFont>
                    </PFlexItem>
                </PFlex>
                <PDivider className="mt8" color="neutral-contrast-high" /><br></br>
                {approval.logBook && approval.logBook.map((book, index) => {
                    if (book.action === "UnAssigned") return <></>;
                    return(<>
                <PFlex>
                    <PFlexItem style={{ width: '20%' }}>
                        <PorscheFont className="tn-text-14-400">{book.date.substring(0, 10).split("-").reverse().join(".")}</PorscheFont>
                    </PFlexItem>
                    <PFlexItem style={{ width: '20%' }}>
                        <PorscheFont className="tn-text-14-400">{book.user.ppnName}</PorscheFont>
                    </PFlexItem>
                    <PFlexItem style={{ width: '20%' }}>
                        { (book.action === "Approved") && (
                          <PorscheFont className="dcs-order-approved">{t(book.action)}</PorscheFont>
                        )}
                        { (book.action === "Reassigned") && (
                          <PorscheFont className="dcs-order-reassigned">{t(book.action)}</PorscheFont>
                        )}
                        { (book.action === "Rejected") && (
                          <PorscheFont className="dcs-order-rejected">{t(book.action)}</PorscheFont>
                        )}
                        { (book.action === "Assigned") && (
                          <PorscheFont className="dcs-order-status">{t(book.action)}</PorscheFont>
                        )}
                    </PFlexItem>
                    <PFlexItem style={{ width: '40%' }}>
                        <PorscheFont className="tn-text-14-400">{book.comment}</PorscheFont>
                    </PFlexItem>
                </PFlex><br></br>
                </>);
                })}
            </div>
        </>
    );
};
