import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import { AuthService } from '../AuthService';
import {Constants} from "../../configs/Constants";

interface ResponseWrapper {
  status: string;
  rawResponse?: AxiosResponse<any>;
  errorResponse?: any;
}

interface dcsResponseWrapper {
  status: string;
  response?: AxiosResponse<any>| unknown
  responseFlag:boolean
}

const getHeaders = async (headers: { [key: string]: any }) => {
  let token = "";
  let authService = new AuthService();
  await authService.getUser().then(async(user) => {
    if (user) {
      token = user.id_token;
    } else {
      let url = `${Constants.domain}`;
      window.open(url, "_self");
    }
  });

  const hdrs = {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Accept": "*/*",
    },
  };
  return hdrs;
};

// REST CALL HELPER

export const getRequest = async (path: string, headers={}) => {
  let finalResponse: ResponseWrapper;
  const hdrs = await getHeaders(headers);
  try {
    const requestConfig: AxiosRequestConfig = {
      method: "get",
      headers: hdrs.headers,
      url: path,
    };
    const rawResponse = await axios(requestConfig);
    finalResponse = {
      status: "success",
      rawResponse: rawResponse,
    };
  } catch (error) {
    finalResponse = {
      status: "error",
      errorResponse: error,
    };
  }
  return finalResponse;
};

export const dcsgetRequest = async (path: string, headers: { [key: string]: any } = {}) => {
  let authService = new AuthService();
  let finalResponse: dcsResponseWrapper;
  const hdrs = await getHeaders(headers);
  try {
    const requestConfig: AxiosRequestConfig = {
      method: "get",
      headers: hdrs.headers,
      url: path,
    };
    const rawResponse = await axios(requestConfig);
    finalResponse = { 
      status: "success",
      response: rawResponse?.data?.data as AxiosResponse<any> ,
      responseFlag:rawResponse?.data?.status 
    };
  } catch (err) {
    const error =err as AxiosError<any>
    const errorResult = error?.response?.data?.data
    if(errorResult?.errorCode === 401){
      await authService.logout()
    }
    if(errorResult?.errorCode === 403){
      window.location.replace('#/inactive-user');
    }
    finalResponse = {
      status: "error",
      response: errorResult?.message ? errorResult?.message : "Something went wrong",
      responseFlag:  error?.response?.data?.status

    };
  }
  return finalResponse;
};

export const dcsputRequest = async (path: string, data: any, headers: { [key: string]: any } = {}) => {
  let authService = new AuthService();

  let finalResponse: dcsResponseWrapper;
  const hdrs = await getHeaders(headers);
  // hdrs.headers.Authorization = 'Bearer ';
  try {
    const rawResponse = await axios.put(path, data, hdrs);;
    finalResponse = {
      status: "success",
      response: rawResponse?.data?.data,
      responseFlag:rawResponse?.data?.status 
    };
  } catch (err) {
    const error =err as AxiosError<any>
    const errorResult = error?.response?.data?.data
    if(errorResult?.errorCode === 401){
      await authService.logout()
    }
    finalResponse = {
      status: "error",
      response: errorResult?.message ? errorResult?.message: "Something went wrong",
      responseFlag:  error?.response?.data?.status
    };
  }
  return finalResponse;
};


export const postRequest = async (path: string, data: any, headers: { [key: string]: any } = {}) => {
  let finalResponse: ResponseWrapper;
  const hdrs = await getHeaders(headers);

  try {
    const rawResponse = await axios.post(path, data, hdrs);
    finalResponse = {
      status: "success",
      rawResponse: rawResponse,
    };
  } catch (error) {
    finalResponse = {
      status: "error",
      errorResponse: error,
    };
  }
  return finalResponse;
};

export const dcspostRequest = async (path: string, data: any, headers: { [key: string]: any } = {}) => {
  let authService = new AuthService();

  let finalResponse: dcsResponseWrapper;
  const hdrs = await getHeaders(headers);
  // hdrs.headers.Authorization = 'Bearer ';
  try {
    const rawResponse = await axios.post(path, data, hdrs);;
    finalResponse = {
      status: "success",
      response: rawResponse?.data?.data,
      responseFlag:rawResponse?.data?.status 
    };
  } catch (err) {
    const error =err as AxiosError<any>
    const errorResult = error?.response?.data?.data
    if(errorResult?.errorCode === 401){
      await authService.logout()
    }
    finalResponse = {
      status: "error",
      response: errorResult?.message ? errorResult?.message: "Something went wrong",
      responseFlag:  error?.response?.data?.status
    };
  }
  return finalResponse;
};

export const putRequest = async (path: string, data: any, headers: { [key: string]: any } = {}) => {
  let finalResponse: ResponseWrapper;
  const hdrs = await getHeaders(headers);

  try {
    const rawResponse = await axios.put(path, data, hdrs);;
    finalResponse = {
      status: "success",
      rawResponse: rawResponse,
    };
  } catch (error) {
    finalResponse = {
      status: "error",
      errorResponse: error,
    };
  }
  return finalResponse;
};

export const deleteRequest = async (path: string, headers={}) => {
  let finalResponse: ResponseWrapper;
  const hdrs = await getHeaders(headers);
  try {
    const requestConfig: AxiosRequestConfig = {
      method: "delete",
      headers: hdrs.headers,
      url: path,
    };
    const rawResponse = await axios(requestConfig);
    finalResponse = {
      status: "success",
      rawResponse: rawResponse,
    };
  } catch (error) {
    finalResponse = {
      status: "error",
      errorResponse: error,
    };
  }
  return finalResponse;
};


export const dcsdeleteRequest = async (path: string, headers: { [key: string]: any } = {}) => {
  let authService = new AuthService();
  let finalResponse: dcsResponseWrapper;
  const hdrs = await getHeaders(headers);
  try {
    const requestConfig: AxiosRequestConfig = {
      method: "delete",
      headers: hdrs.headers,
      url: path,
    };
    const rawResponse = await axios(requestConfig);
    finalResponse = { 
      status: "success",
      response: rawResponse?.data?.data as AxiosResponse<any> ,
      responseFlag:rawResponse?.data?.status 
    };
  } catch (err) {
    const error =err as AxiosError<any>
    const errorResult = error?.response?.data?.data
    if(errorResult?.errorCode === 401){
      await authService.logout()
    }
    finalResponse = {
      status: "error",
      response: errorResult?.message ? errorResult?.message : "Something went Wrong",
      responseFlag:  error?.response?.data?.status

    };
  }
  return finalResponse;
};