import { Alert, Snackbar } from '@mui/material';
import {
  PButton, PButtonGroup, PDivider, PFlex, PFlexItem
} from '@porsche-design-system/components-react';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PorscheFont } from '../../../assets/styles/CustomStyles';
import { Constant } from '../../../configs/Constants';
import { OrderContext } from '../../../contexts/OrderContext';
import { sortApprovalConfiguration } from '../../../helpers/sorter';
import {
  ApprovalStepDTO,
  ApprovalsRequestDTO, ApprovalsResponseDTO
} from '../../../interfaces/Approvals';
import { CalculationDraftDTO } from '../../../interfaces/Calculation';
import { UserDetailsDTO } from '../../../interfaces/User';
import {
  ApprovalConfiguration, ApprovalConfigurationStep
} from '../../../interfaces/adminApproval/ApprovalConfiguration';
import { type } from '../../../services/Constants';
import {
  dcschangeStatusOrderApproval, dcsgetApprovalConfiguration, dcsgetApprovals, dcspostOrderApprovals, dcsputOrderApprovals
} from '../../../services/data/Helpers';
import CustomSnakebar from '../../common/CustomSnakebar';
import { OrderApprovalRow } from './OrderApprovalRow';

interface Props {
  orderId: number;
  category: string;
  handleClose: (reload: boolean) => void;
}

export const OrderApprovalNew = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const { orderId, category, handleClose } = props;
  const [edit, setEdit] = useState<boolean>(false);
  const [approvalConfig, setApprovalConfig] = useState<ApprovalConfiguration>({} as ApprovalConfiguration ); 
  const [approvals, setApprovals] = useState<Array<ApprovalConfigurationStep>>([])
  const [orderApprovals, setOrderApprovals] = useState<Array<ApprovalStepDTO>>([])
  const [orderCheck1, setOrderCheck1] = useState<Array<ApprovalStepDTO>>([])
  const [orderCheck2, setOrderCheck2] = useState<Array<ApprovalStepDTO>>([])
  const [exports, setExports] = useState<Array<ApprovalStepDTO>>([])
  const [discount, setDiscount] = useState<Array<ApprovalStepDTO>>([])
  const [others, setOthers] = useState<Array<ApprovalStepDTO>>([])

  const [approvalRequest, setApprovalRequest] = useState<ApprovalsRequestDTO>({} as ApprovalsRequestDTO);
  const [postApprovals, setPostApprovals] = useState<Array<ApprovalStepDTO>>([]);
  const [tempApprovals, setTempApprovals] = useState<Array<ApprovalStepDTO>>([]);
const [saveSuccess, setSaveSuccess] = useState(false);
const [saveSuccessMsg, setSaveSuccessMsg] = useState("");
const [isError, setIsError] = useState(false);
  const [nextStep, setNextStep] = useState("OrderCheck1");
  const [enableNextStep, setEnableNextStep] = useState(false);
  const [isNextStepValid, setIsNextStepValid] = useState(false);
  const [approvedStep, setApprovedStep] = useState({} as ApprovalStepDTO);
  const [newAprovalStep, setNewApprovalStep] = useState<Array<ApprovalStepDTO>>([]);
  const [isApprover, setIsApprover] = useState(false);
  const [showOthers, setShowOthers] = useState(false);
  const [newStepApprover, showNewStepApprover] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [discountPercent, setDiscountPercent] = useState(0.00);
  const [showAlert, setShowAlert] = useState(Constant.None)
  const [alertMessage, setAlertMessage] = useState(Constant.Savedsuccessfully)


  const orderContext = useContext(OrderContext);
  const order = orderContext.getCtxOrderDetails();
  
  let userDetails = {} as UserDetailsDTO;
  const sessionUser = sessionStorage.getItem("userDetails");
  if(sessionUser) {
    userDetails = JSON.parse(sessionUser);
  }

  useEffect(() => {
    const init = async () => {
      if(order.porscheCenter) {
        const apiResponse = await dcsgetApprovalConfiguration(category, order.porscheCenter.id, order.id)
        if(apiResponse.status === type.SUCCESS){
          const approvalConfig = apiResponse.response as ApprovalConfiguration;
          if(approvalConfig && approvalConfig?.commissionApproval && approvalConfig?.commissionApproval[2].approvers ){
           localStorage.setItem("commissionApprovers",JSON.stringify(approvalConfig?.commissionApproval[2].approvers)  )
         }
          if (approvalConfig.orderApproval) {
            approvalConfig.orderApproval = sortApprovalConfiguration(approvalConfig.orderApproval);
            setApprovals(approvalConfig.orderApproval);
          }
          setApprovalConfig(approvalConfig);
        }

        let calculation = {} as CalculationDraftDTO;
        
        if(order?.vehicleDetails?.category?.type) {
          const category = order?.vehicleDetails?.category?.type
          calculation = orderContext.getCtxCalculationDraftDetails(category);
          let discount = 0.00;
          if(calculation?.calculationHeader && calculation?.vehiclePrices) {
            switch(category) {
              case "NewCar":
                // RRP net value
                if(calculation?.vehiclePrices[2] && calculation?.vehiclePrices[2].amountNet > 0.00 && calculation?.calculationHeader.internalDiscount) {
                  discount = calculation?.calculationHeader.internalDiscount / calculation?.vehiclePrices[2].amountNet * 100;
                }
                break;
              case "DemoCar":
                if(calculation?.vehiclePrices[0] && calculation?.vehiclePrices[0].amountNet > 0.00 && calculation?.calculationHeader.internalDiscount) {
                  discount = calculation?.calculationHeader.internalDiscount / calculation?.vehiclePrices[0].amountNet * 100;
                }
                break;
              case "UsedCar":
                if(calculation?.vehiclePrices[0] && calculation?.vehiclePrices[0].amountNet > 0.00 && calculation?.calculationHeader.internalDiscount) {
                  discount = calculation?.calculationHeader.internalDiscount / calculation?.vehiclePrices[0].amountNet * 100;
                }
                break;
            }
          }
          setDiscountPercent(discount);
        }
      } else {
        
      }
        
    };
    init();
  }, [])

  const loadInit = async () => {
    // Getting ppovals step of the order by order id.
    const apiResponse = await dcsgetApprovals(orderId)
    
    if(apiResponse.status===type.SUCCESS && apiResponse.response){
      const approvalResponse = apiResponse.response as ApprovalsResponseDTO;
      setOrderApprovals(approvalResponse?.orderApprovalSteps);
      
      let steps:Array<ApprovalStepDTO> = [];

      approvalResponse.orderApprovalSteps.map((orderStep, i) => {
        let step = {
          createdBy: orderStep.createdBy,
          configurationStep: orderStep.configurationStep,
          approver: orderStep.approver,
          approvalStep: orderStep.approvalStep,
          isEnabled: orderStep.isEnabled,
          action: orderStep.action,
          id: orderStep.id,
          order: orderStep.order ? orderStep.order : {id: order.id}
        } as ApprovalStepDTO;

        if(orderStep.approvalStep === "Others") step.otherSubject = orderStep.otherSubject;

        steps.push(step);
        if(i > 0 && orderStep.action === "Approved") {
          setNextStep(orderStep.approvalStep);
        }

        if(!isApprover) {
          setIsApprover(orderStep.approver.id === userDetails.id ? true : false);

        }
        
      })

      if(steps.length > 0) {
        approvalRequest.approvalSteps = steps;
        setApprovalRequest(approvalRequest);
      }

      setOrderCheck1(approvalResponse.orderApprovalSteps.filter(x => x.approvalStep === "OrderCheck1"));
      setOrderCheck2(approvalResponse.orderApprovalSteps.filter(x => x.approvalStep === "OrderCheck2"));
      setExports(approvalResponse.orderApprovalSteps.filter(x => x.approvalStep === "Export"));
      setDiscount(approvalResponse.orderApprovalSteps.filter(x => x.approvalStep === "Discount"));
      const othrs = approvalResponse.orderApprovalSteps.filter(x => x.approvalStep === "Others");
      setOthers(othrs);

      if(othrs.length === 0){
        if(approvalConfig.orderApproval && approvalConfig.orderApproval.length > 0) {
          if (approvalConfig.orderApproval[4].isEnabled) setShowOthers(true);
        }
      }
    }
  };

  useEffect(() => {
    loadInit();
  }, []);

  const handleEdit = () => {
    setEdit(!edit);
    // if(!edit) {
    //   let post:Array<ApprovalStepDTO> = [];
    //   approvalRequest.approvalSteps.map((step, i) => {
    //     post.push(step)
    //   })
    //   setPostApprovals(post);
    // } else {
    //   setPostApprovals([]);
    // }
  }

  const handleSuccessClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return false;
    }
    setSaveSuccess(false);
  };

  const handleErrorClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return false;
    }
    setIsError(false);
  };

  const handleMessage = (value: boolean,  msg: string) => {
    if(value === true) {
        setSaveSuccess(true);
        setSaveSuccessMsg(msg);
    }
  }

  const validatePost = (post: Array<ApprovalStepDTO>) : boolean => {
    let valid = false;
    // First order check
    const fc = post.filter(x => x.approvalStep === "OrderCheck1");
    if(fc.length > 0) {
      if(fc[0].approver.id) {
        valid = true;
      } else {
        return false; 
      }
    }

    // Second order check
    const sc = post.filter(x => x.approvalStep === "OrderCheck2");
    if(sc.length > 0) {
      if(sc[0].approver.id) {
        valid = true;
      } else {
        return false; 
      }
    }

    // Export
    const exp = post.filter(x => x.approvalStep === "Export");
    if(exp.length > 0) {
      if(exp[0].approver.id) {
        valid = true;
      } else {
        return false; 
      }
    }

    // Discount
    const discount = post.filter(x => x.approvalStep === "Discount");
    if(discount.length > 0) {
      if (discount.length !== 2) return false;
      if(discount[0].approver.id) {
        valid = true;
      } else {
        return false; 
      }

      if(discount[1].approver.id) {
        valid = true;
      } else {
        return false; 
      }

      if(discount[0].approver.id === discount[1].approver.id) return false;
    }

    // Others
    const others = post.filter(x => x.approvalStep === "Others");
    if(others.length > 0) {
      if(others[0].approver.id) {
        valid = true;
      } else {
        return false; 
      }

      if(others[0].otherSubject === undefined ||
        others[0].otherSubject === null ||
        others[0].otherSubject.length === 0
      ) return false;
    }

    return valid;
  }

  const handleAssignee = (approval: ApprovalStepDTO, config: ApprovalConfigurationStep, value: string, isNew: boolean) => {
    
    
    const uid = parseInt(value);
    if(uid > 0) {
      // Setting new approver truw to determine if any new step approver is created for examplefor ohters step.
      if(isNew && config.approvalStep !== "Others") showNewStepApprover(true);
      let user = config.approvers ? config.approvers.filter(user => user.id === uid) : [];
      
      if(postApprovals.length > 0){
        
          let temp = postApprovals;
          let exists = false;
          temp.map((step, i) => {
            if(step.approvalStep === approval.approvalStep) {
              
              if(step.approvalStep === "Discount") {
                  const count = temp.filter(x => (x.approvalStep === "Discount" && step.id === approval.id) );
                  if(count.length > 0) {
                    exists = true;
                    temp[i].approver = user.length > 0 ? user[0] : {};
                    temp[i].action = "Assigned";
                  } 
              } else {
                  exists = true;
                  temp[i].approver = user.length > 0 ? user[0] : {};
                  temp[i].action = "Assigned";
              }
            }

            if(approval.approvalStep === undefined && config.approvalStep === "Others") {
              if(step.approvalStep === "Others") {
                exists = true;
                temp[i].approver = user.length > 0 ? user[0] : {};
                temp[i].action = "Assigned";
              } else {
                exists = false;
              }
            }
          })

          if(!exists) {
            
            const newApprover = {
                //createdBy: userDetails ? {id: userDetails.id, ppnName: userDetails.ppnName} : {},
                createdBy: approval.createdBy 
                  ? {id: approval.createdBy.id, ppnName: approval.createdBy.ppnName} 
                  : userDetails 
                  ? {id: userDetails.id, ppnName: userDetails.ppnName}
                  : {},
                configurationStep: config.id ? {id: config.id} : {},
                approvalStep: (isNew || config.approvalStep === "Others") ? config.approvalStep : approval.approvalStep,
                isEnabled: (isNew || config.approvalStep === "Others") ? config.isEnabled : approval.isEnabled,
                action: "Assigned",
                approver: user.length > 0 ? user[0] : {},
                order: { id: orderId }
            } as ApprovalStepDTO;

            if(approval.id){
              newApprover.id = approval.id; 
            }

            temp.push(newApprover);
          }
          setPostApprovals(temp);
          setIsValid(validatePost(temp));
      } else {
          let  newApprover = {
            createdBy: approval.createdBy 
                  ? {id: approval.createdBy.id, ppnName: approval.createdBy.ppnName} 
                  : userDetails 
                  ? {id: userDetails.id, ppnName: userDetails.ppnName}
                  : {},
            configurationStep: config.id ? {id: config.id} : {},
            approvalStep: isNew ? config.approvalStep : approval.approvalStep,
            isEnabled: isNew ? config.isEnabled : approval.isEnabled,
            action: "Assigned",
            approver: user.length > 0 ? user[0] : {},
            order: { id: orderId }
          } as ApprovalStepDTO;
          if(approval.id){
            newApprover.id = approval.id;
          }
          setPostApprovals([newApprover]);
          setIsValid(validatePost([newApprover]));
      }
      
    //   
    //   setApprovalRequest(approvalRequest);
    //   setIsValid(isValidApproval(approvalRequest));
    } else {
      if(approval.approvalStep !== "Others") {
        setIsValid(false);
      } else {
        postApprovals.map((step, i) => {
          if(step.approvalStep === "Others") {
            postApprovals.splice(i, 1);
          }
        })

        setPostApprovals(postApprovals);
        setIsValid(validatePost(postApprovals));
      }
      
    }
  }

  const handleSave = () => {
     const init = async() => {
        // const res = await putOrderApprovals(postApprovals);
        const apiResponse = await dcsputOrderApprovals(postApprovals)
        
        if(apiResponse.status ===type.SUCCESS) {
          await loadInit();
          setEdit(false);
          setShowAlert(Constant.Success)
          setAlertMessage(Constant.Savedsuccessfully)
        }
        if(apiResponse.status===type.ERROR){
          setShowAlert(Constant.Error)
          setAlertMessage(apiResponse.response)
          } 
      }
      init();
  }

  const handleSubmit = () => {
      const init = async () => {
        if (approvedStep.approver && approvedStep.approver.id) {
          const apiResponse = await dcschangeStatusOrderApproval(approvedStep);

          if (apiResponse.status === type.SUCCESS) {
            if (newAprovalStep.length > 0) {
              const postApiResp = await dcspostOrderApprovals(newAprovalStep);

              if (postApiResp.status === type.SUCCESS) {
                loadInit();
                setEdit(false);
                setEnableNextStep(false);
                setShowAlert(Constant.Success);
                setAlertMessage(Constant.Savedsuccessfully);
                setTimeout(() => handleClose(true), 1000);
              }
              if (postApiResp.status === type.ERROR) {
                setShowAlert(Constant.Error);
                setAlertMessage(postApiResp.response);
              }
            } else {
              setShowAlert(Constant.Success);
              setAlertMessage(Constant.Savedsuccessfully);
              setTimeout(() => handleClose(true), 1000);
            }
          }
          if (apiResponse.status === type.ERROR) {
            setShowAlert(Constant.Success);
            setAlertMessage(apiResponse.response);
          }
        }
      };
      init();
   
  }

  const handleApproved = (step: ApprovalStepDTO) => {
    
    
    let enable = false;
    switch(step.approvalStep) {
      case "OrderCheck1":
        if(approvalConfig.orderApproval &&  approvalConfig.orderApproval.length> 0) {
          if (approvalConfig.orderApproval[1].isEnabled && approvalConfig.orderApproval[1].approvers && approvalConfig.orderApproval[1].approvers.length > 0 ) {
            setNextStep("OrderCheck2");
            enable = true;
          } else if(approvalConfig.orderApproval[2].isEnabled && approvalConfig.orderApproval[2].approvers && approvalConfig.orderApproval[2].approvers.length > 0 && order?.deliveryDetails?.exportDeal) {
            setNextStep("Export");
            enable = true;
          } else if(approvalConfig.orderApproval[3].isEnabled && approvalConfig.orderApproval[3].approvers && approvalConfig.orderApproval[3].approvers.length > 1  && discountPercent > 10) {
            setNextStep("Discount");
            enable = true;
          } else if(approvalConfig.orderApproval[5].isEnabled) {
            setNextStep("PEPMatch");
            enable = true;
          }
        }
        break;
      case "OrderCheck2":
        if(approvalConfig.orderApproval &&  approvalConfig.orderApproval.length> 0) {
          if(approvalConfig.orderApproval[2].isEnabled && approvalConfig.orderApproval[2].approvers && approvalConfig.orderApproval[2].approvers.length > 0 && order?.deliveryDetails?.exportDeal) {
            setNextStep("Export");
            enable = true;
          } else if(approvalConfig.orderApproval[3].isEnabled && approvalConfig.orderApproval[3].approvers && approvalConfig.orderApproval[3].approvers.length > 1  && discountPercent > 9.99) {
            setNextStep("Discount");
            enable = true;
          } 
        //   else if(approvalConfig.orderApproval[5].isEnabled) {
        //     setNextStep("PEPMatch");
        //     enable = true;
        //   }
        }
        break;
      case "Export":
        if(approvalConfig.orderApproval &&  approvalConfig.orderApproval.length> 0) {
          if(approvalConfig.orderApproval[3].isEnabled && approvalConfig.orderApproval[3].approvers && approvalConfig.orderApproval[3].approvers.length > 1 && discountPercent > 9.99) {
            setNextStep("Discount");
            enable = true;
          } 
        //   else if(approvalConfig.orderApproval[4].isEnabled) {
        //     setNextStep("Others");
        //     enable = true;
        //   }
        }
        break;
        case "Discount":
       
          break;
        case "Others":
          
          break;
    }
    
    if(enable) {
      //setEdit(true);
      setEdit(false);
      setEnableNextStep(true);
      setApprovedStep(step);
    }else{
      setEnableNextStep(true);
      setApprovedStep(step);
      setIsNextStepValid(true);
     
    }
  }

  const handleNewAssignee = (nextStep: ApprovalConfigurationStep, value: string, index: number) => {
    const uid = parseInt(value);
    
    
    
    // if(uid > 0) {
      
      let temp = newAprovalStep;
      
      let user = nextStep.approvers ? nextStep.approvers.filter(user => user.id === uid) : [];
      if(temp.length === 0) {
        let ns = {
          createdBy: userDetails 
              ? {id: userDetails.id, ppnName: userDetails.ppnName}
              : {},
            configurationStep: nextStep.id ? {id: nextStep.id} : {},
            approvalStep: nextStep.approvalStep,
            isEnabled: nextStep.isEnabled,
            action: "Assigned",
            approver: user.length > 0 ? user[0] : {},
            order: { id: orderId }
        } as ApprovalStepDTO;
  
        temp.push(ns);
      } else {
        
        if(nextStep.approvalStep === "Discount") {
          if(index === 0) temp[0].approver = user.length > 0 ? user[0] : {};

          if(index === 1) {
            if(temp.length > 1) {
              temp[1].approver = user.length > 0 ? user[0] : {};
            } else {
              let ns = {
                createdBy: userDetails 
                    ? {id: userDetails.id, ppnName: userDetails.ppnName}
                    : {},
                  configurationStep: nextStep.id ? {id: nextStep.id} : {},
                  approvalStep: nextStep.approvalStep,
                  isEnabled: nextStep.isEnabled,
                  action: "Assigned",
                  approver: user.length > 0 ? user[0] : {},
                  order: { id: orderId }
              } as ApprovalStepDTO;
        
              temp[1] = ns;
            }
          }
        } else {
          temp[0].approver = user.length > 0 ? user[0] : {};
        }
      }
      
      setTempApprovals(temp);
      
      setIsNextStepValid(validatePost(temp));
      setNewApprovalStep(temp);
    // } else {
    //   if(nextStep.approvalStep !== "Others") {
    //     setIsNextStepValid(false);
    //   }
    // }
  }

  const refresh = () => {
    setSaveSuccess(true);
    setSaveSuccessMsg("Submitted successfully");
    loadInit();
  }

  const handleOtherSubject = (value: string, step: string) => {
    
    let temp = postApprovals;
    temp.map((app, i) => {
        if(app.approvalStep === step) {
            temp[i].otherSubject = value;
        }
    })
    //setApprovalRequest(approvalRequest);

    setPostApprovals(temp);
    if(value.length === 0) {
      setIsValid(false);
    }else{
      setIsValid(validatePost(temp));
    }
  }

  const handleCustomSnakeClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    setShowAlert(Constant.None)
    setAlertMessage("")
  };

  return (
    <>
      <div style={{marginLeft: "-20px", marginTop:"-32px"}}>
        <PDivider color="neutral-contrast-low"></PDivider><br></br>
        <PFlex justifyContent={"space-between"}>
          <PFlexItem style={{ width: "30%" }} className="sub-headding"><PorscheFont>{t("Approval steps")}</PorscheFont></PFlexItem>
          <PFlexItem style={{ width: "100%" }} className="sub-headding"><PorscheFont>{t("User assigned")}</PorscheFont></PFlexItem>
          <PFlexItem style={{ width: "20%" }}></PFlexItem>
        </PFlex><br></br>

      <PDivider color="neutral-contrast-high"></PDivider>
        { orderCheck1.length > 0 && (
          <>
            <OrderApprovalRow 
              orderId={orderId}
              discountIndex={0}
              approval={approvals[0]} 
              orderApproval={orderCheck1[0]} 
              label={"1st Order Check"} 
              edit={edit} 
              userDetails={userDetails} 
              handleMessage={(value, msg) => handleMessage(value, msg)}
              handleAprrovalStepAssignee={(approvalStep, config, value, newApprover) => handleAssignee(approvalStep, config, value, newApprover)}
              enableNextStep={false}
              handleApproved= {(step) => handleApproved(step)}
              handleNewAssignee= {(step, value, index) => handleNewAssignee(step, value, index)}
              handleOtherSubject= {(value, stepLabel) => handleOtherSubject(value, stepLabel)}
              refreshOnSuccess= {refresh}
              handleClose={(reload) => handleClose(reload)}
            />
            <PDivider color="neutral-contrast-low"></PDivider>
          </>
        )}

      { approvals.length > 0 && approvals[1].isEnabled && orderCheck2.length === 0 && nextStep !== "OrderCheck2" && (
        <>
          <PFlex justifyContent={"space-between"}>
            <PFlexItem style={{ width: "30%" }}>
              <PFlex>
                  <PFlexItem>
                      <PDivider orientation="vertical" style={{ marginTop: "10px", width: "5px", height: "50px", backgroundColor: "#C8CACB" }} />
                  </PFlexItem>&nbsp;&nbsp;
                  <PFlexItem style={{ marginTop: "20px" }}>
                    <img src={require("../../../assets/images/approvals/2ndordercheck.png")}></img>
                  </PFlexItem>&nbsp;&nbsp;
                  <PFlexItem style={{ marginTop: "20px" }}>
                      <PorscheFont className="tn-text-14-600">{t("2nd Order Check")}<span style={{ color: "#D5001B" }}></span></PorscheFont>
                  </PFlexItem>
              </PFlex>
            </PFlexItem>
            <PFlexItem style={{ marginTop: "10px", marginBottom: "10px", width: "100%" }}>
              <PFlexItem>
                <div className='user-assigned' style={{width: "40%"}}>
                  <PorscheFont style={{ padding: "5px" }}></PorscheFont>
                </div>                           
              </PFlexItem>
            </PFlexItem>
            <PFlexItem style={{ width: "20%" }}></PFlexItem>
          </PFlex>
          <PDivider color="neutral-contrast-low"></PDivider>
        </>
      )}

      { approvals.length > 0 && approvals[1].isEnabled && orderCheck2.length === 0  && nextStep === "OrderCheck2" && (
        <>
          <OrderApprovalRow
            orderId={orderId}
            discountIndex={0}
            approval={approvals[1]} 
            orderApproval={{} as ApprovalStepDTO} 
            label={"2nd Order Check"} 
            edit={edit} 
            userDetails={userDetails} 
            handleMessage={(value, msg) => handleMessage(value, msg)}
            handleAprrovalStepAssignee={(approvalStep, users, value, newApprover) => handleAssignee(approvalStep, users, value, newApprover)}
            enableNextStep={true}
            handleApproved= {(step) => handleApproved(step)}
            handleNewAssignee= {(step, value, index) => handleNewAssignee(step, value, index)}
            handleOtherSubject= {(value, stepLabel) => handleOtherSubject(value, stepLabel)}
            refreshOnSuccess= {refresh}
            handleClose={(reload) => handleClose(reload)}
          />
          <PDivider color="neutral-contrast-low"></PDivider>
        </>
      )}

      { orderCheck2.length > 0 && orderCheck2[0].isEnabled  && (
        <>
          <OrderApprovalRow
            orderId={orderId}
            discountIndex={0}
            approval={approvals[1]} 
            orderApproval={orderCheck2[0]} 
            label={"2nd Order Check"} 
            edit={edit} 
            userDetails={userDetails} 
            handleMessage={(value, msg) => handleMessage(value, msg)}
            handleAprrovalStepAssignee={(approvalStep, users, value, newApprover) => handleAssignee(approvalStep, users, value, newApprover)}
            enableNextStep={false}
            handleApproved= {(step) => handleApproved(step)}
            handleNewAssignee= {(step, value, index) => handleNewAssignee(step, value, index)}
            handleOtherSubject= {(value, stepLabel) => handleOtherSubject(value, stepLabel)}
            refreshOnSuccess= {refresh}
            handleClose={(reload) => handleClose(reload)}
          />
          <PDivider color="neutral-contrast-low"></PDivider>
        </>
      )}

      { approvals.length > 0 && approvals[2].isEnabled && exports.length === 0 && nextStep !== "Export" && 
        order?.deliveryDetails?.exportDeal && (
        <>
          <PFlex justifyContent={"space-between"}>
            <PFlexItem style={{ width: "30%" }}>
              <PFlex>
                  <PFlexItem>
                      <PDivider orientation="vertical" style={{ marginTop: "10px", width: "5px", height: "50px", backgroundColor: "#C8CACB" }} />
                  </PFlexItem>&nbsp;&nbsp;
                  <PFlexItem style={{ marginTop: "20px" }}>
                    <img src={require("../../../assets/images/approvals/Export.png")}></img>
                  </PFlexItem>&nbsp;&nbsp;
                  <PFlexItem style={{ marginTop: "20px" }}>
                  <PorscheFont className="tn-text-14-600">{"Export"}<span style={{ color: "#D5001B" }}></span></PorscheFont>
                  </PFlexItem>
              </PFlex>
            </PFlexItem>
            <PFlexItem style={{ marginTop: "10px", marginBottom: "10px", width:"100%" }}>
              <PFlexItem>
                <div className='user-assigned' style={{width: "40%"}}>
                  <PorscheFont style={{ padding: "5px" }}></PorscheFont>
                </div>                           
              </PFlexItem>
            </PFlexItem>
            <PFlexItem style={{ width: "20%" }}></PFlexItem>
          </PFlex>
          <PDivider color="neutral-contrast-low"></PDivider>
        </>
      )}

      { approvals.length > 0 && approvals[2].isEnabled && exports.length === 0  && nextStep === "Export" && 
        order?.deliveryDetails?.exportDeal && (
        <>
          <OrderApprovalRow
            orderId={orderId}
            discountIndex={0}
            approval={approvals[2]} 
            orderApproval={{} as ApprovalStepDTO} 
            label={"Export"} 
            edit={edit} 
            userDetails={userDetails} 
            handleMessage={(value, msg) => handleMessage(value, msg)}
            handleAprrovalStepAssignee={(approvalStep, users, value, newApprover) => handleAssignee(approvalStep, users, value, newApprover)}
            enableNextStep={true}
            handleApproved= {(step) => handleApproved(step)}
            handleNewAssignee= {(step, value, index) => handleNewAssignee(step, value, index)}
            handleOtherSubject= {(value, stepLabel) => handleOtherSubject(value, stepLabel)}
            refreshOnSuccess= {refresh}
            handleClose={(reload) => handleClose(reload)}
          />
          <PDivider color="neutral-contrast-low"></PDivider>
        </>
      )}
      { exports.length > 0 && exports[0].isEnabled && order?.deliveryDetails?.exportDeal && (
        <>
          <OrderApprovalRow
            orderId={orderId}
            discountIndex={0}
            approval={approvals[2]} 
            orderApproval={exports[0]} 
            label={"Export"} 
            edit={edit} 
            userDetails={userDetails} 
            handleMessage={(value, msg) => handleMessage(value, msg)}
            handleAprrovalStepAssignee={(approvalStep, users, value, newApprover) => handleAssignee(approvalStep, users, value, newApprover)}
            enableNextStep={false}
            handleApproved= {(step) => handleApproved(step)}
            handleNewAssignee= {(step, value, index) => handleNewAssignee(step, value, index)}
            handleOtherSubject= {(value, stepLabel) => handleOtherSubject(value, stepLabel)}
            refreshOnSuccess= {refresh}
            handleClose={(reload) => handleClose(reload)}
          />
          <PDivider color="neutral-contrast-low"></PDivider>
        </>
      )}

      { discountPercent > 9.99 && approvals.length > 0 && approvals[3].isEnabled && discount.length === 0 && nextStep !== "Discount" && (
        <>
          <PFlex justifyContent={"space-between"}>
            <PFlexItem style={{ width: "30%" }}>
              <PFlex >
                  <PFlexItem>
                      <PDivider orientation="vertical" style={{ marginTop: "10px", width: "5px", height: "50px", backgroundColor: "#C8CACB" }} />
                  </PFlexItem>&nbsp;&nbsp;
                  <PFlexItem style={{ marginTop: "20px" }}>
                    <img src={require("../../../assets/images/approvals/Discount.png")}></img>
                  </PFlexItem>&nbsp;&nbsp;
                  <PFlexItem style={{ marginTop: "20px" }}>
                      <PorscheFont className="tn-text-14-600">{t("Discount")}</PorscheFont>
                      <PorscheFont style={{color: "#909090", fontSize:"12px", fontWeight:"400", marginTop:"-4px"}}>
                        {t("2 approvals required")}
                        </PorscheFont>
                  </PFlexItem>
              </PFlex>
            </PFlexItem>
            <PFlexItem style={{ marginTop: "10px", marginBottom: "10px", width: "100%" }}>
              <PFlexItem >
                <div className='user-assigned' style={{width: "40%"}}>
                  <PorscheFont style={{ padding: "5px" }}></PorscheFont>
                </div>                           
              </PFlexItem>
            </PFlexItem>
            <PFlexItem style={{ width: "20%" }}></PFlexItem>
          </PFlex>
          <PFlex justifyContent={"space-between"}>
            <PFlexItem style={{ width: "30%" }}>
              <PFlex>
                  <PFlexItem>
                      <PDivider orientation="vertical" style={{ marginTop: "10px", width: "5px", height: "50px", backgroundColor: "#C8CACB" }} />
                  </PFlexItem>&nbsp;&nbsp;
                  <PFlexItem style={{ marginTop: "20px" }}>
                  </PFlexItem>&nbsp;&nbsp;
                  <PFlexItem style={{ marginTop: "20px" }}>
                  </PFlexItem>
              </PFlex>
            </PFlexItem>
            <PFlexItem style={{ marginTop: "10px", marginBottom: "10px", width:"100%" }}>
              <PFlexItem>
                <div className='user-assigned' style={{width: "40%"}}>
                  <PorscheFont style={{ padding: "5px" }}></PorscheFont>
                </div>                           
              </PFlexItem>
            </PFlexItem>
            <PFlexItem style={{ width: "20%" }}></PFlexItem>
          </PFlex>
          <PDivider color="neutral-contrast-low"></PDivider>
        </>
      )}

      { discountPercent > 9.99 && approvals.length > 0 && approvals[3].isEnabled && discount.length === 0  && nextStep === "Discount" && (
        <>
          <OrderApprovalRow
            orderId={orderId}
            discountIndex={0}
            approval={approvals[3]} 
            orderApproval={{} as ApprovalStepDTO} 
            label={"Discount"} 
            edit={edit} 
            userDetails={userDetails} 
            handleMessage={(value, msg) => handleMessage(value, msg)}
            handleAprrovalStepAssignee={(approvalStep, users, value, newApprover) => handleAssignee(approvalStep, users, value, newApprover)}
            enableNextStep={true}
            handleApproved= {(step) => handleApproved(step)}
            handleNewAssignee= {(step, value, index) => handleNewAssignee(step, value, index)}
            handleOtherSubject= {(value, stepLabel) => handleOtherSubject(value, stepLabel)}
            refreshOnSuccess= {refresh}
            handleClose={(reload) => handleClose(reload)}
          />
          <PDivider color="neutral-contrast-low"></PDivider>
          <OrderApprovalRow
            orderId={orderId}
            discountIndex={1}
            approval={approvals[3]} 
            orderApproval={{} as ApprovalStepDTO} 
            label={""} 
            edit={edit} 
            userDetails={userDetails} 
            handleMessage={(value, msg) => handleMessage(value, msg)}
            handleAprrovalStepAssignee={(approvalStep, users, value, newApprover) => handleAssignee(approvalStep, users, value, newApprover)}
            enableNextStep={true}
            handleApproved= {(step) => handleApproved(step)}
            handleNewAssignee= {(step, value, index) => handleNewAssignee(step, value, index)}
            handleOtherSubject= {(value, stepLabel) => handleOtherSubject(value, stepLabel)}
            refreshOnSuccess= {refresh}
            handleClose={(reload) => handleClose(reload)}
          />
          <PDivider color="neutral-contrast-low"></PDivider>
        </>
      )}

      {discountPercent > 9.99 && discount.length > 0 && discount[0].isEnabled && (
        <>
          <OrderApprovalRow
            orderId={orderId}
            discountIndex={0}
            approval={approvals[3]} 
            orderApproval={discount[0]} 
            label={"Discount"} 
            edit={edit} 
            userDetails={userDetails} 
            handleMessage={(value, msg) => handleMessage(value, msg)}
            handleAprrovalStepAssignee={(approvalStep, users, value, newApprover) => handleAssignee(approvalStep, users, value, newApprover)}
            enableNextStep={false}
            handleApproved= {(step) => handleApproved(step)}
            handleNewAssignee= {(step, value, index) => handleNewAssignee(step, value, index)}
            handleOtherSubject= {(value, stepLabel) => handleOtherSubject(value, stepLabel)}
            refreshOnSuccess= {refresh}
            handleClose={(reload) => handleClose(reload)}
          />
          <PDivider color="neutral-contrast-low"></PDivider>
          <OrderApprovalRow
            orderId={orderId}
            discountIndex={1}
            approval={approvals[3]} 
            orderApproval={discount[1]} 
            label={""} 
            edit={edit} 
            userDetails={userDetails} 
            handleMessage={(value, msg) => handleMessage(value, msg)}
            handleAprrovalStepAssignee={(approvalStep, users, value, newApprover) => handleAssignee(approvalStep, users, value, newApprover)}
            enableNextStep={false}
            handleApproved= {(step) => handleApproved(step)}
            handleNewAssignee= {(step, value, index) => handleNewAssignee(step, value, index)}
            handleOtherSubject= {(value, stepLabel) => handleOtherSubject(value, stepLabel)}
            refreshOnSuccess= {refresh}
            handleClose={(reload) => handleClose(reload)}
          />
          <PDivider color="neutral-contrast-low"></PDivider>
        </>
      )}

      {/* { others.length === 0 && ( */}
      { approvals.length > 0 && approvals[4].isEnabled && approvals[4].approvers && approvals[4].approvers.length > 0 && others.length === 0  && (
        <>
          <OrderApprovalRow
            orderId={orderId}
            discountIndex={0}
            approval={approvals[4]} 
            orderApproval={{} as ApprovalStepDTO} 
            label={"Others (Optional)"} 
            edit={edit} 
            userDetails={userDetails} 
            handleMessage={(value, msg) => handleMessage(value, msg)}
            handleAprrovalStepAssignee={(approvalStep, users, value, newApprover) => handleAssignee(approvalStep, users, value, newApprover)}
            enableNextStep={false}
            handleApproved= {(step) => handleApproved(step)}
            handleNewAssignee= {(step, value, index) => handleNewAssignee(step, value, index)}
            handleOtherSubject= {(value, stepLabel) => handleOtherSubject(value, stepLabel)}
            refreshOnSuccess= {refresh}
            handleClose={(reload) => handleClose(reload)}
          />
          <PDivider color="neutral-contrast-low"></PDivider>
        </>
      )}

    {/* { others.length === 0 && (approvals.length > 0 && approvals.filter( x => x.approvalStep === "Others")[0].isEnabled === true)  && (
        <>
        <OrderApprovalRow
          orderId={orderId}
          discountIndex={0}
          approval={approvals[4]} 
          orderApproval={{} as ApprovalStepDTO} 
          label={"Others (Optional)"} 
          edit={edit} 
          userDetails={userDetails} 
          handleMessage={(value, msg) => handleMessage(value, msg)}
          handleAprrovalStepAssignee={(approvalStep, users, value) => handleAssignee(approvalStep, users, value)}
          enableNextStep={true}
          handleApproved= {(step) => handleApproved(step)}
          handleNewAssignee= {(step, value, index) => handleNewAssignee(step, value, index)}
        />
        <PDivider color="neutral-contrast-low"></PDivider>
      </>
      )} */}

      

      { approvals.length > 0 && approvals[4].isEnabled && approvals[4].approvers && approvals[4].approvers.length > 0 && others.length > 0 && others[0].isEnabled && (
        <>
          <OrderApprovalRow
            orderId={orderId} 
            discountIndex={0}
            approval={approvals[4]} 
            orderApproval={others[0]} 
            label={"Others (Optional)"} 
            edit={edit} 
            userDetails={userDetails} 
            handleMessage={(value, msg) => handleMessage(value, msg)}
            handleAprrovalStepAssignee={(approvalStep, users, value, newApprover) => handleAssignee(approvalStep, users, value, newApprover)}
            enableNextStep={false}
            handleApproved= {(step) => handleApproved(step)}
            handleNewAssignee= {(step, value, index) => handleNewAssignee(step, value, index)}
            handleOtherSubject= {(value, stepLabel) => handleOtherSubject(value, stepLabel)}
            refreshOnSuccess= {refresh}
            handleClose={(reload) => handleClose(reload)}
          />
          <PDivider color="neutral-contrast-low"></PDivider>
        </>
      )}

      {/** Also need to check for order creator  */}
      { (userDetails?.id === order?.orderCreator?.id || 
        (userDetails?.canEditAllOrders &&
          orderApprovals?.filter(x => x.approver.id === userDetails.id).length === 0
        )) &&(
        <PFlex justifyContent={"flex-end"} className="mt16">  
          <PFlexItem alignSelf={"flex-end"}>
            <PButtonGroup>
              {/** Restrict edit option if order is approved.*/}
              { !edit && !(order.status === "Approved" || order.status === "CommissionsApproved") && (
                <PButton icon='edit' onClick={() => handleEdit()} >{t("Edit")}</PButton>
              )}

              { edit && (
                <>
                  <PButton variant="tertiary" icon='close' onClick={() => setEdit(!edit)}>{t("Cancel")}</PButton>
                  <PButton icon='save' onClick={() => handleSave()} disabled={isValid ? false : true} >{t("Save")}</PButton>
                </>
              )}
            </PButtonGroup>
          </PFlexItem>
        </PFlex>
      )}

      { enableNextStep && (
        <PFlex justifyContent={"flex-end"} className="mt16">
        <PFlexItem alignSelf={"flex-end"}>
          <PButtonGroup>
            <PButton icon='save' onClick={() => handleSubmit()} disabled={isNextStepValid ? false : true}>{t("Submit")}</PButton>
          </PButtonGroup>
        </PFlexItem>
      </PFlex>
      )}

      <div className="flex30 pt20">
        <Snackbar
            open={saveSuccess}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            autoHideDuration={2000}
            onClose={handleSuccessClose}
            >
            <Alert severity="success" sx={{ width: "100%" }}>
                {t(saveSuccessMsg)}
            </Alert>
        </Snackbar>
        <Snackbar
            open={isError}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            autoHideDuration={2000}
            onClose={handleErrorClose}
            >
            <Alert severity="error" sx={{ width: "100%" }}>
                { t("Error while updating!")}
            </Alert>
        </Snackbar>
        <CustomSnakebar alertType={showAlert} alertMessage={alertMessage} handleClose={()=>handleCustomSnakeClose()}/>

      </div>
      </div>
    </>
  );

};
