export const userProfile = {
  imageUrl: "",
  firstName: "Meisam",
  lastName: "Azadeh",
  position: "Head of Dispatch",
};

export const services = [
  {
    serviceName: "Mounting",
    deliveryLocation: "Porsche Center",
    priceInternal: 100.00,
    sellingPriceNet: 123.53,
    customerShare: 0.00,
    discount: 0.00,
    commission: 0.00,
  },
  {
    serviceName: "Mounting",
    deliveryLocation: "Factory",
    priceInternal: 330.00,
    sellingPriceNet: 330.00,
    customerShare: 0.00,
    discount: 0.00,
    commission: 0.00
  },
  {
    serviceName: "Storage",
    deliveryLocation: "",
    priceInternal: 69.75,
    sellingPriceNet: 69.75,
    customerShare: 0.00,
    discount: 0.00,
    commission: 0.00,
  },
  {
    serviceName: "Shipment",
    deliveryLocation: "",
    priceInternal: 100.00,
    sellingPriceNet: 100.00,
    customerShare: 0.00,
    discount: 0.00,
    commission: 0.00,
  },
  {
    serviceName: "Pick up by customer",
    deliveryLocation: "",
    priceInternal: 0.00,
    sellingPriceNet: 0.00,
    customerShare: 0.00,
    discount: 0.00,
    commission: 0.00,
  }
];

export const upsaleTire = [
  {
    tireName: "CWT Tayacan Turbo S 20”",
    tireId: 3910203,
    priceInternal: 4936.50,
    sellingPriceNet: 5485.00,
    customerShare: 5300.00,
    discount: 185.00,
    commission: 137.13,
    services: [
      {
        serviceName: "Mounting",
        deliveryLocation: "Porsche Center",
        priceInternal: 100.00,
        sellingPriceNet: 123.53,
        customerShare: 0.00,
        discount: 0.00,
        commission: 0.00,
      },
      {
        serviceName: "Storage",
        deliveryLocation: "",
        priceInternal: 69.75,
        sellingPriceNet: 69.75,
        customerShare: 0.00,
        discount: 0.00,
        commission: 0.00,
      },
    ]
  },
  {
    tireName: "CWT 911 Turbo S 20”/21”",
    tireId: 3910204,
    priceInternal: 6705.50,
    sellingPriceNet: 7450.00,
    customerShare: 0.00,
    discount: 0.00,
    commission: 0.00,
    services: [
      {
        serviceName: "Shipment",
        deliveryLocation: "",
        priceInternal: 100.00,
        sellingPriceNet: 100.00,
        customerShare: 0.00,
        discount: 0.00,
        commission: 0.00,
      },
    ]
  }
]

export const serviceNames = [
  "Mounting",
  "Storage",
  "Shipment",
  "Pick-up by customer"
]