import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PCheckboxWrapper, PDivider, PFlex, PFlexItem, PText, PTextFieldWrapper
} from '@porsche-design-system/components-react';

import { PorscheFont } from '../../../assets/styles/CustomStyles';
import { GetRvm } from '../../../interfaces/Variables';
import {
  formatCurrencyToDecimal, formatCurrencyWithNoUnit, onBlurPercentageFormatter, validateDuration,
  validatePercentage
} from '../../../mock/helper';
import { type } from '../../../services/Constants';
import { dcsgetRvm } from '../../../services/data/Helpers';
import ScrollToTop from '../../common/ScrollToTop';

interface Props {
  edit: boolean;
  save: boolean;
  cancel: boolean;
  changedMode: string;
  handleRvmPost: (paw: Array<GetRvm>) => void;
  modified: () => void;
  showHeader:() => void;
  removeHeader:()=> void; 
  setShowSaveButton: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Rvm = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const { edit, save, cancel, handleRvmPost, modified,showHeader,removeHeader, setShowSaveButton } = props;
  const [rvmData, setRvmData] = useState<Array<GetRvm>>([]);
  const [ reload, setReload ] = useState("");
  const [errorState, setErrorState] = useState<Record<string,boolean>>()


  useEffect(() => {
    const init = async () => {
      const apiResponse = await dcsgetRvm();
      if (apiResponse?.status === type.SUCCESS) {
        setRvmData(apiResponse?.response);
      }
    };
    init();
  }, []);

  useEffect(()=>{
    if(errorState){
    const isErrorPresent  = Object.values(errorState).includes(true)
    setShowSaveButton(!isErrorPresent)
  }
  },[errorState])
  
  const checkRange = (value: string) => {
    const valuNumber = parseFloat(value);
    if (valuNumber < 0.00) {
      return false;
    } else if (valuNumber > 1000000.00) {
      return false;
    } else {
      return true;
    }
  };

  const REGEX_INPUT_EXP = /^[0-9]\d{0,6}((\,\d{0,2})?)$/;

  const validateInput = (value: string): boolean => {

    if (REGEX_INPUT_EXP.test(value)) {
      if (value.includes(",")) {
        return checkRange(value.replace(",", "."));
      } else {
        return checkRange(value);
      }
    } else {
      if (value === "") {
        return false;
      }

      if (/\,\d{3}$/g.test(value)) {
        return checkRange(value.replace(",", "."));
      }

      return false;
    }
  };

  const saveRrp = (value: string, id: number) => {
    rvmData.map((rvm) => {
      if(rvm.id === id) {
        rvm.rrp = formatCurrencyToDecimal(value);
      }
    })

    setRvmData(rvmData);
    modified();
    handleRvmPost(rvmData);
  }

  const saveFrom = (value: string, id: number) => {
    rvmData.map((rvm) => {
      if(rvm.id === id) {
        rvm.from = parseInt(value);
      }
    })

    setRvmData(rvmData);
    modified();
    handleRvmPost(rvmData);
  }

  const saveTo = (value: string, id: number) => {
    rvmData.map((rvm) => {
      if(rvm.id === id) {
        rvm.to = parseInt(value);
      }
    })

    setRvmData(rvmData);
    modified();
    handleRvmPost(rvmData);
  }

  const validateRrp = (value: string, id: number) => {
    let valid = true;
    const validRrp = validatePercentage(value);

    if (!validRrp) valid = false;

    let dom = document.getElementById(`rrp${id}`);
    const rrpTextWrapperId = dom?.id as string

    if (valid) {
      saveRrp(value, id);
      modified();
      if(dom) {
        dom.setAttribute("state", "none");
        setErrorState((prev)=>{
          return {...prev, [rrpTextWrapperId]:false}
        })
      }
    } else {
      if(dom) {
        dom.setAttribute("state", "error");
        setErrorState((prev)=>{
          return {...prev, [rrpTextWrapperId]:true}
        })
        //dom.setAttribute("message", "Please enter a valid value");
      }
    }
  }

  const handleFrom = (value:string, id:number) => {
    let valid = true;
    if(!validateDuration(value)) valid = false;

    let toDom = document.getElementById(`ito${id}`);
    if(toDom) {
      const toValue = toDom.getAttribute("value");

      const to = toValue ? parseInt(toValue) : 0;
      const from = parseInt(value);
      modified();

      valid = from > to ? false : true;
    } else {
      valid = false;
    }

    let fromDom = document.getElementById(`from${id}`);
    const fromTextWrapperId = fromDom?.id as string
    if (valid && validateDuration(value)) {
      if (fromDom) fromDom.setAttribute("state", "none");
      setErrorState((prev)=>{
        return {...prev, [fromTextWrapperId]:false}
      })
      saveFrom(value, id);
    } else {

      if (fromDom) fromDom.setAttribute("state", "error");
      setErrorState((prev)=>{
        return {...prev, [fromTextWrapperId]:true}
      })
    }
  }

  const handleTo = (value:string, id:number) => {
    let valid = validateDuration(value);

    let toDom = document.getElementById(`to${id}`);
    const toTextWrapperId = toDom?.id as string


    if (!valid) {
      if (toDom) toDom.setAttribute("state", "error");
      setErrorState((prev)=>{
        return {...prev, [toTextWrapperId]:true}
      })
      return false;
    }

    let fromDom = document.getElementById(`ifrom${id}`);
    if(fromDom) {
      const fromValue = fromDom.getAttribute("value");
      modified();

      const from = fromValue ? parseInt(fromValue) : 0;
      const to = parseInt(value);

      valid = from > to ? false : true;
    } else {
      valid = false;
    }

    if (valid) {
      if (toDom) toDom.setAttribute("state", "none");
      saveTo(value, id);
      setErrorState((prev)=>{
        return {...prev, [toTextWrapperId]:false}
      })
    } else {
      if (toDom) toDom.setAttribute("state", "error");
      setErrorState((prev)=>{
        return {...prev, [toTextWrapperId]:true}
      })
    }
  }

  const handleGrossProfit = (value: boolean, id: number) => {
    rvmData.map((rvm) => {
      if(rvm.id === id) {
        rvm.grossProfit = value;
      }
    })

    setRvmData(rvmData);
    modified();
    handleRvmPost(rvmData);
    setReload(new Date().toLocaleString());
  }

  useEffect(() => {

  }, [reload])

  return (
    <>
    <div className="container-variable" style={{ paddingTop: "30px" }}>
      <ScrollToTop />
      <PText weight="bold" size="medium">
        <PorscheFont>{t('RVM')}</PorscheFont>
      </PText>
      <div className='mt12' style={{ padding: "0 15px" }}>
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead style={{'borderBottom': "1px solid black", 'margin': "15px 0"}}>
            <tr>
              <th style={{ padding: '10px', fontSize: '16px', fontWeight: '700' }}>
                <PorscheFont>{t('Residual value model')}</PorscheFont>
              </th>
              {rvmData.map((item) => (
                <th key={item.id} style={{ padding: '10px', fontSize: '16px', fontWeight: '700', textAlign: 'center' }}>
                  {item.seriesDTO.seriesName}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ padding: '10px', fontSize: '16px', fontWeight: '400' }}>
                <PorscheFont>{t('% of RRP (net)')}</PorscheFont>
              </td>
              {rvmData.map((item) => (
                <td key={item.id} style={{ padding: '10px' }}>
                  <PTextFieldWrapper id={`rrp${item.id}`} unit="%" unitPosition="suffix">
                    <input
                      name={`rrp${item.id}`}
                      type="text"
                      defaultValue={item.rrp ? formatCurrencyWithNoUnit(item.rrp) : '0,00'}
                      onChange={(e) => validateRrp(e.target.value, item.id)}
                      onBlur={(e) => onBlurPercentageFormatter(e)}
                      disabled={!edit}
                      style={{ width: '100%' }}
                    />
                  </PTextFieldWrapper>
                </td>
              ))}
            </tr>
            <tr>
              <td style={{ padding: '10px', fontSize: '16px', fontWeight: '400' }}>
                <PorscheFont>{t('Duration (months)')}</PorscheFont>
              </td>
              {rvmData.map((item) => (
                <td key={item.id} style={{ padding: '10px' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <PTextFieldWrapper id={`from${item.id}`}>
                      <input
                        id={`ifrom${item.id}`}
                        name={`from${item.id}`}
                        type="number"
                        defaultValue={item.from}
                        onChange={(e) => handleFrom(e.target.value, item.id)}
                        disabled={!edit}
                        style={{ width: '70px' }}
                      />
                    </PTextFieldWrapper>
                    <span style={{ margin: '0 15px' }}>-</span>
                    <PTextFieldWrapper id={`to${item.id}`}>
                      <input
                        id={`ito${item.id}`}
                        name={`to${item.id}`}
                        type="number"
                        defaultValue={item.to}
                        onChange={(e) => handleTo(e.target.value, item.id)}
                        disabled={!edit}
                        style={{ width: '70px' }}
                      />
                    </PTextFieldWrapper>
                  </div>
                </td>
              ))}
            </tr>
            <tr>
              <td style={{ padding: '10px', fontSize: '16px', fontWeight: '400' }}>
                <PorscheFont>{t('Reduces gross profit')}</PorscheFont>
              </td>
              {rvmData.map((item) => (
                <td key={item.id} style={{ padding: '10px', textAlign: 'center' }}>
                  <PCheckboxWrapper label={`gp${item.id}`} hideLabel={true} style={{ marginTop: '10px' }}>
                    <input
                      type="checkbox"
                      name={`gp${item.id}`}
                      checked={item.grossProfit}
                      onClick={(e) => handleGrossProfit(!item.grossProfit, item.id)}
                      disabled={!edit}
                    />
                  </PCheckboxWrapper>
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    </>
  );
};
