import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { OrderContext } from "../../../contexts/OrderContext";

import { Alert, Snackbar, Stack } from "@mui/material";
import {
  PButton,
  PButtonGroup,
  PButtonPure,
  PDivider,
  PFlex,
  PFlexItem,
  PIcon,
  PModal,
  PSelectWrapper,
  PTextareaWrapper,
} from "@porsche-design-system/components-react";
import { PorscheFont } from "../../../assets/styles/CustomStyles";
import {
  dcsgetSubsequentApprovalConfig,
    dcspostSubequentApproval,
    getActiveUsers,
  getSubsequentApprovalConfig,
  postSubequentApproval,
} from "../../../services/data/Helpers";
import {  UserDetailsDTO } from "../../../interfaces/User";
import { SubsequentLogBook } from "./SubsequentLogBook";
import { UserDetails } from "../../../interfaces/UserDetails";
import { User } from "../../../interfaces/adminApproval/ApprovalConfiguration";
import { type } from "../../../services/Constants";
import { OrderDTO } from "../../../interfaces/Order";

export interface subsqCommApprovalType {
  id: number;
  ppnName: string;
  comment: string;
  commissionRank: string;
  action: string;
  approvedOn: string;
  isEnable: boolean;
  logs: [
    {
      actionDate: string;
      user: string;
      action: string;
      comment: string;
    }
  ];
}
export interface SubsequentApprovalConfigResponse {
  commissionApprovals: subsqCommApprovalType[];
  createdBy: number;
}
export interface ApproverUser{
    id:number,
    ppnName:string,
    title:string
}
interface Props {
  orderId: number;
  category: string;
  handleClose: (reload: boolean) => void;
}

export const SubsequentApprovalTab = (props: Props) => {
  const { orderId, category, handleClose } = props;
  const orderContext = useContext(OrderContext);
  const { t } = useTranslation();
  const [toggleApproval, setToggleApproval] = useState(false);
  const [subsqConfig, setSubsConfig] = useState<SubsequentApprovalConfigResponse>();
  const [selectedValue, setSelectedValue] = useState(0);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [nextRank, setNextRank] = useState("");
  const [isLogBookOpen, setIsLogBookOpen] = useState<boolean>(false);
  const [inputComment, setInputComment] = useState("")
  const [approvers, setOriginUsers] = useState<Array<ApproverUser>|null>()
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [orderDetails, setOrderDetails] = useState<OrderDTO>({} as OrderDTO);


  let userDetails = {} as UserDetailsDTO;
  const sessionUser = sessionStorage.getItem("userDetails");
  if (sessionUser) {
    userDetails = JSON.parse(sessionUser);
  }

  const handleLogbook = () => {
    setIsLogBookOpen(!isLogBookOpen);
  };

  const handleLogbookClose = () => {
    setIsLogBookOpen(false);
  };

  const handleApproval = () => {
    setToggleApproval(!toggleApproval);
    const approvarsData =  localStorage.getItem('commissionApprovers')
    if(approvarsData ){
        setOriginUsers(JSON.parse(approvarsData))
    }
  };
  const order = orderContext.getCtxOrderDetails();

  useEffect(() => {

    const ord = orderContext.getCtxOrderDetails();
    if(ord?.orderCreator?.id){

      setOrderDetails(ord);
    }
    const init = async () => {
      if(order?.id){
        const apiResponse = await dcsgetSubsequentApprovalConfig(order.id)
        if (apiResponse.status === type.SUCCESS) {
          const approvalConfig = apiResponse.response as SubsequentApprovalConfigResponse;
            setSubsConfig(approvalConfig);
        }
      }
     
    };
    
    init();
    const approvarsData =  localStorage.getItem('commissionApprovers')
    if(approvarsData ){
        setOriginUsers(JSON.parse(approvarsData))
    }
  }, []);

  const handleApprovedComment = (e:React.ChangeEvent<HTMLTextAreaElement>)=>{
    setInputComment(e.target.value)
  }

  const payload = { ...subsqConfig } as SubsequentApprovalConfigResponse;

  const handleSuccessClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return false;
    }
    setSaveSuccess(false);
  };
  const handleSaveApproval = (item: string) => {
   
    const selecteItem =
      payload &&
      payload.commissionApprovals &&
      payload.commissionApprovals.filter(
        (data) => item === data.commissionRank
      );
    const enabledFields =
      payload &&
      payload.commissionApprovals &&
      payload.commissionApprovals.filter((data) => data.isEnable);
    const currentIndex = enabledFields?.findIndex(
      (data) => data.commissionRank === item
    );

    if (selecteItem && selecteItem?.length > 0) {
      const updatedItem = selecteItem[0];
      if (updatedItem) {
        updatedItem.action = "Approved";
        updatedItem.approvedOn = new Date().toUTCString();
        updatedItem.logs.push({
          actionDate: new Date().toUTCString(),
          user: updatedItem.ppnName,
          action: "Approved",
          comment: inputComment,
        });
      }
    }

    if (enabledFields.length - (currentIndex + 1) > 0) {
      setNextRank(enabledFields[currentIndex + 1].commissionRank);
    } else {
      setSubmitEnabled(true);
    }

    setSubsConfig(payload);
    setInputComment("")
  };

  const selectorHandler = (value: number, selectedvalue:subsqCommApprovalType) => {
    setSelectedValue(value);
    setSubmitEnabled(true);
    const selectedCompleteData = selectedvalue
    
    let filteredppn:ApproverUser[] = []
    if(approvers)
    filteredppn =  approvers.filter((item) => item.id === value);

    selectedCompleteData.action = "Assigned";
    selectedCompleteData.id = value;
    selectedCompleteData.ppnName = filteredppn[0].ppnName;
    userDetails?.ppnName &&
      selectedCompleteData.logs.push({
        actionDate: new Date().toUTCString(),
        user: userDetails.ppnName,
        action: "Assigned",
        comment: inputComment,
      });
  };

  const handleSubmit = async () => {
    const apiResponse = await dcspostSubequentApproval(payload, order.id);
    if(apiResponse.status===type.SUCCESS) {
      setSaveSuccess(true);    }
    window.location.reload();
    localStorage.removeItem("commissionApprovers")
  };

  
  return (
    <div style={{ marginLeft: "-20px", marginTop: "-32px" }}>
      <PDivider color="neutral-contrast-low"></PDivider>
      <br></br>
      <PFlex justifyContent={"space-between"}>
        <PFlexItem style={{ width: "30%" }} className="sub-headding">
          <PorscheFont>{t("Approval steps")}</PorscheFont>
        </PFlexItem>
        <PFlexItem style={{ width: "100%" }} className="sub-headding">
          <PorscheFont>{t("User assigned")}</PorscheFont>
        </PFlexItem>
        <PFlexItem style={{ width: "20%" }}></PFlexItem>
      </PFlex>
      <br></br>
      <PDivider color="neutral-contrast-high"></PDivider>

      {subsqConfig?.commissionApprovals.map((item) => {
        return (
          <>
            {item.isEnable ? (
              <>
                <PFlex justifyContent={"space-between"}>
                  <PFlexItem style={{ width: "30%" }}>
                    <PFlex>
                      <PFlexItem>
                        <PDivider
                          orientation="vertical"
                          style={{
                            marginTop: "10px",
                            width: "5px",
                            height: "50px",
                            backgroundColor: `${
                              item.action === "Approved" ? "#1E7A53" : "#C8CACB"
                            }`,
                          }}
                        />
                      </PFlexItem>
                      &nbsp;&nbsp;
                      <PFlexItem style={{ marginTop: "20px" }}>
                        {item.commissionRank === "1st" && (
                          <img
                            src={require("../../../assets/images/approvals/1stordercheck.png")}
                          ></img>
                        )}
                        {item.commissionRank === "2nd" && (
                          <img
                            src={require("../../../assets/images/approvals/2ndordercheck.png")}
                          ></img>
                        )}
                        {item.commissionRank === "Fixed" && (
                          <img
                            src={require("../../../assets/images/approvals/FinalCommission.png")}
                          ></img>
                        )}
                      </PFlexItem>
                      &nbsp;&nbsp;
                      <PFlexItem style={{ marginTop: "20px" }}>
                        <PorscheFont className="tn-text-14-600">
                          {item.commissionRank === "1st" &&
                            t("1st commission check")}
                          {item.commissionRank === "2nd" &&
                            t("2nd commission check")}
                          {item.commissionRank === "Fixed" &&
                            t("Fixed commission")}
                          <span style={{ color: "#D5001B" }}>{" *"}</span>
                        </PorscheFont>
                      </PFlexItem>
                    </PFlex>
                  </PFlexItem>
                  <PFlexItem
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      width: "100%",
                    }}
                  >
                    <PFlexItem>
                      {item.action === "Approved" && (
                        <div
                          className="user-assigned tn-text-14-600"
                          style={{ width: "40%" }}
                        >
                          <PorscheFont style={{ padding: "5px" }}>
                            {item.ppnName}
                          </PorscheFont>
                        </div>
                      )}
                      {item.action === "Pending" &&
                        item.commissionRank !== nextRank && (
                          <div
                            className="user-assigned tn-text-14-600"
                            style={{ width: "40%" }}
                          >
                            <PorscheFont
                              style={{ padding: "5px" }}
                            ></PorscheFont>
                          </div>
                        )}
                      {item.action === "Assigned" &&
                        item.commissionRank !== nextRank && (
                          <div
                            className="user-assigned tn-text-14-600"
                            style={{ width: "40%" }}
                          >
                            <PorscheFont style={{ padding: "5px" }}>
                              {item.ppnName}
                            </PorscheFont>
                          </div>
                        )}

                      {item.commissionRank === nextRank && (
                        <PSelectWrapper
                          hideLabel={false}
                          style={{ width: "40%" }}
                        >
                          <select
                            name={`1st commission check`}
                            defaultValue={0}
                            onChange={(e) =>
                              selectorHandler(Number(e.target.value), item)
                            }
                          >
                            <option value={0} selected={false}>
                              {t("Assign to user")}
                            </option>
                            {approvers &&
                              approvers.map((user, index) => {
                                if (orderDetails?.orderCreator?.id !== user.id) {
                                  return (
                                    <option key={index} value={user.id}>
                                      {user.ppnName}
                                    </option>
                                  );
                                }
                              })}
                          </select>
                        </PSelectWrapper>
                      )}
                    </PFlexItem>
                  </PFlexItem>
                  <PFlexItem
                    style={{
                      marginTop: "10px",
                      width: "20%",
                      marginBottom: "10px",
                    }}
                  >
                    <PFlex justifyContent={"flex-end"}>
                      <PFlexItem alignSelf={"center"}>
                        {item.action === "Approved" && (
                          <div>
                            <Stack
                              direction="row"
                              spacing={1}
                              style={{ alignItems: "flex-end" }}
                            >
                              <PorscheFont
                                className="approved"
                                style={{ fontSize: "16px" }}
                              >
                                {t("Approved")}
                              </PorscheFont>
                              {/* Here redirects to Draft check - Logbook based on condition [approved,reassigned,reject] */}
                              <PButtonPure
                                hideLabel={true}
                                onClick={() => handleLogbook()}
                              ></PButtonPure>
                            </Stack>
                          </div>
                        )}

                        {item.action === "Assigned" &&
                          userDetails?.id === item.id &&
                          item.commissionRank !== nextRank && (
                            <div
                              className="draft-button"
                              style={
                                toggleApproval
                                  ? { background: "#1E7A53" }
                                  : { background: "#FFFFFF" }
                              }
                            >
                              <PIcon
                                name="success"
                                color={`${
                                  toggleApproval
                                    ? "neutral-contrast-low"
                                    : "notification-success"
                                }`}
                                aria={{ "aria-label": "Success icon" }}
                                onClick={handleApproval}
                              />
                              {toggleApproval &&
                                item.commissionRank !== nextRank && (
                                  <div
                                    className="approved-container"
                                    style={{
                                      height: "350px",
                                      width: "403px",
                                      marginTop: "8px",
                                      marginLeft: "-372px",
                                    }}
                                  >
                                    <h4>
                                      <PorscheFont className="tn-text-16-700">
                                        {" "}
                                        {t("Approve")}
                                      </PorscheFont>
                                    </h4>
                                    <br></br>
                                    <div className="container">
                                      <PTextareaWrapper
                                        label={`${t("Comment")}`}
                                      >
                                        <textarea
                                          aria-autocomplete="none"
                                          autoComplete="off"
                                          onChange={(e) =>
                                            handleApprovedComment(e)
                                          }
                                          maxLength={500}
                                          name="approvedComment"
                                        />
                                      </PTextareaWrapper>
                                    </div>
                                    <br></br>
                                    <PButton
                                      icon="success"
                                      onClick={() => {
                                        handleSaveApproval(item.commissionRank);
                                      }}
                                    >
                                      {t("Approve")}
                                    </PButton>
                                    &nbsp;&nbsp;&nbsp;
                                    <PButton
                                      onClick={handleApproval}
                                      type="button"
                                      variant="tertiary"
                                      icon="close"
                                    >
                                      {t("Cancel")}
                                    </PButton>
                                  </div>
                                )}
                            </div>
                          )}
                      </PFlexItem>
                    </PFlex>
                  </PFlexItem>
                </PFlex>
                <PModal open={isLogBookOpen} onClose={handleLogbookClose}>
                  <SubsequentLogBook
                    handleClose={handleLogbookClose}
                    commissioningData={item}
                  />
                </PModal>
              </>
            ) : null}
          </>
        );
      })}

      <PFlex justifyContent={"flex-end"} className="mt16">
        <PFlexItem alignSelf={"flex-end"}>
          <PButtonGroup>
            <PButton
              icon="save"
              onClick={() => handleSubmit()}
              disabled={submitEnabled ? false : true}
            >
              {t("Submit")}
            </PButton>
          </PButtonGroup>
        </PFlexItem>
      </PFlex>
      <div className="flex30 pt20">
          <Snackbar
              open={saveSuccess}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              autoHideDuration={2000}
              onClose={handleSuccessClose}
              >
              <Alert severity="success" sx={{ width: "100%" }}>
                  {t("Submitted successfully")}
              </Alert>
          </Snackbar>
        </div>
    </div>
  );
};

export default SubsequentApprovalTab;
