import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PMainHeader, PorscheFont } from '../../assets/styles/CustomStyles';
import { NavSideBar } from '../../components/NavSideBar';
import HistoryTable from '../../components/administration/changeHistory/HistoryTable';

export const ChangeHistoryPage = (): JSX.Element => {
  const { t } = useTranslation();
  const [ showSaveButton, setShowSaveButton ] = useState<boolean>(false);

  return (
    <div className="custombody"> 
      <div className="navbar" style={{ position: 'fixed', top: '85px',zIndex:"9999" }}>
        <NavSideBar disabled={!showSaveButton} main="Administration" subPage="Change History"/> 
      </div>
      <div className="main"style={{ width: '100%',  backgroundColor: '#F2F2F2', padding: '100px 0px 10px 71px'}}>
        <div className="admin-header" style={{position:'fixed',marginTop:"-25px",padding:"40px 40px 16px 40px",marginLeft:"-10px" ,backgroundColor: '#F2F2F2', zIndex:"999"}}>   
         <div className="main-header-left">
              <PMainHeader>
                <PorscheFont data-testid="page-variables" className="main-header-text">
                  {t("Change History")}
                </PorscheFont>
                <PorscheFont className="main-header-description">
                  {t("Below you can track the changes in the Administration pages.")}
                </PorscheFont>
              </PMainHeader> 
          </div>
        </div>
      
        <div  style={{ margin: "16px 25px 5px 25px" }} >
         <div className="inreview" style={{marginTop:"96px", marginLeft:"7px"}}>
           <HistoryTable/>
         </div>
        </div>
      </div>
    </div> 
  );
};
