import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import {
  PButton,
  PCheckboxWrapper, PDivider, PFlex,
  PFlexItem, PGrid, PGridItem, PIcon,
  PTextareaWrapper, PTextFieldWrapper
} from '@porsche-design-system/components-react';

import { PorscheFont } from '../../../assets/styles/CustomStyles';
import { OrderContext } from '../../../contexts/OrderContext';
import { OrderDTO } from '../../../interfaces/Order';
import {
  NotificationRequestDTO, RecipientNotificationResponseDTO
} from '../../../interfaces/TasksAndNotifications';
import { UserDetailsDTO } from '../../../interfaces/User';
import {
  dcsgetNotificationsByOrderId,
  dcsgetUsers,
  dcsputNotificationsData
} from '../../../services/data/Helpers';
//import { Notifications } from './Notifications';
import { type } from '../../../services/Constants';
import { NotificationRow } from './NotificationRow';

//import { TaskDetCreator } from '../TaskDetCreator';
//import { TaskRow } from './TaskRow';

interface Props {
  orderId: number,
  isDraft:boolean,
}

export const Notifications = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const { orderId,isDraft, } = props;
  
  const [showSendTo, setShowSendTo] = useState(false);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [toggle, setToggle] = useState<boolean>(true);
  const [notificationRequest, setNotificationRequest] = useState<NotificationRequestDTO>({} as NotificationRequestDTO);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [reload, setReload] = useState("");
  const [notifications, setNotifications] = useState<Array<RecipientNotificationResponseDTO>>([]);
  const [users, setUsers] = useState<Array<UserDetailsDTO>>([]);
  const [validationError, setValidationError] = useState(false);
  const orderContext = useContext(OrderContext);
  let user = {} as UserDetailsDTO;
  let loggedInUserId = 0;
  let showCreateNotification = false;
  const sessionUser = sessionStorage.getItem("userDetails");
  if(sessionUser) {
    user = JSON.parse(sessionUser) as UserDetailsDTO;
    
    loggedInUserId = user.id ? user.id : 0;
  }

  const order = orderContext.getCtxOrderDetails();

  const setShowCreateNotification = (user: UserDetailsDTO, order: OrderDTO) => {
    // if order creator allow to create task
    if(order.orderCreator && order.orderCreator.id) {
      if(order.orderCreator.id === loggedInUserId) return true;
    }

    // If user is not order creator, but has order view and order edit permission, allow
    if(user && user.id) {
      if(user.canViewAllOrders || user.canEditAllOrders) return true;
    }

    return false;
  }

  showCreateNotification = setShowCreateNotification(user, order);
  

  useEffect(() => {
    const init = async () => {
      const apiResponse = await dcsgetUsers()
      if(apiResponse.status===type.SUCCESS) {
        
        const users = apiResponse.response
        setUsers(users);
      }
    };
    init();
  }, []);

  // useEffect(() => {
  //   if (open) {
  //     notificationInit();
  //   } else {
  //     setNotificationRequest({} as NotificationRequestDTO);
  //     setValidationError(false);
  //     // setIsError(false);
  //   }
  // }, []);

  useEffect(() => {
    const init = async () => {
      const apiResponse = await dcsgetNotificationsByOrderId(orderId, isDraft)
      
      if(apiResponse.status===type.SUCCESS) {
        const notifications = apiResponse.response
        setNotifications(notifications);
      }
    };
    init();
  }, []);
  const notificationInit = async () => {
    const apiResponse = await dcsgetNotificationsByOrderId(orderId, isDraft)

    
    if(apiResponse.status===type.SUCCESS) {
      const notifications = apiResponse.response
      setNotifications(notifications);
    }
  };

  const handleSubject = (value: string) => {
    notificationRequest.subject = value;
    setNotificationRequest(notificationRequest);
    //isValidnotificationRequest();
    //setReload(new Date().toLocaleString());
  };


  const handleCreateNotification = () => {
    if(isValidNotificationRequest()) {
      if(isDraft){
        notificationRequest.draft={ id:orderId }
      }else{
        notificationRequest.order={id:orderId}
      }
      notificationRequest.sentBy={ id:loggedInUserId};
      notificationRequest.notificationType="Manual";
      try {
        const init = async () => {   
            const apiResponse = await dcsputNotificationsData(notificationRequest)
            if (apiResponse.status===type.SUCCESS) {
              setToggle(!toggle);
              notificationInit();
              setSaveSuccess(true);
            }
        }
        init();
      } catch (e) {
          
      }
    } else {
      
      setValidationError(true);
    }
  }

  const isValidNotificationRequest = () => {
    
    if (notificationRequest.subject === undefined ||  notificationRequest.subject.length === 0) {
      setValidationError(true);
      return false;
    }

    if (notificationRequest.recipients === undefined || notificationRequest.recipients.length === 0) {
      setValidationError(true);
      return false;
    } 
    setValidationError(false);

    return true;
  }

  const handleSuccessClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return false;
    }
    setSaveSuccess(false);
  };

  const handleSendTo = (user: UserDetailsDTO) => {
    
    if (user.id) {
      if(notificationRequest.recipients === undefined) {
        notificationRequest.recipients = [
          {
            recipient : {id: user.id, name: user.ppnName },
            markAsDelete: false
          }
        ];
        setNotificationRequest(notificationRequest);
      } else {
        let index = -1;
        notificationRequest.recipients.map((note, i) => {
          if(note.recipient.id === user.id) {
            index = i;
          }
        })
  
        if(index > -1) {
          notificationRequest.recipients.splice(index, 1);
          setNotificationRequest(notificationRequest);
        } else {
          const recipient = {
            recipient : {id: user.id, name: user.ppnName },
            markAsDelete: false
          }
          notificationRequest.recipients.push(recipient);
          setNotificationRequest(notificationRequest);
        }
      }
      setReload(new Date().toLocaleString());
    }
  } 

  const handleDescription = (value: string) => {
    notificationRequest.description = value;
    setNotificationRequest(notificationRequest);
    //isValidnotificationRequest();
    //setReload(new Date().toLocaleString());
  };

  const isChecked = (userId: number) => {
    if(notificationRequest.recipients) {
      const exists = notificationRequest.recipients.filter(r => r.recipient.id === userId);
      if(exists.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  return (
    <>
      <div className="mt24">
        {toggle && showCreateNotification && (
          <PButton  
            variant="tertiary" 
            data-testid="new-task" 
            icon="plus" 
            onClick={() => setToggle(!toggle)}
          >
            <PorscheFont className="dcs-heading">{t('Create new notification')}</PorscheFont>
          </PButton>
        )}
        {!toggle && showCreateNotification && (
          <>
            <div className="mt24">
            <PGrid>
              <PGridItem size={6}>
                <PTextFieldWrapper className="dcs-heading" label={`${t("Subject")}`} >
                  <input
                    onChange={(e) => handleSubject(e.target.value)}
                    type="text"
                    maxLength={50}
                    name="subject"
                    required
                    //defaultValue={task.subject}
                  />
                </PTextFieldWrapper>
              </PGridItem>
              <PGridItem size={3}>
                <PFlex direction={"column"}>
                  <PFlexItem>
                    <PorscheFont className="dcs-heading">{t('Send to')} <span style={{color: "red"}}>*</span></PorscheFont>
                  </PFlexItem>
                  <PFlexItem>
                    <div className="send-to" onClick={() => setShowSendTo(!showSendTo)} >
                      <PFlex>
                        <PFlexItem style={{width:"90%"}} >
                          { notificationRequest.recipients &&
                            notificationRequest.recipients.length === 1 && (
                              <div style={{marginTop: "12px", marginLeft: "8px"}}>
                                <PorscheFont className="dcs-title">{notificationRequest.recipients[0].recipient.name}</PorscheFont>
                              </div>
                              
                            )
                          }
                          { notificationRequest.recipients &&
                            notificationRequest.recipients.length > 1 && (
                              <PorscheFont className="dcs-title mt12" >{notificationRequest.recipients[0].recipient.name}{" +"}{notificationRequest.recipients.length - 1}</PorscheFont>
                            )
                          }
                        </PFlexItem>
                        <PFlexItem style={{width:"10%"}}>
                          <PIcon name={showSendTo ? "arrow-head-up" : "arrow-head-down"} className="mt8" />
                        </PFlexItem>
                      </PFlex>
                    </div>
                    { showSendTo && (
                      <div className='send-to-dropdown'>
                        <ul>
                          { users.map((user,i)=>{
                              return (   
                                <>
                                  <li key={`${i}`}>
                                  <PCheckboxWrapper label={user.ppnName} hideLabel={false}>
                                    <input 
                                      key={`idealer${i}`}
                                      type="checkbox"  
                                      checked={isChecked(user.id ? user.id : 0) ? true : false}
                                      onChange={(e) => handleSendTo(user)}
                                      //disabled={edit ? false : true}
                                    />
                                  </PCheckboxWrapper>
                                  </li>  
                                </>
                              );
                            })
                          }
                        </ul>
                      </div>
                    )}
                  </PFlexItem>
                </PFlex>
                
              </PGridItem>
            </PGrid>
            <PGrid className="mt10" >
              <PGridItem size={12}>
                <PTextareaWrapper label={`${t("Description (Optional)")}`} >
                <textarea
                  name="description"
                  maxLength={500}
                  onChange={(e) => handleDescription(e.target.value)}
                >
                    {/* {task.description} */} 
                </textarea>
                </PTextareaWrapper>
              </PGridItem>
            </PGrid>
            { validationError && (
              <div className='mt10'>
                <PorscheFont><span style={{color: "red"}}>{t("Please enter valid values.")}</span></PorscheFont>
              </div>
            )}
            <div className='mt10'>
              <PButton onClick={handleCreateNotification}>{t('Create notification')}</PButton>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <PButton
                onClick={() => { 
                  setToggle(!toggle);
                  
                  setValidationError(false);
                }}
                type="button"
                variant="tertiary"
                icon="close"
                >
                {t('Cancel')}
              </PButton>
            </div>
            </div>
          </>
        )}

        <div className="mt24">
          <PorscheFont className="dcs-heading">{t('Existing notifications')}</PorscheFont>
          <PFlex justifyContent={"space-between"}>
            <PFlexItem style={{width:"15%"}}>
              <PorscheFont className="dcs-title">{t('Sent on')}</PorscheFont>
            </PFlexItem>
            <PFlexItem style={{width:"15%"}}>
              <PorscheFont className="dcs-title">{t('Sent by')}</PorscheFont>
            </PFlexItem>
            <PFlexItem style={{width:"30%"}}>
              <PorscheFont className="dcs-title">{t('Notifications')}</PorscheFont>
            </PFlexItem>
            <PFlexItem style={{width:"40%"}}>
              <PorscheFont className="dcs-title">{t('Recipients')}</PorscheFont>
            </PFlexItem>
          </PFlex>
          <PDivider color="neutral-contrast-high" />
          {notifications.map((notificationDetails, index) => {
            return (
              <NotificationRow notificationDetails={notificationDetails} index={index}/>
            );
          })}
        </div>
        <div className="flex30 pt20">
          <Snackbar
            open={saveSuccess}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            autoHideDuration={2000}
            onClose={handleSuccessClose}
          >
            <Alert severity="success" sx={{ width: "100%" }}>
            {t('Saved successfully')}!
            </Alert>
          </Snackbar>
        </div>
      </div>
    </>
  );
};
