import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Stack } from '@mui/material';
import {
    PButtonPure, PDivider, PFlex, PFlexItem, PGrid, PGridItem, PModal, PPopover, PText
} from '@porsche-design-system/components-react';

import { PorscheFont } from '../../assets/styles/CustomStyles';
import { TaskResponseDTO } from '../../interfaces/TasksAndNotifications';
import { TaskPopup } from './TaskPopup';
import { SingleOrderDetailType } from '../../interfaces/OrderDetails';
import { Constant } from '../../configs/Constants';

interface Props {
    // task: Task;
    task: TaskResponseDTO;
    index: number;
    handleDelete: () => void;
}
export const TaskRow1 = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const { task, index, handleDelete } = props;
    const [showPage, setShowPage] = useState<boolean>(false);
    const [isDetailsOpen, setIsDetailsOpen] = useState(false);
    const handleDetailsClose = () => {
        setIsDetailsOpen(false);
    };

    const handlePage = (orderId: number, isDraft: boolean) => {
        if (orderId) {
            const draftValue = isDraft?true:false
            const singOrderDetails:SingleOrderDetailType = { id: orderId, mode:'view', draft: draftValue };
            const newWindow=  window.open(`/#/order/create`, "_blank") 
            newWindow?.sessionStorage.setItem(Constant.SingleOrdDetail, JSON.stringify(singOrderDetails));
        }
    }

    return (
        <>
            <div style={{margin: "3px 1px", boxShadow: '0 1px 0px rgba(0,0,0,0.1)'}}>
                <PFlex>
                    <PFlexItem>
                        <PDivider 
                            orientation="vertical" 
                            style={{ backgroundColor: "#D5001B", width: "5px", height: "100%" }}
                        />
                    </PFlexItem>
                    <PFlexItem width="three-quarters" style={{padding: "0 10px"}}>
                        <PGrid>
                            {/* 
                                <PGridItem size={12}>
                                    <PorscheFont> {task.subject} </PorscheFont>
                                </PGridItem> 
                            */}
                            <PGridItem size={12} style={{paddingTop: "8px", paddingBottom: "2px"}}>
                              {task.taskType === 'Approval' 
                                ? <Stack direction='row'>
                                    <img 
                                        style={{height:"20px",width:"20px"}} 
                                        src={require("../../assets/images/Approval.png")} alt=""
                                    />
                                    &ensp;
                                    <PorscheFont style={{fontWeight:"800"}}>{t(task.subject)}</PorscheFont>
                                  </Stack>
                                : <PorscheFont style={{fontWeight:"800"}}> {t(task.subject)}</PorscheFont>
                              }
                            </PGridItem>
                            <PGridItem size={12} style={{paddingTop: "2px", paddingBottom: "8px", minHeight:  "34px"}}>
                                <PorscheFont>
                                    {task.order 
                                    ? (
                                        <>
                                            {task.order?.orderNumber?task.order?.orderNumber+' | ':""} {task.order?.commissionNumber?task.order?.commissionNumber+' | ':""} {task.order?.actNumber ? task.order?.actNumber+' | ':""} 
                                            {task.order?.companyName 
                                            ? task.order?.companyName + " | "
                                            : task.order?.customerFirstname ? task.order?.customerFirstname + " " : ""}
                                            { task.order?.companyName === null && task.order?.customerLastname ? task.order?.customerLastname + ' | ' : ""} 
                                            {task.order?.vehicleCategory! ? t(task.order?.vehicleCategory) +' | ':""} {task.order?.model!?task.order?.model!+' |':""} {task.order?.paymentType! ? t(task.order?.paymentType)+' | ':""} 
                                            {task.order?.sellerFirstname 
                                              ? task.order?.sellerFirstname + (task.order?.sellerLastname ? " " + task.order?.sellerLastname : "" )
                                              : ""
                                            }
                                        </>
                                    ): `${t('No matching order')}`    
                                    }
                                </PorscheFont>
                            </PGridItem>
                        </PGrid>
                    </PFlexItem>
                    <PFlexItem width="one-quarter" style={{display:"flex", alignItems: "center", justifyContent: "space-between"}}>
                        <PText>
                            {task.createdOn ? task.createdOn.substring(0, 16).replaceAll("-", "."): ""}
                        </PText>
                        <PText>
                            <PPopover 
                                description="Some additional content." 
                                aria={{ 'aria-label': 'Some more descriptive label' }}
                                onClick={(e) => setIsDetailsOpen(true)}
                            ></PPopover>
                        </PText>
                        <div style={{paddingRight: "5px"}}>
                            <PButtonPure 
                                hideLabel={true}
                                onClick={() => handlePage(task.order.id, task.order.isDraft)}>
                            </PButtonPure>
                        </div>
                    </PFlexItem>
                </PFlex>
            </div>
            <PModal className="overview-modal" open={isDetailsOpen} onClose={handleDetailsClose}>
                {/* <TaskPopup task={task} /> */}
                <TaskPopup task={task} closeDetails={handleDetailsClose} handleDelete={handleDelete} />
            </PModal>
        </>
    );
}