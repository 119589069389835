import { Alert, Snackbar } from "@mui/material";
import {
  PButton,
  PFlex,
  PFlexItem,
  PModal,
  PTable,
  PTableBody,
  PTableCell,
  PTableHead,
  PTableHeadCell,
  PTableHeadRow,
  PTableRow
} from "@porsche-design-system/components-react";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { PorscheFont } from "../../../../assets/styles/CustomStyles";
import {
  PartType,
  VehiclePriceType,
  paymentPrintTypes,
  tradeInPrintTypes,
} from "../../../../configs/Enums";
import { AuthContext } from "../../../../contexts/AuthContext";
import { OrderContext } from "../../../../contexts/OrderContext";
import { formatDate } from "../../../../helpers/formatters";
import { CalculationDraftDTO } from "../../../../interfaces/Calculation";
import {
  OrderApproval,
  OrderDTO,
  orderFlag,
} from "../../../../interfaces/Order";
import { UpsaleDetailsDTO } from "../../../../interfaces/UpsaleNew";
import { UserDetailsDTO } from "../../../../interfaces/User";
import {
  formatCurrencyWithNoUnit,
  getStatusColor,
  getUpsaleItemsTotal,
  negativeValueHandler,
} from "../../../../mock/helper";
import {
  dcsgetCityBasedUsers,
  dcsgetOrderSummary,
  dcsgetUserWithPermissions,
  getOrderSummary,

} from "../../../../services/data/Helpers";
import { FirstApproval } from "./FirstApproval";
import { type } from "../../../../services/Constants";
import getSessionStorage from "../../../../helpers/getSessionStorage";
import { Constant } from "../../../../configs/Constants";
import draftValueHandler from "../../../../helpers/sessionDraftValueHandler";

interface Props {
  changedMode: string;
  calDraftPropData: CalculationDraftDTO;
}

interface SummaryData {
  internalDiscountPercentage: number | null;
  externalDiscountPercentage: number | null;
  grossDiscountPercentage: number | null;
  internalDiscountAmount: number | null;
  externalDiscountAmount: number | null;
  grossDiscountAmount: number | null;
}

export const SummaryTab = (props: Props): JSX.Element => {
  const { calDraftPropData } = props;
  const orderValues = getSessionStorage(Constant.SingleOrdDetail);
  const orderContext = useContext(OrderContext);
  const authCtx = useContext(AuthContext);
  const {
    porscheCenter,
    customerDetails,
    dateOfOrder,
    tradeInDetails,
    paymentDetails,
    deliveryDetails,
    status,
    vehicleDetails,
    reCalcStatus,
  } = orderContext.getCtxOrderDetails();
  let upsaleDetailsContext = orderContext.getCtxUpsaleDetails();
  let upsalectxUpsaleItems = upsaleDetailsContext?.upsaleItems;

  let recal = orderContext.getCtxReCalculation()?.reCalculation;
  const [approvalOpen, setApprovalOpen] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState<UserDetailsDTO>(
    {} as UserDetailsDTO
  );
  const [calDraft, setCalDraft] = useState<CalculationDraftDTO>(
    calDraftPropData as CalculationDraftDTO
  );
  const [summaryData, setSummaryData] = useState<SummaryData>(
    {} as SummaryData
  );
  const [orderCreator, setOrderCreator] = useState<UserDetailsDTO|null>(null);
  
  // "isReCalculationAllowed": true,
  //   "dispatchOrderProcessingFlag": true,
  //   "orderCommissionApprovalFlag": false,
  //   "orderFixedCommissionFlag": false,
  const [upsaleDetails, setUpsaleDetails] = useState<UpsaleDetailsDTO>(
    upsaleDetailsContext as UpsaleDetailsDTO
  );
  const [orderFlags, setOrderFlags] = useState<orderFlag>({} as orderFlag);
  const [order, setOrder] = useState<OrderDTO>({} as OrderDTO);
  const [OrderApprovalData, setOrderApprovalData] = useState<OrderApproval>(
    {} as OrderApproval
  );
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [saveSuccessMsg, setSaveSuccessMsg] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [hide, setHide] = useState(false);
  const [calculatedValues, setCalculatedValues] =
    useState<CalculationDraftDTO>();
    
 const draftStatus =draftValueHandler(orderValues?.draft);


  useEffect(() => {
    const init = async () => {
      let userDetails = await authCtx.getUserDetails();
      if (userDetails) {
        setUserDetails(userDetails);
      } else {
        const apiResponse = await dcsgetUserWithPermissions()
        if(apiResponse.status===type.SUCCESS){
          setUserDetails(apiResponse.response);
          authCtx.updateUserDetails(apiResponse.response);
        }  
      }
    };
    init();
    calDraft?.calculationType &&
      setCalculatedValues(
        orderContext.getCtxCalculationDraftDetails(calDraft?.calculationType)
      );
  }, []);

  // if(calDraft && calDraft?calcType!)

  useEffect(() => {
    const init = async () => {
      const id = orderValues?.id ? orderValues?.id+'' :''
      // let response: any;
      if (id) {
        const apiResponse = await dcsgetOrderSummary(parseInt(id))
        if(apiResponse.status===type.SUCCESS){   
        const calDraft = apiResponse.response
          ?.calculation as CalculationDraftDTO;
        const upsaleData = apiResponse.response
          ?.upsale as UpsaleDetailsDTO;
        const orderData = apiResponse.response
          ?.order as OrderDTO;
        const OrderApprovalresponse = apiResponse.response
          ?.orderApproval as OrderApproval;
        let resposeData = apiResponse.response
        const orderFlagObject = {
          dispatchOrderProcessingFlag: resposeData?.dispatchOrderProcessingFlag,
          orderCommissionApprovalFlag: resposeData?.orderCommissionApprovalFlag,
          orderFixedCommissionFlag: resposeData?.orderFixedCommissionFlag,
        };
        setCalDraft(calDraft);
        setUpsaleDetails(upsaleData);
        setOrder(orderData);
        setOrderApprovalData(OrderApprovalresponse);
        setOrderFlags(orderFlagObject);
      }
      }
    };
    if (draftStatus !== "true") {
      init();
    }
  }, []);

  useEffect(() => {
    updateSummaryData();
  }, [calDraft?.calculationHeader, recal?.calculationHeader]);

  useEffect(() => {
    const isDraft =draftValueHandler(orderValues?.draft)
    const mode = orderValues?.mode ? orderValues?.mode+'' :''
    if (isDraft && isDraft === "false") {
      if(mode==='edit'){
        setHide(false)
      }else{
      setHide(true);
    }
    }

    const init = async()=>{
      const apiResponse = await dcsgetCityBasedUsers()
      if (apiResponse.status===type.SUCCESS) {
               const orderCreatorDetails = apiResponse.response.filter((item: UserDetailsDTO) => {
          return item.id ===orderContext.getCtxOrderDetails()?.orderCreator?.id
        })
        orderCreatorDetails && orderCreatorDetails[0] && orderCreatorDetails[0].id && setOrderCreator(orderCreatorDetails[0]);
      }
    }
    init()
    
  }, []);


  const { t } = useTranslation();

  const handleExit = () => {
    window.close();
  };

  const handleApprovalOpen = () => {
    setApprovalOpen(true);
  };

  const handleApprovalClose = () => {
    setApprovalOpen(false);
  };

  const handleSuccessClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return false;
    }
    setSaveSuccess(false);
  };

  function PascalCasingHandler(input: string | null) {
    if (!input) {
      return "";
    }
    const words = input.split(/(?=[A-Z])/);
    const formatted = words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    return formatted;
  }

  const showMessage = (variant: string, msg: string) => {
    if (variant === "success") {
      setSaveSuccess(true);
      setSaveSuccessMsg(msg);
    }
  };

  const getImage = (iconName: string) => {
    let ret;
    switch (iconName) {
      case "Groups":
        ret = (
          <i className="image-border">
            <img
              src={require("../../../../assets/images/summary/Groups.png")}
              alt={"Groups"}
            />
          </i>
        );
        break;

      case "Car":
        ret = (
          <i className="image-border">
            <img
              src={require("../../../../assets/images/summary/Car.png")}
              alt={"Car"}
            />
          </i>
        );
        break;

      case "Apartment":
        ret = (
          <i className="image-border">
            <img
              src={require("../../../../assets/images/summary/Apartment.png")}
              alt={"Apartment"}
            />
          </i>
        );
        break;
      case "Work":
        ret = (
          <i className="image-border">
            <img
              src={require("../../../../assets/images/summary/Work.png")}
              alt={"Work"}
            />
          </i>
        );
        break;
      case "Calendar":
        ret = (
          <i className="image-border">
            <img
              src={require("../../../../assets/images/summary/Calendar.png")}
              alt={"Calendar"}
            />
          </i>
        );
        break;
      case "Trade":
        ret = (
          <i className="image-border" style={{}}>
            <img
              src={require("../../../../assets/images/summary/Trade.png")}
              alt={"Trade"}
            />
          </i>
        );
        break;
      case "Credit_card":
        ret = (
          <i className="image-border">
            <img
              src={require("../../../../assets/images/summary/Credit_card.png")}
              alt={"Credit_card"}
            />
          </i>
        );
        break;
      case "Shipping":
        ret = (
          <i className="image-border">
            <img
              src={require("../../../../assets/images/summary/Shipping.png")}
              alt={"Shipping"}
            />
          </i>
        );
        break;
      case "Vector":
        ret = (
          <i className="image-border">
            <img
              src={require("../../../../assets/images/summary/Vector.png")}
              alt={"Vector"}
            />
          </i>
        );
        break;

      default:
        ret = (
          <i className="image-border">
            <img
              src={require("../../../../assets/images/summary/Groups.png")}
              alt={"911"}
            />
          </i>
        );
        break;
    }

    return ret;
  };

   const getTiresAndPartsAmount = (upsaleDetails: UpsaleDetailsDTO): string => {
    const { isTiresActivated, isPartsActivated, upsaleItems } = upsaleDetails;

    if (upsalectxUpsaleItems.length > 0) {
      return `${formatCurrencyWithNoUnit(
        getUpsaleItemsTotal(
          upsalectxUpsaleItems.filter((x) => x.partType === PartType.tire)
        ).customerShare +
          getUpsaleItemsTotal(
            upsalectxUpsaleItems.filter((x) => x.partType === PartType.part)
          ).customerShare
      )} €`;
    } else if (
      (isTiresActivated || isPartsActivated) &&
      upsaleItems.length > 0
    ) {
      return `${formatCurrencyWithNoUnit(
        getUpsaleItemsTotal(
          upsaleItems.filter((x) => x.partType === PartType.tire)
        ).customerShare +
          getUpsaleItemsTotal(
            upsaleItems.filter((x) => x.partType === PartType.part)
          ).customerShare
      )} €`;
    } else {
      return `${formatCurrencyWithNoUnit(0)} €`;
    }
  };

  const getPAWAndAssistanceAmount = (
    type: string,
    upsaleDetails: any,
    calDraft: CalculationDraftDTO
  ): string => {
    const { isPawActivated, includeAssistance } = upsaleDetails;
    const { additionalCostsPrices } = calDraft;

    let findPAWDetails = additionalCostsPrices?.find(
      (cost) => cost.type === "Paw"
    );
    let findAssistanceDetails = additionalCostsPrices?.find(
      (cost) => cost.type === "Assistance"
    );

    if (type === "pwa") {
      if (findPAWDetails?.customerShare) {
        return `${formatCurrencyWithNoUnit(findPAWDetails?.customerShare)} €`;
      } else {
        return `${formatCurrencyWithNoUnit(0)} €`; // Tires & Parts value when both toggles are deactivated
      }
    } else {
      if (includeAssistance && findAssistanceDetails?.customerShare) {
        return `${formatCurrencyWithNoUnit(
          findAssistanceDetails?.customerShare
        )} €`;
      } else {
        return `${formatCurrencyWithNoUnit(0)} €`; // Tires & Parts value when both toggles are deactivated
      }
    }
  };

  const updateSummaryData = () => {
    setSummaryData((prevSummaryData) => {
      const isUsedCar = vehicleDetails?.category.type === "UsedCar";
      const isDemoCar = vehicleDetails?.category.type === "DemoCar";
      const isNewCar = vehicleDetails?.category.type === "NewCar";

      const updatedSummaryData: SummaryData = { ...prevSummaryData };
      if (!(reCalcStatus && reCalcStatus !== "notStarted")) {
        if (isUsedCar) {
          // For UsedCar, set all values to null
          updatedSummaryData.internalDiscountPercentage = null;
          updatedSummaryData.internalDiscountAmount = null;
          updatedSummaryData.externalDiscountPercentage = null;
          updatedSummaryData.externalDiscountAmount = null;
          updatedSummaryData.grossDiscountPercentage = null;
          updatedSummaryData.grossDiscountAmount = null;
        } else if (isDemoCar || isNewCar) {
          const priceIndex = isDemoCar ? 0 : 2; //

          // Calculate internal discount percentage and amount
          updatedSummaryData.internalDiscountPercentage =
            calDraft.calculationHeader?.internalDiscount &&
            calDraft?.vehiclePrices &&
            calDraft?.vehiclePrices[priceIndex].amountNet > 0
              ? (calDraft.calculationHeader.internalDiscount /
                  calDraft.vehiclePrices[priceIndex].amountNet) *
                100
              : 0.0;

          updatedSummaryData.internalDiscountAmount =
            calDraft.calculationHeader?.internalDiscount || 0;

          // Calculate external discount percentage and amount
          updatedSummaryData.externalDiscountPercentage =
            calDraft.calculationHeader?.externalDiscount &&
            calDraft?.vehiclePrices &&
            calDraft?.vehiclePrices[priceIndex].amountNet > 0
              ? (calDraft.calculationHeader.externalDiscount /
                  calDraft.vehiclePrices[priceIndex].amountNet) *
                100
              : 0.0;

          updatedSummaryData.externalDiscountAmount =
            calDraft.calculationHeader?.externalDiscount || 0;

          // Calculate gross discount percentage and amount
          updatedSummaryData.grossDiscountPercentage =
            calDraft.calculationHeader?.grossProfit &&
            calDraft?.vehiclePrices &&
            calDraft?.vehiclePrices[priceIndex].amountNet > 0
              ? (calDraft.calculationHeader.grossProfit /
                  calDraft.vehiclePrices[priceIndex].amountNet) *
                100
              : 0.0;

          updatedSummaryData.grossDiscountAmount =
            calDraft.calculationHeader?.grossProfit || 0;
        }
      } else {
        if (isUsedCar) {
          // For UsedCar, set all values to null
          updatedSummaryData.internalDiscountPercentage = null;
          updatedSummaryData.internalDiscountAmount = null;
          updatedSummaryData.externalDiscountPercentage = null;
          updatedSummaryData.externalDiscountAmount = null;
          updatedSummaryData.grossDiscountPercentage = null;
          updatedSummaryData.grossDiscountAmount = null;
        } else if (isDemoCar || isNewCar) {
          const priceIndex = isDemoCar ? 0 : 2; // Use [0] for DemoCar, [2] for NewCar

          // Calculate internal discount percentage and amount
          updatedSummaryData.internalDiscountPercentage =
            recal?.calculationHeader?.internalDiscount &&
            recal?.vehiclePrices &&
            recal?.vehiclePrices[priceIndex].amountNet > 0
              ? (recal?.calculationHeader?.internalDiscount /
                  recal?.vehiclePrices[priceIndex].amountNet) *
                100
              : 0.0;

          updatedSummaryData.internalDiscountAmount =
            recal?.calculationHeader?.internalDiscount || 0;

          // Calculate external discount percentage and amount
          updatedSummaryData.externalDiscountPercentage =
            recal?.calculationHeader?.externalDiscount &&
            recal?.vehiclePrices &&
            recal?.vehiclePrices[priceIndex].amountNet > 0
              ? (recal.calculationHeader?.externalDiscount /
                  recal.vehiclePrices[priceIndex].amountNet) *
                100
              : 0.0;

          updatedSummaryData.externalDiscountAmount =
            recal?.calculationHeader?.externalDiscount || 0;

          // Calculate gross profit percentage and amount
          updatedSummaryData.grossDiscountPercentage =
            recal?.calculationHeader?.grossProfit &&
            recal?.vehiclePrices &&
            recal?.vehiclePrices[priceIndex].amountNet > 0
              ? (recal.calculationHeader?.grossProfit /
                  recal?.vehiclePrices[priceIndex].amountNet) *
                100
              : 0.0;

          updatedSummaryData.grossDiscountAmount =
            recal?.calculationHeader?.grossProfit || 0;
        }
      }
      return updatedSummaryData;
    });
  };

  const getFinaCalculationValue = (
    type: string,
    isPercentage: boolean
  ): string => {
    if (type === "internal") {
      if (isPercentage) {
        return summaryData.internalDiscountPercentage
          ? `${formatCurrencyWithNoUnit(
              summaryData.internalDiscountPercentage || 0
            )} %`
          : "-";
      } else {
        return summaryData.internalDiscountAmount
          ? `${formatCurrencyWithNoUnit(
              summaryData.internalDiscountAmount || 0
            )} €`
          : "-";
      }
    } else if (type === "external") {
      if (isPercentage) {
        return summaryData.externalDiscountPercentage
          ? `${formatCurrencyWithNoUnit(
              summaryData.externalDiscountPercentage || 0
            )} %`
          : "-";
      } else {
        return summaryData.externalDiscountAmount
          ? `${formatCurrencyWithNoUnit(
              summaryData.externalDiscountAmount || 0
            )} €`
          : "-";
      }
    } else {
      if (isPercentage) {
        return summaryData.grossDiscountPercentage
          ? `${formatCurrencyWithNoUnit(
              summaryData.grossDiscountPercentage || 0
            )} %`
          : "-";
      } else {
        return summaryData.grossDiscountAmount
          ? `${formatCurrencyWithNoUnit(
              summaryData.grossDiscountAmount || 0
            )} €`
          : "-";
      }
    }
  };
  const getCustomerCalculationValue = (
    type: string,
    isNet: boolean
  ): string => {
    const isUsedCar = vehicleDetails?.category.type === "UsedCar";
    const isNewCar = vehicleDetails?.category.type === "NewCar";
    const purchasePriceusedCar = calDraft?.vehiclePrices?.find(
      (value) => value?.displayLabel === VehiclePriceType.PurchasePriceUsed
    )?.amountNet;
    const sellingPriceusedCar = calDraft?.vehiclePrices?.find(
      (value) => value?.displayLabel === VehiclePriceType.SellingPriceDemo
    );
    const RRPData = calDraft?.vehiclePrices?.find(
      (value) => value?.displayLabel === VehiclePriceType.RRP
    );
    const optionsData = calDraft?.vehiclePrices?.find(
      (value) => value?.displayLabel === "options"
    );
    const totalInvoiceGross = calDraft?.calculationCustomer?.invoiceGross;
    const tatalInvoiceNet = calDraft?.calculationCustomer?.invoiceNet;
    // const totalCost = calDraft.calculationHeader?.totalCosts;

    if (type === "RRP") {
      if (isNet) {
        return isUsedCar
          ? `${formatCurrencyWithNoUnit(purchasePriceusedCar || 0)} €`
          : `${formatCurrencyWithNoUnit(RRPData?.amountNet || 0)} €`;
      } else {
        return isUsedCar
          ? "-"
          : `${formatCurrencyWithNoUnit(RRPData?.amountGross || 0)} €`;
      }
    } else if (type === "Options") {
      if (isNet) {
        return isNewCar
          ? `${formatCurrencyWithNoUnit(optionsData?.amountNet || 0)} €`
          : `${formatCurrencyWithNoUnit(
              sellingPriceusedCar?.amountNet || 0
            )} €`;
      } else {
        return isNewCar
          ? `${formatCurrencyWithNoUnit(optionsData?.amountGross || 0)} €`
          : `${formatCurrencyWithNoUnit(
              sellingPriceusedCar?.amountGross || 0
            )} €`;
      }
    } else {
      if (isNet) {
        return `${
          tatalInvoiceNet &&
          formatCurrencyWithNoUnit(
            calculatedValues?.calculationCustomer?.invoiceNet || tatalInvoiceNet
          )
        } €`;
      } else {
        return `${
          totalInvoiceGross &&
          formatCurrencyWithNoUnit(
            calculatedValues?.calculationCustomer?.invoiceGross ||
              totalInvoiceGross
          )
        } €`;
      }
    }
  };

  const getCommissionValue = (type: string): string => {
    let vehicleCommission =
      calDraft?.commissionCalculation?.vehicleCommissionEuro;
    let totalCommission = calDraft?.commissionCalculation?.totalCommission;
    let otherCommission =
      totalCommission && vehicleCommission
        ? totalCommission - vehicleCommission
        : 0;
    if (type === "vehicle") {
      return `${ vehicleCommission ? formatCurrencyWithNoUnit(negativeValueHandler(vehicleCommission)  || 0): 0} €`;
    } else if (type === "other") {
      return `${otherCommission &&  formatCurrencyWithNoUnit(negativeValueHandler(otherCommission)  || 0)} €`;
    } else {
      return `${totalCommission && formatCurrencyWithNoUnit(negativeValueHandler(totalCommission)  || 0)} €`;
    }
  };

  const checkValues = (value: string | number | undefined): string | number => {
    return value ? value : "-";
  };

  const tradeInPrintHandler = (str: string) => {
    if (
      tradeInPrintTypes.hasOwnProperty(str as keyof typeof tradeInPrintTypes)
    ) {
      return tradeInPrintTypes[str as keyof typeof tradeInPrintTypes];
    } else return "";
  };

  const paymentPrintHandler = (str: string) => {
    if (
      paymentPrintTypes.hasOwnProperty(str as keyof typeof paymentPrintTypes)
    ) {
      return paymentPrintTypes[str as keyof typeof paymentPrintTypes];
    } else return "";
  };

  return (
    <>
      <PFlex className="summary-wrapper" direction="column">
        <div className="summary-container">
          <PorscheFont className="header-text">
            {t("Order details")}
          </PorscheFont>
          <div className="order-detials-container">
            <PFlexItem className="pflex-order-items">
              <PFlex className="order-details-content-flex">
                <div className="order-details-image">{getImage("Groups")}</div>
                <div className="order-discription">
                  <PorscheFont className="order-label">
                    {`${t(PascalCasingHandler(
                      customerDetails?.customerGroupType
                    ))} - ${
                      customerDetails?.customerType ===
                        "DealerBiddingPlatform" || "KeyAccount"
                        ? t(PascalCasingHandler(customerDetails?.customerType))
                        : customerDetails?.customerType ? t(customerDetails?.customerType) : ''
                      }`}
                  </PorscheFont>
                  <PorscheFont className="order-value">
                    {customerDetails?.customerType
                      ? customerDetails?.customerGroupType === "BusinessClient"
                        ? `${customerDetails?.companyName} ${
                            customerDetails.copNumber
                              ? `(${customerDetails.copNumber})`
                              : ""
                          }`
                        : `${customerDetails.firstName} ${
                            customerDetails.lastName
                          } ${
                            customerDetails.copNumber
                              ? `(${customerDetails.copNumber})`
                              : ""
                          }`
                      : "-"}
                  </PorscheFont>
                </div>
              </PFlex>
            </PFlexItem>
            <PFlexItem className="pflex-order-items">
              <PFlex className="order-details-content-flex">
                <div className="order-details-image">{getImage("Work")}</div>
                <div className="order-discription">
                  <PorscheFont className="order-label">{t("Seller")}</PorscheFont>
                  <PorscheFont className="order-value">
                    {/* {order?.orderCreator?.seller
                      ? order?.orderCreator?.seller
                      : userDetails?.ppnName} */}

                      {orderCreator?.ppnName}
                  </PorscheFont>
                </div>
              </PFlex>
            </PFlexItem>
            <PFlexItem className="pflex-order-items">
              <PFlex className="order-details-content-flex">
                <div className="order-details-image">
                  {getImage("Credit_card")}
                </div>
                <div className="order-discription">
                  <PorscheFont className="order-label">{t("Payment")}</PorscheFont>
                  <PorscheFont className="order-value">
                    {/* {formatCapitalize(paymentDetails?.paymentType)} */}
                    {t(paymentPrintHandler(
                      paymentDetails?.paymentType
                        ? paymentDetails?.paymentType
                        : ""
                    ))}
                  </PorscheFont>
                </div>
              </PFlex>
            </PFlexItem>
            <PFlexItem className="pflex-order-items">
              <PFlex className="order-details-content-flex">
                <div className="order-details-image">{getImage("Car")}</div>
                <div className="order-discription">
                  <PorscheFont className="order-label">
                    {t(vehicleDetails?.category?.label)}
                  </PorscheFont>
                  <PorscheFont className="order-value">
                    {`${vehicleDetails?.model?.modelName ?? ""} ${
                      vehicleDetails?.modelDescription ?? ""
                    }`}
                  </PorscheFont>
                </div>
              </PFlex>
            </PFlexItem>
            <PFlexItem className="pflex-order-items">
              <PFlex className="order-details-content-flex">
                <div className="order-details-image">
                  {getImage("Calendar")}
                </div>
                <div className="order-discription">
                  <PorscheFont className="order-label">
                  {t("Date of order")}
                  </PorscheFont>
                  <PorscheFont className="order-value">
                    {formatDate(dateOfOrder)}
                  </PorscheFont>
                </div>
              </PFlex>
            </PFlexItem>
            <PFlexItem className="pflex-order-items">
              <PFlex className="order-details-content-flex">
                <div className="order-details-image">
                  {getImage("Shipping")}
                </div>
                <div className="order-discription">
                  <PorscheFont className="order-label">{t("Delivery")}</PorscheFont>
                  <PorscheFont className="order-value">
                    {deliveryDetails?.deliveryLocation?.name}
                  </PorscheFont>
                </div>
              </PFlex>
            </PFlexItem>
            <PFlexItem className="pflex-order-items">
              <PFlex className="order-details-content-flex">
                <div className="order-details-image">
                  {getImage("Apartment")}
                </div>
                <div className="order-discription">
                  <PorscheFont className="order-label">
                  {t("Porsche center")}
                  </PorscheFont>
                  <PorscheFont className="order-value">
                    {/* {porscheCenter?.centerName} */}
                    {orderCreator?.origin.centerName}
                  </PorscheFont>
                </div>
              </PFlex>
            </PFlexItem>
            <PFlexItem className="pflex-order-items">
              <PFlex className="order-details-content-flex">
                <div className="order-details-image">{getImage("Trade")}</div>
                <div className="order-discription">
                  <PorscheFont className="order-label">{t("Trade-In")}</PorscheFont>
                  <PorscheFont className="order-value">
                    {t(tradeInPrintHandler(
                      tradeInDetails?.tradeInType
                        ? tradeInDetails?.tradeInType
                        : "-"
                    ))}
                  </PorscheFont>
                </div>
              </PFlex>
            </PFlexItem>
            <PFlexItem className="pflex-order-items">
              <PFlex className="order-details-content-flex">
                <div className="order-details-image">{getImage("Vector")}</div>
                <div className="order-discription">
                  <PorscheFont className="order-label">{t("Status")}</PorscheFont>
                  <span className="order-status">
                    <PorscheFont
                      className={` ${
                        status !== "Draft"
                          ? `${
                              status && getStatusColor(status)
                            } order-status-value `
                          : "order-value"
                      }`}
                    >
                      {!!status && status === "Draft"
                        ? "-"
                        : t(PascalCasingHandler(status))}
                    </PorscheFont>
                  </span>
                </div>
              </PFlex>
            </PFlexItem>
          </div>
        </div>
        <PFlex justifyContent="space-between">
          <PFlexItem className="summary-container grid-container">
            <PorscheFont className="header-text">{t("Upsale")}</PorscheFont>
            <div className="upsale-list-container">
              <ul className="upsale-list">
                <li className="li-list">
                  <PorscheFont className="upsale-label">
                  {t("Tires & Parts")}
                  </PorscheFont>
                  <PorscheFont className="upsale-value">
                    {getTiresAndPartsAmount(upsaleDetails) === "-" ? "" : getTiresAndPartsAmount(upsaleDetails)}
                  </PorscheFont>
                </li>
                <li className="li-list">
                  <PorscheFont className="upsale-label">
                  
                    {calDraft && calDraft?.additionalCostsPrices &&  calDraft?.additionalCostsPrices[2].label? calDraft?.additionalCostsPrices[2].label: 'PAW'}
                  </PorscheFont>
                  <PorscheFont className="upsale-value">
                    {getPAWAndAssistanceAmount("pwa", upsaleDetails, calDraft) === "-" ? "" : getPAWAndAssistanceAmount("pwa", upsaleDetails, calDraft)}
                  </PorscheFont>
                </li>
                <li className="li-list">
                  <PorscheFont className="upsale-label">
                  {calDraft && calDraft?.additionalCostsPrices &&  calDraft?.additionalCostsPrices[3].label? calDraft?.additionalCostsPrices[3].label: 'Assistance'}
                  </PorscheFont>
                  <PorscheFont className="upsale-value">
                    {getPAWAndAssistanceAmount(
                      "assistance",
                      upsaleDetails,
                      calDraft
                    )  === "-" ? "" : getPAWAndAssistanceAmount(
                      "assistance",
                      upsaleDetails,
                      calDraft
                    )}
                  </PorscheFont>
                </li>
              </ul>
            </div>
          </PFlexItem>
          <PFlexItem className="summary-container grid-container">
            <PorscheFont className="header-text">
              {t("Order registration")}
            </PorscheFont>
            <div className="upsale-list-container">
              <ul className="upsale-list">
                <li className="li-list">
                  <PorscheFont className="upsale-label">
                  {t("Order registration date")}
                  </PorscheFont>
                  <PorscheFont className="upsale-value">
                    {formatDate(order?.registerDate && order?.registerDate) === "-" ? "" : formatDate(order?.registerDate && order?.registerDate)}
                  </PorscheFont>
                </li>
                <li className="li-list">
                  <PorscheFont className="upsale-label">
                  {t("Commission number")}
                  </PorscheFont>
                  <PorscheFont className="upsale-value">
                    {checkValues(order?.vehicleDetails?.commissionNumber) === "-" ? "" : checkValues(order?.vehicleDetails?.commissionNumber)}
                  </PorscheFont>
                </li>
                <li className="li-list">
                  <PorscheFont className="upsale-label"> {t("Act Number")}</PorscheFont>
                  <PorscheFont className="upsale-value">
                    {checkValues(order?.vehicleDetails?.actNumber) === "-" ? "" : checkValues(order?.vehicleDetails?.actNumber)}
                  </PorscheFont>
                </li>
              </ul>
            </div>
          </PFlexItem>
          <PFlexItem className="summary-container grid-container">
            <PorscheFont className="header-text">
              {t("Customer delivery")}
            </PorscheFont>
            <div className="upsale-list-container">
              <ul className="upsale-list">
                <li className="li-list">
                  <PorscheFont className="upsale-label">
                  {t("Customer delivery date")} 
                  </PorscheFont>
                  <PorscheFont className="upsale-value">
                    {formatDate(order?.customerDeliveryDate) === "-" ? "" : formatDate(order?.customerDeliveryDate)}
                  </PorscheFont>
                </li>
                <li className="li-list">
                  <PorscheFont className="upsale-label">
                  {t("Invoice date")} 
                  </PorscheFont>
                  <PorscheFont className="upsale-value">
                    {formatDate(order?.invoiceDate) === "-" ? "" : formatDate(order?.invoiceDate)}
                  </PorscheFont>
                </li>
                <li className="li-list">
                  <PorscheFont className="upsale-label">
                    C@P Ticket ID
                  </PorscheFont>
                  <PorscheFont className="upsale-value">
                    {checkValues(order?.copTicketId)  === "-" ? "" : checkValues(order?.copTicketId)}
                  </PorscheFont>
                </li>
              </ul>
            </div>
          </PFlexItem>
        </PFlex>
        <div className="summary-container">
          <PorscheFont className="header-text">{t("Calculation")}</PorscheFont>
          <div className="calculation-wrapper">
            <PTable className="calculation-table">
              <PTableHead>
                <PTableHeadRow>
                  <PTableHeadCell style={{position:"relative",left:"-12px"}} className="table-heading">
                  {t("Final calculation")}
                  </PTableHeadCell>
                  <PTableHeadCell className="table-heading"></PTableHeadCell>
                  <PTableHeadCell className="table-heading text-align-right"></PTableHeadCell>
                </PTableHeadRow>
              </PTableHead>
              <PTableBody>
                <PTableRow>
                  <PTableCell style={{position:"relative",left:"-12px"}} className="cell-text">
                    {vehicleDetails?.category?.type === "UsedCar"
                      ? t("Total costs")
                      : t("Internal discount")}
                  </PTableCell>
                  <PTableCell className="cell-text text-align-right">
                    {getFinaCalculationValue("internal", true) === "-" ? "" : getFinaCalculationValue("internal", true)}
                  </PTableCell>
                  <PTableCell className="cell-text text-align-right">
                    {getFinaCalculationValue("internal", false) === "-" ? "" : getFinaCalculationValue("internal", false)}
                  </PTableCell>
                </PTableRow>
                <PTableRow>
                  <PTableCell style={{position:"relative",left:"-12px"}} className="cell-text">
                    {vehicleDetails?.category?.type === "UsedCar"
                      ? t("Standing days")
                      : t("External discount")}
                  </PTableCell>
                  <PTableCell className="cell-text text-align-right">
                    {getFinaCalculationValue("external", true)  === "-" ? "" : getFinaCalculationValue("external", true)}
                  </PTableCell>
                  <PTableCell className="cell-text text-align-right">
                    {getFinaCalculationValue("external", false) === "-" ? "" : getFinaCalculationValue("external", false)}
                  </PTableCell>
                </PTableRow>
                <PTableRow>
                  <PTableCell style={{position:"relative",left:"-12px"}} className="cell-text">{t("Gross profit")}</PTableCell>
                  <PTableCell className="cell-text text-align-right">
                    {getFinaCalculationValue("gross", true) === "-" ? "" : getFinaCalculationValue("gross", true)}
                  </PTableCell>
                  <PTableCell className="cell-text text-align-right">
                    {getFinaCalculationValue("gross", false) === "-" ? "" : getFinaCalculationValue("gross", false)}
                  </PTableCell>
                </PTableRow>
              </PTableBody>
            </PTable>
            <PTable className="calculation-table">
              <PTableHead>
                <PTableHeadRow>
                  <PTableHeadCell style={{position:"relative",left:"-12px"}} className="table-heading">
                  {t("Calculation customer")}
                  </PTableHeadCell>
                  <PTableHeadCell className="table-heading text-align-right">
                    {t("Net")}
                  </PTableHeadCell>
                  <PTableHeadCell className="table-heading text-align-right">
                  {t("Gross")}
                  </PTableHeadCell>
                </PTableHeadRow>
              </PTableHead>
              <PTableBody>
                <PTableRow>
                  <PTableCell style={{position:"relative",left:"-12px"}} className="cell-text">
                    {vehicleDetails?.category.type === "UsedCar"
                      ? t("Purchase price")
                      : t("RRP")}
                  </PTableCell>
                  <PTableCell className="cell-text text-align-right">
                    {getCustomerCalculationValue("RRP", true)  === "-" ? "" : getCustomerCalculationValue("RRP", true)}
                  </PTableCell>
                  <PTableCell className="cell-text text-align-right">
                    {getCustomerCalculationValue("RRP", false) === "-" ? "" : getCustomerCalculationValue("RRP", false)}
                  </PTableCell>
                </PTableRow>
                <PTableRow>
                  <PTableCell style={{position:"relative",left:"-12px"}} className="cell-text">
                    {vehicleDetails?.category.type === "NewCar"
                      ? t("Options")
                      : t("Selling price")}
                  </PTableCell>
                  <PTableCell className="cell-text text-align-right">
                    {getCustomerCalculationValue("Options", true) === "-" ? "" : getCustomerCalculationValue("Options", true)}
                  </PTableCell>
                  <PTableCell className="cell-text text-align-right">
                    {getCustomerCalculationValue("Options", false) === "-" ? "" : getCustomerCalculationValue("Options", false)}
                  </PTableCell>
                </PTableRow>
                <PTableRow>
                  <PTableCell style={{position:"relative",left:"-12px"}} className="cell-text">{t("Total invoice")}</PTableCell>
                  <PTableCell className="cell-text text-align-right">
                    {getCustomerCalculationValue("total", true)  === "-" ? "" : getCustomerCalculationValue("total", true)}
                  </PTableCell>
                  <PTableCell className="cell-text text-align-right">
                    {getCustomerCalculationValue("total", false)  === "-" ? "" : getCustomerCalculationValue("total", false)}
                  </PTableCell>
                </PTableRow>
              </PTableBody>
            </PTable>
            <PTable className="calculation-table">
              <PTableHead>
                <PTableHeadRow>
                  <PTableHeadCell style={{position:"relative",left:"-12px"}} className="table-heading">
                  {t("Commission calculation")}
                  </PTableHeadCell>
                  <PTableHeadCell className="table-heading text-align-right"></PTableHeadCell>
                </PTableHeadRow>
              </PTableHead>
              <PTableBody>
                <PTableRow>
                  <PTableCell style={{position:"relative",left:"-12px"}} className="cell-text">
                  {t("Vehicle commission")}
                  </PTableCell>
                  <PTableCell className="cell-text text-align-right">
                    {getCommissionValue("vehicle") === "-" ? "" : getCommissionValue("vehicle")}
                  </PTableCell>
                </PTableRow>
                <PTableRow>
                  <PTableCell style={{position:"relative",left:"-12px"}} className="cell-text">
                  {t("Other commissions")}
                  </PTableCell>
                  <PTableCell className="cell-text text-align-right">
                    {getCommissionValue("other") === "-" ? "" : getCommissionValue("other")}
                  </PTableCell>
                </PTableRow>
                <PTableRow>
                  <PTableCell style={{position:"relative",left:"-12px"}} className="cell-text">
                  {t("Total commission")}
                  </PTableCell>
                  <PTableCell className="cell-text text-align-right">
                    {getCommissionValue("total") === "-" ? "" : getCommissionValue("total")}
                  </PTableCell>
                </PTableRow>
              </PTableBody>
            </PTable>
          </div>
        </div>
        <div className="summary-container">
          <PorscheFont className="header-text">
            {t("Order processing")}
          </PorscheFont>
          <div style={{paddingBottom:"15px"}} className="order-approval-container">
            <PFlex className="pflex-order-list">
              <ul className="ul-order-list">
                <li
                  className={`list-item ${
                    !(
                      order?.status === "Approved" ||
                      order?.status === "CommissionsApproved" ||
                      order?.status === "Paid"
                    )
                      ? "order-pending"
                      : "order-completed"
                  }`}
                >
                  <PorscheFont className="heading-text">
                  {t('Order approval')}
                  </PorscheFont>
                  {!(
                    order?.status === "Approved" ||
                    order?.status === "CommissionsApproved" ||
                    order?.status === "Paid"
                  ) ? (
                    <div className="order-status-container">
                      <i className="image-wrapper">
                        <img
                          src={require("../../../../assets/images/summary/Pending.png")}
                          alt={"Pending"}
                        />
                      </i>
                      <PorscheFont className="heading-text">
                      {t("Pending")}
                      </PorscheFont>
                    </div>
                  ) : (
                    <div className="order-status-container">
                      <i className="image-wrapper">
                        <img
                          src={require("../../../../assets/images/summary/Completed.png")}
                          alt={"completed"}
                        />
                      </i>
                      <PorscheFont className="heading-text">
                      {t("Completed")}
                      </PorscheFont>
                    </div>
                  )}
                </li>
                <li className="list-item">
                  <PorscheFont style={{position:"relative",left:"0px"}} className="list-item-text order-processing-key">
                    {t("1st order check")}
                  </PorscheFont>
                  <div>
                    <PorscheFont className="list-item-text">
                      {checkValues(OrderApprovalData?.orderCheckOneBy) === "-" ? "" : checkValues(OrderApprovalData.orderCheckOneBy)}
                    </PorscheFont>
                    <PorscheFont className="list-item-text light">
                      {formatDate(OrderApprovalData?.orderCheckOneDate) === "-" ? "" : formatDate(OrderApprovalData?.orderCheckOneDate)}
                    </PorscheFont>
                  </div>
                </li>
                <li className="list-item">
                  <PorscheFont style={{position:"relative",left:"0px"}} className="list-item-text order-processing-key">
                  {t("2nd Order Check")}
                  </PorscheFont>
                  <div>
                    <PorscheFont className="list-item-text">
                      {checkValues(OrderApprovalData.orderCheckTwoBy) === "-" ? "" : checkValues(OrderApprovalData.orderCheckTwoBy)}
                    </PorscheFont>
                    <PorscheFont className="list-item-text light">
                      {formatDate(OrderApprovalData?.orderCheckTwoDate) === "-" ? "" : formatDate(OrderApprovalData?.orderCheckTwoDate)}
                    </PorscheFont>
                  </div>
                </li>
                {order?.deliveryDetails?.exportDeal ? (
                  <>
                    <li className="list-item">
                      <PorscheFont className="list-item-text order-processing-key ">
                        Export
                      </PorscheFont>
                      <div>
                        <PorscheFont className="list-item-text">
                          {checkValues(OrderApprovalData?.orderExportBy) === "-" ? "" : checkValues(OrderApprovalData?.orderExportBy)}
                        </PorscheFont>
                        <PorscheFont className="list-item-text light">
                          {formatDate(OrderApprovalData?.orderExportDate) === "-" ? "" : formatDate(OrderApprovalData?.orderExportDate)}
                        </PorscheFont>
                      </div>
                    </li>
                    <li className="list-item ">
                      <PorscheFont
                        className={`list-item-text  ${
                          OrderApprovalData?.orderDiscountBy && "light"
                        }  order-processing-key`}
                      >
                        {t("Discount")}
                      </PorscheFont>
                      <div>
                        <PorscheFont className="list-item-text">
                          {OrderApprovalData?.orderDiscountBy === "-"
                            ? ""
                            : checkValues(OrderApprovalData?.orderDiscountBy)}
                        </PorscheFont>
                        <PorscheFont className="list-item-text light">
                          {OrderApprovalData?.orderDiscountDate === "-"
                            ? ""
                            : formatDate(OrderApprovalData?.orderDiscountDate)}
                        </PorscheFont>
                      </div>
                    </li>
                    <li className="list-item">
                      <PorscheFont
                        className={`list-item-text  ${
                          OrderApprovalData?.orderOthersBy && "light"
                        }  order-processing-key`}
                      >
                        {t("Others")}
                      </PorscheFont>
                      <div>
                        <PorscheFont className="list-item-text">
                          {OrderApprovalData?.orderOthersBy === "-"
                            ? ""
                            : checkValues(OrderApprovalData?.orderOthersBy)}
                        </PorscheFont>
                        <PorscheFont className="list-item-text light">
                          {OrderApprovalData?.orderOthersDate === "-"
                            ? ""
                            : formatDate(OrderApprovalData?.orderOthersDate)}
                        </PorscheFont>
                      </div>
                    </li>
                  </>
                ) : null}
              </ul>
            </PFlex>
            <PFlex className="pflex-order-list">
              <ul className="ul-order-list">
                <li
                  className={`list-item ${
                    !orderFlags?.dispatchOrderProcessingFlag
                      ? "order-pending"
                      : "order-completed"
                  }`}
                >
                  <PorscheFont className="heading-text">
                  {t("Dispatch Order Processing")}
                  </PorscheFont>
                  {orderFlags?.dispatchOrderProcessingFlag ? (
                    <div className="order-status-container">
                      <i className="image-wrapper">
                        <img
                          src={require("../../../../assets/images/summary/Completed.png")}
                          alt={"Completed"}
                        />
                      </i>
                      <PorscheFont className="heading-text">
                      {t("Completed")}
                      </PorscheFont>
                    </div>
                  ) : (
                    <div className="order-status-container">
                      <i className="image-wrapper">
                        <img
                          src={require("../../../../assets/images/summary/Pending.png")}
                          alt={"Pending"}
                        />
                      </i>
                      <PorscheFont className="heading-text">
                      {t("Pending")}
                      </PorscheFont>
                    </div>
                  )}
                </li>
                <li className="list-item">
                  <PorscheFont className="list-item-text order-processing-key">
                  {t("Order registration")}
                  </PorscheFont>
                  <div>
                    <PorscheFont className="list-item-text">
                      {checkValues(OrderApprovalData.orderRegistrationBy) === "-" ? "" : checkValues(OrderApprovalData.orderRegistrationBy)}
                    </PorscheFont>
                    <PorscheFont className="list-item-text light">
                      {formatDate(OrderApprovalData?.orderRegistrationDate) === "-" ? "" : formatDate(OrderApprovalData?.orderRegistrationDate)}
                    </PorscheFont>
                  </div>
                </li>
                <li className="list-item">
                  <PorscheFont className="list-item-text order-processing-key">
                  {t("Customer delivery")}
                  </PorscheFont>
                  <div>
                    <PorscheFont className="list-item-text">
                      {checkValues(OrderApprovalData?.customerDeliveryBy) === "-" ? "" : checkValues(OrderApprovalData?.customerDeliveryBy)}
                    </PorscheFont>
                    <PorscheFont className="list-item-text light">
                      {formatDate(OrderApprovalData?.customerDeliveryDate) === "-" ? "" : formatDate(OrderApprovalData?.customerDeliveryDate)}
                    </PorscheFont>
                  </div>
                </li>
                <li className="list-item">
                  <PorscheFont className="list-item-text order-processing-key">
                    {t("Recalculation")}
                  </PorscheFont>
                  <div>
                    <PorscheFont className="list-item-text">
                      {checkValues(OrderApprovalData?.recalculationBy) === "-" ? "" : checkValues(OrderApprovalData?.recalculationBy)}
                    </PorscheFont>
                    <PorscheFont className="list-item-text light">
                      {formatDate(OrderApprovalData?.recalculationDate) === "-" ? "" : formatDate(OrderApprovalData?.recalculationDate)}
                    </PorscheFont>
                  </div>
                </li>
              </ul>
            </PFlex>
            <PFlex className="pflex-order-list">
              <ul className="ul-order-list">
                <li
                  className={`list-item ${
                    !orderFlags?.orderCommissionApprovalFlag
                      ? "order-pending"
                      : "order-completed"
                  }`}
                >
                  <PorscheFont className="heading-text">
                  {t('Commission approval')}
                  </PorscheFont>
                  {orderFlags?.orderCommissionApprovalFlag ? (
                    <div className="order-status-container">
                      <i className="image-wrapper">
                        <img
                          src={require("../../../../assets/images/summary/Completed.png")}
                          alt={"Completed"}
                        />
                      </i>
                      <PorscheFont className="heading-text">
                      {t("Completed")}
                      </PorscheFont>
                    </div>
                  ) : (
                    <div className="order-status-container">
                      <i className="image-wrapper">
                        <img
                          src={require("../../../../assets/images/summary/Pending.png")}
                          alt={"Pending"}
                        />
                      </i>
                      <PorscheFont className="heading-text">
                      {t("Pending")}
                      </PorscheFont>
                    </div>
                  )}
                </li>
                <li className="list-item">
                  <PorscheFont className="list-item-text order-processing-key">
                  {t("1st order check")}
                  </PorscheFont>
                  <div>
                    <PorscheFont className="list-item-text">
                      {checkValues(OrderApprovalData?.commissionCheckOneBy) === "-" ? "" : checkValues(OrderApprovalData?.commissionCheckOneBy)}
                    </PorscheFont>
                    <PorscheFont className="list-item-text light">
                      {formatDate(OrderApprovalData?.commissionCheckOneDate) === "-" ? "" : formatDate(OrderApprovalData?.commissionCheckOneDate)}
                    </PorscheFont>
                  </div>
                </li>
                <li className="list-item">
                  <PorscheFont className="list-item-text order-processing-key">
                  {t("2nd Order Check")}
                  </PorscheFont>
                  <div>
                    <PorscheFont className="list-item-text">
                      {checkValues(OrderApprovalData?.commissionCheckTwoBy) === "-" ? "" : checkValues(OrderApprovalData?.commissionCheckTwoBy)}
                    </PorscheFont>
                    <PorscheFont className="list-item-text light">
                      {formatDate(OrderApprovalData?.commissionCheckTwoDate) === "-" ? "" : formatDate(OrderApprovalData?.commissionCheckTwoDate)}
                    </PorscheFont>
                  </div>
                </li>
                {orderFlags?.orderFixedCommissionFlag ? (
                  <li className="list-item">
                    <PorscheFont className="list-item-text light order-processing-key">
                      {t("Fixed commission")}
                    </PorscheFont>
                    <div>
                      <PorscheFont className="list-item-text">
                        {checkValues(OrderApprovalData?.fixedCommissionBy) === "-" ? "" : checkValues(OrderApprovalData?.fixedCommissionBy)}
                      </PorscheFont>
                      <PorscheFont className="list-item-text light">
                        {formatDate(OrderApprovalData?.fixedCommissionDate) === "-" ? "" : formatDate(OrderApprovalData?.fixedCommissionDate)}
                      </PorscheFont>
                    </div>
                  </li>
                ) : null}
              </ul>
            </PFlex>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <PButton icon="close" onClick={handleExit} className="exit-btn">
            <PorscheFont>{t("Exit")}</PorscheFont>
          </PButton>
          {!hide && (
            <PButton
              variant="primary"
              className="next-btn"
              onClick={handleApprovalOpen}
            >
              <PorscheFont>{t("Submit")}</PorscheFont>
            </PButton>
          )}

          {/* <PButton variant="primary" className='next-btn' onClick={handleSubmit}><PorscheFont>Submit</PorscheFont></PButton> */}
        </div>

        {approvalOpen && (
          <PModal open={approvalOpen} onClose={handleApprovalClose}>
            <FirstApproval
              handleModalClose={handleApprovalClose}
              showMessage={(v, m) => showMessage(v, m)}
              id={orderValues?.id ? orderValues?.id+'' :''}
              draft={draftValueHandler(orderValues?.draft)}
            />
          </PModal>
        )}

        <div className="flex30 pt20">
          <Snackbar
            open={saveSuccess}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            autoHideDuration={2000}
            onClose={handleSuccessClose}
          >
            <Alert severity="success" sx={{ width: "100%" }}>
              {saveSuccessMsg}
            </Alert>
          </Snackbar>
        </div>
      </PFlex>
    </>
  );
};
