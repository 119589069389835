import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Alert, Snackbar, Stack} from '@mui/material';

import {
    PButton, PButtonGroup, PDivider, PFlex, PFlexItem, PGrid, PGridItem, PIcon, PModal, PRadioButtonWrapper, PTable, PTableBody,
    PTableCell, PTableHead, PTableHeadCell, PTableHeadRow, PTableRow, PText, PTextFieldWrapper
} from '@porsche-design-system/components-react';

import { PorscheFont } from '../../../assets/styles/CustomStyles';
import { AuthContext } from '../../../contexts/AuthContext';
import { IsUserAllowed } from '../../../helpers/permissions';
import { PDSFileDTO } from '../../../interfaces/Parts';
import { User, UserDetailsDTO, UserDTO } from '../../../interfaces/User';
import { PartDTO } from '../../../interfaces/Variables';
import { WorkshopAndServiceCostsDTO } from '../../../interfaces/WorkService';
import {
    formatCurrency, formatCurrencyToDecimal, formatCurrencyWithNoUnit, formatDateTime,
    getServiceAdminName, onBlurCurrencyFormatter, validateCurrency
} from '../../../mock/helper';
import {
  dcsgetParts,
  dcsgetVat,
    dcsgetWorkshopServices,
    dcspostVariableTequipment,
    dcsuploadParts,
    getLogedInOrigin,  getParts, getVat, getWorkshopServices,
    postVariableTequipment,
    uploadParts
} from '../../../services/data/Helpers';
import ScrollToTop from '../../common/ScrollToTop';
import { type } from '../../../services/Constants';
import { Constant } from '../../../configs/Constants';
import CustomSnakebar from '../../common/CustomSnakebar';

interface Props {
  changedMode: string;
  edit: boolean;
  handleWorkshop: (ser: Array<WorkshopAndServiceCostsDTO>) => void;
  modified: () => void;
  setShowSaveButton: React.Dispatch<React.SetStateAction<boolean>>;
}
export const PartsAndService = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const { edit, handleWorkshop, modified, setShowSaveButton } = props;
  const [userDetails, setUserDetails] = useState<UserDetailsDTO>({} as UserDetailsDTO)
  const [invalidFile, setInvalidFile] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const authCtx = useContext(AuthContext);
  const [demoId, setDemoId]=useState('')
  const [partDiscount, setPartDiscount] = useState(false);
  const [tequipmentCommission,setTequipmentCommission] = useState(false)

  const inputRef = useRef<HTMLInputElement>(null);

  const WSheaders = [
    "Service",
    "Price before customer (net)",
    "Price before customer (gross)",
    "Price internal",
  ];
  const PDSheaders = [
    "Part number",
    "Part description",
    "Price before customer (net)",
    "Price before customer (gross)",
    "Discount internal",
    "Price internal",
  ];

  const services = [
    "Tire Change Porsche Center (Standard)",
    "Tire Change Porsche Center (Center Lock)",
    "Tire Change Factory",
    "Tire Storage",
    "Tire shipment",
    "Workshop work(100 Time Units / 1h)",
  ];

  const [workshopServicesData, setWorkshopServicesData] = useState<
    Array<WorkshopAndServiceCostsDTO>
  >([]);
  const [vat, setVat] = useState(0);
  const [validation, setValidation] = useState(true);
  const [parts, setPart] = useState<Array<PartDTO>>([]);
  const [completePartList, setCompletePartList] = useState<Array<PartDTO>>([]);
  const [fileInfo, setFileInfo] = useState<PDSFileDTO>({} as PDSFileDTO);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reload, setReload] = useState("");
  const [errorState, setErrorState] = useState<Record<string,boolean>>()
  const [showAlert, setShowAlert] = useState(Constant.None)
  const [alertMessage, setAlertMessage] = useState(Constant.Savedsuccessfully)


  useEffect(()=>{
    if(errorState){
    const isErrorPresent  = Object.values(errorState).includes(true)
    setShowSaveButton(!isErrorPresent)
  }
  },[errorState])

  useEffect(() => {
    const init = async () => {
      const profile = sessionStorage.getItem("userDetails");
      if (profile) {
        setUserDetails(JSON.parse(profile))
      }
    };

    init();
  }, [])

  useEffect(() => {
    const init = async () => {
      const apiResponse = await dcsgetVat()
      if(apiResponse?.status===type.SUCCESS){
        const vatValue = apiResponse.response;
        setVat(vatValue?.value);
      }
    };
    init();
  }, []);

  useEffect(() => {
    if (parts?.length === 0) {
      const init = async () => {
      const apiResponse = await dcsgetParts(false)
      if(apiResponse?.status===type.SUCCESS){
        const partsValue = apiResponse?.response?.data 
        const partsInfo = apiResponse?.response?.info 
        setPart(partsValue);
        setCompletePartList(partsValue);
        setDemoId(partsInfo.id)
        setPartDiscount(partsInfo.partDiscount)
        setTequipmentCommission(partsInfo.tequipmentCommission)
        if (partsInfo) {
          setFileInfo(partsInfo);
        }
      }
      
      };
      init();
    }
  }, []);

  useEffect(() => {
    if (workshopServicesData.length === 0) {
      const init = async () => {
        const apiResponse = await dcsgetWorkshopServices();
        if (apiResponse?.status === type.SUCCESS) {
          const servicesValue = apiResponse?.response;
          setWorkshopServicesData(servicesValue);
        }

      };
      init();
    }
  }, []);

  useEffect(() => {

  }, [edit, reload, isModalOpen]);

  const REGEX_INPUT_EXP = /^[0-9]\d{0,6}((\,\d{0,2})?)$/;

  const checkRange = (value: string) => {
    if (Number(value) > 1000000 || Number(value) < 0) {
      return false;
    } else {
      return true;
    }

  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleUploadClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const searchInTable = (value: string) => {
    if (value === "" || value === undefined) {
      setPart(completePartList);
    } else {
      const filtered = parts.filter(
        (item) =>
          item.serialNumber
            .toString()
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          item.part
            .toString()
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase())
      );
      setPart(filtered);
      modified();
    }
  };

  const [loaderImg, setLoaderImg] = useState(false);
  const handleFileChange = (e: any) => {
    setLoaderImg(true)
    const formData = new FormData();
    const name = e.target.files[0].name.toString();
    const splits = name.split(".");
    let invalid = false;
    if (splits.length > 0) {
      if (splits[1] !== "xlsx") invalid = true;
    } else {
      invalid = true;
    }
    setInvalidFile(invalid);
    if (!invalid) {
      formData.append("file", e.target.files[0]);
      formData.append("user", userDetails.ppnFirstName ? userDetails.ppnFirstName + " " + (userDetails.ppnLastName ? userDetails.ppnLastName : "") : "");
      formData.append("originId", getLogedInOrigin()?.id.toString());
      const uploadFile = async () => {
            const apiResponse = await dcsuploadParts(formData)
        if(apiResponse.status===type.SUCCESS){
          setShowAlert(Constant.Success)
          setAlertMessage(Constant.Savedsuccessfully)
          setPart(apiResponse.response?.data);
          if (apiResponse.response?.info) {
            setFileInfo(apiResponse.response?.info);
          }
          setIsModalOpen(false);
          setLoaderImg(false)
        }
         if(apiResponse.status===type.ERROR){
          setShowAlert(Constant.Error)
          setAlertMessage(apiResponse.response)
          setIsModalOpen(false);
          setLoaderImg(false)
         }
      };
      modified();

      uploadFile();
    } else {
      setIsModalOpen(false);
      setLoaderImg(false)
    }
  };

  const handlePriceBeforeNet = (value: string, id: number) => {
    const dom = document.getElementById(`pbn${id}`);
    let elementDomId= dom?.id as string
    if(validateCurrency(value)) {
      if (dom) {
        dom.setAttribute("state", "none");
        setErrorState((prev)=>{
          return {...prev, [elementDomId]:false}
        })
      }

    workshopServicesData?.map((item, i) => {
      if (item.id === id) {
        item.priceBeforeCustomerNet = formatCurrencyToDecimal(value);
        item.priceBeforeCustomerGross = item.priceBeforeCustomerNet * (1 + vat / 100);
      }
    })

      setWorkshopServicesData(workshopServicesData);
      modified();
      handleWorkshop(workshopServicesData);
      setReload(new Date().toLocaleString());
    }
   
    else {
      if(dom) {
        dom.setAttribute("state", "error");
        setErrorState((prev)=>{
          return {...prev, [elementDomId]:true}
        })
      }
    }
  }

  

  const handlePriceInternal = (value: string, id: number) => {
    const dom = document.getElementById(`pi${id}`);
    let elementDomId= dom?.id as string

    if(validateCurrency(value)) {
    if (dom) {
      dom.setAttribute("state", "none");
      setErrorState((prev)=>{
        return {...prev, [elementDomId]:false}
      })
    }

    workshopServicesData?.map((item, i) => {
      if (item.id === id) {
        item.priceInternal = formatCurrencyToDecimal(value);
      }
    })

    setWorkshopServicesData(workshopServicesData);
    modified();
    handleWorkshop(workshopServicesData);
    } else {
      if(dom) {
        dom.setAttribute("state", "error");
        setErrorState((prev)=>{
          return {...prev, [elementDomId]:true}
        })
      }
    }
  }

  const fixFormat = (num: string | undefined) => {
    const oneDecimalPlace = /\,\d{1}$/g;
    const noDecimalPlacesWithDecimal = /\,\d{0}$/g;

    //const value = num?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    const value = num?.replace(".", ",");

    if (value?.includes(",") === false && value !== "") {
      return value + ",00";
    } else if (value?.match(noDecimalPlacesWithDecimal)) {
      return value + "00";
    } else if (value?.match(oneDecimalPlace)) {
      return value + "0";
    } else {
      return value;
    }
  };

  const handleShowAll = () => {
    const init = async () => {
     const apiResponse = await dcsgetParts(!showAll)
      if(apiResponse?.status===type.SUCCESS){
        const partsValue = apiResponse?.response?.data 
        const partsInfo = apiResponse?.response?.info 
        setPart(partsValue);
        setCompletePartList(partsValue);
        if (partsInfo) {
          setFileInfo(partsInfo);
        }
      }
    };
    init();
    setShowAll(!showAll);
  }

  const partDiscountHandler = async(value:boolean)=>{
   setPartDiscount(value)
   const apiResponse  =  await dcspostVariableTequipment({ originId:demoId, tab:'partDiscount',value:value})
     if(apiResponse.status===type.SUCCESS){
      setShowAlert(Constant.Success)
      setAlertMessage(Constant.Savedsuccessfully)
     }
     if(apiResponse.status===type.ERROR){
      setShowAlert(Constant.Error)
      setAlertMessage(apiResponse.response)
     }
  }

  const tequipmentCommissionHandler= async(value:boolean)=>{
    setTequipmentCommission(value)
  const apiResponse =  await dcspostVariableTequipment({ originId:demoId, tab:'tequipmentCommission',value:value})
  if(apiResponse.status===type.SUCCESS){
    setShowAlert(Constant.Success)
    setAlertMessage(Constant.Savedsuccessfully)
   }
   if(apiResponse.status===type.ERROR){
    setShowAlert(Constant.Error)
    setAlertMessage(apiResponse.response)
   }
  }


  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    setShowAlert(Constant.None)
    setAlertMessage("")
  };

  return (
    <>
      <div className="container-variable" style={{paddingTop: "30px"}}> 
        <ScrollToTop /> 
        <PText weight="bold" size="medium">
          <PorscheFont>{t('Workshop services')}</PorscheFont>
        </PText>
        <div className="container-element">
          <PTable caption="Some caption">
            <PTableHead>
              <PTableHeadRow>
                {WSheaders?.map((item, i) => (
                  <PTableHeadCell key={i} multiline={true} style={item !== "Service" ? { textAlign: "right" } : {}}>
                    {t(item)}
                  </PTableHeadCell>
                ))}
                <PTableHeadCell></PTableHeadCell>
                <PTableHeadCell></PTableHeadCell>
                <PTableHeadCell></PTableHeadCell>
              </PTableHeadRow>
            </PTableHead>
            <PTableBody>
              {workshopServicesData?.map((item, i) => {
                return (
                  <>
                    {item.serviceNameAdmin !== "None" && (
                      <>
                        <PTableRow key={i}>
                          <PTableCell>{t(getServiceAdminName(item.serviceNameAdmin))}</PTableCell>
                          <PTableCell key={`${i}1`}>
                            <PTextFieldWrapper
                              id={`pbn${item.id}`}
                              hideLabel={true}
                              unit="€"
                              unitPosition="suffix"
                            >
                              <input
                                type="text"
                                name={`ipbn${item.id}`}
                                defaultValue={formatCurrencyWithNoUnit(item.priceBeforeCustomerNet)}
                                onChange={(e) => handlePriceBeforeNet(e.target.value, item.id)}
                                //onChange={(e) => handlePriceBeforeNet(e.target.value, item.id)}

                                disabled={edit ? false : true}
                                style={{ textAlign: "right" }}

                                onBlur={(e)=>{
                                  onBlurCurrencyFormatter(e)
                                    }
                                  }
                              />
                            </PTextFieldWrapper>
                          </PTableCell>
                          <PTableCell key={`${i}2`}>
                            <div className="read">
                              {formatCurrencyWithNoUnit(item.priceBeforeCustomerNet * (1 + vat / 100))} &nbsp;&nbsp;<span style={{ color: "#626669" }}>{"€"}</span>
                            </div>
                          </PTableCell>
                          <PTableCell key={`${i}3`}>
                            <PTextFieldWrapper
                              id={`pi${item.id}`}
                              hideLabel={true}
                              unit="€"
                              unitPosition="suffix"
                            >
                              <input
                                id={`ipi${item.id}`}
                                type="text"
                                name={`ipi${item.id}`}
                                defaultValue={formatCurrencyWithNoUnit(item.priceInternal)}
                                onChange={(e) => handlePriceInternal(e.target.value, item.id)}
                                onBlur={(e)=>{onBlurCurrencyFormatter(e)}}
                                style={{ textAlign: "right" }}
                                disabled={edit ? false : true}
                              />
                            </PTextFieldWrapper>
                          </PTableCell>
                          <PTableCell></PTableCell>
                          <PTableCell></PTableCell>
                          <PTableCell></PTableCell>
                        </PTableRow>
                      </>
                    )}
                  </>
                )
              })
              }
            </PTableBody>
          </PTable>
        </div>
      </div>

      <div className="container-variable" style={{height:'auto'}}>
        <PText weight="bold" size="medium">
          <PorscheFont>{t("PDS & Tequipment")}</PorscheFont>
        </PText>


        <PFlex justifyContent={"space-between"} style={{ marginTop: '20px', marginBottom: '100px' }}>
              <PFlexItem  >
          <PFlex style={{ width: '40%', minWidth: '360px' }}>
            <PFlexItem className="sub-headding">
              <PorscheFont style={{ padding: '10px', borderBottom: '1px solid black', width: '500px', minWidth:'300px' }}>{t('Basis for part discount')}</PorscheFont>
              <PFlex direction={'column'} style={{ gap: '10px', marginTop: '10px', marginLeft: '10px' }}>
                <PRadioButtonWrapper label={`${t("Price before customer (net)")}`}>
                  <input type="radio" name="price-before" checked={partDiscount} onClick={()=>{partDiscountHandler(true)}}/>
                </PRadioButtonWrapper>
                <PRadioButtonWrapper label={`${t("Price internal")}`}>
                  <input type="radio" name="price-internal" checked={!partDiscount}onClick={()=>{partDiscountHandler(false)}}  />
                </PRadioButtonWrapper>
              </PFlex>

            </PFlexItem>
          </PFlex>
          </PFlexItem>
          <PFlexItem>
          <PFlex style={{ width: '40%', minWidth: '360px' }}>
            <PFlexItem className="sub-headding">
              <PorscheFont style={{ padding: '10px', borderBottom: '1px solid black', width: '500px', minWidth:'300px' }}>{t('PDS & Tequipment commission')}</PorscheFont>
              <PFlex direction={'column'} style={{ gap: '10px', marginTop: '10px', marginLeft: '10px' }}>
                <PRadioButtonWrapper label={`${t("Accounted via DCS")}`}>
                  <input type="radio" name="accounted"  checked={tequipmentCommission} onClick={()=>{tequipmentCommissionHandler(true)}} />
                </PRadioButtonWrapper>
                <PRadioButtonWrapper label={`${t("Not Accounted via DCS")}`}>
                  <input type="radio" name="not-accounted" checked={!tequipmentCommission} onClick={()=>{tequipmentCommissionHandler(false)}} />
                </PRadioButtonWrapper>
              </PFlex>

            </PFlexItem>
          </PFlex>
          </PFlexItem>
        </PFlex>
        <PDivider style={{marginTop:'20px',marginBottom:'20px'}}/>

        
        <div className="container-element">
          <PGrid>
            <PGridItem size={{ base: 6, m: 6 }}>
              <PTextFieldWrapper>
                <input
                  type="search"
                  name="some-name"
                  placeholder={`${t("Search by number or description")}`}
                  onChange={(e) => searchInTable(e.target.value)}
                />
              </PTextFieldWrapper>
            </PGridItem>
            <PGridItem size={{ base: 6, m: 6 }}>
              <PButton
                variant="tertiary"
                icon="upload"
                className="upload-button"
                onClick={(e) => setIsModalOpen(true)}
                disabled={IsUserAllowed("adminVariables", authCtx) ? false : true}
              >
                {t("Upload")}
              </PButton>
            </PGridItem>
          </PGrid>
          <PGrid style={{ marginTop: "14px" }}>
            <PGridItem size={5}>
              <Stack direction="row" spacing={1}>
                <PorscheFont><span style={{ fontSize: "16px", fontWeight: "700" }}>{t("Last upload")}: </span></PorscheFont>
                <PIcon name="document" aria={{ 'aria-label': 'Document icon' }} size="small" />
                <PText><span style={{ fontSize: "16px", fontWeight: "400" }}>{fileInfo.fileLastUploadedDate ? formatDateTime(fileInfo.fileLastUploadedDate) : ""}</span></PText>
                <span style={{ fontSize: "16px", fontWeight: "700" }}>{t("By")}: </span>
                <span style={{ fontSize: "16px", fontWeight: "400" }}>{fileInfo.fileUploadedBy}</span>
              </Stack>
            </PGridItem>
          </PGrid>

          {invalidFile && (
            <PGrid style={{ marginTop: "14px" }}>
              <PGridItem size={5}>
                <PorscheFont className='dcs-title'><span style={{ color: "#D5001B" }}> {t("Please upload a valid .xlsx file")}</span></PorscheFont>
              </PGridItem>
            </PGrid>
          )}
        </div>
        <div className="container-element">
          <PTable caption="Some caption">
            <PTableHead>
              <PTableHeadRow>
                {PDSheaders?.map((item, i) => (
                  <PTableHeadCell key={i} multiline={true}>
                    <span style={{ fontSize: "16px", fontWeight: "700" }}>{t(item)}</span>
                  </PTableHeadCell>
                ))}
              </PTableHeadRow>
            </PTableHead>
            <PTableBody>

              
              {parts?.map((item, i) => (

                <PTableRow key={i}>
                  <PTableCell>{item.serialNumber}</PTableCell>
                  <PTableCell>{item.part}</PTableCell>
                  <PTableCell>
                    {formatCurrencyWithNoUnit(item.priceBeforeCustomerNet) + " €"}
                  </PTableCell>
                  <PTableCell>
                    {formatCurrencyWithNoUnit(item.priceBeforeCustomerGross) + " €"}
                  </PTableCell>
                  <PTableCell>{formatCurrencyWithNoUnit(item.discountInternal) + " %"}</PTableCell>
                  <PTableCell>
                    {formatCurrencyWithNoUnit(item.priceInternal) + " €"}
                  </PTableCell>
                </PTableRow>
              ))}
            </PTableBody>
          </PTable>
        </div>
        <div onClick={handleShowAll}>
          <Stack direction={'row'}>
            <PIcon name={showAll ? 'arrow-head-up' : 'arrow-head-down'}></PIcon>
            <PorscheFont>{showAll ? t('show less') : t('show all')}</PorscheFont>
          </Stack>
        </div>
      </div>
      <PModal open={isModalOpen} onClose={handleModalClose}>
        {loaderImg ?
          <div style={{height:"145px",width:"280px"}}>
            <div style={{marginTop:"-1px"}}>
              <PorscheFont style={{fontWeight:"700",fontSize:"14px",marginLeft:"84px"}}>{t("Processing upload")}</PorscheFont><br></br>
              <div className="loader">
              </div><br></br>
              <PorscheFont style={{fontWeight:"400",fontSize:"14px", marginLeft:"44px"}}>{t("The upload may take a few minutes.")}</PorscheFont>
              </div>
            </div>
          // <div className="loader"></div>
          : <>
          <PButtonGroup className="footer">
            <input
              style={{ display: "none" }}
              ref={inputRef}
              type="file"
              onChange={(e) => handleFileChange(e)}
              accept=".xlsx" />
            <PButton
              onClick={(e) => handleUploadClick()}
              disabled={IsUserAllowed("adminVariables", authCtx) ? false : true}
            >
              {t("New File")}
            </PButton>
          </PButtonGroup>
          <PorscheFont style={{marginTop:"16px"}}>{t("Select file")}</PorscheFont>
          </>
          }
      </PModal>

      <CustomSnakebar alertType={showAlert} alertMessage={alertMessage} handleClose={()=>handleClose()}/>

    </>
  );
};
