import { useEffect, useState } from "react";

import Stack from "@mui/material/Stack";
import { PSwitch } from "@porsche-design-system/components-react";
import { useTranslation } from 'react-i18next';
import { PorscheFont } from "../../assets/styles/CustomStyles";

interface Props {
  title: string;
  component: JSX.Element;
  handleToggle?: (show: boolean, section: string) => void;
  selected?: boolean;
  enableToggle?: boolean;
  disabled?: boolean;
  onUpsaleNewChange?:()=>void

}

export const ContentHide = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const [show, setShow] = useState<boolean>(false);
  const { title, component, handleToggle, enableToggle, disabled, onUpsaleNewChange } = props;
  const [disabledClassName, setDisabledClassName] = useState("");

  useEffect(() => {
    if (enableToggle) setShow(enableToggle);
    // if (disabled) {
    //   setDisabledClassName("disabled")
    // }
  }, [enableToggle]);

  const handleClick = () => {
    if (handleToggle) handleToggle(!show, title);
    setShow(!show);
    onUpsaleNewChange && onUpsaleNewChange()
  };

  return (
    <>
      <div className="order-card">
        <Stack direction="row" spacing={1}>
          <PorscheFont>
            <PorscheFont className="card-header">{t(title)}</PorscheFont>
          </PorscheFont>
          <PSwitch
            checked={show}
            alignLabel="left"
            style={{ paddingTop: "18px" }}
            onClick={handleClick}
            //className={disabledClassName}
            disabled={disabled}
          ></PSwitch>
        </Stack>

        {show && component}
      </div>
    </>
  );
};
