import dayjs, { Dayjs } from "dayjs";
import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

import { CalendarPickerView } from "@mui/x-date-pickers";
import {
  PButton,
  PButtonGroup,
  PGrid,
  PGridItem,
  PModal,
  PSelectWrapper,
  PText,
  PTextFieldWrapper,
} from "@porsche-design-system/components-react";
import { font } from "@porsche-design-system/utilities";

import { PorscheFont } from "../../../../assets/styles/CustomStyles";
import { OrderContext } from "../../../../contexts/OrderContext";
import {
  CustomerType,
  DeliveryLocation,
  DeliveryPresent,
  OrderDTO,
  PaymentType,
  PorscheCenterDto,
  Referral as RefDto,
  Registration,
  TradeInDetails,
  TradeInType,
  UsedCarCollection,
  VehicleCategoryDto,
} from "../../../../interfaces/Order";
import { Series } from "../../../../interfaces/Upsale";
import { UserDetailsDTO } from "../../../../interfaces/User";
import { type } from "../../../../services/Constants";
import {
  ApiReturnType,
  dcsgetCityBasedUsers,
  dcsgetOrderDetails,
  dcsgetOrderManagedVariables,
  dcsgetOrderSummary,
  dcsgetUserWithPermissions,
  getLogedInUser
} from "../../../../services/data/Helpers";
import { ContentHide } from "../../../common/ContentHide";
import { CustomSeries } from "../../../common/CustomSeries";
import { CustomSeriesModel } from "../../../common/CustomSeriesModel";
import { CustomTextField } from "../../../common/CustomTextField";
import "../../tabs/details/detailsCSS/OrderDetailsTab.css";
import { Customer } from "./Customer";
import { Delivery } from "./Delivery";
import { Payment } from "./Payment";
import { Referral } from "./Referral";
import { TradeIn } from "./TradeIn";
import getSessionStorage from "../../../../helpers/getSessionStorage";
import { Constant } from "../../../../configs/Constants";
import draftValueHandler from "../../../../helpers/sessionDraftValueHandler";
import { AuthContext } from "../../../../contexts/AuthContext";

interface Props {
  views?: CalendarPickerView[];
  handleChange?: (e: any) => void | undefined;
  onNextTabChange: (i: number) => void;
  handleMinMandatoryFields: (isValid: boolean) => void;
  isOrderSavedAsDraft: boolean | undefined;
  changedMode: string;
  handleModified: () => void;
}

export const OrderDetailsTab = (props: Props): JSX.Element => {
  const navigate = useNavigate();
  const {
    onNextTabChange,
    handleMinMandatoryFields,
    isOrderSavedAsDraft,
    changedMode,
    handleModified,
    views,
    handleChange,
  } = props;
  const orderValues = getSessionStorage(Constant.SingleOrdDetail);
  const orderContext = useContext(OrderContext);
  const authCtx = useContext(AuthContext);
  const errors = orderContext.getErrors();
  const [models, setModels] = useState<any>();
  const { t } = useTranslation();
  // const [value, setValue] = React.useState<Dayjs | null>(dayjs('2022.04.07'));
  const [value, setValue] = React.useState<Dayjs | null>(dayjs(""));
  const [orderDate, setOrderDate] = React.useState<Dayjs | null>(dayjs(""));
  const [vehicleCategories, setVehicleCategories] = useState<
    Array<VehicleCategoryDto>
  >([]);
  const [porscheCenters, setPorscheCenters] = useState<Array<PorscheCenterDto>>(
    []
  );
  const [customerTypes, setCustomerTypes] = useState<Array<CustomerType>>([]);
  const [paymentTypes, setPaymentTypes] = useState<Array<PaymentType>>([]);
  const [usedCarCollections, setUsedCarCollections] = useState<
    Array<UsedCarCollection>
  >([]);
  const [tradeIns, setTradeIns] = useState<Array<TradeInType>>([]);
  const [deliveryLocations, setDeliveryLocations] = useState<
    Array<DeliveryLocation>
  >([]);
    const [deliveryPresents, setDeliveryPresents] = useState<
    Array<DeliveryPresent>
  >([]);
  const [registrations, setRegistrations] = useState<Array<Registration>>([]);
  const [openExit, setOpenExit] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [orderDto, setOrderDto] = useState<OrderDTO>({} as OrderDTO);
  const [dateOfOrder, setDateOfOrder] = useState("");
  const [quotaMonth, setQuotaMonth] = useState("");
  const [reload, setReload] = useState("");
  const [editOrViewMode, setEditOrViewMode] = useState<boolean>(false);
  const [series, setSeries] = useState<Array<Series>>([]);
  const [vehicleCategoryId, setVehicleCategoryId] = useState<number>(0);
  const [tradeInToggle, setTradeInToggle] = useState<boolean>(false);
  const [referralToggle, setReferralToggle] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [orderDetails, setOrderDetails] = useState<OrderDTO>();
  const [porscheCenterId, setPorscheCenterId] = useState<number>(0);
  const [mode, setMode] = useState("");
  const [draft, setDraft] = useState("");
  const [actNumber, setActNumber] = useState<any>(orderDetails?.vehicleDetails?.actNumber || "");
  const [commissionNumber, setCommissionNumber] = useState("");
  const [usedCarCollection, setUsedCarCollection] = useState("");
  const [gross, setGross] = useState("");
  const [purchasers, setPurchasers] = useState<Array<UserDetailsDTO>>([]);
  const dateFormat = "dd.mm.yyyy";
  const [orderErrors, setOrderErrors] = useState<Array<string>>([]);
  const [showWarning, setWarning] = useState<boolean>(false);
  const [showWarningVin, setWarningVin] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState<UserDetailsDTO>({} as UserDetailsDTO)
  const [isQuotaMonthError, setIsQuotaMonthError] = useState(false)
  const [vin, setVin] = useState<string>(
    orderDetails?.vehicleDetails?.vin || ""
    );

  useEffect(() => {
    const isFormValid = isValidVehicleDetailsSection();
    orderContext.updateFormValidation(0, isFormValid);
    enableNextButton(isFormValid);

    if (isFormValid) removeMandatoryFields();
  });

  useEffect(() => {
    const init = async () => {
      await loadManagedVariables();
      await loadOrderDetails();


      if (mode !== "view") minMandatoryFields();
    };

    init();
  }, [mode]);

  useEffect(() => {
    const init = async () => {
      let userDetails = await authCtx.getUserDetails();
      if(userDetails) {
        setUserDetails(userDetails);
      } else {
        const apiResponse = await dcsgetUserWithPermissions()
        if(apiResponse.status===type.SUCCESS){
          setUserDetails(apiResponse.response);
          authCtx.updateUserDetails(apiResponse.response);
        }  
      }
    };

    init();
  }, [])

  useEffect(() => {
    const category_models = vehicleCategories?.filter(
      (x) => x.id === orderDetails?.vehicleDetails?.category.id
    );
    setModels(category_models);
  }, [vehicleCategories]);

  useEffect(() => {}, [vehicleCategories, errors, reload, orderErrors]);

  useEffect(() => {
    if (changedMode) {
      setMode(changedMode);
      if (changedMode === "view") {
        setDisabled(true);
        //setGross(changedMode);
      }
      if (changedMode === "edit") {
        setDisabled(false);
        //setGross(changedMode);
      }
      setReload(new Date().toString());
    }
  }, [changedMode, reload]);


  
  const loadOrderDetails = async () => {
    const id = orderValues?.id ? orderValues?.id+'' :''
    const mode = orderValues?.mode ? orderValues?.mode+'' :''
    const inReviewRejected = orderValues?.inReviewRejected? orderValues.inReviewRejected :''
    setMode(mode);
    const isDraftVersion = draftValueHandler(orderValues?.draft);
    isDraftVersion &&  setDraft(isDraftVersion);
    if (id && (mode === "view" || mode === "edit")) {
      let apiResponse: ApiReturnType;
      if (isDraftVersion === "false" ||  inReviewRejected==="Yes") {
        apiResponse = await dcsgetOrderSummary(parseInt(id));
      } else {
        apiResponse = await dcsgetOrderDetails(true, parseInt(id));
      
      }
      
      // apiResponse = await dcsgetOrderSummary(parseInt(id));

      if (apiResponse.status===type.SUCCESS) {
        let orderDetails = apiResponse.response?.order || {};
        let upsaleDetails = apiResponse.response?.upsale || {};
        let calculationDetails =
          apiResponse.response?.calculation ||
          orderContext.getCtxCalculationDraftDetails(
            orderDetails?.vehicleDetails?.category?.type
          );

        if (mode === "view") setDisabled(true);
        if (mode === "edit") setDisabled(false);

        orderContext.updateCtxOrderDetails(orderDetails);

        orderContext.updateCtxUpsaleDetails(upsaleDetails);
        orderContext.updateCtxCalculationDraftDetails(
          calculationDetails,
          orderDetails.vehicleDetails.category.type
        );

        setOrderDetails(orderDetails);
        setReload(new Date().toString());

        if (orderDetails.porscheCenter?.id) {
          setPorscheCenterId(orderDetails.porscheCenter.id);
        }
        if (orderDetails.vehicleDetails?.category) {
          setVehicleCategoryId(orderDetails.vehicleDetails.category.id);
        }
        if (orderDetails.dateOfOrder) {
          setDateOfOrder(
            new Date(orderDetails.dateOfOrder).toISOString().split("T")[0]
          );
        }
        if (orderDetails?.vehicleDetails?.actNumber) {
          setActNumber(orderDetails.vehicleDetails.actNumber.toString());
        }
        if (orderDetails?.vehicleDetails?.commissionNumber) {
          setCommissionNumber(
            orderDetails.vehicleDetails.commissionNumber.toString()
          );
        }
        if (orderDetails?.vehicleDetails?.usedCarCollection) {
          setUsedCarCollection(orderDetails.vehicleDetails.usedCarCollection);
        }

        orderContext.clearErrors();
        setReload(new Date().toString());
      }
    } else {
      let enteredDetails = orderContext.getCtxOrderDetails();

      if (enteredDetails.vehicleDetails) {
        setOrderDetails(enteredDetails);
        setVehicleCategoryId(enteredDetails.vehicleDetails.category.id);
        setDateOfOrder(
          new Date(enteredDetails.dateOfOrder).toISOString().split("T")[0]
        );
        setMode("view");
        setDisabled(false);
        orderContext.clearErrors();
        setReload(new Date().toString());
      }

      if (
        enteredDetails.orderCreator === undefined ||
        enteredDetails.orderCreator === null
      ) {
        enteredDetails.orderCreator = {
          id: getLogedInUser().id,
          name: getLogedInUser().name ? getLogedInUser().name : "",
          share: getLogedInUser().share,
        };
        orderContext.updateCtxOrderDetails(enteredDetails);
      }
    }
  };

  const loadManagedVariables = async () => {
    const variables = await dcsgetOrderManagedVariables(1, orderValues? true:false)
    if (variables) {
      setVehicleCategories(variables[0]);
      setPorscheCenters(variables[1]);
      setCustomerTypes(variables[2]);
      setPaymentTypes(variables[3]);
      setTradeIns(variables[4]);
      setDeliveryLocations(variables[5]);
      setDeliveryPresents(variables[6]);
      setRegistrations(variables[7]);
      setSeries(variables[8]);
      setUsedCarCollections(variables[9]);

      let dto = orderContext.getCtxOrderDetails();
      if (dto.id && dto.id > 0) {
        orderDto.porscheCenter = dto.porscheCenter || null;
      } else {
        orderDto.porscheCenter = dto.porscheCenter || null;
        orderDto.orderCreator = {
          id: getLogedInUser().id,
          share: getLogedInUser().share,
        };
        orderDto.status = "Draft";
      }

      setOrderDto(dto);
    }

    const apiResponse = await dcsgetCityBasedUsers()
    if (apiResponse.status===type.SUCCESS) {
      setPurchasers(apiResponse.response);
    }
  };

  const isValidVehicleDetailsSection = (): boolean => {
    const errors = orderContext.getErrors();
    return errors.every((error) => {
      if (error.section === "vehicleDetails") return false;
      if (error.section === "customerDetails") return false;
      if (error.section === "paymentDetails") return false;
      if (error.section === "deliveryDetails") return false;
      if (tradeInToggle && error.section === "tradeInDetails") return false;
      if (referralToggle && error.section === "referalDetails") return false;
      return true;
    });
  };

  const handleCloseTab = () => {
    window.opener = null;
    window.open("", "_self");
    window.close();
  };

  const enableNextButton = (isValid: boolean) => {
    setIsValid(isValid);
  };

  const removeMandatoryFields = () => {
    orderContext.removeError("vehicleDetails");
    orderContext.removeError("customerDetails");
    orderContext.removeError("paymentDetails");
    orderContext.removeError("deliveryDetails");
    orderContext.removeError("porscheCenter");
    orderContext.removeError("dateOfOrder");
    orderContext.removeError("category");
    orderContext.removeError("seriesAndModel");
  };

  const minMandatoryFields = () => {
    addSectionDetails();
    orderContext.setErrors({
      field: "porscheCenter",
      message: "",
      section: "vehicleDetails",
    });
    orderContext.setErrors({
      field: "dateOfOrder",
      message: "",
      section: "vehicleDetails",
    });
    orderContext.setErrors({
      field: "category",
      message: "",
      section: "vehicleDetails",
    });
    orderContext.setErrors({
      field: "seriesAndModel",
      message: "",
      section: "vehicleDetails",
    });
  };

  const addSectionDetails = () => {
    orderContext.setErrors({
      field: "vehicleDetails",
      message: "",
      section: "vehicleDetails",
    });
    orderContext.setErrors({
      field: "customerDetails",
      message: "",
      section: "customerDetails",
    });
    orderContext.setErrors({
      field: "paymentDetails",
      message: "",
      section: "paymentDetails",
    });
    orderContext.setErrors({
      field: "deliveryDetails",
      message: "",
      section: "deliveryDetails",
    });
  };
  const handleNextButton = () => {
    // if(isValidVehicleDetailsSection()) {
    // if (validateOrderDetails()) {
    //   onNextTabChange(2)
    // } else {

    // }

    let errs = validateOrder();
    if (errs.length > 0 || isQuotaMonthError) {
    } else {
      onNextTabChange(2);
    }
    //onNextTabChange(2)
  };

  const validateOrder = () => {
    const orderDetails = orderContext.getCtxOrderDetails();
    let errs: Array<string> = [];
    /* Vehicle details section */
    if (orderDetails?.porscheCenter?.id) {
    } else {
      if (errs.indexOf("porscheCenter") < 0) errs.push("porscheCenter");
    }

    if (orderDetails?.dateOfOrder?.length > 0) {
    } else {
      if (errs.indexOf("dateOfOrder") < 0) errs.push("dateOfOrder");
    }

    if (orderDetails?.vehicleDetails?.category?.type.length > 0) {
      switch (orderDetails?.vehicleDetails?.category?.type) {
        case "NewCar":
          if (
            orderDetails?.vehicleDetails?.category?.option === "Configured" ||
            orderDetails?.vehicleDetails?.category?.option === "ServiceLoaner"
          ) {
            if (
              orderDetails?.vehicleDetails?.series?.id &&
              orderDetails?.vehicleDetails?.model?.id
            ) {
            } else {
              if (errs.indexOf("seriesModel") < 0) errs.push("seriesModel");
            }

            if (orderDetails?.vehicleDetails?.quotaMonth) {
            } else {
              if (errs.indexOf("quotaMonth") < 0) errs.push("quotaMonth");
            }
          } else if (
            orderDetails?.vehicleDetails?.category?.option === "Stock"
          ) {
            if (
              orderDetails?.vehicleDetails?.series?.id &&
              orderDetails?.vehicleDetails?.model?.id
            ) {
            } else {
              if (errs.indexOf("seriesModel") < 0) errs.push("seriesModel");
            }

            if (
              orderDetails?.vehicleDetails?.quotaMonth &&
              orderDetails?.vehicleDetails?.quotaMonth.length > 0
            ) {
            } else {
              if (errs.indexOf("quotaMonth") < 0) errs.push("quotaMonth");
            }

            if (orderDetails?.vehicleDetails?.commissionNumber) {
            } else {
              if (errs.indexOf("commissionNumber") < 0)
                errs.push("commissionNumber");
            }
          } else if (
            orderDetails?.vehicleDetails?.category?.option === "Other"
          ) {
            if (orderDetails?.vehicleDetails?.modelDescription?.length > 0) {
            } else {
              if (errs.indexOf("modelDescription") < 0)
                errs.push("modelDescription");
            }
          }
          break;
        case "DemoCar":
          if (orderDetails?.vehicleDetails?.category?.option === "Active") {
            if (
              orderDetails?.vehicleDetails?.series?.id &&
              orderDetails?.vehicleDetails?.model?.id
            ) {
            } else {
              if (errs.indexOf("seriesModel") < 0) errs.push("seriesModel");
            }

            if (orderDetails?.vehicleDetails?.actNumber) {
            } else {
              if (errs.indexOf("actNumber") < 0) errs.push("actNumber");
            }

            if (orderDetails?.vehicleDetails?.commissionNumber) {
            } else {
              if (errs.indexOf("commissionNumber") < 0)
                errs.push("commissionNumber");
            }

            if (orderDetails?.vehicleDetails?.licensePlate) {
            } else {
              if (errs.indexOf("licensePlate") < 0) errs.push("licensePlate");
            }
          } else if (
            orderDetails?.vehicleDetails?.category?.option === "Passive"
          ) {
            if (
              orderDetails?.vehicleDetails?.series?.id &&
              orderDetails?.vehicleDetails?.model?.id
            ) {
            } else {
              if (errs.indexOf("seriesModel") < 0) errs.push("seriesModel");
            }

            if (orderDetails?.vehicleDetails?.actNumber) {
            } else {
              if (errs.indexOf("actNumber") < 0) errs.push("actNumber");
            }

            if (orderDetails?.vehicleDetails?.commissionNumber) {
            } else {
              if (errs.indexOf("commissionNumber") < 0)
                errs.push("commissionNumber");
            }
          } else if (
            orderDetails?.vehicleDetails?.category?.option === "PreSold"
          ) {
            if (
              orderDetails?.vehicleDetails?.series?.id &&
              orderDetails?.vehicleDetails?.model?.id
            ) {
            } else {
              if (errs.indexOf("seriesModel") < 0) errs.push("seriesModel");
            }

            if (orderDetails?.vehicleDetails?.quotaMonth) {
            } else {
              if (errs.indexOf("quotaMonth") < 0) errs.push("quotaMonth");
            }
          }
          break;
        case "UsedCar":
          if (orderDetails?.vehicleDetails?.category?.option === "Stock") {
            if (orderDetails?.vehicleDetails?.series?.id) {
            } else {
              if (errs.indexOf("seriesOnly") < 0) errs.push("seriesOnly");
            }

            if (
              orderDetails?.vehicleDetails?.modelDescription &&
              orderDetails?.vehicleDetails?.modelDescription.length > 0
            ) {
            } else {
              if (errs.indexOf("modelDescription") < 0)
                errs.push("modelDescription");
            }

            // if (orderDetails?.vehicleDetails?.vin) {
            // } else {
            //   if (errs.indexOf("vinNumber") < 0) errs.push("vinNumber");
            // }

            if (orderDetails?.vehicleDetails?.usedCarCollection) {
            } else {
              if (errs.indexOf("usedCarCollection") < 0)
                errs.push("usedCarCollection");
            }

            if (
              orderDetails?.vehicleDetails?.purchaser &&
              orderDetails?.vehicleDetails?.purchaser?.id
            ) {
            } else {
              if (errs.indexOf("purchaser") < 0) errs.push("purchaser");
            }
          } else if (
            orderDetails?.vehicleDetails?.category?.option === "Other"
          ) {
            if (orderDetails?.vehicleDetails?.modelDescription.length > 0) {
            } else {
              if (errs.indexOf("modelDescription") < 0)
                errs.push("modelDescription");
            }

            if (orderDetails?.vehicleDetails?.usedCarCollection) {
            } else {
              if (errs.indexOf("usedCarCollection") < 0)
                errs.push("usedCarCollection");
            }

            if (
              orderDetails?.vehicleDetails?.purchaser &&
              orderDetails?.vehicleDetails?.purchaser?.id
            ) {
            } else {
              if (errs.indexOf("purchaser") < 0) errs.push("purchaser");
            }
          }
          break;
      }
    } else {
      if (errs.indexOf("category") < 0) errs.push("category");
    }

    // Customer details section
    if (orderDetails?.customerDetails?.customerGroup) {
    } else {
      if (errs.indexOf("cusGroup") < 0) errs.push("cusGroup");
    }

    if (orderDetails?.customerDetails?.customerGroupType) {
    } else {
      if (errs.indexOf("cusType") < 0) errs.push("cusType");
    }

    if (orderDetails?.customerDetails?.customerType) {
      if (
        orderDetails?.customerDetails?.customerGroupType === "PrivateCustomer"
      ) {
        if (orderDetails?.customerDetails?.firstName) {
        } else {
          if (errs.indexOf("cusFirstName") < 0) errs.push("cusFirstName");
        }
        if (orderDetails?.customerDetails?.lastName) {
        } else {
          if (errs.indexOf("cusLastName") < 0) errs.push("cusLastName");
        }
        if (orderDetails?.customerDetails?.copNumber) {
        } else {
          if (errs.indexOf("copNumber") < 0) errs.push("copNumber");
        }
      } else if (
        orderDetails?.customerDetails?.customerGroupType === "BusinessClient"
      ) {
        if (orderDetails?.customerDetails?.companyName) {
        } else {
          if (errs.indexOf("cusCompanyName") < 0) errs.push("cusCompanyName");
        }
        if (orderDetails?.customerDetails?.firstName) {
        } else {
          if (errs.indexOf("cusFirstName") < 0) errs.push("cusFirstName");
        }
        if (orderDetails?.customerDetails?.lastName) {
        } else {
          if (errs.indexOf("cusLastName") < 0) errs.push("cusLastName");
        }
        if (orderDetails?.customerDetails?.copNumber) {
        } else {
          if (errs.indexOf("copNumber") < 0) errs.push("copNumber");
        }

        if (orderDetails?.customerDetails?.deviantDriver) {
          if (orderDetails?.customerDetails?.driverFirstName) {
          } else {
            if (errs.indexOf("driverFirstName") < 0)
              errs.push("driverFirstName");
          }
          if (orderDetails?.customerDetails?.driverLastName) {
          } else {
            if (errs.indexOf("driverLastName") < 0) errs.push("driverLastName");
          }
        }

        if (orderDetails?.customerDetails?.differentAddressFlag) {
          if (orderDetails?.customerDetails?.differentAddress) {
            if (orderDetails?.customerDetails?.differentAddress?.street) {
            } else {
              if (errs.indexOf("dStreet") < 0) errs.push("dStreet");
            }
            if (orderDetails?.customerDetails?.differentAddress?.houseNumber) {
            } else {
              if (errs.indexOf("dHouseNumber") < 0) errs.push("dHouseNumber");
            }
            if (orderDetails?.customerDetails?.differentAddress?.postalCode) {
            } else {
              if (errs.indexOf("dPostalCode") < 0) errs.push("dPostalCode");
            }
            if (orderDetails?.customerDetails?.differentAddress?.city) {
            } else {
              if (errs.indexOf("dCity") < 0) errs.push("dCity");
            }
          } else {
            if (errs.indexOf("dStreet") < 0) errs.push("dStreet");
            if (errs.indexOf("dHouseNumber") < 0) errs.push("dHouseNumber");
            if (errs.indexOf("dPostalCode") < 0) errs.push("dPostalCode");
            if (errs.indexOf("dCity") < 0) errs.push("dCity");
          }
        }
      }
    } else {
      if (errs.indexOf("customerType") < 0) errs.push("customerType");
    }

    // Payment Details
    if (orderDetails?.paymentDetails) {
      if (orderDetails?.paymentDetails?.paymentType) {
        if (orderDetails?.paymentDetails?.paymentType !== "DebitCredit") {
          if (
            orderDetails?.paymentDetails?.durationInMonths !== undefined &&
            orderDetails?.paymentDetails?.durationInMonths > 0
          ) {
          } else {
            if (errs.indexOf("durationInMonths") < 0)
              errs.push("durationInMonths");
          }
        }

        if (orderDetails?.paymentDetails?.paymentType === "Leasing_Other") {
          if (orderDetails?.paymentDetails?.leasingCompany) {
          } else {
            if (errs.indexOf("leasingCompany") < 0) errs.push("leasingCompany");
          }
        }

        if (orderDetails?.paymentDetails?.paymentType === "Financing_Other") {
          if (orderDetails?.paymentDetails?.financingCompany) {
          } else {
            if (errs.indexOf("financingCompany") < 0)
              errs.push("financingCompany");
          }
        }
      } else {
        if (errs.indexOf("paymentType") < 0) errs.push("paymentType");
      }

      // Need to handle durations in months
      // if(orderDetails?.paymentDetails?.durationInMonths) {
      // }
    } else {
      if (errs.indexOf("paymentType") < 0) errs.push("paymentType");
    }

    // Delivery details
    if (orderDetails?.deliveryDetails) {
      if (orderDetails?.deliveryDetails?.deliveryLocation) {
      } else {
        if (errs.indexOf("deliveryLocation") < 0) errs.push("deliveryLocation");
      }
      if (
        orderDetails?.deliveryDetails?.plannedDelivery &&
        orderDetails?.deliveryDetails?.plannedDelivery.length > 0
      ) {
      } else {
        if (errs.indexOf("plannedDelivery") < 0) errs.push("plannedDelivery");
      }
      if (orderDetails?.deliveryDetails?.deliveryPresentDto !== undefined) {
        // if(orderDetails?.deliveryDetails?.deliveryPresentDto?.id) {
        // } else {
        //  if(errs.indexOf("deliveryPresent") < 0) errs.push("deliveryPresent");
        //}
      } else {
        if (errs.indexOf("deliveryPresent") < 0) errs.push("deliveryPresent");
      }
      if (orderDetails?.deliveryDetails?.registrationDto) {
        if (orderDetails?.deliveryDetails?.registrationDto?.registrationType) {
          if (
            orderDetails?.deliveryDetails?.registrationDto?.registrationType ===
            "Standard"
          ) {
            if (orderDetails?.deliveryDetails?.preferredLicensePlate) {
            } else {
              if (errs.indexOf("dLicensePlate") < 0) errs.push("dLicensePlate");
            }
          }
        } else {
          if (errs.indexOf("registration") < 0) errs.push("registration");
        }
      } else {
        if (errs.indexOf("registration") < 0) errs.push("registration");
      }
    } else {
      if (errs.indexOf("deliveryLocation") < 0) errs.push("deliveryLocation");
      if (errs.indexOf("plannedDelivery") < 0) errs.push("plannedDelivery");
      if (errs.indexOf("deliveryPresent") < 0) errs.push("deliveryPresent");
      if (errs.indexOf("registration") < 0) errs.push("registration");
    }

    // TradeIn validations
    if (orderDetails?.tradeInDetails) {
      if (orderDetails?.tradeInDetails?.tradeInType) {
      } else {
        if (errs.indexOf("tradeInCategory") < 0) errs.push("tradeInCategory");
      }
      if (orderDetails?.tradeInDetails?.value) {
      } else {
        if (errs.indexOf("manufacturer") < 0) errs.push("manufacturer");
      }
      if (orderDetails?.tradeInDetails?.vin) {
        const vinLength = orderDetails.tradeInDetails.vin.length;
        if (vinLength < 17) {
          errs.push("tradeInVin");
        }
      } else {
        if (errs.indexOf("tradeInVin") < 0) errs.push("tradeInVin");
      }
    }

    // Referral Details
    if (orderDetails?.referral) {
      if (orderDetails?.referral?.referralType) {
        if (
          orderDetails?.referral?.referralType === "External_Private" ||
          orderDetails?.referral?.referralType === "External_Common"
        ) {
          if (orderDetails?.referral?.surName) {
          } else {
            if (errs.indexOf("refFirstName") < 0) errs.push("refFirstName");
          }

          if (orderDetails?.referral?.lastName) {
          } else {
            if (errs.indexOf("refLastName") < 0) errs.push("refLastName");
          }

          if (orderDetails?.referral?.street) {
          } else {
            if (errs.indexOf("refStreet") < 0) errs.push("refStreet");
          }

          if (orderDetails?.referral?.houseNumber) {
          } else {
            if (errs.indexOf("refHouseNumber") < 0) errs.push("refHouseNumber");
          }

          if (orderDetails?.referral?.postalCode) {
          } else {
            if (errs.indexOf("refPostalCode") < 0) errs.push("refPostalCode");
          }
          if (orderDetails?.referral?.city) {
          } else {
            if (errs.indexOf("refCity") < 0) errs.push("refCity");
          }
        } else if (orderDetails?.referral?.referralType === "Internal_Empl") {
          if (orderDetails?.referral?.surName) {
          } else {
            if (errs.indexOf("refFirstName") < 0) errs.push("refFirstName");
          }

          if (orderDetails?.referral?.lastName) {
          } else {
            if (errs.indexOf("refLastName") < 0) errs.push("refLastName");
          }

          if (orderDetails?.referral?.staffNumber) {
          } else {
            if (errs.indexOf("staffNumber") < 0) errs.push("staffNumber");
          }
          if (orderDetails?.referral?.referralCommission!==null) {
          } else {
            if (errs.indexOf("intRefCom") < 0) errs.push("intRefCom");
          }
        }
      } else {
        if (errs.indexOf("referralType") < 0) errs.push("referralType");
      }
    }

    // Set the dom errors and focus to first one
    errs.map((id, i) => {
      if (
        id === "seriesModel" ||
        id === "seriesOnly" ||
        id === "cusGroup" ||
        id === "cusType" ||
        id === "durationInMonths" ||
        id === "manufacturer" ||
        id === "referralType"
      ) {
        let sdom = document.getElementById(id);
        if (sdom) {
          sdom.setAttribute("class", "series-model-error-display");
          if (i === 0) sdom.scrollIntoView();
        }
      } else {
        let dom = document.getElementById(id + "Wrap");
        if (dom) dom.setAttribute("state", "error");
        if (i === 0) {
          let idom = document.getElementById(id);
          if (idom) idom.focus();
        }
      }
    });

    setOrderErrors(errs);
    return errs;
  };

  const updateError = (id: string) => {
    if (
      id === "seriesModel" ||
      id === "seriesOnly" ||
      id === "cusGroup" ||
      id === "cusType" ||
      id === "durationInMonths" ||
      id === "manufacturer" ||
      id === "referralType"
    ) {
      let sdom = document.getElementById(id);
      if (sdom) {
        sdom.setAttribute("class", "series-model-error-hide");
      }
    } else {
      let dom = document.getElementById(id + "Wrap");
      if (dom) {
        let state = dom.getAttribute("state");
        if (state === "error") {
          //validateOrder();
          dom.setAttribute("state", "none");
        }
      }
    }
  };

  const validateOrderDetails = () => {
    const orderDetails = orderContext.getCtxOrderDetails();
    // Check porsch center
    if (orderDetails.porscheCenter) {
      if (orderDetails.porscheCenter.id < 1) return false;
    } else {
      return false;
    }

    if (orderDetails.dateOfOrder) {
    } else {
      return false;
    }

    // vehicle details validation
    if (orderDetails.vehicleDetails) {
      if (orderDetails.vehicleDetails.category) {
        if (orderDetails.vehicleDetails.category.id < 1) return false;
        if (
          orderDetails.vehicleDetails.category.id === 1 ||
          orderDetails.vehicleDetails.category.id === 3 ||
          orderDetails.vehicleDetails.category.id === 7
        ) {
          if (
            orderDetails.vehicleDetails.quotaMonth === undefined ||
            orderDetails.vehicleDetails.quotaMonth === null
          )
            return false;
        }
        if (orderDetails.vehicleDetails.category.id === 2) {
          if (
            orderDetails.vehicleDetails.quotaMonth === undefined ||
            orderDetails.vehicleDetails.quotaMonth === null
          )
            return false;
          if (
            orderDetails.vehicleDetails.commissionNumber === undefined ||
            orderDetails.vehicleDetails.commissionNumber === null
          )
            return false;
          // if (orderDetails.vehicleDetails.commissionNumber === undefined) return false;
        }
        if (orderDetails.vehicleDetails.category.id === 4) {
          if (orderDetails.vehicleDetails.series === undefined) return false;
          if (orderDetails.vehicleDetails.series.id === undefined) return false;
          if (
            orderDetails.vehicleDetails.modelDescription === undefined ||
            orderDetails.vehicleDetails.modelDescription === null
          )
            return false;
          if (orderDetails.vehicleDetails.modelDescription.length === 0)
            return false;
        }
        if (orderDetails.vehicleDetails.category.id === 5) {
          if (orderDetails.vehicleDetails.series === undefined) return false;
          if (orderDetails.vehicleDetails.series.id === undefined) return false;
          if (
            orderDetails.vehicleDetails.actNumber === undefined ||
            orderDetails.vehicleDetails.actNumber === null
          )
            return false;
          if (
            orderDetails.vehicleDetails.commissionNumber === undefined ||
            orderDetails.vehicleDetails.commissionNumber === null
          )
            return false;
          if (
            orderDetails.vehicleDetails.licensePlate === undefined ||
            orderDetails.vehicleDetails.licensePlate === null
          )
            return false;
        }
        if (orderDetails.vehicleDetails.category.id === 6) {
          if (orderDetails.vehicleDetails.series === undefined) return false;
          if (orderDetails.vehicleDetails.series.id === undefined) return false;
          if (
            orderDetails.vehicleDetails.actNumber === undefined ||
            orderDetails.vehicleDetails.actNumber === null
          )
            return false;
          if (
            orderDetails.vehicleDetails.commissionNumber === undefined ||
            orderDetails.vehicleDetails.commissionNumber === null
          )
            return false;
        }
        if (orderDetails.vehicleDetails.category.id === 8) {
          if (orderDetails.vehicleDetails.series === undefined) return false;
          if (orderDetails.vehicleDetails.series.id === undefined) return false;
          if (
            orderDetails.vehicleDetails.vin === undefined ||
            orderDetails.vehicleDetails.vin === null
          )
            return false;
          if (
            orderDetails.vehicleDetails.usedCarCollection === undefined ||
            orderDetails.vehicleDetails.usedCarCollection === null
          )
            return false;
          if (
            orderDetails.vehicleDetails.purchaser === undefined ||
            orderDetails.vehicleDetails.purchaser === null
          )
            return false;
        }
        if (orderDetails.vehicleDetails.category.id === 9) {
          if (orderDetails.vehicleDetails.series === undefined) return false;
          if (orderDetails.vehicleDetails.series.id === undefined) return false;
          if (
            orderDetails.vehicleDetails.modelDescription === undefined ||
            orderDetails.vehicleDetails.modelDescription === null
          )
            return false;
          if (
            orderDetails.vehicleDetails.usedCarCollection === undefined ||
            orderDetails.vehicleDetails.usedCarCollection === null
          )
            return false;
          if (
            orderDetails.vehicleDetails.purchaser === undefined ||
            orderDetails.vehicleDetails.purchaser === null
          )
            return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }

    // Customer Details
    if (orderDetails.customerDetails) {
      if (
        orderDetails.customerDetails.customerGroup === undefined ||
        orderDetails.customerDetails.customerGroup === null
      )
        return false;
      if (
        orderDetails.customerDetails.customerType === undefined ||
        orderDetails.customerDetails.customerType === null
      )
        return false;
      if (
        orderDetails.customerDetails.customerType === "PrivateCustomer" ||
        orderDetails.customerDetails.customerType === "VIPPress" ||
        orderDetails.customerDetails.customerType === "Employee" ||
        orderDetails.customerDetails.customerType === "Multiplier"
      ) {
        if (
          orderDetails.customerDetails.firstName === undefined ||
          orderDetails.customerDetails.firstName === null
        )
          return false;
        if (
          orderDetails.customerDetails.lastName === undefined ||
          orderDetails.customerDetails.lastName === null
        )
          return false;
        if (
          orderDetails.customerDetails.copNumber === undefined ||
          orderDetails.customerDetails.copNumber === null
        )
          return false;
      }
      if (
        orderDetails.customerDetails.customerType === "BusinessClient" ||
        orderDetails.customerDetails.customerType === "Dealer" ||
        orderDetails.customerDetails.customerType === "DealerBiddingPlatform" ||
        orderDetails.customerDetails.customerType === "KeyAccount" ||
        orderDetails.customerDetails.customerType === "Supplier"
      ) {
        if (
          orderDetails.customerDetails.companyName === undefined ||
          orderDetails.customerDetails.companyName === null
        )
          return false;
        if (
          orderDetails.customerDetails.firstName === undefined ||
          orderDetails.customerDetails.firstName === null
        )
          return false;
        if (
          orderDetails.customerDetails.lastName === undefined ||
          orderDetails.customerDetails.lastName === null
        )
          return false;
        if (
          orderDetails.customerDetails.copNumber === undefined ||
          orderDetails.customerDetails.copNumber === null
        )
          return false;

        if (orderDetails.customerDetails.deviantDriver) {
          if (
            orderDetails.customerDetails.driverFirstName === undefined ||
            orderDetails.customerDetails.driverFirstName === null
          )
            return false;
          if (
            orderDetails.customerDetails.driverLastName === undefined ||
            orderDetails.customerDetails.driverLastName === null
          )
            return false;
        }

        if (orderDetails.customerDetails.confidential) {
          if (orderDetails.customerDetails.differentAddress) {
            if (
              orderDetails.customerDetails.differentAddress.city ===
                undefined ||
              orderDetails.customerDetails.differentAddress.city === null
            )
              return false;
            if (
              orderDetails.customerDetails.differentAddress.street ===
                undefined ||
              orderDetails.customerDetails.differentAddress.street === null
            )
              return false;
            if (
              orderDetails.customerDetails.differentAddress.postalCode ===
                undefined ||
              orderDetails.customerDetails.differentAddress.postalCode === null
            )
              return false;
            if (
              orderDetails.customerDetails.differentAddress.houseNumber ===
                undefined ||
              orderDetails.customerDetails.differentAddress.postalCode === null
            )
              return false;
          }
        }
      }

      if (orderDetails?.customerDetails?.deviantDriver) {
        if (
          orderDetails.customerDetails.firstName === undefined ||
          orderDetails.customerDetails.firstName === null ||
          orderDetails.customerDetails.firstName.length === 0
        ) {
          return false;
        }
      }

      if (orderDetails.customerDetails.differentAddressFlag) {
        if (orderDetails.customerDetails.differentAddress) {
          if (
            orderDetails.customerDetails.differentAddress.city === undefined ||
            orderDetails.customerDetails.differentAddress.city === null ||
            orderDetails.customerDetails.differentAddress.city.length === 0
          ) {
            return false;
          }

          if (
            orderDetails.customerDetails.differentAddress.houseNumber ===
              undefined ||
            orderDetails.customerDetails.differentAddress.houseNumber ===
              null ||
            orderDetails.customerDetails.differentAddress.houseNumber.length ===
              0
          ) {
            return false;
          }

          if (
            orderDetails.customerDetails.differentAddress.postalCode ===
              undefined ||
            orderDetails.customerDetails.differentAddress.postalCode === null ||
            orderDetails.customerDetails.differentAddress.postalCode.length ===
              0
          ) {
            return false;
          }

          if (
            orderDetails.customerDetails.differentAddress.street ===
              undefined ||
            orderDetails.customerDetails.differentAddress.street === null ||
            orderDetails.customerDetails.differentAddress.street.length === 0
          ) {
            return false;
          }
        } else {
          return false;
        }
      }
    } else {
      return false;
    }

    //Payment Details
    if (orderDetails.paymentDetails) {
      if (
        orderDetails.paymentDetails.paymentType === undefined ||
        orderDetails.paymentDetails.paymentType === null
      )
        return false;
      if (
        orderDetails.paymentDetails.paymentType === "SUBSCRIPTION" ||
        orderDetails.paymentDetails.paymentType === "Leasing_PFS" ||
        orderDetails.paymentDetails.paymentType === "Financing_PFS" ||
        orderDetails.paymentDetails.paymentType === "Leasing_Other" ||
        orderDetails.paymentDetails.paymentType === "Financing_Other"
      ) {
        if (
          orderDetails.paymentDetails.durationInMonths === undefined ||
          orderDetails.paymentDetails.durationInMonths === null
        )
          return false;
      }
      if (orderDetails.paymentDetails.paymentType === "Leasing_Other") {
        if (
          orderDetails.paymentDetails.leasingCompany === undefined ||
          orderDetails.paymentDetails.leasingCompany === null
        )
          return false;
      }
      if (orderDetails.paymentDetails.paymentType === "Financing_Other") {
        if (
          orderDetails.paymentDetails.financingCompany === undefined ||
          orderDetails.paymentDetails.financingCompany === null
        )
          return false;
      }
    } else {
      return false;
    }

    // Delivery details
    if (orderDetails.deliveryDetails) {
      if (
        orderDetails.deliveryDetails.deliveryLocation === undefined ||
        orderDetails.deliveryDetails.deliveryLocation === null
      )
        return false;
      if (
        orderDetails.deliveryDetails.plannedDelivery === undefined ||
        orderDetails.deliveryDetails.plannedDelivery === null
      )
        return false;
      if (
        orderDetails.deliveryDetails.deliveryPresentDto === undefined ||
        orderDetails.deliveryDetails.deliveryPresentDto === null
      )
        return false;
      if (
        orderDetails.deliveryDetails.registrationDto === undefined ||
        orderDetails.deliveryDetails.registrationDto === null
      )
        return false;
      if (
        orderDetails.deliveryDetails.registrationDto.registrationType ===
        "Standard"
      ) {
        if (
          orderDetails.deliveryDetails.preferredLicensePlate === undefined ||
          orderDetails.deliveryDetails.preferredLicensePlate === null
        )
          return false;
      }
    } else {
      return false;
    }

    //TradeIndetails
    if (orderDetails.tradeInDetails) {
      if (
        orderDetails.tradeInDetails.tradeInType === undefined ||
        orderDetails.tradeInDetails.tradeInType === null
      )
        return false;
      if (orderDetails.tradeInDetails.tradeInType.length === 0) return false;
      if (
        orderDetails.tradeInDetails.value === undefined ||
        orderDetails.tradeInDetails.value === null
      )
        return false;
      if (orderDetails.tradeInDetails.value.length === 0) return false;
      if (
        orderDetails.tradeInDetails.vin === undefined ||
        orderDetails.tradeInDetails.vin === null
      )
        return false;
      if (orderDetails.tradeInDetails.vin.length === 0) return false;
    }

    //Referal details
    if (orderDetails.referral) {
      if (
        orderDetails.referral.referralType === undefined ||
        orderDetails.referral.referralType === null
      )
        return false;
      if (
        orderDetails.referral.referralType === "External_Private" ||
        orderDetails.referral.referralType === "External_Common"
      ) {
        if (
          orderDetails.referral.surName === undefined ||
          orderDetails.referral.surName === null
        )
          return false;
        if (orderDetails.referral.surName.length === 0) return false;
        if (
          orderDetails.referral.lastName === undefined ||
          orderDetails.referral.lastName === null
        )
          return false;
        if (orderDetails.referral.lastName.length === 0) return false;
        if (
          orderDetails.referral.street === undefined ||
          orderDetails.referral.street === null
        )
          return false;
        if (orderDetails.referral.street.length === 0) return false;
        if (
          orderDetails.referral.houseNumber === undefined ||
          orderDetails.referral.houseNumber === null
        )
          return false;
        if (orderDetails.referral.houseNumber.length === 0) return false;
        if (
          orderDetails.referral.postalCode === undefined ||
          orderDetails.referral.postalCode === null
        )
          return false;
        if (orderDetails.referral.postalCode.length === 0) return false;
        if (
          orderDetails.referral.city === undefined ||
          orderDetails.referral.city === null
        )
          return false;
        if (orderDetails.referral.city.length === 0) return false;
      }

      if (orderDetails.referral.referralType === "Internal_Empl") {
        if (
          orderDetails.referral.surName === undefined ||
          orderDetails.referral.surName === null
        )
          return false;
        if (orderDetails.referral.surName.length === 0) return false;
        if (
          orderDetails.referral.lastName === undefined ||
          orderDetails.referral.lastName === null
        )
          return false;
        if (orderDetails.referral.lastName.length === 0) return false;
        if (
          orderDetails.referral.staffNumber === undefined ||
          orderDetails.referral.staffNumber === null
        )
          return false;
        if (orderDetails.referral.staffNumber.length === 0) return false;
        if (
          orderDetails.referral.referralCommission === undefined ||
          orderDetails.referral.referralCommission === null
        )
          return false;
        if (orderDetails.referral.referralCommission === 0) return false;
      }
    }

    return true;
  };

  useEffect(()=>{
    const orderDto = orderContext.getCtxOrderDetails();
    if(userDetails?.origin?.id){
      const porscheCenter = {id: userDetails?.origin.id, centerName:userDetails?.origin.centerName};
      orderDto.porscheCenter = porscheCenter
      orderContext.removeError("porscheCenter");
    }
  },[userDetails, orderContext.getCtxOrderDetails()])

  const handlePorscheCenter = (e: ChangeEvent<HTMLSelectElement>): void => {
    const orderDto = orderContext.getCtxOrderDetails();
    if (e.target.value === "default") {
      handleMinMandatoryFields(false);
      orderDto.porscheCenter = null;
      orderContext.setErrors({
        field: "porscheCenter",
        message: "",
        section: "vehicleDetails",
      });
      orderContext.setErrors({
        field: "vehicleDetails",
        message: "",
        section: "vehicleDetails",
      });
      setReload(new Date().toLocaleString());
      return;
    } else {
      updateError("porscheCenter");
    }

    if (orderDto.porscheCenter) {
      orderDto.porscheCenter.id = parseInt(e.target.value);
      let pc = porscheCenters.filter((x) => x.id === parseInt(e.target.value));
      if (pc.length > 0) {
        orderDto.porscheCenter.centerName = pc[0].centerName;
      }
    } else {
      let pc = porscheCenters.filter((x) => x.id === parseInt(e.target.value));
      if (pc.length > 0) {
        orderDto.porscheCenter = {
          id: parseInt(e.target.value),
          centerName: pc[0].centerName,
        };
      } else {
        orderDto.porscheCenter = { id: parseInt(e.target.value) };
      }
    }

    orderContext.updateCtxOrderDetails(orderDto);
    handleModified();
    setReload(new Date().toLocaleString());
    handleMinMandatoryFields(true);
    orderContext.removeError("porscheCenter");
  };

  const handleDate = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.value.length > 0) {
      setDateOfOrder(e.target.value);
      orderDto.dateOfOrder = e.target.value;
      orderContext.updateCtxOrderDetails(orderDto);
      orderContext.removeError("dateOfOrder");
      updateError("dateOfOrder");
      handleModified();
    } else {
      setDateOfOrder("");
      orderDto.dateOfOrder = "";
      orderContext.updateCtxOrderDetails(orderDto);
      orderContext.setErrors({
        field: "vehicleDetails",
        message: "",
        section: "vehicleDetails",
      });
      orderContext.setErrors({
        field: "dateOfOrder",
        message: "",
        section: "vehicleDetails",
      });
    }
    setReload(new Date().toLocaleString());
  };

  const handleDateAftrFocus = (e: ChangeEvent<HTMLInputElement>) => {
    const fromDateRange = "1999";
    const toDateRange = "2100";
    const userFullDate = e.target.value;
    const setDateFormat = userFullDate.split("-");
    const userYear = setDateFormat[0];

    if (userYear < fromDateRange || userYear > toDateRange) {
      setWarning(true);
    } else {
      setWarning(false);
    }
  };

  const clearVehicleDetails = () => {
    let orderDetails = orderContext.getCtxOrderDetails();
    if (!orderDetails.vehicleDetails) return;

    orderDetails.vehicleDetails.actNumber = 0;
    orderDetails.vehicleDetails.category.id = 0;
    orderDetails.vehicleDetails.category.label = "";
    orderDetails.vehicleDetails.category.type = "";
    orderDetails.vehicleDetails.commissionNumber = "";
    orderDetails.vehicleDetails.licensePlate = "";
    orderDetails.vehicleDetails.modelDescription = "";
    orderDetails.vehicleDetails.purchaser = null;
    orderDetails.vehicleDetails.quotaMonth = "";
    orderDetails.vehicleDetails.usedCarCollection = null;
    orderDetails.vehicleDetails.vin = "";
    setOrderDetails(orderDetails);
    //orderContext.updateCtxOrderDetails(orderDetails)
  };
  const handleCategory = (e: ChangeEvent<HTMLSelectElement>): void => {
    clearVehicleDetails();
    if (e.target.value === "default") {
      handleMinMandatoryFields(false);
      orderContext.setErrors({
        field: "category",
        message: "",
        section: "vehicleDetails",
      });
      setReload(new Date().toLocaleString());
      return;
    }
    const category_models = vehicleCategories?.filter(
      (x) => x.id === parseInt(e.target.value)
    );
    setModels(category_models);
    const selectedCategory = category_models[0];
    const orderDetails = orderContext.getCtxOrderDetails();
    const vehicleDetails = orderDetails.vehicleDetails || {};
    vehicleDetails.category = vehicleDetails.category || {};
    setVehicleCategoryId(selectedCategory.id);
    vehicleDetails.category.id = selectedCategory.id;
    vehicleDetails.category.type = selectedCategory.type;
    vehicleDetails.category.label = selectedCategory.label;
    vehicleDetails.category.option = selectedCategory.option;
    //Model and series null
    // if(vehicleDetails.category.type === "NewCar" && vehicleDetails.category.option === "Other") {
    //   vehicleDetails.series = null;
    //   vehicleDetails.model = null;
    // }
    orderDetails.vehicleDetails = vehicleDetails;
    orderContext.updateCtxOrderDetails(orderDetails);
    handleModified();
    setReload(new Date().toLocaleString());
    updateError("category");
    handleMinMandatoryFields(true);
    orderContext.removeError("category");
    handleMandatoryFields(vehicleDetails.category.id);
  };

  const handleSeriesAndModel = (seriesId: number, modelId: number) => {
    const orderDetails = orderContext.getCtxOrderDetails();
    const vehicleDetails = orderDetails.vehicleDetails || {};
    vehicleDetails.series = vehicleDetails.series || {};
    vehicleDetails.model = vehicleDetails.model || {};
    const previouModelid = vehicleDetails.model.id || {};
    series.map((s) => {
      if (seriesId === s.id) {
        vehicleDetails.series.id = seriesId;
        vehicleDetails.series.seriesName = s.seriesName;
        vehicleDetails.model.id = modelId;
        let modelName = s.models.filter((model) => model.id === modelId);
        vehicleDetails.model.modelName = modelName[0]?.modelName;
      }
      return true;
    });
    if (previouModelid !== modelId) {
      handleModified();
    }
    // if(vehicleDetails?.series?.id !== undefined && vehicleDetails?.model?.id !== undefined) {
    //   const dom = document.getElementById("seriesModel");
    //   if(dom) {
    //     dom.setAttribute("class", "series-model-error-hide")
    //   }
    // }

    orderDetails.vehicleDetails = vehicleDetails;
    orderContext.updateCtxOrderDetails(orderDetails);
    orderContext.removeError("seriesAndModel");
    updateError("seriesModel");
    // handleModified();
    setReload(new Date().toLocaleString());
  };

  const handleSeries = (seriesId: number) => {
    const orderDetails = orderContext.getCtxOrderDetails();
    const vehicleDetails = orderDetails.vehicleDetails || {};
    vehicleDetails.series = vehicleDetails.series || {};
    const previouSeriesid = vehicleDetails.series.id || 0;
    series.map((s) => {
      if (seriesId === s.id) {
        vehicleDetails.series.id = seriesId;
        vehicleDetails.series.seriesName = s.seriesName;
      }
      //return true
    });
    if (previouSeriesid !== seriesId) {
      handleModified();
    }
    orderDetails.vehicleDetails = vehicleDetails;
   
    orderContext.updateCtxOrderDetails(orderDetails);
    orderContext.removeError("seriesAndModel");
    updateError("seriesOnly");
    setReload(new Date().toLocaleString());
  };

  const handleMandatoryFields = (categoryId: number) => {
    if (categoryId === 1 || categoryId === 3 || categoryId === 7) {
      setQuotaMonth("");
      orderContext.setErrors({
        field: "quotaMonth",
        message: "",
        section: "vehicleDetails",
      });
      orderContext.removeError("commissionNumber");
      orderContext.removeError("modelDescription");
      orderContext.removeError("actNumber");
      orderContext.removeError("licensePlate");
      orderContext.removeError("vin");
      orderContext.removeError("purchaser");
      orderContext.removeError("usedCarCollection");
    } else if (categoryId === 2) {
      setQuotaMonth("");
      orderContext.setErrors({
        field: "quotaMonth",
        message: "",
        section: "vehicleDetails",
      });
      orderContext.setErrors({
        field: "commissionNumber",
        message: "",
        section: "vehicleDetails",
      });
      orderContext.removeError("modelDescription");
      orderContext.removeError("actNumber");
      orderContext.removeError("licensePlate");
      orderContext.removeError("vin");
      orderContext.removeError("purchaser");
      orderContext.removeError("usedCarCollection");
    } else if (categoryId === 4) {
      orderContext.setErrors({
        field: "modelDescription",
        message: "",
        section: "vehicleDetails",
      });
      orderContext.removeError("commissionNumber");
      orderContext.removeError("actNumber");
      orderContext.removeError("licensePlate");
      orderContext.removeError("quotaMonth");
      orderContext.removeError("vin");
      orderContext.removeError("purchaser");
      orderContext.removeError("usedCarCollection");
    } else if (categoryId === 5) {
      orderContext.setErrors({
        field: "actNumber",
        message: "",
        section: "vehicleDetails",
      });
      orderContext.setErrors({
        field: "commissionNumber",
        message: "",
        section: "vehicleDetails",
      });
      orderContext.setErrors({
        field: "licensePlate",
        message: "",
        section: "vehicleDetails",
      });
      orderContext.removeError("modelDescription");
      orderContext.removeError("quotaMonth");
      orderContext.removeError("vin");
      orderContext.removeError("purchaser");
      orderContext.removeError("usedCarCollection");
    } else if (categoryId === 6) {
      orderContext.setErrors({
        field: "actNumber",
        message: "",
        section: "vehicleDetails",
      });
      orderContext.setErrors({
        field: "commissionNumber",
        message: "",
        section: "vehicleDetails",
      });
      orderContext.removeError("licensePlate");
      orderContext.removeError("modelDescription");
      orderContext.removeError("quotaMonth");
      orderContext.removeError("vin");
      orderContext.removeError("purchaser");
      orderContext.removeError("usedCarCollection");
    } else if (categoryId === 8) {
      orderContext.setErrors({
        field: "modelDescription",
        message: "",
        section: "vehicleDetails",
      });
      orderContext.setErrors({
        field: "vinNumber",
        message: "",
        section: "vehicleDetails",
      });
      orderContext.setErrors({
        field: "purchaser",
        message: "",
        section: "vehicleDetails",
      });
      orderContext.setErrors({
        field: "usedCarCollection",
        message: "",
        section: "vehicleDetails",
      });
      orderContext.removeError("quotaMonth");
      //orderContext.removeError("modelDescription")
      orderContext.removeError("licensePlate");
      orderContext.removeError("commissionNumber");
      orderContext.removeError("actNumber");
    } else if (categoryId === 9) {
      orderContext.setErrors({
        field: "modelDescription",
        message: "",
        section: "vehicleDetails",
      });
      orderContext.setErrors({
        field: "purchaser",
        message: "",
        section: "vehicleDetails",
      });
      orderContext.setErrors({
        field: "usedCarCollection",
        message: "",
        section: "vehicleDetails",
      });
      orderContext.removeError("quotaMonth");
      orderContext.removeError("commissionNumber");
      orderContext.removeError("vin");
      orderContext.removeError("licensePlate");
      orderContext.removeError("actNumber");
    }
  };

  const handleOpenExit = () => {
    setOpenExit(true);
  };

  const handleCloseExit = () => {
    setOpenExit(false);
  };

  const handleLeaveWithoutSaving = () => {
    setOpenExit(false);
    navigate("/");
    window.close();
  };

  const handleSaveAndLeave = () => {
    // Need to handle save
    setOpenExit(false);
    navigate("/");
  };

  const handleActNumber =  (value: number | any): void  => {

    const alphanumericRegex = /^[a-zA-Z0-9]*$/;
      if (!alphanumericRegex.test(value)) {
        value = value.replace(/[^a-zA-Z0-9]/g, "");
      }
      setActNumber(value);
    const orderDto = orderContext.getCtxOrderDetails();
    const vehicleDetails = orderDto.vehicleDetails || {};
    const categoryType = vehicleDetails?.category.id;

    if (
      (categoryType === 4 ||
        categoryType === 5 ||
        categoryType === 6 ||
        categoryType === 8 ||
        categoryType === 9) &&
        value.length > 0
    ) {
      vehicleDetails.actNumber = value;
      orderDto.vehicleDetails = vehicleDetails;
      orderContext.updateCtxOrderDetails(orderDto);
      orderContext.removeError("actNumber");
      updateError("actNumber");
    } else {
      orderContext.setErrors({
        field: "actNumber",
        message: "",
        section: "vehicleDetails",
      });
    }
    handleModified();
    setReload(new Date().toLocaleString());
  };

  const handleCommissionNumber = (e: ChangeEvent<HTMLInputElement>): void => {
    const orderDto = orderContext.getCtxOrderDetails();
    const vehicleDetails = orderDto.vehicleDetails || {};

    const categoryType = vehicleDetails?.category.id;
    if (
      (categoryType === 1 ||
        categoryType === 2 ||
        categoryType === 3 ||
        categoryType === 5 ||
        categoryType === 6 ||
        categoryType === 7) &&
      e.target.value.length > 0
    ) {
      setCommissionNumber(e.target.value);
      vehicleDetails.commissionNumber = e.target.value;
      orderDto.vehicleDetails = vehicleDetails;
      orderContext.updateCtxOrderDetails(orderDto);
      orderContext.removeError("commissionNumber");
      updateError("commissionNumber");
      handleModified();
    } else {
      orderContext.setErrors({
        field: "commissionNumber",
        message: "",
        section: "vehicleDetails",
      });
    }

    setReload(new Date().toLocaleString());
  };

  const inputVin = (value: string): void => {
    const checkbox = document.querySelector(`input[name="vinNumber"]`) as HTMLInputElement
    if(checkbox.value.length<=17){
      const alphanumericRegex = /^[a-zA-Z0-9]*$/;
      if (!alphanumericRegex.test(value)) {
        value = value.replace(/[^a-zA-Z0-9]/g, "");
      }
      setVin(value);
      const orderDto = orderContext.getCtxOrderDetails();
      const vehicleDetails = orderDto.vehicleDetails || {};
      const categoryId = vehicleDetails?.category?.id;
      if (
          (categoryId === 2 ||
              categoryId === 4 ||
              categoryId === 5 ||
              categoryId === 6 ||
              categoryId === 8) &&
          value.length > 0
      ) {
        vehicleDetails.vin = value;
        setGross(value);
        orderDto.vehicleDetails = vehicleDetails;
        setOrderDto(orderDto);
        orderContext.updateCtxOrderDetails(orderDto);
        orderContext.removeError("vinNumber");
        updateError("vinNumber");
      } else {
        orderContext.setErrors({
          field: "vinNumber",
          message: "",
          section: "vehicleDetails",
        });
      }
      handleModified();
      setReload(new Date().toLocaleString());
    }

  }

  const handleVinNumber = (value: string): void => {
    const alphanumericRegex = /^[a-zA-Z0-9]*$/;

    // Check if the input value contains only alphanumeric characters
    if (!alphanumericRegex.test(value)) {
      // If non-alphanumeric characters are detected, remove them from the input
      value = value.replace(/[^a-zA-Z0-9]/g, "");
    }

    setVin(value);
    const orderDto = orderContext.getCtxOrderDetails();
    const vehicleDetails = orderDto.vehicleDetails || {};
    const categoryId = vehicleDetails?.category?.id;
    if (
      (categoryId === 2 ||
        categoryId === 4 ||
        categoryId === 5 ||
        categoryId === 6 ||
        categoryId === 8) &&
      value.length > 0
    ) {
      vehicleDetails.vin = value;
      setGross(value);
      orderDto.vehicleDetails = vehicleDetails;
      setOrderDto(orderDto);
      orderContext.updateCtxOrderDetails(orderDto);
      orderContext.removeError("vinNumber");
      updateError("vinNumber");
    } else {
      orderContext.setErrors({
        field: "vinNumber",
        message: "",
        section: "vehicleDetails",
      });
    }
    handleModified();
    setReload(new Date().toLocaleString());
  };

  const handleLengthAftrFocus = (value: string) => {
    const userLength = value.length;
    if (userLength < 17) {
      setWarningVin(true);
    } else {
      setWarningVin(false);
    }
  };

  const handleLicensePlate = (e: ChangeEvent<HTMLInputElement>): void => {
    const orderDto = orderContext.getCtxOrderDetails();
    const vehicleDetails = orderDto.vehicleDetails || {};
    const categoryType = vehicleDetails?.category.id;
    if (
      (categoryType === 5 || categoryType === 9) &&
      e.target.value.length > 0
    ) {
      vehicleDetails.licensePlate = e.target.value;
      orderContext.removeError("licensePlate");
      updateError("licensePlate");
    } else {
      vehicleDetails.licensePlate = "";
      orderContext.setErrors({
        field: "licensePlate",
        message: "",
        section: "vehicleDetails",
      });
    }
    orderDto.vehicleDetails = vehicleDetails;
    orderContext.updateCtxOrderDetails(orderDto);
    handleModified();
    setReload(new Date().toLocaleString());
  };

  const handleUsedCarCollection = (e: ChangeEvent<HTMLSelectElement>): void => {
    if (e.target.value === "default") {
      orderContext.setErrors({
        field: "usedCarCollection",
        message: "",
        section: "vehicleDetails",
      });
      setReload(new Date().toLocaleString());
      return;
    }
    const orderDto = orderContext.getCtxOrderDetails();
    const vehicleDetails = orderDto.vehicleDetails || {};
    vehicleDetails.usedCarCollection = e.target.value;
    orderDto.vehicleDetails = vehicleDetails;
    orderContext.updateCtxOrderDetails(orderDto);
    orderContext.removeError("usedCarCollection");
    updateError("usedCarCollection");
    handleModified();
    setReload(new Date().toLocaleString());
  };

  const handlePurchaser = (e: string): void => {
    const orderDto = orderContext.getCtxOrderDetails();
    const vehicleDetails = orderDto.vehicleDetails || {};
    const value = parseInt(e);

    const categoryType = vehicleDetails?.category.id;
    if ((categoryType === 8 || categoryType === 9) && value > 0) {
      vehicleDetails.purchaser = { id: value };
      orderContext.removeError("purchaser");
      updateError("purchaser");
    } else {
      vehicleDetails.purchaser = null;
      //vehicleDetails.purchaser = {} as UserDTO;
      orderContext.setErrors({
        field: "purchaser",
        message: "",
        section: "vehicleDetails",
      });
    }
    orderDto.vehicleDetails = vehicleDetails;
    orderContext.updateCtxOrderDetails(orderDto);
    handleModified();
    setReload(new Date().toLocaleString());
  };

  const handleModelDescription = (e: ChangeEvent<HTMLInputElement>): void => {
    const orderDto = orderContext.getCtxOrderDetails();
    const vehicleDetails = orderDto.vehicleDetails || {};

    const categoryType = vehicleDetails?.category.id;
    if (
      (categoryType === 4 || categoryType === 8 || categoryType === 9) &&
      e.target.value.length > 0
    ) {
      vehicleDetails.modelDescription = e.target.value;
      orderContext.removeError("modelDescription");
      updateError("modelDescription");
    } else {
      orderContext.setErrors({
        field: "modelDescription",
        message: "",
        section: "vehicleDetails",
      });
    }
    orderDto.vehicleDetails = vehicleDetails;
    orderContext.updateCtxOrderDetails(orderDto);
    handleModified();
    setReload(new Date().toLocaleString());
  };

  const handleQuotaMonth = (e: ChangeEvent<HTMLInputElement>): void => {
    const wrapDom = document.getElementById("quotaMonthWrap") as HTMLInputElement;
    setIsQuotaMonthError(false)
    const inputDateValue = e.target.value
    let year =null
    let month =null
    year = inputDateValue.split('-')[0];
    month  = inputDateValue.split('-')[1];
    
  if(!inputDateValue.includes('-') || isNaN(Number(year)) || isNaN(Number(month))|| year.length>4 || month.length>2 || e.target.value.length>7){
    if (wrapDom) wrapDom.setAttribute("state", "error");
    orderContext.setErrors({
      field: "quotaMonth",
      message: "",
      section: "vehicleDetails",
    });
    setIsQuotaMonthError(true)
    return
  }

    const orderDto = orderContext.getCtxOrderDetails();
    const vehicleDetails = orderDto.vehicleDetails || {};
    const categoryType = vehicleDetails?.category.id;
    if (e.target.value.length) {
      if (
        (categoryType === 1 ||
          categoryType === 2 ||
          categoryType === 3 ||
          categoryType === 7) &&
        e.target.value.length > 0
      ) {
        vehicleDetails.quotaMonth = e.target.value;
        setQuotaMonth(e.target.value);
        orderDto.vehicleDetails = vehicleDetails;
        orderContext.updateCtxOrderDetails(orderDto);
        orderContext.removeError("quotaMonth");
        updateError("quotaMonth");
        handleModified();
      } else {
        orderContext.setErrors({
          field: "quotaMonth",
          message: "",
          section: "vehicleDetails",
        });
      }
      setReload(new Date().toLocaleString());
    } else {
      vehicleDetails.quotaMonth = "";
      setQuotaMonth("");
      orderDto.vehicleDetails = vehicleDetails;
      orderContext.updateCtxOrderDetails(orderDto);
    }
  };

  const handleTradeInToggle = (show: boolean) => {
    // Make tradeInDetails to null if section is hidden
    let ord = orderContext.getCtxOrderDetails();
    let tradeInDetails = ord?.tradeInDetails;
    if (!show) {
      ord.tradeInDetails = null;
      orderContext.updateCtxOrderDetails(ord);
    } else {
      if (ord.tradeInDetails) {
        ord.tradeInDetails.tradeInType = null;
        ord.tradeInDetails.value = null;
        ord.tradeInDetails.vin = null;
        orderContext.updateCtxOrderDetails(ord);
      } else {
        ord.tradeInDetails = {} as TradeInDetails;
        ord.tradeInDetails.tradeInType = null;
        ord.tradeInDetails.value = null;
        ord.tradeInDetails.vin = null;
        orderContext.updateCtxOrderDetails(ord);
      }
    }
    setTradeInToggle(show);
    //handleModified()
  };

  const handleReferalToggle = (show: boolean) => {
    setReferralToggle(show);
    let ord = orderContext.getCtxOrderDetails();
    if (show) {
      ord.referral = {} as RefDto;
    } else {
      ord.referral = null;
    }
    orderContext.updateCtxOrderDetails(ord);
    setOrderDetails(ord);
    handleModified();
    setReload(new Date().toLocaleString());
  };

  return (
    <>
      <div className="order-card vehicle-card">
        <PorscheFont data-testid="vehicle" className="card-header">
          {t("Vehicle")}
        </PorscheFont>
        <PGrid>
          <PGridItem size={3}>
            <PSelectWrapper
              id="porscheCenterWrap"
              label={`${t("Porsche Center")}`}
            >
              <select
                id="porscheCenter"
                data-testid="porscheCenter"
                name="porscheCenter"
                // onChange={(e) => handlePorscheCenter(e)}
                disabled={true}
                required
              >
                <option key="default" value="default">
                  {t("Select Porsche Center")}
                </option>
                {porscheCenters?.map((porscheCenter, index) => {
                  return (
                    <option
                      key={index}
                      value={porscheCenter.id}
                      selected={ disabled? porscheCenter.id === orderDetails?.porscheCenter?.id :porscheCenter.id === userDetails?.origin?.id
                      }
                    >
                      {t(porscheCenter.centerName)}
                    </option>
                  );
                })}
              </select>
            </PSelectWrapper>
          </PGridItem>
          <PGridItem size={3} style={{ marginTop: "-1px" }}>
            <PTextFieldWrapper
              id="dateOfOrderWrap"
              label={`${t("Date of Order")}`}
            >
              <input
                id="dateOfOrder"
                type="date"
                name="dateOfOrder"
                max="9999-12-31"
                value={dateOfOrder}
                required
                onChange={handleDate}
                onBlur={handleDateAftrFocus}
                disabled={disabled}
              />
            </PTextFieldWrapper>
          </PGridItem>
        </PGrid>
        <PGrid className="pt24">
          <PGridItem size={3}>
            <PSelectWrapper
              id="categoryWrap"
              label={`${t("Select vehicle category")}`}
              dropdownDirection="down"
            >
              <select
                id="category"
                name="category_id"
                required
                onChange={(e) => handleCategory(e)}
                value={orderDetails?.vehicleDetails?.category.id}
                disabled={disabled}
              >
                <option key="default" value="default">
                  {t("Select Vehicle Category")}
                </option>
                {vehicleCategories?.map((category, index) => {
                  return (
                    <option
                      key={index}
                      value={category.id}
                      selected={
                        category.id ===
                        orderDetails?.vehicleDetails?.category.id
                      }
                    >
                      {t(category.label)}{" "}
                    </option>
                  );
                })}
              </select>
            </PSelectWrapper>
          </PGridItem>
        </PGrid>
        <PGrid style={{ paddingTop: "30px" }}>
          {vehicleCategoryId > 0 &&
            vehicleCategoryId !== 4 &&
            vehicleCategoryId !== 8 &&
            vehicleCategoryId !== 9 &&
            series.length > 0 && (
              <>
                <PGridItem size={12}>
                  <div style={{ paddingBottom: "10px" }}>
                    <PorscheFont>
                      {t("Select series and model")}{" "}
                      <span style={{ color: "#D5001B" }}>*</span>
                    </PorscheFont>
                  </div>
                  <div id="seriesModel" className={"series-model-error-hide"}>
                    <PorscheFont>
                      {t("Please select series and model to proceed further")}{" "}
                    </PorscheFont>
                  </div>
                  <CustomSeriesModel
                    allSeries={series}
                    handleChange={handleSeriesAndModel}
                    mode={orderValues?.mode ? orderValues?.mode+'' :''}
                    selectedSeriesId={orderDetails?.vehicleDetails?.series?.id}
                    selectedModel={orderDetails?.vehicleDetails?.model}
                    disabled={disabled}
                  />
                </PGridItem>
              </>
            )}

          {/** need to modfify id 7 to 8 */}
          {vehicleCategoryId > 0 &&
            vehicleCategoryId === 8 &&
            series.length > 0 && (
              <>
                <PGridItem size={12}>
                  <div style={{ paddingBottom: "10px" }}>
                    <PorscheFont>
                      {t("Select series")}{" "}
                      <span style={{ color: "#D5001B" }}>*</span>
                    </PorscheFont>
                  </div>
                  <div id="seriesOnly" className={"series-model-error-hide"}>
                    <PorscheFont>
                      {t("Please select series to proceed further")}{" "}
                    </PorscheFont>
                  </div>
                  <CustomSeries
                    allSeries={series}
                    handleChange={handleSeries}
                    mode={orderValues?.mode ? orderValues?.mode+'' :''}
                    selectedSeriesId={orderDetails?.vehicleDetails?.series?.id}
                    disabled={disabled}
                  />
                </PGridItem>
              </>
            )}
        </PGrid>
        <PGrid>
          {/** Need to modify category id 9 to 4, after fresh db script is done. */}
          {(vehicleCategoryId === 1 || vehicleCategoryId === 3) && (
            <>
              <PGridItem
                size={3}
                style={{ marginTop: "27px", marginRight: "5%" }}
              >
                <PTextFieldWrapper
                  id="quotaMonthWrap"
                  label={`${t("Quota Month")}`}
                >
                  <input
                    id="quotaMonth"
                    type="month"
                    name="quotaMonth"
                    value={orderDetails?.vehicleDetails?.quotaMonth}
                    required
                    onChange={(e) => handleQuotaMonth(e)}
                    disabled={disabled}
                  />
                </PTextFieldWrapper>
              </PGridItem>
              <PGridItem size={3}>
                <CustomTextField
                  id="commissionNumber"
                  type="text"
                  name="commissionNumber"
                  defaultValue={
                    orderDetails?.vehicleDetails?.commissionNumber
                      ? orderDetails?.vehicleDetails?.commissionNumber.toString()
                      : undefined
                  }
                  label={`${t("Commission number (Optional)")}`}
                  // onClick={(e) => handleCommissionNumber(e)}
                  onChange={(e) => handleCommissionNumber(e)}
                  required={false}
                  disabled={disabled}
                />
              </PGridItem>
            </>
          )}
          {(vehicleCategoryId === 2 || vehicleCategoryId === 7) && (
            <PGridItem
              size={3}
              style={{ marginTop: "27px", marginRight: "5%" }}
            >
              <PTextFieldWrapper
                id="quotaMonthWrap"
                label={`${t("Quota Month")}`}
              >
                <input
                  id="quotaMonth"
                  type="month"
                  name="quotaMonth"
                  max="9999-12-31"
                  value={orderDetails?.vehicleDetails?.quotaMonth}
                  required
                  onChange={(e) => handleQuotaMonth(e)}
                  disabled={disabled}
                />
              </PTextFieldWrapper>
            </PGridItem>
          )}
          {vehicleCategoryId === 2 && (
            <>
              <PGridItem size={3}>
                <CustomTextField
                  id="commissionNumber"
                  type="text"
                  name="commissionNumber"
                  defaultValue={
                    orderDetails?.vehicleDetails?.commissionNumber
                      ? orderDetails?.vehicleDetails?.commissionNumber.toString()
                      : undefined
                  }
                  label={`${t("Commission number")}`}
                  // onClick={(e) => handleCommissionNumber(e)}
                  onChange={(e) => handleCommissionNumber(e)}
                  required={true}
                  disabled={disabled}
                />
              </PGridItem>
              <PGridItem size={3}>
                <CustomTextField
                  id="vinNumber"
                  type="text"
                  name="vinNumber"
                  defaultValue={orderDetails?.vehicleDetails?.vin || vin}
                  label={`${t("VIN")}` + " (Optional)"}
                  onChange={(e) => inputVin(e.target.value)}
                  disabled={disabled}
                  // maxLength={17}
                  onBlur={() => handleLengthAftrFocus(vin)}
                  value={vin}
                />
                <PGrid>
                  <PGridItem size={11}>
                    {vin.length && showWarningVin ? (
                      <span style={{ color: "red" }}>
                        <PorscheFont>{`${t("VIN must be of 17 characters.")}`}</PorscheFont>
                      </span>
                    ) : null}
                  </PGridItem>
                </PGrid>
              </PGridItem>
            </>
          )}
          {vehicleCategoryId === 4 && (
            <>
              <PGridItem size={3}>
                <CustomTextField
                  id="modelDescription"
                  type="text"
                  name="modelDescription"
                  defaultValue={orderDetails?.vehicleDetails?.modelDescription}
                  label={`${t("Model Description")}`}
                  //  onClick={(e) => handleModelDescription(e)}
                  onChange={(e) => handleModelDescription(e)}
                  required={true}
                  disabled={disabled}
                />
              </PGridItem>
              <PGridItem size={3}>
                <CustomTextField
                  id="actNumber"
                  type="text"
                  name="actNumber"
                  defaultValue={orderDetails?.vehicleDetails?.actNumber || actNumber}
                  label={`${t("Act number")}` + " (Optional)"}
                  onChange={(e) => handleActNumber(e.target.value)}
                  disabled={disabled}
                  value={actNumber}
                />
              </PGridItem>
              <PGridItem size={3}>
                <CustomTextField
                  id="vinNumber"
                  type="text"
                  name="vinNumber"
                  defaultValue={orderDetails?.vehicleDetails?.vin || vin}
                  label={`${t("VIN")}` + " (Optional)"}
                  onChange={(e) => inputVin(e.target.value)}
                  disabled={disabled}
                  // maxLength={17}
                  onBlur={() => handleLengthAftrFocus(vin)}
                  value={vin}
                />
                <PGrid>
                  <PGridItem size={11}>
                    {vin.length && showWarningVin ? (
                      <span style={{ color: "red" }}>
                        <PorscheFont>{`${t("VIN must be of 17 characters.")}`}</PorscheFont>
                      </span>
                    ) : null}
                  </PGridItem>
                </PGrid>
              </PGridItem>
            </>
          )}

          {vehicleCategoryId === 5 && (
            <>
              <PGridItem size={3}>
                <CustomTextField
                  id="actNumber"
                  type="text"
                  name="actNumber"
                  defaultValue={orderDetails?.vehicleDetails?.actNumber || actNumber}
                  label={`${t("Act number")}`}
                  // onClick={(e) => handleActNumber(e)}
                  onChange={(e) => handleActNumber(e.target.value)}
                  required={true}
                  disabled={disabled}
                  value={actNumber}
                />
              </PGridItem>

              <PGridItem size={3}>
                <CustomTextField
                  id="commissionNumber"
                  type="text"
                  name="commissionNumber"
                  defaultValue={orderDetails?.vehicleDetails?.commissionNumber?.toString()}
                  label={`${t("Commission number")}`}
                  // onClick={(e) => handleCommissionNumber(e)}
                  onChange={(e) => handleCommissionNumber(e)}
                  required={true}
                  disabled={disabled}
                />
              </PGridItem>

              <PGridItem size={3}>
                <CustomTextField
                  id="licensePlate"
                  type="text"
                  name="licensePlate"
                  defaultValue={orderDetails?.vehicleDetails?.licensePlate}
                  label={`${t("License plate")}`}
                  // onClick={(e) => handleLicensePlate(e)}
                  onChange={(e) => handleLicensePlate(e)}
                  required={true}
                  disabled={disabled}
                />
              </PGridItem>

              <PGridItem size={3}>
                <CustomTextField
                  id="vinNumber"
                  type="text"
                  name="vinNumber"
                  defaultValue={orderDetails?.vehicleDetails?.vin || vin}
                  label={`${t("VIN")}` + " (Optional)"}
                  onChange={(e) => inputVin(e.target.value)}
                  disabled={disabled}
                  // maxLength={17}
                  onBlur={() => handleLengthAftrFocus(vin)}
                  value={vin}
                />
                <PGrid>
                  <PGridItem size={11}>
                    {vin.length && showWarningVin ? (
                      <span style={{ color: "red" }}>
                        <PorscheFont>{`${t("VIN must be of 17 characters.")}`}</PorscheFont>
                      </span>
                    ) : null}
                  </PGridItem>
                </PGrid>
              </PGridItem>
            </>
          )}

          {vehicleCategoryId === 6 && (
            <>
              <PGridItem size={3}>
                <CustomTextField
                  id="actNumber"
                  type="text"
                  name="actNumber"
                  defaultValue={orderDetails?.vehicleDetails?.actNumber || actNumber}
                  label={`${t("Act number")}`}
                  // onClick={(e) => handleActNumber(e)}
                  onChange={(e) => handleActNumber(e.target.value)}
                  required={true}
                  disabled={disabled}
                  value={actNumber}
                />
              </PGridItem>

              <PGridItem size={3}>
                <CustomTextField
                  id="commissionNumber"
                  type="text"
                  name="commissionNumber"
                  defaultValue={orderDetails?.vehicleDetails?.commissionNumber?.toString()}
                  label={`${t("Commission number")}`}
                  // onClick={(e) => handleCommissionNumber(e)}
                  onChange={(e) => handleCommissionNumber(e)}
                  required={true}
                  disabled={disabled}
                />
              </PGridItem>

              <PGridItem size={3}>
                <CustomTextField
                  id="vinNumber"
                  type="text"
                  name="vinNumber"
                  defaultValue={orderDetails?.vehicleDetails?.vin || vin}
                  label={`${t("VIN")}` + " (Optional)"}
                  onChange={(e) => inputVin(e.target.value)}
                  disabled={disabled}
                  // maxLength={17}
                  onBlur={() => handleLengthAftrFocus(vin)}
                  value={vin}
                />
                <PGrid>
                  <PGridItem size={11}>
                    {vin.length && showWarningVin ? (
                      <span style={{ color: "red" }}>
                        <PorscheFont>{`${t("VIN must be of 17 characters.")}`}</PorscheFont>
                      </span>
                    ) : null}
                  </PGridItem>
                </PGrid>
              </PGridItem>
            </>
          )}

          {vehicleCategoryId === 7 && (
            <>
              <PGridItem size={3}>
                <CustomTextField
                  id="commissionNumber"
                  type="text"
                  name="commissionNumber"
                  defaultValue={orderDetails?.vehicleDetails?.commissionNumber?.toString()}
                  label={`${t("Commission number (Optional)")}`}
                  onChange={(e) => handleCommissionNumber(e)}
                  disabled={disabled}
                />
              </PGridItem>
            </>
          )}

          {vehicleCategoryId === 8 && (
            <>
              <PGridItem size={3}>
                <CustomTextField
                  id="modelDescription"
                  type="text"
                  name="modelDescription"
                  defaultValue={orderDetails?.vehicleDetails?.modelDescription}
                  label={`${t("Model Description")}`}
                  // onClick={(e) => handleModelDescription(e)}
                  onChange={(e) => handleModelDescription(e)}
                  required={true}
                  disabled={disabled}
                />
              </PGridItem>

              <PGridItem size={3}>
                {/* <CustomTextField type="number" name="actNumber" value={orderDetails?.vehicleDetails?.actNumber.toString()} label={`${t("number")}`}  onChange={(e) => handleActNumber(e)} disabled={disabled} /> */}
                <CustomTextField
                  id="actNumber"
                  type="text"
                  name="actNumber"
                  defaultValue={orderDetails?.vehicleDetails?.actNumber || actNumber}
                  label={`${t("Act number")}` + " (Optional)"}
                  onChange={(e) => handleActNumber(e.target.value)}
                  disabled={disabled}
                  value={actNumber}
                />
              </PGridItem>

              <PGridItem size={3}>
                <CustomTextField
                  id="vinNumber"
                  type="text"
                  name="vinNumber"
                  defaultValue={orderDetails?.vehicleDetails?.vin || vin}
                  label={`${t("VIN")}` + " (Optional)"}
                  onChange={(e) => inputVin(e.target.value)}
                  disabled={disabled}
                  // maxLength={17}
                  onBlur={() => handleLengthAftrFocus(vin)}
                  value={vin}
                />
                <PGrid>
                  <PGridItem size={11}>
                    {vin.length && showWarningVin ? (
                      <span style={{ color: "red" }}>
                        <PorscheFont>{`${t("VIN must be of 17 characters.")}`}</PorscheFont>
                      </span>
                    ) : null}
                  </PGridItem>
                </PGrid>
              </PGridItem>

              <PGridItem size={3}>
                <PSelectWrapper
                  id="usedCarCollectionWrap"
                  label={`${t("Used Car Collection")}`}
                  style={{ marginTop: "20px" }}
                >
                  <select
                    id="usedCarCollection"
                    name="used_car_collection_id"
                    required
                    onChange={(e) => handleUsedCarCollection(e)}
                    disabled={disabled}
                  >
                    <option key="default" value="default">
                      {t("Select Used Car Collection")}
                    </option>
                    {usedCarCollections?.map((usedCar, index) => {
                      let ord = orderContext.getCtxOrderDetails();
                      if (
                        ord?.vehicleDetails?.category?.option === "Stock" &&
                        (usedCar.second === "LeasingReturnOther" ||
                          usedCar.second === "LeasingRedemptionOther")
                      )
                        return <></>;
                      if (
                        ord?.vehicleDetails?.category?.option === "Other" &&
                        (usedCar.second === "LeasingReturnOther" ||
                          usedCar.second === "LeasingRedemptionOther")
                      )
                        return <></>;
                      return (
                        <option
                          key={index}
                          value={usedCar.second}
                          selected={
                            usedCar.second ===
                            orderDetails?.vehicleDetails?.usedCarCollection
                          }
                        >
                          {t(usedCar.first.trim())}
                        </option>
                      );
                    })}
                  </select>
                </PSelectWrapper>
              </PGridItem>

              <PGridItem size={3}>
                <PSelectWrapper
                  id="purchaserWrap"
                  label={`${t("Purchaser")}`}
                  style={{ marginTop: "20px" }}
                >
                  <select
                    id="purchaser"
                    name="purchaser"
                    required
                    onChange={(e) => handlePurchaser(e.target.value)}
                    disabled={disabled}
                  >
                    <option key="default" value={0}>
                      {t("Select purchaser")}
                    </option>
                    {purchasers.map((user, i) => {
                      return (
                        <option
                          key={user.id}
                          value={user.id}
                          selected={
                            user.id ===
                            orderDetails?.vehicleDetails?.purchaser?.id
                          }
                        >
                          {user.ppnName}
                        </option>
                      );
                    })}
                  </select>
                </PSelectWrapper>
              </PGridItem>
            </>
          )}

          {vehicleCategoryId === 9 && (
            <>
              <PGridItem size={3}>
                <CustomTextField
                  id="modelDescription"
                  type="text"
                  name="modelDescription"
                  defaultValue={orderDetails?.vehicleDetails?.modelDescription}
                  label={`${t("Model Description")}`}
                  // onClick={(e) => handleModelDescription(e)}
                  onChange={(e) => handleModelDescription(e)}
                  required={true}
                  disabled={disabled}
                />
              </PGridItem>

              <PGridItem size={3}>
                <CustomTextField
                  id="actNumber"
                  type="text"
                  name="actNumber"
                  defaultValue={orderDetails?.vehicleDetails?.actNumber || actNumber}
                  label={`${t("Act number")}` + " (Optional)"}
                  onChange={(e) => handleActNumber(e.target.value)}
                  disabled={disabled}
                  value={actNumber}
                />
              </PGridItem>

              <PGridItem size={3}>
                <CustomTextField
                  id="licensePlate"
                  type="text"
                  name="licensePlate"
                  defaultValue={orderDetails?.vehicleDetails?.licensePlate}
                  label={`${t("Preferred license plate") + " (Optional)"}`}
                  onChange={(e) => handleLicensePlate(e)}
                  disabled={disabled}
                />
              </PGridItem>

              <PGridItem size={3}>
                <PSelectWrapper
                  id="usedCarCollectionWrap"
                  label={`${t("Used Car Collection")}`}
                  style={{ marginTop: "20px" }}
                >
                  <select
                    id="usedCarCollection"
                    name="used_car_collection_id"
                    required
                    onChange={(e) => handleUsedCarCollection(e)}
                    disabled={disabled}
                  >
                    <option key="default" value="default">
                      {t("Select Used Car Collection")}
                    </option>
                    {usedCarCollections?.map((usedCar, index) => {
                      let ord = orderContext.getCtxOrderDetails();
                      if (
                        ord?.vehicleDetails?.category?.option === "Stock" &&
                        (usedCar.second === "LeasingReturnOther" ||
                          usedCar.second === "LeasingRedemptionOther")
                      )
                        return <></>;
                      if (
                        ord?.vehicleDetails?.category?.option === "Other" &&
                        (usedCar.second === "LeasingReturnOther" ||
                          usedCar.second === "LeasingRedemptionOther")
                      )
                        return <></>;
                      return (
                        <option
                          key={index}
                          value={usedCar.second}
                          selected={
                            usedCar.second ===
                            orderDetails?.vehicleDetails?.usedCarCollection
                          }
                        >
                          {t(usedCar.first.trim())}
                        </option>
                      );
                    })}
                  </select>
                </PSelectWrapper>
              </PGridItem>
              <PGridItem size={3}>
                <PSelectWrapper
                  id="purchaserWrap"
                  label={`${t("Purchaser")}`}
                  style={{ marginTop: "20px" }}
                >
                  <select
                    id="purchaser"
                    name="purchaser"
                    required
                    onChange={(e) => handlePurchaser(e.target.value)}
                    disabled={disabled}
                  >
                    <option key="default" value="0">
                      {t("Select purchaser")}
                    </option>
                    {purchasers.map((user, i) => {
                      return (
                        <option
                          key={user.id}
                          value={user.id}
                          selected={
                            user.id ===
                            orderDetails?.vehicleDetails?.purchaser?.id
                          }
                        >
                          {user.ppnName}
                        </option>
                      );
                    })}
                  </select>
                </PSelectWrapper>
              </PGridItem>
            </>
          )}
        </PGrid>
      </div>
      <div className="order-card others-card">
        <Customer
          customerTypes={customerTypes}
          enableNextButton={enableNextButton}
          tradeInToggle={tradeInToggle}
          referralToggle={referralToggle}
          disabled={disabled}
          customerDetails={orderDetails?.customerDetails}
          handleModified={handleModified}
          updateError={(id) => updateError(id)}
        />
      </div>
      <div className="order-card others-card">
        <Payment
          paymentTypes={paymentTypes}
          enableNextButton={enableNextButton}
          tradeInToggle={tradeInToggle}
          referralToggle={referralToggle}
          disabled={disabled}
          paymentDetails={orderDetails?.paymentDetails}
          handleModified={handleModified}
          updateError={(id) => updateError(id)}
        />
      </div>
      <div className="order-card others-card">
        <Delivery
          deliveryLocations={deliveryLocations}
          deliveryPresents={deliveryPresents}
          registrations={registrations}
          selectedVehicleCategories={models}
          enableNextButton={enableNextButton}
          tradeInToggle={tradeInToggle}
          referralToggle={referralToggle}
          disabled={disabled}
          deliveryDetails={orderDetails?.deliveryDetails}
          handleModified={handleModified}
          updateError={(id) => updateError(id)}
        />
      </div>

      <ContentHide
        title={`${t("Trade-In")}`}
        component={
          <TradeIn
            tradeIns={tradeIns}
            enableNextButton={enableNextButton}
            tradeInToggle={tradeInToggle}
            referralToggle={referralToggle}
            disabled={disabled}
            tradeInDetails={orderDetails?.tradeInDetails}
            handleModified={handleModified}
            updateError={(id) => updateError(id)}
          />
        }
        handleToggle={(show) => handleTradeInToggle(show)}
        enableToggle={orderDetails?.tradeInDetails ? true : false}
        disabled={disabled}
      />

      <ContentHide
        title={`${t("Referral")}`}
        component={
          <Referral
            tradeInToggle={tradeInToggle}
            referralToggle={referralToggle}
            enableNextButton={enableNextButton}
            disabled={disabled}
            referralDetails={orderDetails?.referral}
            handleModified={handleModified}
            updateError={(id) => updateError(id)}
          />
        }
        handleToggle={(show) => handleReferalToggle(show)}
        enableToggle={orderDetails?.referral ? true : false}
        disabled={disabled}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          margin: "0px 30px 0px 30px",
        }}
      >
        <PButton icon="close" onClick={handleOpenExit}>
          {t("Exit")}
        </PButton>
        {/* <PButton variant="primary" type="submit" onClick={handleNextButton} disabled={isValid ? false : true}>Next</PButton> */}
        {changedMode !== "view" && (
          <PButton
            variant="primary"
            onClick={handleNextButton}
            disabled={false}
          >
            {t("Next")}
          </PButton>
        )}
      </div>

      {editOrViewMode && (
        <PModal open={openExit} onClose={handleCloseExit}>
          <PText>{t("There are unsaved entries. Leave order?")}</PText>
          <PButtonGroup className="footer pt24">
            <PButton onClick={handleLeaveWithoutSaving}>
              {t("Leave without saving")}
            </PButton>
            <PButton onClick={handleSaveAndLeave} icon="save">
              {t("Save and leave")}
            </PButton>
            <PButton
              type="button"
              variant="tertiary"
              onClick={handleSaveAndLeave}
              icon="close"
            >
              {t("Cancel")}
            </PButton>
          </PButtonGroup>
        </PModal>
      )}

      {isOrderSavedAsDraft !== undefined && isOrderSavedAsDraft && (
        <PModal open={openExit} onClose={handleCloseExit}>
          <PText>{t("Leave website? Your changes may not be saved.")}</PText>
          <PButtonGroup className="footer pt24">
            <PButton style={{ paddingLeft: "50px" }} onClick={handleCloseTab}>
              {t("Leave")}
            </PButton>
            <PButton
              type="button"
              variant="tertiary"
              onClick={handleCloseExit}
              icon="close"
            >
              {t("Cancel")}
            </PButton>
          </PButtonGroup>
        </PModal>
      )}

      {!isOrderSavedAsDraft && (
        <PModal
          className="modal-exit"
          open={openExit}
          disableCloseButton={true}
          onClose={handleCloseExit}
        >
          <p
            className="modal-exit-header sub-headding"
            style={{
              fontFamily: `${font.family}`,
            }}
          >
            {t("Exit order?")}
          </p>
          <PText>{t("Unsaved entries will be discarded.")}</PText>
          <PButtonGroup className="footer modal-exit-footer">
            <PButton
              type="button"
              variant="tertiary"
              onClick={handleCloseExit}
              icon="close"
            >
              {t("No, cancel")}
            </PButton>

            <PButton
              aria={{ "aria-label": "Arrow Head Right icon" }}
              onClick={handleLeaveWithoutSaving}
            >
              {t("Yes, exit order")}
            </PButton>
          </PButtonGroup>
        </PModal>
      )}
    </>
  );
};
