import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PDivider, PTabs, PTabsItem } from '@porsche-design-system/components-react';

import { PorscheFont } from '../../../assets/styles/CustomStyles';
import { FirstCommissionApproval } from './FirstCommissionApproval';
import { ResubmitCommission } from './ResubmitCommission';
import { CalculationDraftDTO } from '../../../interfaces/Calculation';

interface Props {
  orderId: number;
  handleClose: (reload: boolean) => void;
  resubmit: boolean;
  persistRecalData?:CalculationDraftDTO;
  subsequentActive?:boolean
  subsequentSubmitMessage?:string
}

export const FirstApproval = (props: Props): JSX.Element => {
  const { orderId, handleClose, resubmit, persistRecalData, subsequentActive,subsequentSubmitMessage } = props;
  const { t } = useTranslation();
  return (
    <div className="tn-layout">
      <div className="dcs-section-heading">
        <PorscheFont>{t("Approvals")}</PorscheFont>
      </div>
      <br></br>
      <div>
        <PTabs>
          <PTabsItem
            label={t("Commission Approval")}
            className="inreview"
            style={{ marginTop: "16px" }}
          >
            {subsequentActive ? (
              <ResubmitCommission
                subsequentActive={subsequentActive}
                persistRecalData={persistRecalData}
                id={orderId}
                handleModalClose={() => handleClose(false)}
                subsequentSubmitMessage={subsequentSubmitMessage}
              />
            ) : resubmit ? (
              <ResubmitCommission
                id={orderId}
                handleModalClose={() => handleClose(false)}
              />
            ) : (
              <FirstCommissionApproval
                id={orderId}
                handleModalClose={() => handleClose(false)}
              />
            )}
          </PTabsItem>
        </PTabs>
      </div>
    </div>
  );
}