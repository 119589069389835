import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Stack } from '@mui/material';
import {
  PButton,
  PButtonGroup,
  PButtonPure, PCheckboxWrapper, PDivider, PFlex, PFlexItem, PIcon, PModal, PSelectWrapper,
  PTextFieldWrapper
} from '@porsche-design-system/components-react';

import { PorscheFont } from '../../../assets/styles/CustomStyles';
import { PorscheCenterDTO } from '../../../interfaces/OrderDetails';
import { Scaling, UserDetails } from '../../../interfaces/UserDetails';
import { formatCurrencyWithNoUnit, formatDate } from '../../../mock/helper';
import { type } from '../../../services/Constants';
import {
  dcsgetActiveCommissionScaling,
  dcsgetDealers,
  dcsgetOrderManagedVariablesNew,
  dcsupdateUser
} from '../../../services/data/Helpers';
import { Constant } from '../../../configs/Constants';
import CustomSnakebar from '../../common/CustomSnakebar';

interface Props {
  user: UserDetails;
  refreshData: () => void;
  showMessage: (variant: string, msg: string) => void;
}

export const InActiveUserRow = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const { user, refreshData, showMessage } = props;
  const [edit, setEdit] = useState(false);
  const [reload, setReload] = useState("");
  const [showAdminRights, setShowAdminRights] = useState(false);
  const [showOrderRights, setShowOrderRights] = useState(false);
  const [showExportRights, setShowExportRights] = useState(false);
  const [scalings, setScalings] = useState<Array<Scaling>>([]);
  const [delaerPermissions, setDelaerPermissions] = useState<Array<PorscheCenterDTO>>([]);
  const [dealersFromSameCity, setDealersFromSameCity] = useState<Array<PorscheCenterDTO>>([]);
  const [activate, setActivate] = useState(false);
  const [userDetails,setUserDetails]=  useState<UserDetails>( {} as  UserDetails );
  const [showAlert, setShowAlert] = useState(Constant.None)
  const [alertMessage, setAlertMessage] = useState(Constant.Savedsuccessfully)

  useEffect(() => {
    //reload the data for rerender
  }, [reload]);
  useEffect(() => { 
    const init = async () => {
      const sessionUser = sessionStorage.getItem("userDetails");
      if(sessionUser) {
          const user = JSON.parse(sessionUser);
          setUserDetails(user);
      }
      const apiResponse = await dcsgetDealers()
      if(apiResponse.status===type.SUCCESS){
        setDealersFromSameCity(apiResponse.response);
      }
     
    };
    init();
  }, []);

  useEffect(() => {
    const init = async () => {
     

      const apiResponse = await dcsgetActiveCommissionScaling()
      if(apiResponse.status === type.SUCCESS){
        setScalings(apiResponse.response);
      }

    };
    init();
  }, []);

  useEffect(() => {
    const init = async () => {
      const variables = await dcsgetOrderManagedVariablesNew(1, false);
      setDelaerPermissions(variables[1]);
    };
    init();
  }, []);

  const handleJobTitle = (e: ChangeEvent<HTMLInputElement>, title: string): void => {
    let jobTitle = e.target.value;
    user.jobTitle = jobTitle;
    setReload(new Date().toLocaleString());
  };

  const handleStaffNumber = (e: ChangeEvent<HTMLInputElement>, staff: string): void => {
    let staffNumber = e.target.value;
    user.staffNumber = staffNumber;
    setReload(new Date().toLocaleString());
  };
  const handleScaling = (scalingid: number, id: number): void => {
    user.scaling = {
      id: scalingid
    };
    setReload(new Date().toLocaleString());
  };

  const handleShare = (e: ChangeEvent<HTMLInputElement>, shareValue: number): void => {
    let share = e.target.value
    user.share = parseInt(share);
    setReload(new Date().toLocaleString());
  };
  const saveUser = (edit: boolean) => {
    const initSave = async() => {
    const apiResponse = await dcsupdateUser(user);
    if (apiResponse.status === type.SUCCESS) {
      setEdit(edit);
      setShowAlert(Constant.Success)    
      setAlertMessage(Constant.Savedsuccessfully)
    }
    if(apiResponse.status===type.ERROR){
      setShowAlert(Constant.Error)
      setAlertMessage(apiResponse.response)              
     }


    }

    
    initSave();
  };

  const handleEnableCommission = (enableCommission: boolean, id: number): void => {
    user.enableCommission = enableCommission
    setReload(new Date().toLocaleString());
  };

  const handleEnableVariables = (enableVariabales: boolean, id: number): void => {
    user.enableVariabales = enableVariabales
    setReload(new Date().toLocaleString());
  };
  const handleEnableScaling = (enableScaling: boolean, id: number): void => {

    user.enableScaling = enableScaling;
    setReload(new Date().toLocaleString());
  };

  const handleEnableUserMgmt = (enableUserMgmt: boolean, id: number): void => {

    user.enableUserMgmt = enableUserMgmt
    setReload(new Date().toLocaleString());
  };

  const handleEnableApprovals = (enableApprovals: boolean, id: number): void => {

    user.enableApprovals = enableApprovals
    setReload(new Date().toLocaleString());
  };

  const handleCanViewAllOrders = (canViewAllOrders: boolean, id: number): void => {

    user.canViewAllOrders = canViewAllOrders
    setReload(new Date().toLocaleString());
  };
  const handleCanEditAllOrders = (canEditAllOrders: boolean, id: number): void => {

    user.canEditAllOrders = canEditAllOrders
    setReload(new Date().toLocaleString());
  };

  const handleCanViewOrderCommissions = (canViewOrderCommissions: boolean, id: number): void => {

    user.canViewOrderCommissions = canViewOrderCommissions
    setReload(new Date().toLocaleString());
  };

  const handleCanExportOrders = (canExportOrders: boolean, id: number): void => {
    user.canExportOrders = canExportOrders
    setReload(new Date().toLocaleString());
  };
  const handleCanExportCommissions = (canExportCommissions: boolean, id: number): void => {

    user.canExportCommissions = canExportCommissions
    setReload(new Date().toLocaleString());
  };

  const handleActivateClose = () => {
    setActivate(false);
   // window.location.reload();
  };
  const handleActivateOpen = () => {
    setActivate(true);
  
  };

  const isOrigin=(user:UserDetails,dealer:PorscheCenterDTO)=>{

    if(user.origin.id==dealer.id){
      return true;
    }
    
    if(user.origin.origin==dealer.origin){
      return false;
    }else{
      return true;
    }
   };
   const isChecked=(user:UserDetails,dealer:PorscheCenterDTO)=>{
    
    const exists =user.dealerPermissions.filter(de=>de.id==dealer.id);
    if(exists.length>0){
      return true;
    }else{
      return false;
    }
   };

   const handleDealerPermissions=(user:UserDetails,dealer:PorscheCenterDTO)=>{
    const exists =user.dealerPermissions.filter(de=>de.id==dealer.id);
    if(exists.length>0){
      user.dealerPermissions.map((de,i)=>{
        if(de.id===dealer.id){
          user.dealerPermissions.splice(i,1); 
        }
      })
   
    }else{
      user.dealerPermissions.push(dealer);
    }
   
    setReload(new Date().toLocaleString());
   
   };

  const activateUser=()=>{
    const initActivate = async () => {
      user.active=true;
     

      const apiResponse = await dcsupdateUser(user);

    if (apiResponse.status === type.SUCCESS) {
      setShowAlert(Constant.Success)    
      setAlertMessage(Constant.Savedsuccessfully)
      setActivate(false);
      refreshData();
      setReload(new Date().toLocaleString());
    }
    if(apiResponse.status===type.ERROR){
      setShowAlert(Constant.Error)
      setAlertMessage(apiResponse.response)              
     }
    }
    initActivate();
  };

  const handleClose = ( ) => {
    setShowAlert(Constant.None)
    setAlertMessage("")
    sessionStorage.setItem("messageFlag", ("false" || false));
  };
  return (
    <>
      <PFlex>
        <PFlexItem style={{ width: "70%" }}>
          <Stack direction={"row"} spacing={1}>
            <img src={require("../../../assets/images/User.png")} alt={"User"} width="12" height="12" style={{ marginTop: "5px" }} />
            <PorscheFont className="dcs-heading">{user.ppnName}</PorscheFont>
          </Stack>
        </PFlexItem>
        {edit && (<>
          <PFlexItem style={{ width: "20%" }}>
            <PButtonPure icon={"edit"} onClick={() => setEdit(!edit)}>{t("Edit")}</PButtonPure>
          </PFlexItem>
          <PFlexItem style={{ width: "10%" }}>
            <PButtonPure icon={"subtract"}  onClick={()=>handleActivateOpen()}>{t("Deactivate")}</PButtonPure>
          </PFlexItem>
        </>)}
        {!edit && (<>
          <PFlexItem style={{ width: "20%" }}>
          <PorscheFont className="tn-text-12-600">
            {t("Deactivated on")}: {formatDate(user.deActivatedon)} 
          </PorscheFont>
          </PFlexItem>
          <PFlexItem style={{ width: "10%" }}>
            <PButtonPure icon={"subtract"} disabled={!userDetails.enableUserMgmt} onClick={()=>handleActivateOpen()}>{t("Reactivate")}</PButtonPure>
          </PFlexItem>
        </>)}
      </PFlex>
      {edit && (
        <>
          <PFlex className='mt12' justifyContent={"space-between"}>
            <PFlexItem>
              { dealersFromSameCity.map((dealer,i)=>{
                if(!isOrigin(user,dealer)) {
                  return (
                    <div className={`${isChecked(user,dealer) ? 'dealer-btn-selected' : 'dealer-btn'}`} style={{marginTop: "-8px"}} onClick={() => handleDealerPermissions(user,dealer)}>{dealer.centerName}</div>
                  )
                } else {
                  return (
                    <div className={'dealer-btn-disabled'} style={{marginTop: "-8px"}}>{dealer.centerName}</div>
                  )
                }
              })}
        
            </PFlexItem>
            <PFlexItem>
              <PDivider orientation={"vertical"} color="neutral-contrast-medium" style={{ height: "20px", color: "#C8CACB" }} />
            </PFlexItem>
            <PFlexItem>
              <Stack direction={"row"} spacing={1}>
                <PorscheFont className="tn-text-14-600">{t("Staff No. ")}</PorscheFont>
                <div style={{ width: "80px", marginTop: "-8px" }}>
                  <PTextFieldWrapper id={`umiu-0`}>
                    <input
                      id={`iumiu-0`}
                      key={`kumiu-0`}
                      type="text"
                      value={user.staffNumber}
                      onChange={(e) => handleStaffNumber(e, user.staffNumber)}
                    />
                  </PTextFieldWrapper>
                </div>
              </Stack>
            </PFlexItem>
            <PFlexItem>
              <PDivider orientation={"vertical"} color="neutral-contrast-medium" style={{ height: "20px", color: "#C8CACB" }} />
            </PFlexItem>
            <PFlexItem>
              <Stack direction={"row"} spacing={1}>
                <PorscheFont className="tn-text-14-600">{t("Job Title")} </PorscheFont>
                <div style={{ width: "150px", marginTop: "-8px" }}>
                  <PTextFieldWrapper id={`umiu-0`}>
                    <input
                      id={`iumiu-0`}
                      key={`kumiu-0`}
                      type="text"
                      value={user.jobTitle}
                      onChange={(e) => handleJobTitle(e, user.jobTitle)}
                    />
                  </PTextFieldWrapper>
                </div>
              </Stack>
            </PFlexItem>
            <PFlexItem>
              <PDivider orientation={"vertical"} color="neutral-contrast-medium" style={{ height: "20px", color: "#C8CACB" }} />
            </PFlexItem>

            <PFlexItem>
              <Stack direction={"row"} spacing={1}>
                <PorscheFont className="tn-text-14-600">{t("Commission")}</PorscheFont>
                <div style={{ width: "100px", marginTop: "-8px" }}>
                  <PSelectWrapper >
                    <select name="commissionScaling" onChange={(e) => handleScaling(parseInt(e.target.value), user.id)} disabled={edit ? false : true}>

                      {
                        scalings.map((scaling, i) => {

                          return (<option value={scaling.id} selected={scaling.id === user.scaling?.id ? true : false}> {scaling.name} </option>);

                        })
                      }

                    </select>
                  </PSelectWrapper>
                </div>
                <PDivider orientation={"vertical"} color="neutral-contrast-medium" style={{ height: "20px", color: "#C8CACB" }} />
                <PorscheFont className="tn-text-14-600">{t("Share")} </PorscheFont>
                <div style={{ width: "75px", marginTop: "-8px" }}>
                  <PTextFieldWrapper id={`umshare-0`}>
                    <input
                      type="text"
                      defaultValue={formatCurrencyWithNoUnit(user.share)}
                      onBlur={(e) => handleShare(e, user.share)}
                    />
                  </PTextFieldWrapper>
                </div>
              </Stack>
            </PFlexItem>
          </PFlex>

          {/** TODO RAina */}
          <PFlex className='mt12'>
            <PFlexItem>
              <Stack direction={"row"} spacing={1}>
                <div className="rights tn-title" onClick={() => setShowAdminRights(!showAdminRights)} style={{marginRight:"4px"}}>
                      <PFlex> 
                        <PFlexItem style={{width:"85%"}} > 
                        <PorscheFont>{t("Admin Rights")}</PorscheFont>
                        </PFlexItem>                    
                        <PFlexItem style={{width:"15%", marginTop:"-7px", marginRight:"4px"}}>
                          <PIcon name={showAdminRights ? "arrow-head-up" : "arrow-head-down"} className="mt8" />
                        </PFlexItem>
                      </PFlex>
                    </div>
                    {showAdminRights && (
                    <div className="dropdown-adminRights" style={{marginTop: "2px"}}>
                      <ul>
                        <li>
                          <PCheckboxWrapper label={`${t("Variables")}`} hideLabel={false}>
                            <input
                              key={`idealer`}
                              type="checkbox"
                                checked={user.enableVariabales}
                                  onClick={(e) => handleEnableVariables(!user.enableVariabales,user.id)}
                              disabled={edit ? false : true}

                            />
                          </PCheckboxWrapper>

                        </li>
                        <li>
                          <PCheckboxWrapper label={`${t("Scaling")}`} hideLabel={false}>
                            <input
                              key={`idealer`}
                              type="checkbox"
                                checked={user.enableScaling}
                                  onClick={(e) => handleEnableScaling(!user.enableScaling,user.id)}
                              disabled={edit ? false : true}

                            />
                          </PCheckboxWrapper>
                        </li>
                        <li>
                          <PCheckboxWrapper label={`${t("User Mgmt.")}`} hideLabel={false}>
                            <input
                              key={`idealer`}
                              type="checkbox"
                                checked={user.enableUserMgmt}
                                  onClick={(e) => handleEnableUserMgmt(!user.enableUserMgmt,user.id)}
                              disabled={edit ? false : true}
                            />
                          </PCheckboxWrapper>
                        </li>
                        <li>
                          <PCheckboxWrapper label={`${t("Approvals")}`} hideLabel={false}>
                            <input
                              key={`idealer`}
                              type="checkbox"
                                checked={user.enableApprovals}
                                  onClick={(e) => handleEnableApprovals(!user.enableApprovals,user.id)}
                              disabled={edit ? false : true}
                            />
                          </PCheckboxWrapper>
                        </li>
                      </ul>
                    </div>)}
              </Stack>
            </PFlexItem>
            <PFlexItem>
              <Stack direction={"row"} spacing={1}>
                <PorscheFont className="tn-title">{t("Order Rights")}</PorscheFont>
                <div className="dealer-dropdown">
                  <ul>
                    <li>
                      <PCheckboxWrapper label={`${t("View all")}`} hideLabel={false}>
                        <input
                          key={`idealer`}
                          type="checkbox"
                            checked={user.canViewAllOrders}
                          onClick={(e) => handleCanViewAllOrders(!user.canViewAllOrders, user.id)}

                        />
                      </PCheckboxWrapper>
                    </li>
                    <li>
                      <PCheckboxWrapper label={`${t("Edit all")}`} hideLabel={false}>
                        <input
                          key={`idealer`}
                          type="checkbox"
                          checked={user.canEditAllOrders}
                          onClick={(e) => handleCanEditAllOrders(!user.canEditAllOrders, user.id)}
                          disabled={edit ? false : true}
                        />
                      </PCheckboxWrapper>
                    </li>
                    <li>
                      <PCheckboxWrapper label={`${t("View all commissions")}`} hideLabel={false}>
                        <input
                          key={`idealer`}
                          type="checkbox"
                          checked={user.canViewOrderCommissions}
                          onClick={(e) => handleCanViewOrderCommissions(!user.canViewOrderCommissions, user.id)}
                          disabled={edit ? false : true}
                        />
                      </PCheckboxWrapper>
                    </li>
                  </ul>
                </div>
              </Stack>
            </PFlexItem>
            <PFlexItem>
              <Stack direction={"row"} spacing={1}>
                <PorscheFont className="tn-title">{t("Export Rights")}</PorscheFont>
                <div className="dealer-dropdown">
                  <ul>
                    <li>
                      <PCheckboxWrapper label={`${t("Orders")}`} hideLabel={false}>
                        <input
                          key={`idealer`}
                          type="checkbox"
                          checked={user.canExportOrders}
                          onClick={(e) => handleCanExportOrders(!user.canExportOrders, user.id)}
                          disabled={edit ? false : true}
                        />
                      </PCheckboxWrapper>
                    </li>
                    <li>
                      <PCheckboxWrapper label={`${t("Commissions")}`} hideLabel={false}>
                        <input
                          key={`idealer`}
                          type="checkbox"
                            checked={user.canExportCommissions}
                              onClick={(e) => handleCanExportCommissions(!user.canExportCommissions,user.id)}
                          disabled={edit ? false : true}
                        />
                      </PCheckboxWrapper>
                    </li>
                  </ul>
                </div>
              </Stack>
            </PFlexItem>
          </PFlex>
          <PFlex justifyContent={"flex-end"}>
            <PFlexItem style={{ width: "10%" }}>
              <PButtonPure icon={"save"} onClick={() => saveUser(!edit)}>{t("Save")}</PButtonPure>
            </PFlexItem>
            <PFlexItem style={{ width: "10%" }}>
              <PButtonPure icon={"close"} onClick={() => setEdit(!edit)}>{t("Cancel")}</PButtonPure>
            </PFlexItem>
          </PFlex>

        </>
      )}

{!edit && (
        <>
          {/* <PFlex className='mt12' justifyContent={"space-between"}> */}
          <PFlex className='mt12'>
            <PFlexItem>
              <Stack direction={"row"} spacing={1}>
                <PorscheFont className="tn-text-14-600">{t("Dealer Permissions")}</PorscheFont>
                <PorscheFont className="tn-title">
                  {`${user.origin.centerName}`}
                  {user.dealerPermissions.length > 0 && user.dealerPermissions.map((dealer,i)=>{
                      return (<>{`, ${dealer.centerName}`}</>)
                  })}
              </PorscheFont>
              </Stack>
            </PFlexItem>
            <PFlexItem>
              <PDivider orientation={"vertical"} color="neutral-contrast-medium" style={{height: "20px", color:"#C8CACB", marginRight:"16px", marginLeft:"16px"}} />
            </PFlexItem>
            
            {user.staffNumber && (
            <>
              <PFlexItem>
                <Stack direction={"row"} spacing={1}>
                  <PorscheFont className="tn-text-14-600">{t("Staff No.")} </PorscheFont>
                  <PorscheFont className="tn-title">{user.staffNumber}</PorscheFont>
                </Stack>
              </PFlexItem>
              <PFlexItem>
                <PDivider orientation={"vertical"} color="neutral-contrast-medium" style={{height: "20px", color:"#C8CACB", marginRight:"16px", marginLeft:"16px"}} />
              </PFlexItem>
            </>
            )}
            {user.jobTitle && (
              <>
                <PFlexItem>
                  <Stack direction={"row"} spacing={1}>
                    <PorscheFont className="tn-text-14-600">{t("Job Title")} </PorscheFont>
                    <PorscheFont className="tn-title">{user.jobTitle}</PorscheFont>
                  </Stack>
                </PFlexItem>
                <PFlexItem>
                  <PDivider orientation={"vertical"} color="neutral-contrast-medium" style={{height: "20px", color:"#C8CACB", marginRight:"16px", marginLeft:"16px"}} />
                </PFlexItem>
              </>
            )}
            <PFlexItem>
              <Stack direction={"row"} spacing={1}>
                <PorscheFont className="tn-text-14-600">{t("Origin")}</PorscheFont>
                <PorscheFont className="tn-title">{user.origin.centerName}</PorscheFont>
              </Stack>
            </PFlexItem>
            {user?.scaling && (<>
              <PFlexItem>
                <PDivider orientation={"vertical"} color="neutral-contrast-medium" style={{ height: "20px", color: "#C8CACB", marginRight:"16px", marginLeft:"16px" }} />
              </PFlexItem>
              <PFlexItem>
                <Stack direction={"row"} spacing={1}>
                  <PorscheFont className="tn-text-14-600">{t("Commission ")}</PorscheFont>
                  <PorscheFont className="tn-title">{user?.scaling?.name}</PorscheFont>
                  <PDivider orientation={"vertical"} color="neutral-contrast-medium" style={{ height: "20px", color: "#C8CACB" }} />
                  <PorscheFont className="tn-title">{user.share}</PorscheFont>
                </Stack>
              </PFlexItem>
            </>)
            }
          </PFlex>

          <PFlex className='mt12' justifyContent={"space-between"}>
            {
              (user.enableVariabales || user.enableScaling || user.enableUserMgmt || user.enableApprovals) && (<><PFlexItem>
                <Stack direction={"row"} spacing={1}>
                  <PorscheFont className="tn-title">{t("Admin Rights")}</PorscheFont>
                  {user.enableVariabales && <div className="tn-btn"><PorscheFont>{t("Variables")}</PorscheFont></div>}
                  {user.enableScaling && <div className="tn-btn"><PorscheFont>{t("Comm. Scaling")}</PorscheFont></div>}
                  {user.enableUserMgmt && <div className="tn-btn"><PorscheFont>{t("User Mgmt.")}</PorscheFont></div>}
                  {user.enableApprovals && <div className="tn-btn"><PorscheFont>{t("Approval process")}</PorscheFont></div>}
                </Stack>
              </PFlexItem></>)
            }
            {
              (user.canViewAllOrders || user.canEditAllOrders || user.canViewOrderCommissions) && (<><PFlexItem>
                <Stack direction={"row"} spacing={1}>
                  <PorscheFont className="tn-title">{t("Order Rights")}</PorscheFont>
                  {user.canViewAllOrders && <div className="tn-btn"><PorscheFont>{t("View all")}</PorscheFont></div>}
                  {user.canEditAllOrders && <div className="tn-btn"><PorscheFont>{t("Edit all")}</PorscheFont></div>}
                  {user.canViewOrderCommissions && <div className="tn-btn"><PorscheFont>{t("View all commissions")}</PorscheFont></div>}
                </Stack>
              </PFlexItem></>)
            }{(user.canExportCommissions || user.canExportOrders) &&
              (<><PFlexItem>
                <Stack direction={"row"} spacing={1}>
                  <PorscheFont className="tn-title">{t("Export Rights")}</PorscheFont>
                  {user.canExportOrders && <div className="tn-btn"><PorscheFont>{t("Orders")}</PorscheFont></div>}
                  {user.canExportCommissions && <div className="tn-btn"><PorscheFont>{t("Commissions")}</PorscheFont></div>}
                </Stack>
              </PFlexItem></>)
            }
          </PFlex>
        </>
      )}
      <PDivider color="neutral-contrast-high" style={{ marginTop: "12px", marginBottom: "12px" }} />
      <PModal 
        className="overview-modal"
        open={activate} 
        onClose={handleActivateClose} 
      >
        <PFlex>
          <PFlexItem style={{marginRight: "4px", marginTop:"6px"}}>
            <PorscheFont className="dcs-heading">
              {t("Activate this user?")}
            </PorscheFont>
          </PFlexItem>
        </PFlex>
        <PFlex dir="rtl" style={{ marginTop: "14px" }}>
          <PFlexItem>
            <PButtonGroup>
              <PButton 
                icon={"arrow-head-right"} 
                onClick={activateUser}
              >
                {t("Yes, activate")}
              </PButton>
              <PButton
                  variant="tertiary"
                  icon={"close"}
                  onClick={handleActivateClose}
                >
                  {t("Cancel")}
                </PButton>
            </PButtonGroup>
          </PFlexItem>
        </PFlex>
      </PModal>  
      <CustomSnakebar alertType={showAlert} alertMessage={alertMessage} handleClose={()=>handleClose()}/>
    </>
  );
}