import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PorscheFont } from '../../../assets/styles/CustomStyles';
import { UserDetails } from '../../../interfaces/UserDetails';
import { type } from '../../../services/Constants';
import { dcsgetNewUsers } from '../../../services/data/Helpers';
import ScrollToTop from '../../common/ScrollToTop';
import { NewUserRow } from './NewUserRow';

export const NewUsers= (): JSX.Element => {
  
  const [ reload, setReload ] = useState("");
  const [newUsers, setNewUsers] = useState<Array<UserDetails>>([]);
  const { t } = useTranslation();
  useEffect(() => {
    const init = async () => {
       const apiResponse = await dcsgetNewUsers()
      if (apiResponse.status===type.SUCCESS) {
        setNewUsers(apiResponse.response);
        window.location.reload();
      }
    };
    init();
  }, []);
  
  useEffect(() => {
  //reload the data for rerender
  }, [reload]);


 
  return(
    <>
    <div className="container-variable" style={{paddingTop: "30px"}}>
      <ScrollToTop />
      <PorscheFont className="dcs-section-heading">{t("New Users")}</PorscheFont>
    </div>
    <div className="container-variable">
    {
      newUsers.map((user, i) => {
        return (
          <NewUserRow  user={user}/>  
        );
      })
    }
    {newUsers.length === 0 && (
      <PorscheFont className="tn-16-600">{t("No new users.")}</PorscheFont>
    )
    }
    </div>
  </>
  );
};
