import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { Stack, Tab, Tabs } from "@mui/material";
import {
  PButton,
  PButtonGroup,
  PFlex,
  PFlexItem,
  TabChangeEvent,
} from "@porsche-design-system/components-react";

import { PMainHeader, PorscheFont } from "../../assets/styles/CustomStyles";
import { NavSideBar } from "../../components/NavSideBar";
import { Accounting } from "../../components/administration/approvalProcess/Accounting";
import { ApprovalDemoCar } from "../../components/administration/approvalProcess/ApprovalDemoCar";
import { ApprovalNewCar } from "../../components/administration/approvalProcess/ApprovalNewCar";
import { ApprovalUsedCar } from "../../components/administration/approvalProcess/ApprovalUsedCar";
import CustomSnakebar from "../../components/common/CustomSnakebar";
import ScrollToTop from "../../components/common/ScrollToTop";
import { Constant } from "../../configs/Constants";
import { AuthContext } from "../../contexts/AuthContext";
import { IsUserAllowed } from "../../helpers/permissions";
import { UserDetails } from "../../interfaces/UserDetails";
import {
  AccountingConfigurationDTO,
  ApprovalConfiguration,
} from "../../interfaces/adminApproval/ApprovalConfiguration";
import { HistoryLogTypes } from "../../interfaces/changeHistory";
import { type } from "../../services/Constants";
import {
  dcspostApprovalConfiguration,
  dcspostChangeHistoryLogs,
  dcsputAccountingApprovalConfiguration
} from "../../services/data/Helpers";

interface HeaderTabPanels {
  id: number;
  name: string;
  enabled: boolean;
  className: string;
}
export const ApprovalProcessPage = (): JSX.Element => {
  const { t } = useTranslation();
  const [edit, setEdit] = useState(false);
  const [save, setSave] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [isModified, setIsModified] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState<boolean>(false);
  const [userPost, setUserPost] = useState<Array<UserDetails>>([]);
  const [approvalConfig, setApprovalConfig] = useState<ApprovalConfiguration>(
    {} as ApprovalConfiguration
  );
  const [accountApprovalConfig, setAccountApprovalConfig] =
    useState<AccountingConfigurationDTO>({} as AccountingConfigurationDTO);
  const authCtx = useContext(AuthContext);
  const [reload, setReload] = useState("");
  const [showHeader, setShowHeader] = useState(true);
  const messageData: boolean =
    JSON.parse(sessionStorage.getItem("messageFlag") || "false") || false;

  const [tabPanels, setTabPanels] = useState<Array<HeaderTabPanels>>([]);
  const [panelIndex, setPanelIndex] = useState<number>(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [modeChange, setChangeMode] = useState("");
  const [showAlert, setShowAlert] = useState(Constant.None)
  const [alertMessage, setAlertMessage] = useState(Constant.Savedsuccessfully)


  const currPageName = "Approval Process";
  const sessionUser = sessionStorage.getItem("userDetails");
  let userId = "";
  if (sessionUser) {
    userId = JSON.parse(sessionUser).id;
  }

  const logData: HistoryLogTypes = {
    page: currPageName,
    tab: tabPanels[panelIndex]?.name,
    userId: userId,
  };
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setPanelIndex(newValue);
  };
  useEffect(() => {
    const mode = searchParams.get("mode") || "";
    setChangeMode(mode);
  }, []);
  useEffect(() => {}, [edit]);

  const onTabChange = (e: CustomEvent<TabChangeEvent>) => {
    setTabIndex(e.detail.activeTabIndex);
  };
  const handleShowHeader = () => {
    setShowHeader(true);
  };
  const handleRemoveHeader = () => {
    setShowHeader(false);
  };
  const onNextTabChange = (panelIndex: number) => {
    setTabIndex(panelIndex);
  };

  const handleEdit = () => {
    sessionStorage.setItem("changeFlag", "true");
    setEdit(true);
  };
  const handleSave = () => {
    sessionStorage.setItem("changeFlag", "false");
    switch (panelIndex) {
      case 0:
        try {
          const init = async () => {
            const apiResponse = await dcspostApprovalConfiguration(
              approvalConfig
            );
            if (apiResponse.status === type.SUCCESS) {
              setShowAlert(Constant.Success)
              setAlertMessage(Constant.Savedsuccessfully)
              window.location.reload();
              setSaveSuccess(true);
              sessionStorage.setItem("messageFlag", "true" || true);
              setReload(new Date().toString());
              await dcspostChangeHistoryLogs(logData,'admin');
            }
            if (apiResponse.status === type.ERROR) {
              setShowAlert(Constant.Error)
              setAlertMessage(apiResponse.response)
              }
          };
          init();
        } catch (e) {}
        setEdit(false);
        break;
      case 1:
        try {
          const init = async () => {
           const apiResponse =  await dcspostApprovalConfiguration(approvalConfig);
           if (apiResponse.status === type.SUCCESS) {
            setShowAlert(Constant.Success)
            setAlertMessage(Constant.Savedsuccessfully)
            await dcspostChangeHistoryLogs(logData, 'admin');
              setSaveSuccess(true);
            }
            if (apiResponse.status === type.ERROR) {
              setShowAlert(Constant.Error)
              setAlertMessage(apiResponse.response)            }

          };
          init();
        } catch (e) {}
        setEdit(false);

        break;
      case 2:
        try {
          const init = async () => {
           const apiResponse =  await dcspostApprovalConfiguration(approvalConfig);
           if (apiResponse.status === type.SUCCESS) {
            setShowAlert(Constant.Success)
            setAlertMessage(Constant.Savedsuccessfully)
            await dcspostChangeHistoryLogs(logData, 'admin');
              setSaveSuccess(true);
            }
            if (apiResponse.status === type.ERROR) {
              setShowAlert(Constant.Error)
              setAlertMessage(apiResponse.response)
            }
          };
          init();
        } catch (e) {}
        setEdit(false);
        break;
      case 3:
        try {
          const init = async () => {
            const apiResponse = await dcsputAccountingApprovalConfiguration(accountApprovalConfig);
            if (apiResponse.status === type.SUCCESS) {
              setShowAlert(Constant.Success)
              setAlertMessage(Constant.Savedsuccessfully)
              setSaveSuccess(true);
            await dcspostChangeHistoryLogs(logData, 'admin');
            }
            if (apiResponse.status === type.ERROR) {
              setShowAlert(Constant.Error)
              setAlertMessage(apiResponse.response)
            }
          };
          init();
        } catch (e) {}
        setEdit(false);
        break;
    }
  };
  

  const handleCancel = () => {
    sessionStorage.setItem("changeFlag", "false");
    switch (panelIndex) {
      default:
        setShowSaveButton(false);
    }

    setEdit(false);
    setCancel(true);
    window.location.reload();
  };

  const onEditMode = (enableSave: boolean) => {
    setShowSaveButton(enableSave);
  };

 
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    setShowAlert(Constant.None)
    setAlertMessage("")
  };

  const handleUsers = (appconfig: ApprovalConfiguration) => {
    setApprovalConfig(appconfig);
    setIsModified(true);
    setShowSaveButton(validateApproval(appconfig));
  };
  const handleUsersData = (appconfig: AccountingConfigurationDTO) => {
    setAccountApprovalConfig(appconfig);
    setIsModified(true);
    setShowSaveButton(true);
  };

  const validateApproval = (appconfig: ApprovalConfiguration) => {
    if (appconfig.orderApproval) {
      for (let i = 1; i < 6; i++) {
        if (appconfig.orderApproval[i].isEnabled) {
          if (appconfig.orderApproval[i].approvers === undefined) return false;
          if (i === 3) {
            if (
              appconfig.orderApproval[3].approvers &&
              appconfig.orderApproval[3].approvers?.length < 2
            ) {
              return false;
            }
          }
          if (
            appconfig.orderApproval[i].approvers &&
            appconfig.orderApproval[i].approvers?.length === 0
          ) {
            return false;
          }
        }
      }
    }

    if (appconfig.commissionApproval) {
      if (
        appconfig.commissionApproval[2].isEnabled &&
        appconfig.commissionApproval[2].approvers &&
        appconfig.commissionApproval[2].approvers.length === 0
      ) {
        return false;
      }
      if (
        appconfig.commissionApproval[3].isEnabled &&
        appconfig.commissionApproval[3].approvers &&
        appconfig.commissionApproval[3].approvers.length === 0
      ) {
        return false;
      }
    }
    return true;
  };
  const enableSave = (value: boolean) => {
    setShowSaveButton(value ? true : false);
  };
  const handleIsModified = () => {
    setIsModified(true);
    setShowSaveButton(true);
  };
  useEffect(() => {
    var panels = [];
    panels.push({
      id: 0,
      name: "New Car",
      enabled: true,
      className: "",
    });
    panels.push({
      id: 1,
      name: "Demo Car",
      enabled: true,
      className: "",
    });
    panels.push({
      id: 2,
      name: "Used Car",
      enabled: true,
      className: "",
    });
    panels.push({
      id: 3,
      name: "Accounting",
      enabled: true,
      className: "",
    });
    setTabPanels(panels);
  }, []);

  return (
    <>
      <div className="custombody" style={{ marginBottom: "-40px" }}>
        <div
          className="navbar"
          style={{ position: "fixed", top: "85px", zIndex: "9999" }}
        >
          <NavSideBar
            approvalSave={handleSave}
            disabled={!showSaveButton}
            main="Administration"
            subPage="Approval Process"
          />
        </div>
        <div>
          <div
            className="custombody"
            style={{
              width: "100%",
              backgroundColor: "#F2F2F2",
              padding: "100px 0px 10px 71px",
            }}
          >
            <div
              className="admin-header"
              style={{
                position: "fixed",
                marginTop: "-22px",
                padding: "40px 40px 16px 40px",
                marginLeft: "-10px",
                backgroundColor: "#F2F2F2",
                zIndex: "999",
              }}
            >
              <div className="main-header-left">
                <PMainHeader>
                  <PorscheFont
                    data-testid="page-variables"
                    className="main-header-text"
                  >
                    {t("Approval process")}
                  </PorscheFont>
                  <PorscheFont className="main-header-description">
                    {t(
                      "Define all relevant approval steps processes by assigning specific users as approval authorities."
                    )}
                  </PorscheFont>
                </PMainHeader>
                <PFlex>
                  <PFlexItem width="three-quarters">
                    {
                      <Tabs
                        textColor="primary"
                        indicatorColor="secondary"
                        onChange={handleChange}
                        value={panelIndex}
                        sx={{
                          minHeight: "37px!important",
                          "& .MuiTabs-indicator": {
                            backgroundColor: "#D5001B",
                            height: 2,
                          },
                          "& .MuiTab-root.Mui-selected": {
                            color: "#313639",
                            textTransform: "inherit",
                            fontFamily: "Porsche Next",
                            paddingBottom: "5px",
                          },
                        }}
                      >
                        {tabPanels.map((tabPanel, i) => {
                          return (
                            <Tab
                              disabled={!tabPanel.enabled}
                              label={t(tabPanel.name)}
                              value={tabPanel.id}
                              sx={{
                                borderLeft: "1px solid #C8CACB ",
                                padding: "0px 24px !important",
                                minHeight: "16px!important",
                                textTransform: "inherit",
                                fontFamily: "Porsche Next",
                                fontSize: "16px",
                                marginBottom: "-30px",
                                marginTop: "8px",
                              }}
                            />
                          );
                        })}
                      </Tabs>
                    }
                  </PFlexItem>
                  <PFlexItem
                    style={
                      window.innerHeight > 1000
                        ? { paddingLeft: "250px", marginTop: "-45px" }
                        : { paddingLeft: "125px", marginTop: "-45px" }
                    }
                  >
                    {!edit && (
                      <PButton
                        icon="edit"
                        onClick={handleEdit}
                        disabled={
                          IsUserAllowed("adminVariables", authCtx)
                            ? false
                            : true
                        }
                      >
                        {t("Edit entries")}
                      </PButton>
                    )}
                    {edit && (
                      <PButtonGroup>
                        <Stack
                          direction="row"
                          style={
                            window.innerHeight > 1000
                              ? { paddingRight: "80px" }
                              : { paddingRight: "60px" }
                          }
                        >
                          <PButton
                            variant="tertiary"
                            icon="close"
                            onClick={handleCancel}
                          >
                            {t("Cancel")}
                          </PButton>
                          &ensp;
                          <PButton
                            icon="save"
                            onClick={handleSave}
                            disabled={!showSaveButton}
                          >
                            {t("Save")}
                          </PButton>
                        </Stack>
                      </PButtonGroup>
                    )}
                  </PFlexItem>
                </PFlex>
              </div>
            </div>
            <div
              style={{
                padding: "128px 125px 35px 32px",
                minHeight: "100vh",
                width: "100vw",
              }}
            >
              <ScrollToTop />
              {panelIndex === 0 && (
                <ApprovalNewCar
                  edit={edit}
                  save={save}
                  cancel={cancel}
                  handleUsersPost={handleUsers} //Need to modify according to requriement
                  modified={handleIsModified}
                  enableSave={(value) => enableSave(value)}
                  showHeader={handleShowHeader}
                  removeHeader={handleRemoveHeader}
                />
              )}
              {panelIndex === 1 && (
                <ApprovalDemoCar
                  edit={edit}
                  save={save}
                  cancel={cancel}
                  handleUsersPost={handleUsers} //Need to modify according to requriement
                  modified={handleIsModified}
                  enableSave={(value) => enableSave(value)}
                  showHeader={handleShowHeader}
                  removeHeader={handleRemoveHeader}
                />
              )}
              {panelIndex === 2 && (
                <ApprovalUsedCar
                  edit={edit}
                  save={save}
                  cancel={cancel}
                  handleUsersPost={handleUsers} //Need to modify according to requriement
                  modified={handleIsModified}
                  enableSave={(value) => enableSave(value)}
                  showHeader={handleShowHeader}
                  removeHeader={handleRemoveHeader}
                />
              )}
              {panelIndex === 3 && (
                <Accounting
                  edit={edit}
                  save={save}
                  cancel={cancel}
                  handleUsersPost={handleUsersData} //Need to modify according to requriement
                  modified={handleIsModified}
                  enableSave={(value) => enableSave(value)}
                  showHeader={handleShowHeader}
                  removeHeader={handleRemoveHeader}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <CustomSnakebar alertType={showAlert} alertMessage={alertMessage} handleClose={()=>handleClose()}/>
    </>
  );
};
