import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Stack } from '@mui/material';
import {
  PButton,
  PButtonGroup,
  PButtonPure, PCheckboxWrapper, PDivider, PFlex, PFlexItem, PIcon, PModal, PSelectWrapper,
  PTextFieldWrapper
} from '@porsche-design-system/components-react';

import { PorscheFont } from '../../../assets/styles/CustomStyles';
import { PorscheCenterDTO } from '../../../interfaces/OrderDetails';
import { Scaling, UserDetails } from '../../../interfaces/UserDetails';
import {
  formatCurrencyWithNoUnit
} from '../../../mock/helper';
import { type } from '../../../services/Constants';
import {
  dcsgetActiveCommissionScaling,
  dcsgetDealers,
  dcsgetOrderManagedVariablesNew,
  updateUser
} from '../../../services/data/Helpers';

interface Props {
  user: UserDetails;

}
export const NewUserRow = (props: Props): JSX.Element => {
  const { user } = props;
  const { t } = useTranslation();
  const [edit, setEdit] = useState(true);
  const [reload, setReload] = useState("");
  const [scalings, setScalings] = useState<Array<Scaling>>([]);
  const [delaerPermissions, setDelaerPermissions] = useState<Array<PorscheCenterDTO>>([]);
  const [dealersFromSameCity, setDealersFromSameCity] = useState<Array<PorscheCenterDTO>>([]);
  const [activate, setActivate] = useState(false);
  const [userDetails,setUserDetails]=  useState<UserDetails>( {} as  UserDetails );
  const [showSendTo, setShowSendTo] = useState(false);
  const [showOrderRights, setShowOrderRights] = useState(false);
  const [showExportRights, setShowExportRights] = useState(false);

  useEffect(() => {
    //reload the data for rerender
  }, [reload]);
  useEffect(() => {
    const init = async () => {
      
      
      const sessionUser = sessionStorage.getItem("userDetails");
      if(sessionUser) {
          const user = JSON.parse(sessionUser);
          setUserDetails(user);
      }
     
      const apiResponse = await dcsgetDealers();
      if (apiResponse.status===type.SUCCESS) {
        setDealersFromSameCity(apiResponse.response);
      }
    };
    init();
  }, []);

  useEffect(() => {
    const init = async () => {
      const apiResponse = await dcsgetActiveCommissionScaling();
      if (apiResponse.status===type.SUCCESS) {
        setScalings(apiResponse.response);
      }
    };
    init();
  }, []);

  useEffect(() => {
    const init = async () => {
            const variables = await dcsgetOrderManagedVariablesNew(1, false);
      setDelaerPermissions(variables[1]);
      
    };
    init();
  }, []);

  const handleJobTitle = (e: ChangeEvent<HTMLInputElement>, title: string): void => {
    let jobTitle = e.target.value;
    user.jobTitle = jobTitle;
    setReload(new Date().toLocaleString());
  };

  const handleStaffNumber = (e: ChangeEvent<HTMLInputElement>, staff: string): void => {
    let staffNumber = e.target.value;
    user.staffNumber = staffNumber;
    setReload(new Date().toLocaleString());
  };
  const handleScaling = (scalingid: number, id: number): void => {
    user.scaling = {
      id: scalingid
    };
    setReload(new Date().toLocaleString());
  };

  const handleShare = (e: ChangeEvent<HTMLInputElement>, shareValue: number): void => {
    let share = e.target.value
    user.share = parseInt(share);
    setReload(new Date().toLocaleString());
  };
  const saveUser = (edit: boolean) => {

    updateUser(user);
    setEdit(edit);
    setReload(new Date().toLocaleString());
  };
  const handleEnableCommission = (enableCommission: boolean, id: number): void => {
    user.enableCommission = enableCommission
    setReload(new Date().toLocaleString());
  };

  const handleEnableVariables = (enableVariabales: boolean, id: number): void => {
    user.enableVariabales = enableVariabales
    setReload(new Date().toLocaleString());
  };
  const handleEnableScaling = (enableScaling: boolean, id: number): void => {

    user.enableScaling = enableScaling;
    setReload(new Date().toLocaleString());
  };

  const handleEnableUserMgmt = (enableUserMgmt: boolean, id: number): void => {

    user.enableUserMgmt = enableUserMgmt
    setReload(new Date().toLocaleString());
  };

  const handleEnableApprovals = (enableApprovals: boolean, id: number): void => {

    user.enableApprovals = enableApprovals
    setReload(new Date().toLocaleString());
  };

  const handleCanViewAllOrders = (canViewAllOrders: boolean, id: number): void => {

    user.canViewAllOrders = canViewAllOrders
    setReload(new Date().toLocaleString());
  };
  const handleCanEditAllOrders = (canEditAllOrders: boolean, id: number): void => {

    user.canEditAllOrders = canEditAllOrders
    setReload(new Date().toLocaleString());
  };

  const handleCanViewOrderCommissions = (canViewOrderCommissions: boolean, id: number): void => {

    user.canViewOrderCommissions = canViewOrderCommissions
    setReload(new Date().toLocaleString());
  };

  const handleCanExportOrders = (canExportOrders: boolean, id: number): void => {
    user.canExportOrders = canExportOrders
    setReload(new Date().toLocaleString());
  };
  const handleCanExportCommissions = (canExportCommissions: boolean, id: number): void => {

    user.canExportCommissions = canExportCommissions
    setReload(new Date().toLocaleString());
  };

  const handleActivateClose = () => {
    setActivate(false);
   // window.location.reload();
  };
  const handleActivateOpen = () => {
    setActivate(true);
  
  };

  const isOrigin=(user:UserDetails,dealer:PorscheCenterDTO)=>{

    if(user.origin.id==dealer.id){
      return true;
    }
    
    if(user.origin.origin==dealer.origin){
      return false;
    }else{
      return true;
    }
   };
   const isChecked=(user:UserDetails,dealer:PorscheCenterDTO)=>{
    
    const exists =user.dealerPermissions.filter(de=>de.id==dealer.id);
    if(exists.length>0){
      return true;
    }else{
      return false;
    }
   };

   const handleDealerPermissions=(user:UserDetails,dealer:PorscheCenterDTO)=>{
    const exists =user.dealerPermissions.filter(de=>de.id==dealer.id);
    if(exists.length>0){
      user.dealerPermissions.map((de,i)=>{
        if(de.id===dealer.id){
          user.dealerPermissions.splice(i,1); 
        }
      })
   
    }else{
      user.dealerPermissions.push(dealer);
    }
   
    setReload(new Date().toLocaleString());
   
   };

   const activateUser=()=>{
    user.active=true;
    user.isNewUser=false;
    updateUser(user);
    setActivate(false);
    setReload(new Date().toLocaleString());
   };

 

  return (
    <>
      <PFlex>
        <PFlexItem style={{ width: "80%" }}>
          <Stack direction={"row"} spacing={1}>
            <img 
              src={require("../../../assets/images/User.png")}
              alt={"User"} 
              width="12" 
              height="12" 
              style={{ marginTop: "5px" }}
            />
            <PorscheFont className="dcs-heading">
              {user.ppnName}
            </PorscheFont>
          </Stack>
        </PFlexItem>
      </PFlex>

      {edit && (
        <>
          <PFlex className="mt12">
            <PFlexItem>
              <Stack direction={"row"} spacing={1} style={{alignItems: "center"}}>
                <PorscheFont className="tn-text-14-400">
                  {t("Dealer Permissions")}
                </PorscheFont>
                { dealersFromSameCity.map((dealer,i) => {
                  if(!isOrigin(user,dealer))
                    return (   
                      <>
                        <div
                          className={`${
                            isChecked(user, dealer)
                              ? "dealer-btn-selected"
                              : "dealer-btn"
                          }`}
                          onClick={() => handleDealerPermissions(user, dealer)}
                        >
                          {dealer.centerName}
                        </div>
                      </>
                    );
                  })
                }
                <PDivider
                  orientation={"vertical"}
                  color="neutral-contrast-medium"
                  style={{ height: "32px", color: "#C8CACB", margin: "0 16px" }}
                />
              </Stack>
            </PFlexItem>
            <PFlexItem>
              <Stack direction={"row"} spacing={1} style={{alignItems: "center"}}>
                <PorscheFont className="tn-text-14-400">
                  {t("Staff No.")}
                </PorscheFont>
                <div style={{ width: "80px" }}>
                  <PTextFieldWrapper id={`umiu-0`}>
                    <input
                      id={`iumiu-0`}
                      key={`kumiu-0`}
                      type="text"
                      defaultValue={user.staffNumber}
                      onChange={(e) => handleStaffNumber(e, user.staffNumber)}
                    />
                  </PTextFieldWrapper>
                </div>
                <PDivider
                  orientation={"vertical"}
                  color="neutral-contrast-medium"
                  style={{ height: "32px", color: "#C8CACB", margin: "0 16px" }}
                />
              </Stack>
            </PFlexItem>
            <PFlexItem>
              <Stack direction={"row"} spacing={1} style={{alignItems: "center"}}>
                <PorscheFont className="tn-text-14-400">
                  {t("Job Title")}
                </PorscheFont>
                <PTextFieldWrapper id={`umiu-0`}>
                  <input
                    id={`iumiu-0`}
                    key={`kumiu-0`}
                    type="text"
                    defaultValue={user.jobTitle}
                    onChange={(e) => handleJobTitle(e, user.jobTitle)}
                  />
                </PTextFieldWrapper>
                <PDivider
                  orientation={"vertical"}
                  color="neutral-contrast-medium"
                  style={{ height: "32px", color: "#C8CACB", margin: "0 16px" }}
                />
              </Stack>
            </PFlexItem>
            <PFlexItem>
              <Stack direction={"row"} spacing={1} style={{alignItems: "center"}}>
                <PorscheFont className="tn-text-14-400">
                  {t("Commission")} 
                </PorscheFont>
                <PSelectWrapper>
                  <select 
                    name="commissionScaling"
                    onChange={(e) => handleScaling(parseInt(e.target.value), user.id)}
                    disabled={edit ? false : true}
                  >
                    {scalings.map((scaling, i) => {
                      return (
                        <option 
                          value={scaling.id} 
                          selected={scaling.id === user.scaling?.id ? true : false}
                        >
                          {scaling.name} 
                        </option>
                      );
                    })}
                  </select>
                </PSelectWrapper>
                <PDivider
                  orientation={"vertical"}
                  color="neutral-contrast-medium"
                  style={{ height: "32px", color: "#C8CACB", margin: "0 16px" }}
                />
              </Stack>
            </PFlexItem>
            <PFlexItem>
              <Stack direction={"row"} spacing={1} style={{alignItems: "center"}}>
                <PorscheFont className="tn-text-14-400">
                  {t("Share")}
                </PorscheFont>
                <PTextFieldWrapper id={`umshare-0`}>
                  <input
                    type="text"
                    defaultValue={formatCurrencyWithNoUnit(user.share)}
                    onBlur={(e) => handleShare(e, user.share)}
                  />
                </PTextFieldWrapper>
              </Stack>
            </PFlexItem>
          </PFlex>
          

          {/** TODO RAina */}
          <PFlex className="mt18">
            <PFlexItem style={{ marginRight: "15px", position: "relative" }}>
              <div
                className="rights tn-title"
                onClick={() => setShowSendTo(!showSendTo)}
              >
                <PFlex>
                  <PFlexItem style={{ minWidth: "175px" }}>
                    <PorscheFont> {t("Admin Rights")}</PorscheFont>
                  </PFlexItem>
                  <PFlexItem
                    style={{
                      width: "15%",
                      marginTop: "-7px",
                      marginRight: "4px",
                    }}
                  >
                    <PIcon
                      name={showSendTo ? "arrow-head-up" : "arrow-head-down"}
                      className="mt8"
                    />
                  </PFlexItem>
                </PFlex>
              </div>
              {showSendTo && (
                <div
                  className="dropdown-adminRights"
                  style={{ marginTop: "2px" }}
                >
                  <ul>
                    <li>
                      <PCheckboxWrapper label={`${t("Variables")}`} hideLabel={false}>
                        <input
                          key={`idealer`}
                          type="checkbox"
                          checked={user.enableVariabales}
                          onClick={(e) => handleEnableVariables(!user.enableVariabales,user.id)}
                          disabled={edit ? false : true}
                        />
                      </PCheckboxWrapper>
                    </li>
                    <li>                    
                      <PCheckboxWrapper label={`${t('Scaling')}`} hideLabel={false}>
                        <input
                          key={`idealer`}
                          type="checkbox"
                          checked={user.enableScaling}
                          onClick={(e) => handleEnableScaling(!user.enableScaling,user.id)}
                          disabled={edit ? false : true}
                        />
                      </PCheckboxWrapper>
                    </li>
                    <li>
                      <PCheckboxWrapper label={`${t('User Mgmt.')}`} hideLabel={false}>
                        <input
                          key={`idealer`}
                          type="checkbox"
                          checked={user.enableUserMgmt}
                          onClick={(e) => handleEnableUserMgmt(!user.enableUserMgmt,user.id)}
                          disabled={edit ? false : true}
                        />
                      </PCheckboxWrapper>
                    </li>
                    <li> 
                      <PCheckboxWrapper label={`${t('Approvals')}`} hideLabel={false}>
                        <input
                          key={`idealer`}
                          type="checkbox"
                          checked={user.enableApprovals}
                          onClick={(e) => handleEnableApprovals(!user.enableApprovals,user.id)}
                          disabled={edit ? false : true}
                        />
                      </PCheckboxWrapper>
                    </li>
                  </ul>
                </div>
              )}
            </PFlexItem>
            <PFlexItem style={{ marginRight: "15px", position: "relative" }}>
              <div
                className="rights tn-title"
                onClick={() => setShowOrderRights(!showOrderRights)}
              >
                <PFlex>
                  <PFlexItem style={{ minWidth: "175px" }}>
                    <PorscheFont>{t("Order Rights")}</PorscheFont>
                  </PFlexItem>
                  <PFlexItem
                    style={{
                      width: "15%",
                      marginTop: "-7px",
                      marginRight: "4px",
                    }}
                  >
                    <PIcon
                      name={showOrderRights ? "arrow-head-up" : "arrow-head-down"}
                      className="mt8"
                    />
                  </PFlexItem>
                </PFlex>
              </div>
              {showOrderRights && (
                <div
                  className="dropdown-adminRights"
                  style={{ marginTop: "2px" }}
                >
                  <ul>
                    <li>
                      <PCheckboxWrapper label={`${t("View all")}`} hideLabel={false}>
                        <input
                          key={`idealer`}
                          type="checkbox"
                          checked={user.canViewAllOrders}
                          onClick={(e) => handleCanViewAllOrders(!user.canViewAllOrders, user.id)}
                        />
                      </PCheckboxWrapper>
                    </li>
                    <li>
                      <PCheckboxWrapper label={`${t("Edit all")}`} hideLabel={false}>
                        <input
                          key={`idealer`}
                          type="checkbox"
                          checked={user.canEditAllOrders}
                          onClick={(e) => handleCanEditAllOrders(!user.canEditAllOrders, user.id)}
                          disabled={edit ? false : true}
                        />
                      </PCheckboxWrapper>
                    </li>
                    <li>
                      <PCheckboxWrapper label={`${t("View all commissions")}`} hideLabel={false}>
                        <input
                          key={`idealer`}
                          type="checkbox"
                          checked={user.canViewOrderCommissions}
                          onClick={(e) => handleCanViewOrderCommissions(!user.canViewOrderCommissions, user.id)}
                          disabled={edit ? false : true}
                        />
                      </PCheckboxWrapper>
                    </li>
                  </ul>
                </div>
              )}
            </PFlexItem>
            <PFlexItem style={{ marginRight: "4px", position: "relative" }}>
              <div
                className="rights tn-title"
                onClick={() => setShowExportRights(!showExportRights)}
              >
                <PFlex>
                  <PFlexItem style={{ minWidth: "175px" }}>
                    <PorscheFont>{t("Export Rights")}</PorscheFont>
                  </PFlexItem>
                  <PFlexItem
                    style={{
                      width: "15%",
                      marginTop: "-7px",
                      marginRight: "4px",
                    }}
                  >
                    <PIcon name={showExportRights ? "arrow-head-up"  : "arrow-head-down"} className="mt8" />
                  </PFlexItem>
                </PFlex>
              </div>
              {showExportRights && (
                <div
                  className="dropdown-adminRights"
                  style={{ marginTop: "2px" }}
                >
                  <ul>
                    <li>
                      <PCheckboxWrapper label={`${t("Orders")}`} hideLabel={false}>
                        <input
                          key={`idealer`}
                          type="checkbox"
                          checked={user.canExportOrders}
                          onClick={(e) => handleCanExportOrders(!user.canExportOrders, user.id)}
                          disabled={edit ? false : true}
                        />
                      </PCheckboxWrapper>
                    </li>
                    <li>
                      <PCheckboxWrapper label={`${t("Commissions")}`} hideLabel={false}>
                        <input
                          key={`idealer`}
                          type="checkbox"
                          checked={user.canExportCommissions}
                          onClick={(e) => handleCanExportCommissions(!user.canExportCommissions,user.id)}
                          disabled={edit ? false : true}
                        />
                      </PCheckboxWrapper>
                    </li>
                  </ul>
                </div>
              )}
            </PFlexItem>
            <PFlex justifyContent={"flex-end"} style={{width: "100%"}}>
              <PFlexItem>
                <PButtonGroup>
                  <PButton 
                    icon={"save"} 
                    disabled={!userDetails.enableUserMgmt}
                    onClick={handleActivateOpen}
                  >
                    {t("Activate user")}
                  </PButton>
                </PButtonGroup>
              </PFlexItem>  
            </PFlex>
          </PFlex>
        </>
      )}

      <PDivider 
        color="neutral-contrast-high" 
        style={{ marginTop: "12px", marginBottom: "12px" }}
      />

      <PModal
        className="overview-modal"
        open={activate} 
        onClose={handleActivateClose} 
      >
        <PFlex>
          <PFlexItem style={{ marginRight: "4px", marginTop: "6px" }}>
            <PorscheFont className="dcs-heading">
              {t("Activate this user?")}
            </PorscheFont>
          </PFlexItem>
        </PFlex>
        <PFlex dir="rtl" style={{ marginTop: "14px" }}>
          <PFlexItem style={{ marginRight: "4px" }}>
            <PButtonGroup>
              <PButton 
                icon={"arrow-head-right"} 
                onClick={activateUser}
              >
                {t("Yes, activate")}
              </PButton>
              <PButton
                variant="tertiary"
                icon={"close"}
                onClick={handleActivateClose}
              >
                <PorscheFont>
                  {t("Cancel")}
                </PorscheFont>
              </PButton>
            </PButtonGroup>
          </PFlexItem>
        </PFlex>
      </PModal>
    </>
  );
}