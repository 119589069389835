
// import { PorscheFont } from '../../assets/styles/CustomStyles';
// import { TaskRequestDTO, TaskResponseDTO } from '../../interfaces/TasksAndNotifications';
// import { UserDetailsDTO } from '../../interfaces/User';
// import { formatDate } from '../../mock/helper';
// import { tasksResult } from '../../mock/tasksResponse';
// import {
//     deleteTasks, getTasksByOrderId, getUsers, putTasksData
// } from '../../services/data/Helpers';
// //import { Notifications } from './Notifications';
// import { SaveDialog } from '../order/tabs/upsale/SaveDialog';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  PDivider,
  PTabs, PTabsItem
} from '@porsche-design-system/components-react';

import { PorscheFont } from '../../../assets/styles/CustomStyles';
import { CustomerDelivery } from './CustomerDelivery';
import { OrderRegistration } from './OrderRegistration';

//import { TaskRow } from './TaskRow';

interface Props {
  // task: TaskResponseDTO;
  // closeDetails: () => void;
  handleCloseOrderRegister: () => void;
  orderId: number,
  tabIndex: number,
}

export const OrderRegistrationTabs = (props: Props): JSX.Element => {
  const { handleCloseOrderRegister, orderId, tabIndex } = props;
  const [toggle, setToggle] = useState<boolean>(false);
  const { t } = useTranslation();

  let loggedInUserId = 1;
  const sessionUser = sessionStorage.getItem("userDetails");
  if (sessionUser) {
    const user = JSON.parse(sessionUser);
    loggedInUserId = user.id;
  }
  const handleClose = () => {
    setToggle(!toggle)
  }

  return (
    <>
      <div className='tn-layout' >
        <div>
          <PorscheFont className="dcs-section-heading" >{t("Order processing")}</PorscheFont>
        </div><br></br>
        <div>
          <PTabs activeTabIndex={tabIndex}>
            <PTabsItem label={`${t("Order registration")}`}>
              <PDivider style={{ height: "1px", background: "rgba(0, 0, 0, 0.25)", marginTop: "-8px" }} />
              <OrderRegistration orderId={orderId} handleCloseOrderRegister={handleCloseOrderRegister}
              />
            </PTabsItem>
            <PTabsItem label={`${t("Customer delivery")}`}>
              <PDivider style={{ height: "1px", background: "rgba(0, 0, 0, 0.25)", marginTop: "-8px" }} />
              <CustomerDelivery orderId={orderId} handleCloseOrderRegister={handleCloseOrderRegister}
              />
            </PTabsItem>
          </PTabs>
        </div>
      </div>
    </>
  );
}

