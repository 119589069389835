import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Stack } from '@mui/system';
import {
  PCheckboxWrapper, PFlex, PFlexItem, PPopover, PText
} from '@porsche-design-system/components-react';

import { PorscheFont } from '../../../assets/styles/CustomStyles';
import { sortApprovalConfiguration } from '../../../helpers/sorter';
import { UserDetailsDTO } from '../../../interfaces/User';
import { UserDetails } from '../../../interfaces/UserDetails';
import {
  AccountingConfigurationDTO, ApprovalConfigurationStep, User
} from '../../../interfaces/adminApproval/ApprovalConfiguration';
import { type } from '../../../services/Constants';
import {
  dcsgetAccountingApprovalConfiguration,
  dcsgetUsers
} from '../../../services/data/Helpers';
import ScrollToTop from '../../common/ScrollToTop';

interface Props {
  edit: boolean;
  save: boolean;
  cancel: boolean;
  handleUsersPost: (approvalConfig: AccountingConfigurationDTO) => void;
  modified: () => void;
  enableSave: (value: boolean) => void;
  showHeader:() => void;
  removeHeader:()=> void;
}

export const Accounting = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const { edit, save, cancel, handleUsersPost, modified, enableSave,showHeader,removeHeader } = props;

  const [approvalConfig, setApprovalConfig] = useState<AccountingConfigurationDTO>({} as AccountingConfigurationDTO);
  const [reload, setReload] = useState("");
  const [activeUsers, setActiveUsers] = useState<Array<UserDetails>>([]);

  let userDetails = {} as UserDetailsDTO;
  const sessionUser = sessionStorage.getItem("userDetails");
  if (sessionUser) {
    userDetails = JSON.parse(sessionUser);
  }

  useEffect(() => {
    const init = async () => {
      const apiResponse = await dcsgetUsers()
      if(apiResponse.status===type.SUCCESS){
        setActiveUsers(apiResponse.response);
      }
    };

    init();
  }, []);

  //Add logged in user origin
  const approvalConfigurationData = () => {

    return getIntilaDataForCommissionApproval();

  }


  const getIntilaDataForCommissionApproval = () => {
    var commissionApprovalList: Array<ApprovalConfigurationStep> = [];
    const step5: ApprovalConfigurationStep = {
      isEnabled: false,
      approvalStep: "StartPayroll",
      displayLabel: "Start payroll",
      approvers: []
    };
    const step6: ApprovalConfigurationStep = {
      isEnabled: false,
      approvalStep: "CommissionAccounting",
      displayLabel: "Commission Accounting",
      approvers: []
    };

    commissionApprovalList.push(step5);
    commissionApprovalList.push(step6);

    var config: AccountingConfigurationDTO = {
      accountingApprovals: commissionApprovalList,
      origin: userDetails.origin
    };
    setApprovalConfig(config);
    return config;
  }
  useEffect(() => {
    const init = async () => {
      const apiResponse = await dcsgetAccountingApprovalConfiguration()
      if(apiResponse.status===type.SUCCESS){
        const approvalConfig = apiResponse.response as AccountingConfigurationDTO;
        if (approvalConfig.accountingApprovals.length>0) {
          approvalConfig.accountingApprovals = sortApprovalConfiguration(approvalConfig.accountingApprovals);
          setApprovalConfig(approvalConfig);
        }else{
          setApprovalConfig(approvalConfigurationData())
        }
      }
       else {
        setApprovalConfig(approvalConfigurationData())
      }
      setReload(new Date().toLocaleString());
    };
    init();
  }, []);



  const isChecked = (orderStep: ApprovalConfigurationStep, user: User) => {
    const exists = orderStep?.approvers?.filter(de => de.id == user.id);
    if (exists != undefined && exists.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const getCommissionIcon = (step: number) => {
    let ret = <></>;
    switch (step) {
      case 0:
        ret = (<img src={require("../../../assets/images/approvals/Startpayroll.png")} alt="" />);
        break;
      case 1:
        ret = (<img src={require("../../../assets/images/approvals/Commissionaccounting.png")} alt="" />);
        break;
      case 2:
        ret = (<img src={require("../../../assets/images/approvals/Export.png")} alt="" />);
        break;
      case 3:
        ret = (<img src={require("../../../assets/images/approvals/Discount.png")} alt="" />);
        break;
      case 4:
        ret = (<img src={require("../../../assets/images/approvals/Startpayroll.png")} alt="" />);
        break;
      case 5:
        ret = (<img src={require("../../../assets/images/approvals/Commissionaccounting.png")} alt="" />);
        break;
    }
    return ret;
  }
  const getCommissionActivateMsg = [
    "",
    "",
    "2nd commission check is not activated. To enable this as an approval step go to “Edit entries”.",
    "Fixed commission is not activated. To enable this as an approval step go to “Edit entries”.",
    "",
    ""
  ]
  const getCommissionPopover = [
    "Define monthly payroll period and start accounting",
    "Approve order lists for accounting"
  ];
 
  let commissionError=false;
  let payrollError=false;
  const commissinApprovalUserAssignment = (orderStep: ApprovalConfigurationStep, user: User, index: number) => {
    const dom = document.getElementById(`commissionErr${index}`);
    if (dom) {
      dom.innerHTML = "";
    }

    approvalConfig.accountingApprovals?.map((order,j) => {
      if (orderStep.approvalStep===order.approvalStep ) {
        const exists = order?.approvers?.filter(de => de.id == user.id);
        if (exists != undefined && exists.length > 0) {
          order?.approvers?.map((de, i) => {
            if (de.id === user.id) {
              order?.approvers?.splice(i, 1);
            }
          })
        } else{
          order?.approvers?.push(user);
        };
        if (order.approvers !=undefined && order.approvalStep === "StartPayroll" && order.approvers?.length < 2 && dom) {
          dom.innerHTML = t("At least 2 users must be selected.");
          payrollError=true;
        } else if(order.approvers !=undefined && order.approvalStep === "StartPayroll"&& order.approvers?.length > 5 && dom){
          dom.innerHTML = t("At most 5 user can be selected.");
          payrollError=true;
        }

        if (order.approvers !=undefined && order.approvalStep === "CommissionAccounting" && order.approvers?.length !== 2 && dom) {
          dom.innerHTML = t("Two users must be selected.");
          commissionError=true;
        }

      }
    });

    setApprovalConfig(approvalConfig);
    //setReload(new Date().toLocaleString());
    modified();
    
    if (!isValidPost(approvalConfig)) {
      enableSave(false);
    } else {
      handleUsersPost(approvalConfig);
    }
    setReload(new Date().toLocaleString());
  };

  const isValidPost = (approvalConfig: AccountingConfigurationDTO) => {
    let payrolls = approvalConfig.accountingApprovals?.filter(x => x.approvalStep === "StartPayroll");
    if(payrolls.length > 0) {
      if (payrolls[0].approvers !=undefined && payrolls[0].approvers.length < 2 ) {
        return false;
      } else if(payrolls[0].approvers !=undefined && payrolls[0].approvers?.length > 5){
        return false;
      }
    }

    let commrolls = approvalConfig.accountingApprovals?.filter(x => x.approvalStep === "CommissionAccounting");
    if(commrolls.length > 0) {
      if (commrolls[0].approvers !=undefined && commrolls[0].approvers?.length !== 2) {
        return false;
      }
    }

    return true;
  }

  return (
    <>
      {/** Commission approval */}
      <div className="container-variable" onMouseOver={props.removeHeader} onMouseOut={props.showHeader}>
        <ScrollToTop />
        <PText weight="bold" size="medium">
          {t('Accounting')}
        </PText>
        <div className="container-element">
          {/** View mode */}

          {!edit && (
            approvalConfig.accountingApprovals?.map((order, i) => {
              return (
                <>
                  <div className={`${i > 0 ? "mt24" : ""}`}>
                    <PFlex direction={"column"} >
                      <PFlexItem>
                        <Stack direction={"row"} spacing={1}>
                          {getCommissionIcon(i)}
                          <PorscheFont className="dcs-title">{t(order.displayLabel)}</PorscheFont>
                        </Stack>
                      </PFlexItem>


                      {(order.approvers && order.approvers?.length == 0) &&
                        (
                          <PFlexItem className="mt8">
                            <PFlex>
                              {/** Loop selected users here under view mode*/}
                              <PFlexItem style={{ width: "20%", marginRight: "4px" }}>
                                <div className="read dcs-title" style={{ textAlign: "left", paddingLeft: "4px" }} >{""}</div>
                              </PFlexItem>
                            </PFlex>
                          </PFlexItem>
                        )
                      }
                      {(order.approvers && order.approvers?.length > 0) &&
                        (
                          <PFlexItem className="mt8">
                            <PFlex>
                              {
                                order.approvers.map((user, i) => {
                                  return (<PFlexItem style={{ width: "20%", marginRight: "4px" }}>
                                    <div className="read dcs-title" style={{ textAlign: "left", paddingLeft: "4px" }} >{user.ppnName}</div>
                                  </PFlexItem>)
                                })
                              }
                            </PFlex>
                          </PFlexItem>
                        )}
                    </PFlex>
                  </div>
                </>);
            })
          )}

          {/** Edit Mode */}
          {edit && (
            approvalConfig.accountingApprovals?.map((order, i) => {
              return (
                <>
                  <div className={`${i > 0 ? "mt24" : ""}`}>
                    <PFlex direction={"column"} >
                      <PFlexItem>
                        <Stack direction={"row"} spacing={1}>

                          {getCommissionIcon(i)}
                          <PorscheFont className="dcs-title">{t(order.displayLabel)}</PorscheFont>
                          <PPopover direction="top">
                            <PorscheFont className="dcs-popover">
                              {t(getCommissionPopover[i])}
                            </PorscheFont>
                          </PPopover>

                        </Stack>
                      </PFlexItem>


                      {(activeUsers != undefined) && (
                        <PFlexItem className="mt8">
                          <div className='user-dropdown'>
                            <ul>
                              {
                                activeUsers.map((user, j) => {
                                  return (
                                    <>
                                      <li key={`${j}`} className="mt8">
                                        <PCheckboxWrapper label={user.ppnName} hideLabel={false}>
                                          <input
                                            key={`idealer${j}`}
                                            type="checkbox"
                                            checked={isChecked(order, user) ? true : false}
                                            onClick={(e) => commissinApprovalUserAssignment(order, user, i)}
                                            disabled={edit ? false : true}

                                          />
                                        </PCheckboxWrapper>
                                      </li>
                                    </>
                                  );
                                })
                              }
                            </ul>
                          </div>
                        </PFlexItem>
                      )}
                    </PFlex>
                  </div>
                  <PorscheFont>
                    <span id={`commissionErr${i}`} style={{ color: "red" }}></span>
                  </PorscheFont>
                </>)
            })
          )}
        </div>
      </div>
    </>
  )
}