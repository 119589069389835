import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Stack } from '@mui/material';
import {
  PButton, PDivider, PFlex, PFlexItem,
  PSelectWrapper, PTextFieldWrapper
} from '@porsche-design-system/components-react';

import { PorscheFont } from '../../../../assets/styles/CustomStyles';
import {
  CategoryCommissionDTO, VehicleCommissionDTO
} from '../../../../interfaces/commission';
import {
  adminCurrencyFormatter,
  formatCurrencyToDecimal, formatCurrencyWithNoUnit, onBlurCurrencyFormatter, onBlurPercentageFormatter, validateCurrency, validatePercentage, validateStrandingDays
} from '../../../../mock/helper';
import { CustomDelete } from '../../../common/CustomDelete';

interface Props {
  handleCommissionPost: (i : number, categoryCommission: CategoryCommissionDTO) => void;
  commissions: CategoryCommissionDTO;
  cindex : number;
  edit: boolean;
  title: string;
  setErrorState :React.Dispatch<React.SetStateAction<Record<string, boolean> | undefined>>
  errorState:Record<string, boolean>|undefined
}

export const TiresAndParts = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const { commissions, handleCommissionPost ,cindex, edit, title, errorState, setErrorState } = props;

  const [hideRow , setHideRow] = useState<boolean>(false);
  const [reload, setReload] = useState("");
  const [rangeError, setRangeError] = useState<boolean>(false);
  const [commissionData, setCommissionData] = useState<CategoryCommissionDTO>(commissions);
  const [vehicleCommErrorState, setVehicleCommErrorState] = useState<Record<string,boolean>>()

  const variable:string ="Discount"


  const errorStateHandler=(wrapperId:string , state:boolean)=>{
    setErrorState((prev)=>{
      return {...prev, [wrapperId]:state}
    })
  }
  const vehicleCommErrorStateHandler = (wrapperId:string , state:boolean)=>{
    setVehicleCommErrorState((prev)=>{
      return {...prev, [wrapperId]:state}
    })
  }

  const isAddButtonActive = ()=>{
    const lastVehicleCommValue =  commissionData.vehicleCommissions[commissionData.vehicleCommissions.length-1].toValue
    if(variable ==="Discount"){
       if(lastVehicleCommValue < 100 && !(vehicleCommErrorState && Object.values(vehicleCommErrorState).includes(true)) ){
         return true
       }
       else{
         return false
       }
    }
    else{
     if(lastVehicleCommValue < 9999 && !(vehicleCommErrorState && Object.values(vehicleCommErrorState).includes(true)) ){
       return true
     }
     else{
       return false
     }
    }
        
   }

  const specialCommissionHandler = (value: string, elementDomId:string, refrenceName:string|undefined )=>{
  
    if(refrenceName=== "Euro"){
      if(validateCurrency(value)){
        errorStateHandler(elementDomId, false)
        return(adminCurrencyFormatter(value)) 
      }
      else{
        errorStateHandler(elementDomId, true)
        return 0
      }
  }
  else{
   if(validatePercentage(value)){
     errorStateHandler(elementDomId, false)
     return(adminCurrencyFormatter(value)) 
    }
    else{
      errorStateHandler(elementDomId, true)
      return 0
    }
  }
  }

  const handleValue = (value: string, type : string, i: number)=> {
    switch(type){
    //   case "min": commissionData.minCommissionValue = formatCurrencyToDecimal(value);
    //   break;
    // case "max": commissionData.maxCommissionValue =  formatCurrencyToDecimal(value);
    //   break;
    // case "no": commissionData.noProfitCommissionValue =  formatCurrencyToDecimal(value);
    //   break;
    case "val1": 
    const val1WrapperDom = document.getElementById(`${commissions.commissionScalingCategoryType+`vcvarpv1${i}`}`);
    let val1ElementDomId= val1WrapperDom?.id as string
    commissionData.vehicleCommissions[i].payoutValue1 =specialCommissionHandler(value, val1ElementDomId,commissionData.vehicleCommissions[i].payoutRefence1)


    // commissionData.vehicleCommissions[i].payoutValue1 = formatCurrencyToDecimal(value);
      break; 
    case "val2":
      const val2WrapperDom = document.getElementById(`${commissions.commissionScalingCategoryType+`vcvarpv2${i}`}`);
      let val2ElementDomId= val2WrapperDom?.id as string
      commissionData.vehicleCommissions[i].payoutValue2 =specialCommissionHandler(value, val2ElementDomId,commissionData.vehicleCommissions[i].payoutRefence1)
    
    // commissionData.vehicleCommissions[i].payoutValue2 = formatCurrencyToDecimal(value);
      break;
    }
    setCommissionData(commissionData);
    handleCommissionPost(cindex, commissionData);
    //setReload(new Date().toLocaleString());
  }
  
  const handleClickPayOut = () => {
    commissionData.payOut2 = !commissionData.payOut2;
    setCommissionData(commissionData);
    handleCommissionPost(cindex, commissionData);

    //setPayOut(!showPayOut);
  }
  const handleDeleteRow = (index: number) => {
    let vc = commissionData.vehicleCommissions;
    
    if(vc && vc.length-1 === index)
    {
      vc.splice(index , 1);
      if (commissionData.variable==='Discount'){

      if(vc[vc.length-1].toValue<100 && hideRow === true){
        setHideRow(false);

      }
    } 
    // if (commissionData.variable==='StandingDays'){

    //   if(vc[vc.length-1].toValue<9999 && hideRow === true){
    //     setHideRow(false);

    //   }
    // } 
      commissionData.vehicleCommissions= vc;
      setCommissionData(commissionData);
      handleCommissionPost(cindex, commissionData);
      setReload(new Date().toLocaleString());
    }

  }
  // const handleClickTo = (index: number, value: string,) => {

  //   if (commissionData.variable==='Discount')
  //       {
  //         if( formatCurrencyToDecimal(value) >= 100){
  //         setHideRow(true);
  //         }
  //         else{
  //         setHideRow(false);
  //         }
  //       }
       
  //   if (commissionData.variable==='StandingDays' && formatCurrencyToDecimal(value) >= 9999)
  //      {
  //       setHideRow(true);
  //      }
      
  //   if (commissionData.vehicleCommissions[index].fromValue > formatCurrencyToDecimal(value)) {
  //     setRangeError(true);
  //   }
  //   else {
  //     setRangeError(false);
  //     commissionData.vehicleCommissions[index].toValue = formatCurrencyToDecimal(value);
  //     setCommissionData(commissionData);
  //     handleCommissionPost(cindex, commissionData);
  //     setReload(new Date().toLocaleString());
  //   }
  // }
  const handleClickTo = (index: number, VehicleCommValue: string, apiResponse?:boolean) => {
    const value = apiResponse ? parseFloat(VehicleCommValue) :adminCurrencyFormatter(VehicleCommValue);
    const inputValue = apiResponse ? variable ==="Discount" ?parseFloat(VehicleCommValue).toLocaleString('de-DE'):value+'':VehicleCommValue
    const vehicleCommInputWrapperDom = document.getElementById(`${commissions.commissionScalingCategoryType+`vcvar${index}`}`);
      let vehElementDomId= vehicleCommInputWrapperDom?.id as string
  // if (commissionData.variable === "Discount") {
  //   if (!isNaN(value) && value > 0 && value <= 100) {
  //     setHideRow(false);
  //     setRangeError(false);
  //     setShowSaveButton(true);
  //   } else {
  //     setHideRow(true);
  //     setRangeError(true);
  //     setShowSaveButton(false);
  //   }
  // } else if (commissionData.variable === "StandingDays") {
  //   if (!isNaN(value) && value > 0 && value <= 9999) {
  //     setHideRow(false);
  //     setRangeError(false);
  //     setShowSaveButton(true);
  //   } else {
  //     setHideRow(true);
  //     setRangeError(true);
  //     setShowSaveButton(false);
  //   }
  // }

  if (
    // !isNaN(value) &&
    (commissionData.variable === "StandingDays"
      ? validateStrandingDays(inputValue)
      : validatePercentage(inputValue)) &&
    commissionData.vehicleCommissions[index].fromValue <= value
     && ( commissionData.vehicleCommissions[index+1]?.fromValue ? commissionData.vehicleCommissions[index+1]?.fromValue > value ? true: false :true)
  ) {
    setRangeError(false);
    // setShowSaveButton(true);
    errorStateHandler(vehElementDomId, false)
    vehicleCommErrorStateHandler(vehElementDomId, false)
    commissionData.vehicleCommissions[index].toValue =
      formatCurrencyToDecimal(value.toString());
    setCommissionData(commissionData);
    handleCommissionPost(cindex, commissionData);
    setReload(new Date().toLocaleString());
  } else {
    setRangeError(true);
    setHideRow(true);
    errorStateHandler(vehElementDomId, true)
    vehicleCommErrorStateHandler(vehElementDomId, true)
    // setShowSaveButton(false);
  }
};
  const handleAddRow = () => {
    let vc = commissionData.vehicleCommissions;
    let last = vc[vc.length - 1];
    switch (commissionData.variable) {
      case "Discount":
        if (last.toValue < 100) {
          let next = {
            fromValue: last.toValue + 0.01,
            toValue: 0,
            payoutValue1: 0,
            payoutRefence1: 'SellingPrice',
            id: 0,
            payoutValue2: 0,
            payoutRefence2: 'SellingPrice'
          } as VehicleCommissionDTO;

          vc.push(next);
          commissionData.vehicleCommissions = vc;
          setCommissionData(commissionData);
          handleCommissionPost(cindex, commissionData);
          setHideRow(false);
          setReload(new Date().toLocaleString());
        } break;
      case "StandingDays":
        if (last.toValue < 9999) {
          let next = {
            fromValue: last.toValue + 0,
            toValue: 0,
            payoutValue1: 0,
            payoutRefence1: 'SellingPrice',
            id: 0,
            payoutValue2: 0,
            payoutRefence2: 'SellingPrice'
          } as VehicleCommissionDTO;

          vc.push(next);
          commissionData.vehicleCommissions = vc;
          setCommissionData(commissionData);
          handleCommissionPost(cindex, commissionData);
          setHideRow(false);
          setReload(new Date().toLocaleString());
        }break;
    }
  }
   const handleReference = (value: string, type: string, i: number) => {
    switch (type) {
      // case "min": commissionData.minCommissionReference = value;
      //   break;
      // case "max": commissionData.maxCommissionReference = value;
      //   break;
      // case "no": commissionData.noProfitCommissionReference = value;
      //   break;
      case "ref1": 
      const refWrapperDom = document.getElementById(`${commissions.commissionScalingCategoryType+'vcvarpv1'+i}`);
      const refInputDom = document.getElementById(`${commissions.commissionScalingCategoryType+'vcvarpv1-value'+i}`) as HTMLInputElement;
      let refWrapperDomId = refWrapperDom?.id as string
      let refInputvalue = refInputDom && refInputDom?.value

      commissionData.vehicleCommissions[i].payoutRefence1 = value;
      commissionData.vehicleCommissions[i].payoutValue1=specialCommissionHandler(refInputvalue, refWrapperDomId,value)

      
      
      // commissionData.vehicleCommissions[i].payoutRefence1 = value;
        break;
      case "ref2":
        const ref2WrapperDom = document.getElementById(`${commissions.commissionScalingCategoryType+'vcvarpv2'+i}`);
        const ref2InputDom = document.getElementById(`${commissions.commissionScalingCategoryType+'vcvarpv2-value'+i}`) as HTMLInputElement;
        let ref2WrapperDomId = ref2WrapperDom?.id as string
        let ref2Inputvalue = ref2InputDom && ref2InputDom?.value
  
        commissionData.vehicleCommissions[i].payoutRefence2 = value;
        commissionData.vehicleCommissions[i].payoutValue2=specialCommissionHandler(ref2Inputvalue, ref2WrapperDomId,value)
    
      
      // commissionData.vehicleCommissions[i].payoutRefence2 = value;
        break;
    };
    setCommissionData(commissionData);
    handleCommissionPost(cindex, commissionData);
    setReload(new Date().toLocaleString());

  }
  const handleVariable = (value: string) => {
    commissionData.variable = value;
    setCommissionData(commissionData);
    handleCommissionPost(cindex, commissionData);
    setReload(new Date().toLocaleString());
  }
 
  return (
    <>
      <div>
        <span className="table-column-header">
        <PorscheFont>{t(title)}</PorscheFont></span>
        <PDivider color="neutral-contrast-high" style={{width:"49%", marginTop: "10px"}}></PDivider><br></br>
        {/* <PGrid direction="row">
          <PGridItem size={3}>
            <PSelectWrapper label="Variable" >
              <select name="some-name" disabled={edit ? false : true} onChange={(e) => handleVariable(e.target.value)} required>
                <option value="Select a value"  selected={commissionData.variable === "Select a value" ? true : false}>Select a value</option>
                <option value="Discount" selected={commissionData.variable === "Discount" ? true : false}>{t("Discount")}</option>
            
              </select>
            </PSelectWrapper>
          </PGridItem>
          <PGridItem size={4}>
            {!commissionData.payOut2 && (
              <PButton variant="tertiary" icon="add" disabled={edit ? false : true} style={{ paddingTop: "26px" }} onClick={handleClickPayOut}>{t("Add 2nd Payout")}</PButton>
            )}
            {commissionData.payOut2 && (
              <PButton variant="tertiary" icon="close" disabled={edit ? false : true}  style={{ paddingTop: "26px" }} onClick={handleClickPayOut}>Remove 2nd payout</PButton>
            )}
          </PGridItem>

        </PGrid><br></br> */}
        <div>
          <Stack direction='row' >
          <div>
          <PSelectWrapper style={{width:"160px"}} label={`${t("Variable")}`} >
              <select name="some-name" disabled={edit ? false : true} onChange={(e) => handleVariable(e.target.value)} required>
                <option value="Select a value"  selected={commissionData.variable === "Select a value" ? true : false}>{t("Select a value")}</option>
                <option value="Discount" selected={commissionData.variable === "Discount" ? true : false}>{t("Discount")}</option>
              </select>
           </PSelectWrapper>
          </div>
          <div style={{paddingLeft:"72px"}}>
          {!commissionData.payOut2 && (
              <PButton variant="tertiary" icon="add" disabled={edit ? false : true} style={{ paddingTop: "28px",marginLeft:"-30%" }} onClick={handleClickPayOut}>{t("Add 2nd Payout")}</PButton>
            )}
            {commissionData.payOut2 && (
              <PButton variant="tertiary" icon="close" disabled={edit ? false : true}  style={{ paddingTop: "26px" }} onClick={handleClickPayOut}>{t("Remove 2nd payout")}</PButton>
            )}
          </div>
        </Stack>
        </div>
        <br></br>
        <PFlex>
          <PFlexItem style={{ width: '20%' }}>
            <PorscheFont>{t('From')} <span style={{ color: "#D5001B" }}>*</span></PorscheFont>

          </PFlexItem>
          <PFlexItem style={{ width: '20%' }}>
            <PorscheFont>{t("To")} <span style={{ color: "#D5001B" }}>*</span></PorscheFont>

          </PFlexItem>
          <PFlexItem style={{ width: '20%' }}>
            <PorscheFont>{t('Value')}  <span style={{ color: "#D5001B" }}>*</span></PorscheFont>

          </PFlexItem>
          <PFlexItem style={{ width: '28%' }}>
            <PorscheFont>{t('Reference')} <span style={{ color: "#D5001B" }}>*</span></PorscheFont>
          </PFlexItem>
          {!commissionData.payOut2 && (
            <PFlexItem style={{ width: '20%' }}></PFlexItem>
          )}
          {commissionData.payOut2 ? (
            <>
              <PFlexItem style={{ width: '20%' }}>
                <PorscheFont>{t('Value')} <span style={{ color: "#D5001B" }}>*</span></PorscheFont>
              </PFlexItem>
              <PFlexItem style={{ width: '28%' }}>
                <PorscheFont>{t('Reference')} <span style={{ color: "#D5001B" }}>*</span></PorscheFont>
              </PFlexItem>
              <PFlexItem style={{ width: '20%' }}></PFlexItem>
            </>
          ) : (
            // Creates the skeleton of the Pflex
            <>
              <PFlexItem style={{ width: '20%' }}> </PFlexItem>             
              <PFlexItem style={{ width: '28%' }}> </PFlexItem>         
              <PFlexItem style={{ width: '20%' }}></PFlexItem>
            </>
          )}
        </PFlex>
        {commissionData.vehicleCommissions.map((x, i) => {
          return (
            <>
              <PFlex>
                <PFlexItem style={{ width: "20%" }}>
                  <PTextFieldWrapper
                    unit={commissionData.variable === "Discount" ? "%" : ""}
                    unitPosition="suffix"
                    style={{ marginRight: "16px" }}
                  >
                    <input
                      type="text"
                      aria-autocomplete="none"
                      autoComplete="off"
                      name="some-name"
                      disabled={edit ? false : true}
                      value={formatCurrencyWithNoUnit(x.fromValue) }
                      required
                      readOnly
                    />
                  </PTextFieldWrapper>
                </PFlexItem>
                <PFlexItem style={{ width: "20%" }}>
                  <PTextFieldWrapper
                    unit={commissionData.variable === "Discount" ? "%" : ""}
                    unitPosition="suffix"
                    style={{ marginRight: "16px" }}
                    id={`${commissions.commissionScalingCategoryType+`vcvar${i}`}`}
                    state={errorState && errorState[`${commissions.commissionScalingCategoryType+`vcvar${i}`}`]===true? 'error':'none'}

                  >
                    <input
                      type="text"
                      aria-autocomplete="none"
                      autoComplete="off"
                      name={`tovalue${i}`}
                      disabled={edit ? false : true}
                      defaultValue={x.toValue}
                      onChange={(e) => handleClickTo(i, e.target.value)}
                      onBlur={(e)=>{
                        variable === "Discount"  &&    onBlurPercentageFormatter(e)
                        }}
                      required
                    />
                  </PTextFieldWrapper>
                </PFlexItem>
                <PFlexItem style={{ width: "20%" }}>
                  <PTextFieldWrapper
                    unit={x.payoutRefence1 === "Euro" ? "€" : "%"}
                    unitPosition="suffix"
                    style={{ marginRight: "16px" }}
                    id={`${commissions.commissionScalingCategoryType+'vcvarpv1'+i}`}
                    state={errorState && errorState[`${commissions.commissionScalingCategoryType+'vcvarpv1'+i}`]===true? 'error':'none'}

                  >
                    <input
                      type="text"
                      aria-autocomplete="none"
                      autoComplete="off"
                      id={`${commissions.commissionScalingCategoryType+'vcvarpv1-value'+i}`}

                      name={`payoutValue1${i}`}
                      disabled={edit ? false : true}
                      defaultValue={x.payoutValue1}
                      onChange={(e) => handleValue(e.target.value, "val1", i)}
                      onBlur={(e)=>{
                        if(commissionData.vehicleCommissions[i].payoutRefence1 === "Euro"){
                          onBlurCurrencyFormatter(e)
                        }
                        else{
                          onBlurPercentageFormatter(e)
                        }
                      }}
                      required
                    />
                  </PTextFieldWrapper>
                </PFlexItem>
                <PFlexItem style={{ width: "28%" }}>
                  <PSelectWrapper>
                    <select
                      name="some-name"
                      disabled={edit ? false : true}
                      required
                      onChange={(e) =>
                        handleReference(e.target.value, "ref1", i)
                      }
                    >
                      <option
                        value="Euro"
                        selected={x.payoutRefence1 === "Euro" ? true : false}
                      >
                        {t("Euro")}
                      </option>
                      <option
                        value="SellingPrice"
                        selected={
                          x.payoutRefence1 === "SellingPrice" ? true : false
                        }
                      >
                        {t("Selling price")}
                      </option>
                      <option
                        value="CustomerShare"
                        selected={
                          x.payoutRefence1 === "CustomerShare" ? true : false
                        }
                      >
                        {t("Customer share")}
                      </option>
                    </select>
                  </PSelectWrapper>
                </PFlexItem>
                {!commissionData.payOut2 && (
                  <PFlexItem
                    style={{
                      width: "20%",
                      paddingLeft: "20px",
                      marginTop: "8px",
                    }}
                  >
                    {commissionData.vehicleCommissions &&
                      commissionData.vehicleCommissions.length - 1 === i &&
                      i !== 0 && (
                        <span>
                          <CustomDelete
                            partId={i}
                            message={t("Delete this row?")}
                            handleDelete={(partId) => handleDeleteRow(partId)}
                            disabled={edit ? false : true}
                          />
                          {/* <PIcon name="delete" aria={{ 'aria-label': 'Delete icon' }}  onClick={() => handleDeleteRow(i)} /> */}
                        </span>
                      )}
                  </PFlexItem>
                )}
                &nbsp;&nbsp;&nbsp;
                {commissionData.payOut2 ? (
                  <>
                    <PFlexItem style={{ width: "20%" }}>
                      <PTextFieldWrapper
                        unit={x.payoutRefence2 === "Euro" ? "€" : "%"}
                        unitPosition="suffix"
                        style={{ marginRight: "16px" }}
                        id={`${commissions.commissionScalingCategoryType+'vcvarpv2'+i}`}
                        state={errorState && errorState[`${commissions.commissionScalingCategoryType+'vcvarpv2'+i}`]===true? 'error':'none'}
                      >
                        <input
                          type="text"
                          aria-autocomplete="none"
                          autoComplete="off"
                          id={`${commissions.commissionScalingCategoryType+'vcvarpv2-value'+i}`}
                          name={`payoutValue2${i}`}
                          disabled={edit ? false : true}
                          defaultValue={x.payoutValue2}
                          onChange={(e) =>
                            handleValue(e.target.value, "val2", i)
                          }
                          onBlur={(e)=>{
                            if(commissionData.vehicleCommissions[i].payoutRefence1 === "Euro"){
                              onBlurCurrencyFormatter(e)
                            }
                            else{
                              onBlurPercentageFormatter(e)
                            }
                          }}
                          required
                        />
                      </PTextFieldWrapper>
                    </PFlexItem>
                    <PFlexItem style={{ width: "28%" }}>
                      <PSelectWrapper>
                        <select
                          name="some-name"
                          disabled={edit ? false : true}
                          required
                          onChange={(e) =>
                            handleReference(e.target.value, "ref2", i)
                          }
                        >
                          <option
                            value="Euro"
                            selected={
                              x.payoutRefence2 === "Euro" ? true : false
                            }
                          >
                            {t("Euro")}
                          </option>
                          <option
                            value="SellingPrice"
                            selected={
                              x.payoutRefence2 === "SellingPrice" ? true : false
                            }
                          >
                            {t("Selling price")}
                          </option>
                          <option
                            value="CustomerShare"
                            selected={
                              x.payoutRefence2 === "CustomerShare"
                                ? true
                                : false
                            }
                          >
                            {t("Customer share")}
                          </option>
                        </select>
                      </PSelectWrapper>
                    </PFlexItem>
                    <PFlexItem
                      style={{
                        width: "20%",
                        paddingLeft: "20px",
                        marginTop: "8px",
                      }}
                    >
                      {commissionData.vehicleCommissions &&
                        commissionData.vehicleCommissions.length - 1 === i &&
                        i !== 0 && (
                          <span>
                            <CustomDelete
                              partId={i}
                              message={t("Delete this row?")}
                              handleDelete={(partId) => handleDeleteRow(partId)}
                              disabled={edit ? false : true}
                            />
                            {/* <PIcon name="delete" aria={{ 'aria-label': 'Delete icon' }} onClick={() => handleDeleteRow(i)}/> */}
                          </span>
                        )}
                    </PFlexItem>
                  </>
                ) : (
                  // Creates the skeleton of the flex
                  <>
                    <PFlexItem style={{ width: "20%" }}></PFlexItem>
                    <PFlexItem style={{ width: "28%" }}> </PFlexItem>
                    <PFlexItem style={{ width: "20%" }}> </PFlexItem>
                  </>
                )}
              </PFlex>
              <br></br>
            </>
          );
        })}
        <br></br>
        {rangeError && (
          <span style={{ color: "#D5001B" }}>
           <PorscheFont>{t("Please enter a valid value and make sure vehicle commission rules are defined for 0,00% - 100,00% discount or 0 - 9.999 standing days.")}</PorscheFont>
          </span>
        )}
        {/* {!hideRow && (
        <PButton variant="secondary" icon="add" disabled={edit ? false : true} onClick={handleAddRow}>{t("Add rule")}</PButton>
        )} */}

         {isAddButtonActive() && (
          <PButton
            variant="secondary"
            icon="add"
            disabled={edit ? false : true}
            onClick={handleAddRow}
          >
            {t("Add rule")}
          </PButton>
         )}
      </div>
    </>
  );
}