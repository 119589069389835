import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Alert, Tab, Tabs } from "@mui/material";
import {
  PButton,
  PFlex,
  PFlexItem,
} from "@porsche-design-system/components-react";

import { ReactComponent as ManualCommissionIcon } from "../../assets/images/ManualCommissionIcon.svg";
import { PMainHeader, PorscheFont } from "../../assets/styles/CustomStyles";
import { NavSideBar } from "../../components/NavSideBar";
import { Accounting } from "../../components/commissions/Accounting";
import { Approval } from "../../components/commissions/Approval";
import { History } from "../../components/commissions/History";
import { Recalculation } from "../../components/commissions/Recalculation";
import { isValidUserForDispatchOrderProcessing } from "../../helpers/sorter";
import { UserDetailsDTO } from "../../interfaces/User";
import { ManualCommissionUserObject } from "../../interfaces/commission";

interface HeaderTabPanels {
  id: number;
  name: string;
  enabled: boolean;
  className: string;
}

interface Props {
}
export const AccountingPage = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  let userDetails = {} as UserDetailsDTO;
  const sessionUser = sessionStorage.getItem("userDetails");
  if (sessionUser) {
    userDetails = JSON.parse(sessionUser);
  }
  let isDiableDispatchOrderProcessingButton = isValidUserForDispatchOrderProcessing(userDetails);
  const [tabPanels, setTabPanels] = useState<Array<HeaderTabPanels>>([]);
  const [panelIndex, setPanelIndex] = useState<number>(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [modeChange, setChangeMode] = useState("");
  const [showSaveButton, setShowSaveButton] = useState<boolean>(false);
  const [subPage, setSubPage] = useState("");
  const navigate = useNavigate();

  const [saveSuccess, setSaveSuccess] = useState(false);
  const [saveSuccessMsg, setSaveSuccessMsg] = useState("");
  const [isError, setIsError] = useState(false);

  const handleSuccessClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return false;
    }
    setSaveSuccess(false);
  };
  const handleErrorClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return false;
    }
    setIsError(false);
  };

  const handleAlert = (msg: string, type: string) => {

    if (type === "success") {
      setSaveSuccessMsg(msg);
      setSaveSuccess(true);
      setTimeout(() => setSaveSuccess(false), 2000);
    }

    if (type === "error") {
      setSaveSuccessMsg("");
      setIsError(true);
      setTimeout(() => setIsError(false), 2000);
    }
  };
  
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setPanelIndex(newValue);
    let subPage,
      val = 0;
    val = newValue + 1;
    switch (val) {
      case 1:
        subPage = "Accounting";
        setSubPage(subPage);
        navigate("/commission/accounting");
        break;
      case 2:
        subPage = "History";
        setSubPage(subPage);
        navigate("/commission/history");
        break;

      case 3:
        subPage = "Recalculation";
        setSubPage(subPage);
        navigate("/commission/recalculation");
        break;
      case 4:
        subPage = "Approval";
        setSubPage(subPage);
        navigate("/commission/approval");
        break;
    }
    return subPage;
  };

  useEffect(() => {
    const mode = searchParams.get("mode") || "";
    setChangeMode(mode);
  }, []);
  useEffect(() => {
    var panels: any = [];
    panels.push({
      id: 2,
      name: "Recalculation",
      enabled: true,
      className: "",
    });
    panels.push({
      id: 3,
      name: "Approval",
      enabled: true,
      className: "",
    });
    panels.push({
      id: 0,
      name: "Accounting",
      enabled: true,
      className: "",
    });
    panels.push({
      id: 1,
      name: "History",
      enabled: true,
      className: "",
    });
    setTabPanels(panels);
  }, []);

  useEffect(() => {}, [saveSuccessMsg]);

  const handleClickManualCommission = () => {
    window.open(`/#/commission/manualcommission`, "_blank");
  };
  return (
    <div className="custombody">
      <div className="navbar" style={{ position: "fixed", top: "85px" }}>
        <NavSideBar
          disabled={!showSaveButton}
          main="Commissions"
          subPage={t("Accounting")}
        />
      </div>
      <div
        className="main"
        style={{
          width: "100%",
          height: "100vh",
          backgroundColor: "#F2F2F2",
          padding: "100px 0px 10px 71px",
        }}
      >
        <div className="main-container commision-container">
          <div
            className="main-header"
            style={{ position: "sticky", top: "70px" }}
          >
            <div className="main-header-left commission-header-left">
              <PMainHeader>
                <PorscheFont
                  data-testid="page-dashboard"
                  className="main-header-text"
                >
                  {t("Commissions")}
                </PorscheFont>
                <PorscheFont className="main-header-description">
                  {t(
                    "Below you can track the commission accounting process for all approved orders with defined customer delivery date."
                  )}
                </PorscheFont>
              </PMainHeader>
            </div>
            <div
              className="main-header-right commission-header-right"
              style={
                window.innerHeight > 1000
                  ? { marginRight: "-125px" }
                  : { marginRight: "5px" }
              }
            >
              <PButton
                className="create-manual-commission-btn"
                data-testid="new-commission"
                iconSource="../../assets/images/ManualCommissionIcon.svg"
                icon="purchase"
                onClick={handleClickManualCommission}
                disabled={!isDiableDispatchOrderProcessingButton}
              >
                <i><ManualCommissionIcon /></i>
                <PorscheFont
                  className="create-manual-commission"
                >
                  {t("Create manual commission")}
                </PorscheFont>
              </PButton>
            </div>
          </div>
        </div>

        <div style={{ margin: "16px 25px 20px 25px" }}>
          <PFlex justifyContent={"space-between"}>
            <PFlexItem>
              {
                <Tabs
                  textColor="primary"
                  indicatorColor="secondary"
                  onChange={handleChange}
                  value={panelIndex}
                  sx={{
                    minHeight: "28px!important",
                    "& .MuiTabs-indicator": {
                      backgroundColor: "#D5001B",
                      height: 2,
                    },
                    "& .MuiTab-root.Mui-selected": {
                      color: "#313639",
                      textTransform: "inherit",
                      fontFamily: "Porsche Next",
                      paddingBottom: "5px",
                    },
                  }}
                >
                  {tabPanels.map((tabPanel, i) => {
                    return (
                      <Tab
                        disabled={!tabPanel.enabled}
                        label={t(tabPanel.name)}
                        value={tabPanel.id}
                        sx={{
                          borderLeft: "1px solid #C8CACB ",
                          padding: "0px 12px !important",
                          minHeight: "6px!important",
                          textTransform: "inherit",
                          fontFamily: "Porsche Next",
                          fontSize: "16px",
                        }}
                      />
                    );
                  })}
                </Tabs>
              }
            </PFlexItem>
            <PFlexItem>
              {/* {saveSuccessMsg} */}
              {saveSuccess && (
                <Alert
                  severity="success"
                  sx={{ width: "100%", marginTop: "-20px" }}
                >
                  {t(saveSuccessMsg)}
                </Alert>
              )}
              {isError && (
                <Alert
                  severity="error"
                  sx={{ width: "100%", marginTop: "-20px" }}
                >
                  {t("Error!")}
                </Alert>
              )}
            </PFlexItem>
          </PFlex>
          <div className="inreview">
            {panelIndex === 2 && <Recalculation />}

            {panelIndex === 3 && <Approval />}

            {panelIndex === 0 && (
              <Accounting
                handleMsg={(msg: string, type: string) =>
                  handleAlert(msg, type)
                }
              />
            )}

            {panelIndex === 1 && <History />}
          </div>
        </div>
      </div>
    </div>
  );
};
