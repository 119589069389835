import { format, isValid, parseISO } from "date-fns";

export const currencyFormatter = (value: number) => {
  if (value < 1) return value;

  return new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
    maximumFractionDigits: 2,
  }).format(value);
};

export const decimalFormatter = (value: number) => {
  return new Intl.NumberFormat("de-DE", {
    style: "decimal",
    maximumFractionDigits: 2,
  }).format(value);
};

export const percentageFormatter = (value: number) => {
  return new Intl.NumberFormat("de-DE", {
    style: "percent",
    maximumFractionDigits: 2,
  }).format(value);
};

export const euNumberFormat = (value: number) => {
  return new Intl.NumberFormat("de-DE").format(value);
};

export const formatCapitalize = (input: string | null): string => {
  // Split the input string by underscores to get individual words
  if (input?.length) {
    const words = input.split("_");

    // Capitalize each word and join them with a space
    const formattedString = words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    return formattedString;
  } else {
    return "";
  }
};

export function formatDate(
  dateString: string | null | undefined,
  formatString: string = "dd.MM.yyyy"
): string {
  if (!dateString) {
    // Handle the case where dateString is null or undefined
    return "-";
  }

  const parsedDate = parseISO(dateString);

  if (isValid(parsedDate)) {
    return format(parsedDate, formatString);
  } else {
    // Handle the case where dateString is not a valid date
    return "-";
  }
};

export function isValidEuropeanAccountingFormat(input : string) {
  // Regular expression to match the European accounting format (1.000,00 €)
  const europeanFormatPattern = /^\d{1,3}(?:\.\d{3})*(?:,\d{1,2})?\s€$/;
  return europeanFormatPattern.test(input);
}

