import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Alert, Snackbar } from '@mui/material';
import {
  PButton, PButtonGroup, PDivider, PFlex, PFlexItem, PSelectWrapper
} from '@porsche-design-system/components-react';

import { PorscheFont } from '../../../assets/styles/CustomStyles';
import { OrderContext } from '../../../contexts/OrderContext';
import { sortApprovalConfiguration } from '../../../helpers/sorter';
import { ApprovalStepDTO, ApprovalsRequestDTO } from '../../../interfaces/Approvals';
import { CalculationDraftDTO } from '../../../interfaces/Calculation';
import { OrderDTO } from '../../../interfaces/Order';
import { UserDTO, UserDetailsDTO } from '../../../interfaces/User';
import {
  ApprovalConfiguration, ApprovalConfigurationStep
} from '../../../interfaces/adminApproval/ApprovalConfiguration';
import { HistoryOrderLogTypes } from '../../../interfaces/changeHistory';
import { type } from '../../../services/Constants';
import { dcsgetApprovalConfiguration, dcspostChangeHistoryLogs, dcssubmitRecalculation } from '../../../services/data/Helpers';
import { SingleOrderDetailType } from '../../../interfaces/OrderDetails';
import { Constant } from '../../../configs/Constants';
import CustomSnakebar from '../../common/CustomSnakebar';

interface Props {
  handleModalClose: () => void;
  //showMessage: (variant: string, msg: string) => void;
  id: number;
  //draft: string | null;
}

export const FirstCommissionApproval = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  //const {, showMessage, id, draft } = props;
  const { handleModalClose,id } = props;
  const [approvals, setApprovals] = useState<Array<ApprovalConfigurationStep>>([])
  const [approvalRequest, setApprovalRequest] = useState<ApprovalsRequestDTO>({} as ApprovalsRequestDTO);
  //const [approvalPost, setApprovalPost] = useState<Array<ApprovalStepDTO>>([]);
  const [order, setOrder] = useState<OrderDTO>({} as OrderDTO);
  const orderContext = useContext(OrderContext);
  let userDetails = {} as UserDetailsDTO;
  const [subjectOpen, setSubjectOpen] = useState<boolean>(false);
  const [ isValid, setIsValid ] = useState(false);
  const [discountPercent, setDiscountPercent] = useState(0.00);
  // const [saveSuccess, setSaveSuccess] = useState(false);
  // const [isError, setIsError] = useState(false);
  const [reCalculation, setReCalculation] = useState<CalculationDraftDTO>({} as CalculationDraftDTO)
  const [showAlert, setShowAlert] = useState(Constant.None)
  const [alertMessage, setAlertMessage] = useState(Constant.Savedsuccessfully)

  useEffect(() => {
    const init = async () => {
      const ord = orderContext.getCtxOrderDetails();
      setOrder(ord);
      
      if(ord?.vehicleDetails?.category?.type.length > 0 && ord.porscheCenter) {
        const category = ord.vehicleDetails.category.type;
        const apiResp = await dcsgetApprovalConfiguration(ord.vehicleDetails.category.type, ord.porscheCenter.id, ord.id);
        
        if(apiResp.status===type.SUCCESS){
          const approvalConfig = apiResp.response as ApprovalConfiguration;
          if (approvalConfig?.commissionApproval) {
            approvalConfig.commissionApproval = sortApprovalConfiguration(approvalConfig.commissionApproval);
            setApprovals(approvalConfig.commissionApproval);
          }
          
          //setApprovalConfig(approvalConfig);
        }
      
        // Set the discount %
        let calculation = {} as CalculationDraftDTO;
        calculation = orderContext.getCtxCalculationDraftDetails(category);
        let discount = 0.00;
        if(calculation?.calculationHeader && calculation?.vehiclePrices) {
          switch(category) {
            case "NewCar":
              // RRP net value
              if(calculation?.vehiclePrices[2] && calculation?.vehiclePrices[2].amountNet > 0.00 && calculation?.calculationHeader.internalDiscount !== undefined) {
                discount = calculation?.calculationHeader.internalDiscount / calculation?.vehiclePrices[2].amountNet * 100;
              }
              break;
            case "DemoCar":
              if(calculation?.vehiclePrices[0] && calculation?.vehiclePrices[0].amountNet > 0.00 && calculation?.calculationHeader.internalDiscount !== undefined) {
                discount = calculation?.calculationHeader.internalDiscount / calculation?.vehiclePrices[0].amountNet * 100;
              }
              break;
            case "UsedCar":
              if(calculation?.vehiclePrices[0] && calculation?.vehiclePrices[0].amountNet > 0.00 && calculation?.calculationHeader.internalDiscount !== undefined) {
                discount = calculation?.calculationHeader.internalDiscount / calculation?.vehiclePrices[0].amountNet * 100;
              }
              break;
          }

          setDiscountPercent(discount);
        }
       
      }
      let recalData = orderContext.getCtxReCalculation();
      setReCalculation(orderContext.getCtxReCalculation()?.reCalculation)
      
      //setApprovalConfig(approvalConfig);
    };
    init();
  }, [])

  const handleSubmit = async () => {
    let recalData = orderContext.getCtxReCalculation();
    recalData.commissionApprovalSteps=approvalRequest.approvalSteps;
    orderContext.updateCtxReCalculation(recalData);
    
    // TODO : NEED TO IMPLEMENT COMISSION SUMMARY
    
    const apiResp = await dcssubmitRecalculation(id,recalData);
     if(apiResp.status===type.SUCCESS) {
       const call = async () => {
          props.handleModalClose();
          if(id) {
            const logData: HistoryOrderLogTypes = {
              page: "",
              tab: "Recalculation Submitted",
              userId: userDetails?.id ? userDetails?.id : 1,
              comment: "",
              orderId: id
            };
            await dcspostChangeHistoryLogs(logData, 'order');
            const singOrderDetails:SingleOrderDetailType = { id: id, mode:'view', draft: false, tab: 'calculation' };
            const newWindow=  window.open(`/#/order/create`, "_blank") 
            newWindow?.sessionStorage.setItem(Constant.SingleOrdDetail, JSON.stringify(singOrderDetails));
            window.close();
          }
       }
       setShowAlert(Constant.Success)    
       setAlertMessage(Constant.Savedsuccessfully)
       setTimeout(() => call(), 1000);
     }
     if(apiResp.status===type.ERROR){
      setShowAlert(Constant.Error)
      setAlertMessage(apiResp.response)  
     }
  }

  const handleAssignee = (approval: ApprovalConfigurationStep, users: Array<UserDTO>, aprroversIndex: number, value: string) => {
    let sessionUser = sessionStorage.getItem("userDetails");
    if(sessionUser) {
      userDetails = JSON.parse(sessionUser);
    }

    let user = users.filter(user => user.id === parseInt(value));
    if(approvalRequest.approvalSteps && approvalRequest.approvalSteps.length > 0){
        let exists = false;
        
        approvalRequest.approvalSteps.map((step, i) => {
          if(step.approvalStep === approval.approvalStep) {
            if(step.approvalStep === "Discount") {
                const count = approvalRequest.approvalSteps.filter(x => x.approvalStep === "Discount");
                if(count.length === 2) {
                    exists = true;
                    approvalRequest.approvalSteps[i].approver = user.length > 0 ? user[0] : {};
                } 
            } else {
                exists = true;
                approvalRequest.approvalSteps[i].approver = user.length > 0 ? user[0] : {};
            }
          }
        })

        if(!exists) {
             const newApprover = {
                createdBy: userDetails ? {id: userDetails.id, ppnName: userDetails.ppnName} : {},
                configurationStep: {id: approval?.id!},
                approvalStep: approval.approvalStep,
                isEnabled: approval.isEnabled,
                action: "Assigned",
                approver: user.length > 0 ? user[0] : {},
                order: order?.id ? {id: order.id} : {}
            } as ApprovalStepDTO;
            
            approvalRequest.approvalSteps.push(newApprover);
        }
    } else {
        let  newApprover = {
            createdBy: userDetails ? {id: userDetails.id, ppnName: userDetails.ppnName} : {},
            configurationStep: {id: approval?.id!},
            approvalStep: approval.approvalStep,
            isEnabled: approval.isEnabled,
            action: "Assigned",
            approver: user.length > 0 ? user[0] : {},
            order: order?.id ? {id: order.id} : {}
        } as ApprovalStepDTO;

        approvalRequest.approvalSteps = [newApprover];
    }

    
    setApprovalRequest(approvalRequest);
    setIsValid(isValidApproval(approvalRequest));
  }

  const isValidApproval = (approvalRequest: ApprovalsRequestDTO) : boolean => {
    
    
    if(approvalRequest.approvalSteps.length > 0 ) {
        if(approvals.length > 0) {
            if (approvals[1].approvers && approvals[1].approvers.length > 0) {
                const exists0 = approvalRequest.approvalSteps.filter( x => x.approvalStep === approvals[1].approvalStep);
                if(exists0.length === 0) return false;
                if(exists0[0].approver.id === undefined) return false;
            }
         
            return true
        }
    } else {
        return false;
    };

    return true;
  }

  

 

  const handleClose = ( ) => {
    setShowAlert(Constant.None)
    setAlertMessage("")
  };
  return (
    <>
      <div style={{marginLeft: "-20px", marginTop:"-32px"}}>
        <PDivider color="neutral-contrast-low"></PDivider><br></br>
        <PFlex >
          <PFlexItem width="one-quarter" className="sub-headding"><PorscheFont>{t("Approval steps")}</PorscheFont></PFlexItem>
          <PFlexItem width="one-quarter" className="sub-headding"><PorscheFont>{t("User assigned")}</PorscheFont></PFlexItem>
        </PFlex><br></br>
        <PDivider color="neutral-contrast-high"></PDivider> 

        {/** nedd to add here first commission approval step */}
        { approvals.length > 0 && approvals[1] && approvals[1].approvers &&  approvals[1].approvers.length > 0 && (
        <>
          <PFlex>
            <PFlexItem width="one-quarter">
              <PFlex>
                  <PFlexItem>
                      <PDivider orientation="vertical" style={{ marginTop: "10px", width: "5px", height: "50px", backgroundColor: "#C8CACB" }} />
                  </PFlexItem>&nbsp;&nbsp;
                  <PFlexItem style={{ marginTop: "20px" }}>
                    <img src={require("../../../assets/images/approvals/1stordercheck.png")}></img>
                  </PFlexItem>&nbsp;&nbsp;
                  <PFlexItem style={{ marginTop: "20px" }}>
                      <PorscheFont className="tn-text-14-600">{t("1st commission check")}<span style={{ color: "#D5001B" }}>*</span></PorscheFont>
                  </PFlexItem>
              </PFlex>
            </PFlexItem>
            <PFlexItem width="one-quarter" style={{ marginTop: "10px", marginBottom: "10px" }}>
              <PFlexItem>
                <PSelectWrapper hideLabel={false}>
                    <select name="1stordercheck" onChange={(e) => handleAssignee(approvals[1], approvals[1].approvers ? approvals[1].approvers : [] , 0, e.target.value)} >
                    <option value={0}>{t("Assign to user")}</option>
                    {approvals[1].approvers.map((user, index) => {
                      // if (getLogedInUser().id !== user.id) {
                      if(order?.orderCreator?.id !== user.id) {
                        return(
                          <option key={index} value={user.id} >
                            {user.ppnName}
                          </option>
                        );
                      }
                    })}
                    </select>
                </PSelectWrapper>                              
              </PFlexItem>
            </PFlexItem>
          </PFlex>
          <PDivider color="neutral-contrast-low"></PDivider>
        </>
      )}

      { approvals.length > 0 && approvals[2] && approvals[2].isEnabled && approvals[2].approvers && approvals[2].approvers.length > 0 && (
        <>
          <PFlex>
            <PFlexItem width="one-quarter">
              <PFlex>
                  <PFlexItem>
                      <PDivider orientation="vertical" style={{ marginTop: "10px", width: "5px", height: "50px", backgroundColor: "#C8CACB" }} />
                  </PFlexItem>&nbsp;&nbsp;
                  <PFlexItem style={{ marginTop: "20px" }}>
                    <img src={require("../../../assets/images/approvals/2ndordercheck.png")}></img>
                  </PFlexItem>&nbsp;&nbsp;
                  <PFlexItem style={{ marginTop: "20px" }}>
                      <PorscheFont className="tn-text-14-600">{t("2nd commission check")}<span style={{ color: "#D5001B" }}></span></PorscheFont>
                  </PFlexItem>
              </PFlex>
            </PFlexItem>
            <PFlexItem width="one-quarter" style={{ marginTop: "10px", marginBottom: "10px" }}>
              <PFlexItem>
                <div className='user-assigned' style={{width: "100%"}}>
                  <PorscheFont style={{ padding: "5px" }}></PorscheFont>
                </div>                           
              </PFlexItem>
            </PFlexItem>
          </PFlex>
          <PDivider color="neutral-contrast-low"></PDivider>
        </>
      )}
       { approvals.length > 0 && approvals[3] && approvals[3].isEnabled && approvals[3].approvers && approvals[3].approvers.length > 0 && (reCalculation?.commissionCalculation?.vehicleCommission==="Fixed"||(reCalculation?.commissionCalculation?.vehicleCommission==="Standard" && (reCalculation?.commissionCalculation?.commissionTypeText==="NoProfitCommission"||reCalculation?.commissionCalculation?.commissionTypeText==="MaxCommission"||reCalculation?.commissionCalculation?.commissionTypeText==="MinCommission")) ) && (
        <>
          <PFlex>
            <PFlexItem width="one-quarter">
              <PFlex>
                  <PFlexItem>
                      <PDivider orientation="vertical" style={{ marginTop: "10px", width: "5px", height: "50px", backgroundColor: "#C8CACB" }} />
                  </PFlexItem>&nbsp;&nbsp;
                  <PFlexItem style={{ marginTop: "20px" }}>
                    <img src={require("../../../assets/images/approvals/FinalCommission.png")}></img>
                  </PFlexItem>&nbsp;&nbsp;
                  <PFlexItem style={{ marginTop: "20px" }}>
                      <PorscheFont className="tn-text-14-600">{t("Fixed commission")}<span style={{ color: "#D5001B" }}></span></PorscheFont>
                  </PFlexItem>
              </PFlex>
            </PFlexItem>
            <PFlexItem width="one-quarter" style={{ marginTop: "10px", marginBottom: "10px" }}>
              <PFlexItem>
                <div className='user-assigned' style={{width: "100%"}}>
                  <PorscheFont style={{ padding: "5px" }}></PorscheFont>
                </div>                           
              </PFlexItem>
            </PFlexItem>
          </PFlex>
          <PDivider color="neutral-contrast-low"></PDivider>
        </>
      )}
       <PFlex justifyContent={"flex-end"} className="mt16">
        <PFlexItem alignSelf={"flex-end"}>
            <PButtonGroup>
              <PButton icon='save' onClick={handleSubmit} disabled={!isValid}>{t("Submit")}</PButton>
            </PButtonGroup>
             </PFlexItem>
      </PFlex>
      
        <CustomSnakebar alertType={showAlert} alertMessage={alertMessage} handleClose={()=>handleClose()}/>
      </div>
    </>
  )
}