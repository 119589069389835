import { Route, Routes } from "react-router-dom";

import { ApprovalProcessPage } from "../pages/administration/ApprovalProcessPage";
import { ChangeHistoryPage } from "../pages/administration/ChangeHistoryPage";
import { CommissionScalingPage } from "../pages/administration/CommissionScalingPage";
import { UserManagementPage } from "../pages/administration/UserManagementPage";
import { VariablesPage } from "../pages/administration/VariablesPage";
import { AccountingPage } from "../pages/commissions/AccountingPage";
import { ApprovalPage } from "../pages/commissions/AprrovalPage";
import { HistoryPage } from "../pages/commissions/HistoryPage";
import { RecalculationPage } from "../pages/commissions/RecalculationPage";
import { ManualCommissionPage } from "../pages/commissions/ManualCommissionPage";
import { DashboardPage } from "../pages/dashboard/DashboardPage";
import { LoginPage } from "../pages/LoginPage";
import { CreateOrderPage } from "../pages/orders/CreateOrderPage";
import { InReviewPage } from "../pages/orders/InReviewPage";
import { OrderBookPage } from "../pages/orders/OrderBookPage";
import { WelcomePage } from "../pages/WelcomePage";
import { RecalcTab } from "./order/recalculation/RecalTab";
import { RequiredAuth } from "./RequiredAuth";
import Subsequent from "./order/subsequent/Subsequent";
import { useEffect, useState } from "react";
import { dcsgetManualCommissionUserDispatchData, getManualCommissionUserDispatchData } from "../services/data/Helpers";
import { ManualCommissionUserObject } from "../interfaces/commission";
import InactiveUser from "../pages/InactiveUser";
import { type } from "../services/Constants";
import PageNotFound from "../pages/PageNotFound";


type Props = {
  setActivated:React.Dispatch<React.SetStateAction<boolean>>
}
export const Routing = ({setActivated}: Props): JSX.Element => {
  const [manualCommissionDispatchData, setManualCommissionDispatchData] =
    useState({} as ManualCommissionUserObject);

  useEffect(() => {
    const fetchManualCommissionData = async () => {
       const apiResponse = await dcsgetManualCommissionUserDispatchData()
        if (apiResponse.status === type.SUCCESS) {
          let responseData = apiResponse.response as ManualCommissionUserObject;
          setManualCommissionDispatchData(responseData);
        }
    };
    if (window.location.hash==="#/commission/manualcommission"){
      fetchManualCommissionData();
    }
  }, [window.location.hash]); 

  return (
    <>
      <Routes>
      <Route
          path="/inactive-user"
          element={<InactiveUser setActivated={setActivated}/>}
        />
        <Route
          path="/admin/aprrovalprocess"
          element={<ApprovalProcessPage />}
        />
        <Route
          path="/admin/commissionscaling"
          element={<CommissionScalingPage />}
        />
        <Route path="/admin/usermanagement" element={<UserManagementPage />} />
        {/* <Route 
          path="/admin/variables" 
          element=
          {
            <RequiredAuth  permission="adminVariables">
              <VariablesPage />
            </RequiredAuth>
          } 
        /> */}
        <Route path="/admin/variables" element={<VariablesPage />} />
        <Route path="/admin/history" element={<ChangeHistoryPage />} />

        <Route path="/commission/accounting" element={<AccountingPage  />} />
        <Route path="/commission/approval" element={<ApprovalPage  />} />
        <Route path="/commission/history" element={<HistoryPage  />} />
        <Route path="/commission/recalculation"   element={<RecalculationPage  />} />
        <Route path="/commission/manualcommission"   element={<ManualCommissionPage manualCommissionDispatchData={manualCommissionDispatchData} />} />

        <Route path="/order/book" element={<OrderBookPage />} />
        <Route path="/order/create" element={<CreateOrderPage />} />
        <Route path="/order/recalculation" element={<RecalcTab />} />
        <Route path="/order/subsequent" element={<Subsequent />} />
        <Route path="/order/inreview" element={<InReviewPage />} />

        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/welcome" element={<WelcomePage />} />
        <Route path="/" element={<LoginPage />} />
        <Route path="*" element={<PageNotFound />}/>
      </Routes>
    </>
  );
};
