import { useTranslation } from "react-i18next";

import {
  PButton,
  PButtonGroup,
  PFlex,
  PFlexItem,
} from "@porsche-design-system/components-react";

import { PorscheFont } from "../../assets/styles/CustomStyles";
import { Constant } from "../../configs/Constants";
import { AccountingResponseDTO } from "../../interfaces/Commissions";
import { UserDetailsDTO } from "../../interfaces/User";
import { type } from "../../services/Constants";
import { dcsapproveComissionsAccounting } from "../../services/data/Helpers";

//import { TaskRow } from './TaskRow';

interface Props {
  commissionAccounting: AccountingResponseDTO;
  handleClosePopup: () => void;
  alertHandler: (status: string, message: string) => void;
}

export const ApproveList = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const { handleClosePopup, commissionAccounting, alertHandler } = props;

  let userDetails = {} as UserDetailsDTO;
  const sessionUser = sessionStorage.getItem("userDetails");
  if (sessionUser) {
    userDetails = JSON.parse(sessionUser);
  }

  const handleCancel = () => {
    handleClosePopup();
  };

  const handleKeepOrder = () => {
    const init = async () => {
      const apiResponse = await dcsapproveComissionsAccounting(
        commissionAccounting
      );

      if (apiResponse.status === type.SUCCESS) {
        alertHandler(Constant.Success, "Approved successfully");
      }
      if (apiResponse.status === type.ERROR) {
        alertHandler(Constant.Error, apiResponse.response);
      }

      handleClosePopup();
    };
    init();
  };

  return (
    <>
      <div style={{ height: "auto", width: "376px", margin: "-20px" }}>
        <PorscheFont style={{ fontSize: "16px", fontWeight: "700" }}>
          {t("Approve list?")}
        </PorscheFont>
        <PorscheFont className="dcs-title">
          {t("This action can not be undone.")}
        </PorscheFont>
        <PFlex justifyContent={"flex-end"} style={{ marginTop: "16px" }}>
          <PFlexItem>
            <PButtonGroup>
              <PButton variant="tertiary" icon="close" onClick={handleCancel}>
                {t("No, cancel")}
              </PButton>
              <PButton variant="secondary" onClick={handleKeepOrder}>
                {t("Yes, approve list")}
              </PButton>
            </PButtonGroup>
          </PFlexItem>
        </PFlex>
      </div>
    </>
  );
};
