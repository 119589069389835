import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  PButtonGroup, PCheckboxWrapper
} from '@porsche-design-system/components-react';

import { PorscheFont } from '../../../../assets/styles/CustomStyles';
import { OrderContext } from '../../../../contexts/OrderContext';
import { UpsaleError } from '../../../../interfaces/Upsale';

const durationsConfig = [
  {
    value: 12,
    selected: false,
  },
  {
    value: 24,
    selected: false,
  },
  {
    value: 36,
    selected: false,
  },
];

interface Props {
  open?: boolean;
  enableNextButton: (isValid: boolean) => void;
  disabled: boolean;
  handleModified: () => void;
}

export const PAW = (props: Props): JSX.Element => {
  const { open, enableNextButton, disabled, handleModified } = props;
  const orderContext = useContext(OrderContext);
  let upsaleDetails = orderContext.getCtxUpsaleDetails();

  const [ assistance, setAssistance ] = useState<boolean>(true);
  const [ duration, setDuration ] = useState<number>(0);
  const [ durations, setDurations ] = useState(durationsConfig);
  const [errors, setErrors] = useState<Array<UpsaleError>>([]);
  const { t } = useTranslation();

  const [ reload, setReload ] = useState("");
  const [ disabledClassName, setDisabledClassName] = useState("")


  useEffect(() => {
    const isFormValid = isValidUpsale();
    orderContext.updateFormValidation(1, isFormValid);
    enableNextButton(isFormValid);
  })

  useEffect(() => {
    setAssistance(upsaleDetails.includeAssistance);
    if(disabled) {
      setDisabledClassName("disabled")
    } else {
      setDisabledClassName("")
    }
  }, [upsaleDetails, disabled])

  const isValidUpsale = () : boolean => {
    const errors = orderContext.getErrors();
    return errors.every(error => {
        if (open && error.section === "upsalePaw") return false
        return true
    })
  }

  const handleAssistance = () => {
    if(upsaleDetails.includeAssistance !== !assistance) {
      let ord = orderContext.getCtxOrderDetails();
      let cdd = orderContext.getCtxCalculationDraftDetails(ord?.vehicleDetails?.category?.type);
      if(cdd.calculationType !== undefined) {
        if(cdd.additionalCostsPrices !== undefined && upsaleDetails.durationInMonths > 0 && cdd.additionalCostsPrices[3].label !== `Assistance (${upsaleDetails.durationInMonths} months)`) {
          let add = cdd.additionalCostsPrices;
          add[3].amountNet  = 0.00;
          add[3].customerShare = 0.00;
          add[3].discountInEuro = 0.00;
          add[3].discountInPercent = 0.00;
          cdd.additionalCostsPrices = add;
          orderContext.updateCtxCalculationDraftDetails(cdd, ord?.vehicleDetails?.category?.type);
        }
      }
    }
    upsaleDetails.includeAssistance = !assistance;
    orderContext.updateCtxUpsaleDetails(upsaleDetails);
    setAssistance(!assistance);
    handleModified();
    setReload(new Date().toLocaleString());
    validateDuration();
  }

  const handleDuration = (value: number) => {
    if (durations) {
      durations.map((v) => {
        if (v.value === value) {
          v.selected = true;
        } else {
          v.selected = false;
        }
      })

      if(upsaleDetails.durationInMonths !== value) {
        let ord = orderContext.getCtxOrderDetails();
        let cdd = orderContext.getCtxCalculationDraftDetails(ord?.vehicleDetails?.category?.type);
        if(cdd.calculationType !== undefined) {
          if(cdd.additionalCostsPrices !== undefined && cdd.additionalCostsPrices[2].label !== `PAW (${value} months)`) {
            let add = cdd.additionalCostsPrices;
            add[2].amountNet  = 0.00;
            add[2].customerShare = 0.00;
            add[2].discountInEuro = 0.00;
            add[2].discountInPercent = 0.00;
            add[3].amountNet  = 0.00;
            add[3].customerShare = 0.00;
            add[3].discountInEuro = 0.00;
            add[3].discountInPercent = 0.00;
            cdd.additionalCostsPrices = add;
            orderContext.updateCtxCalculationDraftDetails(cdd, ord?.vehicleDetails?.category?.type);
          }
        }
      }

      upsaleDetails.durationInMonths = value;
      orderContext.updateCtxUpsaleDetails(upsaleDetails);

      orderContext.removeError("upsalePaw")
      orderContext.removeError("duration")
      setDurations(durations);
      handleModified();
      setReload(new Date().toLocaleString());
    }
  }

  useEffect(() => {
    if (durations) {
      durations.map((v) => {
        if (v.selected) {
          upsaleDetails.durationInMonths = v.value;
        }
      })
    }


    return() => {
      //setAssistance(true);
      setDurations(durationsConfig);
    }
  });

  const validateDuration = () => {
    if (open) {
      if (upsaleDetails.durationInMonths === 0) {
        orderContext.setErrors({
          field: "durationInMonths",
          message: "Please select the duration in months",
          section: "upsalePaw"
        });

        setErrors(orderContext.getErrors());
      }
    }
  }

  useEffect(() => {
    if (open) {
      if (upsaleDetails.durationInMonths !== undefined || upsaleDetails.durationInMonths === 0) {
        durations.map((v) => {
          v.selected = false
        })
      }
    }
  }, [open])

  return (
    <>
      <div className="pt15">
        <PCheckboxWrapper label={`${t("Incl. Assistance")}`} hideLabel={false}>
          <input
            type="checkbox"
            name="assistance"
            checked={assistance ? true : false}
            onChange={handleAssistance}
            disabled={disabled}
            //onFocus={validateDuration}
          />
        </PCheckboxWrapper>

        <div className="pt10">
          <span><PorscheFont>{t("Duration in months")} <span style={{color: "#D5001B"}}>*</span></PorscheFont></span>
          <PButtonGroup className="pt10">
            {
              durations.map((duration, index) => {
                if(duration.value === upsaleDetails?.durationInMonths) duration.selected = true
                return (
                  <div
                    key={index}
                    className={duration.selected ? `btn-duration btn-duration-selected ${disabledClassName}` : `btn-duration ${disabledClassName}`}
                    onClick={() => handleDuration(duration.value)}
                    //onTouchCancel={() => validateDuration}
                  >
                    {duration.value}
                  </div>
                )
              })
            }
          </PButtonGroup>
        </div>
      </div>
    </>
  );
};
