import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Tab, Tabs } from '@mui/material';
import {
    PFlex, PFlexItem, TabChangeEvent
} from '@porsche-design-system/components-react';

import { PMainHeader, PorscheFont } from '../../assets/styles/CustomStyles';
import { ActiveUsersNew } from '../../components/administration/userManagement/ActiveUsersNew';
import { InactiveUsers } from '../../components/administration/userManagement/InactiveUsers';
import { NewUsers } from '../../components/administration/userManagement/NewUsers';
import ScrollToTop from '../../components/common/ScrollToTop';
import { NavSideBar } from '../../components/NavSideBar';
import { AuthContext } from '../../contexts/AuthContext';

interface HeaderTabPanels {
  id: number;
  name: string;
  enabled: boolean;
  className: string;
}
export const UserManagementPage = (): JSX.Element => {
  const { t } = useTranslation();
  const [edit, setEdit] = useState(false);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [reload, setReload] = useState("");
  const authCtx = useContext(AuthContext);
  const [tabPanels, setTabPanels] = useState<Array<HeaderTabPanels>>([]);
  const [ showSaveButton, setShowSaveButton ] = useState<boolean>(false);

  const [panelIndex, setPanelIndex] = useState<number>(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [modeChange, setChangeMode] = useState("");
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setPanelIndex(newValue);
  };
  useEffect(() => {
    const mode = searchParams.get("mode") || "";
    setChangeMode(mode);
  }, [])

  useEffect(() => { }, [edit, reload]);
  const onTabChange = (e: CustomEvent<TabChangeEvent>) => {
    setTabIndex(e.detail.activeTabIndex);
  };
  const onNextTabChange = (tabIndex: number) => {
    setTabIndex(tabIndex);
  };
  useEffect(() => {
    var panels = [];
    panels.push({
      id: 0, name: "Active Users", enabled: true, className: ""
    });
    panels.push({
      id: 1, name: "New Users", enabled: true, className: ""
    });
    panels.push({
      id: 2, name: "Inactive Users", enabled: true, className: ""
    });
    setTabPanels(panels);
  }, []);

  return (
    <>
    <div className="custombody" style={{marginBottom:"-40px"}}>
      <div className="navbar" style={{ position: 'fixed', top: '85px',zIndex:"9999" }}>
        <NavSideBar  disabled={!showSaveButton} main="Administration" subPage={t("User Management")} />
      </div>
      <div>     
      <div className="custombody"style={{ width: '100%',  backgroundColor: '#F2F2F2', padding: '100px 0px 10px 71px'}}>
      <div className="admin-header" style={{position:'fixed',marginTop:"-32px",padding:"40px 40px 16px 40px",marginLeft:"-10px" ,backgroundColor: '#F2F2F2', zIndex:"999"}}>   
      <div className="main-header-left">
        <PMainHeader>
          <PorscheFont data-testid="page-variables" className="main-header-text">
            {t("User Management")}
          </PorscheFont>
          <PorscheFont className="main-header-description">
            {t("Enter user information, assign dealer permissions, commission scalings and special rights to users.")}
          </PorscheFont>
        </PMainHeader>
        <div style={{paddingTop:"5px"}}>
        <PFlex>
         <PFlexItem width="three-quarters">
          {<Tabs
            textColor="primary"
            indicatorColor="secondary"
            onChange={handleChange}
            value={panelIndex}
            sx={{
               minHeight: '28px!important',
               "& .MuiTabs-indicator": {
               backgroundColor: "#D5001B",
               height: 2,
              },
              "& .MuiTab-root.Mui-selected": {
               color: "#313639",
               textTransform: "inherit",
               fontFamily: "Porsche Next",
               paddingBottom: "5px"
              }
             }}
            >
            {tabPanels.map((tabPanel, i) => {
              return (
                <Tab
                  disabled={!tabPanel.enabled}
                  label={t(tabPanel.name)}
                  value={tabPanel.id}
                  sx={{
                      borderLeft: "1px solid #C8CACB ",
                      padding: "0px 24px !important",
                      minHeight: '16px!important',
                      textTransform: "inherit",
                      fontFamily: "Porsche Next",
                      fontSize: "16px",
                  }}
                />
              );
            })}
          </Tabs>}
          </PFlexItem>
        </PFlex>
        </div>
      </div>
      </div>
      <div style={{padding:"106px 125px 32px 32px",minHeight:"100vh", width:"100vw"}}> 
        <ScrollToTop/>         
        {panelIndex === 0 && <ActiveUsersNew />}
        {panelIndex === 1 && <NewUsers/>}
        {panelIndex === 2 && <InactiveUsers/>}             
      </div>
      </div>     
      </div>
      </div>   
    </>    
     );
   };
  
