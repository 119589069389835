import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PorscheFont } from "../../../assets/styles/CustomStyles";
import { Stack } from "@mui/material";
import { OrderApproval, OrderDTO, orderFlag } from "../../../interfaces/Order";
import { dcsgetOrderSummary, getOrderSummary } from "../../../services/data/Helpers";
import { CalculationDraftDTO } from "../../../interfaces/Calculation";
import { useSearchParams } from "react-router-dom";
import { UpsaleDetailsDTO } from "../../../interfaces/UpsaleNew";
import {
  PartType,
  VehiclePriceType,
  paymentPrintTypes,
  tradeInPrintTypes,
} from "../../../configs/Enums";
import {
  formatCurrencyWithNoUnit,
  getStatusColor,
  getUpsaleItemsTotal,
  negativeValueHandler,
  showDashToValue,
} from "../../../mock/helper";
import { formatDate } from "../../../helpers/formatters";
import { type } from "../../../services/Constants";
import getSessionStorage from "../../../helpers/getSessionStorage";
import { Constant } from "../../../configs/Constants";
import draftValueHandler from "../../../helpers/sessionDraftValueHandler";
import { OrderContext } from "../../../contexts/OrderContext";

export const SummaryPrint = React.forwardRef((props, ref: any) => {
  const { t } = useTranslation();
  const orderValues = getSessionStorage(Constant.SingleOrdDetail);

  const [searchParams, setSearchParams] = useSearchParams();
  const [order, setOrder] = useState<OrderDTO>({} as OrderDTO);
  const [recalData, setRecalData] = useState<CalculationDraftDTO>({}as CalculationDraftDTO)
  const [calDraft, setCalDraft] = useState<CalculationDraftDTO>(
    {} as CalculationDraftDTO
  );
  const [upsaleDetails, setUpsaleDetails] = useState<UpsaleDetailsDTO>(
    {} as UpsaleDetailsDTO
  );
  const [OrderApprovalData, setOrderApprovalData] = useState<OrderApproval>(
    {} as OrderApproval
  );
  const [orderFlags, setOrderFlags] = useState<orderFlag>({} as orderFlag);


  const isDraftVersion =draftValueHandler(orderValues?.draft);

  useEffect(() => {
    const init = async () => {
      const id = orderValues?.id ? orderValues?.id+'' :'' ;
      
      if (id && isDraftVersion==='false') {
       const apiResponse = await dcsgetOrderSummary(parseInt(id));
        if(apiResponse.status===type.SUCCESS){
          const data = apiResponse.response
          const calDraft = data?.calculation as CalculationDraftDTO;
        const upsaleData = data?.upsale as UpsaleDetailsDTO;
        const orderData = data?.order as OrderDTO;
        const recalDataResponse = data?.reCalculation as CalculationDraftDTO
        const OrderApprovalresponse = data?.orderApproval as OrderApproval;
        const orderFlagObject = {
          dispatchOrderProcessingFlag: data?.dispatchOrderProcessingFlag,
          orderCommissionApprovalFlag: data?.orderCommissionApprovalFlag,
          orderFixedCommissionFlag: data?.orderFixedCommissionFlag,
        };
        setCalDraft(calDraft);
        setUpsaleDetails(upsaleData);
        setOrder(orderData);
        setOrderApprovalData(OrderApprovalresponse);
        setOrderFlags(orderFlagObject);
        setRecalData(recalDataResponse)
        }
        
      }
    };

    init();
  }, []);

  const getImage = (iconName: string) => {
    let ret;
    switch (iconName) {
      case "Groups":
        ret = (
          <i className="image-border">
            <img
              src={require("../../../assets/images/summary/Groups.png")}
              alt={"Groups"}
            />
          </i>
        );
        break;

      case "Car":
        ret = (
          <i className="image-border">
            <img
              src={require("../../../assets/images/summary/Car.png")}
              alt={"Car"}
            />
          </i>
        );
        break;

      case "Apartment":
        ret = (
          <i className="image-border">
            <img
              src={require("../../../assets/images/summary/Apartment.png")}
              alt={"Apartment"}
            />
          </i>
        );
        break;
      case "Work":
        ret = (
          <i className="image-border">
            <img
              src={require("../../../assets/images/summary/Work.png")}
              alt={"Work"}
            />
          </i>
        );
        break;
      case "Calendar":
        ret = (
          <i className="image-border">
            <img
              src={require("../../../assets/images/summary/Calendar.png")}
              alt={"Calendar"}
            />
          </i>
        );
        break;
      case "Trade":
        ret = (
          <i className="image-border">
            <img
              src={require("../../../assets/images/summary/Trade.png")}
              alt={"Trade"}
            />
          </i>
        );
        break;
      case "Credit_card":
        ret = (
          <i className="image-border">
            <img
              src={require("../../../assets/images/summary/Credit_card.png")}
              alt={"Credit_card"}
            />
          </i>
        );
        break;
      case "Shipping":
        ret = (
          <i className="image-border">
            <img
              src={require("../../../assets/images/summary/Shipping.png")}
              alt={"Shipping"}
            />
          </i>
        );
        break;
      case "Vector":
        ret = (
          <i className="image-border">
            <img
              src={require("../../../assets/images/summary/Vector.png")}
              alt={"Vector"}
            />
          </i>
        );
        break;

      default:
        ret = (
          <i className="image-border">
            <img
              src={require("../../../assets/images/summary/Groups.png")}
              alt={"911"}
            />
          </i>
        );
        break;
    }

    return ret;
  };

  function PascalCasingHandler(input: string | null) {
    if (!input) {
      return null;
    }
    const words = input.split(/(?=[A-Z])/);
    const formatted = words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    return formatted;
  }

  const paymentPrintHandler = (str: string) => {
    if (
      paymentPrintTypes.hasOwnProperty(str as keyof typeof paymentPrintTypes)
    ) {
      return paymentPrintTypes[str as keyof typeof paymentPrintTypes];
    } else return "";
  };

  const tradeInPrintHandler = (str: string) => {
    if (
      tradeInPrintTypes.hasOwnProperty(str as keyof typeof tradeInPrintTypes)
    ) {
      return tradeInPrintTypes[str as keyof typeof tradeInPrintTypes];
    } else return "";
  };

  const orderContext = useContext(OrderContext);
  let upsaleDetailsContext = orderContext.getCtxUpsaleDetails();
  let upsalectxUpsaleItems = upsaleDetailsContext?.upsaleItems;

  const getTiresAndPartsAmount = (upsaleDetails: UpsaleDetailsDTO): string => {
    const { isTiresActivated, isPartsActivated, upsaleItems } = upsaleDetails;

    if (upsalectxUpsaleItems.length > 0) {
      return `${formatCurrencyWithNoUnit(
        getUpsaleItemsTotal(
          upsalectxUpsaleItems.filter((x) => x.partType === PartType.tire)
        ).customerShare +
          getUpsaleItemsTotal(
            upsalectxUpsaleItems.filter((x) => x.partType === PartType.part)
          ).customerShare
      )} €`;
    } else if (
      (isTiresActivated || isPartsActivated) &&
      upsaleItems.length > 0
    ) {
      return `${formatCurrencyWithNoUnit(
        getUpsaleItemsTotal(
          upsaleItems.filter((x) => x.partType === PartType.tire)
        ).customerShare +
          getUpsaleItemsTotal(
            upsaleItems.filter((x) => x.partType === PartType.part)
          ).customerShare
      )} €`;
    } else {
      return `${formatCurrencyWithNoUnit(0)} €`;
    }
  };

  const getPAWAndAssistanceAmount = (
    type: string,
    upsaleDetails: any,
    calDraft: CalculationDraftDTO
  ): string => {
    const { isPawActivated, includeAssistance } = upsaleDetails;
    const { additionalCostsPrices } = calDraft;

    let findPAWDetails = additionalCostsPrices?.find(
      (cost) => cost.type === "Paw"
    );
    let findAssistanceDetails = additionalCostsPrices?.find(
      (cost) => cost.type === "Assistance"
    );

    if (type === "pwa") {
      if (findPAWDetails?.customerShare) {
        return `${formatCurrencyWithNoUnit(findPAWDetails?.customerShare)} €`;
      } else {
        return `${formatCurrencyWithNoUnit(0)} €`; // Tires & Parts value when both toggles are deactivated
      }
    } else {
      if (includeAssistance && findAssistanceDetails?.customerShare) {
        return `${formatCurrencyWithNoUnit(
          findAssistanceDetails?.customerShare
        )} €`;
      } else {
        return `${formatCurrencyWithNoUnit(0)} €`; // Tires & Parts value when both toggles are deactivated
      }
    }
  };

  const getFinaCalculationValue = (
    type: string,
    isPercentage: boolean
  ): string => {
    const isUsedCar = order?.vehicleDetails?.category?.type === "UsedCar";
    const isDemoCar = order?.vehicleDetails?.category?.type === "DemoCar";
    const isNewCar = order?.vehicleDetails?.category?.type === "NewCar";

    let internalDiscountPercentage;
    let internalDiscountAmount;
    let externalDiscountPercentage;
    let externalDiscountAmount;
    let grossDiscountPercentage;
    let grossDiscountAmount;
    if (isUsedCar) {
      internalDiscountPercentage = null;
      internalDiscountAmount = null;
      externalDiscountPercentage = null;
      externalDiscountAmount = null;
      grossDiscountPercentage = null;
      grossDiscountAmount = null;
    } else if (isDemoCar || isNewCar) {
      const priceIndex = isDemoCar ? 0 : 2;

      internalDiscountPercentage =
        recalData.calculationHeader?.internalDiscount &&
        recalData?.vehiclePrices &&
        recalData?.vehiclePrices[priceIndex].amountNet > 0
          ? (recalData.calculationHeader.internalDiscount /
              recalData.vehiclePrices[priceIndex].amountNet) *
            100
          : 0.0;
      internalDiscountAmount =
        calDraft.calculationHeader?.internalDiscount || 0;

      // Calculate external discount percentage and amount
      externalDiscountPercentage =
        recalData.calculationHeader?.externalDiscount &&
        recalData?.vehiclePrices &&
        recalData?.vehiclePrices[priceIndex].amountNet > 0
          ? (recalData.calculationHeader.externalDiscount /
              recalData.vehiclePrices[priceIndex].amountNet) *
            100
          : 0.0;

      externalDiscountAmount =
        calDraft.calculationHeader?.externalDiscount || 0;

      // Calculate gross discount percentage and amount
      grossDiscountPercentage =
        recalData.calculationHeader?.grossProfit &&
        recalData?.vehiclePrices &&
        recalData?.vehiclePrices[priceIndex].amountNet > 0
          ? (recalData.calculationHeader.grossProfit /
              recalData.vehiclePrices[priceIndex].amountNet) *
            100
          : 0.0;

      grossDiscountAmount = calDraft.calculationHeader?.grossProfit || 0;
    }

    if (type === "internal") {
      if (isPercentage) {
        return internalDiscountPercentage
          ? `${formatCurrencyWithNoUnit(internalDiscountPercentage || 0)} %`
          : "-";
      } else {
        return internalDiscountAmount
          ? `${formatCurrencyWithNoUnit(internalDiscountAmount || 0)} €`
          : "-";
      }
    } else if (type === "external") {
      if (isPercentage) {
        return externalDiscountPercentage
          ? `${formatCurrencyWithNoUnit(externalDiscountPercentage || 0)} %`
          : "-";
      } else {
        return externalDiscountAmount
          ? `${formatCurrencyWithNoUnit(externalDiscountAmount || 0)} €`
          : "-";
      }
    } else {
      if (isPercentage) {
        return grossDiscountPercentage
          ? `${formatCurrencyWithNoUnit(grossDiscountPercentage || 0)} %`
          : "-";
      } else {
        return grossDiscountAmount
          ? `${formatCurrencyWithNoUnit(grossDiscountAmount || 0)} €`
          : "-";
      }
    }
  };

  const getCustomerCalculationValue = (
    type: string,
    isNet: boolean
  ): string => {
    const isUsedCar = order?.vehicleDetails?.category?.type === "UsedCar";
    const isNewCar = order?.vehicleDetails?.category?.type === "NewCar";
    const purchasePriceusedCar = calDraft?.vehiclePrices?.find(
      (value) => value?.displayLabel === VehiclePriceType.PurchasePriceUsed
    )?.amountNet;
    const sellingPriceusedCar = calDraft?.vehiclePrices?.find(
      (value) => value?.displayLabel === VehiclePriceType.SellingPriceDemo
    );
    const RRPData = calDraft?.vehiclePrices?.find(
      (value) => value?.displayLabel === VehiclePriceType.RRP
    );
    const optionsData = calDraft?.vehiclePrices?.find(
      (value) => value?.displayLabel === "options"
    );
    const totalInvoiceGross = calDraft?.calculationCustomer?.invoiceGross;
    const tatalInvoiceNet = calDraft?.calculationCustomer?.invoiceNet;

    if (type === "RRP") {
      if (isNet) {
        return isUsedCar
          ? `${formatCurrencyWithNoUnit(purchasePriceusedCar || 0)} €`
          : `${formatCurrencyWithNoUnit(RRPData?.amountNet || 0)} €`;
      } else {
        return isUsedCar
          ? "-"
          : `${formatCurrencyWithNoUnit(RRPData?.amountGross || 0)} €`;
      }
    } else if (type === "Options") {
      if (isNet) {
        return isNewCar
          ? `${formatCurrencyWithNoUnit(optionsData?.amountNet || 0)} €`
          : `${formatCurrencyWithNoUnit(
              sellingPriceusedCar?.amountNet || 0
            )} €`;
      } else {
        return isNewCar
          ? `${formatCurrencyWithNoUnit(optionsData?.amountGross || 0)} €`
          : `${formatCurrencyWithNoUnit(
              sellingPriceusedCar?.amountGross || 0
            )} €`;
      }
    } else {
      if (isNet) {
        return `${
          tatalInvoiceNet && formatCurrencyWithNoUnit(tatalInvoiceNet)
        } €`;
      } else {
        return `${
          totalInvoiceGross && formatCurrencyWithNoUnit(totalInvoiceGross)
        } €`;
      }
    }
  };

  const getCommissionValue = (type: string): string => {
    let vehicleCommission =
      calDraft?.commissionCalculation?.vehicleCommissionEuro;
    let totalCommission = calDraft?.commissionCalculation?.totalCommission;
    let otherCommission =
      totalCommission && vehicleCommission
        ? totalCommission - vehicleCommission
        : 0;
    if (type === "vehicle") {
      return `${vehicleCommission &&  formatCurrencyWithNoUnit(negativeValueHandler(vehicleCommission)  || 0)} €`;
    } else if (type === "other") {
      return `${otherCommission && formatCurrencyWithNoUnit(negativeValueHandler(otherCommission) || 0)} €`;
    } else {
      return `${totalCommission && formatCurrencyWithNoUnit(negativeValueHandler(totalCommission) || 0)} €`;
    }
  };
  
  return (
    <>
      <div ref={ref}>
        <div className="print-header-container">
          <div className="header-left-side">
            <PorscheFont className="print-heading-name">
              4. {t(" Summary")}
            </PorscheFont>
            <div className="seller-info-label">
              <table>
                <tr>
                  <td className="table-label-td font-14">Seller</td>
                  <td className="font-14">
                    {showDashToValue(order?.orderCreator?.seller)}
                  </td>
                </tr>
                <tr>
                  <td className="table-label-td font-14">Comm No.</td>
                  <td className="font-14">
                    {showDashToValue(order?.vehicleDetails?.commissionNumber)}
                  </td>
                </tr>
                <tr>
                  <td className="table-label-td font-14">Act No.</td>
                  <td className="font-14">
                    {showDashToValue(order?.vehicleDetails?.actNumber)}
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div className="header-right-side">
            <Stack direction="column" className="right-header-container">
              <PorscheFont className="order-number">
                {t("Order No. 20120230001")}
              </PorscheFont>
              <PorscheFont className="order-date">
                {t("Creation date")}: {formatDate(order?.createdOn)}
              </PorscheFont>
              <PorscheFont className="order-date">
                {t("Last edited")}: {formatDate(order?.updatedOn)}
              </PorscheFont>
            </Stack>
          </div>
        </div>
        <div className="summary-print-wrapper">
          <div className="summary-print-container">
            <PorscheFont className="print-header-text">
              {t("Order details")}
            </PorscheFont>
            <div className="order-print-container">
              <div className="pflex-order-print-items">
                <div className="order-details-block">
                  <div className="order-details-image">
                    {getImage("Groups")}
                  </div>
                  <div className="order-discription">
                    <PorscheFont className="order-label">
                      {`${PascalCasingHandler(
                        order?.customerDetails?.customerGroupType
                      )} - ${
                        order?.customerDetails?.customerType ===
                          "DealerBiddingPlatform" || "KeyAccount"
                          ? PascalCasingHandler(
                              order?.customerDetails?.customerType
                            )
                          : showDashToValue(
                              order?.customerDetails?.customerType
                            )
                      }`}
                    </PorscheFont>
                    <PorscheFont className="order-value">
                      {order?.customerDetails?.customerType
                        ? order?.customerDetails?.customerGroupType ===
                          "BusinessClient"
                          ? `${order?.customerDetails?.companyName} ${
                              order?.customerDetails.copNumber
                                ? `(${order?.customerDetails.copNumber})`
                                : ""
                            }`
                          : `${order?.customerDetails.firstName} ${
                              order?.customerDetails.lastName
                            } ${
                              order?.customerDetails.copNumber
                                ? `(${order?.customerDetails.copNumber})`
                                : ""
                            }`
                        : "-"}
                    </PorscheFont>
                  </div>
                </div>
              </div>
              <div className="pflex-order-print-items">
                <div className="order-details-block">
                  <div className="order-details-image">{getImage("Work")}</div>
                  <div className="order-discription">
                    <PorscheFont className="order-label">Seller</PorscheFont>
                    <PorscheFont className="order-value">
                      {order?.orderCreator?.seller}
                    </PorscheFont>
                  </div>
                </div>
              </div>
              <div className="pflex-order-print-items">
                <div className="order-details-block">
                  <div className="order-details-image">
                    {getImage("Credit_card")}
                  </div>
                  <div className="order-discription">
                    <PorscheFont className="order-label">Payment</PorscheFont>
                    <PorscheFont className="order-value">
                      {/* {formatCapitalize(paymentDetails?.paymentType)} */}
                      {paymentPrintHandler(
                        order?.paymentDetails?.paymentType
                          ? order?.paymentDetails?.paymentType
                          : ""
                      )}
                    </PorscheFont>
                  </div>
                </div>
              </div>
              <div className="pflex-order-print-items">
                <div className="order-details-block">
                  <div className="order-details-image">{getImage("Car")}</div>
                  <div className="order-discription">
                    <PorscheFont className="order-label">
                      {order?.vehicleDetails?.category?.label}
                    </PorscheFont>
                    <PorscheFont className="order-value">
                      {`${order?.vehicleDetails?.model?.modelName ?? ""} ${
                        order?.vehicleDetails?.modelDescription ?? ""
                      }`}
                    </PorscheFont>
                  </div>
                </div>
              </div>
              <div className="pflex-order-print-items">
                <div className="order-details-block">
                  <div className="order-details-image">
                    {getImage("Calendar")}
                  </div>
                  <div className="order-discription">
                    <PorscheFont className="order-label">
                    {t("Date of order")}
                    </PorscheFont>
                    <PorscheFont className="order-value">
                      {formatDate(order?.dateOfOrder)}
                    </PorscheFont>
                  </div>
                </div>
              </div>
              <div className="pflex-order-print-items">
                <div className="order-details-block">
                  <div className="order-details-image">
                    {getImage("Shipping")}
                  </div>
                  <div className="order-discription">
                    <PorscheFont className="order-label">Delivery</PorscheFont>
                    <PorscheFont className="order-value">
                      {order?.deliveryDetails?.deliveryLocation?.name}
                    </PorscheFont>
                  </div>
                </div>
              </div>
              <div className="pflex-order-print-items last-block-item">
                <div className="order-details-block">
                  <div className="order-details-image">
                    {getImage("Apartment")}
                  </div>
                  <div className="order-discription">
                    <PorscheFont className="order-label">
                    {t("Porsche center")}
                    </PorscheFont>
                    <PorscheFont className="order-value">
                      {order?.porscheCenter?.centerName}
                    </PorscheFont>
                  </div>
                </div>
              </div>
              <div className="pflex-order-print-items last-block-item">
                <div className="order-details-block">
                  <div className="order-details-image">{getImage("Trade")}</div>
                  <div className="order-discription">
                    <PorscheFont className="order-label">Trade-In</PorscheFont>
                    <PorscheFont className="order-value">
                      {order?.tradeInDetails?.tradeInType
                        ? tradeInPrintHandler(
                            order?.tradeInDetails?.tradeInType
                          )
                        : "-"}
                    </PorscheFont>
                  </div>
                </div>
              </div>
              <div className="pflex-order-print-items last-block-item">
                <div className="order-details-block">
                  <div className="order-details-image">
                    {getImage("Vector")}
                  </div>
                  <div className="order-discription">
                    <PorscheFont className="order-label">Status</PorscheFont>
                    <span className="order-status print-order-status">
                      <PorscheFont
                        className={` ${
                          order?.status !== "Draft"
                            ? `${
                                order?.status && getStatusColor(order?.status)
                              } order-status-value`
                            : "order-value"
                        }`}
                      >
                        {!!order?.status && order?.status === "Draft"
                          ? "-"
                          : PascalCasingHandler(order?.status)}
                      </PorscheFont>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="summary-middle-section-container">
            <div className="middle-section-block grid-container">
              <PorscheFont className="print-header-text">
                {t("Upsale")}
              </PorscheFont>
              <div className="upsale-print-container">
                <ul className="upsale-list">
                  <li className="li-list">
                    <PorscheFont className="upsale-label">
                      Tires & Parts
                    </PorscheFont>
                    <PorscheFont className="upsale-value">
                      {getTiresAndPartsAmount(upsaleDetails)}
                    </PorscheFont>
                  </li>
                  <li className="li-list">
                    <PorscheFont className="upsale-label">
                      {`PAW ${
                        upsaleDetails.isPawActivated
                          ? `(${upsaleDetails.durationInMonths} months)`
                          : ""
                      }`}
                    </PorscheFont>
                    <PorscheFont className="upsale-value">
                      {getPAWAndAssistanceAmount(
                        "pwa",
                        upsaleDetails,
                        calDraft
                      )}
                    </PorscheFont>
                  </li>
                  <li className="li-list last-list-item">
                    <PorscheFont className="upsale-label">
                      {t("Assistance")}
                    </PorscheFont>
                    <PorscheFont className="upsale-value">
                      {getPAWAndAssistanceAmount(
                        "assistance",
                        upsaleDetails,
                        calDraft
                      )}
                    </PorscheFont>
                  </li>
                </ul>
              </div>
            </div>
            <div className="middle-section-block grid-container">
              <PorscheFont className="print-header-text">
                {t("Order registration")}
              </PorscheFont>
              <div className="upsale-print-container">
                <ul className="upsale-list">
                  <li className="li-list">
                    <PorscheFont className="upsale-label">
                      {t("Order registration date")}
                    </PorscheFont>
                    <PorscheFont className="upsale-value">
                      {order?.registerDate
                        ? formatDate(order?.registerDate)
                        : null}
                    </PorscheFont>
                  </li>
                  <li className="li-list">
                    <PorscheFont className="upsale-label">
                      {t("Commission number")}
                    </PorscheFont>
                    <PorscheFont className="upsale-value">
                      {showDashToValue(order?.vehicleDetails?.commissionNumber)}
                    </PorscheFont>
                  </li>
                  <li className="li-list last-list-item">
                    <PorscheFont className="upsale-label">
                    {t("Act Number")}
                    </PorscheFont>
                    <PorscheFont className="upsale-value">
                      {showDashToValue(order?.vehicleDetails?.actNumber)}
                    </PorscheFont>
                  </li>
                </ul>
              </div>
            </div>
            <div className="middle-section-block grid-container">
              <PorscheFont className="print-header-text">
                {t("Customer delivery")}
              </PorscheFont>
              <div className="upsale-print-container">
                <ul className="upsale-list">
                  <li className="li-list">
                    <PorscheFont className="upsale-label">
                    {t("Customer delivery date")}
                    </PorscheFont>
                    <PorscheFont className="upsale-value">
                      {order?.customerDeliveryDate
                        ? formatDate(order?.customerDeliveryDate)
                        : null}
                    </PorscheFont>
                  </li>
                  <li className="li-list">
                    <PorscheFont className="upsale-label">
                      {t("Invoice date")} 
                    </PorscheFont>
                    <PorscheFont className="upsale-value">
                      {order?.invoiceDate
                        ? formatDate(order?.invoiceDate)
                        : null}
                    </PorscheFont>
                  </li>
                  <li className="li-list last-list-item">
                    <PorscheFont className="upsale-label">
                      C@P Ticket ID
                    </PorscheFont>
                    <PorscheFont className="upsale-value">
                      {showDashToValue(order?.copTicketId)}
                    </PorscheFont>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="summary-print-calculation-container">
            <PorscheFont className="print-header-text calculation-header">
              {t("Calculation")}
            </PorscheFont>
            <div className="calculation-print-wrapper">
              <table className="calculation-print-table">
                <thead>
                  <tr>
                    <th className="print-table-heading left-align">
                      Final calculation
                    </th>
                    <th className="print-table-heading"></th>
                    <th className="print-table-heading"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="print-cell-text">
                      {order?.vehicleDetails?.category?.type === "UsedCar"
                        ? t("Total costs")
                        : t("Internal discount")}
                    </td>
                    <td className="print-cell-text">
                      {getFinaCalculationValue("internal", true)}
                    </td>
                    <td className="print-cell-text right-align">
                    {/* {getFinaCalculationValue("internal", false) === "-" ? "" : getFinaCalculationValue("internal", false)} */}
                    {recalData?.calculationHeader?.internalDiscount &&  formatCurrencyWithNoUnit(recalData?.calculationHeader?.internalDiscount)} € 
                    </td>
                  </tr>
                  <tr>
                    <td className="print-cell-text">
                      {order?.vehicleDetails?.category?.type === "UsedCar"
                        ? t("Standing days")
                        : t("External discount")}
                    </td>
                    <td className="print-cell-text">
                      {" "}
                      {getFinaCalculationValue("external", true)}
                    </td>
                    <td className="print-cell-text right-align">
                      {" "}
                      {/* {getFinaCalculationValue("external", false)} */}
                      {recalData?.calculationHeader?.externalDiscount &&  formatCurrencyWithNoUnit(recalData?.calculationHeader?.externalDiscount)} € 

                    </td>
                  </tr>
                  <tr>
                    <td className="print-cell-text">{t("Gross profit")}</td>
                    <td className="print-cell-text">
                      {getFinaCalculationValue("gross", true)}
                    </td>
                    <td className="print-cell-text right-align">
                      {/* {getFinaCalculationValue("gross", false)} */}
                      {recalData?.calculationHeader?.grossProfit &&  formatCurrencyWithNoUnit(recalData?.calculationHeader?.grossProfit)} € 

                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="calculation-print-table">
                <thead>
                  <tr>
                    <th className="print-table-heading left-align">
                      {t("Calculation customer")}
                    </th>
                    <th className="print-table-heading right-align">{t("Net")}</th>
                    <th className="print-table-heading right-align">{t("Gross")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="print-cell-text">
                      {order?.vehicleDetails?.category?.type === "UsedCar"
                        ? t("Purchase price")
                        : t("RRP")}
                    </td>
                    <td className="print-cell-text right-align">
                      {" "}
                      {getCustomerCalculationValue("RRP", true)}
                    </td>
                    <td className="print-cell-text right-align">
                      {" "}
                      {getCustomerCalculationValue("RRP", false)}
                    </td>
                  </tr>
                  <tr>
                    <td className="print-cell-text">
                      {order?.vehicleDetails?.category?.type === "NewCar"
                        ? t("Options")
                        : t("Selling price")}
                    </td>
                    <td className="print-cell-text right-align">
                      {getCustomerCalculationValue("Options", true)}
                    </td>
                    <td className="print-cell-text right-align">
                      {getCustomerCalculationValue("Options", false)}
                    </td>
                  </tr>
                  <tr>
                    <td className="print-cell-text">{t("Total invoice")}</td>
                    <td className="print-cell-text right-align">
                      {" "}
                      {getCustomerCalculationValue("total", true)}
                    </td>
                    <td className="print-cell-text right-align">
                      {" "}
                      {getCustomerCalculationValue("total", false)}
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="calculation-print-table">
                <thead>
                  <tr>
                    <th className="print-table-heading left-align">
                    {t("Commission calculation")}
                    </th>
                    <th className="print-table-heading"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="print-cell-text"> {t("Vehicle commission")}</td>
                    <td className="print-cell-text right-align">
                      {getCommissionValue("vehicle")}
                    </td>
                  </tr>
                  <tr>
                    <td className="print-cell-text">{t("Other commissions")}</td>
                    <td className="print-cell-text right-align">
                      {getCommissionValue("other")}
                    </td>
                  </tr>
                  <tr>
                    <td className="print-cell-text">{t("Total commission")}</td>
                    <td className="print-cell-text right-align">
                      {getCommissionValue("total")}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="summary-print-approval-container">
            <PorscheFont className="print-header-approval-text">
              {t("Order processing")}
            </PorscheFont>
            <div className="order-approval-container">
              <div className="pflex-order-list">
                <ul className="ul-order-list">
                  <li
                    className={`list-item ${
                      !(
                        order?.status === "Approved" ||
                        order?.status === "CommissionsApproved" ||
                        order?.status === "Paid"
                      )
                        ? "order-pending"
                        : "order-completed"
                    }`}
                  >
                    <PorscheFont className="heading-text">
                    {t('Order approval')}
                    </PorscheFont>
                    {!(
                      order?.status === "Approved" ||
                      order?.status === "CommissionsApproved" ||
                      order?.status === "Paid"
                    ) ? (
                      <div className="order-status-container">
                        <i className="image-wrapper">
                          <img
                            src={require("../../../assets/images/summary/Pending.png")}
                            alt={"Pending"}
                          />
                        </i>
                        <PorscheFont className="heading-text">
                        {t("Pending")}
                        </PorscheFont>
                      </div>
                    ) : (
                      <div className="order-status-container">
                        <i className="image-wrapper">
                          <img
                            src={require("../../../assets/images/summary/Completed.png")}
                            alt={"completed"}
                          />
                        </i>
                        <PorscheFont className="heading-text">
                        {t("Completed")}
                        </PorscheFont>
                      </div>
                    )}
                  </li>
                  <li className="list-item">
                    <PorscheFont className="print-list-item-text order-processing-key">
                      1st order check
                    </PorscheFont>
                    <div>
                      <PorscheFont className="print-list-item-text">
                        {showDashToValue(OrderApprovalData.orderCheckOneBy)}
                      </PorscheFont>
                      <PorscheFont className="print-list-item-text light">
                        {formatDate(OrderApprovalData?.orderCheckOneDate)}
                      </PorscheFont>
                    </div>
                  </li>
                  <li className="list-item">
                    <PorscheFont className="print-list-item-text order-processing-key">
                    {t("2nd Order Check")}
                    </PorscheFont>
                    <div>
                      <PorscheFont className="print-list-item-text">
                        {showDashToValue(OrderApprovalData.orderCheckTwoBy)}
                      </PorscheFont>
                      <PorscheFont className="print-list-item-text light">
                        {formatDate(OrderApprovalData?.orderCheckTwoDate)}
                      </PorscheFont>
                    </div>
                  </li>
                  {order?.deliveryDetails?.exportDeal ? (
                    <>
                      <li className="list-item">
                        <PorscheFont className="print-list-item-text order-processing-key ">
                          Export
                        </PorscheFont>
                        <div>
                          <PorscheFont className="print-list-item-text">
                            {showDashToValue(OrderApprovalData?.orderExportBy)}
                          </PorscheFont>
                          <PorscheFont className="print-list-item-text light">
                            {formatDate(OrderApprovalData?.orderExportDate)}
                          </PorscheFont>
                        </div>
                      </li>
                      <li className="list-item ">
                        <PorscheFont
                          className={`print-list-item-text  ${
                            OrderApprovalData?.orderDiscountBy && "light"
                          }  order-processing-key`}
                        >
                          {t("Discount")}
                        </PorscheFont>
                        <div>
                          <PorscheFont className="print-list-item-text">
                            {OrderApprovalData?.orderDiscountBy === "-"
                              ? ""
                              : showDashToValue(
                                  OrderApprovalData?.orderDiscountBy
                                )}
                          </PorscheFont>
                          <PorscheFont className="print-list-item-text light">
                            {OrderApprovalData?.orderDiscountDate === "-"
                              ? ""
                              : formatDate(
                                  OrderApprovalData?.orderDiscountDate
                                )}
                          </PorscheFont>
                        </div>
                      </li>
                      <li className="list-item">
                        <PorscheFont
                          className={`print-list-item-text  ${
                            OrderApprovalData?.orderOthersBy && "light"
                          }  order-processing-key`}
                        >
                          {t("Others")}
                        </PorscheFont>
                        <div>
                          <PorscheFont className="print-list-item-text">
                            {OrderApprovalData?.orderOthersBy === "-"
                              ? ""
                              : showDashToValue(
                                  OrderApprovalData?.orderOthersBy
                                )}
                          </PorscheFont>
                          <PorscheFont className="print-list-item-text light">
                            {OrderApprovalData?.orderOthersDate === "-"
                              ? ""
                              : formatDate(OrderApprovalData?.orderOthersDate)}
                          </PorscheFont>
                        </div>
                      </li>
                    </>
                  ) : null}
                </ul>
              </div>
              <div className="pflex-order-list">
                <ul className="ul-order-list">
                  <li
                    className={`list-item ${
                      !orderFlags?.dispatchOrderProcessingFlag
                        ? "order-pending"
                        : "order-completed"
                    }`}
                  >
                    <PorscheFont className="heading-text">
                      {t("Dispatch Order Processing")}
                    </PorscheFont>
                    {orderFlags?.dispatchOrderProcessingFlag ? (
                      <div className="order-status-container">
                        <i className="image-wrapper">
                          <img
                            src={require("../../../assets/images/summary/Completed.png")}
                            alt={"Completed"}
                          />
                        </i>
                        <PorscheFont className="heading-text">
                        {t("Completed")}
                        </PorscheFont>
                      </div>
                    ) : (
                      <div className="order-status-container">
                        <i className="image-wrapper">
                          <img
                            src={require("../../../assets/images/summary/Pending.png")}
                            alt={"Pending"}
                          />
                        </i>
                        <PorscheFont className="heading-text">
                        {t("Pending")}
                        </PorscheFont>
                      </div>
                    )}
                  </li>
                  <li className="list-item">
                    <PorscheFont className="print-list-item-text order-processing-key">
                    {t("Order registration")}
                    </PorscheFont>
                    <div>
                      <PorscheFont className="print-list-item-text">
                        {showDashToValue(OrderApprovalData.orderRegistrationBy)}
                      </PorscheFont>
                      <PorscheFont className="print-list-item-text light">
                        {formatDate(OrderApprovalData?.orderRegistrationDate)}
                      </PorscheFont>
                    </div>
                  </li>
                  <li className="list-item">
                    <PorscheFont className="print-list-item-text order-processing-key">
                    {t("Customer delivery")}
                    </PorscheFont>
                    <div>
                      <PorscheFont className="print-list-item-text">
                        {showDashToValue(OrderApprovalData?.customerDeliveryBy)}
                      </PorscheFont>
                      <PorscheFont className="print-list-item-text light">
                        {formatDate(OrderApprovalData?.customerDeliveryDate)}
                      </PorscheFont>
                    </div>
                  </li>
                  <li className="list-item">
                    <PorscheFont className="print-list-item-text order-processing-key">
                    {t("Recalculation")}
                    </PorscheFont>
                    <div>
                      <PorscheFont className="print-list-item-text">
                        {showDashToValue(OrderApprovalData?.recalculationBy)}
                      </PorscheFont>
                      <PorscheFont className="print-list-item-text light">
                        {formatDate(OrderApprovalData?.recalculationDate)}
                      </PorscheFont>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="pflex-order-list">
                <ul className="ul-order-list">
                  <li
                    className={`list-item ${
                      !orderFlags?.orderCommissionApprovalFlag
                        ? "order-pending"
                        : "order-completed"
                    }`}
                  >
                    <PorscheFont className="heading-text">
                    {t('Commission approval')}
                    </PorscheFont>
                    {orderFlags?.orderCommissionApprovalFlag ? (
                      <div className="order-status-container">
                        <i className="image-wrapper">
                          <img
                            src={require("../../../assets/images/summary/Completed.png")}
                            alt={"Completed"}
                          />
                        </i>
                        <PorscheFont className="heading-text">
                        {t("Completed")}
                        </PorscheFont>
                      </div>
                    ) : (
                      <div className="order-status-container">
                        <i className="image-wrapper">
                          <img
                            src={require("../../../assets/images/summary/Pending.png")}
                            alt={"Pending"}
                          />
                        </i>
                        <PorscheFont className="heading-text">
                        {t("Pending")}
                        </PorscheFont>
                      </div>
                    )}
                  </li>
                  <li className="list-item">
                    <PorscheFont className="print-list-item-text order-processing-key">
                      1st order check
                    </PorscheFont>
                    <div>
                      <PorscheFont className="print-list-item-text">
                        {showDashToValue(
                          OrderApprovalData?.commissionCheckOneBy
                        )}
                      </PorscheFont>
                      <PorscheFont className="print-list-item-text light">
                        {formatDate(OrderApprovalData?.commissionCheckOneDate)}
                      </PorscheFont>
                    </div>
                  </li>
                  <li className="list-item">
                    <PorscheFont className="print-list-item-text order-processing-key">
                    {t("2nd Order Check")}
                    </PorscheFont>
                    <div>
                      <PorscheFont className="print-list-item-text">
                        {showDashToValue(
                          OrderApprovalData?.commissionCheckTwoBy
                        )}
                      </PorscheFont>
                      <PorscheFont className="print-list-item-text light">
                        {formatDate(OrderApprovalData?.commissionCheckTwoDate)}
                      </PorscheFont>
                    </div>
                  </li>
                  {orderFlags?.orderFixedCommissionFlag ? (
                    <li className="list-item">
                      <PorscheFont className="print-list-item-text light order-processing-key">
                        Fixed commission
                      </PorscheFont>
                      <div>
                        <PorscheFont className="print-list-item-text">
                          {showDashToValue(
                            OrderApprovalData?.fixedCommissionBy
                          )}
                        </PorscheFont>
                        <PorscheFont className="print-list-item-text light">
                          {formatDate(OrderApprovalData?.fixedCommissionDate)}
                        </PorscheFont>
                      </div>
                    </li>
                  ) : null}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
