import { Stack } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { PorscheFont } from "../../../assets/styles/CustomStyles";
import { OrderContext } from "../../../contexts/OrderContext";
import { getAmountNet } from "../../../helpers/calculations";
import {
  AdditionalCostsPriceDTO,
  CalculationDraftDTO,
  CommissionSharingDTO,
  DiscountNetPriceDTO,
  KickbackNetPriceDTO,
  TradeInPriceDTO,
  VehiclePriceDTO,
} from "../../../interfaces/Calculation";
import { MarginsDTO } from "../../../interfaces/GeneralData";
import { OrderDTO, SaveAsDraftDTO } from "../../../interfaces/Order";
import { UpsaleDetailsDTO, UpsaleItemDTO } from "../../../interfaces/UpsaleNew";
import { GetRvm } from "../../../interfaces/Variables";
import {
  formatCurrencyWithNoUnit,
  getPayementTypeName,
  getWorkshopServiceName,
  negativeValueHandler,
  showDashToValue,
} from "../../../mock/helper";
import { type } from "../../../services/Constants";
import {
  dcsGetGeneralTabs,
  dcsgetOrderDetails,
  dcsgetOrderSummary,
  dcsgetRvm
} from "../../../services/data/Helpers";
import getSessionStorage from "../../../helpers/getSessionStorage";
import { Constant } from "../../../configs/Constants";
import draftValueHandler from "../../../helpers/sessionDraftValueHandler";

export const DemoCarPrint = React.forwardRef((props, ref: any) => {
  const orderContext = useContext(OrderContext);
  const orderValues = getSessionStorage(Constant.SingleOrdDetail);

  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [orderData, setOrderData] = useState<CalculationDraftDTO>(
    {} as CalculationDraftDTO
  );
  const [orderResult, setOrderResult] = useState<SaveAsDraftDTO>(
    {} as SaveAsDraftDTO
  );
  const [order, setOrder] = useState<OrderDTO>({} as OrderDTO);
  const [vehiclePrices, setVehiclePrices] = useState<Array<VehiclePriceDTO>>(
    []
  );
  const [margins, setMargins] = useState<Array<MarginsDTO>>([]);
  const [purchaseMargin, setPurchaseMargin] = useState(0.0);
  const [category, setCategory] = useState("");
  const [vpPercent, setVpPercent] = useState(0.0);
  const [parts, setParts] = useState<Array<UpsaleItemDTO>>([]);
  const [rvm, setRvm] = useState<GetRvm>({} as GetRvm);
  const [recalculationOrderData, setRecalculationOrderData] =
    useState<CalculationDraftDTO>({} as CalculationDraftDTO);
  const [recalVehiclePrices, setRecalVehiclePrices] =
    useState<Array<VehiclePriceDTO | null>>();
  const [recalDiscountNet, setRecalDiscountNet] =
    useState<Array<DiscountNetPriceDTO | null>>();
  const [recalKickbat, setRecalKickbat] =
    useState<Array<KickbackNetPriceDTO | null>>();
  const [recalAdditionalCost, setRecalAdditionalCost] =
    useState<Array<AdditionalCostsPriceDTO | null>>();
  const [recalTrandInPrices, setRecalTradeInPrices] =
    useState<Array<TradeInPriceDTO | null>>();
  const [recalCommissionShares, setRecalCommissionShares] =
    useState<Array<CommissionSharingDTO | null>>();
  const [tequipmentCommission, setTequipmentCommission] = useState(false);
  const mode = orderValues?.id ? orderValues?.id+'' :'' ;
  const draft = draftValueHandler(orderValues?.draft)

  const [upsalseTotalCommission, setUpsaleTotalCommission] = useState(0)

  const PDSTEQcommissionCalculation  = (upsItem:UpsaleItemDTO[])=>{
    let totalCommission = 0
upsItem.forEach((item)=>{
  if(item.commission>0){

    totalCommission = totalCommission + item.commission
  }
})
setUpsaleTotalCommission(totalCommission)
  }

  useEffect(() => {
    const init = async () => {
      const apiResponse =
        draft === "true"
          ? await dcsgetOrderDetails(true, parseInt(mode))
          : await dcsgetOrderSummary(parseInt(mode));

          if(apiResponse.status===type.SUCCESS){
            const upsaleDetails = apiResponse?.response.upsale  as UpsaleDetailsDTO
            PDSTEQcommissionCalculation(upsaleDetails.upsaleItems)
      setOrderData(apiResponse.response?.calculation);
      setOrder(apiResponse.response?.order);
      setTequipmentCommission(
      apiResponse.response?.tequipmentCommission
      );
      const rvmApiRes = await dcsgetRvm();
      let rvmData: Array<GetRvm> = [] 
      if(rvmApiRes.status===type.SUCCESS){
        rvmData=rvmApiRes.response
      }
      const rvm = rvmData?.filter(
        (x) =>
          x.seriesDTO.id ===
          apiResponse.response?.order.vehicleDetails.series
            .id
      );
      if (rvm.length > 0 && rvm[0].grossProfit === true) {
        setRvm(rvm[0]);
      }
      //Upsale details
      const workshop =apiResponse.response
        ?.workshopCosts as UpsaleDetailsDTO;
      if (workshop?.upsaleItems?.length > 0) {
        setParts(parts);
      }
    }
  }


    init();
  }, []);

  useEffect(() => {
    const init = async () => {
      const apiResponse = await dcsGetGeneralTabs()
      if(apiResponse.status===type.SUCCESS){
        const general = apiResponse.response;
        if (general?.margins) {
          setMargins(general.margins);
          let mrgs = general.margins as Array<MarginsDTO>;
          const mrg = mrgs.filter(
            (x) => x.categoryType === "DemoCar" && x.marginType === "Commission"
          );
          if (mrg.length > 0) {
            setPurchaseMargin(mrg[0].value);
          }
        }
      }
     
    };
    init();
  }, []);

  useEffect(() => {
    const init = async () => {

      const apiResponse = draft === "true"
      ? await dcsgetOrderDetails(true, parseInt(mode))
      : await dcsgetOrderSummary(parseInt(mode));

      if(apiResponse.status===type.SUCCESS){
        const preCalculationData = apiResponse.response?.calculation as CalculationDraftDTO;
      const recalCulationdata = apiResponse.response?.reCalculation as CalculationDraftDTO;
      setOrderData(preCalculationData);

      const rearrangedVehiclePrices = preCalculationData?.vehiclePrices
        ?.map((item) => {
          const foundItem = recalCulationdata?.vehiclePrices?.find(
            (element) => element.displayLabel === item.displayLabel
          );
          return foundItem ? { ...foundItem } : null;
        })
        .filter((item) => item !== null);
      rearrangedVehiclePrices && setRecalVehiclePrices(rearrangedVehiclePrices);
      setRecalculationOrderData(
        apiResponse.response?.reCalculation
      );

      const rearrangedDiscountPrice = preCalculationData?.discountNetPrices
        ?.map((item) => {
          const foundItem = recalCulationdata?.discountNetPrices?.find(
            (element) =>
              element.discountNetPriceType === item.discountNetPriceType
          );
          return foundItem ? { ...foundItem } : null;
        })
        .filter((item) => item !== null);
      setRecalDiscountNet(rearrangedDiscountPrice);

      const rearrangedKickback = preCalculationData?.kickbackNetPrices
        ?.map((item) => {
          const foundItem = recalCulationdata?.kickbackNetPrices?.find(
            (element) =>
              element.kickbackNetPriceType === item.kickbackNetPriceType
          );
          return foundItem ? { ...foundItem } : null;
        })
        .filter((item) => item !== null);
      setRecalKickbat(rearrangedKickback);

      const rearrangedAdditionalDiscount =
        preCalculationData?.additionalCostsPrices
          ?.map((item) => {
            const foundItem = recalCulationdata?.additionalCostsPrices?.find(
              (element) => element.type === item.type
            );
            return foundItem ? { ...foundItem } : null;
          })
          .filter((item) => item !== null);
      setRecalAdditionalCost(rearrangedAdditionalDiscount);

      const recalTradeInPrices = preCalculationData?.tradeInPrices
      ?.map((item) => {
        const foundItem = recalCulationdata?.tradeInPrices?.find(
          (element) => element.tradeInColumn === item.tradeInColumn
        );
        return foundItem ? { ...foundItem } : null;
      })
      .filter((item) => item !== null);
      setRecalTradeInPrices(recalTradeInPrices);

      // const recalTradeInPrices = preCalculationData?.tradeInPrices
      //   ?.map((item) => {
      //     const foundItem = recalCulationdata?.tradeInPrices?.find(
      //       (element) => element.displayLabel === item.displayLabel
      //     );
      //     return foundItem ? { ...foundItem } : null;
      //   })
      //   .filter((item) => item !== null);
      // const findind = preCalculationData?.tradeInPrices?.findIndex(
      //   (item) => item.tradeInColumn === "ProfitBooking"
      // );
      // recalCulationdata?.tradeInPrices &&
      //   findind &&
      //   recalTradeInPrices &&
      //   recalTradeInPrices.splice(
      //     findind,
      //     0,
      //     recalCulationdata?.tradeInPrices[4]
      //   );
      // // recalTradeInPrices && recalCulationdata && recalCulationdata?.tradeInPrices && recalCulationdata?.tradeInPrices[4] &&   setRecalTradeInPrices([...recalTradeInPrices, recalCulationdata?.tradeInPrices[-1]])
      // setRecalTradeInPrices(recalTradeInPrices);

      const recalCommissionshare = preCalculationData?.commissionSharing
        ?.map((item) => {
          const foundItem = recalCulationdata?.commissionSharing?.find(
            (element) => element.sellerNames.id === item.sellerNames.id
          );
          return foundItem ? { ...foundItem } : null;
        })
        .filter((item) => item !== null);
      setRecalCommissionShares(recalCommissionshare);

      setOrder(apiResponse.response?.order);
      const rvmResponse = await dcsgetRvm()
      if(rvmResponse.status===type.SUCCESS){
        const rvmData = rvmResponse.response as Array<GetRvm>;
        const rvm = rvmData?.filter(
          (x) =>
            x?.seriesDTO?.id ===
           apiResponse.response?.order?.vehicleDetails
              ?.series?.id
        );
        if (rvm?.length > 0 && rvm[0]?.grossProfit === true) {
          setRvm(rvm[0]);
        }
      }
      

      //Upsale details
      const workshop = apiResponse.response?.workshopCosts as UpsaleDetailsDTO;
      if (workshop?.upsaleItems?.length > 0) {
        setParts(parts);
      }
    };
      }
     
    
    init();
  }, []);
  const formateDate = (strDate: string | undefined): string => {
    if (!strDate) return "";
    const date = new Date(strDate).toISOString().split("T")[0];
    const formatDate = date.toString().split("-");
    return `${formatDate[2]}.${formatDate[1]}.${formatDate[0]}`;
  };

  const formatMonthYear = (strDate: string | undefined): string => {
    if (!strDate) return "";
    const date = new Date(strDate);

    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }
  
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
    };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);
    return formattedDate;
  };
  

  const printGetAmountNet = (
    item: UpsaleItemDTO,
    service: boolean,
    i: number
  ) => {
    if (service) {
      if (
        item.serviceItems &&
        item.serviceItems.length > 0 &&
        item.serviceItems[i]
      ) {
        if (item.serviceItems[i].customerShare > 0) {
          const ret = item.serviceItems[i].services.priceBeforeCustomerNet
            ? item.serviceItems[i].services.priceBeforeCustomerNet
            : 0.0;
          if (item.partType === "part") {
            if (item.serviceItems[i].timeUnits !== undefined) {
              return (ret * item?.serviceItems?.[i].timeUnits!) / 100;
            } else {
              return 0.0;
            }
          } else {
            return ret;
          }
        } else {
          const ret1 = item.serviceItems[i].services.priceInternal
            ? item.serviceItems[i].services.priceInternal
            : 0.0;
          if (item.partType === "part") {
            if (item.serviceItems[i].timeUnits !== undefined) {
              return (ret1 * item?.serviceItems?.[i].timeUnits!) / 100;
            } else {
              return 0.0;
            }
          } else {
            return ret1;
          }
        }
      } else {
        return 0.0;
      }
    } else {
      if (item.customerShare > 0) {
        return item.part.priceBeforeCustomerNet
          ? item.part.priceBeforeCustomerNet
          : 0.0;
      } else {
        return item.part.priceInternal ? item.part.priceInternal : 0.0;
      }
    }
  };

  const getPercent = (
    item: UpsaleItemDTO,
    service: boolean,
    i: number,
    calType: string
  ) => {
    let disPercent = 0.0;
    let dis = 0.0;
    if (service) {
      const net = getAmountNet(item, service, i);
      if (
        item.serviceItems &&
        item.serviceItems.length > 0 &&
        item.serviceItems[i]
      ) {
        dis = net - item.serviceItems[i].customerShare;
      }
      // if (dis > 0 ) {
      //   disPercent =  (dis / net) * 100;
      // }
    } else {
      const net = getAmountNet(item, service, i);
      dis = net - item.customerShare;
    }
    if (
      calType === "re" &&
      recalculationOrderData?.vehiclePrices &&
      recalculationOrderData?.vehiclePrices[0]?.amountNet &&
      recalculationOrderData?.vehiclePrices[0]?.amountNet > 0
    ) {
      disPercent =
        (dis / recalculationOrderData?.vehiclePrices[0]?.amountNet!) * 100;
    }

    if (
      calType === "pre" &&
      orderData?.vehiclePrices &&
      orderData?.vehiclePrices[0]?.amountNet &&
      orderData?.vehiclePrices[0].amountNet > 0
    ) {
      disPercent = (dis / orderData?.vehiclePrices[0].amountNet) * 100;
    }

    return disPercent;
  };

  const getDeviationOfVehicle = (label: string | undefined): number => {
    let rdpc = 0.0;
    let dpc = 0.0;
    dpc =
      orderData &&
      orderData?.vehiclePrices &&
      orderData?.vehiclePrices[0]?.amountNet > 0
        ? (orderData?.vehiclePrices[3]?.amountNet /
            orderData?.vehiclePrices[0]?.amountNet) *
          100
        : 0.0;
    rdpc =
      recalculationOrderData &&
      recalculationOrderData?.vehiclePrices &&
      recalculationOrderData?.vehiclePrices[0]?.amountNet
        ? (recalculationOrderData?.vehiclePrices[3].amountNet /
            recalculationOrderData?.vehiclePrices[0].amountNet) *
          100
        : 0.0;
    if (label === "RRP") {
      return orderData &&
        orderData?.vehiclePrices &&
        recalculationOrderData &&
        recalculationOrderData?.vehiclePrices
        ? recalculationOrderData?.vehiclePrices[0]?.amountNet -
            orderData?.vehiclePrices[0].amountNet
        : 0.0;
    } else if (label === "Selling price") {
      return orderData &&
        orderData?.vehiclePrices &&
        recalculationOrderData &&
        recalculationOrderData?.vehiclePrices
        ? recalculationOrderData?.vehiclePrices[1]?.amountNet -
            orderData?.vehiclePrices[1].amountNet
        : 0.0;
    } else if (label === "Purchase price") {
      return orderData &&
        orderData?.vehiclePrices &&
        recalculationOrderData &&
        recalculationOrderData?.vehiclePrices
        ? recalculationOrderData?.vehiclePrices[2]?.amountNet -
            orderData?.vehiclePrices[2].amountNet
        : 0.0;
    } else if (label === "Discount") {
      return rdpc - dpc;
    }
    return 0.0;
  };

  const calculateValueOfTradeInDiscount = (
    order: OrderDTO,
    type: string
  ): number | null => {
    let isPreCal = type === "precal";
    if (
      order?.tradeInDetails?.tradeInType === "Leasing_Redemption" ||
      order?.tradeInDetails?.tradeInType === "Financing_Redemption_Regular" ||
      order?.tradeInDetails?.tradeInType === "Financing_Redemption_Differential"
    ) {
      const tradeInAmountNet = isPreCal
        ? orderData?.tradeInPrices?.[3]?.amountNet || 0
        : recalTrandInPrices?.[3]?.amountNet || 0;
      const vehicleAmountNet = isPreCal
        ? orderData?.vehiclePrices?.[0]?.amountNet || 1
        : recalVehiclePrices?.[0]?.amountNet || 1;

      return (tradeInAmountNet / vehicleAmountNet) * -1 * 100 || 0.0;
    }
    return null;
  };

  const getSharePcPercent = () => {
    let rtpc = 0.0;
    let tpc = 0.0;
    if (
      recalculationOrderData?.tradeInPrices &&
      recalculationOrderData?.tradeInPrices.length > 0 &&
      orderData?.tradeInPrices &&
      orderData?.tradeInPrices.length > 0
    ) {
      if (
        recalculationOrderData?.tradeInPrices &&
        recalculationOrderData?.tradeInPrices[2]?.amountNet! < 0 &&
        recalculationOrderData?.vehiclePrices &&
        recalculationOrderData?.vehiclePrices[0]?.amountNet! > 0
      ) {
        rtpc =
          (recalculationOrderData?.tradeInPrices[3]?.amountNet! /
            recalculationOrderData?.vehiclePrices[0]?.amountNet!) *
          -1 *
          100;
      }

      if (
        orderData?.tradeInPrices[2]?.amountNet < 0 &&
        orderData?.vehiclePrices &&
        orderData?.vehiclePrices[0]?.amountNet > 0
      ) {
        tpc =
          (orderData?.tradeInPrices[3]?.amountNet /
            orderData?.vehiclePrices[0]?.amountNet) *
          -1 *
          100;
      }
    }
    return rtpc - tpc;
  };

  const getExternalDicountPercent = () => {
    let redpc = 0.0;
    let edpc = 0.0;

    if (
      vehiclePrices &&
      vehiclePrices.length > 0 &&
      orderData?.calculationHeader
    ) {
      edpc =
        vehiclePrices[0].amountNet !== undefined &&
        vehiclePrices[0].amountNet > 0
          ? (orderData?.calculationHeader.externalDiscount /
              vehiclePrices[0].amountNet) *
            100
          : 0.0;
    }

    if (
      recalculationOrderData?.vehiclePrices &&
      recalculationOrderData?.vehiclePrices.length > 0 &&
      recalculationOrderData?.calculationHeader
    ) {
      redpc =
        recalculationOrderData?.vehiclePrices[0].amountNet !== undefined &&
        recalculationOrderData?.vehiclePrices[0].amountNet > 0
          ? (recalculationOrderData?.calculationHeader.externalDiscount /
              recalculationOrderData?.vehiclePrices[0].amountNet) *
            100
          : 0.0;
    }
    return redpc - edpc;
  };

  const getInternalDicountPercent = () => {
    let ridpc = 0.0;
    let idpc = 0.0;

    if (
      vehiclePrices &&
      vehiclePrices.length > 0 &&
      orderData?.calculationHeader
    ) {
      idpc =
        vehiclePrices[0].amountNet !== undefined &&
        vehiclePrices[0].amountNet > 0
          ? (orderData?.calculationHeader.internalDiscount /
              vehiclePrices[0].amountNet) *
            100
          : 0.0;
    }

    if (
      recalculationOrderData?.vehiclePrices &&
      recalculationOrderData?.vehiclePrices.length > 0 &&
      recalculationOrderData?.calculationHeader
    ) {
      ridpc =
        recalculationOrderData?.vehiclePrices[0].amountNet !== undefined &&
        recalculationOrderData?.vehiclePrices[0].amountNet > 0
          ? (recalculationOrderData?.calculationHeader.internalDiscount /
              recalculationOrderData?.vehiclePrices[0].amountNet) *
            100
          : 0.0;
    }
    return ridpc - idpc;
  };

  const getGrossProfitPercent = () => {
    let rgppc = 0.0;
    let gppc = 0.0;

    if (
      vehiclePrices &&
      vehiclePrices.length > 0 &&
      orderData?.calculationHeader
    ) {
      gppc =
        vehiclePrices[0].amountNet !== undefined &&
        vehiclePrices[0].amountNet > 0
          ? (orderData?.calculationHeader.grossProfit /
              vehiclePrices[0].amountNet) *
            100
          : 0.0;
    }

    if (
      recalculationOrderData?.vehiclePrices &&
      recalculationOrderData?.vehiclePrices.length > 0 &&
      recalculationOrderData?.calculationHeader
    ) {
      rgppc =
        recalculationOrderData?.vehiclePrices[0].amountNet !== undefined &&
        recalculationOrderData?.vehiclePrices[0].amountNet > 0
          ? (recalculationOrderData?.calculationHeader.grossProfit /
              recalculationOrderData?.vehiclePrices[0].amountNet) *
            100
          : 0.0;
    }
    return rgppc - gppc;
  };

  const checkBoldTextForDeviation = (value: any): string => {
    return value !== 0 ? "table-col-bold text-right" : "table-td";
  };

  return (
    <>
      <div ref={ref}>
        <div className="print-header-container">
          <div className="header-left-side">
            <PorscheFont className="print-heading-name">
              3. {t("Calculation")} {t("Demo Car")}
            </PorscheFont>
            <div className="seller-info-label">
              <table>
                <tr>
                  <td className="table-label-td font-14">Seller</td>
                  <td className="font-14">
                    {showDashToValue(order?.orderCreator?.seller)}
                  </td>
                </tr>
                <tr>
                  <td className="table-label-td font-14">Comm No.</td>
                  <td className="font-14">
                    {showDashToValue(order?.vehicleDetails?.commissionNumber)}
                  </td>
                </tr>
                <tr>
                  <td className="table-label-td font-14">Act No.</td>
                  <td className="font-14">
                    {showDashToValue(order?.vehicleDetails?.actNumber)}
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div className="header-right-side">
            <Stack direction="column" className="right-header-container">
              <PorscheFont className="order-number">
                {t("Order No. 20120230001")}
              </PorscheFont>
              <PorscheFont className="order-date">
                {t("Creation date")}: {formateDate(order?.createdOn)}
              </PorscheFont>
              <PorscheFont className="order-date">
                {t("Last edited")}: {formateDate(order?.updatedOn)}
              </PorscheFont>
            </Stack>
          </div>
        </div>
        <table className="table">
          <thead>
            <tr className="main-heading-row">
              <th colSpan={2}></th>
              <th colSpan={3} className="main-heading">
                <PorscheFont>{t("Precalculation")}</PorscheFont>
              </th>
              <th colSpan={1} className="empty-space"></th>
              <th colSpan={3} className="main-heading">
                <PorscheFont>{t("Recalculation")}</PorscheFont>
              </th>
              <th></th>
            </tr>
          </thead>
          {/* vehiclePrices start */}
          <tbody>
            <tr>
              <td
                colSpan={2}
                className="table-col-bold table-th-border-bottom p-top"
              >
                <PorscheFont>{t("Vehicle Price")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top table-td">
                <PorscheFont>{t("Amount (net)")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top table-td">
                <PorscheFont>{t("Amount (gross)")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top table-td">
                <PorscheFont>{t("Percentage")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top table-td"></td>
              <td className="table-th-border-bottom p-top table-td">
                <PorscheFont>{t("Amount (net)")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top table-td">
                <PorscheFont>{t("Amount (gross)")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top table-td">
                <PorscheFont>{t("Percentage")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top table-td">
                <PorscheFont>{t("Deviation")}</PorscheFont>
              </td>
            </tr>
            {orderData?.vehiclePrices?.map((ele, index) => {
              return (
                <tr key={index}>
                  <td colSpan={2} className="table-td-left">
                    <PorscheFont>{ele.displayLabel}</PorscheFont>
                  </td>
                  <td className="table-td">
                    <PorscheFont>
                      {`${formatCurrencyWithNoUnit(ele.amountNet)} €`}
                    </PorscheFont>
                  </td>
                  <td className="table-td">
                    <PorscheFont>
                      {`${formatCurrencyWithNoUnit(ele.amountGross)} €`}
                    </PorscheFont>
                  </td>
                  <td className="table-col-bold text-right">
                    <PorscheFont>
                      {ele.displayLabel === "Purchase price"
                        ? `${formatCurrencyWithNoUnit(purchaseMargin)}%`
                        : ele.displayLabel === "Discount"
                        ? `${
                            orderData?.vehiclePrices &&
                            formatCurrencyWithNoUnit(
                              orderData?.vehiclePrices[0]?.amountNet > 0
                                ? (orderData?.vehiclePrices[3]?.amountNet /
                                    orderData?.vehiclePrices[0]?.amountNet) *
                                    100
                                : 0.0
                            )
                          }%`
                        : null}
                    </PorscheFont>
                  </td>
                  <td className="table-td"></td>
                  <td className="table-td">
                    <PorscheFont>
                      {recalVehiclePrices &&
                      recalVehiclePrices[index] &&
                      recalVehiclePrices[index]?.amountNet
                        ? `${formatCurrencyWithNoUnit(
                            recalVehiclePrices[index]?.amountNet!
                          )} €`
                        : "0,00 €"}
                    </PorscheFont>
                  </td>
                  <td className="table-td">
                    <PorscheFont>
                      {recalVehiclePrices &&
                      recalVehiclePrices[index]?.amountGross
                        ? `${formatCurrencyWithNoUnit(
                            recalVehiclePrices[index]?.amountGross!
                          )} €`
                        : "0,00 €"}
                    </PorscheFont>
                  </td>
                  <td className="table-col-bold text-right">
                    <PorscheFont>
                      {ele.displayLabel === "Purchase price"
                        ? `${formatCurrencyWithNoUnit(purchaseMargin)}%`
                        : ele.displayLabel === "Discount"
                        ? `${
                            recalculationOrderData?.vehiclePrices &&
                            formatCurrencyWithNoUnit(
                              recalculationOrderData?.vehiclePrices[0]
                                ?.amountNet > 0
                                ? (recalculationOrderData?.vehiclePrices[3]
                                    ?.amountNet /
                                    recalculationOrderData?.vehiclePrices[0]
                                      ?.amountNet) *
                                    100
                                : 0.0
                            )
                          }%`
                        : null}
                    </PorscheFont>
                  </td>
                  <td
                    className={checkBoldTextForDeviation(
                      getDeviationOfVehicle(ele.displayLabel)
                    )}
                  >
                    <PorscheFont>
                      {formatCurrencyWithNoUnit(
                        getDeviationOfVehicle(ele.displayLabel)
                      )}
                      {ele.displayLabel === "Discount" ? " %" : " €"}
                    </PorscheFont>
                  </td>
                </tr>
              );
            })}
          </tbody>
          {/* vehiclePrices end */}

          {/* Information from Online Car sales start */}
          <tbody>
            <tr>
              <td
                colSpan={2}
                className="table-col-bold table-th-border-bottom p-top"
              >
                <PorscheFont>
                  {t("Information from Online Car Sales")}
                </PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top table-td">
                <PorscheFont>{t("Information")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top table-td"></td>
              <td
                colSpan={2}
                className="table-th-border-bottom p-top table-td"
              ></td>
              <td className="table-th-border-bottom p-top table-td">
                <PorscheFont>{t("Information")}</PorscheFont>
              </td>
              <td
                colSpan={2}
                className="table-th-border-bottom p-top table-td"
              ></td>
              <td className="table-th-border-bottom p-top table-td">
                <PorscheFont>{t("Deviation")}</PorscheFont>
              </td>
            </tr>
            <tr>
              <td className="table-td-left">
                <PorscheFont>{t("Standing days")}</PorscheFont>
              </td>
              <td></td>
              <td className="table-td">
                <PorscheFont>
                  {orderData?.onlineCarSaleInfo?.standingDays}
                </PorscheFont>
              </td>
              <td colSpan={3}></td>
              <td className="table-td">
                <PorscheFont>
                  {recalculationOrderData?.onlineCarSaleInfo?.standingDays}
                </PorscheFont>
              </td>
              <td colSpan={2}></td>
              <td
                className={checkBoldTextForDeviation(
                  orderData?.onlineCarSaleInfo?.standingDays &&
                    recalculationOrderData?.onlineCarSaleInfo?.standingDays &&
                    recalculationOrderData?.onlineCarSaleInfo?.standingDays -
                      orderData?.onlineCarSaleInfo?.standingDays
                )}
              >
                <PorscheFont>
                  {orderData?.onlineCarSaleInfo?.standingDays &&
                    recalculationOrderData?.onlineCarSaleInfo?.standingDays &&
                    recalculationOrderData?.onlineCarSaleInfo?.standingDays -
                      orderData?.onlineCarSaleInfo?.standingDays}
                </PorscheFont>
              </td>
            </tr>
            <tr>
              <td className="table-td-left">
                <PorscheFont>{t("Initital registration")}</PorscheFont>
              </td>
              <td></td>
              <td className="table-td">
                <PorscheFont>
                  {formatMonthYear(
                    orderData?.onlineCarSaleInfo?.initialRegistration
                  )}
                </PorscheFont>
              </td>
              <td colSpan={3}></td>
              <td className="table-td">
                <PorscheFont>
                  {formatMonthYear(
                    recalculationOrderData?.onlineCarSaleInfo
                      ?.initialRegistration
                  )}
                </PorscheFont>
              </td>
              <td colSpan={2}></td>
              <td className="table-td"></td>
            </tr>
            <tr>
              <td className="table-td-left">
                <PorscheFont>{t("Mileage")}</PorscheFont>
              </td>
              <td></td>
              <td className="table-td">
                <PorscheFont>
                  {`${orderData?.onlineCarSaleInfo?.mileage} km`}
                </PorscheFont>
              </td>
              <td colSpan={3}></td>
              <td className="table-td">
                <PorscheFont>
                  {`${recalculationOrderData?.onlineCarSaleInfo?.mileage} km`}
                </PorscheFont>
              </td>
              <td colSpan={2}></td>
              <td
                className={checkBoldTextForDeviation(
                  recalculationOrderData?.onlineCarSaleInfo?.mileage! -
                    orderData?.onlineCarSaleInfo?.mileage!
                )}
              >
                {orderData?.onlineCarSaleInfo?.mileage &&
                recalculationOrderData?.onlineCarSaleInfo?.mileage
                  ? `${
                      recalculationOrderData?.onlineCarSaleInfo?.mileage -
                      orderData?.onlineCarSaleInfo?.mileage
                    } km`
                  : "0 km"}
              </td>
            </tr>
            <tr>
              <td className="table-td-left">
                <PorscheFont>{t("Last gross bidding price")}</PorscheFont>
              </td>
              <td></td>
              <td className="table-td">
                <PorscheFont>
                  {orderData?.onlineCarSaleInfo
                    ? `${formatCurrencyWithNoUnit(
                        orderData?.onlineCarSaleInfo?.lastGrossBiddingPrice!
                      )} €`
                    : "0,00 €"}
                </PorscheFont>
              </td>
              <td colSpan={3}></td>
              <td className="table-td">
                <PorscheFont>
                  {recalculationOrderData?.onlineCarSaleInfo
                    ? `${formatCurrencyWithNoUnit(
                        recalculationOrderData?.onlineCarSaleInfo
                          ?.lastGrossBiddingPrice!
                      )} €`
                    : "0,00 €"}
                </PorscheFont>
              </td>
              <td colSpan={2}></td>
              <td
                className={checkBoldTextForDeviation(
                  recalculationOrderData?.onlineCarSaleInfo
                    ?.lastGrossBiddingPrice! -
                    orderData?.onlineCarSaleInfo?.lastGrossBiddingPrice!
                )}
              >
                <PorscheFont>
                  {recalculationOrderData?.onlineCarSaleInfo &&
                  orderData?.onlineCarSaleInfo
                    ? `${formatCurrencyWithNoUnit(
                        recalculationOrderData?.onlineCarSaleInfo
                          ?.lastGrossBiddingPrice! -
                          orderData?.onlineCarSaleInfo?.lastGrossBiddingPrice!
                      )} €`
                    : "0,00 €"}
                </PorscheFont>
              </td>
            </tr>
            <tr>
              <td className="table-td-left">
                <PorscheFont>{t("Comment (Optional)")}</PorscheFont>
              </td>
              <td></td>
              <td className="table-td">
                <PorscheFont>
                  {orderData?.onlineCarSaleInfo?.comments}
                </PorscheFont>
              </td>
              <td colSpan={3}></td>
              <td className="table-td">
                <PorscheFont>
                  {recalculationOrderData?.onlineCarSaleInfo?.comments}
                </PorscheFont>
              </td>
              <td colSpan={2}></td>
              <td className="table-td"></td>
            </tr>
          </tbody>
          {/* Information from Online Car sales End */}

          {/* discountNetPrices start */}
          <tbody>
            <tr>
              <td
                colSpan={2}
                className="table-col-bold table-th-border-bottom p-top-16"
              >
                <PorscheFont>{t("Discount (net)")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top-16 table-td">
                <PorscheFont>{t("Discount in €")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top-16 table-td">
                <PorscheFont>{t("Discount in %")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top-16 table-td"></td>
              <td className="table-th-border-bottom p-top-16 table-td"></td>
              <td className="table-th-border-bottom p-top-16 table-td">
                <PorscheFont>{t("Discount in €")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top-16 table-td">
                <PorscheFont>{t("Discount in %")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top-16 table-td"></td>
              <td className="table-th-border-bottom p-top-16 table-td">
                <PorscheFont>{t("Deviation")}</PorscheFont>
              </td>
            </tr>
            {orderData?.discountNetPrices?.map((ele, index) => {
              return (
                <tr key={index}>
                  <td colSpan={2} className="table-td-left">
                    <PorscheFont>{ele.displayLabel}</PorscheFont>
                  </td>
                  <td className="table-td">
                    <PorscheFont>
                      {`${formatCurrencyWithNoUnit(ele.discountInEuro)} €`}
                    </PorscheFont>
                  </td>
                  <td className="table-td">
                    <PorscheFont>
                      {`${formatCurrencyWithNoUnit(ele.discountInPercent)} %`}
                    </PorscheFont>
                  </td>
                  <td></td>
                  <td></td>
                  <td className="table-td">
                    <PorscheFont>
                      {recalculationOrderData?.discountNetPrices &&
                      recalculationOrderData?.discountNetPrices[index]
                        ?.discountInEuro
                        ? `${formatCurrencyWithNoUnit(
                            recalculationOrderData?.discountNetPrices[index]
                              ?.discountInEuro!
                          )} €`
                        : "0,00 €"}
                    </PorscheFont>
                  </td>
                  <td className="table-td">
                    <PorscheFont>
                      {recalculationOrderData?.discountNetPrices &&
                      recalculationOrderData?.discountNetPrices[index]
                        .discountInPercent
                        ? `${formatCurrencyWithNoUnit(
                            recalculationOrderData?.discountNetPrices[index]
                              .discountInPercent!
                          )} %`
                        : "0,00 %"}
                    </PorscheFont>
                  </td>
                  <td></td>
                  <td
                    className={checkBoldTextForDeviation(
                      orderData?.discountNetPrices &&
                        orderData?.discountNetPrices[index]
                          ?.discountInPercent &&
                        recalculationOrderData?.discountNetPrices &&
                        recalculationOrderData?.discountNetPrices[index]
                          ?.discountInPercent &&
                        recalculationOrderData?.discountNetPrices[index]
                          ?.discountInPercent! -
                          orderData.discountNetPrices[index]?.discountInPercent!
                    )}
                  >
                    <PorscheFont>
                      {orderData?.discountNetPrices &&
                      orderData?.discountNetPrices[index]?.discountInPercent &&
                      recalculationOrderData?.discountNetPrices &&
                      recalculationOrderData?.discountNetPrices[index]
                        ?.discountInPercent
                        ? `${formatCurrencyWithNoUnit(
                            recalculationOrderData?.discountNetPrices[index]
                              ?.discountInPercent -
                              orderData.discountNetPrices[index]
                                .discountInPercent
                          )} %`
                        : "0,00 %"}
                    </PorscheFont>
                  </td>
                </tr>
              );
            })}
            <tr>
              <td className="table-td-left">
                <PorscheFont>{t("Comment (Optional)")}</PorscheFont>
              </td>
              <td></td>
              <td className="table-td">
                <PorscheFont>{orderData?.discountComment}</PorscheFont>
              </td>
              <td colSpan={3}></td>
              <td className="table-td">
                <PorscheFont>
                  {recalculationOrderData?.discountComment}
                </PorscheFont>
              </td>
              <td colSpan={2}></td>
              <td className="table-td"></td>
            </tr>
          </tbody>
          {/* discountNetPrices End */}

          {/* kickbackNetPrices start */}
          <tbody>
            <tr>
              <td
                colSpan={2}
                className="table-col-bold table-th-border-bottom p-top-16"
              >
                <PorscheFont>{t("Kickback (net)")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top-16 table-td">
                <PorscheFont>{t("Kickback in €")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top-16 table-td">
                <PorscheFont>{t("Kickback in %")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top-16 table-td"></td>
              <td className="table-th-border-bottom p-top-16 table-td"></td>
              <td className="table-th-border-bottom p-top-16 table-td">
                <PorscheFont>{t("Kickback in €")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top-16 table-td">
                <PorscheFont>{t("Kickback in %")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top-16 table-td"></td>
              <td className="table-th-border-bottom p-top-16 table-td">
                <PorscheFont>{t("Deviation")}</PorscheFont>
              </td>
            </tr>
            {orderData?.kickbackNetPrices?.map((ele, index) => {
              return (
                <tr key={index}>
                  <td colSpan={2} className="table-td-left">
                    <PorscheFont>{ele.displayLabel}</PorscheFont>
                  </td>
                  <td className="table-td">
                    <PorscheFont>
                      {`${formatCurrencyWithNoUnit(ele.kickbackInEuro)} €`}
                    </PorscheFont>
                  </td>
                  <td className="table-td">
                    <PorscheFont>
                      {`${formatCurrencyWithNoUnit(ele.kickbackInPercent)} %`}
                    </PorscheFont>
                  </td>
                  <td colSpan={2} />
                  <td className="table-td">
                    <PorscheFont>
                      {recalKickbat
                        ? `${formatCurrencyWithNoUnit(
                            recalKickbat[index]?.kickbackInEuro!
                          )} €`
                        : "0,00 €"}
                    </PorscheFont>
                  </td>
                  <td className="table-td">
                    <PorscheFont>
                      {recalKickbat
                        ? `${formatCurrencyWithNoUnit(
                            recalKickbat[index]?.kickbackInPercent!
                          )} %`
                        : "0,00 %"}
                    </PorscheFont>
                  </td>
                  <td />
                  <td
                    className={checkBoldTextForDeviation(
                      recalKickbat
                        ? ele.kickbackInPercent -
                            recalKickbat[index]?.kickbackInPercent!
                        : 0
                    )}
                  >
                    <PorscheFont>
                      {recalKickbat
                        ? `${formatCurrencyWithNoUnit(
                            ele.kickbackInPercent -
                              recalKickbat[index]?.kickbackInPercent!
                          )} %`
                        : "0,00 %"}
                    </PorscheFont>
                  </td>
                </tr>
              );
            })}

            {orderData?.kickbackDescription != "" && (
              <tr>
                <td colSpan={2} className="table-td-left">
                  <PorscheFont>{t("Description (Other)")}</PorscheFont>
                </td>
                <td colSpan={8} className="table-td-left comment-col">
                  <PorscheFont>{orderData?.kickbackDescription}</PorscheFont>
                </td>
              </tr>
            )}
          </tbody>
          {/* kickbackNetPrices end */}

          {/* additionalCostsPrices start */}
          <tbody>
            <tr>
              <td
                colSpan={2}
                className="table-col-bold table-th-border-bottom p-top-16"
              >
                <PorscheFont>{t("Additional costs")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top-16 table-td">
                <PorscheFont>{t("Amount (net)")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top-16 table-td">
                <PorscheFont>{t("Discount in €")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top-16 table-td">
                <PorscheFont>{t("Discount in %")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top-16 table-td"></td>
              <td className="table-th-border-bottom p-top-16 table-td">
                <PorscheFont>{t("Amount (net)")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top-16 table-td">
                <PorscheFont>{t("Discount in €")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top-16 table-td">
                <PorscheFont>{t("Discount in %")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top-16 table-td">
                <PorscheFont>{t("Deviation")}</PorscheFont>
              </td>
            </tr>
            {orderData?.additionalCostsPrices?.map((ele, index) => {
              if (ele.label.length > 0)
                return (
                  <React.Fragment>
                    <tr key={index}>
                      <td colSpan={2} className="table-td-left">
                        <PorscheFont>{ele.label}</PorscheFont>
                      </td>
                      <td className="table-td">
                        <PorscheFont>
                          {`${formatCurrencyWithNoUnit(ele.amountNet)} €`}
                        </PorscheFont>
                      </td>
                      <td className="table-td">
                        <PorscheFont>
                          {`${formatCurrencyWithNoUnit(ele.discountInEuro)} €`}
                        </PorscheFont>
                      </td>
                      <td className="table-td">
                        <PorscheFont>
                          {`${formatCurrencyWithNoUnit(
                            ele.discountInPercent
                          )} %`}
                        </PorscheFont>
                      </td>
                      <td />
                      <td className="table-td">
                        <PorscheFont>
                          {recalculationOrderData?.additionalCostsPrices
                            ? `${formatCurrencyWithNoUnit(
                                recalculationOrderData?.additionalCostsPrices[
                                  index
                                ]?.amountNet!
                              )} €`
                            : "0,00 €"}
                        </PorscheFont>
                      </td>
                      <td className="table-td">
                        <PorscheFont>
                          {recalculationOrderData?.additionalCostsPrices
                            ? `${formatCurrencyWithNoUnit(
                                recalculationOrderData?.additionalCostsPrices[
                                  index
                                ]?.discountInEuro
                              )} €`
                            : "0,00 €"}
                        </PorscheFont>
                      </td>
                      <td className="table-td">
                        <PorscheFont>
                          {recalculationOrderData?.additionalCostsPrices &&
                          recalculationOrderData?.additionalCostsPrices[index]
                            ?.discountInPercent
                            ? `${formatCurrencyWithNoUnit(
                                recalculationOrderData?.additionalCostsPrices[
                                  index
                                ]?.discountInPercent
                              )} %`
                            : "0,00 %"}
                        </PorscheFont>
                      </td>
                      <td
                        className={checkBoldTextForDeviation(
                          recalculationOrderData?.additionalCostsPrices
                            ? recalculationOrderData?.additionalCostsPrices[
                                index
                              ]?.discountInPercent - ele.discountInPercent
                            : 0
                        )}
                      >
                        <PorscheFont>
                          {recalculationOrderData?.additionalCostsPrices
                            ? `${formatCurrencyWithNoUnit(
                                recalculationOrderData?.additionalCostsPrices[
                                  index
                                ]?.discountInPercent - ele.discountInPercent
                              )} %`
                            : "0,00 %"}
                        </PorscheFont>
                      </td>
                    </tr>
                  </React.Fragment>
                );
            })}
            {orderData?.additionalCostPriceComment?.length ? (
              <tr>
                <td colSpan={2} className="table-td-left">
                  <PorscheFont>{t("Description (Other)")}</PorscheFont>
                </td>
                <td colSpan={8} className="table-td-left comment-col">
                  <PorscheFont>
                    {orderData?.additionalCostPriceComment}
                  </PorscheFont>
                </td>
              </tr>
            ) : null}
          </tbody>
          {/* additionalCostsPrices end */}

          {/* Workshop costs start */}
          <tbody>
            <tr>
              <td
                colSpan={2}
                className="table-col-bold table-th-border-bottom p-top-16"
              >
                <PorscheFont>{t("Workshop costs")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top-16 table-td">
                <PorscheFont>{t("Amount (net)")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top-16 table-td">
                <PorscheFont>{t("Discount in €")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top-16 table-td">
                <PorscheFont>{t("Discount in %")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top-16 table-td"></td>
              <td className="table-th-border-bottom p-top-16 table-td">
                <PorscheFont>{t("Amount (net)")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top-16 table-td">
                <PorscheFont>{t("Discount in €")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top-16 table-td">
                <PorscheFont>{t("Discount in %")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top-16 table-td">
                <PorscheFont>{t("Deviation")}</PorscheFont>
              </td>
            </tr>
            {orderData?.workshopCosts?.upsaleItems?.map((ele, index) => {
              if (ele.partType === "tire") {
                return (
                  <React.Fragment key={index}>
                    <tr>
                      <td colSpan={2} className="table-td-left">
                        <PorscheFont>{t("Complete Winter Tires")}</PorscheFont>
                      </td>
                      <td className="table-td">
                        <PorscheFont>
                          {`${formatCurrencyWithNoUnit(
                            getAmountNet(ele, false, 0)
                          )} €`}
                        </PorscheFont>
                      </td>
                      <td className="table-td">
                        <PorscheFont>
                          {formatCurrencyWithNoUnit(ele.discount)} {" €"}
                        </PorscheFont>
                      </td>
                      <td className="table-td">
                        <PorscheFont>
                          {formatCurrencyWithNoUnit(
                            getPercent(ele, false, 0, "pre")
                          )}
                          {" %"} {/* need to check */}
                        </PorscheFont>
                      </td>
                      <td />
                      <td className="table-td">
                        <PorscheFont>
                          {recalculationOrderData?.workshopCosts
                            ? `${formatCurrencyWithNoUnit(
                                getAmountNet(
                                  recalculationOrderData?.workshopCosts
                                    ?.upsaleItems[index],
                                  false,
                                  0
                                )
                              )} €`
                            : "0,00 €"}
                        </PorscheFont>
                      </td>
                      <td className="table-td">
                        <PorscheFont>
                          {recalculationOrderData?.workshopCosts?.upsaleItems[
                            index
                          ]
                            ? formatCurrencyWithNoUnit(
                                getAmountNet(
                                  recalculationOrderData?.workshopCosts
                                    ?.upsaleItems[index],
                                  false,
                                  0
                                ) -
                                  recalculationOrderData?.workshopCosts
                                    ?.upsaleItems[index]?.customerShare
                              )
                            : "0,00"}
                        </PorscheFont>
                      </td>
                      <td className="table-td">
                        <PorscheFont>
                          {recalculationOrderData?.workshopCosts?.upsaleItems[
                            index
                          ]
                            ? formatCurrencyWithNoUnit(
                                getPercent(
                                  recalculationOrderData?.workshopCosts
                                    ?.upsaleItems[index],
                                  false,
                                  0,
                                  "re"
                                )
                              )
                            : "0.00 €"}
                        </PorscheFont>
                      </td>
                      <td
                        className={checkBoldTextForDeviation(
                          recalculationOrderData?.workshopCosts?.upsaleItems[
                            index
                          ]
                            ? getPercent(
                                recalculationOrderData?.workshopCosts
                                  ?.upsaleItems[index],
                                false,
                                0,
                                "re"
                              ) - getPercent(ele, false, 0, "pre")
                            : "0.00 €"
                        )}
                      >
                        <PorscheFont>
                          {recalculationOrderData?.workshopCosts?.upsaleItems[
                            index
                          ]
                            ? formatCurrencyWithNoUnit(
                                getPercent(
                                  recalculationOrderData?.workshopCosts
                                    ?.upsaleItems[index],
                                  false,
                                  0,
                                  "re"
                                ) - getPercent(ele, false, 0, "pre")
                              )
                            : "0.00 €"}
                          {" %"}
                        </PorscheFont>
                      </td>
                    </tr>
                    {ele.serviceItems.map((service, j) => {
                      if (service.services !== null) {
                        return (
                          <tr>
                            <td colSpan={2} className="table-td-left">
                              <PorscheFont>
                                {getWorkshopServiceName(
                                  ele.serviceItems[j].services
                                    .serviceNameUpsale,
                                  j === 0 ? "winter" : "summer"
                                )}
                              </PorscheFont>
                            </td>
                            <td className="table-td">
                              <PorscheFont>
                                {`${formatCurrencyWithNoUnit(
                                  getAmountNet(ele, true, j)
                                )} €`}
                              </PorscheFont>
                            </td>
                            <td className="table-td">
                              <PorscheFont>
                                {formatCurrencyWithNoUnit(
                                  getAmountNet(ele, true, j) > 0
                                    ? getAmountNet(ele, true, j) -
                                        service.customerShare
                                    : 0.0
                                )}
                                {" €"}
                              </PorscheFont>
                            </td>
                            <td className="table-td">
                              <PorscheFont>
                                {formatCurrencyWithNoUnit(
                                  getPercent(ele, true, j, "pre")
                                )}
                                {" %"}
                              </PorscheFont>
                            </td>
                            <td />
                            <td className="table-td">
                              <PorscheFont>
                                {`${formatCurrencyWithNoUnit(
                                  getAmountNet(ele, true, j)
                                )} €`}
                              </PorscheFont>
                            </td>
                            <td className="table-td">
                              <PorscheFont>
                                {`${formatCurrencyWithNoUnit(
                                  getAmountNet(ele, true, j) > 0
                                    ? getAmountNet(ele, true, j) -
                                        service.customerShare
                                    : 0.0
                                )} €`}
                              </PorscheFont>
                            </td>
                            <td className="table-td">
                              <PorscheFont>
                                {recalculationOrderData?.workshopCosts
                                  ?.upsaleItems[index] &&
                                recalculationOrderData?.vehiclePrices &&
                                recalculationOrderData?.vehiclePrices.length > 0
                                  ? formatCurrencyWithNoUnit(
                                      ((getAmountNet(
                                        recalculationOrderData?.workshopCosts
                                          ?.upsaleItems[index],
                                        true,
                                        j
                                      ) -
                                        recalculationOrderData?.workshopCosts
                                          ?.upsaleItems[index].serviceItems[j]
                                          .customerShare) /
                                        recalculationOrderData?.vehiclePrices[0]
                                          .amountNet) *
                                        100
                                    )
                                  : "0,00"}
                                {" %"}
                              </PorscheFont>
                            </td>
                            <td
                              className={checkBoldTextForDeviation(
                                recalculationOrderData?.workshopCosts
                                  ?.upsaleItems[index]
                                  ? getPercent(
                                      recalculationOrderData?.workshopCosts
                                        ?.upsaleItems[index],
                                      true,
                                      j,
                                      "re"
                                    ) - getPercent(ele, true, j, "pre")
                                  : 0
                              )}
                            >
                              <PorscheFont>
                                {recalculationOrderData?.workshopCosts
                                  ?.upsaleItems[index]
                                  ? formatCurrencyWithNoUnit(
                                      getPercent(
                                        recalculationOrderData?.workshopCosts
                                          ?.upsaleItems[index],
                                        true,
                                        j,
                                        "re"
                                      ) - getPercent(ele, true, j, "pre")
                                    )
                                  : "0,00"}
                                {" %"}
                              </PorscheFont>
                            </td>
                          </tr>
                        );
                      }
                    })}
                  </React.Fragment>
                );
              }
              if (ele.partType === "part") {
                return (
                  <React.Fragment key={index}>
                    <tr>
                      <td colSpan={2} className="table-td-left">
                        <PorscheFont>{t("PDS / Tequipment")}</PorscheFont>
                      </td>
                      <td className="table-td">
                        <PorscheFont>
                          {`${formatCurrencyWithNoUnit(
                            getAmountNet(ele, false, 0)
                          )} €`}
                        </PorscheFont>
                      </td>
                      <td className="table-td">
                        <PorscheFont>
                          {formatCurrencyWithNoUnit(ele.discount? ele.discount:0)}
                          {" €"}
                        </PorscheFont>
                      </td>
                      <td className="table-td">
                        <PorscheFont>
                          {/* {orderData && orderData.vehiclePrices && (getAmountNet(ele, false, 0)- ele.customerShare)/ orderData?.vehiclePrices[2].amountNet * 100} */}

      {/* {formatCurrencyWithNoUnit(calculateDiscountPercentage(ele))} %  ----------------- */}

                          {`${
                            orderData &&
                            orderData.vehiclePrices &&
                            formatCurrencyWithNoUnit(
                              ((getAmountNet(ele, false, 0) -
                                ele.customerShare) /
                                orderData?.vehiclePrices[2].amountNet) *
                                100
                            )
                          } %`}
                        </PorscheFont>
                      </td>
                      <td />
                      <td className="table-td">
                        <PorscheFont>
                          {`${
                            recalculationOrderData &&
                            recalculationOrderData?.workshopCosts
                              ?.upsaleItems &&
                            formatCurrencyWithNoUnit(
                              getAmountNet(
                                recalculationOrderData?.workshopCosts
                                  ?.upsaleItems[index],
                                false,
                                0
                              )
                            )
                          } €`}
                        </PorscheFont>
                      </td>
                      <td className="table-td">
                        <PorscheFont>
                          {recalculationOrderData?.workshopCosts?.upsaleItems[
                            index
                          ] &&
                            formatCurrencyWithNoUnit(
                              getAmountNet(
                                recalculationOrderData?.workshopCosts
                                  ?.upsaleItems[index],
                                false,
                                0
                              ) -
                                recalculationOrderData?.workshopCosts
                                  ?.upsaleItems[index].customerShare
                            )}
                          {" €"}
                        </PorscheFont>
                      </td>
                      <td className="table-td">
                        <PorscheFont>
                          {recalVehiclePrices &&
                          recalculationOrderData?.workshopCosts
                            ?.upsaleItems &&
                          recalculationOrderData?.workshopCosts?.upsaleItems[
                            index
                          ] &&
                          recalVehiclePrices[2]?.amountNet &&
                          orderData.vehiclePrices
                            ? formatCurrencyWithNoUnit(
                                ((printGetAmountNet(
                                  recalculationOrderData?.workshopCosts
                                    ?.upsaleItems[index],
                                  false,
                                  0
                                ) -
                                  recalculationOrderData?.workshopCosts
                                    ?.upsaleItems[index].customerShare) /
                                  recalVehiclePrices[2].amountNet) *
                                  100
                              )
                            : "0.00"}
                          {"%"}
                        </PorscheFont>
                      </td>
                      <td className="table-td">
                        <PorscheFont>
                          {recalculationOrderData?.workshopCosts
                            ?.upsaleItems &&
                            formatCurrencyWithNoUnit(
                              getPercent(
                                recalculationOrderData?.workshopCosts
                                  ?.upsaleItems[index],
                                false,
                                0,
                                "re"
                              ) - getPercent(ele, false, 0, "pre")
                            )}
                          {" %"}
                        </PorscheFont>
                      </td>
                    </tr>
                    {ele.serviceItems.map((service, j) => {
                      if (!(service.services.priceBeforeCustomerNet > 0))
                        return <></>;
                      return (
                        <tr>
                          <td colSpan={2} className="table-td-left">
                            <PorscheFont>
                              {t("Installation  (PDS/TEQ)")}
                            </PorscheFont>
                          </td>
                          <td className="table-td">
                            <PorscheFont>
                              {`${formatCurrencyWithNoUnit(
                                getAmountNet(ele, true, j) / 100
                              )} €`}
                            </PorscheFont>
                          </td>
                          <td className="table-td">
                            <PorscheFont>
                              {formatCurrencyWithNoUnit(
                                printGetAmountNet(ele, true, j) > 0
                                  ? printGetAmountNet(ele, true, j) -
                                      service.customerShare
                                  : 0.0
                              )}
                              {" €"}
                            </PorscheFont>
                          </td>
                          <td className="table-td">
                            <PorscheFont>
                              {orderData &&
                              orderData.vehiclePrices &&
                              orderData.vehiclePrices.length > 0
                                ? formatCurrencyWithNoUnit(
                                    ((printGetAmountNet(ele, true, j) -
                                      service.customerShare) /
                                      orderData.vehiclePrices[2].amountNet) *
                                      100
                                  )
                                : "0,00"}
                              {" %"}
                            </PorscheFont>
                          </td>
                          <td />
                          <td className="table-td">
                            <PorscheFont>
                              {`${
                                recalculationOrderData?.workshopCosts
                                  ?.upsaleItems &&
                                formatCurrencyWithNoUnit(
                                  getAmountNet(
                                    recalculationOrderData?.workshopCosts
                                      ?.upsaleItems[index],
                                    false,
                                    0
                                  )
                                )
                              } €`}
                            </PorscheFont>
                          </td>
                          <td className="table-td">
                            <PorscheFont>
                              {recalculationOrderData?.workshopCosts
                                ?.upsaleItems &&
                                formatCurrencyWithNoUnit(
                                  printGetAmountNet(
                                    recalculationOrderData?.workshopCosts
                                      ?.upsaleItems[index],
                                    true,
                                    j
                                  ) > 0
                                    ? printGetAmountNet(
                                        recalculationOrderData?.workshopCosts
                                          ?.upsaleItems[index],
                                        true,
                                        j
                                      ) - service.customerShare
                                    : 0.0
                                )}
                              {" €"}
                            </PorscheFont>
                          </td>
                          <td className="table-td">
                            <PorscheFont>
                              {/* {`${formatCurrencyWithNoUnit(getAmountNet(ele, false, 0) - ele.customerShare)} %`} */}
                              {recalVehiclePrices &&
                              recalculationOrderData?.workshopCosts
                                ?.upsaleItems &&
                              recalculationOrderData?.workshopCosts
                                ?.upsaleItems[index] &&
                              recalVehiclePrices[2]?.amountNet &&
                              orderData.vehiclePrices
                                ? formatCurrencyWithNoUnit(
                                    ((printGetAmountNet(
                                      recalculationOrderData?.workshopCosts
                                        ?.upsaleItems[index],
                                      true,
                                      0
                                    ) -
                                      recalculationOrderData?.workshopCosts
                                        ?.upsaleItems[index].customerShare) /
                                      recalVehiclePrices[2].amountNet) *
                                      100
                                  )
                                : "0.00"}
                            </PorscheFont>
                          </td>
                          <td className="table-td">
                            <PorscheFont>
                              {recalculationOrderData?.workshopCosts
                                ?.upsaleItems &&
                                formatCurrencyWithNoUnit(
                                  getPercent(
                                    recalculationOrderData?.workshopCosts
                                      ?.upsaleItems[index],
                                    true,
                                    j,
                                    "re"
                                  ) - getPercent(ele, true, j, "pre")
                                )}
                              {" %"}
                            </PorscheFont>
                          </td>
                        </tr>
                      );
                    })}
                  </React.Fragment>
                );
              }
            })}
          </tbody>
          {/* Workshop costs end */}

          {/* Trade-in (regular taxed) start  */}
          <tbody>
            <tr>
              <td
                colSpan={2}
                className="table-col-bold table-th-border-bottom p-top-16"
              >
                <PorscheFont>{t("Trade-in (regular taxed)")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top-16 table-td">
                <PorscheFont>{t("Amount (net)")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top-16 table-td">
                <PorscheFont>{t("Discount in €")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top-16 table-td">
                <PorscheFont>{t("Discount in %")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top-16 table-td"></td>
              <td className="table-th-border-bottom p-top-16 table-td">
                <PorscheFont>{t("Amount (net)")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top-16 table-td">
                <PorscheFont>{t("Discount in €")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top-16 table-td">
                <PorscheFont>{t("Discount in %")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top-16 table-td">
                <PorscheFont>{t("Deviation")}</PorscheFont>
              </td>
            </tr>
            {orderData?.tradeInPrices?.map((ele, index) => {
              if (index > 2) {
                let show = false;
                if (
                  order.tradeInDetails &&
                  (order.tradeInDetails.tradeInType === "Leasing_Redemption" ||
                    order.tradeInDetails.tradeInType ===
                      "Financing_Redemption_Regular" ||
                    order.tradeInDetails.tradeInType ===
                      "Financing_Redemption_Differential") &&
                  ((orderData &&
                    orderData?.tradeInPrices &&
                    orderData?.tradeInPrices[2]?.amountNet < 0) ||
                    (recalTrandInPrices! &&
                      recalTrandInPrices[2]?.amountNet! < 0))
                ) {
                  show = true;
                }

                if (!show) return <></>;
              }
              if (index === 2) {
                return (
                  <tr key={index}>
                    <td colSpan={2} className="table-td-left">
                      <PorscheFont>
                        {orderData &&
                        orderData?.tradeInPrices &&
                        orderData?.tradeInPrices[2].amountNet >= 0 &&
                        recalTrandInPrices! &&
                        recalTrandInPrices[2]?.amountNet! >= 0
                          ? t("Profit booking")
                          : orderData &&
                            orderData?.tradeInPrices &&
                            orderData?.tradeInPrices[2].amountNet < 0 &&
                            recalTrandInPrices! &&
                            recalTrandInPrices[2]?.amountNet! < 0
                          ? t("Loss booking")
                          : t("Booking")}
                      </PorscheFont>
                    </td>
                    <td className="table-td">
                      <PorscheFont>
                        {`${formatCurrencyWithNoUnit(ele.amountNet)} €`}
                      </PorscheFont>
                    </td>
                    <td className="table-td">
                      <PorscheFont>
                        {`${formatCurrencyWithNoUnit(ele.amountGross)} €`}
                      </PorscheFont>
                    </td>
                    <td className="table-td">
                      {order?.tradeInDetails &&
                        order?.tradeInDetails?.tradeInType !==
                          "Leasing_Redemption" &&
                        order.tradeInDetails.tradeInType !==
                          "Financing_Redemption_Regular" &&
                        order.tradeInDetails.tradeInType !==
                          "Financing_Redemption_Differential" && (
                          <>
                            <PorscheFont>
                              {orderData?.vehiclePrices &&
                              orderData?.vehiclePrices[0]?.amountNet > 0
                                ? formatCurrencyWithNoUnit(
                                    orderData?.tradeInPrices! &&
                                      (orderData?.tradeInPrices[2]?.amountNet /
                                        orderData?.vehiclePrices[0]
                                          ?.amountNet) *
                                        -1 *
                                        100
                                  )
                                : "0.00"}
                              {" %"}
                            </PorscheFont>
                          </>
                        )}

                      {order.tradeInDetails &&
                        (order.tradeInDetails.tradeInType ===
                          "Leasing_Redemption" ||
                          order.tradeInDetails.tradeInType ===
                            "Financing_Redemption_Regular" ||
                          order.tradeInDetails.tradeInType ===
                            "Financing_Redemption_Differential") &&
                        orderData?.tradeInPrices! &&
                        orderData?.tradeInPrices[2]?.amountNet >= 0 && (
                          <PorscheFont>
                            {orderData?.vehiclePrices &&
                            orderData?.vehiclePrices[0]?.amountNet > 0
                              ? formatCurrencyWithNoUnit(
                                  orderData?.tradeInPrices! &&
                                    (orderData?.tradeInPrices[2]?.amountNet /
                                      orderData?.vehiclePrices[0]?.amountNet) *
                                      -1 *
                                      100
                                )
                              : "0.00"}
                            {" %"}
                          </PorscheFont>
                        )}
                    </td>
                    <td />
                    <td className="table-td">
                      <PorscheFont>
                        {recalTrandInPrices
                          ? `${formatCurrencyWithNoUnit(
                              recalTrandInPrices[index]?.amountNet!
                            )} €`
                          : "0,00 €"}
                      </PorscheFont>
                    </td>
                    <td className="table-td">
                      <PorscheFont>
                        {recalTrandInPrices!
                          ? `${formatCurrencyWithNoUnit(
                              recalTrandInPrices[index]?.amountGross!
                            )} €`
                          : "0,00 €"}
                      </PorscheFont>
                    </td>
                    <td className="table-td">
                      {order?.tradeInDetails &&
                        order?.tradeInDetails?.tradeInType !==
                          "Leasing_Redemption" &&
                        order.tradeInDetails.tradeInType !==
                          "Financing_Redemption_Regular" &&
                        order.tradeInDetails.tradeInType !==
                          "Financing_Redemption_Differential" && (
                          <>
                            <PorscheFont>
                              {recalTrandInPrices! &&
                              recalVehiclePrices! &&
                              recalVehiclePrices[0]?.amountNet! > 0
                                ? formatCurrencyWithNoUnit(
                                    recalVehiclePrices[0]?.amountNet! > 0
                                      ? (recalTrandInPrices[2]?.amountNet! /
                                          recalVehiclePrices[0]?.amountNet!) *
                                          -1 *
                                          100
                                      : 0.0
                                  )
                                : "0.00"}
                              {" %"}
                            </PorscheFont>
                          </>
                        )}

                      {order.tradeInDetails &&
                        (order.tradeInDetails.tradeInType ===
                          "Leasing_Redemption" ||
                          order.tradeInDetails.tradeInType ===
                            "Financing_Redemption_Regular" ||
                          order.tradeInDetails.tradeInType ===
                            "Financing_Redemption_Differential") &&
                        recalTrandInPrices! &&
                        recalTrandInPrices[2]?.amountNet! >= 0 && (
                          <PorscheFont>
                            {recalVehiclePrices &&
                            recalVehiclePrices[0]?.amountNet! > 0
                              ? formatCurrencyWithNoUnit(
                                  recalVehiclePrices[0]?.amountNet! > 0
                                    ? (recalTrandInPrices[2]?.amountNet! /
                                        recalVehiclePrices[0]?.amountNet!) *
                                        -1 *
                                        100
                                    : 0.0
                                )
                              : "0.00"}
                            {" %"}
                          </PorscheFont>
                        )}
                    </td>
                    <td
                      className={checkBoldTextForDeviation(
                        ele.displayLabel === "Share PC"
                          ? getSharePcPercent()
                          : orderData &&
                            orderData?.tradeInPrices &&
                            recalTrandInPrices
                          ? recalTrandInPrices[index]?.amountNet! -
                            orderData?.tradeInPrices[index]?.amountNet
                          : 0
                      )}
                    >
                      <PorscheFont>
                        {formatCurrencyWithNoUnit(
                          ele.displayLabel === "Share PC"
                            ? getSharePcPercent()
                            : orderData &&
                              orderData?.tradeInPrices &&
                              recalTrandInPrices
                            ? recalTrandInPrices[index]?.amountNet! -
                              orderData?.tradeInPrices[index]?.amountNet
                            : 0
                        )}
                        {ele.displayLabel === "Share PC" ? " %" : " €"}
                      </PorscheFont>
                    </td>
                  </tr>
                );
              }
              return (
                <tr key={index}>
                  <td colSpan={2} className="table-td-left">
                    <PorscheFont>{ele.displayLabel}</PorscheFont>
                  </td>
                  <td className="table-td">
                    <PorscheFont>
                      {`${formatCurrencyWithNoUnit(ele.amountNet)} €`}
                    </PorscheFont>
                  </td>
                  <td className="table-td">
                    <PorscheFont>
                      {`${formatCurrencyWithNoUnit(ele.amountGross)} €`}
                    </PorscheFont>
                  </td>
                  <td className="table-td">
                    {index === 3 &&
                    calculateValueOfTradeInDiscount(order, "precal") !==
                      null ? (
                      <PorscheFont>
                        {formatCurrencyWithNoUnit(
                          calculateValueOfTradeInDiscount(order, "precal") || 0
                        )}
                        {" %"}
                      </PorscheFont>
                    ) : null}
                  </td>
                  <td />
                  <td className="table-td">
                    <PorscheFont>

                    {/* { recalTrandInPrices && ele.displayLabel==='Share Customer'  && recalTrandInPrices[5]?.amountNet ? formatCurrencyWithNoUnit(recalTrandInPrices[5]?.amountNet) : "0,00 " } € */}

                      { recalTrandInPrices
                        ? `${formatCurrencyWithNoUnit(
                            recalTrandInPrices[index]?.amountNet!
                          )} €`
                        : "0,00 €"}  
                    </PorscheFont>
                  </td>
                  <td className="table-td">

                    <PorscheFont>

                    {/* { recalTrandInPrices && ele.displayLabel==='Share Customer'  && recalTrandInPrices[5]?.amountGross ? formatCurrencyWithNoUnit(recalTrandInPrices[5]?.amountGross) : "0,00 "} € */}

                      {recalculationOrderData && recalTrandInPrices
                        ? `${formatCurrencyWithNoUnit(
                            recalTrandInPrices[index]?.amountGross!
                          )} €`
                        : "0,00 €"}
                    </PorscheFont>
                  </td>
                  <td className="table-td">
                    {index === 3 &&
                    calculateValueOfTradeInDiscount(order, "recal") !== null ? (
                      <PorscheFont>
                        {formatCurrencyWithNoUnit(
                          calculateValueOfTradeInDiscount(order, "recal") || 0
                        )}
                        {" %"}
                      </PorscheFont>
                    ) : null}
                  </td>
                  <td
                    className={checkBoldTextForDeviation(
                      ele.displayLabel === "Share PC"
                        ? getSharePcPercent()
                        : orderData &&
                          orderData?.tradeInPrices &&
                          recalculationOrderData &&
                          recalTrandInPrices
                        ? recalTrandInPrices[index]?.amountNet! -
                          orderData?.tradeInPrices[index]?.amountNet
                        : 0
                    )}
                  >
                    <PorscheFont>
                      {formatCurrencyWithNoUnit(
                        ele.displayLabel === "Share PC"
                          ? getSharePcPercent()
                          : orderData &&
                            orderData?.tradeInPrices &&
                            recalculationOrderData &&
                            recalTrandInPrices
                          ? recalTrandInPrices[index]?.amountNet! -
                            orderData?.tradeInPrices[index]?.amountNet
                          : 0
                      )}
                      {ele.displayLabel === "Share PC" ? " %" : " €"}
                    </PorscheFont>
                  </td>
                </tr>
              );
            })}
            <tr>
              <td className="table-td-left">
                <PorscheFont>{t("Comment (Optional)")}</PorscheFont>
              </td>
              <td></td>
              <td className="table-td">
                <PorscheFont>{orderData?.tradeInComment}</PorscheFont>
              </td>
              <td colSpan={3}></td>
              <td className="table-td">
                <PorscheFont>
                  {recalculationOrderData?.tradeInComment}
                </PorscheFont>
              </td>
              <td colSpan={2}></td>
              <td className="table-td"></td>
            </tr>
          </tbody>
          {/* Trade-in (regular taxed) end  */}

          {/* Financing In Start */}
          <tbody>
            <tr>
              <td
                colSpan={2}
                className="table-col-bold table-th-border-bottom p-top"
              >
                <PorscheFont>{t("Financing")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top table-td">
                <PorscheFont>{t("Amount (net)")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top table-td">
                <PorscheFont>{t("Amount (gross)")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top table-td"></td>
              <td className="table-th-border-bottom p-top table-td"></td>
              <td className="table-th-border-bottom p-top table-td">
                <PorscheFont>{t("Amount (net)")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top table-td">
                <PorscheFont>{t("Amount (gross)")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top table-td"></td>
              <td className="table-th-border-bottom p-top table-td">
                <PorscheFont>{t("Deviation")}</PorscheFont>
              </td>
            </tr>
            {orderData?.calculationPaymentDetails?.paymentType !== "CASH" && (
              <tr>
                <td colSpan={2} className="table-td-left">
                  <PorscheFont>
                    {getPayementTypeName(
                      orderData?.calculationPaymentDetails?.paymentType
                        ? orderData?.calculationPaymentDetails?.paymentType
                        : ""
                    ) === "Leasing"
                      ? t("Special leasing payment")
                      : t("Down payment")}
                  </PorscheFont>
                </td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      orderData?.calculationPaymentDetails?.paymentNet!
                    )} €`}
                  </PorscheFont>
                </td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      orderData?.calculationPaymentDetails?.paymentGross!
                    )} €`}
                  </PorscheFont>
                </td>
                <td></td>
                <td></td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      recalculationOrderData?.calculationPaymentDetails
                        ?.paymentNet!
                    )} €`}
                  </PorscheFont>
                </td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      recalculationOrderData?.calculationPaymentDetails
                        ?.paymentGross!
                    )} €`}
                  </PorscheFont>
                </td>
                <td></td>
                <td
                  className={checkBoldTextForDeviation(
                    recalculationOrderData?.calculationPaymentDetails
                      ?.paymentNet! -
                      orderData?.calculationPaymentDetails?.paymentNet!
                  )}
                >
                  <PorscheFont>{`${formatCurrencyWithNoUnit(
                    recalculationOrderData?.calculationPaymentDetails
                      ?.paymentNet! -
                      orderData?.calculationPaymentDetails?.paymentNet!
                  )} €`}</PorscheFont>
                </td>
              </tr>
            )}
            <tr>
              <td colSpan={2} className="table-td-left">
                <PorscheFont>{t("Up-front payment")}</PorscheFont>
              </td>
              <td className="table-td">
                <PorscheFont>
                  {`${formatCurrencyWithNoUnit(
                    orderData?.calculationPaymentDetails?.upFrontPaymentNet
                      ? orderData?.calculationPaymentDetails?.upFrontPaymentNet
                      : 0
                  )} €`}
                </PorscheFont>
              </td>
              <td className="table-td">
                <PorscheFont>
                  {`${formatCurrencyWithNoUnit(
                    orderData?.calculationPaymentDetails?.upFrontPaymentGross
                      ? orderData?.calculationPaymentDetails
                          ?.upFrontPaymentGross
                      : 0
                  )} €`}
                </PorscheFont>
              </td>
              <td></td>
              <td></td>
              <td className="table-td">
                <PorscheFont>
                  {`${formatCurrencyWithNoUnit(
                    recalculationOrderData?.calculationPaymentDetails
                      ?.upFrontPaymentNet
                      ? recalculationOrderData?.calculationPaymentDetails
                          ?.upFrontPaymentNet
                      : 0
                  )} €`}
                </PorscheFont>
              </td>
              <td className="table-td">
                <PorscheFont>
                  {`${formatCurrencyWithNoUnit(
                    recalculationOrderData?.calculationPaymentDetails
                      ?.paymentNet &&
                      orderData?.calculationPaymentDetails?.paymentNet
                      ? recalculationOrderData?.calculationPaymentDetails
                          ?.paymentNet -
                          orderData?.calculationPaymentDetails?.paymentNet
                      : 0
                  )} €`}
                </PorscheFont>
              </td>
              <td></td>
              <td
                className={checkBoldTextForDeviation(
                  recalculationOrderData?.calculationPaymentDetails
                    ?.upFrontPaymentGross &&
                    orderData?.calculationPaymentDetails?.upFrontPaymentNet
                    ? recalculationOrderData?.calculationPaymentDetails
                        ?.upFrontPaymentGross -
                        orderData?.calculationPaymentDetails?.upFrontPaymentNet
                    : 0
                )}
              >
                <PorscheFont>
                  {`${formatCurrencyWithNoUnit(
                    recalculationOrderData?.calculationPaymentDetails
                      ?.upFrontPaymentGross &&
                      orderData?.calculationPaymentDetails?.upFrontPaymentNet
                      ? recalculationOrderData?.calculationPaymentDetails
                          ?.upFrontPaymentGross -
                          orderData?.calculationPaymentDetails
                            ?.upFrontPaymentNet
                      : 0
                  )} €`}
                </PorscheFont>
              </td>
            </tr>
          </tbody>
          {/* Financing In End */}

          {/* Final Calculation start */}
          <tbody>
            <tr>
              <td
                colSpan={2}
                className="table-col-bold table-th-border-bottom p-top"
              >
                <PorscheFont>{t("Final Calculation")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top table-td">
                <PorscheFont>{t("Amount in €")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top table-td">
                <PorscheFont>{t("% of RRP (net)")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top table-td">
                <PorscheFont>{t("Days")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top table-td"></td>
              <td className="table-th-border-bottom p-top table-td">
                <PorscheFont>{t("Amount in €")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top table-td">
                <PorscheFont>{t("% of RRP (net)")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top table-td">
                <PorscheFont>{t("Days")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top table-td">
                <PorscheFont>{t("Deviation")}</PorscheFont>
              </td>
            </tr>
            <tr>
              <td colSpan={2} className="table-col-bold text-left">
                <PorscheFont>{t("External discount")}</PorscheFont>
              </td>
              <td className="table-col-bold text-right">
                <PorscheFont>
                  {`${formatCurrencyWithNoUnit(
                    orderData?.calculationHeader?.externalDiscount
                      ? orderData?.calculationHeader?.externalDiscount
                      : 0
                  )} €`}
                </PorscheFont>
              </td>
              <td className="table-col-bold text-right">
                <PorscheFont>
                  {`${
                    orderData?.calculationHeader?.externalDiscount &&
                    orderData?.vehiclePrices &&
                    orderData?.vehiclePrices[0]?.amountNet > 0
                      ? formatCurrencyWithNoUnit(
                          (orderData?.calculationHeader?.externalDiscount /
                            orderData?.vehiclePrices[0]?.amountNet) *
                            100
                        )
                      : "0,00"
                  } %`}
                </PorscheFont>
              </td>
              <td></td>
              <td></td>
              <td className="table-col-bold text-right">
                <PorscheFont>
                  {`${formatCurrencyWithNoUnit(
                    recalculationOrderData?.calculationHeader?.externalDiscount
                      ? recalculationOrderData?.calculationHeader
                          ?.externalDiscount
                      : 0
                  )} €`}
                </PorscheFont>
              </td>
              <td className="table-col-bold text-right">
                <PorscheFont>
                  {`${
                    recalculationOrderData?.calculationHeader
                      ?.externalDiscount &&
                    recalculationOrderData?.vehiclePrices &&
                    recalculationOrderData?.vehiclePrices[0].amountNet > 0
                      ? formatCurrencyWithNoUnit(
                          (recalculationOrderData?.calculationHeader
                            .externalDiscount /
                            recalculationOrderData?.vehiclePrices[0]
                              .amountNet) *
                            100
                        )
                      : "0,00"
                  }`}
                </PorscheFont>
              </td>
              <td></td>
              <td
                className={checkBoldTextForDeviation(
                  getExternalDicountPercent()
                )}
              >
                <PorscheFont>{`${formatCurrencyWithNoUnit(
                  getExternalDicountPercent()
                )} €`}</PorscheFont>
              </td>
            </tr>
            <tr>
              <td colSpan={2} className="table-col-bold text-left">
                <PorscheFont>{t("Internal discount")}</PorscheFont>
              </td>
              <td className="table-col-bold text-right">
                <PorscheFont>
                  {`${formatCurrencyWithNoUnit(
                    orderData?.calculationHeader?.internalDiscount
                      ? orderData?.calculationHeader?.internalDiscount
                      : 0
                  )} €`}
                </PorscheFont>
              </td>
              <td className="table-col-bold text-right">
                <PorscheFont>
                  {`${
                    orderData?.calculationHeader?.internalDiscount &&
                    orderData?.vehiclePrices &&
                    orderData?.vehiclePrices.length > 0 &&
                    orderData?.vehiclePrices[0].amountNet > 0
                      ? formatCurrencyWithNoUnit(
                          (orderData?.calculationHeader.internalDiscount /
                            orderData?.vehiclePrices[0].amountNet) *
                            100
                        )
                      : "0,00"
                  } %`}
                </PorscheFont>
              </td>
              <td colSpan={2}></td>
              <td className="table-col-bold text-right">
                <PorscheFont>
                  {`${formatCurrencyWithNoUnit(
                    recalculationOrderData?.calculationHeader?.internalDiscount
                      ? recalculationOrderData?.calculationHeader
                          ?.internalDiscount
                      : 0
                  )} €`}
                </PorscheFont>
              </td>
              <td className="table-col-bold text-right">
                <PorscheFont>
                  {`${
                    recalculationOrderData?.calculationHeader
                      ?.internalDiscount &&
                    recalculationOrderData?.vehiclePrices &&
                    recalculationOrderData?.vehiclePrices[2].amountNet > 0
                      ? formatCurrencyWithNoUnit(
                          (recalculationOrderData?.calculationHeader
                            ?.internalDiscount /
                            recalculationOrderData?.vehiclePrices[2]
                              .amountNet) *
                            100
                        )
                      : "0,00"
                  } %`}
                </PorscheFont>
              </td>
              <td></td>
              <td
                className={checkBoldTextForDeviation(
                  getInternalDicountPercent()
                )}
              >
                <PorscheFont>
                  {formatCurrencyWithNoUnit(getInternalDicountPercent())}
                </PorscheFont>
              </td>
            </tr>
            {(order.paymentDetails?.paymentType === "Leasing_PFS" ||
              order.paymentDetails?.paymentType === "Leasing_Other") &&
              rvm &&
              rvm.rrp > 0 && (
                <tr>
                  <td colSpan={2} className="table-td-left">
                    <PorscheFont>{t("Residual value model")}</PorscheFont>
                  </td>
                  <td className="table-td">
                    <PorscheFont>
                      {` ${formatCurrencyWithNoUnit(
                        orderData?.vehiclePrices
                          ? rvm.rrp * orderData?.vehiclePrices[2].amountNet
                          : 0.0
                      )} €  `}
                    </PorscheFont>
                  </td>
                  <td className="table-td">
                    <PorscheFont>
                      {`${
                        rvm.rrp ? formatCurrencyWithNoUnit(rvm.rrp) : "0,00"
                      } %`}
                    </PorscheFont>
                  </td>
                  <td colSpan={2}></td>
                  <td className="table-td">
                    <PorscheFont>
                      {` ${formatCurrencyWithNoUnit(
                        recalculationOrderData?.vehiclePrices
                          ? rvm.rrp *
                              recalculationOrderData?.vehiclePrices[2].amountNet
                          : 0.0
                      )} €  `}
                    </PorscheFont>
                  </td>
                  <td className="table-td">
                    <PorscheFont>
                      {`${
                        rvm.rrp ? formatCurrencyWithNoUnit(rvm.rrp) : "0,00"
                      } %`}
                    </PorscheFont>
                  </td>
                  <td></td>
                  <td
                    className={checkBoldTextForDeviation(
                      recalculationOrderData?.vehiclePrices &&
                        orderData?.vehiclePrices
                        ? recalculationOrderData?.vehiclePrices[2].amountNet -
                            orderData?.vehiclePrices[2].amountNet
                        : 0.0
                    )}
                  >
                    <PorscheFont>{` ${formatCurrencyWithNoUnit(
                      recalculationOrderData?.vehiclePrices &&
                        orderData?.vehiclePrices
                        ? recalculationOrderData?.vehiclePrices[2].amountNet -
                            orderData?.vehiclePrices[2].amountNet
                        : 0.0
                    )} €  `}</PorscheFont>
                  </td>
                </tr>
              )}
            <tr>
              <td colSpan={2} className="table-td-left">
                <PorscheFont>{t("Gross profit")}</PorscheFont>
              </td>
              <td className="table-td">
                <PorscheFont>
                  {`${formatCurrencyWithNoUnit(
                    orderData?.calculationHeader?.grossProfit
                      ? orderData?.calculationHeader?.grossProfit
                      : 0
                  )} €`}
                </PorscheFont>
              </td>
              <td className="table-td">
                <PorscheFont>
                  {`${
                    orderData?.calculationHeader?.grossProfit &&
                    orderData?.vehiclePrices &&
                    orderData?.vehiclePrices.length > 0 &&
                    orderData?.vehiclePrices[0].amountNet > 0
                      ? formatCurrencyWithNoUnit(
                          (orderData?.calculationHeader.grossProfit /
                            orderData?.vehiclePrices[0].amountNet) *
                            100
                        )
                      : "0,00"
                  } %`}
                </PorscheFont>
              </td>
              <td colSpan={2}></td>
              <td className="table-td">
                <PorscheFont>
                  {`${formatCurrencyWithNoUnit(
                    recalculationOrderData?.calculationHeader?.grossProfit
                      ? recalculationOrderData?.calculationHeader?.grossProfit
                      : 0
                  )} €`}
                </PorscheFont>
              </td>
              <td className="table-td">
                <PorscheFont>
                  {`${
                    recalculationOrderData?.calculationHeader?.grossProfit &&
                    recalculationOrderData?.vehiclePrices &&
                    recalculationOrderData?.vehiclePrices[2].amountNet > 0
                      ? formatCurrencyWithNoUnit(
                          (recalculationOrderData?.calculationHeader
                            ?.grossProfit /
                            recalculationOrderData?.vehiclePrices[2]
                              .amountNet) *
                            100
                        )
                      : "0,00"
                  } %`}
                </PorscheFont>
              </td>
              <td></td>
              <td
                className={checkBoldTextForDeviation(getGrossProfitPercent())}
              >
                <PorscheFont>
                  {formatCurrencyWithNoUnit(getGrossProfitPercent())}
                </PorscheFont>
              </td>
            </tr>
            <tr>
              <td colSpan={2} className="table-td-left">
                <PorscheFont>{t("Standing days")}</PorscheFont>
              </td>
              <td colSpan={2}></td>
              <td className="table-td">
                <PorscheFont>
                  {orderData?.onlineCarSaleInfo?.standingDays}
                </PorscheFont>
              </td>
              <td colSpan={3}></td>
              <td className="table-td">
                <PorscheFont>
                  {recalculationOrderData?.onlineCarSaleInfo?.standingDays}
                </PorscheFont>
              </td>
              <td
                className={checkBoldTextForDeviation(
                  recalculationOrderData?.onlineCarSaleInfo?.standingDays! -
                    orderData?.onlineCarSaleInfo?.standingDays!
                )}
              >
                <PorscheFont>
                  {recalculationOrderData?.onlineCarSaleInfo?.standingDays! -
                    orderData?.onlineCarSaleInfo?.standingDays!}
                </PorscheFont>
              </td>
            </tr>
          </tbody>
          {/* Final Calculation end */}

          {/*  Calculation customer start */}
          <tbody>
            <tr>
              <td
                colSpan={2}
                className="table-col-bold table-th-border-bottom p-top"
              >
                <PorscheFont>{t("Calculation customer")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top table-td">
                <PorscheFont>{t("Amount (net)")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top table-td">
                <PorscheFont>{t("Amount (gross)")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top table-td"></td>
              <td className="table-th-border-bottom p-top table-td"></td>
              <td className="table-th-border-bottom p-top table-td">
                <PorscheFont>{t("Amount (net)")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top table-td">
                <PorscheFont>{t("Amount (gross)")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top table-td"></td>
              <td className="table-th-border-bottom p-top table-td">
                <PorscheFont>{t("Deviation")}</PorscheFont>
              </td>
            </tr>
            <tr>
              <td colSpan={2} className="table-td-left">
                <PorscheFont>{t("Total invoice amount")}</PorscheFont>
              </td>
              <td className="table-td">
                <PorscheFont>
                  {`${formatCurrencyWithNoUnit(
                    orderData?.calculationCustomer?.invoiceNet
                      ? orderData?.calculationCustomer?.invoiceNet
                      : 0
                  )} €`}
                </PorscheFont>
              </td>
              <td className="table-td">
                <PorscheFont>
                  {`${formatCurrencyWithNoUnit(
                    orderData?.calculationCustomer?.invoiceGross
                      ? orderData?.calculationCustomer?.invoiceGross
                      : 0
                  )} €`}
                </PorscheFont>
              </td>
              <td></td>
              <td></td>
              <td className="table-td">
                <PorscheFont>
                  {`${formatCurrencyWithNoUnit(
                    recalculationOrderData?.calculationCustomer?.invoiceNet
                      ? recalculationOrderData?.calculationCustomer?.invoiceNet
                      : 0
                  )} €`}
                </PorscheFont>
              </td>
              <td className="table-td">
                <PorscheFont>
                  {`${formatCurrencyWithNoUnit(
                    recalculationOrderData?.calculationCustomer?.invoiceGross
                      ? recalculationOrderData?.calculationCustomer
                          ?.invoiceGross
                      : 0
                  )} €`}
                </PorscheFont>
              </td>
              <td></td>
              <td
                className={checkBoldTextForDeviation(
                  recalculationOrderData &&
                    recalculationOrderData?.calculationCustomer &&
                    orderData &&
                    orderData?.calculationCustomer
                    ? recalculationOrderData?.calculationCustomer?.invoiceNet -
                        orderData?.calculationCustomer?.invoiceNet
                    : 0
                )}
              >
                <PorscheFont>
                  {formatCurrencyWithNoUnit(
                    recalculationOrderData &&
                      recalculationOrderData?.calculationCustomer &&
                      orderData &&
                      orderData?.calculationCustomer
                      ? recalculationOrderData?.calculationCustomer
                          ?.invoiceNet -
                          orderData?.calculationCustomer?.invoiceNet
                      : 0.0
                  )}
                  {" €"}
                </PorscheFont>
              </td>
            </tr>
            <tr>
              <td colSpan={2} className="table-td-left">
                <PorscheFont>{t("Pre-delivery payment")}</PorscheFont>
              </td>
              <td className="table-td">
                <PorscheFont>
                  {`${formatCurrencyWithNoUnit(
                    orderData?.calculationCustomer?.preDeliveryNet
                      ? orderData?.calculationCustomer?.preDeliveryNet
                      : 0
                  )} €`}
                </PorscheFont>
              </td>
              <td className="table-td">
                <PorscheFont>
                  {`${formatCurrencyWithNoUnit(
                    orderData?.calculationCustomer?.preDeliveryGross
                      ? orderData?.calculationCustomer?.preDeliveryGross
                      : 0
                  )} €`}
                </PorscheFont>
              </td>
              <td></td>
              <td></td>
              <td className="table-td">
                <PorscheFont>
                  {`${formatCurrencyWithNoUnit(
                    recalculationOrderData?.calculationCustomer?.preDeliveryNet
                      ? recalculationOrderData?.calculationCustomer
                          ?.preDeliveryNet
                      : 0
                  )} €`}
                </PorscheFont>
              </td>
              <td className="table-td">
                <PorscheFont>
                  {`${formatCurrencyWithNoUnit(
                    recalculationOrderData?.calculationCustomer
                      ?.preDeliveryGross
                      ? recalculationOrderData?.calculationCustomer
                          ?.preDeliveryGross
                      : 0
                  )} €`}
                </PorscheFont>
              </td>
              <td></td>
              <td
                className={checkBoldTextForDeviation(
                  recalculationOrderData &&
                    recalculationOrderData?.calculationCustomer &&
                    orderData &&
                    orderData?.calculationCustomer
                    ? recalculationOrderData?.calculationCustomer
                        ?.preDeliveryNet -
                        orderData?.calculationCustomer?.preDeliveryNet
                    : 0
                )}
              >
                <PorscheFont>
                  {formatCurrencyWithNoUnit(
                    recalculationOrderData &&
                      recalculationOrderData?.calculationCustomer &&
                      orderData &&
                      orderData?.calculationCustomer
                      ? recalculationOrderData?.calculationCustomer
                          ?.preDeliveryNet -
                          orderData?.calculationCustomer?.preDeliveryNet
                      : 0
                  )}
                  {" €"}
                </PorscheFont>
              </td>
            </tr>
            <tr>
              <td colSpan={2} className="table-td-left">
                <PorscheFont>{t("Leasing amount")}</PorscheFont>
              </td>
              <td className="table-td">
                <PorscheFont>
                  {`${formatCurrencyWithNoUnit(
                    orderData?.calculationCustomer?.paymentNet
                      ? orderData?.calculationCustomer?.paymentNet
                      : 0
                  )} €`}
                </PorscheFont>
              </td>
              <td className="table-td">
                <PorscheFont>
                  {`${formatCurrencyWithNoUnit(
                    orderData?.calculationCustomer?.paymentGross
                      ? orderData?.calculationCustomer?.paymentGross
                      : 0
                  )} €`}
                </PorscheFont>
              </td>
              <td></td>
              <td></td>
              <td className="table-td">
                <PorscheFont>
                  {`${formatCurrencyWithNoUnit(
                    recalculationOrderData?.calculationCustomer?.paymentNet
                      ? recalculationOrderData?.calculationCustomer?.paymentNet
                      : 0
                  )} €`}
                </PorscheFont>
              </td>
              <td className="table-td">
                <PorscheFont>
                  {`${formatCurrencyWithNoUnit(
                    recalculationOrderData?.calculationCustomer?.paymentGross
                      ? recalculationOrderData?.calculationCustomer
                          ?.paymentGross
                      : 0
                  )} €`}
                </PorscheFont>
              </td>
              <td></td>
              <td
                className={checkBoldTextForDeviation(
                  recalculationOrderData &&
                    recalculationOrderData?.calculationCustomer &&
                    orderData &&
                    orderData?.calculationCustomer
                    ? recalculationOrderData?.calculationCustomer.paymentNet -
                        orderData?.calculationCustomer.paymentNet
                    : 0
                )}
              >
                <PorscheFont>
                  {formatCurrencyWithNoUnit(
                    recalculationOrderData &&
                      recalculationOrderData?.calculationCustomer &&
                      orderData &&
                      orderData?.calculationCustomer
                      ? recalculationOrderData?.calculationCustomer.paymentNet -
                          orderData?.calculationCustomer.paymentNet
                      : 0
                  )}
                  {" €"}
                </PorscheFont>
              </td>
            </tr>
          </tbody>
          {/*  Calculation customer end */}

          {/* Commission calculation  In Start */}
          <tbody>
            <tr>
              <td
                colSpan={2}
                className="table-col-bold table-th-border-bottom p-top"
              >
                <PorscheFont>{t("Commission calculation")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top table-td">
                <PorscheFont>{t("Amount in €")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top table-td"></td>
              <td className="table-th-border-bottom p-top table-td"></td>
              <td className="table-th-border-bottom p-top table-td"></td>
              <td className="table-th-border-bottom p-top table-td">
                <PorscheFont>{t("Amount in €")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top table-td"></td>
              <td className="table-th-border-bottom p-top table-td"></td>
              <td className="table-th-border-bottom p-top table-td">
                <PorscheFont>{t("Deviation")}</PorscheFont>
              </td>
            </tr>
            <tr>
              <td colSpan={2} className="table-td-left">
                <PorscheFont>{t("Vehicle commission")}</PorscheFont>
              </td>
              <td className="table-td">
                <PorscheFont>
                  {`${formatCurrencyWithNoUnit(
                    orderData?.commissionCalculation?.vehicleCommissionEuro
                      ? orderData?.commissionCalculation?.vehicleCommissionEuro
                      : 0
                  )} €`}
                </PorscheFont>
              </td>
              <td className="table-td"></td>
              <td></td>
              <td></td>
              <td className="table-td">
                <PorscheFont>
                  {`${formatCurrencyWithNoUnit(
                    recalculationOrderData?.commissionCalculation
                      ?.vehicleCommissionEuro
                      ? recalculationOrderData?.commissionCalculation
                          ?.vehicleCommissionEuro
                      : 0
                  )} €`}
                </PorscheFont>
              </td>
              <td className="table-td"></td>
              <td></td>
              <td
                className={checkBoldTextForDeviation(
                  recalculationOrderData &&
                    recalculationOrderData?.commissionCalculation &&
                    orderData &&
                    orderData?.commissionCalculation
                    ? recalculationOrderData?.commissionCalculation
                        ?.vehicleCommissionEuro! -
                        orderData?.commissionCalculation?.vehicleCommissionEuro!
                    : 0
                )}
              >
                <PorscheFont>
                  {formatCurrencyWithNoUnit(
                    recalculationOrderData &&
                      recalculationOrderData?.commissionCalculation &&
                      orderData &&
                      orderData?.commissionCalculation
                      ? recalculationOrderData?.commissionCalculation
                          ?.vehicleCommissionEuro! -
                          orderData?.commissionCalculation
                            ?.vehicleCommissionEuro!
                      : 0
                  )}
                  {" €"}
                </PorscheFont>
              </td>
            </tr>
            {/* <tr>
              <td colSpan={5} className="table-td-left">
                <PorscheFont>
                  {t("Standard: 9,00% of gross profit")}
                </PorscheFont>
              </td>
              <td colSpan={5} className="table-td-left pl-220">
                <PorscheFont>
                  {t(" Standard: 13,00% of gross profit")}
                </PorscheFont>
              </td>
              <td></td>
            </tr> */}
            <tr>
              <td colSpan={2} className="table-td-left">
                <PorscheFont>{t("PFS commission (net)")}</PorscheFont>
              </td>
              <td className="table-td">
                <PorscheFont>
                  {`${formatCurrencyWithNoUnit(
                    orderData?.commissionCalculation?.pfsCommission
                      ? orderData?.commissionCalculation?.pfsCommission
                      : 0
                  )} €`}
                </PorscheFont>
              </td>
              <td className="table-td"></td>
              <td></td>
              <td></td>
              <td className="table-td">
                <PorscheFont>
                  {`${formatCurrencyWithNoUnit(
                    recalculationOrderData?.commissionCalculation?.pfsCommission
                      ? recalculationOrderData?.commissionCalculation
                          ?.pfsCommission
                      : 0
                  )} €`}
                </PorscheFont>
              </td>
              <td className="table-td"></td>
              <td></td>
              <td
                className={checkBoldTextForDeviation(
                  recalculationOrderData?.commissionCalculation
                    ?.pfsCommission &&
                    orderData?.commissionCalculation?.pfsCommission
                    ? recalculationOrderData?.commissionCalculation
                        ?.pfsCommission -
                        orderData?.commissionCalculation?.pfsCommission
                    : 0
                )}
              >
                <PorscheFont>
                  {`${formatCurrencyWithNoUnit(
                    recalculationOrderData?.commissionCalculation
                      ?.pfsCommission &&
                      orderData?.commissionCalculation?.pfsCommission
                      ? recalculationOrderData?.commissionCalculation
                          ?.pfsCommission -
                          orderData?.commissionCalculation?.pfsCommission
                      : 0
                  )} €`}
                </PorscheFont>
              </td>
            </tr>
            <tr>
              <td colSpan={2} className="table-td-left">
                <PorscheFont>{t("Car Policy commission")}</PorscheFont>
              </td>
              <td className="table-td">
                <PorscheFont>
                  {formatCurrencyWithNoUnit(
                    orderData?.commissionCalculation?.carPolicyCommission!
                  )}
                  {" €"}
                </PorscheFont>
              </td>
              <td className="table-td"></td>
              <td></td>
              <td></td>
              <td className="table-td">
                <PorscheFont>
                  {formatCurrencyWithNoUnit(
                    recalculationOrderData?.commissionCalculation
                      ?.carPolicyCommission!
                  )}
                  {" €"}
                </PorscheFont>
              </td>
              <td className="table-td"></td>
              <td></td>
              <td
                className={checkBoldTextForDeviation(
                  recalculationOrderData?.commissionCalculation
                    ?.carPolicyCommission! -
                    orderData?.commissionCalculation?.carPolicyCommission!
                )}
              >
                <PorscheFont>
                  {formatCurrencyWithNoUnit(
                    recalculationOrderData?.commissionCalculation
                      ?.carPolicyCommission! -
                      orderData?.commissionCalculation?.carPolicyCommission!
                  )}
                  {" €"}
                </PorscheFont>
              </td>
            </tr>
            <tr>
              <td colSpan={2} className="table-td-left">
                <PorscheFont>{t("Incentive / Bonus")}</PorscheFont>
              </td>
              <td className="table-td">
                <PorscheFont>
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      orderData?.commissionCalculation?.incentive
                        ? orderData?.commissionCalculation?.incentive
                        : 0
                    )} €`}
                  </PorscheFont>
                </PorscheFont>
              </td>
              <td className="table-td"></td>
              <td></td>
              <td></td>
              <td className="table-td">
                <PorscheFont>
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      recalculationOrderData?.commissionCalculation?.incentive
                        ? recalculationOrderData?.commissionCalculation
                            ?.incentive
                        : 0
                    )} €`}
                  </PorscheFont>
                </PorscheFont>
              </td>
              <td className="table-td"></td>
              <td></td>
              <td
                className={checkBoldTextForDeviation(
                  recalculationOrderData?.commissionCalculation?.incentive &&
                    orderData?.commissionCalculation
                    ? recalculationOrderData?.commissionCalculation?.incentive -
                        orderData?.commissionCalculation?.incentive
                    : 0
                )}
              >
                <PorscheFont>
                  {`${formatCurrencyWithNoUnit(
                    recalculationOrderData?.commissionCalculation?.incentive &&
                      orderData?.commissionCalculation
                      ? recalculationOrderData?.commissionCalculation
                          ?.incentive -
                          orderData?.commissionCalculation?.incentive
                      : 0
                  )} €`}
                </PorscheFont>
              </td>
            </tr>
            <tr>
              <td colSpan={2} className="table-td-left">
                <PorscheFont>{t("Incentive Program")}</PorscheFont>
              </td>
              <td colSpan={8} className="table-td-left comment-col">
                <PorscheFont>{t("Winter Sales Promo 2022")}</PorscheFont>
              </td>
            </tr>
            <tr>
              <td colSpan={2} className="table-td-left">
                <PorscheFont>
                  {tequipmentCommission
                    ? t(`PDS/TEQ commission (included)`)
                    : t(`PDS/TEQ commission (not-included)`)}
                </PorscheFont>
              </td>
              <td className="table-td">
                <PorscheFont>
                  <PorscheFont>
                  {`${formatCurrencyWithNoUnit(upsalseTotalCommission)} €`} 
                  </PorscheFont>
                </PorscheFont>
              </td>
              <td className="table-td"></td>
              <td></td>
              <td></td>
              <td className="table-td">
                <PorscheFont>
                  <PorscheFont>
                  {`${formatCurrencyWithNoUnit(upsalseTotalCommission)} €`} 
                  </PorscheFont>
                </PorscheFont>
              </td>
              <td className="table-td"></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colSpan={2} className="table-col-bold text-left">
                <PorscheFont>{t("Total Commission")}</PorscheFont>
              </td>
              <td className="table-col-bold text-right">
                <PorscheFont>
                  {`${formatCurrencyWithNoUnit(
                    orderData?.commissionCalculation?.totalCommission
                      ? negativeValueHandler(orderData?.commissionCalculation?.totalCommission) 
                      : 0
                  )} €`}
                </PorscheFont>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td className="table-col-bold text-right">
                <PorscheFont>
                  {`${formatCurrencyWithNoUnit(
                    recalculationOrderData?.commissionCalculation
                      ?.totalCommission
                      ? negativeValueHandler(recalculationOrderData?.commissionCalculation
                        ?.totalCommission) 
                      : 0
                  )} €`}
                </PorscheFont>
              </td>
              <td></td>
              <td></td>
              <td
                className={checkBoldTextForDeviation(
                  recalculationOrderData?.commissionCalculation?.totalCommission
                    ? recalculationOrderData?.commissionCalculation
                        ?.totalCommission -
                        orderData?.commissionCalculation?.totalCommission!
                    : 0
                )}
              >
                <PorscheFont>{`${formatCurrencyWithNoUnit(
                  recalculationOrderData?.commissionCalculation?.totalCommission
                    ? recalculationOrderData?.commissionCalculation
                        ?.totalCommission -
                        orderData?.commissionCalculation?.totalCommission!
                    : 0
                )} €`}</PorscheFont>
              </td>
            </tr>
          </tbody>
          {/* Commission calculation  In end */}

          {/* Commission sharing Start */}
          <tbody>
            <tr>
              <td className="table-col-bold table-th-border-bottom p-top">
                <PorscheFont>{t("Commission sharing")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top custom-header">
                <PorscheFont>{t("Sellers involved")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top table-td">
                <PorscheFont>{t("Share in %")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top table-td">
                <PorscheFont>{t("Share in €")}</PorscheFont>
              </td>
              <td
                colSpan={3}
                className="table-th-border-bottom p-top table-td"
              ></td>
              <td className="table-th-border-bottom p-top table-td">
                <PorscheFont>{t("Share in %")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top table-td">
                <PorscheFont>{t("Share in €")}</PorscheFont>
              </td>
              <td className="table-th-border-bottom p-top table-td">
                <PorscheFont>{t("Deviation")}</PorscheFont>
              </td>
            </tr>
            {orderData?.commissionSharing?.map((ele, i) => {
              return (
                <tr key={i}>
                  {i === 0 && (
                    <td className="table-td-left">
                      <PorscheFont>{t("Vehicle commission")}</PorscheFont>
                    </td>
                  )}
                  {i === 1 && (
                    <td className="table-td-left">
                      <PorscheFont>
                        {`${formatCurrencyWithNoUnit(
                          orderData?.commissionCalculation
                            ?.vehicleCommissionEuro
                            ? orderData?.commissionCalculation
                                ?.vehicleCommissionEuro
                            : 0
                        )} €`}
                      </PorscheFont>
                    </td>
                  )}
                  {i > 1 ? <td /> : null}
                  <td className="table-td-left">
                    <PorscheFont>
                      {ele.sellerNames?.ppnName ? ele.sellerNames?.ppnName : ""}
                    </PorscheFont>
                  </td>
                  <td className="table-td">
                    <PorscheFont>
                      {`${formatCurrencyWithNoUnit(ele.shareInPercent)} %`}
                    </PorscheFont>
                  </td>
                  <td className="table-td">
                    <PorscheFont>
                      {`${formatCurrencyWithNoUnit(ele.shareInEuro)} €`}
                    </PorscheFont>
                  </td>
                  <td colSpan={2} />
                  {i === 0 && (
                    <td className="table-td">
                      <PorscheFont>{t("Vehicle commission")}</PorscheFont>
                    </td>
                  )}
                  {i === 1 && (
                    <td className="table-td">
                      <PorscheFont>
                        {`${formatCurrencyWithNoUnit(
                          recalculationOrderData?.commissionCalculation
                            ?.vehicleCommissionEuro
                            ? recalculationOrderData?.commissionCalculation
                                ?.vehicleCommissionEuro
                            : 0
                        )} €`}
                      </PorscheFont>
                    </td>
                  )}
                  {i > 1 ? <td /> : null}
                  <td className="table-td">
                    <PorscheFont>
                      {`${formatCurrencyWithNoUnit(
                        recalculationOrderData &&
                          recalculationOrderData?.commissionSharing
                          ? recalculationOrderData?.commissionSharing[i]
                              ?.shareInPercent!
                          : 0
                      )} %`}
                    </PorscheFont>
                  </td>
                  <td className="table-td">
                    <PorscheFont>
                      {`${formatCurrencyWithNoUnit(
                        recalculationOrderData &&
                          recalculationOrderData?.commissionSharing
                          ? recalculationOrderData?.commissionSharing[i]
                              ?.shareInEuro!
                          : 0
                      )} €`}
                    </PorscheFont>
                  </td>
                  <td
                    className={checkBoldTextForDeviation(
                      recalculationOrderData &&
                        recalculationOrderData?.commissionSharing
                        ? recalculationOrderData?.commissionSharing[i]
                            ?.shareInEuro! - ele.shareInEuro
                        : 0
                    )}
                  >
                    <PorscheFont>
                      {`${formatCurrencyWithNoUnit(
                        recalculationOrderData &&
                          recalculationOrderData?.commissionSharing
                          ? recalculationOrderData?.commissionSharing[i]
                              ?.shareInEuro! - ele.shareInEuro
                          : 0
                      )} €`}
                    </PorscheFont>
                  </td>
                </tr>
              );
            })}
          </tbody>
          {/* Commission sharing End */}
        </table>
      </div>
    </>
  );
});
