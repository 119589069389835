import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import {
  PGrid,
  PGridItem,
  PSegmentedControl,
  PSegmentedControlItem,
  PTextFieldWrapper,
  SegmentedControlChangeEvent,
} from "@porsche-design-system/components-react";

import { PorscheFont } from "../../../../assets/styles/CustomStyles";
import { ReferralType } from "../../../../configs/Enums";
import { OrderContext } from "../../../../contexts/OrderContext";
import { OrderDTO, Referral as RefDto } from "../../../../interfaces/Order";
import {
  formatCurrencyToDecimal,
  formatCurrencyWithNoUnit,
} from "../../../../mock/helper";
import { dcsGetGeneralTabs, getGeneralTabs } from "../../../../services/data/Helpers";
import { CustomTextField } from "../../../common/CustomTextField";
import { type } from "../../../../services/Constants";

interface Props {
  referralToggle: boolean;
  tradeInToggle: boolean;
  enableNextButton: (isValid: boolean) => void;
  disabled: boolean;
  referralDetails?: OrderDTO["referral"];
  handleModified: () => void;
  updateError: (id: string) => void;
}
export const Referral = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const {
    referralToggle,
    enableNextButton,
    tradeInToggle,
    disabled,
    handleModified,
    updateError,
  } = props;
  //const [ referal, setReferal] = useState(ReferralType.ExternalCommercial);
  const [referal, setReferal] = useState("");
  const [internalReferralValue, setInternalReferralValue] = useState(0.0);
  const [reload, setReload] = useState("");
  const [disabledClassName, setDisabledClassName] = useState("");
  const [referralDetails, setReferralDetails] = useState<RefDto>({} as RefDto);
  const orderContext = useContext(OrderContext);
  const refCom = useRef<HTMLInputElement>(null);

  useEffect(() => {
    let ord = orderContext.getCtxOrderDetails();
    if (ord.referral) setReferralDetails(ord.referral);

    if (ord.referral) {
      const referralType = ord.referral.referralType;
      if (referralType === "External_Private")
        setReferal(ReferralType.ExternalPrivate);
      if (referralType === "External_Common")
        setReferal(ReferralType.ExternalCommercial);
      if (referralType === "Internal_Empl") {
        setReferal(ReferralType.InternalEmployee);
      }
      const init = async () => {
        const apiResponse = await dcsGetGeneralTabs();
        if (
          apiResponse.status === type.SUCCESS &&
          apiResponse.response?.referallCommissions &&
          apiResponse.response?.referallCommissions.length > 2
        ) {
          const general = apiResponse.response;
          setInternalReferralValue(general?.referallCommissions[3].value);
          if (referralType === "Internal_Empl") {
            if (ord.referral) {
              ord.referral.referralCommission =
                general?.referallCommissions[3].value;
              if (refCom.current)
                refCom.current.value = formatCurrencyWithNoUnit(
                  ord.referral?.referralCommission
                    ? ord.referral?.referralCommission
                    : 0.0
                );
            } else {
              ord.referral = {} as RefDto;
              ord.referral.referralType = "Internal_Empl";
              ord.referral.referralCommission =
                general?.referallCommissions[3].value;
              if (refCom.current)
                refCom.current.value = formatCurrencyWithNoUnit(
                  ord.referral?.referralCommission
                    ? ord.referral?.referralCommission
                    : 0.0
                );
            }
            setReferralDetails(ord.referral);
            orderContext.updateCtxOrderDetails(ord);
          }
        }
      };
      init();
      setReload(new Date().toLocaleString());
    }
    if (disabled) setDisabledClassName("disabled");
    else setDisabledClassName("");
  }, []);

  useEffect(() => {}, [
    referal,
    reload,
    referralDetails,
    internalReferralValue,
  ]);

  useEffect(() => {
    if (referralToggle) {
      addMandatoryFields();
    }

    if (!tradeInToggle) {
      orderContext.removeError("tradeInDetails");
      orderContext.removeError("tradeInType");
      orderContext.removeError("tradeInCategory");
      orderContext.removeError("vin");
    }
  }, []);

  useEffect(() => {
    let isValid = isValidReferalDetailsSection();
    orderContext.updateFormValidation(0, isValid);
    enableNextButton(isValid);
  });

  const isValidReferalDetailsSection = (): boolean => {
    const errors = orderContext.getErrors();
    return errors.every((error) => {
      if (error.section === "vehicleDetails") return false;
      if (error.section === "customerDetails") return false;
      if (error.section === "paymentDetails") return false;
      if (error.section === "deliveryDetails") return false;
      if (error.section === "referalDetails") return false;
      if (tradeInToggle && error.section === "tradeInDetails") return false;

      return true;
    });
  };

  const addMandatoryFields = () => {
    orderContext.setErrors({
      field: "firstName",
      message: "",
      section: "referalDetails",
    });
    orderContext.setErrors({
      field: "lastName",
      message: "",
      section: "referalDetails",
    });
    orderContext.setErrors({
      field: "street",
      message: "",
      section: "referalDetails",
    });
    orderContext.setErrors({
      field: "houseNumber",
      message: "",
      section: "referalDetails",
    });
    orderContext.setErrors({
      field: "postalCode",
      message: "",
      section: "referalDetails",
    });
    orderContext.setErrors({
      field: "city",
      message: "",
      section: "referalDetails",
    });
  };

  const removeMandatoryFields = () => {
    orderContext.removeError("firstName");
    orderContext.removeError("lastName");
    orderContext.removeError("street");
    orderContext.removeError("houseNumber");
    orderContext.removeError("postalCode");
    orderContext.removeError("city");
  };

  const addInternalEmployeeFields = () => {
    orderContext.setErrors({
      field: "internalFirstName",
      message: "",
      section: "referalDetails",
    });
    orderContext.setErrors({
      field: "internalLastName",
      message: "",
      section: "referalDetails",
    });
    orderContext.setErrors({
      field: "internalStaffNumber",
      message: "",
      section: "referalDetails",
    });
  };

  const removeInternalEmployeeFields = () => {
    orderContext.removeError("internalFirstName");
    orderContext.removeError("internalLastName");
    orderContext.removeError("internalStaffNumber");
  };

  const updateReferralDetails = (referral: OrderDTO["referral"]) => {
    let orderDto = getOrderDetails();
    orderDto["referral"] = referral;
    orderContext.updateCtxOrderDetails(orderDto);
  };

  const getOrderDetails = (): OrderDTO => {
    return orderContext.getCtxOrderDetails();
  };

  const getReferralDetailsFromOrder = (
    orderDto: OrderDTO
  ): OrderDTO["referral"] => {
    return orderDto["referral"] || null;
  };

  const onReferalChange = useCallback(
    (e: CustomEvent<SegmentedControlChangeEvent>) => {
      setReferal(e.detail.value as ReferralType);
      removeMandatoryFields();
      removeInternalEmployeeFields();
      let referralDetails = getReferralDetailsFromOrder(getOrderDetails());
      if (referralDetails === null || referralDetails === undefined) {
        referralDetails = {} as RefDto;
      }
      let referralType = e.detail.value.toString();
      if (referralType === "external (private)" && referralDetails) {
        referralDetails.referralType = "External_Private";
        referralDetails.referralCommission = 0.0;
        addMandatoryFields();
      }
      if (referralType === "external (comm.)" && referralDetails) {
        referralDetails.referralType = "External_Common";
        referralDetails.referralCommission = 0.0;
        addMandatoryFields();
      }
      if (referralType === "internal (empl.)" && referralDetails) {
        const init = async () => {
          const apiResponse = await dcsGetGeneralTabs()
          if (
          apiResponse.status === type.SUCCESS &&
          apiResponse.response?.referallCommissions &&
          apiResponse.response?.referallCommissions.length > 2
          ) {
            const general = apiResponse.response
            setInternalReferralValue(general?.referallCommissions[3].value);
            if (referralDetails) {
              referralDetails.referralType = "Internal_Empl";
              referralDetails.referralCommission =
                general?.referallCommissions[3].value;
              if (refCom.current)
                refCom.current.value = formatCurrencyWithNoUnit(
                  general?.referallCommissions[3].value
                );
            }
          }
        };
        init();
        addInternalEmployeeFields();
      }
      if (referralDetails) setReferralDetails(referralDetails);
      updateReferralDetails(referralDetails);
      updateError("referralType");
      handleModified();
      //setReload(new Date().toLocaleString())
    },
    []
  );

  const updateReferralType = (referralDetails: OrderDTO["referral"]) => {
    if (referralDetails && referralDetails.referralType) return;
    if (referralDetails) {
      referralDetails.referralType = "External_Common";
      updateReferralDetails(referralDetails);
    }
  };

  const handleRefFirstName = (e: ChangeEvent<HTMLInputElement>): void => {
    let referralDetails = getReferralDetailsFromOrder(getOrderDetails());
    if (referralDetails) {
      if (e.target.value.length > 0) {
        referralDetails.surName = e.target.value;
        updateReferralDetails(referralDetails);
        updateError("refFirstName");
        orderContext.removeError("firstName");
      } else {
        referralDetails.surName = null;
        updateReferralDetails(referralDetails);
        orderContext.setErrors({
          field: "firstName",
          message: "",
          section: "referalDetails",
        });
      }

      handleModified();
      setReload(new Date().toLocaleString());
    }
  };

  const handleRefLastName = (e: ChangeEvent<HTMLInputElement>): void => {
    let referralDetails = getReferralDetailsFromOrder(getOrderDetails());
    if (referralDetails) {
      if (e.target.value.length > 0) {
        referralDetails.lastName = e.target.value;
        updateReferralDetails(referralDetails);
        updateError("refLastName");
        orderContext.removeError("lastName");
      } else {
        referralDetails.lastName = null;
        updateReferralDetails(referralDetails);
        orderContext.setErrors({
          field: "lastName",
          message: "",
          section: "referalDetails",
        });
      }

      handleModified();
      setReload(new Date().toLocaleString());
    }
  };

  const handleRefStreet = (e: ChangeEvent<HTMLInputElement>): void => {
    let referralDetails = getReferralDetailsFromOrder(getOrderDetails());
    if (referralDetails) {
      if (e.target.value.length > 0) {
        referralDetails.street = e.target.value;
        updateReferralDetails(referralDetails);
        updateError("refStreet");
        orderContext.removeError("street");
      } else {
        referralDetails.street = null;
        updateReferralDetails(referralDetails);
        orderContext.setErrors({
          field: "street",
          message: "",
          section: "referalDetails",
        });
      }
      // handleModified();
      // setReload(new Date().toLocaleString())
    } else {
      referralDetails = {} as RefDto;
      referralDetails.houseNumber = null;
      updateReferralDetails(referralDetails);
    }

    handleModified();
    setReload(new Date().toLocaleString());
  };

  const handleRefHouseNumber = (e: ChangeEvent<HTMLInputElement>): void => {
    let referralDetails = getReferralDetailsFromOrder(getOrderDetails());
    if (referralDetails) {
      if (e.target.value.length > 0) {
        referralDetails.houseNumber = e.target.value;
        updateReferralDetails(referralDetails);
        updateError("refHouseNumber");
        orderContext.removeError("houseNumber");
      } else {
        referralDetails.houseNumber = null;
        updateReferralDetails(referralDetails);
        orderContext.setErrors({
          field: "houseNumber",
          message: "",
          section: "referalDetails",
        });
      }

      handleModified();
      setReload(new Date().toLocaleString());
    } else {
      referralDetails = {} as RefDto;
      referralDetails.houseNumber = null;
      updateReferralDetails(referralDetails);
    }
  };

  const handleRefPostalCode = (e: ChangeEvent<HTMLInputElement>): void => {
    let referralDetails = getReferralDetailsFromOrder(getOrderDetails());
    if (referralDetails) {
      if (e.target.value.length > 0) {
        referralDetails.postalCode = e.target.value;
        updateReferralDetails(referralDetails);
        updateError("refPostalCode");
        orderContext.removeError("postalCode");
      } else {
        referralDetails.postalCode = null;
        updateReferralDetails(referralDetails);
        orderContext.setErrors({
          field: "postalCode",
          message: "",
          section: "referalDetails",
        });
      }

      handleModified();
      setReload(new Date().toLocaleString());
    }
  };

  const handleRefCity = (e: ChangeEvent<HTMLInputElement>): void => {
    let referralDetails = getReferralDetailsFromOrder(getOrderDetails());
    if (referralDetails) {
      if (e.target.value.length > 0) {
        referralDetails.city = e.target.value;
        updateReferralDetails(referralDetails);
        updateError("refCity");
        orderContext.removeError("city");
      } else {
        referralDetails.city = null;
        updateReferralDetails(referralDetails);
        orderContext.setErrors({
          field: "city",
          message: "",
          section: "referalDetails",
        });
      }

      handleModified();
      setReload(new Date().toLocaleString());
    }
  };

  const handleRefIdCardNumber = (e: ChangeEvent<HTMLInputElement>): void => {
    let referralDetails = getReferralDetailsFromOrder(getOrderDetails());
    if (referralDetails) {
      referralDetails.idCardNumber = e.target.value;
      updateReferralDetails(referralDetails);
      handleModified();
    }
  };

  const handleRefIdCommericalNumber = (
    e: ChangeEvent<HTMLInputElement>
  ): void => {
    let referralDetails = getReferralDetailsFromOrder(getOrderDetails());
    if (referralDetails) {
      referralDetails.commercialRegisterNumber = e.target.value;
      updateReferralDetails(referralDetails);
      handleModified();
    }
  };

  const handleRefInternalFirstName = (
    e: ChangeEvent<HTMLInputElement>
  ): void => {
    let referralDetails = getReferralDetailsFromOrder(getOrderDetails());
    if (referralDetails) {
      if (e.target.value.length > 0) {
        referralDetails.surName = e.target.value;
        updateReferralDetails(referralDetails);
        updateError("refFirstName");
        orderContext.removeError("internalFirstName");
      } else {
        referralDetails.surName = null;
        updateReferralDetails(referralDetails);
        orderContext.setErrors({
          field: "internalFirstName",
          message: "",
          section: "referalDetails",
        });
      }

      handleModified();
      setReload(new Date().toLocaleString());
    }
  };

  const handleRefInternalLastName = (
    e: ChangeEvent<HTMLInputElement>
  ): void => {
    let referralDetails = getReferralDetailsFromOrder(getOrderDetails());
    if (referralDetails) {
      if (e.target.value.length > 0) {
        referralDetails.lastName = e.target.value;
        updateReferralDetails(referralDetails);
        updateError("refLastName");
        orderContext.removeError("internalLastName");
      } else {
        referralDetails.lastName = null;
        updateReferralDetails(referralDetails);
        orderContext.setErrors({
          field: "internalLastName",
          message: "",
          section: "referalDetails",
        });
      }

      handleModified();
      setReload(new Date().toLocaleString());
    }
  };

  const handleRefInternalStaffNumber = (
    e: ChangeEvent<HTMLInputElement>
  ): void => {
    let referralDetails = getReferralDetailsFromOrder(getOrderDetails());
    if (referralDetails) {
      if (e.target.value.length > 0) {
        referralDetails.staffNumber = e.target.value;
        updateReferralDetails(referralDetails);
        updateError("staffNumber");
        orderContext.removeError("internalStaffNumber");
      } else {
        referralDetails.staffNumber = null;
        updateReferralDetails(referralDetails);
        orderContext.setErrors({
          field: "internalStaffNumber",
          message: "",
          section: "referalDetails",
        });
      }
      updateReferralDetails(referralDetails);
      handleModified();
      setReload(new Date().toLocaleString());
    }
  };

  const handleRefInternalCommission = (
    e: ChangeEvent<HTMLInputElement>
  ): void => {
    let referralDetails = getReferralDetailsFromOrder(getOrderDetails());
    if (referralDetails) {
      if (e.target.value.length > 0) {
        referralDetails.referralCommission = formatCurrencyToDecimal(
          e.target.value
        );
        updateReferralDetails(referralDetails);
        orderContext.removeError("referralCommission");
      } else {
        referralDetails.referralCommission = 0.0;
        updateReferralDetails(referralDetails);
        orderContext.setErrors({
          field: "referralCommission",
          message: "",
          section: "referalDetails",
        });
      }

      handleModified();
      setReload(new Date().toLocaleString());
    }
  };

  return (
    <>
      <div style={{ backgroundColor: "white" }}>
        <PGrid>
          <PGridItem size={12}>
            <PorscheFont>
              <span>
                {t("Referral Type")} <span style={{ color: "#D5001B" }}>*</span>
              </span>
            </PorscheFont>
            <div
              id="referralType"
              className={"series-model-error-hide"}
              style={{ marginTop: "8px" }}
            >
              <PorscheFont>
                {t("Please select referral type to proceed further")}{" "}
              </PorscheFont>
            </div>
            <PSegmentedControl
              data-testid="referal"
              value={referal}
              onSegmentedControlChange={onReferalChange}
              className={`pt10 ${disabledClassName}`}
            >
              <PSegmentedControlItem
                value={ReferralType.ExternalPrivate}
                className="btn-shadow mr4"
              >
                {t("External (private)")}
              </PSegmentedControlItem>
              <PSegmentedControlItem
                value={ReferralType.ExternalCommercial}
                className="btn-shadow mr4"
              >
                {t("External (commercial)")}
              </PSegmentedControlItem>
              <PSegmentedControlItem
                value={ReferralType.InternalEmployee}
                className="btn-shadow"
              >
                {t("Internal (employee)")}
              </PSegmentedControlItem>
            </PSegmentedControl>
          </PGridItem>

          {referal === ReferralType.ExternalPrivate && (
            <>
              <PGridItem size={3}>
                <CustomTextField
                  id="refFirstName"
                  type="text"
                  name="firstName"
                  defaultValue={
                    referralDetails?.surName ? referralDetails?.surName : ""
                  }
                  label={t("First name")}
                  // onClick={(e) => handleRefFirstName(e)}
                  onChange={(e) => handleRefFirstName(e)}
                  required={true}
                  disabled={disabled}
                />
              </PGridItem>
              <PGridItem size={3}>
                <CustomTextField
                  id="refLastName"
                  type="text"
                  name="lastName"
                  defaultValue={
                    referralDetails?.lastName ? referralDetails?.lastName : ""
                  }
                  label={t("Last name")}
                  // onClick={(e) => handleRefLastName(e)}
                  onChange={(e) => handleRefLastName(e)}
                  required={true}
                  disabled={disabled}
                />
              </PGridItem>
              <PGridItem size={3}>
                {/* <CustomTextField type="text" name="iban" defaultValue={referralDetails?.iban} label="IBAN" onClick={(e) => handleRefIban(e)} onChange={(e) => handleRefIban(e)} required={true} disabled={disabled}/> */}
              </PGridItem>
              <PGridItem size={4}>
                <CustomTextField
                  id="refStreet"
                  type="text"
                  name="street"
                  defaultValue={
                    referralDetails?.street ? referralDetails?.street : ""
                  }
                  label={t("Street")}
                  // onClick={(e) => handleRefStreet(e)}
                  onChange={(e) => handleRefStreet(e)}
                  required={true}
                  disabled={disabled}
                />
              </PGridItem>
              <PGridItem size={2}>
                <CustomTextField
                  id="refHouseNumber"
                  type="text"
                  name="houseNumber"
                  defaultValue={
                    referralDetails?.houseNumber
                      ? referralDetails?.houseNumber
                      : ""
                  }
                  label={t("House number")}
                  // onClick={(e) => handleRefHouseNumber(e)}
                  onChange={(e) => handleRefHouseNumber(e)}
                  required={true}
                  disabled={disabled}
                />
              </PGridItem>
              <PGridItem size={2}>
                <CustomTextField
                  id="refPostalCode"
                  type="text"
                  name="postalCode"
                  defaultValue={
                    referralDetails?.postalCode
                      ? referralDetails?.postalCode
                      : ""
                  }
                  label={t("Postal code")}
                  // onClick={(e) => handleRefPostalCode(e)}
                  onChange={(e) => handleRefPostalCode(e)}
                  required={true}
                  disabled={disabled}
                />
              </PGridItem>
              <PGridItem size={4}>
                <CustomTextField
                  id="refCity"
                  type="text"
                  name="city"
                  defaultValue={
                    referralDetails?.city ? referralDetails?.city : ""
                  }
                  label={t("City")}
                  // onClick={(e) => handleRefCity(e)}
                  onChange={(e) => handleRefCity(e)}
                  required={true}
                  disabled={disabled}
                />
              </PGridItem>
              <PGridItem size={3}>
                <CustomTextField
                  id="refIdCardNumber"
                  type="text"
                  defaultValue={
                    referralDetails?.idCardNumber
                      ? referralDetails?.idCardNumber
                      : ""
                  }
                  name="idCardNumber"
                  label={t("ID card number (Optional)")}
                  onChange={(e) => handleRefIdCardNumber(e)}
                  required={false}
                  disabled={disabled}
                />
              </PGridItem>
            </>
          )}

          {referal === ReferralType.ExternalCommercial && (
            <>
              <PGridItem size={3}>
                <CustomTextField
                  id="refFirstName"
                  type="text"
                  name="firstName"
                  defaultValue={
                    referralDetails?.surName ? referralDetails?.surName : ""
                  }
                  label={t("First name")}
                  // onClick={(e) => handleRefFirstName(e)}
                  onChange={(e) => handleRefFirstName(e)}
                  required={true}
                  disabled={disabled}
                />
              </PGridItem>
              <PGridItem size={3}>
                <CustomTextField
                  id="refLastName"
                  type="text"
                  name="lastName"
                  defaultValue={
                    referralDetails?.lastName ? referralDetails?.lastName : ""
                  }
                  label={t("Last name")}
                  // onClick={(e) => handleRefLastName(e)}
                  onChange={(e) => handleRefLastName(e)}
                  required={true}
                  disabled={disabled}
                />
              </PGridItem>
              <PGridItem size={3}>
                {/* <CustomTextField type="text" name="iban" defaultValue={referralDetails?.iban} label="IBAN" onClick={(e) => handleRefIban(e)} onChange={(e) => handleRefIban(e)} required={true} disabled={disabled}/> */}
              </PGridItem>
              <PGridItem size={4}>
                <CustomTextField
                  id="refStreet"
                  type="text"
                  name="street"
                  defaultValue={
                    referralDetails?.street ? referralDetails?.street : ""
                  }
                  label={t("Street")}
                  // onClick={(e) => handleRefStreet(e)}
                  onChange={(e) => handleRefStreet(e)}
                  required={true}
                  disabled={disabled}
                />
              </PGridItem>
              <PGridItem size={2}>
                <CustomTextField
                  id="refHouseNumber"
                  type="text"
                  name="houseNumber"
                  defaultValue={
                    referralDetails?.houseNumber
                      ? referralDetails?.houseNumber
                      : ""
                  }
                  label={t("House number")}
                  // onClick={(e) => handleRefHouseNumber(e)}
                  onChange={(e) => handleRefHouseNumber(e)}
                  required={true}
                  disabled={disabled}
                />
              </PGridItem>
              <PGridItem size={2}>
                <CustomTextField
                  id="refPostalCode"
                  type="text"
                  name="postalCode"
                  defaultValue={
                    referralDetails?.postalCode
                      ? referralDetails?.postalCode
                      : ""
                  }
                  label={t("Postal code")}
                  // onClick={(e) => handleRefPostalCode(e)}
                  onChange={(e) => handleRefPostalCode(e)}
                  required={true}
                  disabled={disabled}
                />
              </PGridItem>
              <PGridItem size={4}>
                <CustomTextField
                  id="refCity"
                  type="text"
                  name="city"
                  defaultValue={
                    referralDetails?.city ? referralDetails?.city : ""
                  }
                  label={t("City")}
                  // onClick={(e) => handleRefCity(e)}
                  onChange={(e) => handleRefCity(e)}
                  required={true}
                  disabled={disabled}
                />
              </PGridItem>
              <PGridItem size={3}>
                <CustomTextField
                  id="idCardNumber"
                  type="text"
                  defaultValue={
                    referralDetails?.idCardNumber
                      ? referralDetails?.idCardNumber
                      : ""
                  }
                  name="idCardNumber"
                  label={t("ID card number (Optional)")}
                  onChange={(e) => handleRefIdCardNumber(e)}
                  required={false}
                  disabled={disabled}
                />
              </PGridItem>
              <PGridItem size={4}>
                <CustomTextField
                  id="commercialRegisterNumber"
                  type="text"
                  name="commercialRegisterNumber"
                  defaultValue={
                    referralDetails?.commercialRegisterNumber
                      ? referralDetails?.commercialRegisterNumber
                      : ""
                  }
                  label={t("Commercial register excerpt number (Optional)")}
                  onChange={(e) => handleRefIdCommericalNumber(e)}
                  required={false}
                  disabled={disabled}
                />
              </PGridItem>
            </>
          )}

          {referal === ReferralType.InternalEmployee && (
            <>
              <PGridItem size={3}>
                <CustomTextField
                  id="refFirstName"
                  type="text"
                  name="firstName"
                  defaultValue={
                    referralDetails?.surName ? referralDetails?.surName : ""
                  }
                  label={t("First name")}
                  // onClick={(e) => handleRefInternalFirstName(e)}
                  onChange={(e) => handleRefInternalFirstName(e)}
                  required={true}
                  disabled={disabled}
                />
              </PGridItem>
              <PGridItem size={3}>
                <CustomTextField
                  id="refLastName"
                  type="text"
                  name="lastName"
                  defaultValue={
                    referralDetails?.lastName ? referralDetails?.lastName : ""
                  }
                  label={t("Last name")}
                  // onClick={(e) => handleRefInternalLastName(e)}
                  onChange={(e) => handleRefInternalLastName(e)}
                  required={true}
                  disabled={disabled}
                />
              </PGridItem>
              <PGridItem size={3}>
                <CustomTextField
                  id="staffNumber"
                  type="text"
                  name="staffNumber"
                  defaultValue={
                    referralDetails?.staffNumber
                      ? referralDetails?.staffNumber
                      : ""
                  }
                  label={t("Staff number")}
                  onChange={(e) => handleRefInternalStaffNumber(e)}
                  required={true}
                  disabled={disabled}
                />
              </PGridItem>
              <PGridItem size={3}>
                <PTextFieldWrapper
                  id={`intRefCom`}
                  label={`${t("Referral commission")}`}
                  hideLabel={false}
                  unit="€"
                  unitPosition="suffix"
                  style={{ paddingTop: "23px" }}
                >
                  <input
                    aria-autocomplete="none"
                    autoComplete="off"
                    id={`iintRefCom`}
                    key={`kintRefCom`}
                    type="text"
                    name={`referralCommission`}
                    ref={refCom}
                    defaultValue={
                      referralDetails?.referralCommission
                        ? formatCurrencyWithNoUnit(
                            referralDetails?.referralCommission
                          )
                        : ""
                    }
                    onChange={(e) => {
                      handleRefInternalCommission(e);
                      //e.target.value = formatCurrencyWithNoUnit(formatCurrencyToDecimal(e.target.value))
                    }}
                    required={true}
                    disabled={disabled}
                    readOnly
                    //style={{ textAlign: "right" }}
                  />
                </PTextFieldWrapper>
              </PGridItem>
            </>
          )}
        </PGrid>
      </div>
    </>
  );
};
