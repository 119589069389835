import { useState } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { IconName, PButton, PIcon } from '@porsche-design-system/components-react';
import { useTranslation } from 'react-i18next';

interface Props {
  tabIndex: number,
  message: string,
  handleSave: (tabIndex: number) => void;
  disable?: boolean | false;
}

export const SaveDialog = (props: Props) => {
  const { tabIndex, message, handleSave, disable } = props;
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSaveDialog = () => {
    handleSave(tabIndex);
    setOpen(false);
  }

  return (
    <div>
      <PButton 
        variant="secondary" 
        icon={"save" as IconName} 
        disabled={disable ? true : false}
        onClick={handleClickOpen}
      >
        {t("Save as draft")}
      </PButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id={`save-dialog-title-${tabIndex}`}>
          
        </DialogTitle>
        <DialogContent>
          <DialogContentText id={`save-dialog-description-${tabIndex}`}>
            { message }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSaveDialog}>{t("Save")}</Button>
          <Button onClick={handleClose} autoFocus>
            {t("Cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
