import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  PDivider, PGrid, PGridItem, PTextFieldWrapper
} from '@porsche-design-system/components-react';

import { PorscheFont } from '../../../../assets/styles/CustomStyles';
import { defaultScaling } from '../../../../helpers/commission';
import {
  CategoryCommissionDTO, CommissionScalingDTO
} from '../../../../interfaces/commission';
import {
  formatCurrencyToDecimal, formatCurrencyWithNoUnit, onBlurPercentageFormatter,
  validatePercentage
} from '../../../../mock/helper';
import { type } from '../../../../services/Constants';
import { dcsgetCommissionScaling, getLogedInOrigin } from '../../../../services/data/Helpers';
import ScrollToTop from '../../../common/ScrollToTop';
import { Activation } from './Activation';
import { NewCarCom } from './NewCarCom';
import { TiresAndParts } from './TiresAndParts';

interface Props {
  name: string;
  edit: boolean;
  save: boolean;
  cancel: boolean;
  handleCommissionPost: (commissionPost: CommissionScalingDTO) => void;
  modified: () => void;
  setShowSaveButton: Dispatch<SetStateAction<boolean>>;
}
export const Scaling = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const { edit, save, cancel, handleCommissionPost, modified, name, setShowSaveButton } = props;
  const [reload, setReload] = useState("");
  const [gross, setGross] = useState("");
  const [pfs,setPfs] = useState("");
  const [leasing,setLeasing] = useState("");
  const [gap,setGap] = useState("");
  const [servicePlus,setServicePlus] = useState("");
  const [carPolicy,setCarPolicy] = useState("");
  const [porscheCardS,setPorscheCardS] = useState("");
  const [edited, setEdit] = useState<boolean>(false);
  const [errorState, setErrorState] = useState<Record<string,boolean>>()


  const [scalingobj, setScalingobj] = useState<CommissionScalingDTO>(
    {} as CommissionScalingDTO
  );

  const errorStateHandler= (wrapperId:string , state:boolean)=>{
    setErrorState((prev)=>{
      return {...prev, [wrapperId]:state}
    })
  }

  useEffect(()=>{
    if(errorState){
    const finalValue  = Object.values(errorState).includes(true)
    setShowSaveButton(!finalValue)
  }
  },[errorState])

  useEffect(() => {
    const init = async () => {
     const apiResponse= await dcsgetCommissionScaling()
    if(apiResponse.status === type.SUCCESS && apiResponse.response ){
      const dataSet = apiResponse.response as Array<CommissionScalingDTO>
      const scaling1 = dataSet.filter(x => x.name === name);
        if(scaling1.length > 0) {
          setScalingobj(scaling1[0]);
          setPfs(formatCurrencyWithNoUnit(scaling1[0].pfs));
          setLeasing(formatCurrencyWithNoUnit(scaling1[0].leasings));
          setGap(formatCurrencyWithNoUnit(scaling1[0].gap));
          setServicePlus(formatCurrencyWithNoUnit(scaling1[0].servicePlus));
          setCarPolicy(formatCurrencyWithNoUnit(scaling1[0].carPolicy));
          setPorscheCardS(formatCurrencyWithNoUnit(scaling1[0].porscheCardS));
          // setReload(new Date().toLocaleString());
        } else {
          defaultScaling.origin = getLogedInOrigin();
          defaultScaling.name = name;
          setScalingobj(defaultScaling);
        }
    } else {
        defaultScaling.origin = getLogedInOrigin();
        defaultScaling.name = name;
        setScalingobj(defaultScaling);
      }
    };
    // setScalingobj(defaultScaling);
    init();
  }, []);

  useEffect(() => {

  }, [reload]);

  const handleCommissionScaling = (commissionObj: CommissionScalingDTO) => {
    setScalingobj(commissionObj);
    modified();
    handleCommissionPost(commissionObj);
    setReload(new Date().toLocaleString());
  };
  const handleCategoryCommission = (
    i: number,
    catObj: CategoryCommissionDTO
  ) => {
    scalingobj.commissions[i] = catObj;
    setScalingobj(scalingobj);
    modified();
    handleCommissionPost(scalingobj);
    setReload(new Date().toLocaleString());
  };

  const handlePfs = (e: ChangeEvent<HTMLInputElement>,elementId: string) => {
    const dom = document.getElementById(`${elementId}`);
    let domId= dom?.id as string
      if(e.target.value.length >0){
        setEdit(true);
        if(validatePercentage(e.target.value)) {
          if(dom) {
            dom.setAttribute("state", "none");
            errorStateHandler(domId, false)
          }
          scalingobj.pfs = formatCurrencyToDecimal(e.target.value);
          setGross(e.target.value);
          setScalingobj(scalingobj);
          modified();
          handleCommissionPost(scalingobj);
          setReload(new Date().toLocaleString());          
      }else {
        if(dom) {
          dom.setAttribute("state", "error");
          errorStateHandler(domId, true)
          // dom.setAttribute("message", t("Please enter a valid value."));
        }
      }
    } else {
      if(dom) {
        dom.setAttribute("state", "error");
        errorStateHandler(domId, true)
        // dom.setAttribute("message", t("Please enter a valid value."));
      }
    }
  }

  const handleLeasings = (e: ChangeEvent<HTMLInputElement>, elementId: string) => {
    const dom = document.getElementById(`${elementId}`);
    let domId= dom?.id as string
    if(e.target.value.length >0){
      setEdit(true);  
      if (validatePercentage(e.target.value)) {
        if(dom) {
          dom.setAttribute("state", "none");
          errorStateHandler(domId, false)
        }
        scalingobj.leasings = formatCurrencyToDecimal(e.target.value);
        setGross(e.target.value);
        setScalingobj(scalingobj);
        modified();
        handleCommissionPost(scalingobj);
        setReload(new Date().toLocaleString());               
      }else {
        if(dom) {
          dom.setAttribute("state", "error");
          errorStateHandler(domId, true)
          // dom.setAttribute("message", t("Please enter a valid value."));
        }
      }
    } else {
      if(dom) {
        dom.setAttribute("state", "error");
        errorStateHandler(domId, true)
        // dom.setAttribute("message", t("Please enter a valid value."));
      }
    }
  }   

  const handleGap = (e: ChangeEvent<HTMLInputElement>, elementId: string) => {
    const dom = document.getElementById(`${elementId}`);
    let domId= dom?.id as string
    if(e.target.value.length >0){
      setEdit(true);
      if (validatePercentage(e.target.value)) {
          if(dom) {
            dom.setAttribute("state", "none");
            errorStateHandler(domId, false)
          }
          scalingobj.gap = formatCurrencyToDecimal(e.target.value);
          setGross(e.target.value);
          setScalingobj(scalingobj);
          modified();
          handleCommissionPost(scalingobj);
          setReload(new Date().toLocaleString());
      } else {
        if(dom) {
          dom.setAttribute("state", "error");
          errorStateHandler(domId, true)
          // dom.setAttribute("message", t("Please enter a valid value."));
        }
      }
    } else {
      if(dom) {
        dom.setAttribute("state", "error");
        errorStateHandler(domId, true)
        // dom.setAttribute("message", t("Please enter a valid value."));
      }
    }
  } 

  const handleServicePlus = (e: ChangeEvent<HTMLInputElement>, elementId: string) => {
    const dom = document.getElementById(`${elementId}`);
    let domId= dom?.id as string
    if(e.target.value.length >0){
      setEdit(true);
      if (validatePercentage(e.target.value)) {
        if(dom) {
          dom.setAttribute("state", "none");
          errorStateHandler(domId, false)
        }
      scalingobj.servicePlus = formatCurrencyToDecimal(e.target.value);
      setGross(e.target.value);
      setScalingobj(scalingobj);
      modified();
      handleCommissionPost(scalingobj);
      setReload(new Date().toLocaleString());
    } else {
      if(dom) {
        dom.setAttribute("state", "error");
        errorStateHandler(domId, true)
        // dom.setAttribute("message", t("Please enter a valid value."));
      }
    }
    } else {
      if(dom) {
        dom.setAttribute("state", "error");
        errorStateHandler(domId, true)
        // dom.setAttribute("message", t("Please enter a valid value."));
      }
    }
  }
  const  handlePorscheCard = (e: ChangeEvent<HTMLInputElement>, elementId: string) => {
    const dom = document.getElementById(`${elementId}`);
    let domId= dom?.id as string
    if(e.target.value.length >0){
      setEdit(true);
      if (validatePercentage(e.target.value)) {
        if(dom) {
           dom.setAttribute("state", "none");
           errorStateHandler(domId, false)
        }
        scalingobj.porscheCardS = formatCurrencyToDecimal(e.target.value);
        setGross(e.target.value);
        setScalingobj(scalingobj);
        modified();
        handleCommissionPost(scalingobj);
        setReload(new Date().toLocaleString());
      } else {
        if(dom) {
          dom.setAttribute("state", "error");
          errorStateHandler(domId, true)
          // dom.setAttribute("message", t("Please enter a valid value."));
        }
      }
    } else {
      if(dom) {
        dom.setAttribute("state", "error");
        errorStateHandler(domId, true)
        // dom.setAttribute("message", t("Please enter a valid value."));
      }
    }
  }

  const handleCarPolicy = (e: ChangeEvent<HTMLInputElement>, elementId: string) => {
    const dom = document.getElementById(`${elementId}`);
    let domId= dom?.id as string
    if(e.target.value.length >0){
      setEdit(true);
      if (validatePercentage(e.target.value)) { 
        if(dom) {
           dom.setAttribute("state", "none");
           errorStateHandler(domId, false)
        }
        scalingobj.carPolicy = formatCurrencyToDecimal(e.target.value);
        setGross(e.target.value);
        setScalingobj(scalingobj);
        modified();
        handleCommissionPost(scalingobj);
        setReload(new Date().toLocaleString());
      } else {
        if(dom) {
          dom.setAttribute("state", "error");
          errorStateHandler(domId, true)
          // dom.setAttribute("message", t("Please enter a valid value."));
        }
      }
    } else {
      if(dom) {
        dom.setAttribute("state", "error");
        errorStateHandler(domId, true)
        // dom.setAttribute("message", t("Please enter a valid value."));
      }
    }
  }

  return (
    <>
      <ScrollToTop />
      <div>
        {scalingobj.commissions && (
          <Activation
            key={"activation"}
            commissions={scalingobj}
            handleCommissionPost={(catObj) => handleCommissionScaling(catObj)}
            edit={edit}
          />
        )}

      </div>

      {/** "New Car (Configured)" */}
      {scalingobj.commissions &&
        scalingobj.commissions?.map((x, i) => {
          if (x.commissionScalingCategoryType === "NewCarConfigured") {
            return (
              <>
                <div className="p1">
                  <NewCarCom
                    commissions={x}
                    handleCommissionPost={(j, catObj) =>
                      handleCategoryCommission(j, catObj)
                    }
                    cindex={i}
                    edit={edit}
                    description={x.displayLabel}
                    customerType={x.customerType ? x.customerType : ""}
                    collection={x.collection ? x.collection : ""}
                    setShowSaveButton={setShowSaveButton}
                    setErrorState={setErrorState}
                    errorState={errorState}
                  />
                </div>
              </>
            );
          }
        })}

      {/** "New Car (Stock)" */}
      {scalingobj.commissions &&
        scalingobj.commissions?.map((x, i) => {
          if (x.commissionScalingCategoryType === "NewCarStock") {
            return (
              <>
                <div className="p1">
                  <NewCarCom
                    commissions={x}
                    handleCommissionPost={(j, catObj) =>
                      handleCategoryCommission(j, catObj)
                    }
                    cindex={i}
                    edit={edit}
                    description={x.displayLabel}
                    customerType={x.customerType ? x.customerType : ""}
                    collection={x.collection ? x.collection : ""}
                    setShowSaveButton={setShowSaveButton}
                    setErrorState={setErrorState}
                    errorState={errorState}
                  />
                </div>
              </>
            );
          }
        })}

      {/** "New Car (Special case)" */}
      {scalingobj.commissions &&
        scalingobj.commissions?.map((x, i) => {
          if (x.commissionScalingCategoryType === "NewCarSpecialCase") {
            return (
              <>
                <div className="p1">
                  <NewCarCom
                    commissions={x}
                    handleCommissionPost={(j, catObj) =>
                      handleCategoryCommission(j, catObj)
                    }
                    cindex={i}
                    edit={edit}
                    description={x.commissionScalingCategoryType}
                    customerType={x.customerType ? x.customerType : ""}
                    collection={x.collection ? x.collection : ""}
                    setShowSaveButton={setShowSaveButton}
                    setErrorState={setErrorState}
                    errorState={errorState}
                  />
                </div>
              </>
            );
          }
        })}

      {/** Demo Car (Pre-Sold) */}
      {scalingobj.commissions &&
        scalingobj.commissions?.map((x, i) => {
          if (x.commissionScalingCategoryType === "DemoCarPreSold") {
            return (
              <>
                <div className="p1">
                  <NewCarCom
                    commissions={x}
                    handleCommissionPost={(j, catObj) =>
                      handleCategoryCommission(j, catObj)
                    }
                    cindex={i}
                    edit={edit}
                    description={x.displayLabel}
                    customerType={x.customerType ? x.customerType : ""}
                    collection={x.collection ? x.collection : ""}
                    setShowSaveButton={setShowSaveButton}
                    setErrorState={setErrorState}
                    errorState={errorState}
                  />
                </div>
              </>
            );
          }
        })}

      {/** Demo Car (Stock) */}
      {scalingobj.commissions &&
        scalingobj.commissions?.map((x, i) => {
          if (x.commissionScalingCategoryType === "DemoCarStock") {
            return (
              <>
                <div className="p1">
                  <NewCarCom
                    commissions={x}
                    handleCommissionPost={(j, catObj) =>
                      handleCategoryCommission(j, catObj)
                    }
                    cindex={i}
                    edit={edit}
                    description={x.displayLabel}
                    customerType={x.customerType ? x.customerType : ""}
                    collection={x.collection ? x.collection : ""}
                    setShowSaveButton={setShowSaveButton}
                    setErrorState={setErrorState}
                    errorState={errorState}
                  />
                </div>
              </>
            );
          }
        })}

      {/** Used Car (Stock) */}
      {scalingobj.commissions &&
        scalingobj.commissions?.map((x, i) => {
          if (x.commissionScalingCategoryType === "UsedCarStock") {
            return (
              <>
                <div className="p1">
                  <NewCarCom
                    commissions={x}
                    handleCommissionPost={(j, catObj) =>
                      handleCategoryCommission(j, catObj)
                    }
                    cindex={i}
                    edit={edit}
                    description={x.displayLabel}
                    customerType={x.customerType ? x.customerType : ""}
                    collection={x.collection ? x.collection : ""}
                    setShowSaveButton={setShowSaveButton}
                    setErrorState={setErrorState}
                    errorState={errorState}
                  />
                </div>
              </>
            );
          }
        })}

      {/** Used Car (Special Case) */}
      {scalingobj.commissions &&
        scalingobj.commissions?.map((x, i) => {
          if (
            x.commissionScalingCategoryType ===
            "UsedCarSpecialCasePrivateCustomer"
          ) {
            return (
              <>
                <div className="p1">
                  <NewCarCom
                    commissions={x}
                    handleCommissionPost={(j, catObj) =>
                      handleCategoryCommission(j, catObj)
                    }
                    cindex={i}
                    edit={edit}
                    description={x.commissionScalingCategoryType}
                    customerType={x.customerType ? x.customerType : ""}
                    collection={x.collection ? x.collection : ""}
                    setShowSaveButton={setShowSaveButton}
                    setErrorState={setErrorState}
                    errorState={errorState}
                  />
                </div>
              </>
            );
          }
        })}

      {/** Used Car (Special Case) */}
      {scalingobj.commissions &&
        scalingobj.commissions?.map((x, i) => {
          if (x.commissionScalingCategoryType === "UsedCarSpecialCaseDealer") {
            return (
              <>
                <div className="p1">
                  <NewCarCom
                    commissions={x}
                    handleCommissionPost={(j, catObj) =>
                      handleCategoryCommission(j, catObj)
                    }
                    cindex={i}
                    edit={edit}
                    description={x.commissionScalingCategoryType}
                    customerType={x.customerType ? x.customerType : ""}
                    collection={x.collection ? x.collection : ""}
                    setShowSaveButton={setShowSaveButton}
                    setErrorState={setErrorState}
                    errorState={errorState}
                  />
                </div>
              </>
            );
          }
        })}

      {/** Used Car (Special Case) */}
      {scalingobj.commissions &&
        scalingobj.commissions?.map((x, i) => {
          if (
            x.commissionScalingCategoryType ===
            "UsedCarSpecialCaseDealerBiddingPlatform"
          ) {
            return (
              <>
                <div className="p1">
                  <NewCarCom
                    commissions={x}
                    handleCommissionPost={(j, catObj) =>
                      handleCategoryCommission(j, catObj)
                    }
                    cindex={i}
                    edit={edit}
                    description={x.commissionScalingCategoryType}
                    customerType={x.customerType ? x.customerType : ""}
                    collection={x.collection ? x.collection : ""}
                    setShowSaveButton={setShowSaveButton}
                    setErrorState={setErrorState}
                    errorState={errorState}
                  />
                </div>
              </>
            );
          }
        })}

      {/** Used Car (Special Case) */}
      <div className="p1">
        <p className="dcs-section-heading">{t("Tires & Parts")}</p>
        <br></br>
        {scalingobj.commissions &&
          scalingobj.commissions?.map((x, i) => {
            if (x.commissionScalingCategoryType === "CompleteWinterTires") {
              return (
                <TiresAndParts
                  commissions={x}
                  handleCommissionPost={(j, catObj) =>
                    handleCategoryCommission(j, catObj)
                  }
                  cindex={i}
                  edit={edit}
                  title={"Complete Winter Tires"}
                  setErrorState={setErrorState}
                  errorState={errorState}
                />
              );
            } else if (x.commissionScalingCategoryType === "PDSTequipment") {
              return (
                <>
                  <div style={{ marginTop: "25px" }}>
                    <TiresAndParts
                      commissions={x}
                      handleCommissionPost={(j, catObj) =>
                        handleCategoryCommission(j, catObj)
                      }
                      cindex={i}
                      edit={edit}
                      title={"PDS / Tequipment"}
                      setErrorState={setErrorState}
                      errorState={errorState}
                    />
                  </div>
                </>
              );
            } else {
              return <></>;
            }
          })}
      </div>

      {/* PFS & Car Policy */}

      <div className="p1">
        <PorscheFont className="dcs-section-heading">{`${t(
          "PFS & Car Policy"
        )}`}</PorscheFont>
        <br></br>
        <PGrid>
          <PGridItem size={2}></PGridItem>
          <PGridItem size={2} className="dcs-heading">
            <PorscheFont>
              {t("Seller share")}
              <span style={{ color: "#D5001B" }}>*</span>
            </PorscheFont>
          </PGridItem>

          <PGridItem size={12}>
            <PDivider
              color="neutral-contrast-high"
              style={{ width: "700px" }}
            ></PDivider>
            <br></br>
          </PGridItem>
        </PGrid>
        <PGrid>
          <PGridItem size={2} style={{ paddingTop: "15px" }}>
            <PorscheFont>PFS</PorscheFont>
          </PGridItem>
          <PGridItem size={3}>
            <PTextFieldWrapper id="pfs" unit="%" unitPosition="suffix">
              <input
                aria-autocomplete="none"
                autoComplete="off"
                id="ipfs"
                key="ipfs"
                type="text"
                defaultValue={pfs}
                onChange={(e) => handlePfs(e, "pfs")}
                onBlur={(e) => {
                  onBlurPercentageFormatter(e);
                }}
                //style={{textAlign: "right"}}
                disabled={edit ? false : true}
              />
            </PTextFieldWrapper>
          </PGridItem>
        </PGrid>
        <br></br>
        <PGrid>
          <PGridItem size={2} style={{ paddingTop: "15px" }}>
            <PorscheFont>Leasing S</PorscheFont>
          </PGridItem>
          <PGridItem size={3}>
            <PTextFieldWrapper id="leasings" unit="%" unitPosition="suffix">
              <input
                aria-autocomplete="none"
                autoComplete="off"
                id="ileasings"
                key="kleasings"
                type="text"
                defaultValue={leasing}
                onChange={(e) => handleLeasings(e, "leasings")}
                onBlur={(e) => {
                  onBlurPercentageFormatter(e);
                }}
                //style={{textAlign: "right"}}
                disabled={edit ? false : true}
              />
            </PTextFieldWrapper>
          </PGridItem>
        </PGrid>
        <br></br>
        {/* newlyadded */}
        <PGrid>
          <PGridItem size={2} style={{ paddingTop: "15px" }}>
            <PorscheFont>GAP</PorscheFont>
          </PGridItem>
          <PGridItem size={3}>
            <PTextFieldWrapper id="gap" unit="%" unitPosition="suffix">
              <input
                aria-autocomplete="none"
                autoComplete="off"
                id="igap"
                key="kgap"
                type="text"
                defaultValue={gap}
                onChange={(e) => handleGap(e, "gap")}
                onBlur={(e) => {
                  onBlurPercentageFormatter(e);
                }}
                //style={{textAlign: "right"}}
                disabled={edit ? false : true}
              />
            </PTextFieldWrapper>
          </PGridItem>
        </PGrid>
        <br></br>
        <PGrid>
          <PGridItem size={2} style={{ paddingTop: "15px" }}>
            <PorscheFont>{t("Service Plus")}</PorscheFont>
          </PGridItem>
          <PGridItem size={3}>
            <PTextFieldWrapper id="splus" unit="%" unitPosition="suffix">
              <input
                aria-autocomplete="none"
                autoComplete="off"
                id="isplus"
                key="ksplus"
                type="text"
                defaultValue={servicePlus}
                onChange={(e) => handleServicePlus(e, "splus")}
                onBlur={(e) => {
                  onBlurPercentageFormatter(e);
                }}
                //style={{textAlign: "right"}}
                disabled={edit ? false : true}
              />
            </PTextFieldWrapper>
          </PGridItem>
        </PGrid>
        &nbsp;
        <PGrid>
          <PGridItem size={2} style={{ paddingTop: "15px" }}>
            <PorscheFont>Car Policy</PorscheFont>
          </PGridItem>
          <PGridItem size={3}>
            <PTextFieldWrapper id="carPolicy" unit="%" unitPosition="suffix">
              <input
                aria-autocomplete="none"
                autoComplete="off"
                id="icarPolicy"
                key="kcarPolicy"
                type="text"
                defaultValue={carPolicy}
                onChange={(e) => handleCarPolicy(e, "carPolicy")}
                onBlur={(e) => {
                  onBlurPercentageFormatter(e);
                }}
                //style={{textAlign: "right"}}
                disabled={edit ? false : true}
              />
            </PTextFieldWrapper>
          </PGridItem>
        </PGrid>
        <br></br>
        {/* newlyadded */}
        <PGrid>
          <PGridItem size={2} style={{ paddingTop: "15px" }}>
            <PorscheFont>Porsche Card S</PorscheFont>
          </PGridItem>
          <PGridItem size={3}>
            <PTextFieldWrapper id="porschecard" unit="%" unitPosition="suffix">
              <input
                aria-autocomplete="none"
                autoComplete="off"
                id="iporscheCard"
                key="kporscheCard"
                type="text"
                defaultValue={porscheCardS}
                onChange={(e) => handlePorscheCard(e, "porschecard")}
                onBlur={(e) => {
                  onBlurPercentageFormatter(e);
                }}
                //style={{textAlign: "right"}}
                disabled={edit ? false : true}
              />
            </PTextFieldWrapper>
          </PGridItem>
        </PGrid>
      </div>
    </>
  );
};
