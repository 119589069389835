import './assets/styles/Approval.css';
import './assets/styles/Calculation.css';
import './assets/styles/ChangeHistory.css';
import './assets/styles/General.css';
import './assets/styles/ManualCommission.css';
import './assets/styles/Order.css';
import './assets/styles/Print.css';
import './assets/styles/Recalculation.css';
import './assets/styles/Sammary.css';
import './assets/styles/Tasks.css';
import './assets/styles/TransferPickupRegistration.css';
import './assets/styles/UserManagement.css';
import './assets/styles/Variables.css';
import './index.css';

import { useEffect, useState } from 'react';
import { HashRouter as Router } from 'react-router-dom';

import { Main } from './components/Main';
import { Routing } from './components/Routing';
import { AuthContext } from './contexts/AuthContext';
import { GeneralContext } from './contexts/GeneralContext';
import { OrderContext } from './contexts/OrderContext';
import { TransferPickupRegistrationContext } from './contexts/TransferPickupRegistrationContext';
import { ActivationStatus } from './pages/ActivationStatus';
import { LoginPage } from './pages/LoginPage';
import { Auth } from './services/Auth';
import { Generalctx } from './services/General';
import { Order } from './services/Order';
import { TransferPickupRegistrationctx } from './services/TransferPickupRegistration';
import { dcsgetUserActivationStatus } from './services/data/Helpers';
import { type } from './services/Constants';

export const App = (): JSX.Element => {
  const [activated, setActivated] = useState(true)
  let isLoggedIn = true;

  useEffect(()=>{
    const callActivationStatus = async()=>{
      const apiResponse =  await dcsgetUserActivationStatus()
      if(apiResponse.status===type.SUCCESS){}
      setActivated(apiResponse.response?.active)

    }
    callActivationStatus()
  },[])

  return (
    <>
    <AuthContext.Provider value={Auth}>
    <OrderContext.Provider value={Order}>
    <GeneralContext.Provider value={Generalctx}>
      <TransferPickupRegistrationContext.Provider value={TransferPickupRegistrationctx}>
      <div className="container">
        <Router>
          { isLoggedIn && activated ? (
            <>
              <Main /> 
              <Routing setActivated={setActivated} />
            </>
          ) : isLoggedIn && !activated?(<ActivationStatus/>):  (
            <LoginPage />
          )}
          
        </Router>
      </div>
      </TransferPickupRegistrationContext.Provider> 
     </GeneralContext.Provider>
    </OrderContext.Provider>
    </AuthContext.Provider>
    </>
  );
};
