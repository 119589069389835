import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import {
  PButton,
  PButtonGroup,
  PTextFieldWrapper,
} from "@porsche-design-system/components-react";

import { PorscheFont } from "../../../assets/styles/CustomStyles";
import { dcsgetCommissionHistoryExportDetails } from "../../../services/data/Helpers";
import * as ExcelJS from "exceljs";
import { type } from "../../../services/Constants";

interface Props {
  handleClosePopup: () => void;
}

export const HistoryExport = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const { handleClosePopup} = props;
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(false);
  const [exportData, setExportData] = useState([]);

  const handleSuccessClose = () => {
    setSaveSuccess(false);
    handleClosePopup();
  };
  const handleErrorClose = () => {
    setIsError(false);
  };

  const handleDownload = () => {
    if (exportData.length < 1) {
      setIsError(true);
      return;
    }
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");

    // Adding headers in table
    const headers = Object.keys(exportData[0]);
    worksheet.addRow(headers);

    // Add data rows in table
    exportData.forEach((row) => {
      const values = Object.values(row);
      worksheet.addRow(values);
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
      const data = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(data);
      const link = document.createElement("a");
      link.href = url;
      link.download = `Commission-data.xlsx`;
      link.click();
    });
    setSaveSuccess(true);
  };

  // Fetching data from api fot excel sheet onchange of month
  const handleInputMonthChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelected(true);
    setLoading(true);
         const apiResponse = await dcsgetCommissionHistoryExportDetails(e.target.value)
      if(apiResponse.status===type.SUCCESS){
        setExportData(apiResponse.response);
      }
      if(apiResponse.status===type.ERROR){
        setIsError(false)
      }
    setLoading(false);
  };

  return (
    <>
      <div style={{ height: "220px" }}>
        <PorscheFont className="sub-headding" style={{paddingLeft:"0"}}>
          {t("Export commission accounting")}
        </PorscheFont>
        <br></br>
        <PorscheFont>
          {t("Please select the month of accounting for the export")}
        </PorscheFont>
        <br></br>
        <PTextFieldWrapper
          id="accountingMonthWrap"
          label={`${t("Month of Accounting")}`}
        >
          <input
            id="accontingMonth"
            type="month"
            name="accontingMonth"
            required
            onChange={handleInputMonthChange}
          />
        </PTextFieldWrapper>
        <br></br>
        <PButtonGroup>
          <PButton variant="tertiary" icon="close" onClick={handleClosePopup}>
            {t("Cancel")}
          </PButton>
          <PButton
            disabled={loading || !selected}
            loading={loading}
            variant="secondary"
            onClick={handleDownload}
          >
            {t("Export")}
          </PButton>
        </PButtonGroup>
      </div>

      <div className="flex30 pt20">
        <Snackbar
          open={saveSuccess}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={1000}
          onClose={handleSuccessClose}
          style={{ zIndex: 9999, marginTop: "14px" }}
        >
          <Alert severity="success" sx={{ width: "100%" }}>
            {t('Export success')}
          </Alert>
        </Snackbar>
        <Snackbar
          open={isError}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={2000}
          onClose={handleErrorClose}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            {t('Export failure')}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};
