import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Alert, Button, Divider, Stack, Tooltip } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { PButton, PButtonGroup } from '@porsche-design-system/components-react';

import { PorscheFont } from '../../../assets/styles/CustomStyles';

//import { TaskRow } from './TaskRow';

interface Props {
  handleCloseStartRecalc: () => void;
  orderId: number,
  isDraft:boolean;
  handleStartRecalc: () => void;
}

export const StartRecalc = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const { handleCloseStartRecalc, orderId, isDraft, handleStartRecalc } = props;
  const [edit, setEdit] = useState(false);
  const [reload, setReload] = useState("");
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [saveSuccessMsg, setSaveSuccessMsg] = useState("");
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();

  let loggedInUserId = 1;
  const sessionUser = sessionStorage.getItem("userDetails");
  if (sessionUser) {
    const user = JSON.parse(sessionUser);
    loggedInUserId = user.id;
  }

  const handleCancel = () => {
    handleCloseStartRecalc();
  }
  const handleSuccessClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return false;
    }
    setSaveSuccess(false);
  };
  const handleErrorClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return false;
    }
    setIsError(false);
  };

  const handleYes = () => {
    handleStartRecalc();
  }

  return (
    <>
      <div style={{ height: "90px",margin:"-21px" }}>
        <PorscheFont className="sub-headding">{t("Start Recalculation")}?</PorscheFont><br></br>
        <PButtonGroup style={{marginTop:"15px"}}>
          <Stack direction="row" spacing={1}>
            <PButton variant="tertiary" icon='close' onClick={handleCancel}>{t("No, cancel")}</PButton>
            <PButton variant="secondary" onClick={handleYes}>{t("Yes, start")}</PButton>
          </Stack>
        </PButtonGroup>
      </div>
      <div className="flex30 pt20">
        <Snackbar
          open={saveSuccess}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={2000}
          onClose={handleSuccessClose}
        >
          <Alert severity="success" sx={{ width: "100%" }}>
            {saveSuccessMsg}
          </Alert>
        </Snackbar>
        <Snackbar
          open={isError}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={2000}
          onClose={handleErrorClose}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
          {t("Error while converting!")}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
}

