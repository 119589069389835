import { PTextareaWrapper } from '@porsche-design-system/components-react';

interface Props {
    name: string;
    label: string;
    type: string;
    disabled?: boolean | false;
    placeHolder?: string | "";
    value?:string | "";
    defaultValue?: string | null;
    onChange?: (e: any) => void;
}

export const CustomTextarea = (props: Props): JSX.Element => {
  const { name, label, type, disabled, placeHolder, value, defaultValue, onChange } = props;
  
  return (
    <PTextareaWrapper label={label}>
      <textarea 
        aria-autocomplete='none'
        autoComplete='off'
        name={name} 
        value={value} 
        defaultValue={defaultValue ? defaultValue : ""}
        onChange={(e) => onChange ? onChange(e) : {}}
        disabled={disabled ? disabled : false}
      />
    </PTextareaWrapper>
  );
}