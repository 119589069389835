import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { PDivider, PFlex, PFlexItem, PIcon } from '@porsche-design-system/components-react';
import { PorscheFont } from '../../../assets/styles/CustomStyles';
import { subsqCommApprovalType } from './SubsequentApprovalTab';

interface Props {
 handleClose: () => void;
 commissioningData: subsqCommApprovalType;
}
export const SubsequentLogBook = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const { handleClose,  commissioningData } = props;

    return (
        <>
            <div style={{height: "700px", width: "800px"}}>
                <PorscheFont className="dcs-section-heading" >{t("Logbook")}</PorscheFont><br></br>
                <Stack direction="row">
                    <PIcon name="arrow-left" aria={{ 'aria-label': 'Arrow Left icon' }} onClick={handleClose} />&nbsp;
                    <PorscheFont className="tn-text-16-400">{t("Approval overview")}</PorscheFont>
                </Stack><br></br>
                <PDivider color="neutral-contrast-low" /><br></br>
                <PFlex>
                    <PFlexItem style={{ width: '20%' }} className="sub-headding">
                        <PorscheFont className="dcs-heading">{t("Date")}</PorscheFont>
                    </PFlexItem>
                    <PFlexItem style={{ width: '20%' }} className="sub-headding">
                        <PorscheFont className="dcs-heading">{t("User")}</PorscheFont>
                    </PFlexItem>
                    <PFlexItem style={{ width: '20%' }} className="sub-headding">
                        <PorscheFont className="dcs-heading">{t("Action")}</PorscheFont>
                    </PFlexItem>
                    <PFlexItem style={{ width: '40%' }} className="sub-headding">
                        <PorscheFont className="dcs-heading">{t("Comment")}</PorscheFont>
                    </PFlexItem>
                </PFlex>
                <PDivider className="mt8" color="neutral-contrast-high" /><br></br>
                {commissioningData && commissioningData.logs.map((book, index) => {
                    if(book.action === "UnAssigned") return <></>;

                    return(
                        <>
                            <PFlex>
                                <PFlexItem style={{ width: '20%' }}>
                                    <PorscheFont className="tn-text-14-400">{new Date(book.actionDate).toLocaleDateString('de-DE',{day:'2-digit', month:'2-digit', year:"numeric"})}</PorscheFont>
                                </PFlexItem>
                                <PFlexItem style={{ width: '20%' }}>
                                    <PorscheFont className="tn-text-14-400">{book.user}</PorscheFont>
                                </PFlexItem>
                                <PFlexItem style={{ width: '20%' }}>
                                    { (book.action === "Approved") && (
                                    <PorscheFont className="dcs-order-approved">{t(book.action)}</PorscheFont>
                                    )}
                                    { (book.action === "Reassigned") && (
                                    <PorscheFont className="dcs-order-reassigned">{t(book.action)}</PorscheFont>
                                    )}
                                    { (book.action === "Rejected") && (
                                    <PorscheFont className="dcs-order-rejected">{t(book.action)}</PorscheFont>
                                    )}
                                    { (book.action === "Assigned") && (
                                    <PorscheFont className="dcs-order-status">{t(book.action)}</PorscheFont>
                                    )}
                                </PFlexItem>
                                <PFlexItem style={{ width: '40%' }}>
                                    <PorscheFont className="tn-text-14-400">{book.comment}</PorscheFont>
                                </PFlexItem>
                            </PFlex><br></br>
                        </>
                    );
                })}
            </div>
        </>
    );
};
