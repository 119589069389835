import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { Tab, Tabs } from '@mui/material';
import {
  PButton,
  TabChangeEvent
} from '@porsche-design-system/components-react';

import { PMainHeader, PorscheFont } from '../../assets/styles/CustomStyles';
import { NavSideBar } from '../../components/NavSideBar';
import { TestDemoCar } from '../../components/order/inreview/TestDemoCar';
import { TestNewCar } from '../../components/order/inreview/TestNewCar';
import { TestUsedCar } from '../../components/order/inreview/TestUsedCar';
import { AuthContext } from '../../contexts/AuthContext';
import { Constant } from '../../configs/Constants';
import CustomSnakebar from '../../components/common/CustomSnakebar';
import getSessionStorage from '../../helpers/getSessionStorage';

interface HeaderTabPanels {
  id: number;
  name: string;
  enabled: boolean;
  className: string;
}
export const InReviewPage = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const orderValues = getSessionStorage(Constant.SingleOrdDetail); 
  const [tabPanels, setTabPanels] = useState<Array<HeaderTabPanels>>([]);
  const [panelIndex, setPanelIndex] = useState<number>(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [modeChange, setChangeMode] = useState("");
  const [enableCreateOrder, setEnableCreateOrder] = useState(false)
  const [showAlert, setShowAlert] = useState(Constant.None)
  const [alertMessage, setAlertMessage] = useState("")
  const authCtx = useContext(AuthContext);


   const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setPanelIndex(newValue);
  };

 
  useEffect(() => {
    const mode = orderValues?.mode ? orderValues?.mode+'' :'';
    setChangeMode(mode);
    const init = async() => {
      const user = await authCtx.getUserDetails();
     
      if(user === null || user.isNewUser) {
        navigate("/welcome");
      }
      if(user?.scaling){
       setEnableCreateOrder(true) 
      }
    }
    init();
  }, [])

 

  const handleClick = () => {
    if (!enableCreateOrder) {
      setShowAlert(Constant.Error);
      setAlertMessage(Constant.NoScalingErrorMsg);
    } else {
      window.open("/#/order/create", "_blank");
    }  }
    const handleClose = ( ) => {
      setShowAlert(Constant.None)
      setAlertMessage("")
    };

  const handleOrder = (id: number | undefined) => {
    //navigate("/#/order/create");
    if (id) {
      let urlParams = `?id=${id}&mode=view&draft=true`
      window.open(`/#/order/create${urlParams}`, "_blank");
    }
  }
  const [tabIndex, setTabIndex] = useState<number>(0);
  const onTabChange = useCallback((e: CustomEvent<TabChangeEvent>) => {
    setTabIndex(e.detail.activeTabIndex);
  }, []);
  // const tabPanels: string[] = ['New Car', 'Demo Car', 'Used Car'];
  useEffect(() => {
    var panels = [];
    panels.push({
      id: 0, name: "New Car", enabled: true, className: ""
    });
    panels.push({
      id: 1, name: "Demo Car", enabled: true, className: ""
    });
    panels.push({
      id: 2, name: "Used Car", enabled: true, className: ""
    });
    setTabPanels(panels);
  }, []);
            
  return (
    <div className="custombody">
      <div className="navbar" style={{ position: 'fixed', top: '85px' }}> 
        <NavSideBar main={t('Orders')} subPage={t('In Review')} disabled={false} />
      </div>
      <div className="main" style={{ width: '100%',height:"auto", backgroundColor: '#F2F2F2', padding: '100px 0px 10px 71px' }}>
        <div className="main-container">
          <div className="main-header" style={{ position: 'sticky', top: '70px' }}>
            <div className='main-header-left ' style={{ position: 'sticky'}}>
              <PMainHeader>
                <PorscheFont data-testid="page-dashboard" className="main-header-text">{t('Orders')} - {t('In Review')}</PorscheFont >              
                <PorscheFont className="main-header-description" >
                  {t('Below you can view, filter and open all of your accessible drafts and orders without a submitted order registration.')}
                </PorscheFont>                           
              </PMainHeader>
            </div>
            <div className='main-header-right'style={{marginRight:"55px", position:"relative", flex:" 0.3 1", display:'flex', alignItems:'start', gap:'16px', paddingLeft:'0px'}}>
            <PButton data-testid="new-order" icon='add' onClick={handleClick} style={{right:"38px"}}>
                <PorscheFont className="create-new-order"style={{marginRight:"-5px"}}>
                  {t('Create New Order')}
                </PorscheFont>
              </PButton>
            </div>
          </div>
        </div>
        {/* {showHeader && ( */}
               {/* <CSSTransition
               in={true}
               appear={true}
               timeout={100}
               classNames="fade"
             > */}
          <div  style={{ margin: "16px 25px 5px 25px" }} >
           {<Tabs
            textColor="primary"
            indicatorColor="secondary"
            onChange={handleChange}
            // onClick={handleShowHeader}
            value={panelIndex}
            sx={{
              minHeight: '28px!important',
              "& .MuiTabs-indicator": {
                backgroundColor: "#D5001B",
                height: 2,
              },
              "& .MuiTab-root.Mui-selected": {
                color: "#313639",
                textTransform: "inherit",
                fontFamily: "Porsche Next",
                paddingBottom: "2px"
              }
            }}
          >
            {tabPanels.map((tabPanel, i) => {
              return (
                <Tab
                  disabled={!tabPanel.enabled}
                  label={t(tabPanel.name)}
                  value={tabPanel.id}
                  sx={{
                    borderLeft: "1px solid #C8CACB ",
                    padding: "0px 12px !important",
                    minHeight: '6px!important',
                    textTransform: "inherit",
                    fontFamily: "Porsche Next",
                    fontSize: "16px",
                  }}
                />
              );
            })}
          </Tabs>}  
         <div className="inreview">
            {panelIndex === 0 && <TestNewCar  />}

            {panelIndex === 1 && <TestDemoCar />}

            {panelIndex === 2 && <TestUsedCar  />}
          </div>
        </div>
        {/* </CSSTransition> */}
         {/* )}  */}
         {/* {!showHeader && (
            <>
              <CSSTransition
            in={true}
            appear={true}
            timeout={100}
            classNames="fade"
          >
            <div style={{ margin: "-50px 25px 5px 25px" }}>
            {<Tabs
              textColor="primary"
              indicatorColor="secondary"
              onChange={handleChange}
              value={panelIndex}
              sx={{
                minHeight: '35px!important',
                "& .MuiTabs-indicator": {
                  backgroundColor: "#D5001B",
                  height: 2,
                },
                "& .MuiTab-root.Mui-selected": {
                  color: "#313639",
                  textTransform: "inherit",
                  fontFamily: "Porsche Next",
                  paddingBottom: "10px"
                }
              }}
            >
              {tabPanels.map((tabPanel) => {
                return (
                  <Tab
                    disabled={!tabPanel.enabled}
                    label={tabPanel.name}
                    value={tabPanel.id}
                    sx={{
                      borderLeft: "1px solid #C8CACB ",
                      padding: "0px 12px !important",
                      minHeight: '6px!important',
                      textTransform: "inherit",
                      fontFamily: "Porsche Next",
                      fontSize: "16px",
                    }} />
                );
              })}
            </Tabs>} <br></br>           
            <div className="inreview">
              {panelIndex === 0 && <TestNewCar showHeader={handleShowHeader} removeHeader={handleRemoveHeader} />}

              {panelIndex === 1 && <TestDemoCar showHeader={handleShowHeader} removeHeader={handleRemoveHeader} />}

              {panelIndex === 2 && <TestUsedCar showHeader={handleShowHeader} removeHeader={handleRemoveHeader} />}
            </div>
            </div>      
            </CSSTransition>
          </>
         )} */}
      </div>
      <CustomSnakebar alertType={showAlert} alertMessage={alertMessage} handleClose={()=>handleClose()}/>

    </div>
  );
}
