import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  PButtonPure,
  PFlex,
  PFlexItem,
  PIcon,
  PText
} from "@porsche-design-system/components-react";

import { Model } from "../../interfaces/Order";
import { ModelDTO, Series } from "../../interfaces/Upsale";

interface Props {
  allSeries: Array<Series>;
  handleChange: (seriresId: number, modelId: number) => void;
  selectedModel?: Model;
  mode?: string;
  selectedSeriesId?: number;
  disabled?: boolean;
}

export const CustomSeriesModel = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const {
    allSeries,
    handleChange,
    mode,
    selectedModel,
    selectedSeriesId,
    disabled,
  } = props;
  const [modelSelected, setModelSelected] = useState(false);
  const [model, setModel] = useState<ModelDTO>(
    selectedModel || ({} as ModelDTO)
  );
  const [seriesId, setSeriesId] = useState(selectedSeriesId || 0);
  const [prevSeriesId, setPrevSeriesId] = useState(0);
  const [showId, setShowId] = useState(0);
  const [show, setShow] = useState(false);
  const [reload, setReload] = useState("");
  const [disabledClassName, setDisabledClassName] = useState("");

  useEffect(() => {
    if (selectedModel && (mode === "view" || mode === "edit")) {
      let model = {} as ModelDTO;
      model.id = selectedModel.id;
      model.modelName = selectedModel.modelName;
      setModel(model);
      if (selectedSeriesId) {
        setSeriesId(selectedSeriesId);
      }
      handleSelect(model);
    }

    if (disabled) {
      setDisabledClassName("disabled");
    } else {
      setDisabledClassName("");
    }

    if (mode === "view") {
      setDisabledClassName("disabled");
    } else {
      setDisabledClassName("");
    }
  }, [mode]);

  useEffect(() => {
    if (disabled) {
      setDisabledClassName("disabled");
    } else {
      setDisabledClassName("");
    }
  }, [reload]);

  const handleSelect = (model: ModelDTO) => {
    setShow(false);
    setModelSelected(true);
    setModel(model);
    setPrevSeriesId(seriesId);
    // call back to parent
    handleChange(seriesId, model.id);
    setReload(new Date().toISOString());
  };

  const handleClick = (sid: number) => {
    // setModelSelected(selected);
    if (document.getElementById("show-" + sid)) {
      setShow(false);
    } else {
      setShow(true);
    }

    setSeriesId(sid);
    setReload(new Date().toISOString());
  };

  const getSeriesName = (sid: number): string => {
    let name = "test";
    allSeries.map((x) => {
      if (x.id === sid) {
        if (model) {
          const modSelected = x.models.filter((x) => x.id === model.id);
          if (modSelected.length > 0) {
            //name = model.modelName;
            if (model.modelName.indexOf(x.seriesName) > -1) {
              name = model.modelName;
            } else {
              name = x.seriesName + " " + model.modelName;
            }
          } else {
            name = x.seriesName;
          }
        }
      }
    });
    return name;
  };

  const getSeriesId = (model: ModelDTO): number => {
    let id = 0;
    allSeries.map((ser) => {
      const res = ser.models.filter(
        (x) => x.id === (model.id || selectedModel?.id)
      );
      if (res.length > 0) {
        id = ser.id;
      }
    });

    return id;
  };

  const getImage = (seriesName: string) => {
    let ret = (
      <img
        src={require("../../assets/images/cars/png/Boxster.png")}
        alt={"Boxster"}
        width="108px"
        height="60px"
        style={{ marginBottom: "-4px" }}
      />
    );
    switch (seriesName) {
      case "Boxster":
        ret = (
          <img
            src={require("../../assets/images/cars/png/Boxster.png")}
            alt={"Boxster"}
            width="108px"
            height="60px"
            style={{ marginBottom: "-4px" }}
          />
        );
        break;

      case "Cayenne":
        ret = (
          <img
            src={require("../../assets/images/cars/png/Cayenne.png")}
            alt={"Cayenne"}
            width="125px"
            height="70px"
            style={{ marginBottom: "-4px", marginTop: "3px" }}
          />
        );
        break;

      case "Cayman":
        ret = (
          <img
            src={require("../../assets/images/cars/png/Cayman.png")}
            alt={"Cayman"}
            width="108px"
            height="60px"
            style={{ marginBottom: "-4px", marginRight: "5px" }}
          />
        );
        break;

      case "Macan":
        ret = (
          <img
            src={require("../../assets/images/cars/png/Macan.png")}
            alt={"Macan"}
            width="112px"
            height="63px"
            style={{
              marginBottom: "-4px",
              marginRight: "5px",
              marginTop: "3px",
            }}
          />
        );
        break;

      case "Panamera":
        ret = (
          <img
            src={require("../../assets/images/cars/png/Panamera.png")}
            alt={"Panamera"}
            width="127px"
            height="73px"
            style={{ marginBottom: "1px", marginRight: "3px" }}
          />
        );
        break;

      case "Taycan":
        ret = (
          <img
            src={require("../../assets/images/cars/png/Taycan.png")}
            alt={"Taycan"}
            width="127px"
            height="73px"
            style={{ marginBottom: "-1px" }}
          />
        );
        break;

      case "911":
      default:
        ret = (
          <img
            src={require("../../assets/images/cars/png/911.png")}
            alt={"911"}
            width="120px"
            height="70px"
            style={{ marginBottom: "3px" }}
          />
        );
        break;
    }

    return ret;
  };

  return (
    <>
      {allSeries.length > 0 && (
        <PFlex direction={"column"}>
          {[...Array(Math.ceil(allSeries.length / 3))].map((row, rowIndex) => {
            return (
              <PFlexItem>
                <PFlex
                  direction={"row"}
                  justifyContent={"space-between"}
                  style={{ width: "1000px" }}
                >
                  {allSeries
                    .slice(rowIndex * 3, (rowIndex + 1) * 3)
                    .map((series, i) => {
                      const itemIndex = i * (rowIndex + 1);
                      return (
                        <PFlexItem
                          // style={{ marginBottom: "5px", marginRight: "10px" }}
                          className={`${
                            prevSeriesId === series.id
                              ? "grid-selected-item"
                              : seriesId === series.id &&
                                getSeriesId(model) === series.id
                              ? "grid-selected-item"
                              : "grid-item"
                          }`}
                        >
                          <PFlex
                            direction={"row"}
                            justifyContent={"space-between"}
                            //className={`series ${seriesId === series.id && modelSelected ? "model-selected" : ""}`}
                            className={`${
                              prevSeriesId === series.id
                                ? `model-selected ${disabled ? "disabled" : ""}`
                                : seriesId === series.id &&
                                  getSeriesId(model) === series.id
                                ? `model-selected ${disabled ? "disabled" : ""}`
                                : `series ${disabled ? "disabled" : ""}`
                            }`}
                            onClick={() => handleClick(series.id)}
                          >
                            <PFlexItem
                              alignSelf={"center"}
                              style={{ width: "250px" }}
                            >
                              <PText className={`series-name`}>
                                <span
                                  //className={`${seriesId === series.id || getSeriesId(model) === series.id ? "model-selected-text" : ""}`}
                                  className={`${
                                    prevSeriesId === series.id
                                      ? "model-selected-text"
                                      : seriesId === series.id &&
                                        getSeriesId(model) === series.id
                                      ? "model-selected-text"
                                      : ""
                                  }`}
                                  style={{
                                    fontSize: "14px",
                                    lineHeight: "0.5",
                                  }}
                                >
                                  {getSeriesName(series.id)}
                                </span>
                              </PText>
                            </PFlexItem>
                            <PFlexItem
                              alignSelf={"center"}
                              style={{ paddingRight: "0px" }}
                            >
                              <PFlex alignItems={"flex-end"}>
                                <PFlexItem alignSelf={"center"}>
                                  {getImage(series.seriesName)}
                                </PFlexItem>
                                <PFlexItem alignSelf={"center"}>
                                  <PIcon
                                    name="arrow-head-right"
                                    // color={
                                    //   series.id === seriesId && getSeriesId(model) === series.id
                                    //     ? "neutral-contrast-low"
                                    //     : "neutral-contrast-high"
                                    // }
                                    color={`${
                                      prevSeriesId === series.id
                                        ? "neutral-contrast-low"
                                        : seriesId === series.id &&
                                          getSeriesId(model) === series.id
                                        ? "neutral-contrast-low"
                                        : "neutral-contrast-high"
                                    }`}
                                  />
                                </PFlexItem>

                                {seriesId === series.id && show && (
                                  <PFlexItem alignSelf={"flex-start"} style={{position:'relative'}}>
                                    <ul
                                      id={`show-${series.id}`}
                                      className="model-dropdown"
                                      onBlur={() => setShow(false)}
                                    >
                                      <li
                                        style={{
                                          margin: "14px 0px 14px 6px",
                                          fontSize: "16px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {t("Select Model")}
                                      </li>
                                      {series.models.length > 0 &&
                                        series.models.map((model, i) => {
                                          return (
                                            <li
                                              key={i}
                                              style={{ marginBottom: "14px " }}
                                            >
                                              <PButtonPure
                                                size={"x-small"}
                                                onClick={() =>
                                                  handleSelect(model)
                                                }
                                              >
                                                <span
                                                  style={{ fontSize: "14px" }}
                                                >
                                                  {model.modelName}
                                                </span>
                                              </PButtonPure>
                                            </li>
                                          );
                                        })}
                                    </ul>
                                  </PFlexItem>
                                )}
                              </PFlex>
                            </PFlexItem>
                          </PFlex>
                        </PFlexItem>
                      );
                    })}
                </PFlex>
              </PFlexItem>
            );
          })}
        </PFlex>
      )}
    </>
  );
};
