import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PTabs, PTabsItem } from '@porsche-design-system/components-react';

import { PorscheFont } from '../../../assets/styles/CustomStyles';
// import { ApprovalRow } from './ApprovalRow';
import { type } from '../../../services/Constants';
import { dcsgetSubsequentApprovalConfig } from '../../../services/data/Helpers';
import { CommissionApprovalNew } from './CommissionApprovalNew';
import { OrderApprovalNew } from './OrderApprovalNew';
import SubsequentApprovalTab from './SubsequentApprovalTab';

interface Props {
  orderId: number;
  category: string;
  status: string;
  handleClose: (reload: boolean) => void;
}
export const Approvals = (props: Props): JSX.Element => {
  const { orderId, category, status, handleClose } = props;
  const { t } = useTranslation();
  const [showSubsequent, setShowSubsequent] = useState(false)

  useEffect(() => {
    const init = async () => {
      const apiResponse = await dcsgetSubsequentApprovalConfig(orderId)
      if(apiResponse.status===type.SUCCESS){
        setShowSubsequent(true)
      }
    };
    
    init();
   
  }, []);

  return(
    <div className='tn-layout'>
      <div className="dcs-section-heading">
          <PorscheFont>{t("Approvals")}</PorscheFont>
      </div><br></br>
      <div>
      <PTabs activeTabIndex={ status === "Approved" ? 1 : showSubsequent? 2: 0}>
        <PTabsItem label={t('Order Approval')} className="inreview"  style={{marginTop: "16px"}}>
          {/* <OrderApproval orderId={orderId} category={category} handleClose={handleClose}/> */}
          <OrderApprovalNew orderId={orderId} category={category} handleClose={(reload) => handleClose(reload)}/>
        </PTabsItem>
 
        <PTabsItem label={t('Commission Approval')} className="inreview" style={{marginTop: "16px"}}>
          {/* <CommissionApproval orderId={orderId} category={category} handleClose={(reload) => handleClose(reload)} />   */}
          <CommissionApprovalNew orderId={orderId} category={category} handleClose={(reload) => handleClose(reload)} />  
        </PTabsItem>

        {showSubsequent && 
         <PTabsItem label={t('Subsequent Approval')} className="inreview" style={{marginTop: "16px"}}>
         <SubsequentApprovalTab orderId={orderId} category={category} handleClose={(reload) => handleClose(reload)}/>
       </PTabsItem>
     }
       
       </PTabs> 
      
      </div>
    </div>
  );
}