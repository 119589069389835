import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Stack } from '@mui/material';
import {
  PButtonPure,
  PFlex, PFlexItem,
  PIcon,
  PModal
} from '@porsche-design-system/components-react';

import { PorscheFont } from '../../../assets/styles/CustomStyles';
import { TaskResponseDTO } from '../../../interfaces/TasksAndNotifications';
//import { Notifications } from './Notifications';
import { TaskDetails } from './TaskDetails';

//import { TaskRow } from './TaskRow';

interface Props {
  task: TaskResponseDTO;
  index: number;
  handleDelete: () => void;
  orderId: number,
  isDraft:boolean,
  functProps: () => void;
}

export const TaskRow = (props: Props): JSX.Element => {
  const { task, index, handleDelete, functProps } = props;
  const { t } = useTranslation();
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [toggleDescription, setToggleDescription] = useState<boolean>(false);
  const [reassignComment, setReassignComment] = useState<boolean>(false);
  const [completedComment, setCompletedComment] = useState<boolean>(false);

  const handleModalOpen = () => {
    setIsDetailsOpen(true);
  };
  const handleModalClose = () => {
    setIsDetailsOpen(false);
  };

  const handleDescriptionClose = () => {
    setToggleDescription(!toggleDescription);
  };
  const handleDetailsClose = () => {
    setIsDetailsOpen(false);
  }; 

  return(
    <div style={ index%2 !== 0 ? {background: "#F5F5F5", padding: "5px"} : {paddingLeft: "5px", paddingRight: "5px"} }>
      <PFlex style={{marginTop: "10px", marginBottom: "10px"}}>
        <PFlexItem style={{width:"15%"}}>
          <PorscheFont className="tn-title">{task.createdOn.substring(0, 10).replaceAll("-", ".")}</PorscheFont>
        </PFlexItem>
        <PFlexItem style={{width:"15%"}}>
          <PorscheFont className="tn-title">{task.createdBy.ppnName}</PorscheFont>
        </PFlexItem>
        <PFlexItem style={{width:"25%"}}>
          <PorscheFont className="tn-title">{task.subject}</PorscheFont>
          {task.description && task.description.length > 0 && (
            <Stack direction={"row"} spacing={1} onClick={() => setToggleDescription(!toggleDescription)}>
              <PorscheFont className="tn-expand">{t("View description")}</PorscheFont>
              <div style={{paddingTop: "2px"}}>
                <PIcon name={toggleDescription ?  "arrow-head-up" : "arrow-head-down"} size="small" color="notification-neutral" />
              </div>
            </Stack>
          )}
        </PFlexItem>
        <PFlexItem style={{width:"20%"}}>
          { !task.reassigned && (
            <PorscheFont>{task.assignedTo.ppnName}</PorscheFont>
          )}
          { task.reassigned && (
            <PFlex direction={"column"}>
              <PFlexItem>
                <PFlex>
                    <PFlexItem><PorscheFont className="tn-heading">{t("Reassigned")+": "}</PorscheFont></PFlexItem>
                    <PFlexItem><PorscheFont className="tn-title">{task.assignedTo.ppnName}</PorscheFont></PFlexItem>
                </PFlex>
              </PFlexItem>
              <PFlexItem>
                <PFlex> 
                  <PFlexItem>
                    <Stack direction={"row"} spacing={1}>
                      <PorscheFont className="tn-heading">{"On: "}</PorscheFont>
                      <PorscheFont className="tn-title">{task.assignedOn.substring(0, 10).replaceAll("-", ".")}</PorscheFont>
                    </Stack>
                </PFlexItem>
                  <PFlexItem  onClick={() => setReassignComment(!reassignComment)} >
                    <PorscheFont className="tn-expand" style={{marginLeft:"4px"}}>{t("View Comment")}</PorscheFont>
                    { reassignComment && (
                      <div className="tn-comment">
                        <PFlex direction="column">
                          <PFlexItem alignSelf={"flex-end"}>
                            <PButtonPure icon="close" onClick={() => setReassignComment(!reassignComment)}></PButtonPure>
                          </PFlexItem>
                          <PFlexItem>
                            <span>
                              <PorscheFont className="dcs-heading">{t("Comment")}:</PorscheFont>
                              {task.reassignedReason}
                            </span>
                          </PFlexItem>
                        </PFlex>
                        
                      </div>
                    )}
                  </PFlexItem>
                </PFlex>
              </PFlexItem>
            </PFlex>
          )}
        </PFlexItem>
        <PFlexItem style={{width:"20%"}}>
          { task.status === "Pending" && (
              <PorscheFont className="tn-heading" style={{color: "#FF9B00"}}>{t("Pending")}</PorscheFont>
          )}
          { task.status === "Completed" && (
            <PFlex direction={"column"}>
              <PFlexItem>
                <Stack direction={"row"} spacing={1}>
                  <PorscheFont className="tn-heading">{"Completed On: "}</PorscheFont>
                  <PorscheFont className="tn-title">{task.completedOn.substring(0, 10).replaceAll("-", ".")}</PorscheFont>
                </Stack>
              </PFlexItem>
              <PFlexItem onClick={() => setCompletedComment(!completedComment)}>
              {task?.statusComment?.length > 0 && (
                <PorscheFont className="tn-expand">{t("View Comment")}</PorscheFont>
              )}
              {completedComment && task.statusComment.length > 0 && (
                <div className="tn-comment">
                  <PFlex direction="column">
                    <PFlexItem alignSelf={"flex-end"}>
                      <PButtonPure icon="close" onClick={() => setCompletedComment(!completedComment)}></PButtonPure>
                    </PFlexItem>
                    <PFlexItem>
                      <span>
                        <PorscheFont className="dcs-heading">{t("Comment")}:</PorscheFont>
                        {task.statusComment}
                      </span>
                    </PFlexItem>
                  </PFlex>
                </div>
              )}
              </PFlexItem>
            </PFlex>
          )}
        </PFlexItem>
        <PFlexItem style={{width:"5%"}}>
            <PButtonPure hideLabel={true} onClick={(e) => setIsDetailsOpen(true)}></PButtonPure>
        </PFlexItem>
      </PFlex>
      { task.description && task.description.length > 0 && toggleDescription && (
        <PFlex style={{marginTop: "-10px", marginBottom: "4px"}}>
          <PFlexItem>
            <span>
              <PorscheFont className="tn-heading">{t("Description")}</PorscheFont>
              <PorscheFont className="tn-title">{task.description}</PorscheFont>
            </span>
          </PFlexItem>
        </PFlex>
      )}

      {/* {isDetailsOpen && ( */}
      <PModal open={isDetailsOpen} onClose={handleModalClose} fullscreen={true}>
          <TaskDetails task={task} key={task.id} functProps={functProps} orderId={props.orderId} isDraft={props.isDraft} closeDetails={handleModalClose} handleDelete={handleDelete}/>
      </PModal>
      {/* )} */}
      
    </div>
  );
}