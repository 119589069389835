import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Stack } from "@mui/material";
import {
  IconName,
  PButton,
  PCheckboxWrapper,
  PFlex,
  PFlexItem,
  PIcon,
  PSelectWrapper,
  PSwitch,
  PTable,
  PTableBody,
  PTableCell,
  PTableHead,
  PTableHeadCell,
  PTableHeadRow,
  PTableRow,
  PText,
  PTextFieldWrapper,
  SwitchChangeEvent
} from "@porsche-design-system/components-react";

import { PorscheFont } from "../../../assets/styles/CustomStyles";
import { Model } from "../../../interfaces/Model";
import { Series } from "../../../interfaces/Order";
import {
  formatCurrencyToDecimal,
  formatCurrencyWithNoUnit,
  onBlurCurrencyFormatter,
  validateCurrency,
} from "../../../mock/helper";
import { type } from "../../../services/Constants";
import {
  dcsgetModels,
  dcsgetOrderManagedVariables,
  getLogedInOrigin
} from "../../../services/data/Helpers";
import ScrollToTop from "../../common/ScrollToTop";

interface Props {
  edit: boolean;
  changedMode: string;
  handleModels: (models: Array<Model>) => void;
  modified: () => void;
  setShowSaveButton: Dispatch<SetStateAction<boolean>>;
}

export const Models = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const { edit, handleModels, modified, setShowSaveButton } = props;
  const [series, setSeries] = useState<Array<Series>>([]);
  const [models, setModels] = useState<Array<Model>>([]);
  const [reload, setReload] = useState("");
  const [seriesId, setSeriesId] = useState(0);
  const [group, setGroup] = useState("");
  const [modelRarity, setModelRarity] = useState("");
  const [engineTypeModel, setEngineTypeModel] = useState("");
  const [visibility, setVisibility] = useState("");
  const [errorState, setErrorState] = useState<Record<string, boolean>>();

  const tRef = [
    { engineTypeModel: useRef<HTMLSelectElement>(null) },
    { seriesId: useRef<HTMLSelectElement>(null) },
    { modelRarity: useRef<HTMLSelectElement>(null) },
    { group: useRef<HTMLSelectElement>(null) },
    { visibility: useRef<HTMLSelectElement>(null) },
  ];
  // const [values,setValue] = useState("");

  useEffect(() => {
    if (errorState) {
      const isErrorPresent = Object.values(errorState).includes(true);
      setShowSaveButton(!isErrorPresent);
    }
  }, [errorState]);

  useEffect(() => {
    const init = async () => {
      const variables = await dcsgetOrderManagedVariables(1, false);
      const apiResponse = await dcsgetModels("");
      if (apiResponse.status === type.SUCCESS) {
        if (apiResponse.response.length > 0) {
          setModels(apiResponse.response);
        }
      }
      if (apiResponse.status === type.ERROR) {
        setModels([]);
      }

      if (variables.length > 0) {
        setSeries(variables[8]);
      }
    };
    init();
  }, []);

  const handleResetFilter = () => {
    if (tRef[0] && tRef[0].engineTypeModel && tRef[0].engineTypeModel.current) {
      tRef[0].engineTypeModel.current.value = "";
    }

    if (tRef[1] && tRef[1].seriesId && tRef[1].seriesId.current) {
      tRef[1].seriesId.current.value = "";
    }

    if (tRef[2] && tRef[2].modelRarity && tRef[2].modelRarity.current) {
      tRef[2].modelRarity.current.value = "";
    }

    if (tRef[3] && tRef[3].group && tRef[3].group.current) {
      tRef[3].group.current.value = "";
    }

    if (tRef[4] && tRef[4].visibility && tRef[4].visibility.current) {
      tRef[4].visibility.current.value = "";
    }
    const init = async () => {
            const variables = await dcsgetOrderManagedVariables(1, false);
      const apiResponse = await dcsgetModels("");
      if (apiResponse.status === type.SUCCESS) {
        if (apiResponse.response.length > 0) {
          setModels(apiResponse.response);
        }
      }
      if (apiResponse.status === type.ERROR) {
        
        setModels([]);
      }

      if (variables.length > 0) {
        setSeries(variables[8]);
      }
    };
    init();
    setReload(new Date().toLocaleString());
  };

  const headBasic = [
    "Series",
    "Model",
    "Group",
    "Special Model",
    "Engine Type",
    "Fixed Margin",
    //"Visible",
    "Visibility",
  ];

  const handleCheckIsModified = () => {
    let modelChecks: boolean[] = [];

    models.forEach((model) => {
      modelChecks.push(!!model?.serie?.id);
      modelChecks.push(!!model?.modelName);
      modelChecks.push(!!model?.group);
    });
    let isValidModified = modelChecks.every((val) => val === true);

    if (isValidModified) {
      modified();
    } else {
      setShowSaveButton(false);
    }
  };

  const handleSeries = (value: string, id: number, index: number) => {
    const dom = document.getElementById(`series-${id}-${index}`);
    const seriesId = value.length === 0 ? 0 : parseInt(value);
    let elementDomId= dom?.id as string
    if (seriesId === 0) {
      if (dom) {
        dom.setAttribute("state", "error");
      }
      setErrorState((prev)=>{
        return {...prev, [elementDomId]:true}
      })
    } else {
      if (dom) dom.setAttribute("state", "none");
      setErrorState((prev)=>{
        return {...prev, [elementDomId]:false}
      })
      models.map((item, i) => {
        if ((id === 0 && i === index) || item.id === id) {
          const res = series.filter((x) => x.id === seriesId);
          if (res.length > 0) {
            item.serie = {
              id: res[0].id,
              seriesName: res[0].seriesName,
              origin: getLogedInOrigin(),
            };
          }
        }
      });
      handleCheckIsModified();
      setModels(models);
      handleModels(models);
    }
  };

  const handleModelName = (value: string, m: Model, index: number) => {
    const id = m.id ? m.id : 0;
    const dom = document.getElementById(`${m.serie.seriesName}-${id}-${index}`);
    let elementDomId= dom?.id as string

    if (value.length > 0) {
      if (dom) dom.setAttribute("state", "none");
      setErrorState((prev)=>{
        return {...prev, [elementDomId]:false}
      })
      models.map((item, i) => {
        if ((id === 0 && i === index) || item.id === id) {
          //item.modelName = value;
          models[i].modelName = value;

          if (!item.serie.id) {
            const seriesDom = document.getElementById(`series-${id}-${index}`);
            if (seriesDom) {
              setErrorState((prev)=>{
                return {...prev, [elementDomId]:true}
              })
              seriesDom.setAttribute("state", "error");
              seriesDom.setAttribute(
                "message",
                t("Please enter a valid value.")
              );
            }
          }

          if (item.group.length === 0) {
            const groupDom = document.getElementById(`group-${id}-${index}`);
            if (groupDom) {
              setErrorState((prev)=>{
                return {...prev, [elementDomId]:true}
              })
              groupDom.setAttribute("state", "error");
              groupDom.setAttribute(
                "message",
                t("Please enter a valid value.")
              );
            }
          }
        }
      });
      setModels(models);
      // modified();
      handleCheckIsModified();
      handleModels(models);
    } else {
      if (dom) {
        setErrorState((prev)=>{
          return {...prev, [elementDomId]:true}
        })
        dom.setAttribute("state", "error");
      }
    }
  };

  const handleGroup = (value: string, id: number, index: number) => {
    const dom = document.getElementById(`group-${id}-${index}`);
    let elementDomId= dom?.id as string

    if (value) {
      if (dom) dom.setAttribute("state", "none");
      setErrorState((prev)=>{
        return {...prev, [elementDomId]:false}
      })
      models.map((item, i) => {
        if ((id === 0 && i === index) || item.id === id) {
          models[i].group = value;
        }
      });
      setModels(models);
      // modified();
      handleCheckIsModified();
      handleModels(models);
    } else {
      setErrorState((prev)=>{
        return {...prev, [elementDomId]:true}
      })
      if (dom) {
        dom.setAttribute("state", "error");
      }
    }
  };

  const handleRarity = (id: number, index: number) => {
    models.map((item, i) => {
      if ((id === 0 && i === index) || item.id === id) {
        if (item.rarity === "SpecialModel") {
          item.rarity = "StandardModel";
        } else {
          item.rarity = "SpecialModel";
        }
      }
    });
    setModels(models);
    // modified();
    handleCheckIsModified();
    handleModels(models);
    setReload(new Date().toLocaleString());
  };

  const handleEngineType = (id: number, index: number) => {
    models.map((item, i) => {
      if ((id === 0 && i === index) || item.id === id) {
        if (item.engineType === "Electro_hybrid") {
          item.engineType = "Combustion";
        } else {
          item.engineType = "Electro_hybrid";
        }
      }
    });
    setModels(models);
    // modified();
    handleCheckIsModified();
    handleModels(models);
    setReload(new Date().toLocaleString());
  };

  const handleFixedMarginCheckbox = (id: number, index: number) => {
    models.map((item, i) => {
      if ((id === 0 && i === index) || item.id === id) {
        item.hasFixedMargin = item.hasFixedMargin ? false : true;
      }
    });
    setModels(models);
    // modified();
    handleCheckIsModified();
    handleModels(models);
    setReload(new Date().toLocaleString());
  };

  function removeFirstLetter(inputString: string) {
    return inputString.substring(1);
  }
  const handleFixedMargin = (
    value: string,
    id: number,
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    // const dom = document.getElementById(`fmargin-${id}-${index}`);
    const inputTagId = e.target.id as string;
    const wrapperTagId = removeFirstLetter(inputTagId);
    const dom = document.getElementById(wrapperTagId);

    if (validateCurrency(value)) {
      if (dom) dom.setAttribute("state", "none");
      setErrorState((prev) => {
        return { ...prev, [wrapperTagId]: false };
      });
      models.map((item, i) => {
        if ((id === 0 && i === index) || item.id === id) {
          item.fixedMargin = formatCurrencyToDecimal(value);
        }
      });
      setModels(models);
      // modified();
      handleCheckIsModified();
      handleModels(models);
      setReload(new Date().toLocaleString());
    } else {
      if (dom) {
        dom.setAttribute("state", "error");
        setErrorState((prev) => {
          return { ...prev, [wrapperTagId]: true };
        });
        // dom.setAttribute("message", "Please enter a valid value");
      }
    }
  };

  const handleVisible = (
    e: CustomEvent<SwitchChangeEvent>,
    id: number,
    index: number
  ): void => {
    models.map((item, i) => {
      if ((id === 0 && i === index) || item.id === id) {
        item.visibility = e.detail.checked;
      }
    });

    setModels(models);
    // modified();
    handleCheckIsModified();
    handleModels(models);
    setReload(new Date().toLocaleString());
  };

  const handleAddModel = () => {
    let newModel = {
      modelName: "",
      group: "",
      rarity: "StandardModel",
      engineType: "Combustion",
      visibility: true,
      fixedMargin: 0,
      hasFixedMargin: false,
      serie: { origin: getLogedInOrigin() } as Series,
      origin: getLogedInOrigin(),
    } as Model;

    models.unshift(newModel);
    setModels(models);
    handleModels(models);
    setShowSaveButton(false);
    setReload(new Date().toLocaleString());
  };

  const handleApply = () => {
    let query = "";
    if (seriesId > 0) {
      query = query + `seriesId=${seriesId}`;
    }

    if (group.length > 0) {
      query =
        query.length > 0 ? query + `&group=${group}` : query + `group=${group}`;
    }

    if (modelRarity.length > 0) {
      query =
        query.length > 0
          ? query + `&modelRarity=${modelRarity}`
          : query + `modelRarity=${modelRarity}`;
    }

    if (engineTypeModel.length > 0) {
      query =
        query.length > 0
          ? query + `&engineTypeModel=${engineTypeModel}`
          : query + `engineTypeModel=${engineTypeModel}`;
    }

    if (visibility.length > 0) {
      const visible = visibility === "true" ? true : false;
      query =
        query.length > 0
          ? query + `&visibility=${visible}`
          : query + `visibility=${visible}`;
    }

    const init = async () => {
      const apiResponse = await dcsgetModels(query);

      if (apiResponse.status === type.SUCCESS) {
        // if (apiResponse.response.length > 0) {
          setModels(apiResponse.response);
          setReload(new Date().toLocaleString());
        // }
      }
      if (apiResponse.status === type.ERROR) {
        
        setModels([]);
      }
    };
    init();
    // modified();
    handleCheckIsModified();
    setReload(new Date().toLocaleString());
  };

  return (
    <>
      <div className="container-variable" style={{ paddingTop: "30px" }}>
        <ScrollToTop />
        <PText weight="bold" size="medium">
          {t("Models")}
        </PText>
        <br />
        <Stack direction="row" spacing={1}>
          <PIcon name={"information" as IconName}></PIcon>
          <PText size="small">
            <PorscheFont>
              {t(
                "Special Model orders lever out commission ceilings (only applies for new cars)."
              )}
            </PorscheFont>
          </PText>
        </Stack>
        <br /> <br />
        <div>
          <PFlex>
            <PFlexItem width="one-quarter" className="model-sub-headding">
              <PorscheFont>{t("Series")} </PorscheFont>
            </PFlexItem>
            <PFlexItem width="one-quarter" className="model-sub-headding">
              <PorscheFont>{t("Group")} </PorscheFont>
            </PFlexItem>
            <PFlexItem width="one-quarter" className="model-sub-headding">
              <PorscheFont>{t("Special Model")} </PorscheFont>
            </PFlexItem>
            <PFlexItem width="one-quarter" className="model-sub-headding">
              <PorscheFont>{t("Engine Type")} </PorscheFont>
            </PFlexItem>
            <PFlexItem width="one-quarter" className="model-sub-headding">
              <PorscheFont>{t("Visibility")} </PorscheFont>
            </PFlexItem>
            <PFlexItem width="one-quarter" className="model-sub-headding">
              {" "}
            </PFlexItem>
          </PFlex>{" "}
          <br />
          <div>
            <PFlex>
              <PFlexItem
                width="one-quarter"
                className="model-box"
                style={{ marginRight: "20px", height: "20px" }}
              >
                <PSelectWrapper>
                  <select
                    name="series"
                    ref={tRef[1].seriesId}
                    onChange={(e) => setSeriesId(parseInt(e.target.value))}
                  >
                    <option value="" className="box-text">
                      {t("All")}
                    </option>
                    {series?.map((serie, index) => {
                      return (
                        <option
                          key={index}
                          value={serie.id}
                          className="box-text"
                        >
                          {serie.seriesName}
                        </option>
                      );
                    })}
                  </select>
                </PSelectWrapper>
              </PFlexItem>
              <PFlexItem
                width="one-quarter"
                className="model-box"
                style={{ marginRight: "20px", height: "20px" }}
              >
                <PSelectWrapper>
                  <select
                    name="group"
                    ref={tRef[3].group}
                    onChange={(e) => setGroup(e.target.value)}
                  >
                    <option value="" className="box-text">
                      {t("All")}
                    </option>
                    {series?.map((serie, index) => {
                      return (
                        <option
                          key={index}
                          value={serie.seriesName}
                          className="box-text"
                        >
                          {serie.seriesName}
                        </option>
                      );
                    })}
                  </select>
                </PSelectWrapper>
              </PFlexItem>

              <PFlexItem
                width="one-third"
                className="sub-headding"
                style={{ marginRight: "20px", height: "20px" }}
              >
                <PSelectWrapper>
                  <select
                    name="rarity"
                    ref={tRef[2].modelRarity}
                    onChange={(e) => setModelRarity(e.target.value)}
                  >
                    <option value="">{t("All")}</option>
                    <option value="StandardModel">{t("Standard Model")}</option>
                    <option value="SpecialModel">{t("Special Model")}</option>
                  </select>
                </PSelectWrapper>
              </PFlexItem>

              <PFlexItem
                width="one-third"
                className="sub-headding"
                style={{ marginRight: "20px", height: "20px" }}
              >
                <PSelectWrapper>
                  <select
                    name="engineType"
                    ref={tRef[0].engineTypeModel}
                    defaultValue=""
                    onChange={(e) => setEngineTypeModel(e.target.value)}
                  >
                    <option value="">{t("All")}</option>
                    <option value="Combustion">{t("Combustion")}</option>
                    <option value="Electro_hybrid">
                      {t("Electro/Hybrid")}
                    </option>
                  </select>
                </PSelectWrapper>
              </PFlexItem>
              <PFlexItem
                width="one-quarter"
                className="sub-headding"
                style={{ marginRight: "20px" }}
              >
                <PSelectWrapper>
                  <select
                    name="visibility"
                    ref={tRef[4].visibility}
                    onChange={(e) => setVisibility(e.target.value)}
                  >
                    <option value="">{t("All")}</option>
                    <option value="true">{t("Visible")}</option>
                    <option value="false">{t("Invisible")}</option>
                  </select>
                </PSelectWrapper>
              </PFlexItem>
              <PFlexItem width="one-quarter">
                <PButton icon="arrow-head-right" onClick={() => handleApply()}>
                  {t("Apply")}
                </PButton>
              </PFlexItem>
              <PFlexItem width="one-quarter">
                <PButton onClick={() => handleResetFilter()}>
                  {t("Reset filter")}
                </PButton>
              </PFlexItem>
            </PFlex>
            <br />
          </div>
          <PButton
            icon="add"
            onClick={() => handleAddModel()}
            disabled={edit ? false : true}
          >
            {t("Add Model")}
          </PButton>
        </div>
        <br></br>
        {/* LIST OF MODELS */}    
        <PTable>
          <PTableHead>
            <PTableHeadRow>
              {headBasic.map((item, i) => (
                <PTableHeadCell
                  style={{ textAlign: "left" }}
                  key={i}
                  multiline={true}
                >
                  {" "}
                  {t(item)}
                </PTableHeadCell>
              ))}
            </PTableHeadRow>
          </PTableHead>
          <PTableBody>
            { models.length >0 ? models.map((item, i) => (
              <PTableRow key={i}>
                <PTableCell>
                  <PSelectWrapper dropdownDirection='down'
                    id={item?.id ? `series-${item.id}-${i}` : `series-0-${i}`}
                  >
                    <select
                      //value={item?.serie?.id ? item?.serie?.id : ""}

                      onChange={(e) =>
                        handleSeries(e.target.value, item?.id ? item?.id : 0, i)
                      }
                      //onBlur={(e) => handleSeries(e.target.value, item.id, i)}
                      disabled={edit ? false : true}
                    >
                      <option
                        key={`${item?.serie?.id}${i}`}
                        value={""}
                        className="box-text"
                      >
                        Select
                      </option>
                      {series?.map((serie, index) => {
                        return (
                          <option
                            selected={item.serie.id === serie.id}
                            key={index}
                            value={serie.id}
                            className="box-text"
                          >
                            {serie.seriesName}
                          </option>
                        );
                      })}
                    </select>
                  </PSelectWrapper>
                </PTableCell>
                <PTableCell>
                  <PTextFieldWrapper
                    id={
                      item?.id
                        ? `${item.serie.seriesName}-${item.id}-${i}`
                        : `${item.serie.seriesName}-0-${i}`
                    }
                    style={{ width: "175px" }}
                  >
                    <input
                      id={
                        item?.id
                          ? `i${item.serie.seriesName}-${item.id}-${i}`
                          : `i${item.serie.seriesName}-0-${i}`
                      }
                      key={
                        item?.id
                          ? `k${item.serie.seriesName}-${item.id}-${i}`
                          : `k${item.serie.seriesName}-0-${i}`
                      }
                      type="text"
                      style={{
                        textAlign: "left",
                        //marginRight: "20px",
                        height: "20px",
                      }}
                      defaultValue={item.modelName}
                      //value={item ? item.modelName : ""}
                      onChange={(e) => handleModelName(e.target.value, item, i)}
                      //onBlur={(e) => handleModelName(e.target.value, item?.id ? item?.id : 0, i)}
                      disabled={edit ? false : true}
                    />
                  </PTextFieldWrapper>
                </PTableCell>
                <PTableCell>
                  <PSelectWrapper dropdownDirection='down'
                    id={item?.id ? `group-${item.id}-${i}` : `group-0-${i}`}
                  >
                    <select
                      //value={item?.group ? item?.group : ""}
                      onChange={(e) =>
                        handleGroup(e.target.value, item?.id ? item?.id : 0, i)
                      }
                      disabled={edit ? false : true}
                    >
                      <option
                        key={`k${item?.group}${item.id}`}
                        value={""}
                        className="box-text"
                      >
                        Select
                      </option>
                      {series?.map((serie, index) => {
                        return (
                          <option
                            selected={item.group === serie.seriesName}
                            key={index}
                            value={serie.seriesName}
                            className="box-text"
                          >
                            {serie.seriesName}
                          </option>
                        );
                      })}
                    </select>
                  </PSelectWrapper>
                </PTableCell>
                <PTableCell>
                  <PCheckboxWrapper>
                    <input
                      type="checkbox"
                      name="rarity"
                      checked={item.rarity === "SpecialModel"}
                      onClick={() => handleRarity(item?.id ? item?.id : 0, i)}
                      disabled={edit ? false : true}
                    />
                    <span style={{ marginLeft: "4px" }}>
                      {t("Special Model")}
                    </span>
                  </PCheckboxWrapper>
                </PTableCell>
                <PTableCell>
                  <PCheckboxWrapper>
                    <input
                      type="checkbox"
                      name="engineType"
                      checked={
                        item.engineType === "Electro_hybrid" ? true : false
                      }
                      onClick={() =>
                        handleEngineType(item?.id ? item?.id : 0, i)
                      }
                      disabled={edit ? false : true}
                    />
                    <span style={{ marginLeft: "4px" }}>
                      {t("Electro/Hybrid")}
                    </span>
                  </PCheckboxWrapper>
                </PTableCell>
                <PTableCell>
                  <PFlex>
                    <PFlexItem alignSelf={"center"}>
                      <PCheckboxWrapper>
                        <input
                          type="checkbox"
                          name={`fmargin${item.id}`}
                          checked={item.hasFixedMargin ? true : false}
                          onClick={() =>
                            handleFixedMarginCheckbox(
                              item?.id ? item?.id : 0,
                              i
                            )
                          }
                          disabled={edit ? false : true}
                        />
                      </PCheckboxWrapper>
                    </PFlexItem>
                    <PFlexItem style={{ paddingLeft: "5px" }}>
                      {item.hasFixedMargin && (
                        <PTextFieldWrapper
                          id={
                            item?.id
                              ? `fmargin${item.modelName}-${item.id}-${i}`
                              : `fmargin${item.modelName}-0-${i}`
                          }
                          unit="€"
                          unitPosition="suffix"
                          style={{ width: "160px" }}
                        >
                          <input
                            id={
                              item?.id
                                ? `ifmargin${item.modelName}-${item.id}-${i}`
                                : `ifmargin${item.modelName}-0-${i}`
                            }
                            key={
                              item?.id
                                ? `kfmargin${item.modelName}-${item.id}-${i}`
                                : `ifmargin${item.modelName}-0-${i}`
                            }
                            type="text"
                            style={{
                              textAlign: "right",
                            }}
                            defaultValue={formatCurrencyWithNoUnit(
                              item.fixedMargin
                            )}
                            //value={item.modelName}
                            // onBlur={(e) => handleFixedMargin(e.target.value, item?.id ? item?.id : 0, i)}
                            onChange={(e) =>
                              handleFixedMargin(
                                e.target.value,
                                item?.id ? item?.id : 0,
                                i,
                                e
                              )
                            }
                            onBlur={(e) => {
                              onBlurCurrencyFormatter(e);
                            }}
                            disabled={edit ? false : true}
                          />
                        </PTextFieldWrapper>
                      )}
                    </PFlexItem>
                  </PFlex>
                </PTableCell>
                <PTableCell>
                  <PSwitch
                    checked={item.visibility}
                    onSwitchChange={(e) =>
                      handleVisible(e, item?.id ? item?.id : 0, i)
                    }
                    disabled={edit ? false : true}
                  ></PSwitch>
                </PTableCell>
              </PTableRow>
            )):
           
            <th colSpan={7} style={{textAlign:'center'}}>
              <div style={{marginTop:'16px'}}> {t("No results")}</div>
             </th>
            }
          </PTableBody>
        </PTable>
        {/* {models.length>0?  <div style ={{ marginTop:'16px', display:'flex', justifyContent:'center' }}>
          <PPagination totalItemsCount={500} itemsPerPage={25} activePage={1}/>
            </div>:null} */}
      </div>
    </>
  );
};
