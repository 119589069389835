export enum FilterType {
    PORSCHECENTER = 'Porsche Center',
    SERIES = 'Series',
    CUSTOMERTYPE = 'Customer Type',
    CUSTOMERGROUP = 'Customer Group',
    PAYMENT = 'Payment',
    PFSEXTRAS = 'PFS Extras',
    DELIVERY = 'Delivery',
    EXPORT = 'Export',
    ORDERSTATUS = 'Order Status',
    SPLIT = '^',
    ORDATE = 'OR Date',
    PLANNEDCD = 'Planned CD',
    ACTUALCD = 'Actual CD',
    VEHICLETYPE = 'Vehicle Type',
    SELLER = 'Seller',
    FIRSTAPPROVAL = 'First Approval',
    SECONDAPPROVAL = 'Second Approval',
    MONTHOFACCOUNTING = 'Month of Accounting'

}

