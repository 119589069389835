import { PFlex, PFlexItem, PMarque } from '@porsche-design-system/components-react';

import { PorscheFont } from '../assets/styles/CustomStyles';
import MenuButton from '../components/common/MenuButton';
import { Constants } from '../configs/Constants';
import { useTranslation } from 'react-i18next';

export const WelcomePage = (): JSX.Element => {
  const { t } = useTranslation();

  const handleLogout = () => {
    //authCtx.logout();
    localStorage.clear();
    sessionStorage.clear();
    let url = `${Constants.domain}`;
    window.open(url, "_self");
  }

    return (
      <>
        <div className="header">
          <PFlex justifyContent={"space-between"}>
            <PFlexItem style={{width: "33%"}}></PFlexItem>

            <PFlexItem style={{width: "33%"}} alignSelf={"center"}> 
              <PMarque className="logo" size="responsive" style={{ paddingTop: "5px" }}/>
            </PFlexItem>
            <PFlexItem className="header-right" shrink={0} style={{justifyContent: "right"}} >
                <div className="dot-dropdown">
                  <MenuButton handleLogout={handleLogout}/>
                </div>
            </PFlexItem>
          </PFlex>
        </div>
        <div className="custombody"> 
          <div className="main">
            <PorscheFont>
              {t("Welcome to the Porsche Dealer Calculation system! The administrators of your Porsche Center have been notified about your Login and will soon activate your account.")}
              {t("In case of any questions, please contact your Sales Manager.")}
            </PorscheFont>
          </div>
        </div>
      </>
    );
  };
  