import React, { useContext, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from "@mui/material";
import {
  PButtonPure,
  PButton,
  PFlex,
  PFlexItem,
  PPopover,
  PText,
  PGrid,
  PGridItem,
  PSelectWrapper,
  PSwitch,
  PIcon,
  PCheckboxWrapper,
  PTextFieldWrapper,
} from "@porsche-design-system/components-react";
import { formatDate } from "../../helpers/formatters";
import { PorscheFont } from "../../assets/styles/CustomStyles";
import { AuthContext } from "../../contexts/AuthContext";
import { CommissionsOrderResponseDTO } from "../../interfaces/Commissions";
import { formatCurrencyWithNoUnit, getExportRegion } from "../../mock/helper";
import { dcsgetCommissionHistory, getCommissionHistory } from "../../services/data/Helpers";
import { styled } from "@mui/material/styles";
import "./history.css";
import {FilterType} from "../FilterType";
import {ReactComponent as ManualCommissionIcon} from "../../assets/images/ManualCommissionIcon.svg";
import {ReactComponent as SubsequentIcon} from "../../assets/images/SubsequentIcon.svg";

import {
  haveCommonElements,
  filterWithLikeValue,
  checkEquleValue,
  filterDate,
  removeElementFromArray,
  formatMonth,
  checkAccountMonth
} from "../OrderFilter";
import { type } from "../../services/Constants";
import { SingleOrderDetailType } from "../../interfaces/OrderDetails";
import { Constant } from "../../configs/Constants";

interface Props {
  title?: string;
  component?: JSX.Element;
  handleToggle?: (show: boolean) => void;
  selected?: boolean;
  enableToggle?: boolean;
  disabled?: boolean;
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#0000",
    boxShadow: theme.shadows[1],
    fontSize: 15,
    maxWidth: 500,
  },
}));

export const History = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [ordersHistory, setOrdersHistory] = useState<
    Array<CommissionsOrderResponseDTO>
  >([]);
  const authCtx = useContext(AuthContext);

  //toggle
  const [show, setShow] = useState<boolean>(false);
  //search
  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState("");
  const { title, component, handleToggle, enableToggle, disabled } = props;
  //sorting
  const [order, setOrder] = useState("");
  //on Load Sort state
  const [onLoadOrder, setOnLoadOrder] = useState("RESET");
  //rrp state initial level
  const [rrpOrder, setRrpOrder] = useState<boolean>(false);
  //rrp state up
  const [rrpOrderUp, setRrpOrderUp] = useState<boolean>(false);
  //OR date
  const [OrDate, setOrDate] = useState<boolean>(false);
  const [OrDateUp, setOrDateUp] = useState<boolean>(false);
  //Planned date
  const [planDate, setplanDate] = useState<boolean>(false);
  const [planDateUp, setplanDateUp] = useState<boolean>(false);
  //Actual CD
  const [actualCdDate, setActualCdDate] = useState<boolean>(false);
  const [actualCdDateUp, setActualCdDateUp] = useState<boolean>(false);

  //discount state for only color hover handling
  //rrp dsc state initial level
  const [dscOrder, setDscOrder] = useState<boolean>(false);
  const [rrpOrderSecond, setRrrpOrderSecond] = useState<boolean>(false);
  //plan date
  const [planDateSecond, setPlanDateSecond] = useState<boolean>(false);
  //rrp dsc state up
  const [dscOrderUp, setDscOrderUp] = useState<boolean>(false);
  //counter
  const [count, setCount] = useState<number>(0);
  //OrDate
  const [orDateSecond, setOrDateSecond] = useState<boolean>(false);
  //counter OrDate and Planned CD
  const [orDateCount, setOrDateCount] = useState<number>(0);

  //for payment sorting state
  //sorting Payment
  const [orderPayment, setOrderPayment] = useState("");
  //sorting FirstApproval
  const [orderFirstApproval, setOrderFirstApproval] = useState("");
  //sorting SecondApproval
  const [orderSecondApproval, setOrderSecondApproval] = useState("");
  //sorting Month
  const [orderMonth, setOrderMonth] = useState("");
  //on Load Sort state
  const [onPaymentLoadOrder, setOnPaymentLoadOrder] = useState("RESET");
  //on Load first approval state
  const [onFirstApprovalLoadOrder, setOnFirstApprovalLoadOrder] =
    useState("RESET");
  //on Load second approval state
  const [onSecondApprovalLoadOrder, setOnSecondApprovalLoadOrder] =
    useState("RESET");
  //on Load Month
  const [onMonthLoadOrder, setOnMonthOrder] = useState("RESET");
  //payment state initial level
  const [paymentOrder, setPaymentOrder] = useState<boolean>(false);
  //payment state up
  const [paymentOrderUp, setPaymentOrderUp] = useState<boolean>(false);
  //payment
  const [paymentCount, setPaymentCount] = useState<number>(0);
  //first Approval state initial level
  const [firstApprovalOrder, setFirstApprovalOrder] = useState<boolean>(false);
  //first Approval state up
  const [firstApprovalOrderUp, setFirstApprovalOrderUp] =
    useState<boolean>(false);
  //first Approval
  const [firstApprovalCount, setFirstApprovalCount] = useState<number>(0);

  //Month
  const [monthOrder, setMonthOrder] = useState<boolean>(false);
  //Month
  const [monthOrderUp, setMonthOrderUp] = useState<boolean>(false);
  //Month
  const [monthCount, setMonthCount] = useState<number>(0);

  //second Approval state initial level
  const [secondApprovalOrder, setSecondApprovalOrder] =
    useState<boolean>(false);
  //second Approval state up
  const [secondApprovalOrderUp, setSecondApprovalOrderUp] =
    useState<boolean>(false);
  //second Approval
  const [secondApprovalCount, setSecondApprovalCount] = useState<number>(0);

  //for seller sorting state
  //sorting Payment
  const [orderSeller, setOrderSeller] = useState("");
  //on Load Sort state
  const [onSellerLoadOrder, setOnSellerLoadOrder] = useState("RESET");
  //payment state initial level
  const [sellerOrder, setSellerOrder] = useState<boolean>(false);
  //payment state up
  const [sellerOrderUp, setSellerOrderUp] = useState<boolean>(false);
  //payment
  const [sellerCount, setSellerCount] = useState<number>(0);
  //for Actual CD
  const [orderActualCd, setOrderActualCd] = useState("");
  const [sellerActualCd, setSellerActualCd] = useState<boolean>(false);
  const [sellerActualCdUp, setSellerActualCdUp] = useState<boolean>(false);
  const [actualCdCount, setActualCdCount] = useState<number>(0);

  //hover state
  const [isHoveringRrp, setIsHoveringRrp] = useState(false);
  const [isHoveringDisc, setIsHoveringDisc] = useState(false);
  const [isHoveringPymnt, setIsHoveringPymnt] = useState(false);
  const [isHoveringSecondApproval, setIsHoveringSecondApproval] =
    useState(false);
  const [isHoveringFirstApproval, setIsHoveringFirstApproval] = useState(false);
  const [isHoveringMonth, setIsHoveringMonth] = useState(false);
  const [isHoveringSellr, setIsHoveringSellr] = useState(false);
  const [isHoveringOrDate, setIsHoveringOrDate] = useState(false);
  const [isHoveringPlanCd, setIsHoveringPlanCd] = useState(false);
  const [isHoveringSellrActualCd, setIsHoveringSellrActualCd] = useState(false);

  //Series Dropdown
  const [seriesDropdown, setSelectSeriesDropdown] = useState<string[]>([
    "Cayman",
    "Boxster",
    "911",
    "Macan",
    "Cayenne",
    "Panamera",
    "Taycan",
  ]);
  //const [pfsDropdown, setSelectPfsDropdown] = useState<string[]>(["Leasing S","Service Plus","GAP","Car Policy","Porsche Card S"]);
  const [pfsDropdown, setSelectPfsDropdown] = useState<string[]>(["Leasing S","Service Plus","GAP","Car Policy","Porsche card S"]);

  //Planned Cd filter
  const [fromDatePlanCd, setFromDatePlanCd] = useState<string>("");
  const [toDatePlanCd, setToDatePlanCd] = useState<string>("");
  const [dateRangePlanCd, setDateRangePlanCd] = useState<string | any>("");
  const [disabledPlanDate, setDisabledPlanDate] = useState<boolean>(true);

  //Actual Cd filter
  const [fromDateActCd, setFromDateActCd] = useState<string>("");
  const [toDateActCd, setToDateActCd] = useState<string>("");
  const [dateRangeActCd, setDateRangeActCd] = useState<string | any>("");
  const [disabledActDate, setDisabledActDate] = useState<boolean>(true);

  //Month Cd filter
  const [fromDateMonthCd, setFromDateMonthCd] = useState<string>("");
  const [toDateMonthCd, setToDateMonthCd] = useState<string>("");
  const [dateRangeMonthCd, setDateRangeMonthCd] = useState<string | any>("");
  const [disabledMonthDate, setDisabledMonthDate] = useState<boolean>(true);

  //Enter state
  const [isEnteringRrp, setIsEnteringRrp] = useState(false);
  //Enter state Disc
  const [isEnteringDisc, setIsEnteringDisc] = useState(false);
  //Enter state Payment
  const [isEnteringPay, setIsEnteringPay] = useState(false);
  //Enter First approval
  const [isEnteringFirstApproval, setIsEnteringFirstApproval] = useState(false);
  //Enter Second approval
  const [isEnteringSecondApproval, setIsEnteringSecondApproval] =
    useState(false);
  //Enter Month
  const [isEnteringMonth, setIsEnteringMonth] = useState(false);
  //Enter state status
  const [isEnteringSeller, setisEnteringSeller] = useState(false);
  //Entering OR date
  const [isEnteringOrDate, setIsEnteringOrDate] = useState(false);
  //Entering Plan date
  const [isEnteringPlan, setIsEnteringPlanDate] = useState(false);
  //filters state for Actual CD
  const [isEnteringActualCd, setIsEnteringActualCd] = useState(false);

  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [filteredItems, setFilteredItems] = useState(ordersHistory);

  // set the initial state (an array with 1 object to start (this can be an empty object to start))
  const [selectedLbl, setSelectedLbl] = useState<string[]>([]);


  //toggle label
  //let toggleName: string = "Show filters";

  //Test furkan
  const [porscheFilter, setPorscheFilter] = useState<string[]>([]);
  const [isPopoverVisible, setIsPopoverVisible] = useState<null | number>(null);

  useEffect(() => {
    setFilteredItems(
      ordersHistory.filter((order) => {
        return (
            (haveCommonElements(porscheFilter.filter(p => p.split(FilterType.SPLIT)[0] == FilterType.PORSCHECENTER),order.origin)) &&
            (filterWithLikeValue(porscheFilter.filter(p => p.split(FilterType.SPLIT)[0] == FilterType.VEHICLETYPE),order.vehicleCategory)) &&
            (filterDate(porscheFilter.filter(p => p.split(FilterType.SPLIT)[0] == FilterType.PLANNEDCD),order.plannedCD)) &&
            (filterDate(porscheFilter.filter(p => p.split(FilterType.SPLIT)[0] == FilterType.ACTUALCD),order.actualCD)) &&
            (filterWithLikeValue(porscheFilter.filter(p => p.split(FilterType.SPLIT)[0] == FilterType.SERIES),order.model)) &&
            (haveCommonElements(porscheFilter.filter(p => p.split(FilterType.SPLIT)[0] == FilterType.PAYMENT),order.payment)) &&
            (checkEquleValue(porscheFilter.filter(p => p.split(FilterType.SPLIT)[0] == FilterType.PFSEXTRAS),order.pfsExtras)) &&
            (haveCommonElements(porscheFilter.filter(p => p.split(FilterType.SPLIT)[0] == FilterType.EXPORT),order.exportRegion)) &&
            (haveCommonElements(porscheFilter.filter(p => p.split(FilterType.SPLIT)[0] == FilterType.SELLER),order.seller)) &&
            (haveCommonElements(porscheFilter.filter(p => p.split(FilterType.SPLIT)[0] == FilterType.FIRSTAPPROVAL),order.firstApproval)) &&
            (haveCommonElements(porscheFilter.filter(p => p.split(FilterType.SPLIT)[0] == FilterType.SECONDAPPROVAL),order.secondApproval)) &&
            (checkAccountMonth(porscheFilter.filter(p => p.split(FilterType.SPLIT)[0] == FilterType.MONTHOFACCOUNTING),order.monthOfAccounting))
        )
      })
    );
  }, [
    porscheFilter
  ]);

  //Drop-Down outside on - off logic basic on ref ----start
  const [clickedOutsidePorsche, setClickedOutsidePorsche] = useState(false);
  const [clickedOutsideSeries, setClickedOutsideSeriese] = useState(false);
  const [clickedOutsideCustType, setClickedOutsideCustType] = useState(false);
  const [clickedOutsideCustGrp, setClickedOutsideCustGrp] = useState(false);
  const [clickedOutsidePayment, setClickedOutsidePayment] = useState(false);
  const [clickedOutsidePfsExtra, setClickedOutsidePfsExtra] = useState(false);
  const [clickedOutsideDelivery, setClickedOutsideDelivery] = useState(false);
  const [clickedOutsideExport, setClickedOutsideExport] = useState(false);
  const [clickedOutsideStatus, setClickedOutsideStatus] = useState(false);
  const [clickedOutsideFirstApproval, setClickedOutsideFirstApproval] =
    useState(false);
  const [clickedOutsideSecondApproval, setClickedOutsideSecondApproval] =
    useState(false);
  const [clickedOutsideMonth, setClickedOutsideMonth] = useState(false);
  const [clickedOutsideOrDate, setClickedOutsideOrDate] = useState(false);
  const [clickedOutsidePlannedCd, setClickedOutsidePlannedCd] = useState(false);
  const [clickedOutsideActualCd, setClickedOutsideActualCd] = useState(false);
  const [clickedOutsideMonthCd, setClickedOutsideMonthCd] = useState(false);
  const myRef = useRef<HTMLInputElement>(null);
  const myRefSeries = useRef<HTMLInputElement>(null);
  const myRefCustType = useRef<HTMLInputElement>(null);
  const myRefCustGrp = useRef<HTMLInputElement>(null);
  const myRefCustPayment = useRef<HTMLInputElement>(null);
  const myRefCustPfsExtra = useRef<HTMLInputElement>(null);
  const myRefCustPfsDelivery = useRef<HTMLInputElement>(null);
  const myRefCustPfsExport = useRef<HTMLInputElement>(null);
  const myRefCustPfsStaus = useRef<HTMLInputElement>(null);
  const myRefFirstApproval = useRef<HTMLInputElement>(null);
  const myRefSecondApproval = useRef<HTMLInputElement>(null);
  const myRefMonth = useRef<HTMLInputElement>(null);
  const myRefOrDate = useRef<HTMLInputElement>(null);
  const myRefActualCd = useRef<HTMLInputElement>(null);
  const myRefPlanCd = useRef<HTMLInputElement>(null);
  const myRefMonthCd = useRef<HTMLInputElement>(null);
  

  const handleClickInsidePorsche = () => {
    setClickedOutsidePorsche(!clickedOutsidePorsche);
  };
  const handleClickInsideSeries = () => {
    setClickedOutsideSeriese(!clickedOutsideSeries);
  };
  const handleClickInsideCustTyp = () => {
    setClickedOutsideCustType(!clickedOutsideCustType);
  };
  const handleClickInsideCustGrp = () => {
    setClickedOutsideCustGrp(!clickedOutsideCustGrp);
  };
  const handleClickInsidePaymnt = () => {
    setClickedOutsidePayment(!clickedOutsidePayment);
  };
  const handleClickInsidePfsExtra = () => {
    setClickedOutsidePfsExtra(!clickedOutsidePfsExtra);
  };
  const handleClickInsideDelivery = () => {
    setClickedOutsideDelivery(!clickedOutsideDelivery);
  };
  const handleClickInsideExport = () => {
    setClickedOutsideExport(!clickedOutsideExport);
  };
  const handleClickInsidePfsStatus = () => {
    setClickedOutsideStatus(!clickedOutsideStatus);
  };
  const handleClickInsideFirstApproval = () => {
    setClickedOutsideFirstApproval(!clickedOutsideFirstApproval);
  };
  const handleClickInsideSecondApproval = () => {
    setClickedOutsideSecondApproval(!clickedOutsideSecondApproval);
  };
  const handleClickInsideMonth = () => {
    setClickedOutsideMonth(!clickedOutsideMonth);
  };
  const handleClickInsideOrDate = () => {
    setClickedOutsideOrDate(!clickedOutsideOrDate);
  };
  const handleClickInsidePlanDate = () => {
    setClickedOutsidePlannedCd(!clickedOutsidePlannedCd);
  };
  const handleClickInsideActualCd = () => {
    setClickedOutsideActualCd(!clickedOutsideActualCd);
  };
  const handleClickInsideMonthCd = () => {
    setClickedOutsideMonthCd(!clickedOutsideMonthCd);
  };

  useEffect(() => {
    // Event listener to close the dropdown when clicking outside
    const handleClickOutside = (e: { target: any }) => {
      if (!myRef?.current?.contains(e.target)) {
        setClickedOutsidePorsche(false);
      }
      if (!myRefSeries?.current?.contains(e.target)) {
        setClickedOutsideSeriese(false);
      }
      if (!myRefCustType?.current?.contains(e.target)) {
        setClickedOutsideCustType(false);
      }
      if (!myRefCustGrp?.current?.contains(e.target)) {
        setClickedOutsideCustGrp(false);
      }
      if (!myRefCustPayment?.current?.contains(e.target)) {
        setClickedOutsidePayment(false);
      }
      if (!myRefCustPfsExtra?.current?.contains(e.target)) {
        setClickedOutsidePfsExtra(false);
      }
      if (!myRefCustPfsDelivery?.current?.contains(e.target)) {
        setClickedOutsideDelivery(false);
      }
      if (!myRefCustPfsExport?.current?.contains(e.target)) {
        setClickedOutsideExport(false);
      }
      if (!myRefCustPfsStaus?.current?.contains(e.target)) {
        setClickedOutsideStatus(false);
      }
      if (!myRefFirstApproval?.current?.contains(e.target)) {
        setClickedOutsideFirstApproval(false);
      }
      if (!myRefSecondApproval?.current?.contains(e.target)) {
        setClickedOutsideSecondApproval(false);
      }
      if (!myRefMonth?.current?.contains(e.target)) {
        setClickedOutsideMonth(false);
      }
      if (!myRefOrDate?.current?.contains(e.target)) {
        setClickedOutsideOrDate(false);
      }
      if (!myRefActualCd?.current?.contains(e.target)) {
        setClickedOutsideActualCd(false);
      }
      if (!myRefPlanCd?.current?.contains(e.target)) {
        setClickedOutsidePlannedCd(false);
      }
      if (!myRefMonthCd?.current?.contains(e.target)) {
        setClickedOutsideMonthCd(false);
      }
    };
  
    // Attach the event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);
  
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  //Drop-Down outside on - off logic basic on ref ---end
  useEffect(() => {
    const init = async () => {
      const apiResponse = await dcsgetCommissionHistory()
      if(apiResponse.status===type.SUCCESS){
        setOrdersHistory(apiResponse.response)
        setFilteredItems(apiResponse.response);
      }
    };
    init();
  }, []);

  useEffect(() => {
    if (enableToggle) setShow(enableToggle);
    // if (disabled) {
    //   setDisabledClassName("disabled")
    // }
  }, [enableToggle]);

  //search function
  const handleSearch = async (search: string) => {
    setSearchValue(search);
    setSearchResult(search);
    filterData(search);
  };
  //Comment for now

  // exclude column list from filter
  const excludeColumns = [
    "vehicleCategory",
    "model",
    "plannedCD",
    "actualCD",
    "copNumber",
    "payment",
    "pfsExtras",
    "exportRegion",
    "seller",
    "origin",
    "commission",
    "commissionRecalculation",
    "incentive",
    "incentiveRecalculation",
    "totalCommission",
    "rejectedInfo",
    "recalculationDate",
    "firstApproval",
    "firstApprovalDate",
    "secondApproval",
    "secondApprovalDate",
  ];
  // filter records by search text
  const filterData = (value: string) => {
    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === "") setFilteredItems(ordersHistory);
    else {
      const filteredData = filteredItems.filter((item) => {
        return Object.keys(item).some((key) =>
          excludeColumns.includes(key)
            ? false
            : item[key]?.toString()?.toLowerCase()?.includes(lowercasedValue)
        );
      });
      setFilteredItems(filteredData);
    }
  };

  //date of order Planned Cd
  const handleFromDateChangePlanCd = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFromDatePlanCd(event.target.value);
    //Button enable disable  validation
    const startDateValidationPlanCd = new Date(event.target.value);
    const endDateValidationPlanCd = new Date(toDatePlanCd);
    //alert(new Date(event.target.value));
    if (startDateValidationPlanCd <= endDateValidationPlanCd) {
      setDisabledPlanDate(false);
    }
    if (startDateValidationPlanCd > endDateValidationPlanCd) {
      setDisabledPlanDate(true);
    }
  };

  const handleToDateChangePlanCd = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setToDatePlanCd(event.target.value);
    //Button enable disable  validation
    const startDateValidationPlanCd = new Date(fromDatePlanCd);
    const endDateValidationPlanCd = new Date(event.target.value);
    if (startDateValidationPlanCd <= endDateValidationPlanCd) {
      setDisabledPlanDate(false);
    }
    if (startDateValidationPlanCd > endDateValidationPlanCd) {
      setDisabledPlanDate(true);
    }
  };

  //date of order Actual Cd
  const handleFromDateChangeActCd = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFromDateActCd(event.target.value);
    //Button enable disable  validation
    const startDateValidationActCd = new Date(event.target.value);
    const endDateValidationActCd = new Date(toDateActCd);
    //alert(new Date(event.target.value));
    if (startDateValidationActCd <= endDateValidationActCd) {
      setDisabledActDate(false);
    }
    if (startDateValidationActCd > endDateValidationActCd) {
      setDisabledActDate(true);
    }
  };

  const handleToDateChangeActCd = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setToDateActCd(event.target.value);
    //Button enable disable  validation
    const startDateValidationActCd = new Date(fromDateActCd);
    const endDateValidationActCd = new Date(event.target.value);
    if (startDateValidationActCd <= endDateValidationActCd) {
      setDisabledActDate(false);
    }
    if (startDateValidationActCd > endDateValidationActCd) {
      setDisabledActDate(true);
    }
  };

  //date of Month
  const handleFromDateChangeMonthCd = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFromDateMonthCd(event.target.value);
    //Button enable disable  validation
    const startDateValidationMonthCd = new Date(event.target.value);
    const endDateValidationMonthCd = new Date(toDateMonthCd);
    //alert(new Date(event.target.value));
    if (startDateValidationMonthCd <= endDateValidationMonthCd) {
      setDisabledMonthDate(false);
    }
    if (startDateValidationMonthCd > endDateValidationMonthCd) {
      setDisabledMonthDate(true);
    }
  };

  const handleToDateChangeMonthCd = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setToDateMonthCd(event.target.value);
    //Button enable disable  validation
    const startDateValidationMonthCd = new Date(fromDateMonthCd);
    const endDateValidationMonthCd = new Date(event.target.value);
    if (startDateValidationMonthCd <= endDateValidationMonthCd) {
      setDisabledMonthDate(false);
    }
    if (startDateValidationMonthCd > endDateValidationMonthCd) {
      setDisabledMonthDate(true);
    }
  };

  const calculateDateRange = (str: any) => {
    if(str === "PlanCd"){
      filterChecked("Planned CD^"+formatDate(fromDatePlanCd)+' '+'-'+' '+formatDate(toDatePlanCd))
    }

    if(str === "ActualCd"){
      filterChecked("Actual CD^"+formatDate(fromDateActCd)+' '+'-'+' '+formatDate(toDateActCd))
    }

    if (str === "MonthCd") {
      filterChecked(FilterType.MONTHOFACCOUNTING+"^"+formatMonth(fromDateMonthCd)+' '+'-'+' '+formatMonth(toDateMonthCd))
    }
  };

  const SearchResult = (): JSX.Element => {
    if (searchResult.length === 0) {
      return <></>;
    }

    return (
      <ul className={`autocomplete ${searchResult.length > 3}`}>
        <li style={{ display: "flex" }}>
          <PText weight="bold" size="small">
            '{searchResult}'
          </PText>
        </li>
      </ul>
    );
  };

  //toggle
  const handleClickToggle = () => {
    if (handleToggle) handleToggle(!show);
    setShow(!show);
  };
  //mouse hover
  const handleMouseEnter = (col: string) => {
    if (col === "PlannedCd") {
      setIsHoveringOrDate(true);
    }
    if (col === "actualCd") {
      setIsHoveringPlanCd(true);
    }

    if (col === "payment") {
      setIsHoveringPymnt(true);
    }

    if (col === "seller") {
      setIsHoveringSellr(true);
    }

    if (col === "firstApproval") {
      setIsHoveringFirstApproval(true);
    }

    if (col === "secondApproval") {
      setIsHoveringSecondApproval(true);
    }

    if (col === "month") {
      setIsHoveringMonth(true);
    }
  };

  const handleMouseLeave = (col: string) => {
    if (col === "PlannedCd") {
      setIsHoveringOrDate(false);
    }
    if (col === "actualCd") {
      setIsHoveringPlanCd(false);
    }
    if (col === "payment") {
      setIsHoveringPymnt(false);
    }

    if (col === "seller") {
      setIsHoveringSellr(false);
    }

    if (col === "firstApproval") {
      setIsHoveringFirstApproval(false);
    }

    if (col === "secondApproval") {
      setIsHoveringSecondApproval(false);
    }

    if (col === "month") {
      setIsHoveringMonth(false);
    }
  };

  //sorting function
  const sorting = (col: string) => {
    if (col === "PlannedCd") {
      setActualCdCount(0);
      setPaymentCount(0);
      setSellerCount(0);
      setFirstApprovalCount(0);
      setSecondApprovalCount(0);
      setSecondApprovalOrder(false);
      setMonthCount(0);
      setMonthOrder(false);
      //up arrow
      setMonthOrderUp(false);
      setIsEnteringMonth(false);
      setSecondApprovalOrderUp(false);
      setIsEnteringSecondApproval(false);
      setFirstApprovalOrderUp(false);
      setFirstApprovalOrder(false);
      setOrderFirstApproval("DSC");
      setIsEnteringFirstApproval(false);
      //final reset state off
      setPaymentOrder(false);
      setActualCdDateUp(false);
      //OrDate down off
      setActualCdDate(false);
      //up arrow
      setPaymentOrderUp(false);
      setIsEnteringPay(false);
      setIsEnteringPlanDate(false);
      setIsHoveringOrDate(true);
      setIsEnteringOrDate(true);
      //payment color off
      setIsEnteringPay(false);
      //status off
      setisEnteringSeller(false);
      //alert("I am in RRP");
      setIsHoveringRrp(false);
      setIsHoveringDisc(false);
      //Enter Filter color  State
      setIsEnteringRrp(false);
      setIsEnteringDisc(false);
      //Payment off
      //final reset state off
      setPaymentOrder(false);
      //up arrow
      setPaymentOrderUp(false);
      //off payment
      //final reset state off
      setPaymentOrder(false);
      //up arrow
      setPaymentOrderUp(false);
      //Payment off
      //final reset state off
      setSellerOrder(false);
      setSellerOrderUp(false);
      setplanDate(false);
      setplanDateUp(false);
      setPaymentOrder(false);
      //up arrow
      setPaymentOrderUp(false);
      //final reset state off
      setSellerActualCd(false);
      setSellerActualCdUp(false);
      setIsEnteringActualCd(false);
      if (orDateCount == 0) {
        const sorted = [...filteredItems].sort(
          (a: any, b: any) =>
            +new Date(b?.plannedCD.split("-").reverse()) -
            +new Date(a?.plannedCD.split("-").reverse())
        );
        //Down arrow OrDate
        setOrDate(true);
        //down arrow down
        setOrDateUp(false);
        setFilteredItems(sorted);
        setOrder("DSC");
        //Up arrow false
        setRrpOrderUp(false);
        setOrDateCount(orDateCount + 1);
        //color code condition
        setRrrpOrderSecond(false);
        setOrDateSecond(true);
      }

      if (orDateCount == 1) {
        const sorted = [...filteredItems].sort(
          (a: any, b: any) =>
            +new Date(a?.plannedCD.split("-").reverse()) -
            +new Date(b?.plannedCD.split("-").reverse())
        );
        setFilteredItems(sorted);
        setOrder("ASC");
        //OrDate up on
        setOrDateUp(true);
        //OrDate down off
        setOrDate(false);
        //down arrow down
        setRrpOrder(false);
        //up arrow
        setRrpOrderUp(false);
        setOrDateCount(orDateCount + 1);
        //color code condition
        setRrrpOrderSecond(true);
        setOrDateSecond(true);
      }

      if (orDateCount == 2) {
        setOrDateCount(0);
        if(porscheFilter.length > 0){
          const sortedDrpFilter = [...filteredItems].sort(
            (a: any, b: any) =>
              +new Date(a?.plannedCD.split("-").reverse()) -
              +new Date(b?.plannedCD.split("-").reverse())
          );
          setFilteredItems(sortedDrpFilter);
        }else {
          setFilteredItems(ordersHistory);
        }
        //setFilteredItems(ordersHistory);
        //OrDate down off
        setOrDate(false);
        setOrDateUp(false);
        //down arrow down
        setRrpOrder(false);
        //up arrow
        setRrpOrderUp(false);
        //final reset state off
        //color code condition
        setOrDateSecond(false);
        //Enter Filter color  State off
        setIsEnteringOrDate(false);
        setIsEnteringPlanDate(false);
        setOrder("DSC");
        setRrrpOrderSecond(true);
      }
    }

    if (col === "actualCd") {
      setOrDateCount(0);
      setPaymentCount(0);
      setSellerCount(0);
      setFirstApprovalCount(0);
      setSecondApprovalCount(0);
      setMonthCount(0);
      setMonthOrder(false);
      //up arrow
      setMonthOrderUp(false);
      setIsEnteringMonth(false);
      setSecondApprovalOrder(false);
      setSecondApprovalOrderUp(false);
      setIsEnteringSecondApproval(false);
      setFirstApprovalOrderUp(false);
      setFirstApprovalOrder(false);
      setOrderFirstApproval("DSC");
      setIsEnteringFirstApproval(false);
      //final reset state off
      setPaymentOrder(false);
      setOrDate(false);
      setOrDateUp(false);
      //up arrow
      setPaymentOrderUp(false);
      setIsEnteringPay(false);
      setIsEnteringPlanDate(true);
      setIsEnteringOrDate(false);
      setIsHoveringPlanCd(true);
      //payment color off
      setIsEnteringPay(false);
      //status off
      setisEnteringSeller(false);
      //alert("I am in RRP");
      setIsHoveringRrp(false);
      setIsHoveringDisc(false);
      //Enter Filter color  State
      setIsEnteringRrp(false);
      setIsEnteringDisc(false);
      //Payment off
      //final reset state off
      setPaymentOrder(false);
      //up arrow
      setPaymentOrderUp(false);
      //off payment
      //final reset state off
      setPaymentOrder(false);
      //up arrow
      setPaymentOrderUp(false);
      //Payment off
      //final reset state off
      setSellerOrder(false);
      setSellerOrderUp(false);
      setOrDate(false);
      setOrDateUp(false);
      //final reset state off
      setSellerActualCd(false);
      setSellerActualCdUp(false);
      setIsEnteringActualCd(false);
      if (actualCdCount == 0) {
        const sorted = [...filteredItems].sort(
          (a: any, b: any) =>
            +new Date(b?.actualCD.split("-").reverse()) -
            +new Date(a?.actualCD.split("-").reverse())
        );
        setFilteredItems(sorted);
        //Down arrow OrDate
        setActualCdDate(true);
        setActualCdDateUp(false);
        //down arrow down
        setRrpOrder(false);
        setOrder("DSC");
        //Up arrow false
        setRrpOrderUp(false);
        setActualCdCount(actualCdCount + 1);
        //color code condition
        setPlanDateSecond(true);
      }

      if (actualCdCount == 1) {
        const sorted = [...filteredItems].sort(
          (a: any, b: any) =>
            +new Date(a?.actualCD.split("-").reverse()) -
            +new Date(b?.actualCD.split("-").reverse())
        );
        setFilteredItems(sorted);
        //OrDate up on
        setActualCdDateUp(true);
        //OrDate down off
        setActualCdDate(false);
        setRrpOrder(false);
        setOrder("ASC");
        setActualCdCount(actualCdCount + 1);
        //color code condition
        setPlanDateSecond(true);
      }

      if (actualCdCount == 2) {
        if(porscheFilter.length > 0){
          const sortedDrpFilter = [...filteredItems].sort(
            (a: any, b: any) =>
              +new Date(a?.actualCD.split("-").reverse()) -
              +new Date(b?.actualCD.split("-").reverse())
          );
          setFilteredItems(sortedDrpFilter);
        }else {
          setFilteredItems(ordersHistory);
        }
        //setFilteredItems(ordersHistory);
        //OrDate up on
        setActualCdDateUp(false);
        //OrDate down off
        setActualCdDate(false);
        setOrder("DSC");
        //color code condition
        setPlanDateSecond(true);
        setIsEnteringPlanDate(false);
        //down arrow down
        setRrpOrder(false);
        //up arrow
        setRrpOrderUp(false);
        //final reset state off
        //color code condition
        setPlanDateSecond(false);
        //Enter Filter color  State off
        setIsEnteringRrp(false);
        setIsEnteringPlanDate(false);
        setIsEnteringOrDate(false);
        setActualCdCount(0);
      }
    }

    if (col === "payment") {
      setSecondApprovalOrder(false);
      setSecondApprovalOrderUp(false);
      setIsEnteringSecondApproval(false);
      setActualCdDateUp(false);
      //OrDate down off
      setActualCdDate(false);
      //final reset off
      //OrDate up on
      setActualCdCount(0);
      setOrDateCount(0);
      setSellerCount(0);
      setFirstApprovalCount(0);
      setSecondApprovalCount(0);
      setMonthCount(0);
      setMonthOrder(false);
      //up arrow
      setMonthOrderUp(false);
      setIsEnteringMonth(false);
      setFirstApprovalOrderUp(false);
      setFirstApprovalOrder(false);
      setOrderFirstApproval("DSC");
      setIsEnteringFirstApproval(false);
      setIsEnteringPlanDate(false);
      setIsEnteringOrDate(false);
      setIsEnteringRrp(false);
      setOrDateUp(false);
      setIsEnteringOrDate(false);
      //OrDate down off
      setOrDate(false);
      //final reset state off
      setSellerActualCd(false);
      setSellerActualCdUp(false);
      setIsEnteringActualCd(false);
      //payment off
      setIsEnteringDisc(false);
      //setOrders(orders);
      setIsEnteringPay(true);
      //RRP OFF
      //down arrow down
      setRrpOrder(false);
      //up arrow
      setRrpOrderUp(false);
      //final reset state off
      //color code condition
      setRrrpOrderSecond(false);
      //Enter Filter color  State off
      setIsEnteringRrp(false);
      //RRP OFF
      //final reset state off
      setSellerOrder(false);
      setSellerOrderUp(false);
      setisEnteringSeller(false);
      if (paymentCount == 0) {
        const sorted = [...filteredItems].sort((a: any, b: any) =>
          a?.payment.toString().toLowerCase() >
          b?.payment.toString().toLowerCase()
            ? 1
            : -1
        );
        setFilteredItems(sorted);
        //down arrow down
        setPaymentOrder(true);
        setOrderPayment("DSC");
        //Up arrow false
        setPaymentOrderUp(false);
        setPaymentCount(paymentCount + 1);
      }

      if (paymentCount == 1) {
        const sorted = [...filteredItems].sort((a: any, b: any) =>
          a?.payment.toString().toLowerCase() >
          b?.payment.toString().toLowerCase()
            ? -1
            : 1
        );
        setFilteredItems(sorted);
        //down arrow down
        setOrderPayment("ASC");
        //up arrow
        setPaymentOrderUp(true);
        setPaymentOrder(false);
        setPaymentCount(paymentCount + 1);
      }

      if (paymentCount == 2) {
        setPaymentCount(0);
        if(porscheFilter.length > 0){
          const sortedDrpFilter = [...filteredItems].sort((a: any, b: any) =>
          a?.payment.toString().toLowerCase() >
          b?.payment.toString().toLowerCase()
            ? -1
            : 1
        );
          setFilteredItems(sortedDrpFilter);
        }else {
          setFilteredItems(ordersHistory);
        }
        //setFilteredItems(ordersHistory);
        //final reset state off
        setPaymentOrder(false);
        //up arrow
        setPaymentOrderUp(false);
        setIsEnteringPay(false);
      }
    }

    if (col === "seller") {
      setActualCdCount(0);
      setOrDateCount(0);
      setPaymentCount(0);
      setFirstApprovalCount(0);
      setSecondApprovalCount(0);
      setMonthCount(0);
      setMonthOrder(false);
      //up arrow
      setMonthOrderUp(false);
      setIsEnteringMonth(false);
      setSecondApprovalOrder(false);
      setSecondApprovalOrderUp(false);
      setIsEnteringSecondApproval(false);
      setFirstApprovalOrderUp(false);
      setFirstApprovalOrder(false);
      setOrderFirstApproval("DSC");
      setIsEnteringFirstApproval(false);
      setActualCdDateUp(false);
      //OrDate down off
      setActualCdDate(false);
      setOrDate(false);
      setOrDateUp(false);
      //final reset off
      //OrDate up on
      setIsEnteringPlanDate(false);
      setIsEnteringOrDate(false);
      setIsEnteringRrp(false);
      setOrDateUp(false);
      setIsEnteringOrDate(false);
      //final reset state off
      setSellerActualCd(false);
      setSellerActualCdUp(false);
      setIsEnteringActualCd(false);
      //final reset state off
      setPaymentOrder(false);
      //up arrow
      setPaymentOrderUp(false);
      //payment color off
      setIsEnteringPay(false);
      //payment off
      setIsEnteringDisc(false);
      //setOrders(orders);
      //RRP OFF
      setisEnteringSeller(true);
      //RRP OFF
      //down arrow down
      setRrpOrder(false);
      //up arrow
      setRrpOrderUp(false);
      //final reset state off
      //color code condition
      setRrrpOrderSecond(false);
      //Enter Filter color  State off
      setIsEnteringRrp(false);
      if (sellerCount == 0) {
        const sorted = [...filteredItems].sort((a: any, b: any) =>
          a?.seller.toString().toLowerCase() >
          b?.seller.toString().toLowerCase()
            ? 1
            : -1
        );
        setFilteredItems(sorted);
        //down arrow down
        setSellerOrder(true);
        setOrderSeller("DSC");
        //Up arrow false
        setSellerOrderUp(false);
        setSellerCount(sellerCount + 1);
      }

      if (sellerCount == 1) {
        const sorted = [...filteredItems].sort((a: any, b: any) =>
          a?.seller.toString().toLowerCase() >
          b?.seller.toString().toLowerCase()
            ? -1
            : 1
        );
        //down arrow down
        setSellerOrder(false);
        setFilteredItems(sorted);
        setOrderSeller("ASC");
        //up arrow
        setSellerOrderUp(true);
        setSellerCount(sellerCount + 1);
      }
      if (sellerCount == 2) {
        //setFilteredItems(ordersHistory);
        if(porscheFilter.length > 0){
          const sortedDrpFilter = [...filteredItems].sort((a: any, b: any) =>
          a?.seller.toString().toLowerCase() >
          b?.seller.toString().toLowerCase()
            ? -1
            : 1
        );
          setFilteredItems(sortedDrpFilter);
        }else {
          setFilteredItems(ordersHistory);
        }
        setSellerCount(0);
        //final reset state off
        setSellerOrder(false);
        setSellerOrderUp(false);
        setisEnteringSeller(false);
      }
    }

    //first and second approval
    if (col === "firstApproval") {
      setActualCdCount(0);
      setPaymentCount(0);
      setSellerCount(0);
      setOrDateCount(0);
      setSecondApprovalCount(0);
      setMonthCount(0);
      setMonthOrder(false);
      //up arrow
      setMonthOrderUp(false);
      setIsEnteringMonth(false);
      setOrDate(false);
      setOrDateUp(false);
      //OrDate up on
      setActualCdDateUp(false);
      //OrDate down off
      setActualCdDate(false);
      setSellerActualCd(false);
      setSellerActualCdUp(false);
      setIsEnteringActualCd(false);
      setPaymentOrder(false);
      //up arrow
      setPaymentOrderUp(false);
      setIsEnteringPay(false);
      //final reset state off
      setSellerOrder(false);
      setSellerOrderUp(false);
      setisEnteringSeller(false);
      setIsEnteringFirstApproval(true);
      //final reset state off
      setSecondApprovalOrder(false);
      //up arrow
      setSecondApprovalOrderUp(false);
      setIsEnteringSecondApproval(false);
      //final reset off
      //OrDate up on
      setIsEnteringPlanDate(false);
      setIsEnteringOrDate(false);
      setIsEnteringRrp(false);
      setOrDateUp(false);
      setIsEnteringOrDate(false);
      //OrDate down off
      setOrDate(false);
      //final reset state off
      setSellerActualCd(false);
      setSellerActualCdUp(false);
      setIsEnteringActualCd(false);
      //payment off
      setIsEnteringDisc(false);
      //setOrders(orders);
      setIsEnteringFirstApproval(true);
      setIsEnteringPay(false);
      //RRP OFF
      //down arrow down
      setRrpOrder(false);
      //up arrow
      setRrpOrderUp(false);
      //final reset state off
      //color code condition
      setRrrpOrderSecond(false);
      //Enter Filter color  State off
      setIsEnteringRrp(false);
      //RRP OFF
      //final reset state off
      setSellerOrder(false);
      setSellerOrderUp(false);
      setisEnteringSeller(false);
      if (firstApprovalCount == 0) {
        const sorted = [...filteredItems].sort((a: any, b: any) =>
          a?.firstApproval.toString().toLowerCase() >
          b?.firstApproval.toString().toLowerCase()
            ? 1
            : -1
        );
        //down arrow down
        setFirstApprovalOrder(true);
        setFilteredItems(sorted);
        setOrderFirstApproval("DSC");
        //Up arrow false
        setFirstApprovalOrderUp(false);
        setFirstApprovalCount(firstApprovalCount + 1);
      }

      if (firstApprovalCount == 1) {
        const sorted = [...filteredItems].sort((a: any, b: any) =>
          a?.firstApproval.toString().toLowerCase() >
          b?.firstApproval.toString().toLowerCase()
            ? -1
            : 1
        );
        setFilteredItems(sorted);
        //down arrow down
        setFirstApprovalOrder(false);
        setOrderFirstApproval("ASC");
        //up arrow
        setFirstApprovalOrderUp(true);
        setFirstApprovalCount(firstApprovalCount + 1);
      }

      if (firstApprovalCount == 2) {
        //setCount(count + 0);
        //setFilteredItems(ordersHistory);
        if(porscheFilter.length > 0){
          const sortedDrpFilter = [...filteredItems].sort((a: any, b: any) =>
          a?.firstApproval.toString().toLowerCase() >
          b?.firstApproval.toString().toLowerCase()
            ? -1
            : 1
        );
          setFilteredItems(sortedDrpFilter);
        }else {
          setFilteredItems(ordersHistory);
        }
        setFirstApprovalOrderUp(false);
        setFirstApprovalOrder(false);
        setOrderFirstApproval("DSC");
        setIsEnteringFirstApproval(false);
        //up arrow
        setFirstApprovalCount(0);
      }
    }

    if (col === "secondApproval") {
      setActualCdCount(0);
      setPaymentCount(0);
      setSellerCount(0);
      setOrDateCount(0);
      setFirstApprovalCount(0);
      setMonthCount(0);
      setMonthOrder(false);
      //up arrow
      setMonthOrderUp(false);
      setIsEnteringMonth(false);
      setSellerOrder(false);
      setSellerOrderUp(false);
      setisEnteringSeller(false);
      setIsEnteringPlanDate(false);
      setIsEnteringPay(false);
      setOrDate(false);
      setOrDateUp(false);
      setActualCdDateUp(false);
      setPaymentOrder(false);
      //up arrow
      setPaymentOrderUp(false);
      //OrDate down off
      setActualCdDate(false);
      //final reset state off
      setFirstApprovalOrder(false);
      //up arrow
      setFirstApprovalOrderUp(false);
      setIsEnteringFirstApproval(false);
      setIsEnteringSecondApproval(true);
      setIsEnteringFirstApproval(false);
      //final reset off
      //OrDate up on
      setIsEnteringPlanDate(false);
      setIsEnteringOrDate(false);
      setIsEnteringRrp(false);
      setOrDateUp(false);
      setIsEnteringOrDate(false);
      //OrDate down off
      setOrDate(false);
      //final reset state off
      setSellerActualCd(false);
      setSellerActualCdUp(false);
      setIsEnteringActualCd(false);
      //payment off
      setIsEnteringDisc(false);
      //setOrders(orders);
      setIsEnteringPay(false);
      //RRP OFF
      //down arrow down
      setRrpOrder(false);
      //up arrow
      setRrpOrderUp(false);
      //final reset state off
      //color code condition
      setRrrpOrderSecond(false);
      //Enter Filter color  State off
      setIsEnteringRrp(false);
      //RRP OFF
      //final reset state off
      setSellerOrder(false);
      setSellerOrderUp(false);
      setisEnteringSeller(false);
      if (secondApprovalCount == 0) {
        const sorted = [...filteredItems].sort((a: any, b: any) =>
          a?.secondApproval.toString().toLowerCase() >
          b?.secondApproval.toString().toLowerCase()
            ? 1
            : -1
        );
        setFilteredItems(sorted);
        //down arrow down
        setSecondApprovalOrder(true);
        setOrderSecondApproval("DSC");
        //Up arrow false
        setSecondApprovalOrderUp(false);
        setSecondApprovalCount(secondApprovalCount + 1);
      }

      if (secondApprovalCount == 1) {
        const sorted = [...filteredItems].sort((a: any, b: any) =>
          a?.secondApproval.toString().toLowerCase() >
          b?.secondApproval.toString().toLowerCase()
            ? -1
            : 1
        );
        setFilteredItems(sorted);
        //down arrow down
        setSecondApprovalOrder(false);
        setOrderSecondApproval("ASC");
        //up arrow
        setSecondApprovalOrderUp(true);
        setSecondApprovalCount(secondApprovalCount + 1);
      }

      if (secondApprovalCount == 2) {
        setSecondApprovalCount(0);
        if(porscheFilter.length > 0){
          const sortedDrpFilter = [...filteredItems].sort((a: any, b: any) =>
          a?.secondApproval.toString().toLowerCase() >
          b?.secondApproval.toString().toLowerCase()
            ? -1
            : 1
        );
          setFilteredItems(sortedDrpFilter);
        }else {
          setFilteredItems(ordersHistory);
        }
        //setFilteredItems(ordersHistory);
        //final reset state off
        setSecondApprovalOrder(false);
        //up arrow
        setOrderSecondApproval("DSC");
        setSecondApprovalOrderUp(false);
        setIsEnteringSecondApproval(false);
        setIsEnteringSecondApproval(false);
      }
    }

    if (col === "month") {
      setActualCdCount(0);
      setPaymentCount(0);
      setSellerCount(0);
      setOrDateCount(0);
      setFirstApprovalCount(0);
      setSecondApprovalCount(0);
      setMonthCount(0);
      setIsEnteringMonth(true);
      setActualCdDate(false);
      setActualCdDate(false);
      setSecondApprovalOrder(false);
      setSecondApprovalOrderUp(false);
      setIsEnteringSecondApproval(false);
      setIsEnteringSecondApproval(false);
      setOrDateUp(false);
      //OrDate down off
      setOrDate(false);
      setActualCdDate(false);
      setActualCdDateUp(false);
      setPaymentOrder(false);
      //up arrow
      setPaymentOrderUp(false);
      setIsEnteringPay(false);
      setSellerOrder(false);
      setSellerOrderUp(false);
      setisEnteringSeller(false);
      setFirstApprovalOrderUp(false);
      setFirstApprovalOrder(false);
      setIsEnteringFirstApproval(false);
      setSecondApprovalOrderUp(false);
      setIsEnteringSecondApproval(false);
      setIsEnteringSecondApproval(false);
      //final reset state off
      setPaymentOrder(false);
      //up arrow
      setPaymentOrderUp(false);
      setIsEnteringPay(false);
      //final reset state off
      setFirstApprovalOrder(false);
      //up arrow
      setFirstApprovalOrderUp(false);
      setIsEnteringFirstApproval(false);
      //up arrow
      setSecondApprovalOrderUp(false);
      setIsEnteringSecondApproval(false);
      setIsEnteringSecondApproval(false);
      //final reset state off
      setFirstApprovalOrder(false);
      //up arrow
      setFirstApprovalOrderUp(false);
      setIsEnteringFirstApproval(false);
      setIsEnteringSecondApproval(false);
      setIsEnteringFirstApproval(false);
      //final reset off
      //OrDate up on
      setIsEnteringPlanDate(false);
      setIsEnteringOrDate(false);
      setIsEnteringRrp(false);
      setOrDateUp(false);
      setIsEnteringOrDate(false);
      //OrDate down off
      setOrDate(false);
      //final reset state off
      setSellerActualCd(false);
      setSellerActualCdUp(false);
      setIsEnteringActualCd(false);
      //payment off
      setIsEnteringDisc(false);
      //setOrders(orders);
      setIsEnteringPay(false);
      //RRP OFF
      //down arrow down
      setRrpOrder(false);
      //up arrow
      setRrpOrderUp(false);
      //final reset state off
      //color code condition
      setRrrpOrderSecond(false);
      //Enter Filter color  State off
      setIsEnteringRrp(false);
      //RRP OFF
      //final reset state off
      setSellerOrder(false);
      setSellerOrderUp(false);
      setisEnteringSeller(false);
      if (monthCount == 0) {
        //below code sort month as per year

        const sortedData = filteredItems.slice().sort((a, b) => {
            const dateA = new Date(a.monthOfAccounting);
            const dateB = new Date(b.monthOfAccounting);
            return dateB.getTime() - dateA.getTime();
        }); 
          setFilteredItems(sortedData);
    
        //down arrow down
        setMonthOrder(true);
        //setFilteredItems(sorted);
        setOrderMonth("DSC");
        //Up arrow false
        setMonthOrderUp(false);
        setMonthCount(monthCount + 1);
      }

      if (monthCount == 1) {
        //below code sort month as per year
        const sortedData = filteredItems.slice().sort((a, b) => {
          const dateA = new Date(a.monthOfAccounting);
          const dateB = new Date(b.monthOfAccounting);
          return dateA.getTime() - dateB.getTime();
        }); 
        setFilteredItems(sortedData);

        //down arrow down
        setMonthOrder(false);
        //setFilteredItems(sortedData);
        setOrderMonth("ASC");
        //up arrow
        setMonthOrderUp(true);
        setMonthCount(monthCount + 1);
      }

      if (monthCount == 2) {
        //setCount(count + 0);
        setMonthCount(0);
        //setFilteredItems(ordersHistory);
        if(porscheFilter.length > 0){       
        //below code sort month as per year
        const sortedData = filteredItems.slice().sort((a, b) => {
          const dateA = new Date(a.monthOfAccounting);
          const dateB = new Date(b.monthOfAccounting);
          return dateA.getTime() - dateB.getTime();
        }); 
        setFilteredItems(sortedData);
        }else {
          setFilteredItems(ordersHistory);
        }
        //final reset state off
        setMonthOrder(false);
        //up arrow
        setMonthOrderUp(false);
        setIsEnteringMonth(false);
        setOrderMonth("DSC");
      }
    }
  };

  // Filter Dropdown Data
  const filterChecked = (checkedVal: string) => {
    const indexCheckVal = porscheFilter.indexOf(checkedVal);
    const checkbox = document.querySelector(`input[name="${checkedVal}"]`) as HTMLInputElement;

    if((checkbox !==null && checkbox.checked) && indexCheckVal === -1){
      setPorscheFilter([...porscheFilter,checkedVal])
    }

    if(indexCheckVal !== -1 && (checkedVal.split(FilterType.SPLIT)[0] !== (FilterType.PLANNEDCD || FilterType.ACTUALCD || FilterType.MONTHOFACCOUNTING))){
      setPorscheFilter(removeElementFromArray(porscheFilter, checkedVal))
      if(checkbox !== null){
        checkbox.checked = false
      }
    }

    if(checkedVal.split(FilterType.SPLIT)[0] === FilterType.PLANNEDCD){
      const orDateck = porscheFilter.filter((item) => item.startsWith(FilterType.PLANNEDCD));

      if(orDateck.length!==0){
        porscheFilter.splice(porscheFilter.indexOf(orDateck[0]),1)
        selectedFilters.splice(selectedFilters.indexOf(orDateck[0]),1)
      }
      setPorscheFilter([...porscheFilter,checkedVal])
    }

    if(checkedVal.split(FilterType.SPLIT)[0] === FilterType.ACTUALCD){
      const orDateck = porscheFilter.filter((item) => item.startsWith(FilterType.ACTUALCD));

      if(orDateck.length!==0){
        porscheFilter.splice(porscheFilter.indexOf(orDateck[0]),1)
        selectedFilters.splice(selectedFilters.indexOf(orDateck[0]),1)
      }
      setPorscheFilter([...porscheFilter,checkedVal])
    }

    if(checkedVal.split(FilterType.SPLIT)[0] === FilterType.MONTHOFACCOUNTING){
      const orDateck = porscheFilter.filter((item) => item.startsWith(FilterType.MONTHOFACCOUNTING));

      if(orDateck.length!==0){
        porscheFilter.splice(porscheFilter.indexOf(orDateck[0]),1)
        selectedFilters.splice(selectedFilters.indexOf(orDateck[0]),1)
      }
      setPorscheFilter([...porscheFilter,checkedVal])
    }

    if (selectedFilters.includes(checkedVal)) {
      let filters = selectedFilters.filter((el) => el !== checkedVal);
      setSelectedFilters(filters);
    } else {
      setSelectedFilters([...selectedFilters, checkedVal]);
    }
  }

  const filterCheckedRemove = (checkedVal:string) => {
    const indexCheckVal = porscheFilter.indexOf(checkedVal);

    if(indexCheckVal !== -1){
      setPorscheFilter(removeElementFromArray(porscheFilter, checkedVal))
      const checkbox = document.querySelector(`input[name="${checkedVal}"]`) as HTMLInputElement;
      if(checkbox != null){
        checkbox.checked = false
      }
    }

    if (selectedFilters.includes(checkedVal)) {
      let filters = selectedFilters.filter((el) => el !== checkedVal);
      setSelectedFilters(filters);
    } else {
      setSelectedFilters([...selectedFilters, checkedVal]);
    }
  };

  const handleMouseLeaveFilterIcn = (lblFilter: any) => {
    const filterLbl = lblFilter;
    let spanLbl = document.getElementsByClassName(filterLbl);
    for (let i = 0; i < spanLbl.length; i++) {
      spanLbl[i].classList.add("active");
    }
  };

  const handleMouseFilterIcn = (lblFilter: any) => {
    const filterLbl = lblFilter;
    let spanLbl = document.getElementsByClassName(filterLbl);
    for (let i = 0; i < spanLbl.length; i++) {
      spanLbl[i].classList.remove("active");
    }
  };

  const FilterResult = (): JSX.Element => {
    if (selectedFilters.length === 0) {
      return <></>;
    }

    return (
      <div
        className="users"
        style={{ display: "flex", flexWrap: "wrap", paddingBottom: "20px" }}
      >
        {selectedFilters.map((item, index) => (
          <div
            className="filter-val"
            style={{
              background: "#F2F2F2",
              width: "auto",
              height: "54px",
              paddingLeft: "15px",
              paddingRight: "44px",
              paddingTop: "5px",
              paddingBottom: "5px",
              margin: "0 5px 5px 0",
              cursor: "pointer",
            }}
            onMouseEnter={() =>
              handleMouseLeaveFilterIcn("close-car" + " " + index)
            }
            onMouseLeave={() => handleMouseFilterIcn("close-car" + " " + index)}
            onClick={(e) => filterCheckedRemove(item)}
          >
            <div className="user">
              <span
                style={{
                  display: "block",
                  color: "#626669",
                  position: "relative",
                  top: "2px",
                }}
              >
                {t(item.split("^")[0])}
              </span>
              <span style={{ fontSize: "15px" }}>
                {item.split("^")[1] === "InReview"
                  ? t('In Review')
                  : t(item.split("^")[1]) }
              </span>
            </div>
            <div
              className="close-car-container"
              id="filterLabl"
              style={{
                position: "relative",
                cursor: "pointer",
                display: "flex",
                justifyContent: "end",
                left: "33px",
              }}
            >
              <span
                className={"close-car" + " " + index}
                onMouseEnter={() =>
                  handleMouseLeaveFilterIcn("close-car" + " " + index)
                }
                onClick={(e) => filterCheckedRemove(item)}
              >
                +
              </span>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const handleOrder = (ordersHistory : any) => {
    if(ordersHistory?.isManualCommission){
      if (ordersHistory?.id) {
        const singOrderDetails:SingleOrderDetailType = { id: ordersHistory?.id };
        const newWindow = window.open(`/#/commission/manualcommission`, "_blank");
        newWindow?.sessionStorage.setItem(Constant.SingleOrdDetail, JSON.stringify(singOrderDetails));
      }
    }else{
      if (ordersHistory.id) {
        const singOrderDetails:SingleOrderDetailType = { id: ordersHistory.id, mode:'view', draft: false };
        const newWindow=  window.open(`/#/order/create`, "_blank") 
        newWindow?.sessionStorage.setItem(Constant.SingleOrdDetail, JSON.stringify(singOrderDetails));

      }
    }
  };

  const dispString = (str: string) => {
    let disStr = str;
    if (str.length > 15) {
      const strArray = str.substring(0, 15);
      disStr = str.substring(0, 15);
    }
    return disStr;
  };

  return (
    <>
      <div className="pt10" style={{ display: "flex", marginBottom: "16px" }}>
        <PFlex>
          <PFlexItem>
            <PTextFieldWrapper
              label={`${t("search")}`}
              hideLabel={true}
              style={{ width: "440px" }}
            >
              <LightTooltip
                placement="top"
                title={
                  <h1
                    style={{
                      color: "black",
                      fontSize: "15px",
                      fontWeight: "400",
                      textAlign: "left",
                      padding: "5px",
                      borderRadius: 0,
                      textTransform: "none",
                    }}
                  >
                    {t("Search for")} <b>{t("customer")}</b> {t("(name / company)")},<b>{t("commission number")}</b> {t("or")} <b>{t("act number.")}</b>
                  </h1>
                }
              >
                <input
                  type="search"
                  name="search"
                  placeholder={`${t("Search")}`}
                  onChange={(e) => handleSearch(e.target.value)}
                  //onKeyDown={(e) => handleKeyDown(e)}
                  value={searchValue}
                  autoComplete="off"
                  //disabled={disabled}
                />
              </LightTooltip>
            </PTextFieldWrapper>

            <SearchResult />
          </PFlexItem>
        </PFlex>
        {/* toggle */}
        <PSwitch
          checked={show}
          alignLabel="right"
          style={{ paddingTop: "12px", marginLeft: "25px" }}
          onClick={handleClickToggle}
          //className={disabledClassName}
          disabled={disabled}
        >
          {/* {toggleName} */}
          {t("Show filters")}
        </PSwitch>
      </div>

      {show ? (
        <div style={{ marginBottom: "5px" }}>
          <PGrid direction="row" className="example-grid pt15">
            <PGridItem size={2}>
              <div className="multiselect"  ref={myRef}>
                <div className="selectBox" onClick={handleClickInsidePorsche}>
                  <PSelectWrapper
                    dropdownDirection="down"
                    id="porscheCenterWrapNewCar"
                    label={`${t("Porsche Center")}`}
                    hideLabel={false}
                  >
                    <select>
                      <option hidden />
                    </select>
                    <div className="overSelect"></div>
                  </PSelectWrapper>
                </div>
                <div
                  id="checkboxes-history"
                  className="checkbox-section"
                  style={{ display: clickedOutsidePorsche ? "block" : "none" }}
                >
                  <ul
                    style={{
                      overflow: "hidden",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    {Array.from(
                      new Set(ordersHistory?.map((obj) => obj.origin))
                    ).map((period) => {
                      return (
                        <>
                          <li className="checkbox-wrapper">
                            <PCheckboxWrapper label={`${t(period)}`} hideLabel={false}>
                              <input
                                type="checkbox"
                                name={"Porsche Center^" + period}
                                onClick={(e) =>
                                  filterChecked("Porsche Center^" + period)
                                }
                              />
                            </PCheckboxWrapper>
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </PGridItem>

            <PGridItem size={2}>
              <div className="multiselect" ref={myRefCustType}>
                <div className="selectBox" onClick={handleClickInsideCustTyp}>
                  <PSelectWrapper
                    dropdownDirection="down"
                    id="porscheCenterWrapNewCar"
                    label={`${t("Vehicle Type")}`}
                    hideLabel={false}
                  >
                    <select>
                      <option hidden />
                    </select>
                    <div className="overSelect"></div>
                  </PSelectWrapper>
                </div>
                <div
                  id="checkboxes-history"
                  className="checkbox-section"
                  
                  style={{ display: clickedOutsideCustType ? "block" : "none" }}
                >
                  <ul
                    style={{
                      overflow: "hidden",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    {Array.from(
                      new Set(ordersHistory?.map((obj) => obj.vehicleCategory))
                    ).map((period) => {
                      return (
                        <>
                          <li className="checkbox-wrapper">
                            <PCheckboxWrapper
                              label={`${t(period)}`}
                              hideLabel={false}
                              style={{ color: "red !important" }}
                            >
                              <input
                                type="checkbox"
                                name={"Vehicle Type^" + period}
                                onClick={(e) =>
                                  filterChecked("Vehicle Type^" + period)
                                }
                              />
                            </PCheckboxWrapper>
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </PGridItem>

            <PGridItem size={2}>
              <div className="multiselect" ref={myRefPlanCd}>
                <div className="selectBox" onClick={handleClickInsidePlanDate}>
                  <PSelectWrapper
                    dropdownDirection="down"
                    id="PlannedCDWrapNewCar"
                    label={`${t("Planned CD")}`}
                    hideLabel={false}
                  >
                    <select>
                      <option hidden />
                    </select>
                    <div className="overSelect"></div>
                  </PSelectWrapper>
                </div>
                <div
                  id="checkboxes-history"
                  className="checkbox-section"
                  
                  style={{
                    display: clickedOutsidePlannedCd ? "block" : "none",
                  }}
                >
                  <PGridItem size={3} style={{ marginTop: "-1px" }}>
                    <PTextFieldWrapper
                      id="dateOfPlan"
                      label={`${t("From")}`}
                      style={{ padding: "5px", marginTop: "-20px" }}
                    >
                      <input
                        id="startDatePlan"
                        type="date"
                        name="startDatePlan"
                        max="9999-12-31"
                        value={fromDatePlanCd}
                        onChange={handleFromDateChangePlanCd}
                      />
                    </PTextFieldWrapper>

                    <PTextFieldWrapper
                      id="dateOfOrderWrap"
                      label={`${t("To")}`}
                      style={{ padding: "5px" }}
                    >
                      <input
                        id="endDatePlan"
                        type="date"
                        name="endDate"
                        max="9999-12-31"
                        value={toDatePlanCd}
                        onChange={handleToDateChangePlanCd}
                      />
                    </PTextFieldWrapper>

                    <div
                      style={{
                        padding: "5px",
                        float: "right",
                        marginBottom: "5px",
                        marginTop: "5px",
                      }}
                    >
                      <PButton
                        onClick={() => calculateDateRange("PlanCd")}
                        disabled={disabledPlanDate}
                      >
                        {t("Apply")}
                      </PButton>
                    </div>
                  </PGridItem>
                </div>
              </div>
            </PGridItem>

            <PGridItem size={2}>
              <div className="multiselect" ref={myRefActualCd}>
                <div className="selectBox" onClick={handleClickInsideActualCd}>
                  <PSelectWrapper
                    dropdownDirection="down"
                    id="ActualCDWrapNewCar"
                    label={`${t("Actual CD")}`}
                    hideLabel={false}
                  >
                    <select>
                      <option hidden />
                    </select>
                    <div className="overSelect"></div>
                  </PSelectWrapper>
                </div>
                <div
                  id="checkboxes-history"
                  className="checkbox-section"
                  
                  style={{ display: clickedOutsideActualCd ? "block" : "none" }}
                >
                  <PGridItem size={3} style={{ marginTop: "-1px" }}>
                    <PTextFieldWrapper
                      id="dateOfOrderWrap"
                      label={`${t("From")}`}
                      style={{ padding: "5px", marginTop: "-20px" }}
                    >
                      <input
                        id="startDatePlan"
                        type="date"
                        name="startDatePlan"
                        max="9999-12-31"
                        value={fromDateActCd}
                        onChange={handleFromDateChangeActCd}
                      />
                    </PTextFieldWrapper>

                    <PTextFieldWrapper
                      id="dateOfOrderWrap"
                      label={`${t("To")}`}
                      style={{ padding: "5px" }}
                    >
                      <input
                        id="endDatePlan"
                        type="date"
                        name="endDate"
                        max="9999-12-31"
                        value={toDateActCd}
                        onChange={handleToDateChangeActCd}
                      />
                    </PTextFieldWrapper>

                    <div
                      style={{
                        padding: "5px",
                        float: "right",
                        marginBottom: "5px",
                        marginTop: "5px",
                      }}
                    >
                      <PButton
                        onClick={() => calculateDateRange("ActualCd")}
                        disabled={disabledActDate}
                      >
                        {t("Apply")}
                      </PButton>
                    </div>
                  </PGridItem>
                </div>
              </div>
            </PGridItem>

            <PGridItem size={2}>
              <div className="multiselect" ref={myRefSeries}>
                <div className="selectBox" onClick={handleClickInsideSeries}>
                  <PSelectWrapper
                    dropdownDirection="down"
                    id="porscheCenterWrapNewCar"
                    label={`${t("Series")}`}
                    hideLabel={false}
                  >
                    <select>
                      <option hidden />
                    </select>
                    <div className="overSelect"></div>
                  </PSelectWrapper>
                </div>
                <div
                  id="checkboxes-history"
                  className="checkbox-section"
                  
                  style={{ display: clickedOutsideSeries ? "block" : "none" }}
                >
                  <ul
                    style={{
                      overflow: "hidden",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    {seriesDropdown.map((period) => {
                      return (
                        <>
                          <li className="checkbox-wrapper">
                            <PCheckboxWrapper
                              label={period}
                              hideLabel={false}
                              style={{ color: "red !important" }}
                            >
                              <input
                                type="checkbox"
                                name={"Series^" + period}
                                onClick={(e) =>
                                  filterChecked("Series^" + period)
                                }
                              />
                            </PCheckboxWrapper>
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </PGridItem>

            <PGridItem size={2}>
              <div className="multiselect" ref={myRefCustPayment}>
                <div className="selectBox" onClick={handleClickInsidePaymnt}>
                  <PSelectWrapper
                    dropdownDirection="down"
                    id="porscheCenterWrapNewCar"
                    label={`${t("Payment")}`}
                    hideLabel={false}
                  >
                    <select>
                      <option hidden />
                    </select>
                    <div className="overSelect"></div>
                  </PSelectWrapper>
                </div>
                <div
                  id="checkboxes-history"
                  className="checkbox-section"
                  
                  style={{ display: clickedOutsidePayment ? "block" : "none" }}
                >
                  <ul
                    style={{
                      overflow: "hidden",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    {Array.from(
                      new Set(ordersHistory?.map((obj) => obj.payment))
                    ).map((period) => {
                      return (
                        <>
                          <li className="checkbox-wrapper">
                            <PCheckboxWrapper
                              label={`${t(period)}`}
                              hideLabel={false}
                              style={{ color: "red !important" }}
                            >
                              <input
                                type="checkbox"
                                name={"Payment^" + period}
                                onClick={(e) =>
                                  filterChecked("Payment^" + period)
                                }
                              />
                            </PCheckboxWrapper>
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </PGridItem>
          </PGrid>

          <PGrid
            direction="row"
            className="example-grid pt15"
            style={{ paddingBottom: "30px" }}
          >
            <PGridItem size={2}>
              <div className="multiselect" ref={myRefCustPfsExtra}>
                <div className="selectBox" onClick={handleClickInsidePfsExtra}>
                  <PSelectWrapper
                    dropdownDirection="down"
                    id="porscheCenterWrapNewCar"
                    label={`${t("PFS Extras")}`}
                    hideLabel={false}
                  >
                    <select>
                      <option hidden />
                    </select>
                    <div className="overSelect"></div>
                  </PSelectWrapper>
                </div>
                <div
                  id="checkboxes-history"
                  className="checkbox-section"
                  
                  style={{ display: clickedOutsidePfsExtra ? "block" : "none" }}
                >
                  <ul
                    style={{
                      overflow: "hidden",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    {pfsDropdown.map((period) => {
                      return (
                        <>
                          <li className="checkbox-wrapper">
                            <PCheckboxWrapper
                              label={`${t(period)}`}
                              hideLabel={false}
                              style={{ color: "red !important" }}
                            >
                              <input
                                type="checkbox"
                                name={"PFS Extras^" + period}
                                onClick={(e) =>
                                  filterChecked("PFS Extras^" + period)
                                }
                              />
                            </PCheckboxWrapper>
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </PGridItem>

            <PGridItem size={2}>
              <div className="multiselect"  ref={myRefCustPfsExport}>
                <div className="selectBox" onClick={handleClickInsideExport}>
                  <PSelectWrapper
                    dropdownDirection="down"
                    id="porscheCenterWrapNewCar"
                    label={`${t("Export")}`}
                    hideLabel={false}
                  >
                    <select>
                      <option hidden />
                    </select>
                    <div className="overSelect"></div>
                  </PSelectWrapper>
                </div>
                <div
                  id="checkboxes-history"
                  className="checkbox-section"
                 
                  style={{ display: clickedOutsideExport ? "block" : "none" }}
                >
                  <ul
                    style={{
                      overflow: "hidden",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    {Array.from(
                      new Set(ordersHistory?.map((obj) => obj.exportRegion))
                    ).map((period) => {
                      return (
                        <>
                          <li className="checkbox-wrapper">
                            <PCheckboxWrapper
                              label={`${t(period)}`}
                              hideLabel={false}
                              style={{ color: "red !important" }}
                            >
                              <input
                                type="checkbox"
                                name={"Export^" + period}
                                onClick={(e) =>
                                  filterChecked("Export^" + period)
                                }
                              />
                            </PCheckboxWrapper>
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </PGridItem>

            <PGridItem size={2}>
              <div className="multiselect" ref={myRefCustPfsStaus}>
                <div className="selectBox" onClick={handleClickInsidePfsStatus}>
                  <PSelectWrapper
                    dropdownDirection="down"
                    id="porscheCenterWrapNewCar"
                    label={`${t("Seller")}`}
                    hideLabel={false}
                  >
                    <select>
                      <option hidden />
                    </select>
                    <div className="overSelect"></div>
                  </PSelectWrapper>
                </div>
                <div
                  id="checkboxes-history"
                  className="checkbox-section"
                  
                  style={{ display: clickedOutsideStatus ? "block" : "none" }}
                >
                  <ul
                    style={{
                      overflow: "hidden",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    {Array.from(
                      new Set(ordersHistory?.map((obj) => obj.seller))
                    ).map((period) => {
                      return (
                        <>
                          <li className="checkbox-wrapper">
                            <PCheckboxWrapper
                              label={period}
                              hideLabel={false}
                              style={{ color: "red !important" }}
                            >
                              <input
                                type="checkbox"
                                name={"Seller^" + period}
                                onClick={(e) =>
                                  filterChecked("Seller^" + period)
                                }
                              />
                            </PCheckboxWrapper>
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </PGridItem>

            {/* First approval and second appsoval */}
            <PGridItem size={2}>
              <div className="multiselect" ref={myRefFirstApproval}>
                <div
                  className="selectBox"
                  onClick={handleClickInsideFirstApproval}
                >
                  <PSelectWrapper
                    dropdownDirection="down"
                    id="porscheCenterWrapNewCar"
                    label={`${t("1st Approval")}`}
                    hideLabel={false}
                  >
                    <select>
                      <option hidden />
                    </select>
                    <div className="overSelect"></div>
                  </PSelectWrapper>
                </div>
                <div
                  id="checkboxes-history"
                  className="checkbox-section"
                  
                  style={{
                    display: clickedOutsideFirstApproval ? "block" : "none",
                  }}
                >
                  <ul
                    style={{
                      overflow: "hidden",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    {Array.from(
                      new Set(ordersHistory?.map((obj) => obj.firstApproval))
                    ).map((period) => {
                      return (
                        <>
                          <li className="checkbox-wrapper">
                            <PCheckboxWrapper
                              label={period}
                              hideLabel={false}
                              style={{ color: "red !important" }}
                            >
                              <input
                                type="checkbox"
                                name={"First Approval^" + period}
                                onClick={(e) =>
                                  filterChecked("First Approval^" + period)
                                }
                              />
                            </PCheckboxWrapper>
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </PGridItem>

            <PGridItem size={2}>
              <div className="multiselect" ref={myRefSecondApproval}>
                <div
                  className="selectBox"
                  onClick={handleClickInsideSecondApproval}
                >
                  <PSelectWrapper
                    dropdownDirection="down"
                    id="porscheCenterWrapNewCar"
                    label={`${t("2nd Approval")}`}
                    hideLabel={false}
                  >
                    <select>
                      <option hidden />
                    </select>
                    <div className="overSelect"></div>
                  </PSelectWrapper>
                </div>
                <div
                  id="checkboxes-history"
                  className="checkbox-section"
                  
                  style={{
                    display: clickedOutsideSecondApproval ? "block" : "none",
                  }}
                >
                  <ul
                    style={{
                      overflow: "hidden",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    {Array.from(
                      new Set(ordersHistory?.map((obj) => obj.secondApproval))
                    ).map((period) => {
                      return (
                        <>
                          <li className="checkbox-wrapper">
                            <PCheckboxWrapper
                              label={period}
                              hideLabel={false}
                              style={{ color: "red !important" }}
                            >
                              <input
                                type="checkbox"
                                name={"Second Approval^" + period}
                                onClick={(e) =>
                                  filterChecked("Second Approval^" + period)
                                }
                              />
                            </PCheckboxWrapper>
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </PGridItem>

            <PGridItem size={2}>
              <div className="multiselect" ref={myRefMonthCd}>
                <div className="selectBox" onClick={handleClickInsideMonthCd}>
                  <PSelectWrapper
                    dropdownDirection="down"
                    id="ActualWrapNewCar"
                    label={`${t("Month of Accounting")}`}
                    hideLabel={false}
                  >
                    <select>
                      <option hidden />
                    </select>
                    <div className="overSelect"></div>
                  </PSelectWrapper>
                </div>
                <div
                  id="checkboxes-history"
                  className="checkbox-section"
                  
                  style={{ display: clickedOutsideMonthCd ? "block" : "none" }}
                >
                  <PGridItem size={3} style={{ marginTop: "-1px" }}>
                    <PTextFieldWrapper
                      id="dateOfOrderWrap"
                      label={`${t("From")}`}
                      style={{ padding: "5px", marginTop: "-20px" }}
                    >
                      <input
                        id="startDatePlan"
                        type="month"
                        name="startDatePlan"
                        max="9999-12-31"
                        value={fromDateMonthCd}
                        onChange={handleFromDateChangeMonthCd}
                      />
                    </PTextFieldWrapper>

                    <PTextFieldWrapper
                      id="dateOfOrderWrap"
                      label={`${t("To")}`}
                      style={{ padding: "5px" }}
                    >
                      <input
                        id="endDatePlan"
                        type="month"
                        name="endDate"
                        max="9999-12-31"
                        value={toDateMonthCd}
                        onChange={handleToDateChangeMonthCd}
                      />
                    </PTextFieldWrapper>

                    <div
                      style={{
                        padding: "5px",
                        float: "right",
                        marginBottom: "5px",
                        marginTop: "5px",
                      }}
                    >
                      <PButton
                        onClick={() => calculateDateRange("MonthCd")}
                        disabled={disabledMonthDate}
                      >
                        {t("Apply")}
                      </PButton>
                    </div>
                  </PGridItem>
                </div>
              </div>
            </PGridItem>
          </PGrid>
          <FilterResult />
        </div>
      ) : null}

      <div>
        <TableContainer
          sx={
            window.innerHeight > 1000
              ? { maxHeight: "780px" }
              : { maxHeight: "360px" }
          }
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell className="cell-align-demo">
                  <PFlex direction={"column"}>
                    <PFlexItem className="inreview-text-top">
                      <PorscheFont>{t("Vehicle type")}</PorscheFont>
                    </PFlexItem>
                    <PFlexItem className="inreview-text-bottom">
                      <PorscheFont>{t("Model")}</PorscheFont>
                    </PFlexItem>
                  </PFlex>
                </TableCell>

                <TableCell className="cell-align-demo">
                  <PFlex direction={"column"}>
                    <PFlexItem className="inreview-text-top">
                      <PorscheFont>{t("Comm No.")}</PorscheFont>
                    </PFlexItem>
                    <PFlexItem className="inreview-text-bottom">
                      <PorscheFont>{t("Act No.")}</PorscheFont>
                    </PFlexItem>
                  </PFlex>
                </TableCell>

                <TableCell className="cell-align-demo">
                  <PFlex direction={"column"}>
                    <PFlexItem
                      className="inreview-text-top"
                      onClick={() => sorting("PlannedCd")}
                      style={{ cursor: "pointer" }}
                    >
                      <PorscheFont>
                        <span
                          className="rrp-col"
                          onMouseEnter={() => handleMouseEnter("PlannedCd")}
                          onMouseLeave={() => handleMouseLeave("PlannedCd")}
                          style={{
                            color: isHoveringOrDate
                              ? "#d5001b"
                              : isEnteringOrDate
                              ? "#d5001b"
                              : "",
                          }}
                        >
                          {t("Planned CD")}
                        </span>{" "}
                        {OrDateUp ? (
                          <span style={{ position: "absolute" }}>
                            <span>
                              <PIcon name={"arrow-up"} />
                            </span>
                          </span>
                        ) : (
                          ""
                        )}{" "}
                        {OrDate ? (
                          <span style={{ position: "absolute", top: "5px" }}>
                            <PIcon name={"arrow-down"} />
                          </span>
                        ) : (
                          ""
                        )}
                      </PorscheFont>
                    </PFlexItem>
                    <PFlexItem
                      className="inreview-text-bottom"
                      onClick={() => sorting("actualCd")}
                      style={{ cursor: "pointer" }}
                    >
                      <PorscheFont>
                        <span
                          className="rrp-col"
                          onMouseEnter={() => handleMouseEnter("actualCd")}
                          onMouseLeave={() => handleMouseLeave("actualCd")}
                          style={{
                            color: isHoveringPlanCd
                              ? "#d5001b"
                              : isEnteringPlan
                              ? "#d5001b"
                              : "",
                          }}
                        >
                          {t("Actual CD")}
                        </span>
                        {actualCdDateUp ? (
                          <span style={{ position: "absolute" }}>
                            <span>
                              <PIcon name={"arrow-up"} />
                            </span>
                          </span>
                        ) : (
                          ""
                        )}{" "}
                        {actualCdDate ? (
                          <span style={{ position: "absolute" }}>
                            <PIcon name={"arrow-down"} />
                          </span>
                        ) : (
                          ""
                        )}
                      </PorscheFont>
                    </PFlexItem>
                  </PFlex>
                </TableCell>

                <TableCell className="cell-align-demo">
                  <PFlex direction={"column"}>
                    <PFlexItem className="inreview-text-top">
                      <PorscheFont>{t("Customer")}</PorscheFont>
                    </PFlexItem>
                    <PFlexItem className="inreview-text-bottom">
                      <PorscheFont>{t("C@P No.")}</PorscheFont>
                    </PFlexItem>
                  </PFlex>
                </TableCell>

                <TableCell className="cell-align-demo">
                  <PFlex direction={"column"}>
                    <PFlexItem
                      className="inreview-text-top"
                      onClick={() => sorting("payment")}
                      style={{ cursor: "pointer" }}
                    >
                      <PorscheFont>
                        <span
                          onMouseEnter={() => handleMouseEnter("payment")}
                          onMouseLeave={() => handleMouseLeave("payment")}
                          style={{
                            color: isHoveringPymnt
                              ? "#d5001b"
                              : isEnteringPay
                              ? "#d5001b"
                              : "",
                          }}
                        >
                          {t("Payment")}
                        </span>
                        {paymentOrder ? (
                          <span
                            style={{ position: "absolute", marginLeft: "5px" }}
                          >
                            <PIcon name={"arrow-down"} />
                          </span>
                        ) : (
                          ""
                        )}{" "}
                        {paymentOrderUp ? (
                          <span
                            style={{ position: "absolute", marginLeft: "5px" }}
                          >
                            <PIcon name={"arrow-up"} />
                          </span>
                        ) : (
                          ""
                        )}
                      </PorscheFont>
                    </PFlexItem>
                    <PFlexItem className="inreview-text-bottom">
                      <PorscheFont>{t("PFS Extras")}</PorscheFont>
                    </PFlexItem>
                  </PFlex>
                </TableCell>

                <TableCell className="cell-align-demo">
                  <PFlex direction={"column"}>
                    <PFlexItem className="inreview-text-top">
                      <PorscheFont>{t("Export")}</PorscheFont>
                    </PFlexItem>
                    <PFlexItem className="inreview-text-top">
                      <PorscheFont>{t("Region")}</PorscheFont>
                    </PFlexItem>
                  </PFlex>
                </TableCell>

                <TableCell className="cell-align-demo">
                  <PFlex direction={"column"}>
                    <PFlexItem
                      className="inreview-text-top"
                      onClick={() => sorting("seller")}
                      style={{ cursor: "pointer" }}
                    >
                      <PorscheFont>
                        <span
                          onMouseEnter={() => handleMouseEnter("seller")}
                          onMouseLeave={() => handleMouseLeave("seller")}
                          style={{
                            color: isHoveringSellr
                              ? "#d5001b"
                              : isEnteringSeller
                              ? "#d5001b"
                              : "",
                          }}
                        >
                          {t("Seller")}
                        </span>
                        {sellerOrder ? (
                          <span
                            style={{ position: "absolute", marginLeft: "5px" }}
                          >
                            <PIcon name={"arrow-down"} />
                          </span>
                        ) : (
                          ""
                        )}{" "}
                        {sellerOrderUp ? (
                          <span
                            style={{ position: "absolute", marginLeft: "5px" }}
                          >
                            <PIcon name={"arrow-up"} />
                          </span>
                        ) : (
                          ""
                        )}
                      </PorscheFont>
                    </PFlexItem>
                    <PFlexItem className="inreview-text-bottom">
                      <PorscheFont>{t("Origin")}</PorscheFont>
                    </PFlexItem>
                  </PFlex>
                </TableCell>

                <TableCell className="cell-align-demo">
                  <PFlex direction={"column"}>
                    <PFlexItem className="inreview-text-top">
                      <PorscheFont>{t("Commission")}</PorscheFont>
                    </PFlexItem>
                    <PFlexItem className="inreview-text-bottom">
                      <PorscheFont>{t("Recalculation")}</PorscheFont>
                    </PFlexItem>
                  </PFlex>
                </TableCell>

                <TableCell className="cell-align-demo">
                  <PFlex direction={"column"}>
                    <PFlexItem className="inreview-text-top">
                      <PorscheFont>{t("Incentive")}</PorscheFont>
                    </PFlexItem>
                    <PFlexItem className="inreview-text-bottom">
                      <PorscheFont>{t("Recalculation")}</PorscheFont>
                    </PFlexItem>
                  </PFlex>
                </TableCell>

                <TableCell className="cell-align-demo">
                  <PFlex direction={"column"}>
                    <PFlexItem className="inreview-text-top">
                      <PorscheFont>{t("Total")}</PorscheFont>
                    </PFlexItem>
                    <PFlexItem className="inreview-text-top">
                      <PorscheFont>{t("Commission")}</PorscheFont>
                    </PFlexItem>
                  </PFlex>
                </TableCell>

                <TableCell className="cell-align-demo">
                  <PFlex direction={"column"}>
                    <PFlexItem className="inreview-text-top">
                      <PorscheFont>{t("Recalculation")}</PorscheFont>
                    </PFlexItem>
                    <PFlexItem className="inreview-text-bottom">
                      <PorscheFont>{t("Date")}</PorscheFont>
                    </PFlexItem>
                  </PFlex>
                </TableCell>

                <TableCell className="cell-align-demo">
                  <PFlex direction={"column"}>
                    <PFlexItem
                      className="inreview-text-top"
                      onClick={() => sorting("firstApproval")}
                      style={{ cursor: "pointer" }}
                    >
                      <PorscheFont>
                        <span
                          onMouseEnter={() => handleMouseEnter("firstApproval")}
                          onMouseLeave={() => handleMouseLeave("firstApproval")}
                          style={{
                            color: isHoveringFirstApproval
                              ? "#d5001b"
                              : isEnteringFirstApproval
                              ? "#d5001b"
                              : "",
                          }}
                        >
                          {t("1st Approval")}
                        </span>
                        {firstApprovalOrder ? (
                          <span style={{ position: "absolute" }}>
                            <PIcon name={"arrow-down"} />
                          </span>
                        ) : (
                          ""
                        )}{" "}
                        {firstApprovalOrderUp ? (
                          <span style={{ position: "absolute" }}>
                            <PIcon name={"arrow-up"} />
                          </span>
                        ) : (
                          ""
                        )}
                      </PorscheFont>
                    </PFlexItem>
                    <PFlexItem className="inreview-text-bottom">
                      <PorscheFont>{t("Date")}</PorscheFont>
                    </PFlexItem>
                  </PFlex>
                </TableCell>
                <TableCell className="cell-align-demo">
                  <PFlex direction={"column"}>
                    <PFlexItem
                      className="inreview-text-top"
                      onClick={() => sorting("secondApproval")}
                      style={{ cursor: "pointer" }}
                    >
                      <PorscheFont>
                        <span
                          onMouseEnter={() =>
                            handleMouseEnter("secondApproval")
                          }
                          onMouseLeave={() =>
                            handleMouseLeave("secondApproval")
                          }
                          style={{
                            color: isHoveringSecondApproval
                              ? "#d5001b"
                              : isEnteringSecondApproval
                              ? "#d5001b"
                              : "",
                          }}
                        >
                          {t("2nd Approval")}
                        </span>
                        {secondApprovalOrder ? (
                          <span style={{ position: "absolute" }}>
                            <PIcon name={"arrow-down"} />
                          </span>
                        ) : (
                          ""
                        )}{" "}
                        {secondApprovalOrderUp ? (
                          <span style={{ position: "absolute" }}>
                            <PIcon name={"arrow-up"} />
                          </span>
                        ) : (
                          ""
                        )}
                      </PorscheFont>
                    </PFlexItem>
                    <PFlexItem className="inreview-text-bottom">
                      <PorscheFont>{t("Date")}</PorscheFont>
                    </PFlexItem>
                  </PFlex>
                </TableCell>
                <TableCell className="cell-align-demo">
                  <PFlex direction={"column"}>
                    <PFlexItem
                      className="inreview-text-top"
                      onClick={() => sorting("month")}
                      style={{ cursor: "pointer" }}
                    >
                      <PorscheFont>
                        <span
                          onMouseEnter={() => handleMouseEnter("month")}
                          onMouseLeave={() => handleMouseLeave("month")}
                          style={{
                            color: isHoveringMonth
                              ? "#d5001b"
                              : isEnteringMonth
                              ? "#d5001b"
                              : "",
                          }}
                        >
                          {t("Month of")}
                        </span>
                        {monthOrder ? (
                          <span style={{ position: "absolute" }}>
                            <PIcon name={"arrow-down"} />
                          </span>
                        ) : (
                          ""
                        )}{" "}
                        {monthOrderUp ? (
                          <span style={{ position: "absolute" }}>
                            <PIcon name={"arrow-up"} />
                          </span>
                        ) : (
                          ""
                        )}
                      </PorscheFont>
                    </PFlexItem>
                    <PFlexItem className="inreview-text-bottom">
                      <PorscheFont>{t("Accounting")}</PorscheFont>
                    </PFlexItem>
                  </PFlex>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredItems.length > 0 ? (
                filteredItems.map((ordersHistory, i) => {
                  let commissionDiff = ordersHistory.commissionRecalculation
                    ? parseFloat(ordersHistory.commissionRecalculation)
                    : 0.0;
                  let incentiveDiff = ordersHistory.incentiveRecalculation
                    ? parseFloat(ordersHistory.incentiveRecalculation)
                    : 0.0;

                  let moa =
                    ordersHistory.monthOfAccounting &&
                    ordersHistory.monthOfAccounting !== "-"
                      ? ordersHistory.monthOfAccounting.split(" ")
                      : [];
                  return (
                    <TableRow hover role="checkbox" tabIndex={-4}>
                      <TableCell className="cell-align-demo">
                        <PFlex
                          direction={"column"}
                          style={{ marginTop: "8px", marginBottom: "8px" }}
                        >
                          <PFlexItem className="inreview-text-top">
                            <PorscheFont>
                              {ordersHistory.vehicleCategory
                                ? t(ordersHistory.vehicleCategory)
                                : "-"}
                            </PorscheFont>
                          </PFlexItem>
                          <PFlexItem className="inreview-text-bottom" style={{ marginRight: ordersHistory.model && ordersHistory.model.length > 17 ? '15px' : '0' }}>
                              {ordersHistory.model !== undefined &&
                              ordersHistory.model.length > 0 ? (
                                  <PorscheFont>
                                    {dispString(ordersHistory.model)}
                                  </PorscheFont>
                              ) : (
                                "-"
                              )}
                          </PFlexItem>
                        </PFlex>
                      </TableCell>
                      <TableCell className="cell-align-demo">
                        <PFlex direction={"column"}>
                          <PFlexItem className="inreview-text-top">
                            <PorscheFont>
                              {ordersHistory.commissionNumber}
                            </PorscheFont>
                          </PFlexItem>
                          <PFlexItem className="inreview-text-bottom">
                            <PorscheFont>{ordersHistory.actNumber}</PorscheFont>
                          </PFlexItem>
                        </PFlex>
                      </TableCell>
                      <TableCell className="cell-align-demo">
                        <PFlex direction={"column"}>
                          <PFlexItem className="inreview-text-top">
                            <PorscheFont style={{ fontWeight: "400" }}>
                              {ordersHistory.plannedCD !== undefined &&
                              ordersHistory.plannedCD.length > 0
                                ? ordersHistory.plannedCD.replaceAll("-", ".")
                                : "-"}
                            </PorscheFont>
                          </PFlexItem>
                          <PFlexItem className="inreview-text-bottom">
                            <PorscheFont style={{ fontWeight: "400" }}>
                              {ordersHistory.actualCD !== undefined &&
                              ordersHistory.actualCD.length > 0
                                ? ordersHistory.actualCD.replaceAll("-", ".")
                                : "-"}
                            </PorscheFont>
                          </PFlexItem>
                        </PFlex>
                      </TableCell>
                      <TableCell className="cell-align-demo">
                        <PFlex direction={"column"}>
                          <PFlexItem className="inreview-text-top">
                            <PorscheFont>{ordersHistory.customer}</PorscheFont>
                          </PFlexItem>
                          <PFlexItem className="inreview-text-bottom">
                            <PorscheFont>{ordersHistory.copNumber}</PorscheFont>
                          </PFlexItem>
                        </PFlex>
                      </TableCell>
                      <TableCell className="cell-align-demo">
                        <PFlex direction={"column"}>
                          <PFlexItem className="inreview-text-top">
                            {ordersHistory.payment
                              ? t(ordersHistory.payment)
                              : "-"}
                          </PFlexItem>
                          <PFlexItem className="inreview-text-bottom">
                            <PorscheFont style={{ fontWeight: "400" }}>
                              {ordersHistory.pfsExtras}
                            </PorscheFont>
                          </PFlexItem>
                        </PFlex>
                      </TableCell>
                      <TableCell className="cell-align-demo">
                        <PFlex direction={"column"}>
                          <PFlexItem className="inreview-text-top">
                            <PorscheFont style={{ fontWeight: "400" }}>
                              {ordersHistory.exportRegion &&
                              ordersHistory.exportRegion !== "-"
                                ? getExportRegion(
                                    t(ordersHistory.exportRegion),
                                    0
                                  )
                                : "-"}
                            </PorscheFont>
                          </PFlexItem>
                          <PFlexItem
                            className="inreview-text-bottom"
                            style={{ fontWeight: "400" }}
                          >
                            {ordersHistory.exportRegion &&
                            ordersHistory.exportRegion !== "-"
                              ? getExportRegion(
                                  t(ordersHistory.exportRegion),
                                  1
                                )
                              : ""}
                          </PFlexItem>
                        </PFlex>
                      </TableCell>
                      <TableCell className="cell-align-demo">
                        <PFlex direction={"column"}>
                          <PFlexItem className="inreview-text-top">
                            <PorscheFont>{ordersHistory.seller}</PorscheFont>
                          </PFlexItem>
                          <PFlexItem className="inreview-text-bottom">
                            <PorscheFont>{ordersHistory.origin}</PorscheFont>
                          </PFlexItem>
                        </PFlex>
                      </TableCell>
                      <TableCell className="cell-align-demo">
                        <PFlex direction={"column"}>
                          <PFlexItem className="inreview-text-top">
                            <PorscheFont>
                              {ordersHistory.commission &&
                              ordersHistory.commission !== "-" &&
                              ordersHistory.commission !== ""
                                ? formatCurrencyWithNoUnit(
                                    parseFloat(ordersHistory.commission)
                                  ) + " €"
                                : ordersHistory.commission}
                            </PorscheFont>
                          </PFlexItem>
                          <PFlexItem className="inreview-text-top">
                            {ordersHistory.commissionRecalculation &&
                              (ordersHistory.commissionRecalculation === "-" ||
                                ordersHistory.commissionRecalculation ===
                                  "") && (
                                <>{ordersHistory.commissionRecalculation}</>
                              )}
                            {ordersHistory.commissionRecalculation &&
                              ordersHistory.commissionRecalculation !== "-" &&
                              ordersHistory.commissionRecalculation !== "" && (
                                <PorscheFont
                                  style={{
                                    color:
                                      commissionDiff < 0
                                        ? "#A90000"
                                        : "#00950F",
                                  }}
                                >
                                  {commissionDiff < 0 ? "" : "+"}
                                  {formatCurrencyWithNoUnit(commissionDiff)}
                                  {" €"}
                                </PorscheFont>
                              )}
                          </PFlexItem>
                        </PFlex>
                      </TableCell>
                      <TableCell className="cell-align-demo">
                        <PFlex direction={"column"}>
                          <PFlexItem className="inreview-text-top">
                            <PorscheFont style={{ fontWeight: "400" }}>
                              {ordersHistory.incentive &&
                              ordersHistory.incentive !== "-" &&
                              ordersHistory.incentive !== ""
                                ? formatCurrencyWithNoUnit(
                                    parseFloat(ordersHistory.incentive)
                                  ) + " €"
                                : ordersHistory.incentive}
                            </PorscheFont>
                          </PFlexItem>
                          <PFlexItem className="inreview-text-top">
                            {ordersHistory.incentive &&
                              (ordersHistory.incentive === "-" ||
                                ordersHistory.incentive === "") && (
                                <>{ordersHistory.incentive}</>
                              )}
                            {ordersHistory.incentive &&
                              ordersHistory.incentive !== "-" &&
                              ordersHistory.incentive !== "" && (
                                <PorscheFont
                                  style={{
                                    color:
                                      incentiveDiff < 0 ? "#A90000" : "#00950F",
                                  }}
                                >
                                  {incentiveDiff < 0 ? "" : "+"}
                                  {formatCurrencyWithNoUnit(incentiveDiff)}
                                  {" €"}
                                </PorscheFont>
                              )}
                          </PFlexItem>
                        </PFlex>
                      </TableCell>
                      <TableCell className="cell-align-demo">
                        <PFlex direction={"column"}>
                          <PFlexItem className="inreview-text-top">
                            <PorscheFont style={{ fontWeight: "400" }}>
                              {ordersHistory.totalCommission &&
                              ordersHistory.totalCommission !== "-" &&
                              ordersHistory.totalCommission !== ""
                                ? formatCurrencyWithNoUnit(
                                    parseFloat(ordersHistory.totalCommission)
                                  ) + " €"
                                : ordersHistory.totalCommission}
                            </PorscheFont>
                          </PFlexItem>
                        </PFlex>
                      </TableCell>
                      <TableCell className="cell-align-demo">
                        <PFlex direction={"column"}>
                          <PFlexItem className="inreview-text-top">
                            <PorscheFont>
                              {ordersHistory.recalculation
                                ? ordersHistory.recalculation
                                : "-"}
                            </PorscheFont>
                          </PFlexItem>
                          <PFlexItem className="inreview-text-bottom">
                            <PorscheFont>
                              {ordersHistory.recalculationDate &&
                              ordersHistory.recalculationDate !== "-"
                                ? ordersHistory.recalculationDate.replaceAll(
                                    "-",
                                    "."
                                  )
                                : "-"}
                            </PorscheFont>
                          </PFlexItem>
                        </PFlex>
                      </TableCell>
                      <TableCell className="cell-align-demo">
                        <PFlex direction={"column"}>
                          <PFlexItem className="inreview-text-top">
                            <PorscheFont>
                              {ordersHistory.firstApproval
                                ? ordersHistory.firstApproval
                                : "-"}
                            </PorscheFont>
                          </PFlexItem>
                          <PFlexItem className="inreview-text-bottom">
                            <PorscheFont>
                              {ordersHistory.firstApprovalDate &&
                              ordersHistory.firstApprovalDate !== "-"
                                ? ordersHistory.firstApprovalDate.replaceAll(
                                    "-",
                                    "."
                                  )
                                : "-"}
                            </PorscheFont>
                          </PFlexItem>
                        </PFlex>
                      </TableCell>
                      <TableCell className="cell-align-demo">
                        <PFlex direction={"column"}>
                          <PFlexItem className="inreview-text-top">
                            <PorscheFont>
                              {ordersHistory.secondApproval &&
                              ordersHistory.secondApproval.length > 0
                                ? ordersHistory.secondApproval
                                : ""}
                            </PorscheFont>
                          </PFlexItem>
                          <PFlexItem className="inreview-text-bottom">
                            <PorscheFont>
                              {ordersHistory.secondApprovalDate &&
                              ordersHistory.secondApprovalDate !== "-"
                                ? ordersHistory.secondApprovalDate.replaceAll(
                                    "-",
                                    "."
                                  )
                                : ordersHistory.secondApproval &&
                                  ordersHistory.secondApproval.length > 0
                                ? "-"
                                : ""}
                            </PorscheFont>
                          </PFlexItem>
                        </PFlex>
                      </TableCell>
                      <TableCell className="cell-align-demo">
                        <PFlex direction={"column"}>
                          <PFlexItem className="inreview-text-top">
                            <PorscheFont>
                              {moa.length > 0 ? t(moa[0]) : ""}
                            </PorscheFont>
                          </PFlexItem>
                          <PFlexItem className="inreview-text-bottom">
                            <PorscheFont>
                              {moa.length > 1 ? moa[1] : ""}
                            </PorscheFont>
                          </PFlexItem>
                        </PFlex>
                      </TableCell>
                      <TableCell className="cell-align-demo">
                        <PFlex direction={"row"}>
                        <PFlexItem style={{ minWidth: "30px"}} className="manual-icon">
                            {ordersHistory?.isManualCommission ? (<>
                            <div 
                              className="icon-container"
                              onMouseOver={()=>setIsPopoverVisible(i)}
                              onMouseLeave={()=>setIsPopoverVisible(null)}
                              >
                              <ManualCommissionIcon />
                              </div>
                              {isPopoverVisible === i ? (
                                  <PorscheFont className="custom-popover">
                                  {t("Manual commission")}
                                  </PorscheFont>
                              ):null}
                            </>) : null}

                            {ordersHistory?.isSubsequentChange ? (<>
                            <div 
                              className="icon-container"
                              onMouseOver={()=>setIsPopoverVisible(i)}
                              onMouseLeave={()=>setIsPopoverVisible(null)}
                              >
                              <SubsequentIcon />
                              </div>
                              {isPopoverVisible === i ? (
                                  <PorscheFont className="custom-popover">
                                    {t("Subsequent Change")}
                                  </PorscheFont>
                              ):null}
                            </>) : null}
                          </PFlexItem>
                          <PFlexItem>
                            <PButtonPure
                              hideLabel={true}
                              onClick={() => handleOrder(ordersHistory)}
                            ></PButtonPure>
                          </PFlexItem>
                        </PFlex>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <PText
                  size="small"
                  style={{ whiteSpace: "nowrap", marginTop: "20px" }}
                >
                   {t("No matching results.")}
                </PText>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};
