import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Alert, Snackbar } from '@mui/material';


import { PorscheFont } from '../../../assets/styles/CustomStyles';
import { UserDetails } from '../../../interfaces/UserDetails';
import { type } from '../../../services/Constants';
import { dcsgetInActiveUsers } from '../../../services/data/Helpers';
import ScrollToTop from '../../common/ScrollToTop';
import { InActiveUserRow } from './InActiveUserRow';

export const InactiveUsers= (): JSX.Element => {
  const [inActiveUsers, setInActiveUsers, ] = useState<Array<UserDetails>>([]);
  const [reload, setReload] = useState("");
  const [saveSuccess, setSaveSuccess] = useState(false);
  const { t } = useTranslation();
  const [saveSuccessMsg, setSaveSuccessMsg] = useState("");

  useEffect(() => {
    const init = async () => {
      const userDetails = sessionStorage.getItem("userDetails");
      const apiResponse = await dcsgetInActiveUsers()
      if(apiResponse.status===type.SUCCESS){
        setInActiveUsers(apiResponse.response);
      }


    };
    init();
  }, []);

  useEffect(() => {
    const init = async () => {
      const userDetails = sessionStorage.getItem("userDetails");
     

      const apiResponse = await dcsgetInActiveUsers()
      if(apiResponse.status===type.SUCCESS){
        setInActiveUsers(apiResponse.response);
      }
    };
    init();
  }, [reload]);

  const handleSuccessClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return false;
    }
    setSaveSuccess(false);
  };
  
  const refreshData = () => {
    const initRefresh = async () => {
      const apiResponse = await dcsgetInActiveUsers()
      if(apiResponse.status===type.SUCCESS){
        setInActiveUsers(apiResponse.response);
        window.location.reload();
      }
    };
    initRefresh();
  };

  const showMessage = (variant: string, msg: string) => {
    if(variant === "success") {
      setSaveSuccess(true);
      setSaveSuccessMsg(msg)
    }
  }
  return(
    <>
    <div className="container-variable" style={{paddingTop: "30px"}}>
      <ScrollToTop />
      <PorscheFont className="dcs-section-heading">{t("Inactive Users")}</PorscheFont>
    </div>
    
    <div className="container-variable">
    {
      inActiveUsers.map((user, i) => {
        return (
          <InActiveUserRow  user={user} refreshData={refreshData} showMessage={(v, m) => showMessage(v, m)}/>  
        );
      })
    }
    {inActiveUsers.length === 0 && (
      <PorscheFont className="tn-16-600">{t("No inactive users.")}</PorscheFont>
    )
    }
    </div>
    <div className="flex30 pt20">
      <Snackbar
        open={saveSuccess}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={2000}
        onClose={handleSuccessClose}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          {t(saveSuccessMsg)}
        </Alert>
      </Snackbar>
    </div>
  </>
  );
};
