import { ChangeEvent, Ref, useState } from 'react';

import { PTextFieldWrapper } from '@porsche-design-system/components-react';

import { UpsaleError } from '../../interfaces/Upsale';

interface Props {
    id: string;
    name: string;
    label: string;
    hideLabel?: boolean | false;
    type: string;
    disabled?: boolean | false;
    placeHolder?: string | "";
    value?:string | "";
    required?: boolean | false;
    // errors?: {
    //   [key: string]: {
    //     message: string;
    //   };
    // } | null;
    errors?: Array<UpsaleError>;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (event: any) => void;
    onClick?: (event: any) => void;
    forwardRef?: Ref<HTMLInputElement>;
    defaultValue?: string | "";
    maxLength?:any;
}

export const CustomTextField = (props: Props): JSX.Element => {
  const {
    id,
    name,
    label,
    hideLabel,
    type,
    disabled,
    placeHolder,
    value,
    required,
    errors,
    onChange,
    onClick,
    onBlur,
    forwardRef,
    defaultValue,
    maxLength,
  } = props;

  const [ error, setError ] = useState(false);
  const [ errorMsg, setErrorMsg ] = useState("");


  // useEffect(() => {
  //   if (errors && errors.length > 0) {
  //     const err = errors.filter((x) => x.field === name);
  //     if (err.length > 0) {
  //       setError(true);
  //       setErrorMsg(err[0].message);
  //     }
  //   }
  // }, [])

  let err: Array<UpsaleError> = [];
  if (errors) {
    err = errors.filter((x) => x.field === name);
  }

  return (
    <PTextFieldWrapper
      id={id+"Wrap"}
      label={label}
      hideLabel={hideLabel ? true : false}
      state={err.length > 0 ? "error" : "none"}
      message={err.length > 0 ? err[0].message : ""}
      className="pt24"
    >
      <input
        aria-autocomplete='none'
        autoComplete="off"
        id={id}
        name={name}
        type={type}
        disabled={disabled ? true : false}
        placeholder={placeHolder ? placeHolder : ""}
        value={ value }
        required={required ? true : false}
        onChange={onChange}
        onClick={onClick}
        onBlur={onBlur}
        {...(defaultValue ? {defaultValue: defaultValue} : {})}
        {...(forwardRef ? { ref: forwardRef } : {})}
        maxLength={maxLength}
        
      />
    </PTextFieldWrapper>
  );
}
