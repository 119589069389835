import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Alert, Snackbar, Stack, Tab, Tabs } from '@mui/material';
import {
    PButton, PButtonGroup, PFlex, PFlexItem, TabChangeEvent
} from '@porsche-design-system/components-react';

import { PMainHeader, PorscheFont } from '../../assets/styles/CustomStyles';
import { Scaling } from '../../components/administration/commission/scaling/Scaling';
import ScrollToTop from '../../components/common/ScrollToTop';
import { NavSideBar } from '../../components/NavSideBar';
import { AuthContext } from '../../contexts/AuthContext';
import { IsUserAllowed } from '../../helpers/permissions';
import { CommissionScalingDTO } from '../../interfaces/commission';
import { dcspostChangeHistoryLogs, dcsputCommissionScaling, postChangeHistoryLogs, putCommissionScaling } from '../../services/data/Helpers';
import { HistoryLogTypes } from '../../interfaces/changeHistory';
import { type } from '../../services/Constants';
import { Constant } from '../../configs/Constants';
import CustomSnakebar from '../../components/common/CustomSnakebar';
import getSessionStorage from '../../helpers/getSessionStorage';

interface HeaderTabPanels {
  id: number;
  name: string;
  enabled: boolean;
  className: string;
}
export const CommissionScalingPage = (): JSX.Element => {
  const { t } = useTranslation();
  const orderValues = getSessionStorage(Constant.SingleOrdDetail); 
  const [edit, setEdit] = useState(false);
  const [save, setSave] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [showSaveButton, setShowSaveButton] = useState<boolean>(false);
  const [commissionPost, setCommissionPost] = useState<CommissionScalingDTO>({} as CommissionScalingDTO);
  const [isModified, setIsModified] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [reload, setReload] = useState("");
  const [showAlert, setShowAlert] = useState(Constant.None)
  const [alertMessage, setAlertMessage] = useState(Constant.Savedsuccessfully)

  const authCtx = useContext(AuthContext);
  const messageData: boolean = JSON.parse(sessionStorage.getItem("messageFlag") || "false") || (false);

  const [tabPanels, setTabPanels] = useState<Array<HeaderTabPanels>>([]);
  const [panelIndex, setPanelIndex] = useState<number>(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [modeChange, setChangeMode] = useState("");
  const currPageName = "Commission Scaling";
  const sessionUser = sessionStorage.getItem("userDetails");
  let userId ="" 
  if(sessionUser) {
    userId = JSON.parse(sessionUser).id;
  }
   
  const logData: HistoryLogTypes = {
    page: currPageName,
    tab: tabPanels[panelIndex]?.name,
    userId: userId,
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setPanelIndex(newValue);
  };
  useEffect(() => {
    const mode = orderValues?.mode ? orderValues?.mode+'' :'';
    setChangeMode(mode);
  }, [])
  const onTabChange = (e: CustomEvent<TabChangeEvent>) => {
    setTabIndex(e.detail.activeTabIndex);
  }
  const handleEdit = () => {
    setEdit(true);
    sessionStorage.setItem("changeFlag", "true");
  }
  const handleComissionPost = (commission: CommissionScalingDTO) => {
    setCommissionPost(commission);
    setIsModified(true);
  }
  const handleCancel = () => {
    sessionStorage.setItem("changeFlag",("false" || false));
    setEdit(false);
    setCancel(true);
    window.location.reload();
  }
  const handleIsModified = () => {
    setIsModified(true);
    setShowSaveButton(true);
    // sessionStorage.setItem("changeFlag",("true" || true));
  }
  const onEditMode = (enableSave: boolean) => {
    setShowSaveButton(enableSave);
  }

  const validateData = (data: CommissionScalingDTO) => {
    let isValid = true;
    data.commissions.map((x, i) => {
      if (isValid) {
        if (x.minCommission) {
          if (x.minCommissionValue !== undefined && x.minCommissionValue === 0) {
            isValid = false;
            return;
          }
        }
        if (x.maxCommission) {
          if (x.maxCommissionValue !== undefined && x.maxCommissionValue === 0) {
            isValid = false;
            return;
          }
        }
        if (x.noProfitCommission) {
          if (x.noProfitCommissionValue !== undefined && x.noProfitCommissionValue === 0) {
            isValid = false;
            return;
          }
        }

        x.vehicleCommissions.map((item, i) => {
          if (isValid) {
            if (item.fromValue > item.toValue) {
              isValid = false;
              return;
            }

            if (item.payoutValue1 === 0) {
              isValid = false;
              return;
            }

            if (x.payOut2 && item.payoutValue2 === 0) {
              isValid = false;
              return;
            }
          }
        })
      }
    })
    return isValid;
  }
  const handleSave = () => {
    sessionStorage.setItem("changeFlag",("false" || false));
    switch (panelIndex) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
        const checkData = true;
        if (checkData) {
          try {
            const init = async () => {
              const apiResponse = await dcsputCommissionScaling(commissionPost)
              if(apiResponse.status===type.SUCCESS){
                setShowAlert(Constant.Success)    
                setAlertMessage(Constant.Savedsuccessfully)
                await dcspostChangeHistoryLogs(logData, 'admin')
                window.location.reload();
                setSaveSuccess(true);
                sessionStorage.setItem("messageFlag",("true" || true));
                setReload(new Date().toString())
              }

              if(apiResponse.status===type.ERROR){
                setShowAlert(Constant.Error)
                setAlertMessage(apiResponse.response)              
               }
              
            }
            init();
          } catch (e) {
          }
          setEdit(false);
        } else {
          setIsError(true);
        }
        break;
    }
  }
  const handleClose = ( ) => {
    setShowAlert(Constant.None)
    setAlertMessage("")
    setSaveSuccess(false);
    setReload(new Date().toString())
    sessionStorage.setItem("messageFlag", ("false" || false));
    setIsError(false);
  };
  useEffect(() => {
    var panels = [];
    panels.push({
      id: 0, name: "Scaling 1", enabled: true, className: ""
    });
    panels.push({
      id: 1, name: "Scaling 2", enabled: true, className: ""
    });
    panels.push({
      id: 2, name: "Scaling 3", enabled: true, className: ""
    });
    panels.push({
      id: 3, name: "Scaling 4", enabled: true, className: ""
    });
    panels.push({
      id: 4, name: "Scaling 5", enabled: true, className: ""
    });
    setTabPanels(panels);
  }, []);

  return (
    <>
    <div className="custombody" style={{marginBottom:"-40px"}}>
      <div className="navbar" style={{ position: 'fixed', top: '85px',zIndex:"9999" }}>
        <NavSideBar main="Administration" subPage="Commission Scaling" commissionSave={handleSave} disabled={!showSaveButton}  />
      </div>
      <div>
        <div className="custombody"style={{ width: '100%',  backgroundColor: '#F2F2F2', padding: '100px 0px 10px 71px'}}>
        <div className="admin-header" style={{position:'fixed',marginTop:"-22px",padding:"40px 40px 16px 40px",marginLeft:"-10px" ,backgroundColor: '#F2F2F2', zIndex:"999"}}>   
         <div className="main-header-left">
              <PMainHeader>
                <PorscheFont data-testid="page-variables" className="main-header-text">
                  {t("Commission Scaling")}
                </PorscheFont>
                <PorscheFont className="main-header-description">
                  {t("Define the commission scaling for all relevant vehicle categories and parts.")}
                </PorscheFont>
              </PMainHeader>
              <PFlex >  
            <PFlexItem  width="three-quarters"> 
            {<Tabs
            textColor="primary"
            indicatorColor="secondary"
            onChange={handleChange}
            value={panelIndex}
            sx={{
              minHeight: '37px!important',
              "& .MuiTabs-indicator": {
                backgroundColor: "#D5001B",
                height: 2,
              },
              "& .MuiTab-root.Mui-selected": {
                color: "#313639",
                textTransform: "inherit",
                fontFamily: "Porsche Next",
                paddingBottom: "5px"
              },
              "& .MuiTab-root": {
                color: "#313639"
              }
            }}
          >
            {tabPanels.map((tabPanel, i) => {
              return (
                <Tab
                  //className={classes.tab}
                  disabled={!tabPanel.enabled}
                  //label={tabPanel.name}
                  label={t(tabPanel.name.split(' ')[0]) + " " +  tabPanel.name.split(' ')[1]}
                  value={tabPanel.id}
                  sx={{
                    borderLeft: "1px solid #C8CACB ",
                    height: "21px",
                    padding: "0px 24px !important",
                    minHeight: '16px !important',
                    textTransform: "inherit",
                    fontFamily: "Porsche Next",
                    fontSize: "16px",
                    marginBottom: "-30px",
                    marginTop: "8px"
                  }} />
              );
            })}
            </Tabs>}
            </PFlexItem>
          <PFlexItem  style={window.innerHeight>1000 ? {paddingLeft: "250px", marginTop: "-45px"}: {paddingLeft: "125px",  marginTop: "-45px"}}>           
          { !edit && (
                  <PButton 
                    icon="edit"
                    onClick={handleEdit}
                    disabled={IsUserAllowed("adminVariables", authCtx) ? false : true}
                  >{t("Edit entries")}</PButton>
                )}                
                { edit && (
                    <PButtonGroup>
                      <Stack direction="row" style={window.innerHeight>1000 ? {paddingRight:"80px"} : {paddingRight:"60px"}}>
                      <PButton variant="tertiary" icon='close' onClick={handleCancel}>{t("Cancel")}</PButton>&ensp;
                      <PButton icon='save' onClick={handleSave} disabled={!showSaveButton}>{t("Save")}</PButton>
                      </Stack>
                    </PButtonGroup>
                  )
                }              
             </PFlexItem>
          </PFlex>
          </div>
          </div>
          <div style={{padding:"124px 125px 52px 32px", minHeight:"100vh", width:"100vw"}}>           
            <ScrollToTop />
            {panelIndex === 0 && <Scaling
              name={"scaling1"}
              edit={edit}
              save={save}
              cancel={cancel}
              handleCommissionPost={handleComissionPost}
              modified={handleIsModified}
              setShowSaveButton={setShowSaveButton}
            />}
            {panelIndex === 1 && <Scaling
              name={"scaling2"}
              edit={edit}
              save={save}
              cancel={cancel}
              handleCommissionPost={handleComissionPost}
              modified={handleIsModified}
              setShowSaveButton={setShowSaveButton}
            />}
            {panelIndex === 2 && <Scaling
              name={"scaling3"}
              edit={edit}
              save={save}
              cancel={cancel}
              handleCommissionPost={handleComissionPost}
              modified={handleIsModified}
              setShowSaveButton={setShowSaveButton}
            />}
            {panelIndex === 3 && <Scaling
              name={"scaling4"}
              edit={edit}
              save={save}
              cancel={cancel}
              handleCommissionPost={handleComissionPost}
              modified={handleIsModified}
              setShowSaveButton={setShowSaveButton}
            />}
            {panelIndex === 4 && <Scaling
              name={"scaling5"}
              edit={edit}
              save={save}
              cancel={cancel}
              handleCommissionPost={handleComissionPost}
              modified={handleIsModified}
              setShowSaveButton={setShowSaveButton}
            />}
        </div>
        </div>      
       </div>
     </div>
    {/* <div className='flex30 pt20'>
    <Snackbar open={saveSuccess} anchorOrigin= {{vertical: 'top', horizontal: 'center'}} autoHideDuration={2000} onClose={handleClose}>
      <Alert  severity="success" sx={{ width: '100%' }}>
      {t("saved successfully")}!
      </Alert>
    </Snackbar>
    <Snackbar open={isError} anchorOrigin= {{vertical: 'top', horizontal: 'center'}} autoHideDuration={2000} onClose={handleClose}>
      <Alert  severity="error" sx={{ width: '100%' }}>
      {t("Please enter valid values.")}
      </Alert>
    </Snackbar>
  </div>
  {(messageData ===  true || "true") && (
      <div className='flex30 pt20'>
      <Snackbar open={messageData}  message="Saved successfully" style={{zIndex:999, marginTop:"50px"}} anchorOrigin= {{vertical: 'top', horizontal: 'center'}} autoHideDuration={2000} onClose={handleClose}>
        <Alert  severity="success" sx={{ width: '100%' }}>
        {t("Saved successfully")}!
        </Alert>
      </Snackbar>
    </div>
  )} */}

<CustomSnakebar alertType={showAlert} alertMessage={alertMessage} handleClose={()=>handleClose()}/>

  </>
  );
}
