import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    PButtonGroup, PCheckboxWrapper, PGrid, PGridItem, PSelectWrapper
} from '@porsche-design-system/components-react';

import { PorscheFont } from '../../../../assets/styles/CustomStyles';
import { OrderContext } from '../../../../contexts/OrderContext';
import { OrderDTO, PaymentType } from '../../../../interfaces/Order';
import { CustomTextField } from '../../../common/CustomTextField';

interface Props {
    paymentTypes: Array<PaymentType>;
    enableNextButton: (isValid: boolean) => void;
    tradeInToggle: boolean;
    referralToggle: boolean;
    disabled: boolean;
    paymentDetails?: OrderDTO["paymentDetails"];
    handleModified: () => void;
    updateError: (id: string) => void;
}

const durationsConfig = [
    {
      value: 6,
      selected: false,
    },
    {
      value: 12,
      selected: false,
    },
    {
      value: 18,
      selected: false,
    },
    {
      value: 24,
      selected: false,
    },
    {
        value: 30,
        selected: false,
    },
    {
        value: 36,
        selected: false,
    },
    {
        value: 42,
        selected: false,
    },
    {
        value: 48,
        selected: false,
    },
    {
        value: 54,
        selected: false,
    },
    {
        value: 60,
        selected: false,
    }
  ];

export const Payment = (props: Props): JSX.Element => {
  const { paymentTypes, enableNextButton, tradeInToggle, referralToggle, disabled, paymentDetails, handleModified, updateError } = props
  const orderContext = useContext(OrderContext)
  const { t } = useTranslation();
  const [ durations, setDurations ] = useState(durationsConfig)
  const [ reload, setReload ] = useState("")
  const [ duration, setDuration ] = useState<number>(0)
  const [ leasingS, setLeasingS] = useState<boolean>(false)
  const [ servicePlus, setServicePlus] = useState<boolean>(false)
  const [ gap, setGap] = useState<boolean>(false)
  const [ carPolicy, setCarPolicy] = useState<boolean>(false)
  const [ porscheCardS, setPorscheCardS] = useState<boolean>(false)
  const [ leasingPFS, setLeasingPFS] = useState<boolean>(false)
  const [ leasingOther, setLeasingOther] = useState<boolean>(false)
  const [ financingPFS, setFinancingPFS] = useState<boolean>(false)
  const [ financingOther, setFinancingOther] = useState<boolean>(false)
  const [ cash, setCash] = useState<boolean>(false)
  const [ subscription, setSubscription] = useState<boolean>(false)
  //const [ bankTransfer, setBankTransfer] = useState<boolean>(false)
  const [ initialLoad, setInitialLoad] = useState<boolean>(false)
  const [ disabledClassName, setDisabledClassName] = useState("")

  useEffect(() => {
      if (paymentDetails) setInitialLoad(true)
      if (paymentDetails && disabled) setDisabledClassName("disabled")
      else setDisabledClassName("")

      if (paymentDetails?.leasing_s) setLeasingS(paymentDetails.leasing_s)
      if (paymentDetails?.servicePlus) setServicePlus(paymentDetails.servicePlus)

      if (paymentDetails?.gap) setGap(paymentDetails.gap)
      if (paymentDetails?.carPolicy) setCarPolicy(paymentDetails.carPolicy)
      if (paymentDetails?.porscheCardS) setPorscheCardS(paymentDetails.porscheCardS)

      if (paymentDetails?.paymentType) showPaymentDetails(paymentDetails.paymentType)
  },[paymentDetails])
  
  useEffect(() => {
    if (durations) {
      durations.map((v) => {
        if (v.value === duration) {
          v.selected = true;
        } else {
          v.selected = false;
        }
      })

      setDurations(durations);
    }
  }, [reload])

  useEffect(() => {
      let isValid = isValidPaymentDetailsSection()
      orderContext.updateFormValidation(0, isValid)
      enableNextButton(isValid)
  })

  useEffect(() => {
    orderContext.setErrors({field: "durationInMonths",message: "",section:"paymentDetails"})
  },[])

  const isValidPaymentDetailsSection = () : boolean => {
    const errors = orderContext.getErrors();
    return errors.every(error => {
      if (error.section === "vehicleDetails") return false
      if (error.section === "customerDetails") return false
      if (error.section === "paymentDetails") return false
      if (error.section === "deliveryDetails") return false
      if (tradeInToggle && error.section === "tradeInDetails") return false
      if (referralToggle && error.section === "deliveryDetails") return false

      return true
    })
  }

  const updatePaymentDetails = (payemntDetails: OrderDTO["paymentDetails"]) => {
    let orderDto = getOrderDetails()
    orderDto["paymentDetails"] = payemntDetails
    orderContext.updateCtxOrderDetails(orderDto)
  }

  const getOrderDetails = () : OrderDTO => {
    return  orderContext.getCtxOrderDetails()
  }

  const getPaymentDetailsFromOrder = (orderDto: OrderDTO) : OrderDTO["paymentDetails"] => {
    return orderDto["paymentDetails"] || {}
  }

  const handleLeasingS = () => {
    setLeasingS(!leasingS)
    let paymentDetails = getPaymentDetailsFromOrder(getOrderDetails())
    paymentDetails.leasing_s = !leasingS
    updatePaymentDetails(paymentDetails)
    handleModified();
  }

  const handleServicePlus = () => {
    setServicePlus(!servicePlus)
    let paymentDetails = getPaymentDetailsFromOrder(getOrderDetails());
    paymentDetails.servicePlus = !servicePlus
    updatePaymentDetails(paymentDetails)
    handleModified();
  }

  const handleGap = () => {
    setGap(!gap)
    let paymentDetails = getPaymentDetailsFromOrder(getOrderDetails())
    paymentDetails.gap = !gap
    updatePaymentDetails(paymentDetails)
    handleModified();
  }

  const handleCarPolicy = () => {
    setCarPolicy(!carPolicy)
    let paymentDetails = getPaymentDetailsFromOrder(getOrderDetails())
    paymentDetails.carPolicy = !carPolicy
    updatePaymentDetails(paymentDetails)
    handleModified();
  }

  const handlePorscheCardS = () => {
    setPorscheCardS(!porscheCardS)
    let paymentDetails = getPaymentDetailsFromOrder(getOrderDetails())
    paymentDetails.porscheCardS = !porscheCardS
    updatePaymentDetails(paymentDetails)
    handleModified();
  }

  const handleFinancingCompany = (e: ChangeEvent<HTMLInputElement>) : void => {
    let paymentDetails = getPaymentDetailsFromOrder(getOrderDetails());
    if (e.target.value.length > 0) {
      paymentDetails.financingCompany = e.target.value;
      updatePaymentDetails(paymentDetails);
      updateError("financingCompany");
      orderContext.removeError("financingCompany")
    } else {
      paymentDetails.financingCompany = null;
      updatePaymentDetails(paymentDetails)
      orderContext.setErrors({field: "financingCompany",message: "",section:"paymentDetails"})
    }

    
    handleModified();
    setReload(new Date().toLocaleString());
  }

  const handleLeasingCompany = (e: ChangeEvent<HTMLInputElement>) : void => {
    let paymentDetails = getPaymentDetailsFromOrder(getOrderDetails())
    if (e.target.value.length > 0) {
      paymentDetails.leasingCompany = e.target.value;
      updatePaymentDetails(paymentDetails);
      updateError("leasingCompany");
      orderContext.removeError("leasingCompany");
    } else {
      paymentDetails.leasingCompany = null;
      updatePaymentDetails(paymentDetails);
      orderContext.setErrors({field: "leasingCompany",message: "",section:"paymentDetails"})
    }
    handleModified();
    setReload(new Date().toLocaleString());
  }

  const handleDuration = (value: number) => {
    setDuration(value);
    let paymentDetails = getPaymentDetailsFromOrder(getOrderDetails());
    paymentDetails.durationInMonths = value;
    handleModified();
    updatePaymentDetails(paymentDetails);
    updateError("durationInMonths");
    orderContext.removeError("durationInMonths");
    orderContext.removeError("paymentType")

    if (durations) {
      durations.map((v) => {
        if (v.value === value) {
          v.selected = true;
        } else {
          v.selected = false;
        }
      })

      setDurations(durations);
      setReload(new Date().toLocaleString());
    }
  }

  const clearPaymentDetails = () => {
    let paymentDetails = getPaymentDetailsFromOrder(getOrderDetails());
    paymentDetails.financingCompany = ''
    paymentDetails.leasingCompany = ''
    updatePaymentDetails(paymentDetails)
  }

  const handlePayment = (e: ChangeEvent<HTMLInputElement>) : void => {
    let paymentDetails = getPaymentDetailsFromOrder(getOrderDetails());
    if (e.target.value === "") {
      paymentDetails.program_company = null;
      updatePaymentDetails(paymentDetails);
      handleModified();
      setReload(new Date().toLocaleString());
      return
    }
    
    paymentDetails.program_company = e.target.value
    handleModified();
    updatePaymentDetails(paymentDetails);
  }

  const handlePaymentType = (e: ChangeEvent<HTMLSelectElement>) : void => {
    clearPaymentDetails()
    let paymentDetails = getPaymentDetailsFromOrder(getOrderDetails());
    const selectedPaymentType = e.target.value
    if (selectedPaymentType === 'default') {
      paymentDetails.paymentType = null;
      paymentDetails.durationInMonths = 0;
      paymentDetails.program_company = null;
      paymentDetails.leasingCompany = null;
      paymentDetails.financingCompany = null;
      updatePaymentDetails(paymentDetails)
      orderContext.setErrors({field: "paymentType",message: "",section:"paymentDetails"})
      orderContext.setErrors({field: "customerDetails",message: "",section:"customerDetails"})
      setReload(new Date().toLocaleString());
      return
    }

    orderContext.removeError("customerDetails")
    if (selectedPaymentType === 'DebitCredit' || selectedPaymentType === 'SUBSCRIPTION' ) {
      orderContext.removeError("paymentType")
    } else {
      orderContext.setErrors({field: "paymentType",message: "",section:"paymentDetails"})
    }

    paymentDetails.paymentType = e.target.value
    paymentDetails.durationInMonths = 0;
    paymentDetails.program_company = null;
    paymentDetails.leasingCompany = null;
    paymentDetails.financingCompany = null;
    updatePaymentDetails(paymentDetails)
    updateError("paymentType")
    showPaymentDetails(selectedPaymentType)
    setReload(new Date().toString())
    handleModified();
    setInitialLoad(true)
  }

  const showPaymentDetails = (paymentType: string) => {
    if (paymentType === 'DebitCredit') {
      setLeasingPFS(false)
      setLeasingOther(false)
      setFinancingPFS(false)
      setFinancingOther(false)
      setCash(true)
      setSubscription(false)
      ////setBankTransfer(true)
      orderContext.removeError("durationInMonths")
      orderContext.removeError("leasingCompany")
      orderContext.removeError("financingCompany")
    }
    if (paymentType === 'SUBSCRIPTION') {
      setLeasingPFS(false)
      setLeasingOther(false)
      setFinancingPFS(false)
      setFinancingOther(false)
      setCash(false)
      setSubscription(true)
      //setBankTransfer(false)
      orderContext.removeError("leasingCompany")
      orderContext.removeError("financingCompany")
    }
    if (paymentType === 'Leasing_PFS') {
      setLeasingPFS(true)
      setLeasingOther(false)
      setFinancingPFS(false)
      setFinancingOther(false)
      setCash(false)
      setSubscription(false)
      //setBankTransfer(false)
      orderContext.removeError("leasingCompany")
      orderContext.removeError("financingCompany")
    }
    if (paymentType === 'Leasing_Other') {
      setLeasingPFS(false)
      setLeasingOther(true)
      setFinancingPFS(false)
      setFinancingOther(false)
      setCash(false)
      setSubscription(false)
      //setBankTransfer(false)
      orderContext.setErrors({field: "leasingCompany",message: "",section:"paymentDetails"})
      orderContext.removeError("financingCompany")
    }
    if (paymentType === 'Financing_PFS') {
      setLeasingPFS(false)
      setLeasingOther(false)
      setFinancingPFS(true)
      setFinancingOther(false)
      setCash(false)
      setSubscription(false)
      //setBankTransfer(false)
      orderContext.removeError("financingCompany")
      orderContext.removeError("leasingCompany")
    }
    if (paymentType === 'Financing_Other') {
      setLeasingPFS(false)
      setLeasingOther(false)
      setFinancingPFS(false)
      setFinancingOther(true)
      setCash(false)
      setSubscription(false)
      //setBankTransfer(false)
      orderContext.setErrors({field: "financingCompany",message: "",section:"paymentDetails"})
      orderContext.removeError("leasingCompany")
    }
  }

return (
    <>
         <div style={{backgroundColor: "white"}}>
         <PorscheFont data-testid="payment" className="card-header">{t("Payment")}
        </PorscheFont>
        <PGrid>
          <PGridItem size={3}>
            <PSelectWrapper id="paymentTypeWrap" label={`${t("Payment type")}`}>
                <select id="paymentType" name="payment_id" required onChange={(e) => handlePaymentType(e)} disabled={disabled}>
                <option key="default" value="default">{t("Select payment type")}</option>
                    { paymentTypes?.map((payment, index) => {
                        return <option key={index} value={payment.second} selected={payment.second === paymentDetails?.paymentType}>{ t(payment.first) }</option>
                    })
                    }
                </select>
            </PSelectWrapper>
          </PGridItem>
          { initialLoad && (
            <>
                { leasingPFS && (
                  <PGridItem size={4} style={{marginTop:'-24px'}}>
                    <CustomTextField id="program" type="text" name="program" defaultValue={paymentDetails?.program_company ? paymentDetails?.program_company : ""} label={`${t("Program (Optional)")}`} disabled={disabled} onChange={(e) => handlePayment(e)} 
                    // onClick={(e) => handlePayment(e)}
                    />
                  </PGridItem>
                )}

                { (!cash) && (
                    <PGridItem size={5}>
                    <span><PorscheFont>{t("Duration in months")} <span style={{color: "#D5001B"}}>*</span></PorscheFont></span>
                    <div id="durationInMonths" className={"series-model-error-hide"} style={{marginTop: "8px"}}><PorscheFont >{t("Please select duration in months to proceed further")} </PorscheFont></div>
                    <PButtonGroup className="pt10">
                      {
                        durations.map((duration, index) => {
                          if(duration.value === paymentDetails?.durationInMonths) duration.selected=true
                          if(subscription && duration.value !== 6 && duration.value !== 12) {
                            return <></>
                          }
                          return (
                            <div
                              className={duration.selected ? `btn-duration btn-duration-selected ${disabledClassName}` : `durationInMonths btn-duration ${disabledClassName}`}
                              onClick={() => handleDuration(duration.value)}
                            >
                              {duration.value}
                            </div>
                          )
                        })
                      }
                    </PButtonGroup>
                    </PGridItem>
                )}

            </>
          )}

          <PGridItem size={12}>
            { leasingPFS && (
              <>
                  <PGridItem size={12}>
                    <div className="chkbox-payment-leasings">
                    <PCheckboxWrapper id="leasingSWrap" label={`${t("Leasing S")}`} hideLabel={false}>
                        <input
                          id="leasingS"
                            type="checkbox"
                            name="leasingS"
                            checked={leasingS ? true : false}
                            onClick={handleLeasingS}
                            disabled={disabled}
                        />
                    </PCheckboxWrapper>
                    </div>
                  </PGridItem>
                  <PGridItem size={12}>
                  <div className="chkbox-payment-servicePlus">
                    <PCheckboxWrapper id="servicePlusWrap" label={`${t("Service Plus")}`} hideLabel={false}>
                        <input
                            id="servicePlus"
                            type="checkbox"
                            name="servicePlus"
                            checked={servicePlus ? true : false}
                            onClick={handleServicePlus}
                            disabled={disabled}
                        />
                    </PCheckboxWrapper>
                    </div>
                  </PGridItem>
                  <PGridItem size={12}>
                  <div className="chkbox-payment-servicePlus">
                    <PCheckboxWrapper id="gapWrap" label={`${t("GAP")}`} hideLabel={false}>
                        <input
                            id="gap"
                            type="checkbox"
                            name="gap"
                            checked={gap ? true : false}
                            onClick={handleGap}
                            disabled={disabled}
                        />
                    </PCheckboxWrapper>
                    </div>
                  </PGridItem>
              </>
            )}

            <PGridItem size={12}>
              <div className="chkbox-payment-servicePlus">
                <PCheckboxWrapper label={`${t("Car Policy")}`} hideLabel={false}>
                  <input
                      type="checkbox"
                      name="carPolicy"
                      checked={carPolicy ? true : false}
                      onClick={handleCarPolicy}
                      disabled={disabled}
                  />
                </PCheckboxWrapper>
              </div>
            </PGridItem>

            <PGridItem size={12}>
              <div className="chkbox-payment-servicePlus">
                <PCheckboxWrapper label={`${t("Porsche Card S")}`} hideLabel={false}>
                  <input
                      type="checkbox"
                      name="porscheCardS"
                      checked={porscheCardS ? true : false}
                      onClick={handlePorscheCardS}
                      disabled={disabled}
                  />
                </PCheckboxWrapper>
              </div>
            </PGridItem>

            { leasingOther && (
              <>
                <PGridItem size={3}>
                  <div className="txt-leasing-company">
                    <CustomTextField id="leasingCompany" type="text" name="leasingCompany" label={`${t("Leasing company")}`} defaultValue={paymentDetails?.leasingCompany ? paymentDetails?.leasingCompany : ""} 
                    // onClick={(e) => handleLeasingCompany(e)}  
                    onChange={(e) => handleLeasingCompany(e)} required={true} disabled={disabled} />
                  </div>
                </PGridItem>
              </>
            )}

            { financingOther && (
              <>
                <PGridItem size={3}>
                  <div className="txt-financing-company">
                    <CustomTextField id="financingCompany" type="text" name="financingCompany" label={`${t("Financing company")}`} defaultValue={paymentDetails?.financingCompany ? paymentDetails?.financingCompany : ""}
                    // onClick={(e) => handleFinancingCompany(e)}  
                    onChange={(e) => handleFinancingCompany(e)} required={true} disabled={disabled} />
                  </div>
                </PGridItem>
              </>
            )}

          </PGridItem>

          </PGrid>
          </div>
    </>
);
}
