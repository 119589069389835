import { Stack } from "@mui/material";
import { PText } from "@porsche-design-system/components-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { PorscheFont } from "../../../assets/styles/CustomStyles";
import { getAmountNet } from "../../../helpers/calculations";
import {
  AdditionalCostsPriceDTO,
  CalculationDraftDTO,
  CommissionSharingDTO,
  DiscountNetPriceDTO,
  KickbackNetPriceDTO,
  TradeInPriceDTO,
  VehiclePriceDTO,
} from "../../../interfaces/Calculation";
import { OrderDTO, SaveAsDraftDTO } from "../../../interfaces/Order";
import { UpsaleDetailsDTO, UpsaleItemDTO } from "../../../interfaces/UpsaleNew";
import { GetRvm } from "../../../interfaces/Variables";
import {
  formatCurrencyWithNoUnit,
  getPayementTypeName,
  getWorkshopServiceName,
  negativeValueHandler,
} from "../../../mock/helper";
import { type } from "../../../services/Constants";
import {
  dcsgetOrderDetails,
  dcsgetOrderSummary,
  dcsgetRvm
} from "../../../services/data/Helpers";
import getSessionStorage from "../../../helpers/getSessionStorage";
import { Constant } from "../../../configs/Constants";
import draftValueHandler from "../../../helpers/sessionDraftValueHandler";
// import { getAmountNet } from '../../../helpers/calculations';

const checkBoldTextForDeviation = (value: number): string => {
  return value !== 0 ? "table-col-bold text-right" : "table-td";
};

export const UsedCarPrint = React.forwardRef((props, ref: any) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const orderValues = getSessionStorage(Constant.SingleOrdDetail);
  const [orderData, setOrderData] = useState<CalculationDraftDTO>(
    {} as CalculationDraftDTO
  );
  const [recalculationOrderData, setRecalculationOrderData] =
    useState<CalculationDraftDTO>({} as CalculationDraftDTO);
  const [recalVehiclePrices, setRecalVehiclePrices] =
    useState<Array<VehiclePriceDTO | null>>();
  const [recalDiscountNet, setRecalDiscountNet] =
    useState<Array<DiscountNetPriceDTO | null>>();
  const [recalKickbat, setRecalKickbat] =
    useState<Array<KickbackNetPriceDTO | null>>();
  const [recalAdditionalCost, setRecalAdditionalCost] =
    useState<Array<AdditionalCostsPriceDTO | null>>();
  const [recalTrandInPrices, setRecalTradeInPrices] =
    useState<Array<TradeInPriceDTO | null>>();
  const [recalCommissionShares, setRecalCommissionShares] =
    useState<Array<CommissionSharingDTO | null>>();
  const [tequipmentCommission, setTequipmentCommission] =
    useState<boolean>(false);

  const [orderResult, setOrderResult] = useState<SaveAsDraftDTO>(
    {} as SaveAsDraftDTO
  );
  const [order, setOrder] = useState<OrderDTO>({} as OrderDTO);
  const [rvm, setRvm] = useState<GetRvm>({} as GetRvm);
  const mode = orderValues?.id ? orderValues?.id+'' :'' ;
  const draft = draftValueHandler(orderValues?.draft);

  const [upsalseTotalCommission, setUpsaleTotalCommission] = useState(0)

  const PDSTEQcommissionCalculation  = (upsItem:UpsaleItemDTO[])=>{
    let totalCommission = 0
upsItem.forEach((item)=>{
  if(item.commission>0){

    totalCommission = totalCommission + item.commission
  }
})
setUpsaleTotalCommission(totalCommission)
  }

  useEffect(() => {
    const init = async () => {
      const apiResponse =
        draft === "true"
          ? await dcsgetOrderDetails(true, parseInt(mode))
          : await dcsgetOrderSummary(parseInt(mode));
          if(apiResponse.status===type.SUCCESS){
            const data = apiResponse.response
            const preCalculationData = data?.calculation as CalculationDraftDTO;
          const recalCulationdata = data?.reCalculation as CalculationDraftDTO;
          const upsaleDetails = apiResponse?.response.upsale  as UpsaleDetailsDTO
          PDSTEQcommissionCalculation(upsaleDetails.upsaleItems)

          setOrderData(data?.calculation);
          setTequipmentCommission(data?.tequipmentCommission as boolean
          );
    
          const rearrangedVehiclePrices = preCalculationData?.vehiclePrices
            ?.map((item) => {
              const foundItem = recalCulationdata?.vehiclePrices?.find(
                (element) => element.displayLabel === item.displayLabel
              );
              return foundItem ? { ...foundItem } : null;
            })
            .filter((item) => item !== null);
          rearrangedVehiclePrices && setRecalVehiclePrices(rearrangedVehiclePrices);
          setRecalculationOrderData(data?.reCalculation);
    
          const rearrangedDiscountPrice = preCalculationData?.discountNetPrices
            ?.map((item) => {
              const foundItem = recalCulationdata?.discountNetPrices?.find(
                (element) =>
                  element.discountNetPriceType === item.discountNetPriceType
              );
              return foundItem ? { ...foundItem } : null;
            })
            .filter((item) => item !== null);
          setRecalDiscountNet(rearrangedDiscountPrice);
    
          const rearrangedKickback = preCalculationData?.kickbackNetPrices
            ?.map((item) => {
              const foundItem = recalCulationdata?.kickbackNetPrices?.find(
                (element) =>
                  element.kickbackNetPriceType === item.kickbackNetPriceType
              );
              return foundItem ? { ...foundItem } : null;
            })
            .filter((item) => item !== null);
          setRecalKickbat(rearrangedKickback);
    
          const rearrangedAdditionalDiscount =
            preCalculationData?.additionalCostsPrices
              ?.map((item) => {
                const foundItem = recalCulationdata?.additionalCostsPrices?.find(
                  (element) => element.type === item.type
                );
                return foundItem ? { ...foundItem } : null;
              })
              .filter((item) => item !== null);
          setRecalAdditionalCost(rearrangedAdditionalDiscount);
    
          const recalTradeInPrices = preCalculationData?.tradeInPrices
            ?.map((item) => {
              const foundItem = recalCulationdata?.tradeInPrices?.find(
                (element) => element.displayLabel === item.displayLabel
              );
              return foundItem ? { ...foundItem } : null;
            })
            .filter((item) => item !== null);
          const findind = preCalculationData?.tradeInPrices?.findIndex(
            (item) => item.tradeInColumn === "ProfitBooking"
          );
          recalCulationdata?.tradeInPrices &&
            findind &&
            recalTradeInPrices &&
            recalTradeInPrices.splice(
              findind,
              0,
              recalCulationdata?.tradeInPrices[4]
            );
          setRecalTradeInPrices(recalTradeInPrices);
    
          const recalCommissionshare = preCalculationData?.commissionSharing
            ?.map((item) => {
              const foundItem = recalCulationdata?.commissionSharing?.find(
                (element) => element.sellerNames.id === item.sellerNames.id
              );
              return foundItem ? { ...foundItem } : null;
            })
            .filter((item) => item !== null);
          setRecalCommissionShares(recalCommissionshare);
    
          setOrder(data?.order);
          const rvmRes = await dcsgetRvm();
          if(rvmRes.status===type.SUCCESS){
            const rvmData = rvmRes.response as Array<GetRvm>;
            const rvm = rvmData.filter(
              (x) =>
                x.seriesDTO?.id ===data?.order.vehicleDetails.series?.id
            );
            if (rvm.length > 0 && rvm[0].grossProfit === true) {
              setRvm(rvm[0]);
            }
          }
          
          }
     
    };
    init();
  }, []);

  const formateDate = (strDate: string): string => {
    if (!strDate) return "";
    const date = new Date(strDate).toISOString().split("T")[0];
    const formatDate = date.toString().split("-");
    return `${formatDate[2]}.${formatDate[1]}.${formatDate[0]}`;
  };

  const printGetAmountNet = (item: UpsaleItemDTO, service: boolean, i: number) => {
    if (service) {
      if (
        item.serviceItems &&
        item.serviceItems.length > 0 &&
        item.serviceItems[i]
      ) {
        if (item.serviceItems[i].customerShare > 0) {
          const ret = item.serviceItems[i].services.priceBeforeCustomerNet
            ? item.serviceItems[i].services.priceBeforeCustomerNet
            : 0.0;
          if (item.partType === "part") {
            if (item.serviceItems[i].timeUnits !== undefined) {
              return ret * item?.serviceItems?.[i].timeUnits! / 100;
            } else {
              return 0.00;
            }
          } else {
            return ret;
          }
        } else {
          const ret1 = item.serviceItems[i].services.priceInternal
            ? item.serviceItems[i].services.priceInternal
            : 0.0;
          if (item.partType === "part") {
            if (item.serviceItems[i].timeUnits !== undefined) {
              return ret1 * item?.serviceItems?.[i].timeUnits! / 100;
            } else {
              return 0.00;
            }
          } else {
            return ret1;
          }
        }
      } else {
        return 0.00;
      }
    } else {
      if (item.customerShare > 0) {
        return item.part.priceBeforeCustomerNet ? item.part.priceBeforeCustomerNet : 0.00;
      } else {
        return item.part.priceInternal ? item.part.priceInternal : 0.00;
      }
    }
  }

  const getPercent = (
    item: UpsaleItemDTO,
    service: boolean,
    i: number,
    calType: string
  ) => {
    let disPercent = 0.0;
    let dis = 0.0;
    if (service) {
      const net = getAmountNet(item, service, i);
      if (
        item.serviceItems &&
        item.serviceItems.length > 0 &&
        item.serviceItems[i]
      ) {
        dis = net - item.serviceItems[i].customerShare;
      }
      // if (dis > 0 ) {
      //   disPercent =  (dis / net) * 100;
      // }
    } else {
      const net = getAmountNet(item, service, i);
      dis = net - item.customerShare;
      // if (net > 0) {
      //   disPercent =   (dis / net) * 100;
      // }
    }
    if (
      calType === "re" &&
      recalVehiclePrices &&
      recalVehiclePrices[0]?.amountNet &&
      recalVehiclePrices[0]?.amountNet > 0
    ) {
      disPercent = (dis / recalVehiclePrices[0]?.amountNet) * 100;
    }

    if (
      calType === "pre" &&
      orderData &&
      orderData?.vehiclePrices &&
      orderData?.vehiclePrices[0]?.amountNet > 0
    ) {
      disPercent = (dis / orderData?.vehiclePrices[0]?.amountNet) * 100;
    }

    return disPercent;
  };

  const sgetSharePcPercent = () => {
    let rtpc = 0.0;
    let tpc = 0.0;
    if (
      recalculationOrderData &&
      recalculationOrderData?.tradeInPrices &&
      recalculationOrderData?.tradeInPrices.length > 0 &&
      recalculationOrderData?.vehiclePrices &&
      orderData?.tradeInPrices &&
      orderData?.tradeInPrices.length > 0
    ) {
      if (
        recalculationOrderData?.tradeInPrices[2].amountNet < 0 &&
        recalVehiclePrices &&
        recalVehiclePrices[0]?.amountNet &&
        recalVehiclePrices[0].amountNet > 0
      ) {
        rtpc =
          (recalculationOrderData?.tradeInPrices[3].amountNet /
            recalculationOrderData?.vehiclePrices[0].amountNet) *
          -1 *
          100;
        //rtpc = Number(rtpc.toFixed(2));
      }

      if (
        orderData?.tradeInPrices[2].amountNet < 0 &&
        orderData?.vehiclePrices &&
        orderData?.vehiclePrices[0].amountNet > 0
      ) {
        tpc =
          (orderData?.tradeInPrices[3].amountNet /
            orderData?.vehiclePrices[0].amountNet) *
          -1 *
          100;
        //tpc = Number(tpc.toFixed(2));
      }
    }

    return rtpc - tpc;
  };


  const getSharePcPercent = () => {
    let rtpc = 0.0;
    let tpc = 0.0;

    // if(recalTradeInFields && recalTradeInFields.length > 0 && tradeInFields && tradeInFields.length > 0) {
    if (
      recalTrandInPrices &&
      recalTrandInPrices[3] &&
      recalTrandInPrices[3].amountNet &&
      recalVehiclePrices &&
      recalVehiclePrices[0] &&
      recalVehiclePrices[0]?.amountNet &&
      recalVehiclePrices[0].amountNet!
    ) {
      rtpc =
        (recalTrandInPrices[3].amountNet! / recalVehiclePrices[0]?.amountNet!) *
        -1 *
        100;
      //rtpc = Number(rtpc.toFixed(2));
    }

    if (
      orderData?.tradeInPrices &&
      orderData?.vehiclePrices &&
      orderData?.tradeInPrices[3].amountNet < 0 &&
      orderData?.vehiclePrices[0].amountNet > 0
    ) {
      tpc =
        (orderData?.tradeInPrices[3].amountNet /
          orderData?.vehiclePrices[0].amountNet) *
        -1 *
        100;
      //tpc = Number(tpc.toFixed(2));
      // }
    }

    // setSharePcPercent(rtpc - tpc);
    return rtpc - tpc;
  };

  const calculateValueOfTradeInDiscount = (
    order: OrderDTO,
    type: string
  ): number | null => {
    let isPreCal = type === "precal";
    if (
      order?.tradeInDetails?.tradeInType === "Leasing_Redemption" ||
      order?.tradeInDetails?.tradeInType === "Financing_Redemption_Regular" ||
      order?.tradeInDetails?.tradeInType === "Financing_Redemption_Differential"
    ) {
      const tradeInAmountNet = isPreCal
        ? orderData?.tradeInPrices?.[3]?.amountNet || 0
        : recalTrandInPrices?.[3]?.amountNet || 0;
      const vehicleAmountNet = isPreCal
        ? orderData?.vehiclePrices?.[0]?.amountNet || 1
        : recalVehiclePrices?.[0]?.amountNet || 1;

      return (tradeInAmountNet / vehicleAmountNet) * -1 * 100 || 0.0;
    }
    return null;
  };

  return (
    <>
      <div ref={ref}>
        <div className="print-header-container">
          <div className="header-left-side">
            <PorscheFont className="print-heading-name">
              3. {t("Calculation")} {t("Used Car")}
            </PorscheFont>
            <div className="seller-info-label">
              <table>
                <tr>
                  <td className="table-label-td font-14">Seller</td>
                  <td className="font-14">Timon Topseller</td>
                </tr>
                <tr>
                  <td className="table-label-td font-14">Comm No.</td>
                  <td className="font-14">C0291281</td>
                </tr>
                <tr>
                  <td className="table-label-td font-14">Act No.</td>
                  <td className="font-14">7281</td>
                </tr>
              </table>
            </div>
          </div>
          <div className="header-right-side">
            <Stack direction="column" className="right-header-container">
              <PorscheFont className="order-number">
                {t("Order No. 20120230001")}
              </PorscheFont>
              <PorscheFont className="order-date">
                {t("Creation date")}: {formateDate(order?.createdOn)}
              </PorscheFont>
              <PorscheFont className="order-date">
                {t("Last edited")}: {formateDate(order?.updatedOn)}
              </PorscheFont>
            </Stack>
          </div>
        </div>

        <div>
          <table className="table">
            <thead>
              <tr className="main-heading-row">
                <th colSpan={2}></th>
                <th colSpan={3} className="main-heading">
                  <PorscheFont>{t("Precalculation")}</PorscheFont>
                </th>
                <th colSpan={1} className="empty-space"></th>
                <th colSpan={3} className="main-heading">
                  <PorscheFont>{t("Recalculation")}</PorscheFont>
                </th>
                <th></th>
              </tr>
            </thead>
            {/* Vehicle Price start */}
            <tbody>
              <tr>
                <td
                  colSpan={2}
                  className="table-col-bold table-th-border-bottom p-top"
                >
                  <PorscheFont>{t("Vehicle Price")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Amount (net)")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Amount (gross)")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Taxation")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td"></td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Amount (net)")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Amount (gross)")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Taxation")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Deviation")}</PorscheFont>
                </td>
              </tr>
              {recalVehiclePrices &&
                orderData?.vehiclePrices?.map((ele, index) => {
                  return (
                    <tr key={index}>
                      <td colSpan={2} className="table-td-left">
                        <PorscheFont>{ele.displayLabel}</PorscheFont>
                      </td>
                      <td className="table-td">
                        <PorscheFont>
                          {`${formatCurrencyWithNoUnit(ele.amountNet)} €`}
                        </PorscheFont>
                      </td>
                      <td className="table-td">
                        <PorscheFont>
                          {`${formatCurrencyWithNoUnit(ele.amountGross)} €`}
                        </PorscheFont>
                      </td>
                      <td className="table-td"></td>
                      <td className="table-td"></td>
                      <td className="table-td">
                        <PorscheFont>
                          {`${formatCurrencyWithNoUnit(
                            recalVehiclePrices[index]?.amountNet ?? 0
                          )} €`}
                        </PorscheFont>
                      </td>
                      <td className="table-td">
                        <PorscheFont>
                          {`${formatCurrencyWithNoUnit(
                            recalVehiclePrices[index]?.amountGross ?? 0
                          )} €`}
                        </PorscheFont>
                      </td>
                      <td className="table-td"></td>

                      <td
                        className={checkBoldTextForDeviation(
                          (recalVehiclePrices[index]?.amountNet ?? 0) -
                            ele.amountNet
                        )}
                      >
                        <PorscheFont>
                          {`${formatCurrencyWithNoUnit(
                            (recalVehiclePrices[index]?.amountNet ?? 0) -
                              ele.amountNet
                          )} €`}
                        </PorscheFont>
                      </td>
                    </tr>
                  );
                })}
              {recalVehiclePrices && orderData?.vehiclePrices && (
                <tr>
                  <td colSpan={2} className="table-td-left">
                    <PorscheFont>{t("Taxation")}</PorscheFont>
                  </td>
                  <td colSpan={2}></td>
                  <td className="table-td">
                    <PorscheFont>
                      {orderData?.vehiclePrices?.length > 0 &&
                      orderData?.vehiclePrices[0]?.taxation
                        ? orderData?.vehiclePrices[0]?.taxation
                        : ""}
                    </PorscheFont>
                  </td>
                  <td colSpan={3}></td>
                  <td className="table-td">
                    <PorscheFont>
                      {recalVehiclePrices[0]?.taxation
                        ? recalVehiclePrices[0].taxation
                        : ""}
                    </PorscheFont>
                  </td>
                  <td className="table-td"></td>
                </tr>
              )}
            </tbody>
            {/* Vehicle Price end */}

            {/* Information from Online Car sales start */}
            <tbody>
              <tr>
                <td
                  colSpan={2}
                  className="table-col-bold table-th-border-bottom p-top"
                >
                  <PorscheFont>
                    {t("Information from Online Car sales")}
                  </PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Information")}</PorscheFont>
                </td>
                <td
                  className="table-th-border-bottom p-top table-td"
                  colSpan={3}
                ></td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Information")}</PorscheFont>
                </td>
                <td
                  className="table-th-border-bottom p-top table-td"
                  colSpan={2}
                ></td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Deviation")}</PorscheFont>
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="table-td-left">
                  <PorscheFont>{t("Standing days")}</PorscheFont>
                </td>
                <td className="table-td">
                  <PorscheFont>
                    {orderData?.onlineCarSaleInfo?.standingDays
                      ? orderData?.onlineCarSaleInfo?.standingDays
                      : ""}
                  </PorscheFont>
                </td>
                <td colSpan={3} className="table-td"></td>
                <td className="table-td">
                  <PorscheFont>
                    {recalculationOrderData?.onlineCarSaleInfo?.standingDays
                      ? recalculationOrderData?.onlineCarSaleInfo?.standingDays
                      : ""}
                  </PorscheFont>
                </td>
                <td colSpan={2} className="table-td"></td>

                <td
                  className={checkBoldTextForDeviation(
                    orderData?.onlineCarSaleInfo?.standingDays &&
                      recalculationOrderData?.onlineCarSaleInfo?.standingDays
                      ? recalculationOrderData?.onlineCarSaleInfo
                          ?.standingDays -
                          orderData?.onlineCarSaleInfo?.standingDays
                      : 0
                  )}
                >
                  <PorscheFont>
                    {orderData?.onlineCarSaleInfo?.standingDays &&
                    recalculationOrderData?.onlineCarSaleInfo?.standingDays
                      ? recalculationOrderData?.onlineCarSaleInfo
                          ?.standingDays -
                        orderData?.onlineCarSaleInfo?.standingDays
                      : ""}
                  </PorscheFont>
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="table-td-left">
                  <PorscheFont>{t("Repair costs")}</PorscheFont>
                </td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      orderData?.onlineCarSaleInfo?.repairCosts
                        ? orderData?.onlineCarSaleInfo?.repairCosts
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td colSpan={3} className="table-td"></td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      recalculationOrderData?.onlineCarSaleInfo?.repairCosts
                        ? recalculationOrderData?.onlineCarSaleInfo?.repairCosts
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td colSpan={2} className="table-td"></td>

                <td
                  className={checkBoldTextForDeviation(
                    recalculationOrderData?.onlineCarSaleInfo?.repairCosts &&
                      orderData?.onlineCarSaleInfo?.repairCosts
                      ? recalculationOrderData?.onlineCarSaleInfo?.repairCosts -
                          orderData?.onlineCarSaleInfo?.repairCosts
                      : 0
                  )}
                >
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      recalculationOrderData?.onlineCarSaleInfo?.repairCosts &&
                        orderData?.onlineCarSaleInfo?.repairCosts
                        ? recalculationOrderData?.onlineCarSaleInfo
                            ?.repairCosts -
                            orderData?.onlineCarSaleInfo?.repairCosts
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="table-td-left">
                  <PorscheFont>{t("Last gross bidding price")}</PorscheFont>
                </td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      orderData?.onlineCarSaleInfo?.lastGrossBiddingPrice
                        ? orderData?.onlineCarSaleInfo?.lastGrossBiddingPrice
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td colSpan={3} className="table-td"></td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      recalculationOrderData?.onlineCarSaleInfo
                        ?.lastGrossBiddingPrice
                        ? recalculationOrderData?.onlineCarSaleInfo
                            ?.lastGrossBiddingPrice
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td colSpan={2} className="table-td"></td>
                <td
                  className={checkBoldTextForDeviation(
                    recalculationOrderData?.onlineCarSaleInfo
                      ?.lastGrossBiddingPrice &&
                      orderData?.onlineCarSaleInfo?.lastGrossBiddingPrice
                      ? recalculationOrderData?.onlineCarSaleInfo
                          ?.lastGrossBiddingPrice -
                          orderData?.onlineCarSaleInfo?.lastGrossBiddingPrice
                      : 0
                  )}
                >
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      recalculationOrderData?.onlineCarSaleInfo
                        ?.lastGrossBiddingPrice &&
                        orderData?.onlineCarSaleInfo?.lastGrossBiddingPrice
                        ? recalculationOrderData?.onlineCarSaleInfo
                            ?.lastGrossBiddingPrice -
                            orderData?.onlineCarSaleInfo?.lastGrossBiddingPrice
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="table-td-left">
                  <PorscheFont>{t("Buyer")}</PorscheFont>
                </td>
                <td className="table-td">
                  <PorscheFont>
                    {orderData?.onlineCarSaleInfo?.buyer?.ppnName
                      ? orderData?.onlineCarSaleInfo?.buyer?.ppnName
                      : ""}
                  </PorscheFont>
                </td>
                <td colSpan={3} className="table-td"></td>
                <td className="table-td">
                  <PorscheFont>
                    {recalculationOrderData?.onlineCarSaleInfo?.buyer?.ppnName
                      ? recalculationOrderData?.onlineCarSaleInfo?.buyer?.ppnName
                      : ""}
                  </PorscheFont>
                </td>
                <td colSpan={3} className="table-td"></td>
              </tr>
              <tr>
                <td colSpan={2} className="table-td-left">
                  <PorscheFont>{t("Comment (Optional)")}</PorscheFont>
                </td>
                <td className="table-td">
                  <PorscheFont>
                    {orderData?.onlineCarSaleInfo?.comments
                      ? orderData?.onlineCarSaleInfo?.comments
                      : "Bidding price was super low"}
                  </PorscheFont>
                </td>
                <td colSpan={3} className="table-td"></td>
                <td className="table-td">
                  <PorscheFont>
                    {recalculationOrderData?.onlineCarSaleInfo?.comments
                      ? recalculationOrderData?.onlineCarSaleInfo?.comments
                      : "That´s right, bidding price was too low, I changed it"}
                  </PorscheFont>
                </td>
                <td colSpan={3} className="table-td"></td>
              </tr>
            </tbody>
            {/* Information from Online Car sales end */}

            {/* discountNetPrices start */}
            <tbody>
              <tr>
                <td
                  colSpan={2}
                  className="table-col-bold table-th-border-bottom p-top-16"
                >
                  <PorscheFont>{t("Discount (net)")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Discount in €")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Discount in %")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td"></td>
                <td className="table-th-border-bottom p-top-16 table-td"></td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Discount in €")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Discount in %")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td"></td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Deviation")}</PorscheFont>
                </td>
              </tr>
              {recalDiscountNet &&
                orderData?.discountNetPrices?.map((ele, index) => {
                  return (
                    <tr key={index}>
                      <td colSpan={2} className="table-td-left">
                        <PorscheFont>{ele.displayLabel}</PorscheFont>
                      </td>
                      <td className="table-td">
                        <PorscheFont>
                          {`${formatCurrencyWithNoUnit(ele.discountInEuro)} €`}
                        </PorscheFont>
                      </td>
                      <td className="table-td">
                        <PorscheFont>
                          {`${formatCurrencyWithNoUnit(
                            ele.discountInPercent
                          )} %`}
                        </PorscheFont>
                      </td>
                      <td></td>
                      <td></td>
                      <td className="table-td">
                        <PorscheFont>
                          {recalDiscountNet &&
                          recalDiscountNet[index]?.discountInEuro
                            ? `${formatCurrencyWithNoUnit(
                                recalDiscountNet[index]?.discountInEuro!
                              )} %`
                            : "0,00 %"}
                        </PorscheFont>
                      </td>
                      <td className="table-td">
                        <PorscheFont>
                          {recalDiscountNet &&
                          recalDiscountNet[index]?.discountInPercent
                            ? `${formatCurrencyWithNoUnit(
                                recalDiscountNet[index]?.discountInPercent!
                              )} %`
                            : "0,00 %"}
                        </PorscheFont>
                      </td>
                      <td></td>
                      <td
                        className={checkBoldTextForDeviation(
                          recalDiscountNet &&
                            recalDiscountNet[index]?.discountInPercent
                            ? recalDiscountNet[index]?.discountInPercent! -
                                ele.discountInPercent
                            : 0
                        )}
                      >
                        <PorscheFont>
                          {recalDiscountNet &&
                          recalDiscountNet[index]?.discountInPercent
                            ? `${formatCurrencyWithNoUnit(
                                recalDiscountNet[index]?.discountInPercent! -
                                  ele.discountInPercent
                              )} %`
                            : "0,00 %"}
                        </PorscheFont>
                      </td>
                    </tr>
                  );
                })}
              <tr>
                <td colSpan={2} className="table-td-left">
                  <PorscheFont>{t("Comment (Optional)")}</PorscheFont>
                </td>
                <td colSpan={8} className="table-td-left comment-col">
                  <PorscheFont>
                    {recalculationOrderData?.discountComment
                      ? recalculationOrderData?.discountComment
                      : orderData?.discountComment}
                  </PorscheFont>
                </td>
              </tr>
            </tbody>
            {/* discountNetPrices end */}

            {/* kickbackNetPrices start */}
            <tbody>
              <tr>
                <td
                  colSpan={2}
                  className="table-col-bold table-th-border-bottom p-top-16"
                >
                  <PorscheFont>{t("Kickback (net)")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Kickback in €")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Kickback in %")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td"></td>
                <td className="table-th-border-bottom p-top-16 table-td"></td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Kickback in €")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Kickback in %")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td"></td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Deviation")}</PorscheFont>
                </td>
              </tr>
              {recalKickbat &&
                orderData?.kickbackNetPrices?.map((ele, index) => {
                  return (
                    <tr key={index}>
                      <td colSpan={2} className="table-td-left">
                        <PorscheFont>{ele.displayLabel}</PorscheFont>
                      </td>
                      <td className="table-td">
                        <PorscheFont>
                          {`${formatCurrencyWithNoUnit(ele.kickbackInEuro)} €`}
                        </PorscheFont>
                      </td>
                      <td className="table-td">
                        <PorscheFont>
                          {`${formatCurrencyWithNoUnit(
                            ele.kickbackInPercent
                          )} %`}
                        </PorscheFont>
                      </td>
                      <td colSpan={2} />
                      <td className="table-td">
                        <PorscheFont>
                          {recalKickbat && recalKickbat[index]?.kickbackInEuro
                            ? `${formatCurrencyWithNoUnit(
                                recalKickbat[index]?.kickbackInEuro!
                              )} €`
                            : "0,00 €"}
                        </PorscheFont>
                      </td>
                      <td className="table-td">
                        <PorscheFont>
                          {recalKickbat &&
                          recalKickbat[index]?.kickbackInPercent
                            ? `${formatCurrencyWithNoUnit(
                                recalKickbat[index]?.kickbackInPercent!
                              )} €`
                            : "0,00 €"}
                        </PorscheFont>
                      </td>
                      <td />
                      <td
                        className={checkBoldTextForDeviation(
                          recalKickbat && recalKickbat[index]?.kickbackInPercent
                            ? recalKickbat[index]?.kickbackInPercent! -
                                ele.kickbackInPercent
                            : 0
                        )}
                      >
                        <PorscheFont>
                          {recalKickbat &&
                          recalKickbat[index]?.kickbackInPercent
                            ? `${formatCurrencyWithNoUnit(
                                recalKickbat[index]?.kickbackInPercent! -
                                  ele.kickbackInPercent
                              )} €`
                            : "0,00 €"}
                        </PorscheFont>
                      </td>
                    </tr>
                  );
                })}

              {orderData?.kickbackDescription != "" && (
                <tr>
                  <td colSpan={2} className="table-td-left">
                    <PorscheFont>
                      {t("Description (Sales promotion)")}
                    </PorscheFont>
                  </td>
                  <td colSpan={8} className="table-td-left comment-col">
                    <PorscheFont>
                      {orderData?.additionalCostPriceComment}
                    </PorscheFont>
                  </td>
                </tr>
              )}
            </tbody>
            {/* kickbackNetPrices end */}

            {/* additionalCostsPrices start */}
            <tbody>
              <tr>
                <td
                  colSpan={2}
                  className="table-col-bold table-th-border-bottom p-top-16"
                >
                  <PorscheFont>{t("Additional costs")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Amount (net)")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Discount in €")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Discount in %")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td"></td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Amount (net)")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Discount in €")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Discount in %")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Deviation")}</PorscheFont>
                </td>
              </tr>
              {recalAdditionalCost &&
                orderData?.additionalCostsPrices?.map((ele, index) => {
                  if (ele.label.length > 0)
                    return (
                      <React.Fragment>
                        <tr key={index}>
                          <td colSpan={2} className="table-td-left">
                            <PorscheFont>{ele.label}</PorscheFont>
                          </td>
                          <td className="table-td">
                            <PorscheFont>
                              {`${formatCurrencyWithNoUnit(ele.amountNet)} €`}
                            </PorscheFont>
                          </td>
                          <td className="table-td">
                            <PorscheFont>
                              {`${formatCurrencyWithNoUnit(
                                ele.discountInEuro
                              )} €`}
                            </PorscheFont>
                          </td>
                          <td className="table-td">
                            <PorscheFont>
                              {`${formatCurrencyWithNoUnit(
                                ele.discountInPercent
                              )} %`}
                            </PorscheFont>
                          </td>
                          <td />
                          <td className="table-td">
                            <PorscheFont>
                              {recalAdditionalCost &&
                              recalAdditionalCost[index]?.amountNet
                                ? `${formatCurrencyWithNoUnit(
                                    recalAdditionalCost[index]?.amountNet!
                                  )} €`
                                : "0,00 €"}
                            </PorscheFont>
                          </td>
                          <td className="table-td">
                            <PorscheFont>
                              {recalAdditionalCost &&
                              recalAdditionalCost[index]?.discountInEuro
                                ? `${formatCurrencyWithNoUnit(
                                    recalAdditionalCost[index]?.discountInEuro!
                                  )} €`
                                : "0,00 €"}
                            </PorscheFont>
                          </td>
                          <td className="table-td">
                            <PorscheFont>
                              {recalAdditionalCost &&
                              recalAdditionalCost[index]?.discountInPercent
                                ? `${formatCurrencyWithNoUnit(
                                    recalAdditionalCost[index]
                                      ?.discountInPercent!
                                  )} €`
                                : "0,00 €"}
                            </PorscheFont>
                          </td>
                          <td
                            className={checkBoldTextForDeviation(
                              recalAdditionalCost &&
                                recalAdditionalCost[index]?.discountInPercent
                                ? recalAdditionalCost[index]
                                    ?.discountInPercent! - ele.discountInPercent
                                : 0
                            )}
                          >
                            <PorscheFont>
                              {recalAdditionalCost &&
                              recalAdditionalCost[index]?.discountInPercent
                                ? `${formatCurrencyWithNoUnit(
                                    recalAdditionalCost[index]
                                      ?.discountInPercent! -
                                      ele.discountInPercent
                                  )} €`
                                : "0,00 €"}
                            </PorscheFont>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                })}
              {orderData?.additionalCostPriceComment !== "" && (
                <tr>
                  <td colSpan={2} className="table-td-left">
                    <PorscheFont>{t("Description (Other)")}</PorscheFont>
                  </td>
                  <td colSpan={8} className="table-td-left comment-col">
                    <PorscheFont>
                      {orderData?.additionalCostPriceComment}
                    </PorscheFont>
                  </td>
                </tr>
              )}
            </tbody>
            {/* additionalCostsPrices end */}

            {/* Workshop costs start */}
            <tbody>
              <tr>
                <td
                  colSpan={2}
                  className="table-col-bold table-th-border-bottom p-top-16"
                >
                  <PorscheFont>{t("Workshop costs")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Amount (net)")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Discount in €")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Discount in %")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td"></td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Amount (net)")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Discount in €")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Discount in %")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Deviation")}</PorscheFont>
                </td>
              </tr>
              {orderData?.workshopCosts?.upsaleItems?.map((ele, index) => {
                if (ele.partType === "tire") {
                  return (
                    <React.Fragment key={index}>
                      <tr>
                        <td colSpan={2} className="table-td-left">
                          <PorscheFont>
                            {t("Complete Winter Tires")}
                          </PorscheFont>
                        </td>
                        <td className="table-td">
                          <PorscheFont>
                            {`${formatCurrencyWithNoUnit(
                              printGetAmountNet(ele, false, 0)
                            )} €`}
                          </PorscheFont>
                        </td>
                        <td className="table-td">
                          <PorscheFont>
                            {formatCurrencyWithNoUnit(
                              printGetAmountNet(ele, false, 0) -
                                ele.customerShare
                            )}
                            {" €"}
                          </PorscheFont>
                        </td>
                        <td className="table-td">
                          <PorscheFont>
                            {formatCurrencyWithNoUnit(
                              getPercent(ele, false, 0, "pre")
                            )}
                            {" %"}
                          </PorscheFont>
                        </td>
                        <td />
                        {recalculationOrderData?.workshopCosts?.upsaleItems && (
                          <>
                            {" "}
                            <td className="table-td">
                              <PorscheFont>
                                {`${formatCurrencyWithNoUnit(
                                  printGetAmountNet(
                                    recalculationOrderData?.workshopCosts
                                      ?.upsaleItems[index],
                                    false,
                                    0
                                  )
                                )} €`}
                              </PorscheFont>
                            </td>
                            <td className="table-td">
                              <PorscheFont>
                                {`${formatCurrencyWithNoUnit(
                                  printGetAmountNet(
                                    recalculationOrderData?.workshopCosts
                                      ?.upsaleItems[index],
                                    false,
                                    0
                                  ) -
                                    recalculationOrderData?.workshopCosts
                                      ?.upsaleItems[index].customerShare
                                )} €`}
                              </PorscheFont>
                            </td>
                            <td className="table-td">
                              <PorscheFont>
                                {formatCurrencyWithNoUnit(
                                  getPercent(ele, false, 0, "re")
                                )}
                                {" %"}
                              </PorscheFont>
                            </td>
                          </>
                        )}
                        <td
                          className={checkBoldTextForDeviation(
                            recalculationOrderData?.workshopCosts?.upsaleItems
                              ? getPercent(
                                  recalculationOrderData?.workshopCosts
                                    ?.upsaleItems[index],
                                  false,
                                  0,
                                  "re"
                                ) - getPercent(ele, false, 0, "pre")
                              : 0
                          )}
                        >
                          <PorscheFont>
                            {recalculationOrderData?.workshopCosts
                              ?.upsaleItems &&
                              formatCurrencyWithNoUnit(
                                getPercent(
                                  recalculationOrderData?.workshopCosts
                                    ?.upsaleItems[index],
                                  false,
                                  0,
                                  "re"
                                ) - getPercent(ele, false, 0, "pre")
                              )}
                            {" %"}
                          </PorscheFont>
                        </td>
                      </tr>
                      {ele.serviceItems.map((service, j) => {
                        if (service.services !== null) {
                          return (
                            <tr>
                              <td colSpan={2} className="table-td-left">
                                <PorscheFont>
                                  {getWorkshopServiceName(
                                    ele.serviceItems[j].services
                                      .serviceNameUpsale,
                                    j === 0 ? "winter" : "summer"
                                  )}
                                </PorscheFont>
                              </td>
                              <td className="table-td">
                                <PorscheFont>
                                  {`${formatCurrencyWithNoUnit(
                                    printGetAmountNet(ele, true, j)
                                  )} €`}
                                </PorscheFont>
                              </td>
                              <td className="table-td">
                                <PorscheFont>
                                  {formatCurrencyWithNoUnit(
                                    printGetAmountNet(ele, true, j) > 0
                                      ? printGetAmountNet(ele, true, j) -
                                          service.customerShare
                                      : 0.0
                                  )}
                                  {" €"}
                                </PorscheFont>
                              </td>
                              <td className="table-td">
                                <PorscheFont>
                                  {formatCurrencyWithNoUnit(
                                    getPercent(ele, true, j, "pre")
                                  )}
                                  {" %"}
                                </PorscheFont>
                              </td>
                              <td />
                              <td className="table-td">
                                <PorscheFont>
                                  {`${
                                    recalculationOrderData?.workshopCosts
                                      ?.upsaleItems[index] &&
                                    formatCurrencyWithNoUnit(
                                      printGetAmountNet(
                                        recalculationOrderData?.workshopCosts
                                          ?.upsaleItems[index],
                                        true,
                                        j
                                      )
                                    )
                                  } €`}
                                </PorscheFont>
                              </td>
                              <td className="table-td">
                                <PorscheFont>
                                  {`${
                                    recalculationOrderData?.workshopCosts
                                      ?.upsaleItems[index] &&
                                    formatCurrencyWithNoUnit(
                                      printGetAmountNet(
                                        recalculationOrderData?.workshopCosts
                                          ?.upsaleItems[index],
                                        true,
                                        j
                                      ) > 0
                                        ? printGetAmountNet(
                                            recalculationOrderData
                                              ?.workshopCosts?.upsaleItems[
                                              index
                                            ],
                                            true,
                                            j
                                          ) - service.customerShare
                                        : 0.0
                                    )
                                  } €`}
                                </PorscheFont>
                              </td>
                              <td className="table-td">
                                <PorscheFont>
                                  {formatCurrencyWithNoUnit(
                                    getPercent(ele, true, j, "re")
                                  )}
                                  {" %"}
                                </PorscheFont>
                              </td>
                              <td
                                className={checkBoldTextForDeviation(
                                  recalculationOrderData?.workshopCosts
                                    ?.upsaleItems
                                    ? getPercent(
                                        recalculationOrderData?.workshopCosts
                                          ?.upsaleItems[index],
                                        true,
                                        j,
                                        "re"
                                      ) - getPercent(ele, true, j, "pre")
                                    : 0
                                )}
                              >
                                <PorscheFont>
                                  {recalculationOrderData?.workshopCosts
                                    ?.upsaleItems &&
                                    formatCurrencyWithNoUnit(
                                      getPercent(
                                        recalculationOrderData?.workshopCosts
                                          ?.upsaleItems[index],
                                        true,
                                        j,
                                        "re"
                                      ) - getPercent(ele, true, j, "pre")
                                    )}
                                  {" %"}
                                </PorscheFont>
                              </td>
                            </tr>
                          );
                        }
                      })}
                    </React.Fragment>
                  );
                }

                if (ele.partType === "part") {
                  return (
                    <React.Fragment key={index}>
                      <tr>
                        <td colSpan={2} className="table-td-left">
                          <PorscheFont>{t("PDS / Tequipment")}</PorscheFont>
                        </td>
                        <td className="table-td">
                          <PorscheFont>
                            {`${formatCurrencyWithNoUnit(
                              printGetAmountNet(ele, false, 0)
                            )} €`}
                          </PorscheFont>
                        </td>
                        <td className="table-td">
                          <PorscheFont>
                            {formatCurrencyWithNoUnit(
                              printGetAmountNet(ele, false, 0) -
                                ele.customerShare
                            )}
                            {" €"}
                          </PorscheFont>
                        </td>
                        <td className="table-td">
                          <PorscheFont>
                            {formatCurrencyWithNoUnit(
                              getPercent(ele, false, 0, "pre")
                            )}
                            {" %"}
                          </PorscheFont>
                        </td>
                        <td />
                        <td className="table-td">
                          <PorscheFont>
                            {`${
                              recalculationOrderData &&
                              recalculationOrderData?.workshopCosts
                                ?.upsaleItems &&
                              formatCurrencyWithNoUnit(
                                printGetAmountNet(
                                  recalculationOrderData?.workshopCosts
                                    ?.upsaleItems[index],
                                  false,
                                  0
                                )
                              )
                            } €`}
                          </PorscheFont>
                        </td>
                        <td className="table-td">
                          <PorscheFont>
                            {recalculationOrderData?.workshopCosts?.upsaleItems[
                              index
                            ] &&
                              formatCurrencyWithNoUnit(
                                printGetAmountNet(
                                  recalculationOrderData?.workshopCosts
                                    ?.upsaleItems[index],
                                  false,
                                  0
                                ) -
                                  recalculationOrderData?.workshopCosts
                                    ?.upsaleItems[index].customerShare
                              )}
                            {" €"}
                          </PorscheFont>
                        </td>
                        <td className="table-td">
                          <PorscheFont>
                            {formatCurrencyWithNoUnit(
                              getPercent(ele, false, 0, "re")
                            )}
                            {" %"}
                          </PorscheFont>
                        </td>
                        <td
                          className={checkBoldTextForDeviation(
                            recalculationOrderData?.workshopCosts?.upsaleItems
                              ? getPercent(
                                  recalculationOrderData?.workshopCosts
                                    ?.upsaleItems[index],
                                  false,
                                  0,
                                  "re"
                                ) - getPercent(ele, false, 0, "pre")
                              : 0
                          )}
                        >
                          <PorscheFont>
                            {recalculationOrderData?.workshopCosts
                              ?.upsaleItems &&
                              formatCurrencyWithNoUnit(
                                getPercent(
                                  recalculationOrderData?.workshopCosts
                                    ?.upsaleItems[index],
                                  false,
                                  0,
                                  "re"
                                ) - getPercent(ele, false, 0, "pre")
                              )}
                            {" %"}
                          </PorscheFont>
                        </td>
                      </tr>
                      {ele.serviceItems.map((service, j) => {
                        if (!(service.services.priceBeforeCustomerNet > 0))
                          return <></>;
                        return (
                          <tr>
                            <td colSpan={2} className="table-td-left">
                              <PorscheFont>
                                {t("Installation  (PDS/TEQ)")}
                              </PorscheFont>
                            </td>
                            <td className="table-td">
                              <PorscheFont>
                                {`${formatCurrencyWithNoUnit(
                                  getAmountNet(ele, true, j) / 100
                                )} €`}
                              </PorscheFont>
                            </td>
                            <td className="table-td">
                              <PorscheFont>
                                {formatCurrencyWithNoUnit(
                                  printGetAmountNet(ele, true, j) > 0
                                    ? printGetAmountNet(ele, true, j) -
                                        service.customerShare
                                    : 0.0
                                )}
                                {" €"}
                              </PorscheFont>
                            </td>
                            <td className="table-td">
                              <PorscheFont>
                                {orderData &&
                                orderData?.vehiclePrices &&
                                orderData?.vehiclePrices.length > 0
                                  ? formatCurrencyWithNoUnit(
                                      ((printGetAmountNet(ele, true, j) -
                                        service.customerShare) /
                                        orderData?.vehiclePrices[0]?.amountNet) *
                                        100
                                    )
                                  : "0,00"}
                                {" %"}
                              </PorscheFont>
                            </td>
                            <td />
                            <td className="table-td">
                              <PorscheFont>
                                {`${
                                  recalculationOrderData?.workshopCosts
                                    ?.upsaleItems &&
                                  formatCurrencyWithNoUnit(
                                    printGetAmountNet(
                                      recalculationOrderData?.workshopCosts
                                        ?.upsaleItems[index],
                                      false,
                                      0
                                    )
                                  )
                                } €`}
                              </PorscheFont>
                            </td>
                            <td className="table-td">
                              <PorscheFont>
                                {recalculationOrderData?.workshopCosts
                                  ?.upsaleItems &&
                                  formatCurrencyWithNoUnit(
                                    printGetAmountNet(
                                      recalculationOrderData?.workshopCosts
                                        ?.upsaleItems[index],
                                      true,
                                      j
                                    ) > 0
                                      ? printGetAmountNet(
                                          recalculationOrderData?.workshopCosts
                                            ?.upsaleItems[index],
                                          true,
                                          j
                                        ) - service.customerShare
                                      : 0.0
                                  )}
                                {" €"}
                              </PorscheFont>
                            </td>
                            <td className="table-td">
                              <PorscheFont>
                                {/* {`${formatCurrencyWithNoUnit(getAmountNet(ele, false, 0) - ele.customerShare)} %`} */}
                                {recalVehiclePrices &&
                                recalculationOrderData?.workshopCosts
                                  ?.upsaleItems &&
                                recalculationOrderData?.workshopCosts
                                  ?.upsaleItems[index] &&
                                recalVehiclePrices[2]?.amountNet &&
                                recalVehiclePrices[0]?.amountNet &&
                                orderData?.vehiclePrices
                                  ? formatCurrencyWithNoUnit(
                                      ((printGetAmountNet(
                                        recalculationOrderData?.workshopCosts
                                          ?.upsaleItems[index],
                                        true,
                                        0
                                      ) -
                                        recalculationOrderData?.workshopCosts
                                          ?.upsaleItems[index].customerShare) /
                                        recalVehiclePrices[0]?.amountNet!) *
                                        100
                                    )
                                  : "0.00"}
                              </PorscheFont>
                            </td>
                            <td
                              className={checkBoldTextForDeviation(
                                recalculationOrderData?.workshopCosts
                                  ?.upsaleItems
                                  ? getPercent(
                                      recalculationOrderData?.workshopCosts
                                        ?.upsaleItems[index],
                                      true,
                                      j,
                                      "re"
                                    ) - getPercent(ele, true, j, "pre")
                                  : 0
                              )}
                            >
                              <PorscheFont>
                                {recalculationOrderData?.workshopCosts
                                  ?.upsaleItems &&
                                  formatCurrencyWithNoUnit(
                                    getPercent(
                                      recalculationOrderData?.workshopCosts
                                        ?.upsaleItems[index],
                                      true,
                                      j,
                                      "re"
                                    ) - getPercent(ele, true, j, "pre")
                                  )}
                                {" %"}
                              </PorscheFont>
                            </td>
                          </tr>
                        );
                      })}
                    </React.Fragment>
                  );
                }

                // sample
              })}
              {/* {orderData?.workshopCosts?.upsaleItems?.map((ele, index) => {
                if (ele.partType === "tire") {
                  return (
                    <React.Fragment key={index}>
                      <tr>
                        <td colSpan={2} className='table-td-left'>
                          <PorscheFont>
                            {t("Complete Winter Tires")}
                          </PorscheFont>
                        </td>
                        <td className='table-td'>
                          <PorscheFont>
                            {`${formatCurrencyWithNoUnit(getAmountNet(ele, false, 0))} €`}
                          </PorscheFont>
                        </td>
                        <td className='table-td'>
                          <PorscheFont>
                            {`${formatCurrencyWithNoUnit(ele.customerShare)} €`}
                          </PorscheFont>
                        </td>
                        <td className='table-td'>
                          <PorscheFont>
                            {`${formatCurrencyWithNoUnit(getPercent(ele, false, 0, "NewCar", orderData))} %`}
                          </PorscheFont>
                        </td>
                        <td />
                        <td className='table-td'>
                          <PorscheFont>
                            {`${formatCurrencyWithNoUnit(getAmountNet(ele, false, 0))} €`}
                          </PorscheFont>
                        </td>
                        <td className='table-td'>
                          <PorscheFont>
                            {`${formatCurrencyWithNoUnit(ele.customerShare)} €`}
                          </PorscheFont>
                        </td>
                        <td className='table-td'>
                          <PorscheFont>
                            {`${formatCurrencyWithNoUnit(getAmountNet(ele, false, 0) - ele.customerShare)} %`}
                          </PorscheFont>
                        </td>
                        {(index % 2) ?
                          (<td className='table-col-bold text-right'>
                            <PorscheFont>
                              +1,00%
                            </PorscheFont>
                          </td>)
                          :
                          (<td className='table-td'>
                            <PorscheFont>
                              0,00%
                            </PorscheFont>
                          </td>)}
                      </tr>
                      {ele.serviceItems.map((service, j) => {
                        if (service.services !== null) {
                          return (
                            <tr>
                              <td colSpan={2} className='table-td-left'>
                                <PorscheFont>
                                  {getWorkshopServiceName(ele.serviceItems[j].services.serviceNameUpsale, j === 0 ? "winter" : "summer")}
                                </PorscheFont>
                              </td>
                              <td className='table-td'>
                                <PorscheFont>
                                  {`${formatCurrencyWithNoUnit(getAmountNet(ele, true, j))} €`}
                                </PorscheFont>
                              </td>
                              <td className='table-td'>
                                <PorscheFont>
                                  {`${formatCurrencyWithNoUnit(ele.serviceItems[j].customerShare)} €`}
                                </PorscheFont>
                              </td>
                              <td className='table-td'>
                                <PorscheFont>
                                  {orderData?.vehiclePrices
                                    ? formatCurrencyWithNoUnit((getAmountNet(ele, true, j) - service.customerShare) / orderData?.vehiclePrices[2]?.amountNet * 100)
                                    : "0.00"
                                  }
                                  {"%"}
                                </PorscheFont>
                              </td>
                              <td />
                              <td className='table-td'>
                                <PorscheFont>
                                  {`${formatCurrencyWithNoUnit(getAmountNet(ele, true, j))} €`}
                                </PorscheFont>
                              </td>
                              <td className='table-td'>
                                <PorscheFont>
                                  {`${formatCurrencyWithNoUnit(getAmountNet(ele, true, j) > 0 ? getAmountNet(ele, true, j) - service.customerShare : 0.00)} €`}
                                </PorscheFont>
                              </td>
                              <td className='table-td'>
                                <PorscheFont>
                                  {orderData?.vehiclePrices
                                    ? formatCurrencyWithNoUnit((getAmountNet(ele, true, j) - service.customerShare) / orderData?.vehiclePrices[2]?.amountNet * 100)
                                    : "0.00"
                                  }
                                  {"%"}
                                </PorscheFont>
                              </td>
                              {(index % 2) ?
                                (<td className='table-col-bold text-right'>
                                  <PorscheFont>
                                    +1,00%
                                  </PorscheFont>
                                </td>)
                                :
                                (<td className='table-td'>
                                  <PorscheFont>
                                    0,00%
                                  </PorscheFont>
                                </td>)}
                            </tr>
                          )
                        }
                      })}
                    </React.Fragment>
                  )
                }
              })} */}
              {/* Workshop costs end */}

              {/** Single part start */}
              {/* {parts && parts.length === 1 && orderData?.workshopCosts?.upsaleItems?.map((ele, index) => {
                if (ele.partType === "part") {
                  return (
                    <tr key={index}>
                      <td colSpan={2} className='table-td-left'>
                        <PorscheFont>
                          {t("PDS / Tequipment")}
                        </PorscheFont>
                      </td>
                      <td className='table-td'>
                        <PorscheFont>
                          {`${formatCurrencyWithNoUnit(getAmountNet(ele, false, 0))} €`}
                        </PorscheFont>
                      </td>
                      <td className='table-td'>
                        <PorscheFont>
                          {`${formatCurrencyWithNoUnit(ele.customerShare)} €`}
                        </PorscheFont>
                      </td>
                      <td className='table-td'>
                        <PorscheFont>
                          {`${formatCurrencyWithNoUnit(getAmountNet(ele, false, 0) - ele.customerShare)} %`}
                        </PorscheFont>
                      </td>
                      <td />
                      <td className='table-td'>
                        <PorscheFont>
                          {`${formatCurrencyWithNoUnit(ele.customerShare)} €`}
                        </PorscheFont>
                      </td>
                      <td className='table-td'>
                        <PorscheFont>
                          {`${formatCurrencyWithNoUnit(getAmountNet(ele, false, 0) - ele.customerShare)} €`}
                        </PorscheFont>
                      </td>
                      <td className='table-td'>
                        <PorscheFont>
                          {`${formatCurrencyWithNoUnit(getPercent(ele, false, 0, "NewCar", orderData))} %`}
                        </PorscheFont>
                      </td>
                      {(index % 2) ?
                        (<td className='table-col-bold text-right'>
                          <PorscheFont>
                            +1,00%
                          </PorscheFont>
                        </td>)
                        :
                        (<td className='table-td'>
                          <PorscheFont>
                            0,00%
                          </PorscheFont>
                        </td>)}
                    </tr>
                  )
                }
              })} */}
              {/** Single part  end */}
              {/** Multiple parts */}
              {/* { parts && parts.length > 1 && (

                  )
              } */}
            </tbody>
            {/* Workshop costs end */}

            {/* Trade-in (regular taxed) start  */}
            <tbody>
              <tr>
                <td
                  colSpan={2}
                  className="table-col-bold table-th-border-bottom p-top-16"
                >
                  <PorscheFont>{t("Trade-in (regular taxed)")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Amount (net)")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Discount in €")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Discount in %")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td"></td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Amount (net)")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Discount in €")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Discount in %")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Deviation")}</PorscheFont>
                </td>
              </tr>
              {orderData?.tradeInPrices?.map((ele, index) => {
                if (index > 2) {
                  let show = false;
                  if (
                    order.tradeInDetails &&
                    (order.tradeInDetails.tradeInType ===
                      "Leasing_Redemption" ||
                      order.tradeInDetails.tradeInType ===
                        "Financing_Redemption_Regular" ||
                      order.tradeInDetails.tradeInType ===
                        "Financing_Redemption_Differential") &&
                    ((orderData &&
                      orderData?.tradeInPrices &&
                      orderData?.tradeInPrices[2]?.amountNet < 0) ||
                      (recalTrandInPrices! &&
                        recalTrandInPrices[2]?.amountNet! < 0))
                  ) {
                    show = true;
                  }

                  if (!show) return <></>;
                }
                if (index === 2) {
                  return (
                    <tr key={index}>
                      <td colSpan={2} className="table-td-left">
                        <PorscheFont>
                          {orderData?.tradeInPrices! &&
                          orderData?.tradeInPrices[2].amountNet >= 0 &&
                          recalTrandInPrices! &&
                          recalTrandInPrices[2]?.amountNet! >= 0
                            ? t("Profit booking")
                            : orderData?.tradeInPrices! &&
                              orderData?.tradeInPrices[2].amountNet < 0 &&
                              recalTrandInPrices! &&
                              recalTrandInPrices[2]?.amountNet! < 0
                            ? t("Loss booking")
                            : t("Booking")}
                        </PorscheFont>
                      </td>
                      <td className="table-td">
                        <PorscheFont>
                          {`${formatCurrencyWithNoUnit(ele.amountNet)} €`}
                        </PorscheFont>
                      </td>
                      <td className="table-td">
                        <PorscheFont>
                          {`${formatCurrencyWithNoUnit(ele.amountGross)} €`}
                        </PorscheFont>
                      </td>
                      <td className="table-td">
                        {order?.tradeInDetails &&
                          order?.tradeInDetails?.tradeInType !==
                            "Leasing_Redemption" &&
                          order.tradeInDetails.tradeInType !==
                            "Financing_Redemption_Regular" &&
                          order.tradeInDetails.tradeInType !==
                            "Financing_Redemption_Differential" && (
                            <>
                              <PorscheFont>
                                {orderData?.vehiclePrices &&
                                orderData?.vehiclePrices[0]?.amountNet > 0
                                  ? formatCurrencyWithNoUnit(
                                      orderData?.tradeInPrices! &&
                                        (orderData?.tradeInPrices[2]
                                          ?.amountNet /
                                          orderData?.vehiclePrices[0]
                                            ?.amountNet) *
                                          -1 *
                                          100
                                    )
                                  : "0.00"}
                                {" %"}
                              </PorscheFont>
                            </>
                          )}

                        {order.tradeInDetails &&
                          (order.tradeInDetails.tradeInType ===
                            "Leasing_Redemption" ||
                            order.tradeInDetails.tradeInType ===
                              "Financing_Redemption_Regular" ||
                            order.tradeInDetails.tradeInType ===
                              "Financing_Redemption_Differential") &&
                          orderData?.tradeInPrices! &&
                          orderData?.tradeInPrices[2]?.amountNet >= 0 && (
                            <PorscheFont>
                              {orderData?.vehiclePrices &&
                              orderData?.vehiclePrices[0]?.amountNet > 0
                                ? formatCurrencyWithNoUnit(
                                    orderData?.tradeInPrices! &&
                                      (orderData?.tradeInPrices[2]?.amountNet /
                                        orderData?.vehiclePrices[0]
                                          ?.amountNet) *
                                        -1 *
                                        100
                                  )
                                : "0.00"}
                              {" %"}
                            </PorscheFont>
                          )}
                      </td>
                      <td />
                      <td className="table-td">
                        <PorscheFont>
                          {recalTrandInPrices
                            ? `${formatCurrencyWithNoUnit(
                                recalTrandInPrices[index]?.amountNet!
                              )} €`
                            : "0,00 €"}
                        </PorscheFont>
                      </td>
                      <td className="table-td">
                        <PorscheFont>
                          {recalculationOrderData && recalTrandInPrices
                            ? `${formatCurrencyWithNoUnit(
                                recalTrandInPrices[index]?.amountGross!
                              )} €`
                            : "0,00 €"}
                        </PorscheFont>
                      </td>
                      <td className="table-td">
                        {order?.tradeInDetails &&
                          order?.tradeInDetails?.tradeInType !==
                            "Leasing_Redemption" &&
                          order.tradeInDetails.tradeInType !==
                            "Financing_Redemption_Regular" &&
                          order.tradeInDetails.tradeInType !==
                            "Financing_Redemption_Differential" && (
                            <>
                              <PorscheFont>
                                {recalTrandInPrices! &&
                                recalVehiclePrices! &&
                                recalVehiclePrices[0]?.amountNet! > 0
                                  ? formatCurrencyWithNoUnit(
                                      recalTrandInPrices! &&
                                        recalVehiclePrices[0]?.amountNet! > 0
                                        ? (recalTrandInPrices[2]?.amountNet! /
                                            recalVehiclePrices[0]?.amountNet!) *
                                            -1 *
                                            100
                                        : 0.0
                                    )
                                  : "0.00"}
                                {" %"}
                              </PorscheFont>
                            </>
                          )}

                        {order.tradeInDetails &&
                          (order.tradeInDetails.tradeInType ===
                            "Leasing_Redemption" ||
                            order.tradeInDetails.tradeInType ===
                              "Financing_Redemption_Regular" ||
                            order.tradeInDetails.tradeInType ===
                              "Financing_Redemption_Differential") &&
                          recalTrandInPrices! &&
                          recalTrandInPrices[2]?.amountNet! >= 0 && (
                            <PorscheFont>
                              {recalVehiclePrices! &&
                              recalVehiclePrices[0]?.amountNet! > 0
                                ? formatCurrencyWithNoUnit(
                                    recalTrandInPrices! &&
                                      recalVehiclePrices[0]?.amountNet! > 0
                                      ? (recalTrandInPrices[2]?.amountNet! /
                                          recalVehiclePrices[0]?.amountNet!) *
                                          -1 *
                                          100
                                      : 0.0
                                  )
                                : "0.00"}
                              {" %"}
                            </PorscheFont>
                          )}
                      </td>
                      <td
                        className={checkBoldTextForDeviation(
                          ele.displayLabel === "Share PC"
                            ? getSharePcPercent()
                            : orderData &&
                              orderData?.tradeInPrices &&
                              recalculationOrderData &&
                              recalTrandInPrices
                            ? recalTrandInPrices[index]?.amountNet! -
                              orderData?.tradeInPrices[index]?.amountNet
                            : 0
                        )}
                      >
                        <PorscheFont>
                          {formatCurrencyWithNoUnit(
                            ele.displayLabel === "Share PC"
                              ? getSharePcPercent()
                              : orderData &&
                                orderData?.tradeInPrices &&
                                recalculationOrderData &&
                                recalTrandInPrices
                              ? recalTrandInPrices[index]?.amountNet! -
                                orderData?.tradeInPrices[index]?.amountNet
                              : 0
                          )}
                          {ele.displayLabel === "Share PC" ? " %" : " €"}
                        </PorscheFont>
                      </td>
                    </tr>
                  );
                }
                return (
                  <tr key={index}>
                    <td colSpan={2} className="table-td-left">
                      <PorscheFont>{ele.displayLabel}</PorscheFont>
                    </td>
                    <td className="table-td">
                      <PorscheFont>
                        {`${formatCurrencyWithNoUnit(ele.amountNet)} €`}
                      </PorscheFont>
                    </td>
                    <td className="table-td">
                      <PorscheFont>
                        {`${formatCurrencyWithNoUnit(ele.amountGross)} €`}
                      </PorscheFont>
                    </td>
                    <td className="table-td">
                      {index === 3 &&
                      calculateValueOfTradeInDiscount(order, "precal") !==
                        null ? (
                        <PorscheFont>
                          {formatCurrencyWithNoUnit(
                            calculateValueOfTradeInDiscount(order, "precal") ||
                              0
                          )}
                          {" %"}
                        </PorscheFont>
                      ) : null}
                    </td>
                    <td />
                    <td className="table-td">
                      <PorscheFont>
                        {recalTrandInPrices
                          ? `${formatCurrencyWithNoUnit(
                              recalTrandInPrices[index]?.amountNet!
                            )} €`
                          : "0,00 €"}
                      </PorscheFont>
                    </td>
                    <td className="table-td">
                      <PorscheFont>
                        {recalculationOrderData && recalTrandInPrices
                          ? `${formatCurrencyWithNoUnit(
                              recalTrandInPrices[index]?.amountGross!
                            )} €`
                          : "0,00 €"}
                      </PorscheFont>
                    </td>
                    <td className="table-td">
                      {index === 3 &&
                      calculateValueOfTradeInDiscount(order, "recal") !==
                        null ? (
                        <PorscheFont>
                          {formatCurrencyWithNoUnit(
                            calculateValueOfTradeInDiscount(order, "recal") || 0
                          )}
                          {" %"}
                        </PorscheFont>
                      ) : null}
                    </td>
                    <td
                      className={checkBoldTextForDeviation(
                        ele.displayLabel === "Share PC"
                          ? getSharePcPercent()
                          : orderData &&
                            orderData?.tradeInPrices &&
                            recalculationOrderData &&
                            recalTrandInPrices
                          ? recalTrandInPrices[index]?.amountNet! -
                            orderData?.tradeInPrices[index]?.amountNet
                          : 0
                      )}
                    >
                      <PorscheFont>
                        {formatCurrencyWithNoUnit(
                          ele.displayLabel === "Share PC"
                            ? getSharePcPercent()
                            : orderData &&
                              orderData?.tradeInPrices &&
                              recalculationOrderData &&
                              recalTrandInPrices
                            ? recalTrandInPrices[index]?.amountNet! -
                              orderData?.tradeInPrices[index]?.amountNet
                            : 0
                        )}
                        {ele.displayLabel === "Share PC" ? " %" : " €"}
                      </PorscheFont>
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td className="table-td-left">
                  <PorscheFont>{t("Comment (Optional)")}</PorscheFont>
                </td>
                <td></td>
                <td className="table-td">
                  <PorscheFont>{orderData?.tradeInComment}</PorscheFont>
                </td>
                <td colSpan={3}></td>
                <td className="table-td">
                  <PorscheFont>
                    {recalculationOrderData?.tradeInComment}
                  </PorscheFont>
                </td>
                <td colSpan={2}></td>
                <td className="table-td"></td>
              </tr>
            </tbody>
            {/* Trade-in (regular taxed) end  */}

            {/* Financing In Start */}
            <tbody>
              <tr>
                <td
                  colSpan={2}
                  className="table-col-bold table-th-border-bottom p-top"
                >
                  <PorscheFont>{t("Financing")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Amount (net)")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Amount (gross)")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td"></td>
                <td className="table-th-border-bottom p-top table-td"></td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Amount (net)")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Amount (gross)")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td"></td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Deviation")}</PorscheFont>
                </td>
              </tr>
              {orderData?.calculationPaymentDetails?.paymentType !== "CASH" && (
                <tr>
                  <td colSpan={2} className="table-td-left">
                    <PorscheFont>
                      {getPayementTypeName(
                        orderData?.calculationPaymentDetails?.paymentType
                          ? orderData?.calculationPaymentDetails?.paymentType
                          : ""
                      ) === "Leasing"
                        ? t("Special leasing payment")
                        : t("Down payment")}
                    </PorscheFont>
                  </td>
                  <td className="table-td">
                    <PorscheFont>
                      {`${formatCurrencyWithNoUnit(
                        orderData?.calculationPaymentDetails?.paymentNet!
                      )} €`}
                    </PorscheFont>
                  </td>
                  <td className="table-td">
                    <PorscheFont>
                      {`${formatCurrencyWithNoUnit(
                        orderData?.calculationPaymentDetails?.paymentGross!
                      )} €`}
                    </PorscheFont>
                  </td>
                  <td></td>
                  <td></td>
                  <td className="table-td">
                    <PorscheFont>
                      {`${formatCurrencyWithNoUnit(
                        recalculationOrderData?.calculationPaymentDetails
                          ?.paymentNet!
                      )} €`}
                    </PorscheFont>
                  </td>
                  <td className="table-td">
                    <PorscheFont>
                      {`${formatCurrencyWithNoUnit(
                        recalculationOrderData?.calculationPaymentDetails
                          ?.paymentGross!
                      )} €`}
                    </PorscheFont>
                  </td>
                  <td></td>

                  <td
                    className={checkBoldTextForDeviation(
                      orderData?.calculationPaymentDetails?.paymentNet
                        ? (recalculationOrderData?.calculationPaymentDetails
                            ?.paymentNet ?? 0) -
                            orderData?.calculationPaymentDetails?.paymentNet ??
                            0
                        : 0
                    )}
                  >
                    <PorscheFont>
                      {`${
                        recalculationOrderData?.calculationPaymentDetails &&
                        orderData?.calculationPaymentDetails?.paymentNet &&
                        formatCurrencyWithNoUnit(
                          (recalculationOrderData?.calculationPaymentDetails
                            ?.paymentNet ?? 0) -
                            orderData?.calculationPaymentDetails?.paymentNet ??
                            0
                        )
                      } €`}
                    </PorscheFont>
                  </td>
                </tr>
              )}
              <tr>
                <td colSpan={2} className="table-td-left">
                  <PorscheFont>{t("Up-front payment")}</PorscheFont>
                </td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      orderData?.calculationPaymentDetails?.upFrontPaymentNet
                        ? orderData?.calculationPaymentDetails
                            ?.upFrontPaymentNet
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      orderData?.calculationPaymentDetails?.upFrontPaymentGross
                        ? orderData?.calculationPaymentDetails
                            ?.upFrontPaymentGross
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td></td>
                <td></td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      recalculationOrderData?.calculationPaymentDetails
                        ?.upFrontPaymentNet
                        ? recalculationOrderData?.calculationPaymentDetails
                            ?.upFrontPaymentNet!
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      recalculationOrderData?.calculationPaymentDetails
                        ?.upFrontPaymentGross
                        ? recalculationOrderData?.calculationPaymentDetails
                            ?.upFrontPaymentGross!
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td></td>
                <td
                  className={checkBoldTextForDeviation(
                    recalculationOrderData?.calculationPaymentDetails
                      ?.upFrontPaymentNet
                      ? recalculationOrderData?.calculationPaymentDetails
                          ?.upFrontPaymentNet! -
                          orderData?.calculationPaymentDetails
                            ?.upFrontPaymentNet!
                      : 0
                  )}
                >
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      recalculationOrderData?.calculationPaymentDetails
                        ?.upFrontPaymentNet
                        ? recalculationOrderData?.calculationPaymentDetails
                            ?.upFrontPaymentNet! -
                            orderData?.calculationPaymentDetails
                              ?.upFrontPaymentNet!
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
              </tr>
            </tbody>
            {/* Financing In End */}

            {/* Final Calculation start */}
            <tbody>
              <tr>
                <td
                  colSpan={2}
                  className="table-col-bold table-th-border-bottom p-top"
                >
                  <PorscheFont>{t("Final Calculation")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Amount in €")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("% of RRP (net)")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Days")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td"></td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Amount in €")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("% of RRP (net)")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Days")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Deviation")}</PorscheFont>
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="table-col-bold text-left">
                  <PorscheFont>{t("Total costs")}</PorscheFont>
                </td>
                <td className="table-col-bold text-right">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      orderData?.calculationHeader?.totalCosts
                        ? orderData?.calculationHeader?.totalCosts
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td className="table-col-bold text-right">
                  <PorscheFont>
                    {`${
                      orderData?.calculationHeader !== null &&
                      orderData?.calculationHeader?.externalDiscount &&
                      orderData?.vehiclePrices &&
                      orderData?.vehiclePrices[2]?.amountNet > 0
                        ? formatCurrencyWithNoUnit(
                            (orderData?.calculationHeader?.externalDiscount /
                              orderData?.vehiclePrices[2]?.amountNet) *
                              100
                          )
                        : "0,00"
                    } %`}
                  </PorscheFont>
                </td>
                <td></td>
                <td></td>
                <td className="table-col-bold text-right">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      recalculationOrderData?.calculationHeader?.totalCosts
                        ? recalculationOrderData?.calculationHeader?.totalCosts!
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td className="table-col-bold text-right">
                  <PorscheFont>
                    {`${
                      recalculationOrderData?.calculationHeader !== null &&
                      recalculationOrderData?.calculationHeader
                        ?.externalDiscount &&
                      recalculationOrderData?.vehiclePrices &&
                      recalculationOrderData?.vehiclePrices[2]?.amountNet > 0
                        ? formatCurrencyWithNoUnit(
                            (recalculationOrderData?.calculationHeader
                              ?.externalDiscount /
                              recalculationOrderData?.vehiclePrices[2]
                                ?.amountNet) *
                              100
                          )
                        : "0,00"
                    } %`}
                  </PorscheFont>
                </td>
                <td></td>

                <td
                  className={checkBoldTextForDeviation(
                    recalculationOrderData?.calculationHeader !== null &&
                      recalculationOrderData?.calculationHeader
                        ?.externalDiscount &&
                      orderData?.vehiclePrices &&
                      orderData?.vehiclePrices[2]?.amountNet &&
                      recalculationOrderData?.vehiclePrices &&
                      recalculationOrderData?.vehiclePrices[2]?.amountNet > 0
                      ? (recalculationOrderData?.calculationHeader
                          ?.externalDiscount /
                          recalculationOrderData?.vehiclePrices[2]?.amountNet) *
                          100 -
                          (orderData?.calculationHeader?.externalDiscount! /
                            orderData?.vehiclePrices[2]?.amountNet!) *
                            100
                      : 0
                  )}
                >
                  <PorscheFont>
                    {`${
                      recalculationOrderData?.calculationHeader !== null &&
                      recalculationOrderData?.calculationHeader
                        ?.externalDiscount &&
                      orderData?.vehiclePrices &&
                      orderData?.vehiclePrices[2]?.amountNet &&
                      recalculationOrderData?.vehiclePrices &&
                      recalculationOrderData?.vehiclePrices[2]?.amountNet > 0
                        ? formatCurrencyWithNoUnit(
                            (recalculationOrderData?.calculationHeader
                              ?.externalDiscount /
                              recalculationOrderData?.vehiclePrices[2]
                                ?.amountNet) *
                              100 -
                              (orderData?.calculationHeader?.externalDiscount! /
                                orderData?.vehiclePrices[2]?.amountNet!) *
                                100
                          )
                        : "0,00"
                    } %`}
                  </PorscheFont>
                </td>
              </tr>
              {/* <tr>
                <td colSpan={2} className='table-col-bold text-left'>
                  <PorscheFont>
                    {t("Internal discount")}
                  </PorscheFont>
                </td>
                <td className='table-col-bold text-right'>
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(orderData?.calculationHeader?.internalDiscount ? orderData?.calculationHeader?.internalDiscount : 0)} €`}
                  </PorscheFont>
                </td>
                <td className='table-col-bold text-right'>
                  <PorscheFont>
                    {`${orderData?.calculationHeader?.internalDiscount && orderData?.vehiclePrices && orderData?.vehiclePrices[2]?.amountNet > 0
                      ? formatCurrencyWithNoUnit(orderData?.calculationHeader?.internalDiscount / orderData?.vehiclePrices[2]?.amountNet * 100)
                      : "0,00"
                      } %`}
                  </PorscheFont>
                </td>
                <td></td>
                <td></td>
                <td className='table-col-bold text-right'>
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(orderData?.calculationHeader?.internalDiscount ? orderData?.calculationHeader?.internalDiscount : 0)} €`}
                  </PorscheFont>
                </td>
                <td className='table-col-bold text-right'>
                  <PorscheFont>
                    {`${orderData?.calculationHeader?.internalDiscount && orderData?.vehiclePrices && orderData?.vehiclePrices[2]?.amountNet > 0
                      ? formatCurrencyWithNoUnit(orderData?.calculationHeader?.internalDiscount / orderData?.vehiclePrices[2]?.amountNet * 100)
                      : "0,00"
                      } %`}
                  </PorscheFont>
                </td>
                <td></td>
                {(true) ?
                  <td className='table-col-bold text-right'>
                    <PorscheFont>
                      +2.000,00 €
                    </PorscheFont>
                  </td>
                  :
                  <td className='table-td'>
                    <PorscheFont>
                      0,00 €
                    </PorscheFont>
                  </td>}
              </tr> */}
              {(order.paymentDetails?.paymentType === "Leasing_PFS" ||
                order.paymentDetails?.paymentType === "Leasing_Other") &&
                rvm &&
                rvm.rrp > 0 && (
                  <tr>
                    <td colSpan={2} className="table-td-left">
                      <PorscheFont>{t("Residual value model")}</PorscheFont>
                    </td>
                    <td className="table-td">
                      <PorscheFont>
                        {` ${formatCurrencyWithNoUnit(
                          orderData?.vehiclePrices
                            ? rvm.rrp * orderData?.vehiclePrices[2]?.amountNet
                            : 0.0
                        )} €  `}
                      </PorscheFont>
                    </td>
                    <td className="table-td">
                      <PorscheFont>
                        {`${
                          rvm.rrp ? formatCurrencyWithNoUnit(rvm.rrp) : "0,00"
                        } %`}
                      </PorscheFont>
                    </td>
                    <td></td>
                    <td></td>
                    <td className="table-td">
                      <PorscheFont>
                        {` ${formatCurrencyWithNoUnit(
                          orderData?.vehiclePrices
                            ? rvm.rrp * orderData?.vehiclePrices[2].amountNet
                            : 0.0
                        )} €  `}
                      </PorscheFont>
                    </td>
                    <td className="table-td">
                      <PorscheFont>
                        {`${
                          rvm.rrp ? formatCurrencyWithNoUnit(rvm.rrp) : "0,00"
                        } %`}
                      </PorscheFont>
                    </td>
                    <td></td>

                    <td className="table-td">
                      <PorscheFont>0,00 €</PorscheFont>
                    </td>
                  </tr>
                )}
              <tr>
                <td colSpan={2} className="table-td-left">
                  <PorscheFont>{t("Gross profit")}</PorscheFont>
                </td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      orderData?.calculationHeader?.grossProfit
                        ? orderData?.calculationHeader?.grossProfit
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td className="table-td">
                  <PorscheFont>
                    {`${
                      orderData?.calculationHeader?.grossProfit &&
                      orderData?.vehiclePrices &&
                      orderData?.vehiclePrices[0]?.amountNet > 0
                        ? formatCurrencyWithNoUnit(
                            (orderData?.calculationHeader?.grossProfit /
                              orderData?.vehiclePrices[0]?.amountNet) *
                              100
                          )
                        : "0,00"
                    } %`}
                  </PorscheFont>
                </td>
                <td></td>
                <td></td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      recalculationOrderData?.calculationHeader?.grossProfit
                        ? recalculationOrderData?.calculationHeader
                            ?.grossProfit!
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td className="table-td">
                  <PorscheFont>
                    {`${
                      recalculationOrderData?.calculationHeader?.grossProfit &&
                      recalculationOrderData?.vehiclePrices &&
                      recalculationOrderData?.vehiclePrices[0]?.amountNet > 0
                        ? formatCurrencyWithNoUnit(
                            (recalculationOrderData?.calculationHeader
                              ?.grossProfit /
                              recalculationOrderData?.vehiclePrices[0]
                                ?.amountNet) *
                              100
                          )
                        : "0,00"
                    } %`}
                  </PorscheFont>
                </td>
                <td></td>

                <td
                  className={checkBoldTextForDeviation(
                    recalculationOrderData?.calculationHeader?.grossProfit &&
                      orderData?.calculationHeader?.grossProfit &&
                      orderData?.vehiclePrices &&
                      recalculationOrderData?.vehiclePrices &&
                      recalculationOrderData?.vehiclePrices[0]?.amountNet > 0
                      ? (recalculationOrderData?.calculationHeader
                          ?.grossProfit /
                          recalculationOrderData?.vehiclePrices[0]?.amountNet) *
                          100 -
                          (orderData?.calculationHeader?.grossProfit /
                            orderData?.vehiclePrices[0]?.amountNet) *
                            100
                      : 0
                  )}
                >
                  <PorscheFont>
                    {`${
                      recalculationOrderData?.calculationHeader?.grossProfit &&
                      orderData?.calculationHeader?.grossProfit &&
                      orderData?.vehiclePrices &&
                      recalculationOrderData?.vehiclePrices &&
                      recalculationOrderData?.vehiclePrices[0]?.amountNet > 0
                        ? formatCurrencyWithNoUnit(
                            (recalculationOrderData?.calculationHeader
                              ?.grossProfit /
                              recalculationOrderData?.vehiclePrices[0]
                                ?.amountNet) *
                              100 -
                              (orderData?.calculationHeader?.grossProfit /
                                orderData?.vehiclePrices[0]?.amountNet) *
                                100
                          )
                        : "0,00"
                    } %`}
                  </PorscheFont>
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="table-td-left">
                  <PorscheFont>{t("Standing days")}</PorscheFont>
                </td>
                <td className="table-td"></td>
                <td className="table-td"></td>
                <td className="table-td">
                  <PorscheFont>
                    {orderData?.onlineCarSaleInfo?.standingDays
                      ? orderData?.onlineCarSaleInfo?.standingDays
                      : ""}
                  </PorscheFont>
                </td>
                <td className="table-td"></td>
                <td className="table-td"></td>
                <td className="table-td"></td>
                <td className="table-td">
                  <PorscheFont>
                    <PorscheFont>
                      {recalculationOrderData?.onlineCarSaleInfo?.standingDays
                        ? recalculationOrderData?.onlineCarSaleInfo
                            ?.standingDays
                        : ""}
                    </PorscheFont>
                  </PorscheFont>
                </td>

                <td
                  className={checkBoldTextForDeviation(
                    orderData?.onlineCarSaleInfo?.standingDays &&
                      recalculationOrderData?.onlineCarSaleInfo?.standingDays
                      ? recalculationOrderData?.onlineCarSaleInfo
                          ?.standingDays -
                          orderData?.onlineCarSaleInfo?.standingDays
                      : 0
                  )}
                >
                  <PorscheFont>
                    {orderData?.onlineCarSaleInfo?.standingDays &&
                    recalculationOrderData?.onlineCarSaleInfo?.standingDays
                      ? recalculationOrderData?.onlineCarSaleInfo
                          ?.standingDays -
                        orderData?.onlineCarSaleInfo?.standingDays
                      : ""}
                  </PorscheFont>
                </td>
              </tr>
            </tbody>
            {/* Final Calculation end */}

            {/*  Calculation customer start */}
            <tbody>
              <tr>
                <td
                  colSpan={2}
                  className="table-col-bold table-th-border-bottom p-top"
                >
                  <PorscheFont>{t("Calculation customer")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Amount (net)")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Amount (gross)")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td"></td>
                <td className="table-th-border-bottom p-top table-td"></td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Amount (net)")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Amount (gross)")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td"></td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Deviation")}</PorscheFont>
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="table-td-left">
                  <PorscheFont>{t("Total invoice amount")}</PorscheFont>
                </td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      orderData?.calculationCustomer?.invoiceNet
                        ? orderData?.calculationCustomer?.invoiceNet
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      orderData?.calculationCustomer?.invoiceGross
                        ? orderData?.calculationCustomer?.invoiceGross
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td></td>
                <td></td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      recalculationOrderData?.calculationCustomer?.invoiceNet
                        ? recalculationOrderData?.calculationCustomer
                            ?.invoiceNet!
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      recalculationOrderData?.calculationCustomer?.invoiceGross
                        ? recalculationOrderData?.calculationCustomer
                            ?.invoiceGross!
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td></td>

                <td
                  className={checkBoldTextForDeviation(
                    orderData?.calculationCustomer?.invoiceNet &&
                      recalculationOrderData?.calculationCustomer?.invoiceNet
                      ? recalculationOrderData?.calculationCustomer
                          ?.invoiceNet! -
                          orderData?.calculationCustomer?.invoiceNet
                      : 0
                  )}
                >
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      orderData?.calculationCustomer?.invoiceNet &&
                        recalculationOrderData?.calculationCustomer?.invoiceNet
                        ? recalculationOrderData?.calculationCustomer
                            ?.invoiceNet! -
                            orderData?.calculationCustomer?.invoiceNet
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="table-td-left">
                  <PorscheFont>{t("Pre-delivery payment")}</PorscheFont>
                </td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      orderData?.calculationCustomer?.preDeliveryNet
                        ? orderData?.calculationCustomer?.preDeliveryNet
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      orderData?.calculationCustomer?.preDeliveryGross
                        ? orderData?.calculationCustomer?.preDeliveryGross
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td></td>
                <td></td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      recalculationOrderData?.calculationCustomer
                        ?.preDeliveryNet
                        ? recalculationOrderData?.calculationCustomer
                            ?.preDeliveryNet!
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      recalculationOrderData?.calculationCustomer
                        ?.preDeliveryGross
                        ? recalculationOrderData?.calculationCustomer
                            ?.preDeliveryGross!
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td></td>
                <td
                  className={checkBoldTextForDeviation(
                    orderData?.calculationCustomer?.preDeliveryNet &&
                      recalculationOrderData?.calculationCustomer
                        ?.preDeliveryNet
                      ? recalculationOrderData?.calculationCustomer
                          ?.preDeliveryNet! -
                          orderData?.calculationCustomer?.preDeliveryNet
                      : 0
                  )}
                >
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      orderData?.calculationCustomer?.preDeliveryNet &&
                        recalculationOrderData?.calculationCustomer
                          ?.preDeliveryNet
                        ? recalculationOrderData?.calculationCustomer
                            ?.preDeliveryNet! -
                            orderData?.calculationCustomer?.preDeliveryNet
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="table-td-left">
                  <PorscheFont>{t("Financing amount")}</PorscheFont>
                </td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      orderData?.calculationCustomer?.paymentNet
                        ? orderData?.calculationCustomer?.paymentNet
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      orderData?.calculationCustomer?.paymentGross
                        ? orderData?.calculationCustomer?.paymentGross
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td></td>
                <td></td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      recalculationOrderData?.calculationCustomer?.paymentNet
                        ? recalculationOrderData?.calculationCustomer
                            ?.paymentNet
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      recalculationOrderData?.calculationCustomer?.paymentGross
                        ? recalculationOrderData?.calculationCustomer
                            ?.paymentGross
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td></td>

                <td
                  className={checkBoldTextForDeviation(
                    orderData?.calculationCustomer?.paymentNet &&
                      recalculationOrderData?.calculationCustomer?.paymentNet
                      ? recalculationOrderData?.calculationCustomer
                          ?.paymentNet -
                          orderData?.calculationCustomer?.paymentNet
                      : 0
                  )}
                >
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      orderData?.calculationCustomer?.paymentNet &&
                        recalculationOrderData?.calculationCustomer?.paymentNet
                        ? recalculationOrderData?.calculationCustomer
                            ?.paymentNet -
                            orderData?.calculationCustomer?.paymentNet
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
              </tr>
            </tbody>
            {/*  Calculation customer end */}

            {/* Commission calculation  In Start */}
            <tbody>
              <tr>
                <td
                  colSpan={2}
                  className="table-col-bold table-th-border-bottom p-top"
                >
                  <PorscheFont>{t("Commission calculation")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Amount in €")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td"></td>
                <td className="table-th-border-bottom p-top table-td"></td>
                <td className="table-th-border-bottom p-top table-td"></td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Amount in €")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td"></td>
                <td className="table-th-border-bottom p-top table-td"></td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Deviation")}</PorscheFont>
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="table-td-left">
                  <PorscheFont>{t("Vehicle commission")}</PorscheFont>
                </td>
                <td className="table-td">
                  <PorscheFont>
                    {`${ orderData?.commissionCalculation?.vehicleCommissionEuro && formatCurrencyWithNoUnit(orderData?.commissionCalculation?.vehicleCommissionEuro >0? orderData?.commissionCalculation?.vehicleCommissionEuro : 0 )} €`}
                  </PorscheFont>
                </td>
                <td className="table-td"></td>
                <td></td>
                <td></td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      recalculationOrderData?.commissionCalculation
                        ?.vehicleCommissionEuro
                        ? negativeValueHandler(recalculationOrderData?.commissionCalculation
                          ?.vehicleCommissionEuro) 
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td className="table-td"></td>
                <td></td>
                <td
                  className={checkBoldTextForDeviation(
                    (recalculationOrderData?.commissionCalculation
                      ?.vehicleCommissionEuro ?? 0) -
                      (orderData?.commissionCalculation
                        ?.vehicleCommissionEuro ?? 0)
                  )}
                >
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      (recalculationOrderData?.commissionCalculation
                        ?.vehicleCommissionEuro ?? 0) -
                        (orderData?.commissionCalculation
                          ?.vehicleCommissionEuro ?? 0)
                    )} €`}
                  </PorscheFont>
                </td>
              </tr>
              {/* <tr>
                <td colSpan={5} className='table-td-left'>
                  <PorscheFont>
                    {t("Standard: 9,00% of gross profit")}
                  </PorscheFont>
                </td>
                <td colSpan={5} className='table-td-left pl-220'>
                  <PorscheFont>
                    {t(" Standard: 13,00% of gross profit")}
                  </PorscheFont>
                </td>
                <td></td>
              </tr> */}
              <tr>
                <td colSpan={2} className="table-td-left">
                  <div style={{ display: "flex" }}>
                    <PorscheFont>{t("PFS commission (net)")}</PorscheFont>
                    {order?.paymentDetails?.leasing_s && (
                      <div
                        className="commission-flag-border"
                        style={{ marginLeft: "12px" }}
                      >
                        <PText size="x-small">S</PText>
                      </div>
                    )}
                    {order?.paymentDetails?.servicePlus && (
                      <div
                        className="commission-flag-border"
                        style={{ marginLeft: "8px" }}
                      >
                        <PText size="x-small">SP</PText>
                      </div>
                    )}
                  </div>
                </td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      orderData?.commissionCalculation?.pfsCommission
                        ? orderData?.commissionCalculation?.pfsCommission
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td className="table-td"></td>
                <td></td>
                <td></td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      recalculationOrderData?.commissionCalculation
                        ?.pfsCommission
                        ? recalculationOrderData?.commissionCalculation
                            ?.pfsCommission
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td className="table-td"></td>
                <td></td>
                <td
                  className={checkBoldTextForDeviation(
                    (recalculationOrderData?.commissionCalculation
                      ?.pfsCommission ?? 0) -
                      (orderData?.commissionCalculation?.pfsCommission ?? 0)
                  )}
                >
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      (recalculationOrderData?.commissionCalculation
                        ?.pfsCommission ?? 0) -
                        (orderData?.commissionCalculation?.pfsCommission ?? 0)
                    )} €`}
                  </PorscheFont>
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="table-td-left">
                  <PorscheFont>{t("Car Policy commission")}</PorscheFont>
                </td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      orderData?.commissionCalculation?.carPolicyCommission
                        ? orderData?.commissionCalculation?.carPolicyCommission
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td className="table-td"></td>
                <td></td>
                <td></td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      recalculationOrderData?.commissionCalculation
                        ?.carPolicyCommission
                        ? recalculationOrderData?.commissionCalculation
                            ?.carPolicyCommission
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td className="table-td"></td>
                <td></td>
                <td
                  className={checkBoldTextForDeviation(
                    (recalculationOrderData?.commissionCalculation
                      ?.carPolicyCommission ?? 0) -
                      (orderData?.commissionCalculation?.carPolicyCommission ??
                        0)
                  )}
                >
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      (recalculationOrderData?.commissionCalculation
                        ?.carPolicyCommission ?? 0) -
                        (orderData?.commissionCalculation
                          ?.carPolicyCommission ?? 0)
                    )} €`}
                  </PorscheFont>
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="table-td-left">
                  <PorscheFont>{t("Incentive / Bonus")}</PorscheFont>
                </td>
                <td className="table-td">
                  <PorscheFont>
                    <PorscheFont>
                      {`${formatCurrencyWithNoUnit(
                        orderData?.commissionCalculation?.incentive
                          ? orderData?.commissionCalculation?.incentive
                          : 0
                      )} €`}
                    </PorscheFont>
                  </PorscheFont>
                </td>
                <td className="table-td"></td>
                <td></td>
                <td></td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      recalculationOrderData?.commissionCalculation?.incentive
                        ? recalculationOrderData?.commissionCalculation
                            ?.incentive
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td className="table-td"></td>
                <td></td>
                <td
                  className={checkBoldTextForDeviation(
                    (recalculationOrderData?.commissionCalculation?.incentive ??
                      0) - (orderData?.commissionCalculation?.incentive ?? 0)
                  )}
                >
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      (recalculationOrderData?.commissionCalculation
                        ?.incentive ?? 0) -
                        (orderData?.commissionCalculation?.incentive ?? 0)
                    )} €`}
                  </PorscheFont>
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="table-td-left">
                  <PorscheFont>{t("Incentive Program")}</PorscheFont>
                </td>
                <td colSpan={8} className="table-td-left comment-col">
                  <PorscheFont>
                    {orderData?.commissionCalculation &&
                      orderData?.commissionCalculation?.incentiveProgram}
                  </PorscheFont>
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="table-td-left">
                  <PorscheFont>
                    {tequipmentCommission
                      ? t(`PDS/TEQ commission (included)`)
                      : t(`PDS/TEQ commission (not-included)`)}
                  </PorscheFont>
                </td>
                <td className="table-td">
                  <PorscheFont>
                    <PorscheFont>
                      {`${
                        formatCurrencyWithNoUnit(upsalseTotalCommission)
                      } €`}
                    </PorscheFont>
                  </PorscheFont>
                </td>
                <td className="table-td"></td>
                <td></td>
                <td></td>
                <td className="table-td">
                  <PorscheFont>
                    <PorscheFont>
                      {`${formatCurrencyWithNoUnit(
                        recalculationOrderData?.workshopCosts?.upsaleItems[1]?.commission ?? 0
                      )} €`}
                    </PorscheFont>
                  </PorscheFont>
                </td>
                <td className="table-td"></td>
                <td></td>

                <td
                  className={checkBoldTextForDeviation(
                    (recalculationOrderData?.workshopCosts?.upsaleItems[1]?.commission ?? 0) -
                      (orderData?.workshopCosts?.upsaleItems[1]?.commission ?? 0)
                  )}
                >
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      (recalculationOrderData?.workshopCosts?.upsaleItems[1]?.commission ?? 0) -
                        (orderData?.workshopCosts?.upsaleItems[1]?.commission ??
                          0)
                    )} €`}
                  </PorscheFont>
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="table-col-bold text-left">
                  <PorscheFont>{t("Total Commission")}</PorscheFont>
                </td>
                <td className="table-col-bold text-right">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      orderData?.commissionCalculation?.totalCommission
                        ? negativeValueHandler(orderData?.commissionCalculation?.totalCommission) 
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td></td>
                <td></td>
                <td></td>

                <td className="table-col-bold text-right">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      recalculationOrderData?.commissionCalculation
                        ?.totalCommission
                        ? negativeValueHandler(recalculationOrderData?.commissionCalculation
                          ?.totalCommission)
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td></td>
                <td></td>
                <td
                  className={checkBoldTextForDeviation(
                    (recalculationOrderData?.commissionCalculation
                      ?.totalCommission ?? 0) -
                      (orderData?.commissionCalculation?.totalCommission ?? 0)
                  )}
                >
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      (recalculationOrderData?.commissionCalculation
                        ?.totalCommission ?? 0) -
                        (orderData?.commissionCalculation?.totalCommission ?? 0)
                    )} €`}
                  </PorscheFont>
                </td>
              </tr>
            </tbody>

            {/* Commission calculation  In end */}

            {/* Commission sharing Start */}

            <tbody>
              <tr>
                <td className="table-col-bold table-th-border-bottom p-top">
                  <PorscheFont>{t("Commission sharing")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top custom-header">
                  <PorscheFont>{t("Sellers involved")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Share in %")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Share in €")}</PorscheFont>
                </td>
                <td
                  colSpan={3}
                  className="table-th-border-bottom p-top table-td"
                ></td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Share in %")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Share in €")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Deviation")}</PorscheFont>
                </td>
              </tr>
              {/* {orderData?.commissionSharing?.map((ele, i) => {
                return (
                  <tr key={i}>
                    {i === 0 && <td className='table-td-left'>
                      <PorscheFont>
                        {t("Vehicle commission")}
                      </PorscheFont>
                    </td>}
                    {i === 1 && <td className='table-td-left'>
                      <PorscheFont>
                        {`${formatCurrencyWithNoUnit(orderData?.commissionCalculation?.vehicleCommissionEuro ? orderData?.commissionCalculation?.vehicleCommissionEuro : 0)} €`}
                      </PorscheFont>
                    </td>}
                    <td className='table-td-left' >
                      <PorscheFont>
                        {ele.sellerNames?.ppnName ? ele.sellerNames?.ppnName : "test"}
                      </PorscheFont>
                    </td>
                    <td className='table-td'>
                      <PorscheFont>
                        {`${formatCurrencyWithNoUnit(ele.shareInPercent)} %`}
                      </PorscheFont>
                    </td>
                    <td className='table-td'>
                      <PorscheFont>
                        {`${formatCurrencyWithNoUnit(ele.shareInEuro)} €`}
                      </PorscheFont>
                    </td>
                    <td colSpan={2} />
                    {i === 0 && <td className='table-td'>
                      <PorscheFont>
                        {t("Vehicle commission")}
                      </PorscheFont>
                    </td>}
                    {i === 1 && <td className='table-td'>
                      <PorscheFont>
                        {`${formatCurrencyWithNoUnit(recalculationOrderData?.commissionCalculation?.vehicleCommissionEuro ? recalculationOrderData?.commissionCalculation?.vehicleCommissionEuro : 0)} €`}
                      </PorscheFont>
                    </td>}
                    <td className='table-td'>
                      <PorscheFont>
                        {`${recalCommissionShares && formatCurrencyWithNoUnit(recalCommissionShares[i]?.shareInPercent ?? 0)} %`}
                      </PorscheFont>
                    </td>

                    <td className='table-td'>
                      <PorscheFont>
                        {`${recalCommissionShares && formatCurrencyWithNoUnit(recalCommissionShares[i]?.shareInEuro ?? 0)} €`}
                      </PorscheFont>
                    </td>
                    <td className={`table-${recalCommissionShares && recalCommissionShares[i]?.shareInEuro && recalCommissionShares[i]?.shareInEuro! - ele.shareInEuro > 0 ? 'col-bold text-right' : 'td'}`}>
                      <PorscheFont>
                        {`${recalCommissionShares && formatCurrencyWithNoUnit((recalCommissionShares[i]?.shareInEuro ?? 0) - (ele.shareInEuro ?? 0))} €`}
                      </PorscheFont>
                    </td>
                  </tr>
                )
              })} */}

              {orderData?.commissionSharing?.map((ele, i) => {
                return (
                  <tr key={i}>
                    {i === 0 && (
                      <td className="table-td-left">
                        <PorscheFont>{t("Vehicle commission")}</PorscheFont>
                      </td>
                    )}
                    {i === 1 && (
                      <td className="table-td-left">
                        <PorscheFont>
                          {`${formatCurrencyWithNoUnit(
                            orderData?.commissionCalculation
                              ?.vehicleCommissionEuro
                              ? negativeValueHandler(orderData?.commissionCalculation?.vehicleCommissionEuro) 
                              : 0
                          )} €`}
                        </PorscheFont>
                      </td>
                    )}
                    {i > 1 ? <td /> : null}
                    <td className="table-td-left">
                      <PorscheFont>
                        {ele.sellerNames?.ppnName
                          ? ele.sellerNames?.ppnName
                          : ""}
                      </PorscheFont>
                    </td>
                    <td className="table-td">
                      <PorscheFont>
                        {`${formatCurrencyWithNoUnit(ele.shareInPercent)} %`}
                      </PorscheFont>
                    </td>
                    <td className="table-td">
                      <PorscheFont>
                        {`${formatCurrencyWithNoUnit(ele.shareInEuro)} €`}
                      </PorscheFont>
                    </td>
                    <td colSpan={2} />
                    {i === 0 && (
                      <td className="table-td">
                        <PorscheFont>{t("Vehicle commission")}</PorscheFont>
                      </td>
                    )}
                    {i === 1 && (
                      <td className="table-td">
                        <PorscheFont>
                          {`${formatCurrencyWithNoUnit(
                            recalculationOrderData?.commissionCalculation
                              ?.vehicleCommissionEuro
                              ? negativeValueHandler(recalculationOrderData?.commissionCalculation?.vehicleCommissionEuro) 
                              : 0
                          )} €`}
                        </PorscheFont>
                      </td>
                    )}
                    {i > 1 ? <td /> : null}
                    <td className="table-td">
                      <PorscheFont>
                        {`${formatCurrencyWithNoUnit(
                          recalculationOrderData &&
                            recalculationOrderData?.commissionSharing
                            ? recalculationOrderData?.commissionSharing[i]
                                ?.shareInPercent!
                            : 0
                        )} %`}
                      </PorscheFont>
                    </td>
                    <td className="table-td">
                      <PorscheFont>
                        {`${formatCurrencyWithNoUnit(
                          recalculationOrderData &&
                            recalculationOrderData?.commissionSharing
                            ? recalculationOrderData?.commissionSharing[i]
                                ?.shareInEuro!
                            : 0
                        )} €`}
                      </PorscheFont>
                    </td>
                    <td
                      className={checkBoldTextForDeviation(
                        recalculationOrderData &&
                          recalculationOrderData?.commissionSharing
                          ? recalculationOrderData?.commissionSharing[i]
                              ?.shareInEuro! - ele.shareInEuro
                          : 0
                      )}
                    >
                      <PorscheFont>
                        {" "}
                        {`${formatCurrencyWithNoUnit(
                          recalculationOrderData &&
                            recalculationOrderData?.commissionSharing
                            ? recalculationOrderData?.commissionSharing[i]
                                ?.shareInEuro! - ele.shareInEuro
                            : 0
                        )} €`}
                      </PorscheFont>
                    </td>
                  </tr>
                );
              })}
            </tbody>
            {/* Commission sharing End */}
          </table>
        </div>
      </div>
    </>
  );
});
