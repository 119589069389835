import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Tooltip,
  TooltipProps,
  tooltipClasses
} from '@mui/material';
import { PButton, PButtonPure, PCheckboxWrapper, PFlex, PFlexItem, PGrid, PGridItem, PIcon, PPopover, PSelectWrapper, PSwitch, PText, PTextFieldWrapper } from '@porsche-design-system/components-react';

import { styled } from '@mui/material/styles';
import { PorscheFont } from '../../assets/styles/CustomStyles';
import { CommissionsOrderResponseDTO } from '../../interfaces/Commissions';
import { formatCurrencyWithNoUnit } from '../../mock/helper';
import { type } from '../../services/Constants';
import { dcsgetCommissionRecalc } from '../../services/data/Helpers';
import { FilterType } from "../FilterType";
import { checkEquleValue, filterDate, filterWithLikeValue, haveCommonElements, removeElementFromArray } from "../OrderFilter";
import "./recalculation.css";
import { SingleOrderDetailType } from '../../interfaces/OrderDetails';
import { Constant } from '../../configs/Constants';

interface Props {
   title?: string;
   component?: JSX.Element;
   handleToggle?: (show: boolean) => void;
   selected?: boolean;
   enableToggle?: boolean;
   disabled?: boolean;
 };
 
 const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
   <Tooltip {...props} classes={{ popper: className }} />
 ))(({ theme }) => ({
   [`& .${tooltipClasses.tooltip}`]: {
     backgroundColor: theme.palette.common.white,
     color: '#0000',
     boxShadow: theme.shadows[1],
     fontSize: 15,
     maxWidth: 500,
   },
 }));

export const Recalculation = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const [ordersRecalc, setOrdersRecalc] = useState<Array<CommissionsOrderResponseDTO>>([])
   //toggle
  const [show, setShow] = useState<boolean>(false);
  //search
  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState("");
  const { title, component, handleToggle, enableToggle, disabled } = props;
  //sorting
  const [order, setOrder] = useState("");
  //on Load Sort state
  const [onLoadOrder, setOnLoadOrder] = useState("RESET");
  //rrp state initial level
  const [rrpOrder, setRrpOrder] = useState<boolean>(false);
  //rrp state up
  const [rrpOrderUp, setRrpOrderUp] = useState<boolean>(false);
  //OR date
  const [OrDate, setOrDate] = useState<boolean>(false);
  const [OrDateUp, setOrDateUp] = useState<boolean>(false);
  //Actual CD
  const [actualCdDate, setActualCdDate] = useState<boolean>(false);
  const [actualCdDateUp, setActualCdDateUp] = useState<boolean>(false);
  //Planned date
  const [planDate, setplanDate] = useState<boolean>(false);
  const [planDateUp, setplanDateUp] = useState<boolean>(false);

  //discount state for only color hover handling
   //rrp dsc state initial level
   const [dscOrder, setDscOrder] = useState<boolean>(false);
   const [rrpOrderSecond, setRrrpOrderSecond] = useState<boolean>(false);
   //plan date
   const [planDateSecond, setPlanDateSecond] = useState<boolean>(false);
   //rrp dsc state up
   const [dscOrderUp, setDscOrderUp] = useState<boolean>(false);
  //counter
  const [count, setCount] = useState<number>(0);
  //OrDate
  const [orDateSecond, setOrDateSecond] = useState<boolean>(false);
   //counter OrDate and Planned CD
   const [orDateCount, setOrDateCount] = useState<number>(0);

  //for payment sorting state
  //sorting Payment
  const [orderPayment, setOrderPayment] = useState("");
  //on Load Sort state
  const [onPaymentLoadOrder, setOnPaymentLoadOrder] = useState("RESET");
  //payment state initial level
  const [paymentOrder, setPaymentOrder] = useState<boolean>(false);
  //payment state up
  const [paymentOrderUp, setPaymentOrderUp] = useState<boolean>(false);
  //payment
  const [paymentCount, setPaymentCount] = useState<number>(0);

   //for seller sorting state
  //sorting Payment
  const [orderSeller, setOrderSeller] = useState("");
  //on Load Sort state
  const [onSellerLoadOrder, setOnSellerLoadOrder] = useState("RESET");
  //payment state initial level
  const [sellerOrder, setSellerOrder] = useState<boolean>(false);
  //payment state up
  const [sellerOrderUp, setSellerOrderUp] = useState<boolean>(false);
  //payment
  const [sellerCount, setSellerCount] = useState<number>(0);
  //for Actual CD
  const [orderActualCd, setOrderActualCd] = useState("");
  const [sellerActualCd, setSellerActualCd] = useState<boolean>(false);
  const [sellerActualCdUp, setSellerActualCdUp] = useState<boolean>(false);
  const [actualCdCount, setActualCdCount] = useState<number>(0);

  //hover state
  const [isHoveringRrp, setIsHoveringRrp] = useState(false);
  const [isHoveringDisc, setIsHoveringDisc] = useState(false);
  const [isHoveringPymnt, setIsHoveringPymnt] = useState(false);
  const [isHoveringSellr, setIsHoveringSellr] = useState(false);
  const [isHoveringOrDate, setIsHoveringOrDate] = useState(false);
  const [isHoveringPlanCd, setIsHoveringPlanCd] = useState(false);
  const [isHoveringSellrActualCd, setIsHoveringSellrActualCd] = useState(false);

  //Enter state
  const [isEnteringRrp, setIsEnteringRrp] = useState(false);
  //Enter state Disc
  const [isEnteringDisc, setIsEnteringDisc] = useState(false);
  //Enter state Payment
  const [isEnteringPay, setIsEnteringPay] = useState(false);
  //Enter state status
  const [isEnteringSeller, setisEnteringSeller] = useState(false);
  //Entering OR date
  const [isEnteringOrDate, setIsEnteringOrDate] = useState(false);
  //Entering Plan date
  const [isEnteringPlan, setIsEnteringPlanDate] = useState(false);
   //filters state for Actual CD
   const [isEnteringActualCd, setIsEnteringActualCd] = useState(false);
   
   //Series Dropdown
 const [seriesDropdown, setSelectSeriesDropdown] = useState<string[]>(["Cayman","Boxster","911","Macan","Cayenne","Panamera","Taycan"]);
 //const [pfsDropdown, setSelectPfsDropdown] = useState<string[]>(["Leasing S","Service Plus","GAP","Car Policy","Porsche Card S"]);
 const [pfsDropdown, setSelectPfsDropdown] = useState<string[]>(["Leasing S","Service Plus","GAP","Car Policy","Porsche card S"])

   //Planned Cd filter
 const [fromDatePlanCd, setFromDatePlanCd] = useState<string>('');
 const [toDatePlanCd, setToDatePlanCd] = useState<string>('');
 const [dateRangePlanCd, setDateRangePlanCd] = useState<string | any>("");
 const [disabledPlanDate, setDisabledPlanDate] = useState<boolean>(true);

 //Actual Cd filter
 const [fromDateActCd, setFromDateActCd] = useState<string>('');
 const [toDateActCd, setToDateActCd] = useState<string>('');
 const [dateRangeActCd, setDateRangeActCd] = useState<string | any>("");
 const [disabledActDate, setDisabledActDate] = useState<boolean>(true);

   const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
   const [filteredItems, setFilteredItems] = useState(ordersRecalc);
 
  // set the initial state (an array with 1 object to start (this can be an empty object to start))
  const [selectedLbl, setSelectedLbl] = useState<string[]>([]);
 

  //toggle label
//let toggleName:string = "Show filters";

//Test furkan
    const [porscheFilter, setPorscheFilter] = useState<string[]>([]);

useEffect(() => {
  setFilteredItems(
    ordersRecalc.filter(order => {
        return (
            (haveCommonElements(porscheFilter.filter(p => p.split(FilterType.SPLIT)[0] == FilterType.PORSCHECENTER),order.origin)) &&
            (filterWithLikeValue(porscheFilter.filter(p => p.split(FilterType.SPLIT)[0] == FilterType.VEHICLETYPE),order.vehicleCategory)) &&
            (filterDate(porscheFilter.filter(p => p.split(FilterType.SPLIT)[0] == FilterType.PLANNEDCD),order.plannedCD)) &&
            (filterDate(porscheFilter.filter(p => p.split(FilterType.SPLIT)[0] == FilterType.ACTUALCD),order.actualCD)) &&
            (filterWithLikeValue(porscheFilter.filter(p => p.split(FilterType.SPLIT)[0] == FilterType.SERIES),order.model)) &&
            (haveCommonElements(porscheFilter.filter(p => p.split(FilterType.SPLIT)[0] == FilterType.PAYMENT),order.payment)) &&
            (checkEquleValue(porscheFilter.filter(p => p.split(FilterType.SPLIT)[0] == FilterType.PFSEXTRAS),order.pfsExtras)) &&
            (haveCommonElements(porscheFilter.filter(p => p.split(FilterType.SPLIT)[0] == FilterType.EXPORT),order.exportRegion)) &&
            (haveCommonElements(porscheFilter.filter(p => p.split(FilterType.SPLIT)[0] == FilterType.SELLER),order.seller))

        )
    })
  )
},[porscheFilter]);
//Test Furkan

 //Drop-Down outside on - off logic basic on ref ----start
 const [clickedOutsidePorsche, setClickedOutsidePorsche] = useState(false);
 const [clickedOutsideSeries, setClickedOutsideSeriese] = useState(false);
 const [clickedOutsideCustType, setClickedOutsideCustType] = useState(false);
 const [clickedOutsideCustGrp, setClickedOutsideCustGrp] = useState(false);
 const [clickedOutsidePayment, setClickedOutsidePayment] = useState(false);
 const [clickedOutsidePfsExtra, setClickedOutsidePfsExtra] = useState(false);
 const [clickedOutsideDelivery, setClickedOutsideDelivery] = useState(false);
 const [clickedOutsideExport, setClickedOutsideExport] = useState(false);
 const [clickedOutsideStatus, setClickedOutsideStatus] = useState(false);
 const [clickedOutsideOrDate, setClickedOutsideOrDate] = useState(false);
 const [clickedOutsidePlannedCd, setClickedOutsidePlannedCd] = useState(false);
 const [clickedOutsideActualCd, setClickedOutsideActualCd] = useState(false);
 const myRef = useRef<HTMLInputElement>(null);
 const myRefSeries = useRef<HTMLInputElement>(null);
 const myRefCustType = useRef<HTMLInputElement>(null);
 const myRefCustGrp = useRef<HTMLInputElement>(null);
 const myRefCustPayment = useRef<HTMLInputElement>(null);
 const myRefCustPfsExtra = useRef<HTMLInputElement>(null);
 const myRefCustPfsDelivery = useRef<HTMLInputElement>(null);
 const myRefCustPfsExport = useRef<HTMLInputElement>(null);
 const myRefCustPfsStaus = useRef<HTMLInputElement>(null);
 const myRefOrDate = useRef<HTMLInputElement>(null);
 const myRefActualCd = useRef<HTMLInputElement>(null);
 const myRefPlanCd = useRef<HTMLInputElement>(null);

const handleClickInsidePorsche = () => {setClickedOutsidePorsche(!clickedOutsidePorsche);};
const handleClickInsideSeries = () => {setClickedOutsideSeriese(!clickedOutsideSeries);};
const handleClickInsideCustTyp = () => {setClickedOutsideCustType(!clickedOutsideCustType);};
const handleClickInsideCustGrp = () => {setClickedOutsideCustGrp(!clickedOutsideCustGrp);};
const handleClickInsidePaymnt = () => {setClickedOutsidePayment(!clickedOutsidePayment);};
const handleClickInsidePfsExtra = () => {setClickedOutsidePfsExtra(!clickedOutsidePfsExtra);};
const handleClickInsideDelivery = () => {setClickedOutsideDelivery(!clickedOutsideDelivery);};
const handleClickInsideExport = () => {setClickedOutsideExport(!clickedOutsideExport);};
const handleClickInsidePfsStatus = () => {setClickedOutsideStatus(!clickedOutsideStatus);};
const handleClickInsideOrDate = () => {setClickedOutsideOrDate(!clickedOutsideOrDate);};
const handleClickInsidePlanDate = () => {setClickedOutsidePlannedCd(!clickedOutsidePlannedCd);};
const handleClickInsideActualCd = () => {setClickedOutsideActualCd(!clickedOutsideActualCd);};

useEffect(() => {
  // Event listener to close the dropdown when clicking outside
  const handleClickOutside = (e: { target: any; }) => {
    if (!myRef?.current?.contains(e.target)) {
      setClickedOutsidePorsche(false);
    };
    if (!myRefSeries?.current?.contains(e.target)) {
      setClickedOutsideSeriese(false);
    };
    if (!myRefCustType?.current?.contains(e.target)) {
      setClickedOutsideCustType(false);
    };
    if (!myRefCustGrp?.current?.contains(e.target)) {
      setClickedOutsideCustGrp(false);
    };
    if (!myRefCustPayment?.current?.contains(e.target)) {
      setClickedOutsidePayment(false);
    };
    if (!myRefCustPfsExtra?.current?.contains(e.target)) {
      setClickedOutsidePfsExtra(false);
    };
    if (!myRefCustPfsDelivery?.current?.contains(e.target)) {
      setClickedOutsideDelivery(false);
    };
    if (!myRefCustPfsExport?.current?.contains(e.target)) {
      setClickedOutsideExport(false);
    };
    if (!myRefCustPfsStaus?.current?.contains(e.target)) {
      setClickedOutsideStatus(false);
    };
    if (!myRefOrDate?.current?.contains(e.target)) {
      setClickedOutsideOrDate(false);
    };
    if (!myRefActualCd?.current?.contains(e.target)) {
      setClickedOutsideActualCd(false);
    };
    if (!myRefPlanCd?.current?.contains(e.target)) {
      setClickedOutsidePlannedCd(false);
    };
  };

  // Attach the event listener when the component mounts
  document.addEventListener('mousedown', handleClickOutside);

  // Clean up the event listener when the component unmounts
  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}, []);

    const handleClick = () => {
        window.open("/#/order/create", "_blank");
    }
    
   const formatDate = (strDate: string): string => {
      if(!strDate) return "-";
      if(strDate.length === 0) return "-";
      //const date = new Date(strDate).toISOString().split('T')[0];
      const formatDate = strDate.split("-")
      return `${formatDate[2]}.${formatDate[1]}.${formatDate[0]}`;
   };

   useEffect(() => {
      const init = async () => {
        const apiResponse = await dcsgetCommissionRecalc()
         if(apiResponse.status===type.SUCCESS){
           setOrdersRecalc(apiResponse.response);
           setFilteredItems(apiResponse.response);
         }
      }
      init();
   }, []);

   useEffect(() => {
      if (enableToggle) setShow(enableToggle)
      // if (disabled) {
      //   setDisabledClassName("disabled")
      // }
    },[enableToggle]);

    //search function
  const handleSearch = async (search: string) => {
   setSearchValue(search);
   setSearchResult(search);
   filterData(search);
 };

 //Comment for now

// exclude column list from filter
const excludeColumns = ["vehicleCategory","model","plannedCD","actualCD","copNumber","payment","pfsExtras","exportRegion","seller","origin","commission","commissionRecalculation","incentive","incentiveRecalculation","totalCommission","rejectedInfo"];
 // filter records by search text
const filterData = (value:string) => {
  const lowercasedValue = value.toLowerCase().trim();
  if (lowercasedValue === "") setFilteredItems(ordersRecalc);
  else {
    const filteredData = filteredItems.filter(item => {
      return Object.keys(item).some(key =>
        excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(lowercasedValue)
      );
    });
    setFilteredItems(filteredData);
  }
}


//date of order Planned Cd
const handleFromDateChangePlanCd = (event: React.ChangeEvent<HTMLInputElement>) => {
  setFromDatePlanCd(event.target.value);
  //Button enable disable  validation
  const startDateValidationPlanCd = new Date(event.target.value);
  const endDateValidationPlanCd = new Date(toDatePlanCd);
  //alert(new Date(event.target.value));
  if (startDateValidationPlanCd <= endDateValidationPlanCd) {
    setDisabledPlanDate(false);
  }
  if (startDateValidationPlanCd > endDateValidationPlanCd) {
    setDisabledPlanDate(true);
  }
};

const handleToDateChangePlanCd = (event: React.ChangeEvent<HTMLInputElement>) => {
  setToDatePlanCd(event.target.value);
  //Button enable disable  validation
  const startDateValidationPlanCd = new Date(fromDatePlanCd);
  const endDateValidationPlanCd = new Date(event.target.value);
  if (startDateValidationPlanCd <= endDateValidationPlanCd) {
    setDisabledPlanDate(false);
  }
  if (startDateValidationPlanCd > endDateValidationPlanCd) {
    setDisabledPlanDate(true);
  }
};


//date of order Actual Cd
const handleFromDateChangeActCd = (event: React.ChangeEvent<HTMLInputElement>) => {
  setFromDateActCd(event.target.value);
  //Button enable disable  validation
  const startDateValidationActCd = new Date(event.target.value);
  const endDateValidationActCd = new Date(toDateActCd);
  //alert(new Date(event.target.value));
  if (startDateValidationActCd <= endDateValidationActCd) {
    setDisabledActDate(false);
  }
  if (startDateValidationActCd > endDateValidationActCd) {
    setDisabledActDate(true);
  }
};

const handleToDateChangeActCd = (event: React.ChangeEvent<HTMLInputElement>) => {
  setToDateActCd(event.target.value);
  //Button enable disable  validation
  const startDateValidationActCd = new Date(fromDateActCd);
  const endDateValidationActCd = new Date(event.target.value);
  if (startDateValidationActCd <= endDateValidationActCd) {
    setDisabledActDate(false);
  }
  if (startDateValidationActCd > endDateValidationActCd) {
    setDisabledActDate(true);
  }
};



const calculateDateRange = (str:any) => {
  if(str === "PlanCd"){
      filterChecked("Planned CD^"+formatDate(fromDatePlanCd)+' '+'-'+' '+formatDate(toDatePlanCd))
  }

  if(str === "ActualCd"){
      filterChecked("Actual CD^"+formatDate(fromDateActCd)+' '+'-'+' '+formatDate(toDateActCd))
    }
};
//date of order

 //Comment for now

 const SearchResult = (): JSX.Element => {
   if (searchResult.length === 0) {
     return <></>;
   }

   return (
     <ul className={`autocomplete ${searchResult.length > 3 }`}>
           <li style={{display:"flex"}}>
           <PText weight="bold" size="small">'{searchResult}'</PText>
           </li>
     </ul>
   );
 };

 //toggle
 const handleClickToggle = () => {
   if (handleToggle) handleToggle(!show);
   setShow(!show);
 }
 //mouse hover
 const handleMouseEnter = (col:string) => {
   if(col === "PlannedCd"){
     setIsHoveringOrDate(true);
   };
   if(col === "actualCd"){
     setIsHoveringPlanCd(true);
   };

   if(col === "payment"){
     setIsHoveringPymnt(true);
   };

   if(col === "seller"){
     setIsHoveringSellr(true);
   };

 };

 const handleMouseLeave = (col:string) => {
   if(col === "PlannedCd"){
     setIsHoveringOrDate(false);
   };
   if(col === "actualCd"){
     setIsHoveringPlanCd(false);
   };
   if(col === "payment"){
     setIsHoveringPymnt(false);
   };
   
   if(col === "seller"){
     setIsHoveringSellr(false);
   };
 };

 //sorting function
 const sorting = (col:string) => {

   if(col === "PlannedCd") {
    setActualCdCount(0);
    setPaymentCount(0);
    setSellerCount(0);
      //final reset state off
      setPaymentOrder(false);
      setActualCdDateUp(false);
      //OrDate down off
      setActualCdDate(false);
      //up arrow
    setPaymentOrderUp(false);
    setIsEnteringPay(false);
     setIsEnteringPlanDate(false);
     setIsHoveringOrDate(true);
     setIsEnteringOrDate(true);
     //payment color off
     setIsEnteringPay(false);
     //status off
     setisEnteringSeller(false);
     //alert("I am in RRP");
     setIsHoveringRrp(false);
     setIsHoveringDisc(false);
     //Enter Filter color  State
     setIsEnteringRrp(false);
     setIsEnteringDisc(false);
     //Payment off
     //final reset state off
     setPaymentOrder(false);
     //up arrow
     setPaymentOrderUp(false);
     //off payment
     //final reset state off
     setPaymentOrder(false);
     //up arrow
   setPaymentOrderUp(false);
   //Payment off
   //final reset state off
   setSellerOrder(false);
   setSellerOrderUp(false);
   setplanDate(false);
   setplanDateUp(false);
   setPaymentOrder(false);
       //up arrow
     setPaymentOrderUp(false);
     //final reset state off
     setSellerActualCd(false);
     setSellerActualCdUp(false);
     setIsEnteringActualCd(false);
     if(orDateCount == 0){
     const sorted = [...filteredItems].sort((a: any, b: any)=> +new Date(b?.plannedCD.split('-').reverse()) - +new Date(a?.plannedCD.split('-').reverse()));
     //Down arrow OrDate
     setOrDate(true);
     //down arrow down
     setOrDateUp(false);
     setFilteredItems(sorted);
     setOrder("DSC");
     //Up arrow false
     setRrpOrderUp(false);
     setOrDateCount(orDateCount + 1);
     //color code condition
     setRrrpOrderSecond(false);
     setOrDateSecond(true);
     };

     if(orDateCount == 1){
       const sorted = [...filteredItems].sort((a: any, b: any)=> +new Date(a?.plannedCD.split('-').reverse()) - +new Date(b?.plannedCD.split('-').reverse()));
       setFilteredItems(sorted);
       setOrder("ASC");
       //OrDate up on
       setOrDateUp(true);
       //OrDate down off
       setOrDate(false);
       //down arrow down
       setRrpOrder(false);
     //up arrow
     setRrpOrderUp(false);
     setOrDateCount(orDateCount + 1);
     //color code condition
     setRrrpOrderSecond(true);
     setOrDateSecond(true);
     };

     if(orDateCount == 2){
      setOrDateCount(0);
      if(porscheFilter.length > 0){
        const sortedDrpFilter = [...filteredItems].sort((a: any, b: any)=> +new Date(a?.plannedCD.split('-').reverse()) - +new Date(b?.plannedCD.split('-').reverse()));
        setFilteredItems(sortedDrpFilter);
      }else {
        setFilteredItems(ordersRecalc);
      }
      //setFilteredItems(ordersRecalc);
      //OrDate down off
     setOrDate(false);
     setOrDateUp(false);
      //down arrow down
        setRrpOrder(false);
        //up arrow
        setRrpOrderUp(false);
      //final reset state off
      //color code condition
    setOrDateSecond(false);
    //Enter Filter color  State off
    setIsEnteringOrDate(false);
    setIsEnteringPlanDate(false);
     setOrder("DSC");
     setRrrpOrderSecond(true);
     };
   };

   if(col === "actualCd") {
    setOrDateCount(0);
    setPaymentCount(0);
    setSellerCount(0);
      //final reset state off
      setPaymentOrder(false);
      setOrDate(false);
      setOrDateUp(false);
      //up arrow
    setPaymentOrderUp(false);
    setIsEnteringPay(false);
     setIsEnteringPlanDate(true);
     setIsEnteringOrDate(false);
     setIsHoveringPlanCd(true);
     //payment color off
     setIsEnteringPay(false);
     //status off
     setisEnteringSeller(false);
     //alert("I am in RRP");
     setIsHoveringRrp(false);
     setIsHoveringDisc(false);
     //Enter Filter color  State
     setIsEnteringRrp(false);
     setIsEnteringDisc(false);
     //Payment off
     //final reset state off
     setPaymentOrder(false);
     //up arrow
     setPaymentOrderUp(false);
     //off payment
     //final reset state off
     setPaymentOrder(false);
     //up arrow
   setPaymentOrderUp(false);
   //Payment off
   //final reset state off
   setSellerOrder(false);
   setSellerOrderUp(false);
   setOrDate(false);
   setOrDateUp(false);
   //final reset state off
   setSellerActualCd(false);
   setSellerActualCdUp(false);
   setIsEnteringActualCd(false);
     if(actualCdCount == 0){
     const sorted = [...filteredItems].sort((a: any, b: any)=> +new Date(b?.actualCD.split('-').reverse()) - +new Date(a?.actualCD.split('-').reverse()));
     setFilteredItems(sorted);
     //Down arrow OrDate
    setActualCdDate(true);
    setActualCdDateUp(false);
     //down arrow down
     setRrpOrder(false);
     setOrder("DSC");
     //Up arrow false
     setRrpOrderUp(false);
     setActualCdCount(actualCdCount + 1);
     //color code condition
     setPlanDateSecond(true);
     };

     if(actualCdCount == 1){
       const sorted = [...filteredItems].sort((a: any, b: any)=> +new Date(a?.actualCD.split('-').reverse()) - +new Date(b?.actualCD.split('-').reverse()));
       setFilteredItems(sorted);
       //OrDate up on
      setActualCdDateUp(true);
      //OrDate down off
      setActualCdDate(false);
       setRrpOrder(false);
     setOrder("ASC");
     setActualCdCount(actualCdCount + 1);
     //color code condition
     setPlanDateSecond(true);
     };

     if(actualCdCount == 2){
      if(porscheFilter.length > 0){
        const sortedDrpFilter = [...filteredItems].sort((a: any, b: any)=> +new Date(a?.actualCD.split('-').reverse()) - +new Date(b?.actualCD.split('-').reverse()));
        setFilteredItems(sortedDrpFilter);
      }else {
        setFilteredItems(ordersRecalc);
      }
      //setFilteredItems(ordersRecalc);
       //OrDate up on
       setActualCdDateUp(false);
       //OrDate down off
       setActualCdDate(false);
       setOrder("DSC");
     //color code condition
     setPlanDateSecond(true);
     setIsEnteringPlanDate(false);
       //down arrow down
         setRrpOrder(false);
         //up arrow
         setRrpOrderUp(false);
       //final reset state off
       //color code condition
       setPlanDateSecond(false);
     //Enter Filter color  State off
     setIsEnteringRrp(false);
     setIsEnteringPlanDate(false)
     setIsEnteringOrDate(false);
     setActualCdCount(0);
     };

   };

   if(col === "payment") {
    setActualCdDateUp(false);
       //OrDate down off
       setActualCdDate(false);
      //final reset off
      //OrDate up on
      setActualCdCount(0);
      setOrDateCount(0);
      setSellerCount(0);
      setIsEnteringPlanDate(false)
      setIsEnteringOrDate(false);
      setIsEnteringRrp(false);
      setOrDateUp(false);
      setIsEnteringOrDate(false);
      //OrDate down off
      setOrDate(false);
     //final reset state off
     setSellerActualCd(false);
     setSellerActualCdUp(false);
     setIsEnteringActualCd(false);
     //payment off
     setIsEnteringDisc(false);
     //setOrders(orders);
     setIsEnteringPay(true);
     //RRP OFF
     //down arrow down
     setRrpOrder(false);
     //up arrow
     setRrpOrderUp(false);
     //final reset state off
     //color code condition
     setRrrpOrderSecond(false);
     //Enter Filter color  State off
     setIsEnteringRrp(false);
      //RRP OFF
      //final reset state off
      setSellerOrder(false);
      setSellerOrderUp(false);
      setisEnteringSeller(false);
     if(paymentCount == 0){
     const sorted = [...filteredItems].sort((a: any, b: any)=> a?.payment.toString().toLowerCase() > b?.payment.toString().toLowerCase() ? 1 : -1,);
     setFilteredItems(sorted);
     //down arrow down
     setPaymentOrder(true);
     setOrderPayment("DSC");
     //Up arrow false
     setPaymentOrderUp(false);
     setPaymentCount(paymentCount + 1);
     };
 
     if(paymentCount == 1){
       const sorted = [...filteredItems].sort((a: any, b: any)=> a?.payment.toString().toLowerCase() > b?.payment.toString().toLowerCase() ? -1 : 1,);
       setFilteredItems(sorted);
       //down arrow down
     setOrderPayment("ASC");
     //up arrow
     setPaymentOrderUp(true);
     setPaymentOrder(false);
     setPaymentCount(paymentCount + 1);
     };

     if(paymentCount == 2){
      setPaymentCount(0);
      if(porscheFilter.length > 0){
        const sortedDrpFilter = [...filteredItems].sort((a: any, b: any)=> a?.payment.toString().toLowerCase() > b?.payment.toString().toLowerCase() ? -1 : 1,);
        setFilteredItems(sortedDrpFilter);
      }else {
        setFilteredItems(ordersRecalc);
      }
      //setFilteredItems(ordersRecalc);
      //final reset state off
      setPaymentOrder(false);
      //up arrow
    setPaymentOrderUp(false);
    setIsEnteringPay(false);
     };
 
   };

   if(col === "seller") {
    setActualCdCount(0);
      setOrDateCount(0);
      setPaymentCount(0);
    setActualCdDateUp(false);
    //OrDate down off
    setActualCdDate(false);
      setOrDate(false);
      setOrDateUp(false);
      //final reset off
      //OrDate up on
      setIsEnteringPlanDate(false)
      setIsEnteringOrDate(false);
      setIsEnteringRrp(false);
      setOrDateUp(false);
      setIsEnteringOrDate(false);
     //final reset state off
     setSellerActualCd(false);
     setSellerActualCdUp(false);
     setIsEnteringActualCd(false);
     //final reset state off
     setPaymentOrder(false);
     //up arrow
   setPaymentOrderUp(false);
     //payment color off
     setIsEnteringPay(false);
    //payment off
    setIsEnteringDisc(false);
    //setOrders(orders);
    //RRP OFF
    setisEnteringSeller(true);
    //RRP OFF
    //down arrow down
    setRrpOrder(false);
    //up arrow
    setRrpOrderUp(false);
    //final reset state off
    //color code condition
    setRrrpOrderSecond(false);
    //Enter Filter color  State off
    setIsEnteringRrp(false);
     if(sellerCount == 0){
     const sorted = [...filteredItems].sort((a: any, b: any)=> a?.seller.toString().toLowerCase() > b?.seller.toString().toLowerCase() ? 1 : -1,);
     setFilteredItems(sorted);
     //down arrow down
     setSellerOrder(true);
     setOrderSeller("DSC");
     //Up arrow false
     setSellerOrderUp(false);
     setSellerCount(sellerCount + 1);
     };
 
     if(sellerCount == 1){
      const sorted = [...filteredItems].sort((a: any, b: any)=> a?.seller.toString().toLowerCase() > b?.seller.toString().toLowerCase() ? -1 : 1,);
       //down arrow down
       setSellerOrder(false);
       setFilteredItems(sorted);
     setOrderSeller("ASC");
     //up arrow
     setSellerOrderUp(true);
     setSellerCount(sellerCount + 1);
     }; 
     if(sellerCount == 2){
      if(porscheFilter.length > 0){
        const sortedDrpFilter = [...filteredItems].sort((a: any, b: any)=> a?.seller.toString().toLowerCase() > b?.seller.toString().toLowerCase() ? -1 : 1,);
        setFilteredItems(sortedDrpFilter);
      }else {
        setFilteredItems(ordersRecalc);
      }
      //setFilteredItems(ordersRecalc);
       setSellerCount(0);
       //final reset state off
       setSellerOrder(false);
       setSellerOrderUp(false);
       setisEnteringSeller(false);
     }
 
   };
};

// Filter Dropdown Data
const filterChecked = (checkedVal:string) => {
    const indexCheckVal = porscheFilter.indexOf(checkedVal);
    const checkbox = document.querySelector(`input[name="${checkedVal}"]`) as HTMLInputElement;

    if((checkbox !==null && checkbox.checked) && indexCheckVal === -1){
        setPorscheFilter([...porscheFilter,checkedVal])
    }

    if(indexCheckVal !== -1 && (checkedVal.split(FilterType.SPLIT)[0] !== (FilterType.PLANNEDCD || FilterType.ACTUALCD))){
        setPorscheFilter(removeElementFromArray(porscheFilter, checkedVal))
        checkbox.checked = false
    }

    if(checkedVal.split(FilterType.SPLIT)[0] === FilterType.PLANNEDCD){
        const orDateck = porscheFilter.filter((item) => item.startsWith(FilterType.PLANNEDCD));

        if(orDateck.length!==0){
            porscheFilter.splice(porscheFilter.indexOf(orDateck[0]),1)
            selectedFilters.splice(selectedFilters.indexOf(orDateck[0]),1)
        }
        setPorscheFilter([...porscheFilter,checkedVal])
    }

    if(checkedVal.split(FilterType.SPLIT)[0] === FilterType.ACTUALCD){
        const orDateck = porscheFilter.filter((item) => item.startsWith(FilterType.ACTUALCD));

        if(orDateck.length!==0){
            porscheFilter.splice(porscheFilter.indexOf(orDateck[0]),1)
            selectedFilters.splice(selectedFilters.indexOf(orDateck[0]),1)
        }
        setPorscheFilter([...porscheFilter,checkedVal])
    }

    if (selectedFilters.includes(checkedVal)) {
        let filters = selectedFilters.filter((el) => el !== checkedVal);
        setSelectedFilters(filters);
    } else {
        setSelectedFilters([...selectedFilters, checkedVal]);
    }
  };

    const filterCheckedRemove = (checkedVal:string) => {
        const indexCheckVal = porscheFilter.indexOf(checkedVal);

        if(indexCheckVal !== -1){
            setPorscheFilter(removeElementFromArray(porscheFilter, checkedVal))
            const checkbox = document.querySelector(`input[name="${checkedVal}"]`) as HTMLInputElement;
            if(checkbox != null){
                checkbox.checked = false
            }
        }

        if (selectedFilters.includes(checkedVal)) {
            let filters = selectedFilters.filter((el) => el !== checkedVal);
            setSelectedFilters(filters);
        } else {
            setSelectedFilters([...selectedFilters, checkedVal]);
        }
    };

const handleMouseLeaveFilterIcn = (lblFilter:any) => {
  const filterLbl = lblFilter;
  let spanLbl = document.getElementsByClassName(filterLbl);
  for (let i = 0; i < spanLbl.length; i++) {
    spanLbl[i].classList.add("active");
  }

};

const handleMouseFilterIcn = (lblFilter:any) => {
    const filterLbl = lblFilter;
  let spanLbl = document.getElementsByClassName(filterLbl);
  for (let i = 0; i < spanLbl.length; i++) {
    spanLbl[i].classList.remove("active");
  }
}
  

const FilterResult = (): JSX.Element => {
  if (selectedFilters.length === 0) {
    return <></>;
  }

  return (

    <div className="users" style={{display:"flex",flexWrap:"wrap",paddingBottom:"20px"}}>
        {selectedFilters.map((item,index) => (
          <div className="filter-val" style={{background:"#F2F2F2",width:"auto",height:"54px",paddingLeft:"15px",paddingRight:"44px",paddingTop:"5px",paddingBottom:"5px",margin:"0 5px 5px 0",cursor:"pointer"}} onMouseEnter={()=> handleMouseLeaveFilterIcn("close-car"+' '+index)} onMouseLeave={()=> handleMouseFilterIcn("close-car"+' '+index)}  onClick={(e) => filterCheckedRemove(item)}>
          <div className="user">
            <span style={{display:"block",color:"#626669",position:"relative",top:"2px"}}>{t(item.split("^")[0])}</span>
            <span style={{fontSize:"15px"}}>{item.split("^")[1] === "InReview" ? t('In Review') : t(item.split("^")[1]) }</span>
          </div>
          <div className="close-car-container" id="filterLabl" style={{position:"relative",cursor:"pointer",display:"flex",justifyContent:"end",left:"33px"}}>
          <span className={"close-car"+' '+index} onMouseEnter={()=> handleMouseLeaveFilterIcn("close-car"+' '+index)}  onClick={(e) => filterCheckedRemove(item)}>+</span>
          </div>
          </div>
        ))}
  </div>
  );
};


   const handleOrder = (id: number | undefined) => {
      if (id) {
         let singOrderDetails:SingleOrderDetailType = { id: id, mode:'view', draft: false };
         const newWindow=  window.open(`/#/order/create`, "_blank") 
         newWindow?.sessionStorage.setItem(Constant.SingleOrdDetail, JSON.stringify(singOrderDetails));
 
      }
   }

   const dispString = (str: string) => {
      let disStr = str;
      if (str.length > 15) {
         const strArray = str.substring(0, 15);
         disStr = str.substring(0, 15);
      }
      return disStr;
   }

   const getExportRegion = (str: string, i: number) => {
      const strArray = str.split(" ");
      if(strArray.length > 0 && i === 0) {
         return strArray[0];
      }

      if(strArray.length > 1 && i === 1) {
         strArray.splice(0,1);
         return strArray.join(" ");
      }

      return "";
   }
   return (
      <>
      <div className="pt10" style={{display:"flex", marginBottom:"16px"}}>
<PFlex>
          <PFlexItem>
            <PTextFieldWrapper label={`${t("search")}`} hideLabel={true} style={{ width: "440px" }}>
            <LightTooltip placement="top" title={<h1 style={{ color: "black", fontSize: "15px", fontWeight: "400", textAlign: "left", padding:"5px", borderRadius:0, textTransform: "none" }}>{t("Search for")} <b>{t("customer")}</b> {t("(name / company)")},<b>{t("commission number")}</b> {t("or")} <b>{t("act number.")}</b></h1>}>
              <input
                type="search"
                name="search"
                placeholder={`${t("Search")}`}
                onChange={(e) => handleSearch(e.target.value)}
                //onKeyDown={(e) => handleKeyDown(e)}
                value={searchValue}
                autoComplete="off"
                //disabled={disabled}
              />
              </LightTooltip>
            </PTextFieldWrapper>

            <SearchResult />
          </PFlexItem>
        </PFlex>
        {/* toggle */}
        <PSwitch
            checked={show}
            alignLabel="right"
            style={{paddingTop: "12px",marginLeft:"25px"}}
            onClick={handleClickToggle}
            //className={disabledClassName}
            disabled={disabled}
          >
            {/* {toggleName} */}
          {t("Show filters")}
          </PSwitch>
      </div>
      {show ?
<div style={{marginBottom:"5px"}}>  
<PGrid direction="row" className="example-grid pt15">
<PGridItem size={2}>

  <div className="multiselect" ref={myRef} >
    <div className="selectBox" onClick={handleClickInsidePorsche}>
    <PSelectWrapper dropdownDirection="down" id="porscheCenterWrapNewCar" label={`${t("Porsche Center")}`} hideLabel={false}>
      <select>
      <option hidden />
      </select>
      <div className="overSelect"></div>
      </PSelectWrapper>
    </div>
    <div id="checkboxes-used-car" className="checkbox-section"   style={{display: clickedOutsidePorsche ? 'block' : 'none'}}>
      <ul style={{overflow:"hidden",paddingTop:"10px",paddingBottom:"10px"}}>
      {Array.from(new Set(ordersRecalc?.map(obj => obj.origin))).map(period => {
        return (
          <>
           <li className="checkbox-wrapper">
      <PCheckboxWrapper  label={`${t(period)}`} hideLabel={false}>
            <input type="checkbox" name={"Porsche Center^"+period}  onClick={(e) => filterChecked("Porsche Center^"+period)}/>
      </PCheckboxWrapper>
          </li>
          </>
        );
    })}
      </ul>
    </div>
  </div>
</PGridItem>

<PGridItem size={2}>
      <div className="multiselect" ref={myRefCustType}>
    <div className="selectBox"   onClick={handleClickInsideCustTyp}>
    <PSelectWrapper dropdownDirection="down" id="porscheCenterWrapNewCar" label={`${t("Vehicle Type")}`} hideLabel={false}>
      <select>
      <option hidden />
      </select>
      <div className="overSelect"></div>
      </PSelectWrapper>
    </div>
    <div id="checkboxes-recalculation" className="checkbox-section"   style={{display: clickedOutsideCustType ? 'block' : 'none'}}>
      <ul style={{overflow:"hidden",paddingTop:"10px",paddingBottom:"10px"}}>
      {Array.from(new Set(ordersRecalc?.map(obj => obj.vehicleCategory))).map(period => {
        return (
          <>
           <li className="checkbox-wrapper">
      <PCheckboxWrapper  label={`${t(period)}`} hideLabel={false} style={{color:"red !important"}}>
            <input type="checkbox" name={"Vehicle Type^"+period}  onClick={(e) => filterChecked("Vehicle Type^"+period)} />
      </PCheckboxWrapper>
          </li>
          </>
        );
    })}
      </ul>
    </div>
  </div>
</PGridItem>

<PGridItem size={2}>
            <div className="multiselect" ref={myRefPlanCd}>
          <div className="selectBox"   onClick={handleClickInsidePlanDate}>
          <PSelectWrapper dropdownDirection="down" id="PlannedCDWrapNewCar" label={`${t("Planned CD")}`} hideLabel={false}>
            <select>
            <option hidden />
            </select>
            <div className="overSelect"></div>
            </PSelectWrapper>
          </div>
          <div id="checkboxes-new-car" className="checkbox-section"  style={{display: clickedOutsidePlannedCd ? 'block' : 'none' }}>
          <PGridItem size={3} style={{ marginTop: "-1px" }}>
            <PTextFieldWrapper
              id="dateOfPlan"
              label={`${t("From")}`}
              style={{padding:"5px",marginTop:"-20px"}}
            >
              <input
               id="startDatePlan"
               type="date"
               name="startDatePlan"
               max="9999-12-31"
               value={fromDatePlanCd}
               onChange={handleFromDateChangePlanCd}
              />
            </PTextFieldWrapper>


            <PTextFieldWrapper
              id="dateOfOrderWrap"
              label={`${t("To")}`}
              style={{padding:"5px"}}
            >
              <input
                id="endDatePlan"
                type="date"
                name="endDate"
                max="9999-12-31"
                value={toDatePlanCd}
                onChange={handleToDateChangePlanCd}
              />
            </PTextFieldWrapper>

            <div style={{padding:"5px",float:"right",marginBottom:"5px",marginTop:"5px"}}>
            <PButton onClick={()=>calculateDateRange("PlanCd")} disabled={disabledPlanDate}>{t("Apply")}</PButton>
            </div>
          </PGridItem>
          </div>
        </div>
</PGridItem>

<PGridItem size={2}>
            <div className="multiselect" ref={myRefActualCd}>
          <div className="selectBox"     onClick={handleClickInsideActualCd}>
          <PSelectWrapper dropdownDirection="down" id="ActualCDWrapNewCar" label={`${t("Actual CD")}`} hideLabel={false}>
            <select>
            <option hidden />
            </select>
            <div className="overSelect"></div>
            </PSelectWrapper>
          </div>
          <div id="checkboxes-new-car" className="checkbox-section"  style={{display: clickedOutsideActualCd ? 'block' : 'none' }}>
          <PGridItem size={3} style={{ marginTop: "-1px" }}>
            <PTextFieldWrapper
              id="dateOfOrderWrap"
              label={`${t("From")}`}
              style={{padding:"5px",marginTop:"-20px"}}
            >
              <input
               id="startDatePlan"
               type="date"
               name="startDatePlan"
               max="9999-12-31"
               value={fromDateActCd}
               onChange={handleFromDateChangeActCd}
              />
            </PTextFieldWrapper>


            <PTextFieldWrapper
              id="dateOfOrderWrap"
              label={`${t("To")}`}
              style={{padding:"5px"}}
            >
              <input
              id="endDatePlan"
              type="date"
              name="endDate"
              max="9999-12-31"
              value={toDateActCd}
              onChange={handleToDateChangeActCd}
              />
            </PTextFieldWrapper>

            <div style={{padding:"5px",float:"right",marginBottom:"5px",marginTop:"5px"}}>
            <PButton onClick={()=>calculateDateRange("ActualCd")} disabled={disabledActDate}>{t("Apply")}</PButton>
            </div>
          </PGridItem>
          </div>
        </div>
</PGridItem>

<PGridItem size={2}>
      <div className="multiselect" ref={myRefSeries}>
    <div className="selectBox"  onClick={handleClickInsideSeries}>
    <PSelectWrapper dropdownDirection="down" id="porscheCenterWrapNewCar" label={`${t("Series")}`} hideLabel={false}>
      <select>
      <option hidden />
      </select>
      <div className="overSelect"></div>
      </PSelectWrapper>
    </div>
    <div id="checkboxes-used-car" className="checkbox-section"   style={{display: clickedOutsideSeries ? 'block' : 'none'}}>
      <ul style={{overflow:"hidden",paddingTop:"10px",paddingBottom:"10px"}}>
      {seriesDropdown.map(period => {
        return (
          <>
           <li className="checkbox-wrapper">
      <PCheckboxWrapper  label={period} hideLabel={false} style={{color:"red !important"}}>
            <input type="checkbox" name={"Series^"+period} onClick={(e) => filterChecked("Series^"+period)} />
      </PCheckboxWrapper>
          </li>
          </>
        );
    })}
      </ul>
    </div>
  </div>
</PGridItem>

<PGridItem size={2}>
      <div className="multiselect" ref={myRefCustPayment}>
    <div className="selectBox"  onClick={handleClickInsidePaymnt}>
    <PSelectWrapper dropdownDirection="down" id="porscheCenterWrapNewCar" label={`${t("Payment")}`} hideLabel={false}>
      <select>
      <option hidden />
      </select>
      <div className="overSelect"></div>
      </PSelectWrapper>
    </div>
    <div id="checkboxes-used-car" className="checkbox-section"   style={{display: clickedOutsidePayment ? 'block' : 'none' }}>
      <ul style={{overflow:"hidden",paddingTop:"10px",paddingBottom:"10px"}}>
      {Array.from(new Set(ordersRecalc?.map(obj => obj.payment))).map(period => {
        return (
          <>
           <li className="checkbox-wrapper">
      <PCheckboxWrapper  label={`${t(period)}`} hideLabel={false} style={{color:"red !important"}}>
            <input type="checkbox" name={"Payment^"+period}  onClick={(e) => filterChecked("Payment^"+period)} />
      </PCheckboxWrapper>
          </li>
          </>
        );
    })}
      </ul>
    </div>
  </div>
</PGridItem>

</PGrid>

<PGrid direction="row" className="example-grid pt15" style={{paddingBottom:"30px"}}>
<PGridItem size={2}>

      <div className="multiselect" ref={myRefCustPfsExtra}>
    <div className="selectBox"  onClick={handleClickInsidePfsExtra}>
    <PSelectWrapper dropdownDirection="down" id="porscheCenterWrapNewCar" label={`${t("PFS Extras")}`} hideLabel={false}>
      <select>
      <option hidden />
      </select>
      <div className="overSelect"></div>
      </PSelectWrapper>
    </div>
    <div id="checkboxes-used-car" className="checkbox-section"   style={{display: clickedOutsidePfsExtra ? 'block' : 'none' }}>
      <ul style={{overflow:"hidden",paddingTop:"10px",paddingBottom:"10px"}}>
      {pfsDropdown.map(period => {
        return (
          <>
           <li className="checkbox-wrapper">
      <PCheckboxWrapper   label={`${t(period)}`} hideLabel={false} style={{color:"red !important"}}>
            <input type="checkbox" name={"PFS Extras^"+period}  onClick={(e) => filterChecked("PFS Extras^"+period)} />
      </PCheckboxWrapper>
          </li>
          </>
        );
    })}
      </ul>
    </div>
  </div>
</PGridItem>

<PGridItem size={2}>
      <div className="multiselect" ref={myRefCustPfsExport}>
    <div className="selectBox"   onClick={handleClickInsideExport}>
    <PSelectWrapper dropdownDirection="down" id="porscheCenterWrapNewCar" label={`${t("Export")}`} hideLabel={false}>
      <select>
      <option hidden />
      </select>
      <div className="overSelect"></div>
      </PSelectWrapper>
    </div>
    <div id="checkboxes-used-car" className="checkbox-section"  style={{display: clickedOutsideExport ? 'block' : 'none' }}>
      <ul style={{overflow:"hidden",paddingTop:"10px",paddingBottom:"10px"}}>
      {Array.from(new Set(ordersRecalc?.map(obj => obj.exportRegion))).map(period => {
        return (
          <>
           <li className="checkbox-wrapper">
      <PCheckboxWrapper  label={`${t(period)}`} hideLabel={false} style={{color:"red !important"}}>
            <input type="checkbox" name={"Export^"+period}  onClick={(e) => filterChecked("Export^"+period)} />
      </PCheckboxWrapper>
          </li>
          </>
        );
    })}
      </ul>
    </div>
  </div>
</PGridItem>

<PGridItem size={2}>
      <div className="multiselect" ref={myRefCustPfsStaus}>
    <div className="selectBox"     onClick={handleClickInsidePfsStatus}>
    <PSelectWrapper dropdownDirection="down" id="porscheCenterWrapNewCar" label={`${t("Seller")}`} hideLabel={false}>
      <select>
      <option hidden />
      </select>
      <div className="overSelect"></div>
      </PSelectWrapper>
    </div>
    <div id="checkboxes-recalculation" className="checkbox-section"  style={{display: clickedOutsideStatus ? 'block' : 'none' }}>
      <ul style={{overflow:"hidden",paddingTop:"10px",paddingBottom:"10px"}}>
      {Array.from(new Set(ordersRecalc?.map(obj => obj.seller))).map(period => {
        return (
          <>
           <li className="checkbox-wrapper">
      <PCheckboxWrapper  label={period} hideLabel={false} style={{color:"red !important"}}>
            <input type="checkbox" name={"Seller^"+period} onClick={(e) => filterChecked("Seller^"+period)}  />
      </PCheckboxWrapper>
          </li>
          </>
        );
    })}
      </ul>
    </div>
  </div>
</PGridItem>
</PGrid>
<FilterResult />
</div>
: null}
      <div>
         <TableContainer sx={ window.innerHeight>1000 ? { maxHeight: "780px" } : { maxHeight: "360px" }}>
           <Table stickyHeader aria-label="sticky table">
            <TableHead>
               <TableRow >
                  <TableCell className='cell-align-demo'> 
                  <PFlex direction={"column"}>
                     <PFlexItem className='inreview-text-top'><PorscheFont style={{fontWeight: "400"}}>{t("Vehicle type")}</PorscheFont></PFlexItem>
                     <PFlexItem className='inreview-text-bottom'><PorscheFont style={{fontWeight: "400"}}>{t('Model')}</PorscheFont></PFlexItem>
                  </PFlex>
                  </TableCell>
                  
                  <TableCell className='cell-align-demo'>
                  <PFlex direction={"column"}>
                     <PFlexItem className="inreview-text-top"><PorscheFont style={{fontWeight: "400"}}>{t("Comm No.")}</PorscheFont></PFlexItem>
                     <PFlexItem className="inreview-text-bottom"><PorscheFont style={{fontWeight: "400"}}>{t("Act No.")}</PorscheFont></PFlexItem>
                  </PFlex>
               </TableCell>
            
               <TableCell className='cell-align-demo'> 
                  <PFlex direction={"column"}>
                     <PFlexItem className="inreview-text-top" onClick={()=> sorting("PlannedCd")} style={{cursor:"pointer"}}><PorscheFont><span className='rrp-col' onMouseEnter={()=> handleMouseEnter("PlannedCd")} onMouseLeave={()=> handleMouseLeave("PlannedCd")} style={{color: isHoveringOrDate ? '#d5001b' : isEnteringOrDate ? '#d5001b' : ''}}>{t('Planned CD')}</span> {OrDateUp ? <span style={{position:"absolute"}}><span><PIcon name={"arrow-up"} /></span></span> : "" } {OrDate ? <span style={{position:"absolute",top:"5px"}}><PIcon name={"arrow-down"} /></span> : "" }</PorscheFont></PFlexItem>
                     <PFlexItem className="inreview-text-bottom" onClick={()=> sorting("actualCd")} style={{cursor:"pointer"}}><PorscheFont><span className='rrp-col' onMouseEnter={()=> handleMouseEnter("actualCd")} onMouseLeave={()=> handleMouseLeave("actualCd")} style={{color: isHoveringPlanCd ? '#d5001b' : isEnteringPlan ? '#d5001b' : ''}}>{t('Actual CD')}</span>{actualCdDateUp ? <span style={{position:"absolute"}}><span><PIcon name={"arrow-up"} /></span></span> : "" } {actualCdDate ? <span style={{position:"absolute"}}><PIcon name={"arrow-down"} /></span> : "" }</PorscheFont></PFlexItem>
                  </PFlex>
               </TableCell>
               
               <TableCell className='cell-align-demo'>
                  <PFlex direction={"column"}>
                     <PFlexItem className="inreview-text-top"><PorscheFont style={{fontWeight: "400"}}>{t('Customer')}</PorscheFont></PFlexItem>
                     <PFlexItem className="inreview-text-bottom"><PorscheFont style={{fontWeight: "400"}}>{t('C@P No.')}</PorscheFont></PFlexItem>
                  </PFlex>
               </TableCell>
               
               <TableCell className='cell-align-demo'>
                  <PFlex direction={"column"}>
                     <PFlexItem className="inreview-text-top" onClick={()=> sorting("payment")} style={{cursor:"pointer"}}><PorscheFont><span onMouseEnter={()=> handleMouseEnter("payment")} onMouseLeave={()=> handleMouseLeave("payment")} style={{color: isHoveringPymnt ? '#d5001b' : isEnteringPay ? '#d5001b' : ''}}>{t('Payment')}</span>{paymentOrder ? <span style={{position:"absolute",marginLeft:"5px"}}><PIcon name={"arrow-down"} /></span> : "" }  {paymentOrderUp ? <span style={{position:"absolute",marginLeft:"5px"}}><PIcon name={"arrow-up"} /></span> : "" }</PorscheFont></PFlexItem>
                     <PFlexItem className="inreview-text-bottom"><PorscheFont style={{fontWeight: "400"}}>PFS Extras</PorscheFont></PFlexItem>
                  </PFlex>
               </TableCell>
               
               <TableCell className='cell-align-demo'>
                  <PFlex direction={"column"}>
                     <PFlexItem className="inreview-text-top"><PorscheFont style={{fontWeight: "400"}}>{t("Export")}</PorscheFont></PFlexItem>
                     <PFlexItem className="inreview-text-top"><PorscheFont style={{fontWeight: "400"}}>{t("Region")}</PorscheFont></PFlexItem>
                  </PFlex>
               </TableCell>
               
               <TableCell className='cell-align-demo'>
                  <PFlex direction={"column"}>
                     <PFlexItem className="inreview-text-top" onClick={()=> sorting("seller")} style={{cursor:"pointer"}}><PorscheFont><span onMouseEnter={()=> handleMouseEnter("seller")} onMouseLeave={()=> handleMouseLeave("seller")} style={{color: isHoveringSellr ? '#d5001b' : isEnteringSeller? '#d5001b' : ''}}>{t('Seller')}</span>{sellerOrder ? <span style={{position:"absolute",marginLeft:"5px"}}><PIcon name={"arrow-down"} /></span> : "" }  {sellerOrderUp ? <span style={{position:"absolute",marginLeft:"5px"}}><PIcon name={"arrow-up"} /></span> : "" }</PorscheFont></PFlexItem>
                     <PFlexItem className="inreview-text-bottom"><PorscheFont style={{fontWeight: "400"}}>{t('Origin')}</PorscheFont></PFlexItem>
                  </PFlex>
               </TableCell>
            
               <TableCell className='cell-align-demo'>
                  <PFlex direction={"column"}>
                     <PFlexItem className="inreview-text-top"><PorscheFont style={{fontWeight: "400"}}>{t('Commission')}</PorscheFont></PFlexItem>
                     <PFlexItem className="inreview-text-bottom"><PorscheFont style={{fontWeight: "400"}}>{t('Recalculation')}</PorscheFont></PFlexItem>
                  </PFlex>
               </TableCell>
               
               <TableCell className='cell-align-demo'>
                  <PFlex direction={"column"}>
                     <PFlexItem className="inreview-text-top"><PorscheFont style={{fontWeight: "400"}}>Incentive</PorscheFont></PFlexItem>
                     <PFlexItem className="inreview-text-bottom"><PorscheFont style={{fontWeight: "400"}}>{t('Recalculation')}</PorscheFont></PFlexItem>
                  </PFlex>
               </TableCell>
               
               <TableCell className='cell-align-demo'>
                  <PFlex direction={"column"}>
                     <PFlexItem className="inreview-text-top"><PorscheFont style={{fontWeight: "400"}}>{t('Total')}</PorscheFont></PFlexItem>
                     <PFlexItem className="inreview-text-top"><PorscheFont style={{fontWeight: "400"}}>{t('Commission')}</PorscheFont></PFlexItem>
                  </PFlex>
               </TableCell>
               
               <TableCell className='cell-align-demo'>
                  <PFlex direction={"column"}>
                     <PFlexItem className="inreview-text-top"><PorscheFont style={{fontWeight: "400"}}>{t('Recalculation')}</PorscheFont></PFlexItem>
                     <PFlexItem className="inreview-text-bottom"><PorscheFont style={{fontWeight: "400"}}>{t('Date')}</PorscheFont></PFlexItem>
                  </PFlex>
               </TableCell> 
                                       
               <TableCell className='cell-align-demo'>
                  <PFlex direction={"column"}>
                     <PFlexItem className="inreview-text-top"><PorscheFont style={{fontWeight: "400"}}>{t("1st Approval")}</PorscheFont></PFlexItem>
                     <PFlexItem className="inreview-text-bottom"><PorscheFont style={{fontWeight: "400"}}>{t('Date')}</PorscheFont></PFlexItem>
                  </PFlex>
               </TableCell>  
               <TableCell className='cell-align-demo'>
                  <PFlex direction={"column"}>
                     <PFlexItem className="inreview-text-top"><PorscheFont style={{fontWeight: "400"}}>{t("2nd Approval")}</PorscheFont></PFlexItem>
                     <PFlexItem className="inreview-text-bottom"><PorscheFont style={{fontWeight: "400"}}>{t('Date')}</PorscheFont></PFlexItem>
                  </PFlex>
               </TableCell>
               <TableCell>
               </TableCell>  
               <TableCell>
               </TableCell>            
               </TableRow>
            </TableHead>
            <TableBody>         
               {filteredItems.length > 0 ? filteredItems.map((ordersRecalc, i) => {
                  let commissionDiff = ordersRecalc.commissionRecalculation && ordersRecalc.commissionRecalculation !== "-" ? parseFloat(ordersRecalc.commissionRecalculation) : 0.00;
                  let incentiveDiff = ordersRecalc.incentiveRecalculation && ordersRecalc.incentiveRecalculation !== "-" ? parseFloat(ordersRecalc.incentiveRecalculation) : 0.00; 
               return (
               <TableRow hover role="checkbox" tabIndex={-4}>  
               <TableCell className='cell-align-demo'>
                  <PFlex direction={"column"} style={{marginTop: "8px", marginBottom: "8px"}}>
                     <PFlexItem className="inreview-text-top"><PorscheFont style={{fontWeight: "400"}}>{ordersRecalc.vehicleCategory ? t(ordersRecalc.vehicleCategory) : "-"}</PorscheFont></PFlexItem>
                     <PFlexItem className="inreview-text-bottom" style={{ marginRight: ordersRecalc.model && ordersRecalc.model.length > 17 ? '15px' : '0' }}><PorscheFont style={{fontWeight: "400"}}>{ordersRecalc.model !== undefined && ordersRecalc.model.length > 0
                        ? (
                        <PorscheFont>{t(ordersRecalc.model)}</PorscheFont>
                        ) : "-"
                     }</PorscheFont></PFlexItem>
                  </PFlex>
               </TableCell>  
                  <TableCell className='cell-align-demo'>
                     <PFlex direction={"column"}>
                        <PFlexItem className="inreview-text-top"><PorscheFont style={{fontWeight: "400"}}>{ordersRecalc.commissionNumber}</PorscheFont></PFlexItem>
                        <PFlexItem className="inreview-text-bottom"><PorscheFont style={{fontWeight: "400"}}>{ordersRecalc.actNumber}</PorscheFont></PFlexItem>
                     </PFlex>
                  </TableCell> 
               <TableCell className='cell-align-demo'>
                  <PFlex direction={"column"}>
                     <PFlexItem className="inreview-text-top"><PorscheFont style={{fontWeight: "400"}}>
                        {ordersRecalc.plannedCD !== undefined && ordersRecalc.plannedCD.length > 0 ? ordersRecalc.plannedCD.replaceAll("-", ".") : "-"}
                     </PorscheFont></PFlexItem>
                     <PFlexItem className="inreview-text-bottom"><PorscheFont style={{fontWeight: "400"}}>
                        {ordersRecalc.actualCD !== undefined && ordersRecalc.actualCD.length > 0 ? ordersRecalc.actualCD.replaceAll("-", ".") : "-"}
                     </PorscheFont></PFlexItem>
                  </PFlex>
               </TableCell> 
               <TableCell className='cell-align-demo'>
                  <PFlex direction={"column"}>
                     <PFlexItem className="inreview-text-top"><PorscheFont style={{fontWeight: "400"}}>{ordersRecalc.customer}</PorscheFont></PFlexItem>
                     <PFlexItem className="inreview-text-bottom"><PorscheFont style={{fontWeight: "400"}}>{ordersRecalc.copNumber}</PorscheFont></PFlexItem>
                  </PFlex>
               </TableCell> 
               <TableCell className='cell-align-demo'>                
               <PFlex direction={"column"}>
                  <PFlexItem className="inreview-text-top">{ordersRecalc.payment ? t(ordersRecalc.payment) : "-"}</PFlexItem>
                  <PFlexItem className="inreview-text-bottom"><PorscheFont style={{fontWeight: "400"}}>{ordersRecalc.pfsExtras}</PorscheFont></PFlexItem>
               </PFlex>
               </TableCell>  
               <TableCell className='cell-align-demo'>
                  <PFlex direction={"column"}>
                     <PFlexItem className="inreview-text-top"><PorscheFont style={{fontWeight: "400"}}>{ordersRecalc.exportRegion && ordersRecalc.exportRegion !== "-" ? getExportRegion(t(ordersRecalc.exportRegion), 0) : "-"}</PorscheFont></PFlexItem>
                     <PFlexItem className="inreview-text-bottom" style={{fontWeight: "400"}}>{ordersRecalc.exportRegion && ordersRecalc.exportRegion !== "-" ? getExportRegion(t(ordersRecalc.exportRegion), 1) : ""}</PFlexItem>
                  </PFlex>
               </TableCell> 
               <TableCell className='cell-align-demo'>
                  <PFlex direction={"column"}>
                     <PFlexItem className="inreview-text-top"><PorscheFont style={{fontWeight: "400"}}>{ordersRecalc.seller}</PorscheFont></PFlexItem>
                     <PFlexItem className="inreview-text-bottom"><PorscheFont style={{fontWeight: "400"}}>{ordersRecalc.origin}</PorscheFont></PFlexItem>
                  </PFlex>
               </TableCell>  
               <TableCell className="cell-align-demo" >
                  <PFlex direction={"column"}>
                     <PFlexItem className="inreview-text-top"><PorscheFont style={{fontWeight: "400"}}>{ordersRecalc.commission && ordersRecalc.commission !== "-" && ordersRecalc.commission !== "" ? formatCurrencyWithNoUnit(parseFloat(ordersRecalc.commission))+" €" : ordersRecalc.commission}</PorscheFont></PFlexItem>
                     <PFlexItem className="inreview-text-top">
                     { ordersRecalc.commissionRecalculation && (ordersRecalc.commissionRecalculation === "-" || ordersRecalc.commissionRecalculation === "") && (
                     <>{ordersRecalc.commissionRecalculation}</>
                     )}
                     { ordersRecalc.commissionRecalculation && ordersRecalc.commissionRecalculation !== "-" && ordersRecalc.commissionRecalculation !== "" && (
                     <PorscheFont style={{color : commissionDiff < 0 ? "#A90000": "#00950F"}}>
                     {commissionDiff < 0 ? "" : "+"}{formatCurrencyWithNoUnit(commissionDiff)}{" €"}
                     </PorscheFont>
                     )}
                </PFlexItem>
                  </PFlex>
               </TableCell> 
               <TableCell className='cell-align-demo'>
                  <PFlex direction={"column"}>
                     <PFlexItem className="inreview-text-top">
                        <PorscheFont style={{fontWeight: "400"}}>{ordersRecalc.incentive && ordersRecalc.incentive !== "-" && ordersRecalc.incentive !== "" ? formatCurrencyWithNoUnit(parseFloat(ordersRecalc.incentive))+" €" : ordersRecalc.incentive}</PorscheFont>
                     </PFlexItem>
                     <PFlexItem className="inreview-text-top">
                     { ordersRecalc.incentiveRecalculation && (ordersRecalc.incentiveRecalculation === "-" || ordersRecalc.incentiveRecalculation === "") && (
                        <>{ordersRecalc.incentiveRecalculation}</>
                     )}
                     { ordersRecalc.incentiveRecalculation && ordersRecalc.incentiveRecalculation !== "-" && ordersRecalc.incentiveRecalculation !== "" && (
                        <PorscheFont style={{color : incentiveDiff < 0 ? "#A90000": "#00950F"}}>
                           {incentiveDiff < 0 ? "" : "+"}{formatCurrencyWithNoUnit(incentiveDiff)}{" €"}
                        </PorscheFont>
                     )}
                     </PFlexItem>
                  </PFlex>
               </TableCell>
               <TableCell className='cell-align-demo'>
                  <PFlex direction={"column"}>
                     <PFlexItem className="inreview-text-top">
                        <PorscheFont style={{fontWeight: "400"}}>
                            {ordersRecalc.totalCommission && ordersRecalc.totalCommission !== "-" && ordersRecalc.totalCommission !== "" ? formatCurrencyWithNoUnit(parseFloat(ordersRecalc.totalCommission))+" €" : ordersRecalc.totalCommission}
                        </PorscheFont>
                     </PFlexItem>
                  </PFlex>
               </TableCell>              
               <TableCell className='cell-align-demo'>
                  <PFlex direction={"column"}>
                     <PFlexItem className="inreview-text-top">{"-"}</PFlexItem>
                  </PFlex>
               </TableCell>
               <TableCell className='cell-align-demo'>
                  <PFlex direction={"column"}>
                     <PFlexItem className="inreview-text-top">{"-"}</PFlexItem>
                  </PFlex>
               </TableCell>
               <TableCell className='cell-align-demo'>
                  <PFlex direction={"column"}>
                     <PFlexItem className="inreview-text-top">{"-"}</PFlexItem>
                  </PFlex>
               </TableCell>
               <TableCell className='cell-align-demo'>
                  <PFlex direction={"column"}>
                     {ordersRecalc.rejectedInfo && ordersRecalc.rejectedInfo !== "-" && (
                        <PFlexItem>
                           <PPopover direction={"left"} >
                              {ordersRecalc.rejectedInfo.replace("Rejected by", t("Rejected by"))}
                              {/* {"Rejected by Chris Exec-Officer: Please change the fixed commission as discussed in November"} */}
                           </PPopover>
                        </PFlexItem>
                     )}
                  </PFlex> 
               </TableCell>
               <TableCell className='cell-align-demo'>
                  <PFlex direction={"column"}>
                     <PFlexItem>
                        <PButtonPure hideLabel={true} onClick={() => handleOrder(ordersRecalc.id)}>
                        </PButtonPure>
                     </PFlexItem>
                  </PFlex> 
               </TableCell>

               </TableRow>
                  );
               }): <PText size="small" style={{whiteSpace:"nowrap",marginTop:"20px"}}>{t("No matching results.")}</PText>} 
            </TableBody>
           </Table>
         </TableContainer>     
      </div>
      </>
   );
}
