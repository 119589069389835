import i18next from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { Badge } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import {
  PDivider, PFlex, PFlexItem, PIcon, PMarque, PText
} from '@porsche-design-system/components-react';

import { Constants } from '../configs/Constants';
import { AuthContext } from '../contexts/AuthContext';
import { User, UserDetailsDTO } from '../interfaces/User';
import { type } from '../services/Constants';
import { dcsgetNotification, dcsgetUserWithPermissions } from '../services/data/Helpers';
import MenuButton from './common/MenuButton';

export const Header = (): JSX.Element => {
  const [user, setUser] = useState<User>({} as User);
  const [userDetails, setUserDetails] = useState<UserDetailsDTO>({} as UserDetailsDTO)
  const [ showMenuOptions, setShowMenuOptions ] = useState(false);
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const [ count, setCount] = useState(0);
  const { t } = useTranslation();

  const handleClick = (lang: string | undefined) => {
    i18next.changeLanguage(lang)
  }
  
  useEffect(() => {
    const init = async () => {
      let userDetails = await authCtx.getUserDetails();
      if(userDetails) {
        setUserDetails(userDetails);
      } else {
        const apiResponse = await dcsgetUserWithPermissions()
        if(apiResponse.status===type.SUCCESS){
          setUserDetails(apiResponse.response);
          authCtx.updateUserDetails(apiResponse.response);
        }  
      }
    };

    init();
  }, [])

  useEffect(() => {
    const init = async () => {
      const sessionUser = sessionStorage.getItem("userDetails");
      if(sessionUser) {
        const apiResponse = await dcsgetNotification()
        if(apiResponse.status===type.SUCCESS){
          apiResponse.response?.totalCount &&  setCount(apiResponse.response?.totalCount);
        }
      }
     
    };
    init();
  }, [userDetails]);

  useEffect(() => {}, [count]);

  const redirectToDashboard = () => {
    navigate("/");
  }

  const handleLogout = () => {
    //authCtx.logout();
    localStorage.clear();
    sessionStorage.clear();
    let url = `${Constants.domain}`;
    window.open(url, "_self");
  }
  
  return (
    <div className="header" style={{position:'fixed',zIndex:'9999'}}>
      <PFlex>
      <PFlexItem className="header-left"></PFlexItem>

        <PFlexItem className="header-middle">
          <PMarque className="logo" size="responsive" style={{ paddingTop: "5px" }}/>
        </PFlexItem>
        <PFlexItem className="header-right" shrink={0} style={{display:'flex' ,justifyContent: "right"}}>
          <Stack direction="row" spacing={1} className="mt20" >
            <PText style={{display:'flex'}}> 
              <button onClick={()=>handleClick('en')} > 
              {t("English")}
              </button>
              <PDivider orientation={"vertical"} style={{height: "16px", width:"2px", backgroundColor: "#909090", marginLeft:"4px", marginRight:"4px", marginTop: "16px"}} />
              <button onClick={()=>handleClick('de')} >
                {t("German")}
              </button>&nbsp;
            </PText>
            <Avatar src={require("../assets/images/Profile.png")} sx={{ width: 40, height: 40 }} />
            <div style={{marginRight: "0px"}}>
              <PText data-testid="username" size="small" weight="bold">
                {/* { user ? `${user.firstName} ${user.lastName}` : "" } */}
                {userDetails ? `${userDetails.ppnFirstName} ${userDetails.ppnLastName}` : "" }
              </PText>
              <PText data-testid="position" size="x-small">
                { userDetails && userDetails.jobTitle ? t(userDetails.jobTitle) : "" }
              </PText>
            </div>
            {/* <PIcon id="bell" className="bell-align bell" name="bell" size="medium" aria={{ 'aria-label': 'Bell icon' }} onClick={redirectToDashboard}/> */}
            <Badge badgeContent={count} color="error" overlap="circular" style={{marginBottom:"-30px"}}>
            <PIcon id="bell" className="bell-align bell"  name="bell" size="medium" aria={{ 'aria-label': 'Bell icon' }} onClick={redirectToDashboard}/>
            </Badge>
            <div className="dot-dropdown">
              <MenuButton handleLogout={handleLogout}/>
            </div>
          </Stack>
        </PFlexItem>
      </PFlex>
    </div>
  );
};
