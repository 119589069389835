import {  useContext,  useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { Stack } from '@mui/material';
import {PButton, PButtonGroup} from '@porsche-design-system/components-react';

import { PorscheFont } from '../../../assets/styles/CustomStyles';
import { OrderContext } from '../../../contexts/OrderContext';
import { SingleOrderDetailType } from '../../../interfaces/OrderDetails';
import { Constant } from '../../../configs/Constants';

interface Props {
    orderId: number,
    handleClose: () => void;
}

export const SubsequentExitConfirmationPopup = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { handleClose, orderId } = props;
    const [isError, setIsError] = useState(false);
    const [reload, setReload] = useState("");
    const orderContext = useContext(OrderContext);
 
    let loggedInUserId = 1;
    const sessionUser = sessionStorage.getItem("userDetails");
    if (sessionUser) {
        const user = JSON.parse(sessionUser);
        loggedInUserId = user.id;
    }

    const handleCancel = () => {
        handleClose();
    }
    const handleDiscard = () => {
        let order = orderContext.getCtxOrderDetails();
        if (order.id) {
        const singOrderDetails:SingleOrderDetailType = { id: order.id, mode:'view', draft: false, tab:'calculation' };
        const newWindow=  window.open(`/#/order/create`, "_self") 
        newWindow?.sessionStorage.setItem(Constant.SingleOrdDetail, JSON.stringify(singOrderDetails));
        }
    }

    return (
        <>
            <div style={{ height: "146px", width: "369px",margin:"-22px",paddingLeft:"10px" }}>
                <PorscheFont style={{fontWeight:"700",fontSize:"16px",lineHeight:"23.42px"}}>{t("Exit subsequent change?")}</PorscheFont><br></br>
                <PorscheFont style={{fontWeight:"400",fontSize:"16px",lineHeight:"23.42px",marginTop:"-16px"}}>{t("Unsaved changes will be discarded.")}</PorscheFont><br></br>
                <PButtonGroup style={{marginTop:"12px"}}>
                    <Stack direction="row" spacing={1}>
                    <PButton style={{fontWeight:"400",fontSize:"16px",lineHeight:"23.42px"}} variant="tertiary" icon='close' onClick={handleCancel}>{t("No, continue")}</PButton>
                    <PButton style={{fontWeight:"400",fontSize:"16px",lineHeight:"23.42px"}} variant="secondary" onClick={handleDiscard}>{t("Yes, exit subsequent")}</PButton>
                    </Stack>
                </PButtonGroup>
            </div>
        </>
    );
}
