import React, {
  useContext,
  useEffect,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";


import { OrderContext } from "../../../contexts/OrderContext";
import { CalculationDraftDTO } from "../../../interfaces/Calculation";
import { OrderDTO } from "../../../interfaces/Order";
import {
  dcsgetOrderDetails,
  dcsgetOrderSummary,
} from "../../../services/data/Helpers";
import { DemoCarPrint } from "./DemoCarPrint";
import { NewCarPrint } from "./NewCarPrint";
import { OrderDetailsPrint } from "./OrderDetailsPrint";
import { SummaryPrint } from "./SummaryPrint";
import { UpsalePrint } from "./UpsalePrint";
import { UsedCarPrint } from "./UsedCarPrint";
import { type } from "../../../services/Constants";
import getSessionStorage from "../../../helpers/getSessionStorage";
import { Constant } from "../../../configs/Constants";
import draftValueHandler from "../../../helpers/sessionDraftValueHandler";

export const MainPrint = React.forwardRef((props, ref: any) => {
  const { t } = useTranslation();
  const orderContext = useContext(OrderContext);
  const orderValues = getSessionStorage(Constant.SingleOrdDetail);

  const [category, setCategory] = useState("");
  const [order, setOrder] = useState<OrderDTO>({} as OrderDTO);
  const [searchParams, setSearchParams] = useSearchParams();
  const [orderData, setOrderData] = useState<CalculationDraftDTO>(
    {} as CalculationDraftDTO
  );
  const id = orderValues?.id ? orderValues?.id+'' :'' ;
  const draft = draftValueHandler(orderValues?.draft);
  // const { orderId, isDraft, order, handleClose } = props;
  useEffect(() => {
    const init = async () => {
      const apiResponse =
        draft === "true"
          ? await dcsgetOrderDetails(true, parseInt(id))
          : await dcsgetOrderSummary(parseInt(id));

          if(apiResponse.status===type.SUCCESS){
            let ord = orderContext.getCtxOrderDetails();
            setOrder(ord);
            if (ord?.vehicleDetails?.category?.type) {
              setCategory(ord?.vehicleDetails?.category?.type);
              let cdd = orderContext.getCtxCalculationDraftDetails(
                ord?.vehicleDetails?.category?.type
              );
              if (
                cdd.calculationPaymentDetails &&
                (ord?.paymentDetails?.paymentType !== undefined ||
                  ord?.paymentDetails?.paymentType !== null)
              ) {
                cdd.calculationPaymentDetails.paymentType =
                  ord?.paymentDetails?.paymentType;
              }
            }
            setOrderData(apiResponse.response?.calculation);
          }
      
    };
    init();
  }, []);
  return (
    <>
      <div ref={ref}>
        <div className="print-pages-wrapper">
          <OrderDetailsPrint />
        </div>
        <div className="print-pages-wrapper">
          <UpsalePrint />
        </div>
        <div className="print-pages-wrapper">
          {category === "NewCar" && <NewCarPrint />}
          {category === "DemoCar" && <DemoCarPrint />}
          {category === "UsedCar" && <UsedCarPrint />}
        </div>
        <div className="print-pages-wrapper">
          <SummaryPrint />
        </div>
      </div>
    </>
  );
});
