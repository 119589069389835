import React, { ChangeEvent, useEffect, useState,useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, tooltipClasses, TooltipProps,
} from '@mui/material';
import { PButtonPure, PFlex, PFlexItem, PTextFieldWrapper,PText, PGrid, PGridItem, PSelectWrapper, PSwitch, PIcon, PCheckboxWrapper  } from '@porsche-design-system/components-react';

import { PorscheFont } from '../../../assets/styles/CustomStyles';
import { OrderResponse, SingleOrderDetailType } from '../../../interfaces/OrderDetails';
import { formatCurrencyWithNoUnit, formatDate } from '../../../mock/helper';
import { dcsgetOrdersByCategory, getOrdersByCategory } from '../../../services/data/Helpers';
import { CustomStatus } from '../../common/CustomStatus';
import { styled } from '@mui/material/styles';
import "./testNewCar.css";
import {FilterType} from "../../FilterType";
import {haveCommonElements, filterWithLikeValue,checkEquleValue,filterDate,removeElementFromArray} from "../../OrderFilter";
import { type } from '../../../services/Constants';
import { Link, useNavigate } from 'react-router-dom';
import { Constant } from '../../../configs/Constants';

interface Props {
  title?: string;
  component?: JSX.Element;
  handleToggle?: (show: boolean) => void;
  selected?: boolean;
  enableToggle?: boolean;
  disabled?: boolean;
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: '#0000',
    boxShadow: theme.shadows[1],
    fontSize: 15,
    maxWidth: 500,
  },
}));

export const TestNewCar = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const [orders, setOrders] = useState<Array<OrderResponse>>([]);
  //toggle
  const [show, setShow] = useState<boolean>(false);
  //search
  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState("");
  const { title, component, handleToggle, enableToggle, disabled } = props;
  //sorting
  const [order, setOrder] = useState("");
  //rrp state initial level
  const [rrpOrder, setRrpOrder] = useState<boolean>(false);
  //disc state initial
  const [discOrder, setDiscOrder] = useState<boolean>(false);
  //Disc state up
  const [discOrderUp, setDiscOrderUp] = useState<boolean>(false);
  //rrp state up
  const [rrpOrderUp, setRrpOrderUp] = useState<boolean>(false);

  //discount state for only color hover handling
   //rrp dsc state initial level
   const [dscOrder, setDscOrder] = useState<boolean>(false);
   const [rrpOrderSecond, setRrrpOrderSecond] = useState<boolean>(false);
   //rrp dsc state up
   const [dscOrderUp, setDscOrderUp] = useState<boolean>(false);

   //Series Dropdown
   const [seriesDropdown, setSelectSeriesDropdown] = useState<string[]>(["Cayman","Boxster","911","Macan","Cayenne","Panamera","Taycan"]);
   const [pfsDropdown, setSelectPfsDropdown] = useState<string[]>(["Leasing S","Service Plus","GAP","Car Policy","Porsche card S"])

  //RRp counter
  const [countRrp, setCountRrp] = useState<number>(0);

  //Disc counter
  const [countDisc, setCountDisc] = useState<number>(0);

  //for payment sorting state
  //sorting Payment
  const [orderPayment, setOrderPayment] = useState("");
  //payment state initial level
  const [paymentOrder, setPaymentOrder] = useState<boolean>(false);
  //payment state up
  const [paymentOrderUp, setPaymentOrderUp] = useState<boolean>(false);
  //payment
  const [paymentCount, setPaymentCount] = useState<number>(0);

   //for seller sorting state
  //sorting Payment
  const [orderSeller, setOrderSeller] = useState("");
  //payment state initial level
  const [sellerOrder, setSellerOrder] = useState<boolean>(false);
  //payment state up
  const [sellerOrderUp, setSellerOrderUp] = useState<boolean>(false);
  //payment
  const [sellerCount, setSellerCount] = useState<number>(0);
  //hover state
  const [isHoveringRrp, setIsHoveringRrp] = useState(false);
  const [isHoveringDisc, setIsHoveringDisc] = useState(false);
  const [isHoveringPymnt, setIsHoveringPymnt] = useState(false);
  const [isHoveringSellr, setIsHoveringSellr] = useState(false);

  //Enter state
  const [isEnteringRrp, setIsEnteringRrp] = useState(false);
  //Enter state Disc
  const [isEnteringDisc, setIsEnteringDisc] = useState(false);
  //Enter state Payment
  const [isEnteringPay, setIsEnteringPay] = useState(false);
  //Enter state status
  const [isEnteringSeller, setisEnteringSeller] = useState(false);
  //filters state
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [filteredItems, setFilteredItems] = useState(orders);

 //Test furkan
  const [porscheFilter, setPorscheFilter] = useState<string[]>([]);
  useEffect(() => {
    setFilteredItems(
      orders.filter(order => {

          return (
            (haveCommonElements(porscheFilter.filter(p => p.split(FilterType.SPLIT)[0] == FilterType.ORDERSTATUS),order.status)) &&
            (haveCommonElements(porscheFilter.filter(p => p.split(FilterType.SPLIT)[0] == FilterType.EXPORT),order.exportRegion)) &&
            (haveCommonElements(porscheFilter.filter(p => p.split(FilterType.SPLIT)[0] == FilterType.DELIVERY),order.deliveryLocation)) &&
            (haveCommonElements(porscheFilter.filter(p => p.split(FilterType.SPLIT)[0] == FilterType.PAYMENT),order.payment)) &&
            (haveCommonElements(porscheFilter.filter(p => p.split(FilterType.SPLIT)[0] == FilterType.CUSTOMERGROUP),order.group)) &&
            (haveCommonElements(porscheFilter.filter(p => p.split(FilterType.SPLIT)[0] == FilterType.CUSTOMERTYPE),order.type)) &&
            (haveCommonElements(porscheFilter.filter(p => p.split(FilterType.SPLIT)[0] == FilterType.PORSCHECENTER),order.origin)) &&
            (filterWithLikeValue(porscheFilter.filter(p => p.split(FilterType.SPLIT)[0] == FilterType.SERIES),order.model)) &&
            (checkEquleValue(porscheFilter.filter(p => p.split(FilterType.SPLIT)[0] == FilterType.PFSEXTRAS),order.pfsExtrass))
          )
        })
      )
  },[porscheFilter]);

//Drop-Down outside on - off logic basic on ref ----start
const [clickedOutsidePorsche, setClickedOutsidePorsche] = useState(false);
const [clickedOutsideSeries, setClickedOutsideSeriese] = useState(false);
const [clickedOutsideCustType, setClickedOutsideCustType] = useState(false);
const [clickedOutsideCustGrp, setClickedOutsideCustGrp] = useState(false);
const [clickedOutsidePayment, setClickedOutsidePayment] = useState(false);
const [clickedOutsidePfsExtra, setClickedOutsidePfsExtra] = useState(false);
const [clickedOutsideDelivery, setClickedOutsideDelivery] = useState(false);
const [clickedOutsideExport, setClickedOutsideExport] = useState(false);
const [clickedOutsideStatus, setClickedOutsideStatus] = useState(false);
const myRef = useRef<HTMLInputElement>(null);
const myRefSeries = useRef<HTMLInputElement>(null);
const myRefCustType = useRef<HTMLInputElement>(null);
const myRefCustGrp = useRef<HTMLInputElement>(null);
const myRefCustPayment = useRef<HTMLInputElement>(null);
const myRefCustPfsExtra = useRef<HTMLInputElement>(null);
const myRefCustPfsDelivery = useRef<HTMLInputElement>(null);
const myRefCustPfsExport = useRef<HTMLInputElement>(null);
const myRefCustPfsStaus = useRef<HTMLInputElement>(null);


const handleClickInsidePorsche = () => {setClickedOutsidePorsche(!clickedOutsidePorsche);};
const handleClickInsideSeries = () => {setClickedOutsideSeriese(!clickedOutsideSeries);};
const handleClickInsideCustTyp = () => {setClickedOutsideCustType(!clickedOutsideCustType);};
const handleClickInsideCustGrp = () => {setClickedOutsideCustGrp(!clickedOutsideCustGrp);};
const handleClickInsidePaymnt = () => {setClickedOutsidePayment(!clickedOutsidePayment);};
const handleClickInsidePfsExtra = () => {setClickedOutsidePfsExtra(!clickedOutsidePfsExtra);};
const handleClickInsideDelivery = () => {setClickedOutsideDelivery(!clickedOutsideDelivery);};
const handleClickInsideExport = () => {setClickedOutsideExport(!clickedOutsideExport);};
const handleClickInsidePfsStatus = () => {setClickedOutsideStatus(!clickedOutsideStatus);};

//toggle label
//let toggleName:string = "Show filters";

useEffect(() => {
  // Event listener to close the dropdown when clicking outside
  const handleClickOutside = (e: { target: any; }) => {
    if (!myRef?.current?.contains(e.target)) {
      setClickedOutsidePorsche(false);
    }
    if (!myRefSeries?.current?.contains(e.target)) {
      setClickedOutsideSeriese(false);
    };
    if (!myRefCustType?.current?.contains(e.target)) {
      setClickedOutsideCustType(false);
    };
    if (!myRefCustGrp?.current?.contains(e.target)) {
      setClickedOutsideCustGrp(false);
    };
    if (!myRefCustPayment?.current?.contains(e.target)) {
      setClickedOutsidePayment(false);
    };
    if (!myRefCustPfsExtra?.current?.contains(e.target)) {
      setClickedOutsidePfsExtra(false);
    };
    if (!myRefCustPfsDelivery?.current?.contains(e.target)) {
      setClickedOutsideDelivery(false);
    };
    if (!myRefCustPfsExport?.current?.contains(e.target)) {
      setClickedOutsideExport(false);
    };
    if (!myRefCustPfsStaus?.current?.contains(e.target)) {
      setClickedOutsideStatus(false);
    };
    
  };

  // Attach the event listener when the component mounts
  document.addEventListener('mousedown', handleClickOutside);

  // Clean up the event listener when the component unmounts
  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}, []);

//Drop-Down outside on - off logic basic on ref ---end

  useEffect(() => {
    const init = async () => {
      const apiResponse = await dcsgetOrdersByCategory("NewCar")
      if(apiResponse.status === type.SUCCESS){
        setOrders(apiResponse.response);
        setFilteredItems(apiResponse.response);
      }
    }
    init();
  }, []);

  useEffect(() => {
    if (enableToggle) setShow(enableToggle)
    // if (disabled) {
    //   setDisabledClassName("disabled")
    // }
  },[enableToggle]);
  //search 
  //search function
  const handleSearch = async (search: any) => {
    setSearchValue(search);
    setSearchResult(search);
    filterData(search);
  };

  //test furkan
   // exclude column list from filter
   const excludeColumns = ["registerDate","plannedDate","model","rrp","licensePlate","vin","discount","copNumber","payment","pfsExtrass","deliveryLocation","exportRegion","status","seller","origin","customerDeliveryDate","saleType","type","group","commission"];
// filter records by search text
const filterData = (value: any) => {
  const lowercasedValue = value.toLowerCase().trim();
  if (lowercasedValue === "") setFilteredItems(orders);
  else {
    const filteredData = filteredItems.filter(item => {
      return Object.keys(item).some(key =>
        excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(lowercasedValue)
      );
    });
    setFilteredItems(filteredData);
  }
}

  const SearchResult = (): JSX.Element => {
    if (searchResult.length === 0) {
      return <></>;
    }

    return (
      <ul className={`autocomplete ${searchResult.length > 3 }`}>
            <li style={{display:"flex"}}>
            <PText weight="bold" size="small">'{searchResult}'</PText>
            </li>
      </ul>
    );
  };

  //toggle
  const handleClickToggle = () => {
    if (handleToggle) handleToggle(!show);
    setShow(!show);
  }
  //mouse hover
  const handleMouseEnter = (col:string) => {
    if(col === "rrp"){
      setIsHoveringRrp(true);
    };

    if(col === "discount"){
      setIsHoveringDisc(true)
    };

    if(col === "payment"){
      setIsHoveringPymnt(true);
    };

    if(col === "seller"){
      setIsHoveringSellr(true);
    };

  };

  const handleMouseLeave = (col:string) => {
    if(col === "rrp"){
      setIsHoveringRrp(false);
    };
    
    if(col === "discount"){
      setIsHoveringDisc(false);
    };

    if(col === "payment"){
      setIsHoveringPymnt(false);
    };
    
    if(col === "seller"){
      setIsHoveringSellr(false);
    };
  };

  //sorting function
  const sorting = (col:string) => {
    if(col === "rrp") {
      //Reset when switching sorting
      //setFilteredItems(orders);
      setCountDisc(0);
      setSellerCount(0);
      setPaymentCount(0);
      //Reset when switching sorting
      //payment color off
      setIsEnteringPay(false);
      //status off
      setisEnteringSeller(false);
      //alert("I am in RRP");
      setIsHoveringRrp(true);
      setIsHoveringDisc(false);
      //Enter Filter color  State
      setIsEnteringRrp(true);
      setIsEnteringDisc(false);
      //Payment off
      //final reset state off
      setPaymentOrder(false);
      //up arrow
      setPaymentOrderUp(false);
      //off payment
      //final reset state off
      setPaymentOrder(false);
      //up arrow
    setPaymentOrderUp(false);
    //Payment off
    //final reset state off
    setSellerOrder(false);
    setSellerOrderUp(false);
    setDiscOrder(false);
    setDiscOrderUp(false);

    if(countRrp == 0){
        const sorted = [...filteredItems].sort((a: any, b: any)=> a?.rrp - b?.rrp);
      //Up arrow false
      setRrpOrderUp(false);
      //down arrow down
      setRrpOrder(true);
      setFilteredItems(sorted);
      setOrder("DSC");
      //test
      setCountRrp(countRrp + 1);
      //alert(count);
      //color code condition
      setRrrpOrderSecond(true);
      };

      if(countRrp == 1){
        const sorted = [...filteredItems].sort((a: any, b: any)=> b?.rrp - a?.rrp);
        //down arrow down
      setRrpOrder(false);
        setFilteredItems(sorted);
      setOrder("ASC");
      //up arrow
      setRrpOrderUp(true);
      setCountRrp(countRrp + 1);
      //color code condition
      setRrrpOrderSecond(true);
      //alert(count);
      };

      if(countRrp == 2){
        if(porscheFilter.length > 0){
          const sortedDrpFilter = [...filteredItems].sort((a: any, b: any)=> b?.rrp - a?.rrp);
          setFilteredItems(sortedDrpFilter);
        }else {
          setFilteredItems(orders);
        }
      setOrder("DSC");
      setCountRrp(countRrp + 1);
      //alert(count);
      //color code condition
      setRrrpOrderSecond(true);
      setRrpOrderUp(false);
      setRrpOrder(false);
      //alert(count);
      //reset sorting
      //down arrow down
        setRrpOrder(false);
        //up arrow
        setRrpOrderUp(false);
      //final reset state off
      //color code condition
    setRrrpOrderSecond(false);
    //Enter Filter color  State off
    setIsEnteringRrp(false);
    setCountRrp(0);
      };
    };

    if(col === "discount") {
      //Reset when switching sorting
      //setFilteredItems(orders);
      setCountRrp(0);
      setPaymentCount(0);
      setSellerCount(0);
      //Reset when switching sorting
      setIsHoveringDisc(true)
      setIsHoveringRrp(false);
       //payment color off
       setIsEnteringPay(false);
       //status off
       setisEnteringSeller(false)
      //alert("I am in disc");
       //Enter Filter color  State off
       setIsEnteringRrp(false);
       setIsEnteringDisc(true);
       //off payment
      //final reset state off
      setPaymentOrder(false);
      //up arrow
    setPaymentOrderUp(false);
    //final reset state off
    setSellerOrder(false);
    setSellerOrderUp(false);
    //down arrow down
    setRrpOrder(false);
    //up arrow
    setRrpOrderUp(false);
    setDiscOrder(false);
    setDiscOrderUp(false);

    if(countDisc == 0){
      const sorted = [...filteredItems].sort((a: any, b: any)=>a?.discount -  b?.discount);
      //down arrow down
      setDiscOrder(true);
      //only dsc color hover state
      setDscOrder(true);
      setFilteredItems(sorted);
      setOrder("DSC");
      //Up arrow false
      setDiscOrderUp(false);
      //dsc color hover
      setDscOrderUp(false);
      setCountDisc(countDisc + 1);
      };
  
      if(countDisc == 1){
        const sorted = [...filteredItems].sort((a: any, b: any)=>b?.discount - a?.discount );
        //down arrow down
        setDiscOrder(false);
        //only dsc color hover state
        setDscOrder(false);
        setFilteredItems(sorted);
      setOrder("ASC");
      //up arrow
      setDiscOrderUp(true);
       //dsc color hover
       setDscOrderUp(true);
       setCountDisc(countDisc + 1);
      };
  
      if(countDisc === 2){
        //setCount(count + 0);
        //const sorted = [...filteredItems].sort((a: any, b: any)=> b?.rrp - a?.rrp);
        if(porscheFilter.length > 0){
          const sortedDrpFilter = [...filteredItems].sort((a: any, b: any)=>b?.discount - a?.discount );
          setFilteredItems(sortedDrpFilter);
        }else {
          setFilteredItems(orders);
        }
        //setFilteredItems(orders);
      setOrder("DSC");
      setCountDisc(countDisc + 1);
      //down arrow down
      setDiscOrder(false);
      //only dsc color hover state
      setDscOrder(false);
      //up arrow
      setDiscOrderUp(false);
       //dsc color hover
    setDscOrderUp(false);
    //final reset state off
    setIsEnteringDisc(false);
    setCountDisc(0);
      };
    };

    if(col === "payment") {
      //Reset when switching sorting
      //setFilteredItems(orders);
      setCountDisc(0);
      setCountRrp(0);
      setSellerCount(0);
      //Reset when switching sorting
      //payment off
      setIsEnteringDisc(false);
      //setOrders(orders);
      setIsEnteringPay(true);
      //RRP OFF
      //down arrow down
      setRrpOrder(false);
      //up arrow
      setRrpOrderUp(false);
      //final reset state off
      //color code condition
      setRrrpOrderSecond(false);
      //Enter Filter color  State off
      setIsEnteringRrp(false);
       //RRP OFF
       //final reset state off
       setSellerOrder(false);
       setSellerOrderUp(false);
       setisEnteringSeller(false);
       setDscOrder(false);
      //up arrow
      setDiscOrderUp(false);
      setDiscOrder(false);
      if(paymentCount == 0){
      const sorted = [...filteredItems].sort((a: any, b: any)=> a?.payment.toString().toLowerCase() > b?.payment.toString().toLowerCase() ? 1 : -1,);
      //down arrow down
      setPaymentOrder(true);
      setFilteredItems(sorted);
      setOrderPayment("DSC");
      //Up arrow false
      setPaymentOrderUp(false);
      setPaymentCount(paymentCount + 1);
      };
  
      if(paymentCount == 1){
        const sorted = [...filteredItems].sort((a: any, b: any)=> a?.payment.toString().toLowerCase() > b?.payment.toString().toLowerCase() ? -1 : 1,);
        //down arrow down
        setPaymentOrder(false);
        setFilteredItems(sorted);
      setOrderPayment("ASC");
      //up arrow
      setPaymentOrderUp(true);
      setPaymentCount(paymentCount + 1);
      };

      if(paymentCount == 2){
        //setCount(count + 0);
        if(porscheFilter.length > 0){
          const sortedDrpFilter = [...filteredItems].sort((a: any, b: any)=> a?.payment.toString().toLowerCase() > b?.payment.toString().toLowerCase() ? -1 : 1,);
          setFilteredItems(sortedDrpFilter);
        }else {
          setFilteredItems(orders);
        }
        //setFilteredItems(orders);
          //final reset state off
          setPaymentOrder(false);
          setOrderPayment("DSC");
          //up arrow
        setPaymentOrderUp(false);
        setIsEnteringPay(false);
        setPaymentCount(0);
      };
  
    };

    if(col === "seller") {
       //Reset when switching sorting
       //setFilteredItems(orders);
       setCountDisc(0);
       setCountRrp(0);
       setPaymentCount(0);
      setDscOrder(false);
      //up arrow
      setDiscOrderUp(false);
      //final reset state off
      setPaymentOrder(false);
      //up arrow
    setPaymentOrderUp(false);
      //payment color off
      setIsEnteringPay(false);
     //payment off
     setIsEnteringDisc(false);
     //setOrders(orders);
     //RRP OFF
     setisEnteringSeller(true);
     //RRP OFF
     //down arrow down
     setRrpOrder(false);
     //up arrow
     setRrpOrderUp(false);
     //final reset state off
     //color code condition
     setRrrpOrderSecond(false);
     //Enter Filter color  State off
     setIsEnteringRrp(false);
     //up arrow
     setDiscOrderUp(false);
     setDiscOrder(false);
      if(sellerCount == 0){
      const sorted = [...filteredItems].sort((a: any, b: any)=> a?.seller.toString().toLowerCase() > b?.seller.toString().toLowerCase() ? 1 : -1,);
      //down arrow down
      setSellerOrder(true);
      setFilteredItems(sorted);
      setOrderSeller("DSC");
      //Up arrow false
      setSellerOrderUp(false);
      setSellerCount(sellerCount + 1);
      };
  
      if(sellerCount == 1){
        const sorted = [...filteredItems].sort((a: any, b: any)=> a?.seller.toString().toLowerCase() > b?.seller.toString().toLowerCase() ? -1 : 1,);
        //down arrow down
        setFilteredItems(sorted);
        setSellerOrder(false);
      setOrderSeller("ASC");
      //up arrow
      setSellerOrderUp(true);
      setSellerCount(sellerCount + 1);
      };

      if(sellerCount == 2){
        //down arrow down
        if(porscheFilter.length > 0){
          const sortedDrpFilter = [...filteredItems].sort((a: any, b: any)=> a?.seller.toString().toLowerCase() > b?.seller.toString().toLowerCase() ? -1 : 1,);
          //down arrow down
          setFilteredItems(sortedDrpFilter);
        }else {
          setFilteredItems(orders);
        }
        //setFilteredItems(orders);
        setOrderSeller("DSC");
        //setSellerOrder(false);
        //final reset state off
        setSellerOrder(false);
        setSellerOrderUp(false);
        setisEnteringSeller(false);
        setSellerCount(0);
      };
    };

};

// Filter Dropdown Data
  const filterChecked = (checkedVal:string) => {
    const indexCheckVal = porscheFilter.indexOf(checkedVal);
    const checkbox = document.querySelector(`input[name="${checkedVal}"]`) as HTMLInputElement;
    if(checkbox.checked && indexCheckVal === -1){
      setPorscheFilter([...porscheFilter,checkedVal])
    }
    if(indexCheckVal !== -1){
      setPorscheFilter(removeElementFromArray(porscheFilter, checkedVal))
      checkbox.checked = false
    }
    if (selectedFilters.includes(checkedVal)) {
      let filters = selectedFilters.filter((el) => el !== checkedVal);
      setSelectedFilters(filters);
    } else {
      setSelectedFilters([...selectedFilters, checkedVal]);
    }
  };

  const handleMouseLeaveFilterIcn = (lblFilter:any) => {
    const filterLbl = lblFilter;
    let spanLbl = document.getElementsByClassName(filterLbl);
    for (let i = 0; i < spanLbl.length; i++) {
      spanLbl[i].classList.add("active");
    }
  };

  const handleMouseFilterIcn = (lblFilter:any) => {
      const filterLbl = lblFilter;
    let spanLbl = document.getElementsByClassName(filterLbl);
    for (let i = 0; i < spanLbl.length; i++) {
      spanLbl[i].classList.remove("active");
    }
  }

  const FilterResult = (): JSX.Element => {
    if (selectedFilters.length === 0) {
      return <></>;
    }

    return (

      <div className="users" style={{display:"flex",flexWrap:"wrap",paddingBottom:"20px"}}>
          {selectedFilters.map((item,index) => (
            <div className="filter-val" style={{background:"#F2F2F2",width:"auto",height:"54px",paddingLeft:"15px",paddingRight:"44px",paddingTop:"5px",paddingBottom:"5px",margin:"0 5px 5px 0",cursor:"pointer"}} onMouseEnter={()=> handleMouseLeaveFilterIcn("close-car"+' '+index)} onMouseLeave={()=> handleMouseFilterIcn("close-car"+' '+index)}  onClick={(e) => filterChecked(item)}>
            <div className="user">
              <span style={{display:"block",color:"#626669",position:"relative",top:"2px"}}>{t(item.split("^")[0])}</span>
              <span style={{fontSize:"15px"}}>{item.split("^")[1] === "InReview" ? t('In Review') : t(item.split("^")[1]) }</span>
            </div>
            <div className="close-car-container" id="filterLabl" style={{position:"relative",cursor:"pointer",display:"flex",justifyContent:"end",left:"33px"}}>
            <span className={"close-car"+' '+index} onMouseEnter={()=> handleMouseLeaveFilterIcn("close-car"+' '+index)}  onClick={(e) => filterChecked(item)}>+</span>
            </div>
            </div>
          ))}
    </div>
    );
  };

  const handleOrder = (order: OrderResponse) => {
    if (order) {
      let orderDetails:SingleOrderDetailType = { id: order.id, mode: 'view', draft: true, inReviewRejected:"No" };
      if(order.status !== "Draft") {
        orderDetails = { id: order.id, mode: "view", draft: false, inReviewRejected: order.inReviewRejected?"Yes":"No" };
      }
      
     const newWindow= window.open(`/#/order/create`, "_blank")
     newWindow?.sessionStorage.setItem(Constant.SingleOrdDetail, JSON.stringify(orderDetails));
    }
  }
//  `/#/order/create?id=${order.id}&mode=view&draft=false`
  const getLocation = (str: string, index: number) => {
    let retStr = "-";
    if (str.length > 0) {
      const strArray = str.split(" ");
      if (strArray.length > 0) {
        if (index === 0) retStr = strArray[0];
        if (index === 1 ) {
          strArray.splice(0, 1);
          retStr = strArray.join(" ");
        }
      }
    }
    return retStr;
  }
  const dispString = (str: string) => {
    let disStr = str;
    if (str.length > 10) {
      const strArray = str.substring(0, 10);
      disStr = str.substring(0, 10);
    }
    return disStr;
  };


 return(
     <>

<div className="pt10" style={{display:"flex", marginBottom:"16px"}}>
<PFlex>
          <PFlexItem>
            <PTextFieldWrapper label={`${t("search")}`} hideLabel={true} style={{ width: "440px" }}>
            <LightTooltip placement="top" title={<h1 style={{ color: "black", fontSize: "15px", fontWeight: "400", textAlign: "left", padding:"5px", borderRadius:0, textTransform: "none" }}>{t("Search for")} <b>{t("customer")}</b> {t("(name / company) or")} <b>{t("commission number")}</b></h1>}>
              <input
                type="search"
                name="search"
                placeholder={`${t("Search")}`}
                onChange={(e) => handleSearch(e.target.value)}
                //onKeyDown={(e) => handleKeyDown(e)}
                value={searchValue}
                autoComplete="off"
                //disabled={disabled}
              />
              </LightTooltip>
            </PTextFieldWrapper>

            <SearchResult />
          </PFlexItem>
        </PFlex>
        {/* toggle */}
        <PSwitch
            checked={show}
            alignLabel="right"
            style={{paddingTop: "12px",marginLeft:"25px"}}
            onClick={handleClickToggle}
            //className={disabledClassName}
            disabled={disabled}
          >
            {/* {toggleName} */}
            {t("Show filters")}
          </PSwitch>
</div>
{show ?
<div style={{marginBottom:"5px"}}>  
<PGrid direction="row" className="example-grid pt15">
<PGridItem size={2}>
  <div className="multiselect" ref={myRef}>
    <div className="selectBox" onClick={handleClickInsidePorsche}>
    <PSelectWrapper dropdownDirection="down" id="porscheCenterWrapNewCar" label={`${t("Porsche Center")}`} hideLabel={false}>
      <select>
      <option hidden />
      </select>
      <div className="overSelect"></div>
      </PSelectWrapper>
    </div>
    <div id="checkboxes-test-new-car" className="checkbox-section" style={{display: clickedOutsidePorsche ? 'block' : 'none'}}>
      <ul style={{overflow:"hidden",paddingTop:"10px",paddingBottom:"10px"}}>
      {Array.from(new Set(orders?.map(obj => obj.origin))).map(period => {
        return (
          <>
           <li className="checkbox-wrapper" aria-selected="false" role='option'>
      <PCheckboxWrapper  label={`${t(period)}`} hideLabel={false}>
            <input type="checkbox" name={"Porsche Center^"+period}  onClick={(e) => filterChecked("Porsche Center^"+period)}/>
      </PCheckboxWrapper>
          </li>
          </>
        );
    })}
      </ul>
    </div>
  </div>
</PGridItem>

<PGridItem size={2}>
      <div className="multiselect" ref={myRefSeries}>
    <div className="selectBox"  onClick={handleClickInsideSeries}>
    <PSelectWrapper dropdownDirection="down" id="porscheCenterWrapNewCar" label={`${t("Series")}`} hideLabel={false}>
      <select>
      <option hidden />
      </select>
      <div className="overSelect"></div>
      </PSelectWrapper>
    </div>
    <div id="checkboxes-test-new-car" className="checkbox-section"  style={{display: clickedOutsideSeries ? 'block' : 'none'}}>
      <ul style={{overflow:"hidden",paddingTop:"10px",paddingBottom:"10px"}}>
      {seriesDropdown.map(period => {
        return (
          <>
           <li className="checkbox-wrapper">
      <PCheckboxWrapper  label={period} hideLabel={false} style={{color:"red !important"}}>
            <input type="checkbox" name={"Series^"+period} onClick={(e) => filterChecked("Series^"+period)} />
      </PCheckboxWrapper>
          </li>
          </>
        );
    })}
      </ul>
    </div>
  </div>
</PGridItem>

<PGridItem size={2}>
      <div className="multiselect" ref={myRefCustType}>
    <div className="selectBox"   onClick={handleClickInsideCustTyp}>
    <PSelectWrapper dropdownDirection="down" id="porscheCenterWrapNewCar" label={`${t("Customer Type")}`} hideLabel={false}>
      <select>
      <option hidden />
      </select>
      <div className="overSelect"></div>
      </PSelectWrapper>
    </div>
    <div id="checkboxes-test-new-car" className="checkbox-section" style={{display: clickedOutsideCustType ? 'block' : 'none'}}>
      <ul style={{overflow:"hidden",paddingTop:"10px",paddingBottom:"10px"}}>
      {Array.from(new Set(orders?.map(obj => obj.type))).map(period => {
        return (
          <>
           <li className="checkbox-wrapper">
      <PCheckboxWrapper  label={`${t(period)}`} hideLabel={false} style={{color:"red !important"}}>
            <input type="checkbox" name={"Customer Type^"+period}  onClick={(e) => filterChecked("Customer Type^"+period)} />
      </PCheckboxWrapper>
          </li>
          </>
        );
    })}
      </ul>
    </div>
  </div>
</PGridItem>

<PGridItem size={2}>
      <div className="multiselect" ref={myRefCustGrp}>
    <div className="selectBox"  onClick={handleClickInsideCustGrp}>
    <PSelectWrapper dropdownDirection="down" id="porscheCenterWrapNewCar" label={`${t("Customer Group")}`} hideLabel={false}>
      <select>
      <option hidden />
      </select>
      <div className="overSelect"></div>
      </PSelectWrapper>
    </div>
    <div id="checkboxes-test-new-car" className="checkbox-section" style={{display: clickedOutsideCustGrp ? 'block' : 'none'}}>
      <ul style={{overflow:"hidden",paddingTop:"10px",paddingBottom:"10px"}}>
      {Array.from(new Set(orders?.map(obj => obj.group))).map(period => {
        return (
          <>
           <li className="checkbox-wrapper">
      <PCheckboxWrapper  label={`${t(period)}`} hideLabel={false} style={{color:"red !important"}}>
            <input type="checkbox" name={"Customer Group^"+period}  onClick={(e) => filterChecked("Customer Group^"+period)} />
      </PCheckboxWrapper>
          </li>
          </>
        );
    })}
      </ul>
    </div>
  </div>
</PGridItem>

<PGridItem size={2}>
      <div className="multiselect" ref={myRefCustPayment}>
    <div className="selectBox"  onClick={handleClickInsidePaymnt}>
    <PSelectWrapper dropdownDirection="down" id="porscheCenterWrapNewCar" label={`${t("Payment")}`} hideLabel={false}>
      <select>
      <option hidden />
      </select>
      <div className="overSelect"></div>
      </PSelectWrapper>
    </div>
    <div id="checkboxes-test-new-car" className="checkbox-section" style={{display: clickedOutsidePayment ? 'block' : 'none'}}>
      <ul style={{overflow:"hidden",paddingTop:"10px",paddingBottom:"10px"}}>
      {Array.from(new Set(orders?.map(obj => obj.payment))).map(period => {
        return (
          <>
           <li className="checkbox-wrapper">
      <PCheckboxWrapper  label={`${t(period)}`} hideLabel={false} style={{color:"red !important"}}>
            <input type="checkbox" name={"Payment^"+period}  onClick={(e) => filterChecked("Payment^"+period)} />
      </PCheckboxWrapper>
          </li>
          </>
        );
    })}
      </ul>
    </div>
  </div>
</PGridItem>

<PGridItem size={2}>

      <div className="multiselect" ref={myRefCustPfsExtra}>
    <div className="selectBox"  onClick={handleClickInsidePfsExtra}>
    <PSelectWrapper dropdownDirection="down" id="porscheCenterWrapNewCar" label={`${t("PFS Extras")}`} hideLabel={false}>
      <select>
      <option hidden />
      </select>
      <div className="overSelect"></div>
      </PSelectWrapper>
    </div>
    <div id="checkboxes-test-new-car" className="checkbox-section" style={{display: clickedOutsidePfsExtra ? 'block' : 'none'}}>
      <ul style={{overflow:"hidden",paddingTop:"10px",paddingBottom:"10px"}}>
      {pfsDropdown.map(period => {
        return (
          <>
           <li className="checkbox-wrapper">
      <PCheckboxWrapper  label={`${t(period)}`} hideLabel={false} style={{color:"red !important"}}>
            <input type="checkbox" name={"PFS Extras^"+period}  onClick={(e) => filterChecked("PFS Extras^"+period)} />
      </PCheckboxWrapper>
          </li>
          </>
        );
    })}
      </ul>
    </div>
  </div>
</PGridItem>

</PGrid>

<PGrid direction="row" className="example-grid pt15" style={{paddingBottom:"30px"}}>
<PGridItem size={2}>
      <div className="multiselect" ref={myRefCustPfsDelivery}>
    <div className="selectBox"   onClick={handleClickInsideDelivery}>
    <PSelectWrapper dropdownDirection="down" id="porscheCenterWrapNewCar" label={`${t("Delivery")}`} hideLabel={false}>
      <select>
      <option hidden />
      </select>
      <div className="overSelect"></div>
      </PSelectWrapper>
    </div>
    <div id="checkboxes-test-new-car" className="checkbox-section" style={{display: clickedOutsideDelivery ? 'block' : 'none'}}>
      <ul style={{overflow:"hidden",paddingTop:"10px",paddingBottom:"10px"}}>
      {Array.from(new Set(orders?.map(obj => obj.deliveryLocation))).map(period => {
        return (
          <>
           <li className="checkbox-wrapper">
      <PCheckboxWrapper  label={`${t(period)}`} hideLabel={false} style={{color:"red !important"}}>
            <input type="checkbox" name={"Delivery^"+period}  onClick={(e) => filterChecked("Delivery^"+period)} />
      </PCheckboxWrapper>
          </li>
          </>
        );
    })}
      </ul>
    </div>
  </div>
</PGridItem>

<PGridItem size={2}>
      <div className="multiselect" ref={myRefCustPfsExport}>
    <div className="selectBox"   onClick={handleClickInsideExport}>
    <PSelectWrapper dropdownDirection="down" id="porscheCenterWrapNewCar" label={`${t("Export")}`} hideLabel={false}>
      <select>
      <option hidden />
      </select>
      <div className="overSelect"></div>
      </PSelectWrapper>
    </div>
    <div id="checkboxes-test-new-car" className="checkbox-section" style={{display: clickedOutsideExport ? 'block' : 'none'}}>
      <ul style={{overflow:"hidden",paddingTop:"10px",paddingBottom:"10px"}}>
      {Array.from(new Set(orders?.map(obj => obj.exportRegion))).map(period => {
        return (
          <>
           <li className="checkbox-wrapper">
      <PCheckboxWrapper  label={`${t(period)}`} hideLabel={false} style={{color:"red !important"}}>
            <input type="checkbox" name={"Export^"+period}  onClick={(e) => filterChecked("Export^"+period)} />
      </PCheckboxWrapper>
          </li>
          </>
        );
    })}
      </ul>
    </div>
  </div>
</PGridItem>

<PGridItem size={2}>
      <div className="multiselect" ref={myRefCustPfsStaus}>
    <div className="selectBox"     onClick={handleClickInsidePfsStatus}>
    <PSelectWrapper dropdownDirection="down" id="porscheCenterWrapNewCar" label={`${t("Order Status")}`} hideLabel={false}>
      <select>
      <option hidden />
      </select>
      <div className="overSelect"></div>
      </PSelectWrapper>
    </div>
    <div id="checkboxes-test-new-car" className="checkbox-section" style={{display: clickedOutsideStatus ? 'block' : 'none'}}>
      <ul style={{overflow:"hidden",paddingTop:"10px",paddingBottom:"10px"}}>
      {Array.from(new Set(orders?.map(obj => obj.status))).map(period => {
        return (
          <>
           <li className="checkbox-wrapper">
      <PCheckboxWrapper  label={`${t(period === "InReview" ? "In Review" : period)}`} hideLabel={false} style={{color:"red !important"}}>
            <input type="checkbox" name={"Order Status^"+period} onClick={(e) => filterChecked("Order Status^"+period)}  />
      </PCheckboxWrapper>
          </li>
          </>
        );
    })}
      </ul>
    </div>
  </div>
</PGridItem>
</PGrid>
<FilterResult />
</div>
: null}

      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow >
                <TableCell className='cell-align-new'> 
                <PFlex direction={"column"}>
                  <PFlexItem className='inreview-text-top'><PorscheFont>{t('OR Date')}</PorscheFont></PFlexItem>
                  <PFlexItem className='inreview-text-bottom'><PorscheFont>{t('Planned CD')}</PorscheFont></PFlexItem>
                </PFlex> 
                </TableCell>
               
               <TableCell className='cell-align-new'>
                <PFlex direction={"column"}>
                 <PFlexItem className="inreview-text-top"><PorscheFont>{t('Model')}</PorscheFont></PFlexItem>
                 <PFlexItem className="inreview-text-bottom"><PorscheFont>{t('Comm. No.')}</PorscheFont></PFlexItem>
                </PFlex>
              </TableCell>
           
             <TableCell className='cell-align-new'> 
                 <PFlex direction={"column"}>
                  {/* <ArrowDownwardRoundedIcon fontSize='small' /> */}
                  {/* <ArrowUpwardRoundedIcon fontSize='small' /> */}
                  {/* <PFlexItem className="inreview-text-top" onClick={!dscOrder && !dscOrderUp && !paymentOrder && !paymentOrderUp && !sellerOrder && !sellerOrderUp ? ()=> sorting("rrp") : ()=> sorting("") } style={{cursor:"pointer"}}><PorscheFont><span onMouseEnter={()=> handleMouseEnter("rrp")} onMouseLeave={()=> handleMouseLeave("rrp")} style={{color: isHoveringRrp ? '#d5001b' : ''}}>{t('RRP (net)')}</span> {rrpOrderUp ? <span style={{position:"absolute",marginLeft:"5px"}}><span><PIcon name={"arrow-up"} /></span></span> : "" } {rrpOrder ? <span style={{position:"absolute",top:"5px",marginLeft:"5px"}}><PIcon name={"arrow-down"} /></span> : "" }</PorscheFont></PFlexItem>
                   <PFlexItem className="inreview-text-bottom" onClick={ !rrpOrderSecond && !paymentOrder && !paymentOrderUp && !sellerOrder && !sellerOrderUp ? ()=> sorting("discount") : ()=> sorting("")} style={{cursor:"pointer"}}><PorscheFont><span onMouseEnter={()=> handleMouseEnter("discount")} onMouseLeave={()=> handleMouseLeave("discount")} style={{color: isHoveringDisc ? '#d5001b' : ''}}>{t('Discount (%)')}</span></PorscheFont></PFlexItem> */}
                   <PFlexItem className="inreview-text-top" onClick={()=> sorting("rrp")} style={{cursor:"pointer"}}><PorscheFont><span className='rrp-col' onMouseEnter={()=> handleMouseEnter("rrp")} onMouseLeave={()=> handleMouseLeave("rrp")} style={{color: isHoveringRrp ? '#d5001b' : isEnteringRrp ? '#d5001b' : ''}}>{t('RRP (net)')}</span>{rrpOrderUp ? <span style={{position:"absolute",marginLeft:"5px"}}><span><PIcon name={"arrow-up"} /></span></span> : "" } {rrpOrder ? <span style={{position:"absolute",marginLeft:"5px"}}><PIcon name={"arrow-down"} /></span> : "" }</PorscheFont></PFlexItem>
                   <PFlexItem className="inreview-text-bottom" onClick={()=> sorting("discount")} style={{cursor:"pointer"}}><PorscheFont><span onMouseEnter={()=> handleMouseEnter("discount")} onMouseLeave={()=> handleMouseLeave("discount")} style={{color: isHoveringDisc ? '#d5001b' :isEnteringDisc ? '#d5001b' : ''}}>{t('Discount (%)')}</span>{discOrderUp ? <span style={{position:"absolute",marginLeft:"5px"}}><span><PIcon name={"arrow-up"} /></span></span> : "" } {discOrder ? <span style={{position:"absolute",marginLeft:"5px"}}><PIcon name={"arrow-down"} /></span> : "" }</PorscheFont></PFlexItem>
                  </PFlex>
              </TableCell>
            
              <TableCell className='cell-align-new'>
                <PFlex direction={"column"}>
                  <PFlexItem className="inreview-text-top"><PorscheFont>{t('Customer')}</PorscheFont></PFlexItem>
                  <PFlexItem className="inreview-text-bottom"><PorscheFont>{t('C@P No.')}</PorscheFont></PFlexItem>
                </PFlex>
              </TableCell>
            
             <TableCell className='cell-align-new'>
                <PFlex direction={"column"}>
                  <PFlexItem className="inreview-text-top"><PorscheFont>{t('Customer Type')}</PorscheFont></PFlexItem>
                  <PFlexItem className="inreview-text-bottom"><PorscheFont>{t('Customer Group')}</PorscheFont></PFlexItem>
                </PFlex>
              </TableCell>
            
             <TableCell className='cell-align-new'>
                <PFlex direction={"column"}>
                  {/* <PFlexItem className="inreview-text-top" onClick={ !rrpOrder && !rrpOrderUp && !sellerOrder && !sellerOrderUp ? ()=> sorting("payment") : ()=> sorting('')} style={{cursor:"pointer"}}><PorscheFont><span onMouseEnter={()=> handleMouseEnter("payment")} onMouseLeave={()=> handleMouseLeave("payment")} style={{color: isHoveringPymnt ? '#d5001b' : ''}}>{t('Payment')}</span>{paymentOrder ? <span style={{position:"absolute",marginLeft:"5px"}}><PIcon name={"arrow-down"} /></span> : "" }  {paymentOrderUp ? <span style={{position:"absolute",marginLeft:"5px"}}><PIcon name={"arrow-up"} /></span> : "" }</PorscheFont></PFlexItem> */}
                  <PFlexItem className="inreview-text-top" onClick={()=> sorting("payment")} style={{cursor:"pointer"}}><PorscheFont><span onMouseEnter={()=> handleMouseEnter("payment")} onMouseLeave={()=> handleMouseLeave("payment")} style={{color: isHoveringPymnt ? '#d5001b' : isEnteringPay ? '#d5001b' : ''}}>{t('Payment')}</span>{paymentOrder ? <span style={{position:"absolute",marginLeft:"5px"}}><PIcon name={"arrow-down"} /></span> : "" }  {paymentOrderUp ? <span style={{position:"absolute",marginLeft:"5px"}}><PIcon name={"arrow-up"} /></span> : "" }</PorscheFont></PFlexItem>
                  <PFlexItem className="inreview-text-bottom"><PorscheFont>PFS Extras</PorscheFont></PFlexItem>
                </PFlex>
              </TableCell>
            
             <TableCell className='cell-align-new'>
                <PFlex direction={"column"}>
                  <PFlexItem className="inreview-text-top"><PorscheFont>{t('Delivery')}</PorscheFont></PFlexItem>
                  <PFlexItem className="inreview-text-bottom">&nbsp;</PFlexItem>
                </PFlex>
              </TableCell>
           
             <TableCell className='cell-align-new'>
                <PFlex direction={"column"}>
                  <PFlexItem className="inreview-text-top"><PorscheFont>{t("Export")}</PorscheFont></PFlexItem>
                  <PFlexItem className="inreview-text-bottom">&nbsp;</PFlexItem>
                </PFlex>
              </TableCell>
            
             <TableCell className='cell-align-new'>
                <PFlex direction={"column"}>
                  <PFlexItem className="inreview-text-top"><PorscheFont>{t("Status")}</PorscheFont></PFlexItem>
                  <PFlexItem className="inreview-text-bottom">&nbsp;</PFlexItem>
                </PFlex>
              </TableCell>
             
             <TableCell className='cell-align-new'>
                <PFlex direction={"column"}>
                  <PFlexItem className="inreview-text-top" onClick={()=> sorting("seller")} style={{cursor:"pointer"}}><PorscheFont><span onMouseEnter={()=> handleMouseEnter("seller")} onMouseLeave={()=> handleMouseLeave("seller")} style={{color: isHoveringSellr ? '#d5001b' : isEnteringSeller? '#d5001b' : ''}}>{t('Seller')}</span>{sellerOrder ? <span style={{position:"absolute",marginLeft:"5px"}}><PIcon name={"arrow-down"} /></span> : "" }  {sellerOrderUp ? <span style={{position:"absolute",marginLeft:"5px"}}><PIcon name={"arrow-up"} /></span> : "" }</PorscheFont></PFlexItem>
                  <PFlexItem className="inreview-text-bottom"><PorscheFont>{t('Origin')}</PorscheFont></PFlexItem>
                </PFlex>
              </TableCell>
             
             <TableCell className='cell-align-new'>
                <PFlex direction={"column"}>
                  <PFlexItem className="inreview-text-top"><PorscheFont>{t('Actual CD')}</PorscheFont></PFlexItem>
                  <PFlexItem className="inreview-text-bottom"><PorscheFont>{t('Commission')}</PorscheFont></PFlexItem>
                </PFlex>
              </TableCell> 
                                      
             <TableCell className='cell-align-new'>
              </TableCell>            
            </TableRow>
          </TableHead>
          <TableBody>         
            {/* <TableRow hover role="checkbox" tabIndex={-1}> */}
            {filteredItems.length > 0 ? filteredItems.map((order, i) => {
            return (
            <TableRow hover role="checkbox" tabIndex={-4}>  
             <TableCell className='cell-align-new'>
               <PFlex direction={"column"}>
                 <PFlexItem className="inreview-text-top"><PorscheFont>{order.registerDate && order.registerDate !== "-" ? order.registerDate.replaceAll("-", ".") : "-"}</PorscheFont></PFlexItem> 
                 <PFlexItem className="inreview-text-bottom"><PorscheFont>{order.plannedDate && order.plannedDate !== "-" ? order.plannedDate.replaceAll("-", ".") : "-"}</PorscheFont></PFlexItem>
               </PFlex>
              </TableCell>  
              <TableCell className='cell-align-new' >
                <PFlex direction={"column"} style={{marginTop: "8px", marginBottom: "8px"}}>
                  {/*<PFlexItem className="inreview-text-bottom"><PorscheFont style={{ fontWeight: "400"}}>{order.model !== undefined && order.model.length > 0*/}
                  {/*    ? (*/}
                  {/*    <PorscheFont style={{color: "#313639"}}>{order.model}</PorscheFont>*/}
                  {/*    ) : "-"*/}
                  {/*  }</PorscheFont>*/}
                  {/*  </PFlexItem>*/}
                  <PFlexItem className="inreview-text-bottom" style={{ marginRight: order.model && order.model.length > 17 ? '15px' : '0' }}>
                    <PorscheFont style={{ fontWeight: "400"}}>
                      {order.model !== undefined && order.model.length > 0
                          ? (
                              <PorscheFont style={{ color: "#313639" }}>{order.model}</PorscheFont>
                          )
                          : "-"
                      }
                    </PorscheFont>
                  </PFlexItem>

                  <PFlexItem className="inreview-text-bottom"><PorscheFont>{order.commissionNumber ? order.commissionNumber : "-"}</PorscheFont></PFlexItem>
                  </PFlex>
              </TableCell> 
             <TableCell className='cell-align-new'>
                <PFlex direction={"column"}>
                  <PFlexItem className="inreview-text-top"><PorscheFont>{order.rrp && order.rrp.length > 0 && order.rrp !== "-" ? formatCurrencyWithNoUnit(parseFloat(order.rrp))+ " €": order.rrp}</PorscheFont></PFlexItem>
                  <PFlexItem className="inreview-text-bottom"><PorscheFont>{order.discount && order.discount.length > 0 && order.discount !== "-" ? formatCurrencyWithNoUnit(parseFloat(order.discount))+ " %": order.discount}</PorscheFont></PFlexItem>
                </PFlex>
              </TableCell> 
             <TableCell className='cell-align-new'>
                  <PFlex direction={"column"}>
                    <PFlexItem className="inreview-text-top"><PorscheFont>{order.fullName ? order.fullName : "-"}</PorscheFont></PFlexItem>
                    <PFlexItem className="inreview-text-bottom"><PorscheFont>{order.copNumber ? order.copNumber : "-"}</PorscheFont></PFlexItem>
                  </PFlex>
              </TableCell> 
             <TableCell className='cell-align-new'>
                  <PFlex direction={"column"}>
                    <PFlexItem className="inreview-text-top"><PorscheFont>{order.type ? t(order.type) : "-"}</PorscheFont></PFlexItem>
                    <PFlexItem className="inreview-text-bottom"><PorscheFont>{order.group ? t(order.group) : "-"}</PorscheFont></PFlexItem>
                  </PFlex>
              </TableCell> 
             <TableCell className='cell-align-new'>                
                  <PFlex direction={"column"}>
                    <PFlexItem className="inreview-text-top"><PorscheFont>{order.payment ? t(order.payment) : "-"}</PorscheFont></PFlexItem>
                    <PFlexItem className="inreview-text-bottom"><PorscheFont>
                      {
                        order.pfsExtrass ? order.pfsExtrass : "-"
                      }</PorscheFont>
                    </PFlexItem>
                  </PFlex>
              </TableCell>  
             <TableCell className='cell-align-new'>
                <PFlex direction={"column"}>
                    <PFlexItem className="inreview-text-top"><PorscheFont>{order.deliveryLocation ? getLocation(t(order.deliveryLocation), 0) : "-"}</PorscheFont></PFlexItem>
                    <PFlexItem className="inreview-text-bottom"><PorscheFont>{order.deliveryLocation ? getLocation(t(order.deliveryLocation), 1) : "-"}</PorscheFont></PFlexItem>
                </PFlex>
              </TableCell> 
             <TableCell className='cell-align-new'>
                <PFlex direction={"column"}>
                    <PFlexItem className="inreview-text-top">
                      <PorscheFont>{order.exportRegion ? getLocation(t(order.exportRegion), 0) : "-"}</PorscheFont>
                    </PFlexItem>
                    <PFlexItem className="inreview-text-bottom"><PorscheFont>{order.exportRegion ? getLocation(t(order.exportRegion), 1) : "-"}</PorscheFont></PFlexItem>
                </PFlex>
              </TableCell>  
              <TableCell className="cell-align-new" >
                <PFlex direction={"column"}>
                    <PFlexItem><PorscheFont>
                      {/* <CustomStatus status={getOrderStatusString(order.status)} /> */}
                      <CustomStatus status={order.status ? order.status.toString() : "Draft"} /></PorscheFont>
                    </PFlexItem>
                </PFlex>
              </TableCell> 
             <TableCell className='cell-align-new'>
                <PFlex direction={"column"}>
                    <PFlexItem className="inreview-text-top"><PorscheFont>{order.seller ? order.seller : "-"}</PorscheFont></PFlexItem>
                    <PFlexItem className="inreview-text-bottom"><PorscheFont>{order.origin ? order.origin : "-"}</PorscheFont></PFlexItem>
                </PFlex>
              </TableCell>
             <TableCell className='cell-align-new'>
                <PFlex direction={"column"}>
                    <PFlexItem className="inreview-text-top"><PorscheFont>{order.customerDeliveryDate && order.customerDeliveryDate !== "-" ? order.customerDeliveryDate.replaceAll("-", ".") : "-"}</PorscheFont></PFlexItem>
                    <PFlexItem className="inreview-text-bottom"><PorscheFont>
                      {order.commission && order.commission.length > 0 && order.commission !== "-" ?
                          (parseFloat(order.commission) < 0 ? "0,00 €" : formatCurrencyWithNoUnit(parseFloat(order.commission)) + " €")
                          : order.commission
                      }
                    </PorscheFont></PFlexItem>
                </PFlex>
              </TableCell>              
             <TableCell className='cell-align-new'>
              <PFlex direction={"column"}>
                    <PFlexItem >
                      <PButtonPure hideLabel={true} onClick={() => handleOrder(order)}>
                      </PButtonPure>
                    </PFlexItem>
                  </PFlex>
              </TableCell>
            </TableRow>
               );
              }): <PText size="small" style={{whiteSpace:"nowrap",marginTop:"20px"}}>{t("No matching results.")}</PText>} 
             {/* </TableRow> */}         
          </TableBody>
        </Table>
      </TableContainer>
    </>    
   );
}
    