import { useState } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { IconName, PIcon } from '@porsche-design-system/components-react';
import getSessionStorage from '../../helpers/getSessionStorage';
import { Constant } from '../../configs/Constants';

interface Props {
    partId: number,
    serviceId?: number,
    message: string,
    handleDelete: (partId: number, serviceId?: number) => void;
    disabled?: boolean;
}

export const CustomDelete = (props: Props) => {
  const { t } = useTranslation();
  const { partId, serviceId, message, handleDelete, disabled } = props;
  const [open, setOpen] = useState(false);
  const orderValues = getSessionStorage(Constant.SingleOrdDetail);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleYes = () => {
    if (serviceId) {
        handleDelete(partId, serviceId);
    } else {
        handleDelete(partId);
    }

    setOpen(false);
  }

  const mode = orderValues?.mode ? orderValues?.mode+'' :'' ;

  return (
    <div>
      {((disabled && disabled === true) || mode==="view" )? (
        <PIcon name={"delete" as IconName} ></PIcon>
      ) : (
        <PIcon name={"delete" as IconName} onClick={handleClickOpen}  ></PIcon>
      )}
      
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id={`alert-dialog-title-${partId}-${serviceId? serviceId : ""}`}>
          
        </DialogTitle>
        <DialogContent>
          <DialogContentText id={`alert-dialog-description-${partId}-${serviceId? serviceId : ""}`}>
            { message }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleYes}>{t("Yes")}</Button>
          <Button onClick={handleClose} autoFocus>
            {t("No")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
