import styled from 'styled-components';

import { font } from '@porsche-design-system/utilities';

export const PAccordionText = styled.p`
  font-family: ${font.family};
  font-style: Normal;
  font-size: ${font.size[16]};
  margin-left: 8px;
  color: #131313;
`;

export const PMainHeader = styled.p`
  font-family: ${font.family};
  font-size: ${font.size[24]};
  color: #313639;
`;

export const PorscheFont = styled.p`
  font-family: ${font.family};
`;

