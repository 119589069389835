import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Stack } from '@mui/material';
import {
  PButtonPure, PDivider, PFlex, PFlexItem,
  PIcon
} from '@porsche-design-system/components-react';

import { PorscheFont } from '../../../assets/styles/CustomStyles';
import {
  RecipientNotificationResponseDTO
} from '../../../interfaces/TasksAndNotifications';
//import { Notifications } from './Notifications';

//import { TaskRow } from './TaskRow';

interface Props {
  notificationDetails: RecipientNotificationResponseDTO;
  index: number;
}

export const NotificationRow = (props: Props): JSX.Element => {
  const { notificationDetails, index } = props;
  const [toggleDescription, setToggleDescription] = useState<boolean>(false);
  const [showRecipients, setShowRecipients] = useState(false);
  const { t } = useTranslation();

  return (
    <div style={ index%2 !== 0 ? {background: "#F5F5F5", padding: "5px"} : {paddingLeft: "5px", paddingRight: "5px"} }>
      <PFlex style={{marginTop: "10px", marginBottom: "10px"}}>
        <PFlexItem style={{width:"15%"}}>
          <PorscheFont className="tn-title">{notificationDetails.notification?.sentOn.substring(0, 10).replaceAll("-", ".")}</PorscheFont>
        </PFlexItem> 
        <PFlexItem style={{width:"15%"}}>
          <PorscheFont className="tn-title">{notificationDetails.notification?.sentBy.name}</PorscheFont>
        </PFlexItem>
        <PFlexItem style={{width:"30%"}}>
          <PorscheFont className="tn-title">{notificationDetails.notification?.subject}</PorscheFont>
          {notificationDetails.notification?.description &&
           notificationDetails.notification?.description.length > 0 && (
            <Stack direction={"row"} spacing={1} onClick={() => setToggleDescription(!toggleDescription)}>
              <PorscheFont className="tn-expand">{t("View description")}</PorscheFont>
              <div style={{paddingTop: "2px"}}>
                <PIcon name={toggleDescription ?  "arrow-head-up" : "arrow-head-down"} size="small" color="notification-neutral" />
              </div>
            </Stack>
          )}
        </PFlexItem>
        <PFlexItem style={{width:"40%"}}>
          {notificationDetails.notification?.recipients && (
            <>
            { notificationDetails.notification?.recipients.length === 1 && (
               <PorscheFont className="dcs-title">{notificationDetails.notification?.recipients[0].name}</PorscheFont>
            )}
            { notificationDetails.notification?.recipients.length === 2 && (
               <PorscheFont className="dcs-title">{notificationDetails.notification?.recipients[0].name}{", "}{notificationDetails.notification?.recipients[1].name}</PorscheFont>
            )}
            { notificationDetails.notification?.recipients.length > 2 && (
              <PFlex direction={"column"}>
                <PFlexItem>
                  <PorscheFont className="dcs-title">{notificationDetails.notification?.recipients[0].name}{", "}{notificationDetails.notification?.recipients[1].name}{" +"}{notificationDetails.notification?.recipients.length - 2}</PorscheFont>
                </PFlexItem>
                <PFlexItem>
                  <PorscheFont className="tn-expand" style={{marginLeft:"4px"}} onClick={() => setShowRecipients(!showRecipients)}>{t("View recipients")}</PorscheFont>
                  { showRecipients && (
                    <div className="recipients">
                      <PFlex justifyContent={"space-between"}>
                        <PFlexItem>
                          <PorscheFont className="dcs-heading">{t("Recipients")}: {notificationDetails.notification?.recipients.length} </PorscheFont>
                        </PFlexItem>
                        <PFlexItem>
                          <PButtonPure icon="close" onClick={() => setShowRecipients(false)}></PButtonPure>
                        </PFlexItem>
                      </PFlex>
                      <PDivider color="neutral-contrast-high" />
                      <PorscheFont className="dcs-title">
                        <ul style={{listStyle: "inside"}}>
                          { notificationDetails.notification?.recipients.map((rp) => {
                              return(<li style={{paddingLeft: "0px"}}> {rp.name} </li>);
                            })
                          }
                        </ul>
                      </PorscheFont>
                    </div>
                  )}
                </PFlexItem>
              </PFlex>
            )}
            </>
          )}
        </PFlexItem>
      </PFlex>
      { notificationDetails.notification?.description && 
       notificationDetails.notification?.description.length > 0 && toggleDescription && (
        <PFlex style={{marginTop: "-10px", marginBottom: "4px"}}>
          <PFlexItem>
            <span>
              <PorscheFont className="tn-heading">{t("Description")}</PorscheFont>
              <PorscheFont className="tn-title">{notificationDetails.notification?.description}</PorscheFont>
            </span>
          </PFlexItem>
        </PFlex>
      )}
    </div>
  );
}