import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import {
  PButton
} from '@porsche-design-system/components-react';

import { PorscheFont } from '../../../../assets/styles/CustomStyles';
import { PartType } from '../../../../configs/Enums';
import { OrderContext } from '../../../../contexts/OrderContext';
import { ContentHide } from '../../../common/ContentHide';
import { CustomTextarea } from '../../../common/CustomTextarea';
import { CwtNew } from './CwtNew';
import { PAW } from './PAW';
import { PDSNew } from './PDSNew';
import getSessionStorage from '../../../../helpers/getSessionStorage';
import { Constant } from '../../../../configs/Constants';

const sections = [
  "Porsche Approved Warranty",
  "Complete Winter Tires",
  "PDS / Tequipment",
];

interface Props {
  onNextTabChange: (i: number) => void;
  changedMode: string;
  handleModified: () => void;
  onUpsaleNewChange:()=>void;
}

export const UpsaleTab = (props: Props): JSX.Element => {
  const { onNextTabChange, changedMode, handleModified,onUpsaleNewChange } = props;
  const orderValues = getSessionStorage(Constant.SingleOrdDetail);

  const orderContext = useContext(OrderContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [ reload, setReload ] = useState("");
  const [ showPaw, setShowPaw] = useState(false);
  const [ showCwt, setShowCwt ] = useState(false);
  const [ showPds, setShowPds ] = useState(false);
  const [ showCwtNew, setShowCwtNew ] = useState(false);
  const [ isValid, setIsValid ] = useState(false);
  const [ mode, setMode] = useState("")
  const [ searchParams, setSearchParams] = useSearchParams()
  const [ disabled, setDisabled] = useState<boolean>(false)
  const [ enableToggleCWT, setEnableToggleCWT] = useState<boolean>(false)
  const [ enableTogglePDS, setEnableTogglePDS] = useState<boolean>(false)

  const errors = orderContext.getErrors();

  let upsaleDetails = orderContext.getCtxUpsaleDetails();

  useEffect( () => {
    const id = orderValues?.id ? orderValues?.id+'' :''
    const mode = orderValues?.mode ? orderValues?.mode+'' :'';
    setMode(mode)
    if (id && (mode === "view" || mode === "edit")) {
      if(mode === "view") {
        setDisabled(true)
      } else {
        setDisabled(false)
      }
      
      setEnableToggleCWT(enableCWT())
      setEnableTogglePDS(enablePDS())
    }
  },[])

  useEffect(() => {
    if (changedMode === "edit") {
      setDisabled(false)
    } else if (changedMode === "view") {
      setDisabled(true)
    }
  }, [changedMode])

  useEffect(() => {
    const isFormValid = isValidUpsale();
    orderContext.updateFormValidation(1, isFormValid);
    enableNextButton(isFormValid);
  })

  useEffect(() => {
    setEnableToggleCWT(enableCWT())
    setEnableTogglePDS(enablePDS())
  }, [errors, reload])

  const enableCWT = () : boolean => {
    if (upsaleDetails) {
      let items = upsaleDetails.upsaleItems
      if (items) {
        return items.filter(item => item.partType === PartType.tire).length > 0
      }
    }
    return false
  }

  const enablePDS = () : boolean => {
    if (upsaleDetails) {
      let items = upsaleDetails.upsaleItems
      if (items) {
        return items.filter(item => item.partType === PartType.part).length > 0
      }
    }
    return false
  }

  const handleExit = () => {
    window.close();
  }

  const handleComment = (e: any) => {
    upsaleDetails.comment = e.target.value;
    orderContext.updateCtxUpsaleDetails(upsaleDetails);
    handleModified();
  }

  const handlePaw = (show: boolean) =>  {

    if (show) {
      upsaleDetails.isPawActivated = show;
      orderContext.updateCtxUpsaleDetails(upsaleDetails);
      orderContext.setErrors({field: "upsalePaw",message: "",section:"upsalePaw"})
      if (upsaleDetails.durationInMonths === 0) {
        orderContext.setErrors({
          field: "duration",
          message: "",
          section: "upsalePaw",
        })
      }
    } else {
      orderContext.removeError("upsalePaw");
      orderContext.removeError("duration");
      upsaleDetails.durationInMonths = 0;
      upsaleDetails.includeAssistance = true;
      upsaleDetails.isPawActivated = show;
      orderContext.updateCtxUpsaleDetails(upsaleDetails);

      let ord = orderContext.getCtxOrderDetails();
      let cdd= orderContext.getCtxCalculationDraftDetails(ord?.vehicleDetails?.category?.type)
      if(cdd.calculationType !== undefined && cdd.additionalCostsPrices !== undefined && cdd.additionalCostsPrices.length > 3) {
        let add = cdd.additionalCostsPrices;
        add[2].amountNet  = 0.00;
        add[2].customerShare = 0.00;
        add[2].discountInEuro = 0.00;
        add[2].discountInPercent = 0.00;
        add[3].amountNet  = 0.00;
        add[3].customerShare = 0.00;
        add[3].discountInEuro = 0.00;
        add[3].discountInPercent = 0.00;
        cdd.additionalCostsPrices = add;
        orderContext.updateCtxCalculationDraftDetails(cdd, ord?.vehicleDetails?.category?.type);
      }
    }
    upsaleDetails = orderContext.getCtxUpsaleDetails();
    setShowPaw(show);

    setReload(new Date().toLocaleString());
  }

  const handleCwt = (show: boolean) =>  {
    if (show) {
      let usDetails = orderContext.getCtxUpsaleDetails();
      usDetails.isTiresActivated = true;

      orderContext.updateCtxUpsaleDetails(usDetails);
      orderContext.setErrors({
        field: "tire",
        message: "",
        section: "upsaleCwt",
      })
      orderContext.setErrors({
        field: "winterTire",
        message: "",
        section: "upsaleCwt",
      })
    } else {
      orderContext.removeSectionError("upsaleCwt");
      let usDetails = orderContext.getCtxUpsaleDetails();
      usDetails.isTiresActivated = false;
      let usItems = usDetails.upsaleItems;
      usItems.map((item, i) => {
        if(item.partType === "tire"){
          usItems.splice(i, 1);
        }
      })

      usDetails.upsaleItems = usItems;
      orderContext.updateCtxUpsaleDetails(usDetails);
    }
    upsaleDetails = orderContext.getCtxUpsaleDetails();
    setShowCwt(show);
  }

  const handlePds = (show: boolean) =>  {
    if (show) {
      let usDetails = orderContext.getCtxUpsaleDetails();
      usDetails.isPartsActivated = true;
      orderContext.updateCtxUpsaleDetails(usDetails);
      orderContext.setErrors({
        field: "part",
        message: "",
        section: "upsalePds",
      })
    } else {
      orderContext.removeSectionError("upsalePds");
      let usDetails = orderContext.getCtxUpsaleDetails();
      usDetails.isPartsActivated = false;
      // Empty the upsale items
      let usItems = [];
      //Check for if usitem of Tire exists
      const tire = usDetails.upsaleItems.filter(x => x.partType === "tire");
      if(tire.length > 0) {
        // tire exists , push the tire
        usItems.push(tire[0]);
      }
      usDetails.upsaleItems = usItems;
      orderContext.updateCtxUpsaleDetails(usDetails);
    }
    upsaleDetails = orderContext.getCtxUpsaleDetails();
    setShowPds(show)
  }

  const isValidUpsale = () : boolean => {
    const errors = orderContext.getErrors();
    return errors.every(error => {
        if (showPaw && error.section === "upsalePaw") return false
        if (showCwt && error.section === "upsaleCwt") return false
        if (showPds && error.section === "upsalePds") return false
        return true
    })
  }

  const enableNextButton = (isValid: boolean) => {
    setIsValid(isValid)
  }

  const handleNextButton = () => {
    let errs = validateUpsale();
    if(errs.length > 0) {
    } else {
      onNextTabChange(3)
    }
    //if(isValidUpsale() && validateCustomerShare()) {
     // onNextTabChange(3)
    //} 
  }

  const validateUpsale = () => {
    const upsaleDetails = orderContext.getCtxUpsaleDetails();
    let errs: Array<string> = [];

    // If tires activated
    if(upsaleDetails.isTiresActivated){

    }

    // If parts activated
    if(upsaleDetails.isTiresActivated){

    }

    return errs;
  }

  const validateCustomerShare = () => {
    const upsaleDetails = orderContext.getCtxUpsaleDetails();
    if(upsaleDetails.upsaleItems && upsaleDetails.upsaleItems.length > 0) {
      const ust = upsaleDetails.upsaleItems
      return ust.map((item, i) => {
        if(item.part.priceBeforeCustomerNet) {
          if(item.customerShare > item.part.priceBeforeCustomerNet) return false;
          if(item.customerShare === 0) return false;
        }

        if(item.serviceItems.length > 0) {
          return item.serviceItems.map((st, j) => {
            if(st.services.priceBeforeCustomerNet) {
              if(st.customerShare > st.services.priceBeforeCustomerNet) return false
              if(st.customerShare === 0) return false
            }
          })
        }
      })
    }
    return true
  }

  return (
    <>
      <ContentHide
        //title={`${t("Porsche Approved Warranty")}`}
        title={"Porsche Approved Warranty"}
        component={
          <PAW open={showPaw} enableNextButton={enableNextButton}  disabled={disabled} handleModified={handleModified}/>
        }
        handleToggle={(show) => handlePaw(show)}
        enableToggle = {upsaleDetails?.durationInMonths ? true : false}
        disabled = {disabled}
        onUpsaleNewChange={onUpsaleNewChange}
      />

      <ContentHide
        title={`${t("Complete Winter Tires")}`}
        component={
          <CwtNew open={showCwt} enableNextButton={enableNextButton} disabled={disabled} handleModified={handleModified}/>
        }
        handleToggle={(show) => handleCwt(show)}
        enableToggle = {enableToggleCWT}
        disabled = {disabled}
        onUpsaleNewChange={onUpsaleNewChange}

      />

      <ContentHide
        title={`${t("PDS / Tequipment")}`}
        component={
          <PDSNew enableNextButton={enableNextButton} disabled={disabled} handleModified={handleModified}/>
        }
        handleToggle={(show) => handlePds(show)}
        enableToggle = {enableTogglePDS}
        disabled = {disabled}
        onUpsaleNewChange={onUpsaleNewChange}

      />

      <div className="order-card">
        <PorscheFont className="card-header">{t("Comment (Optional)")}
        </PorscheFont>
        <div className="pt4" >
          <CustomTextarea name="comment" type="text" label="" onChange={(e) => handleComment(e)} defaultValue={upsaleDetails.comment ? upsaleDetails.comment : ""} disabled = {disabled}/>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          //margin: "44px 30px 34px 30px",
          margin: "0px 30px 0px 30px",
        }}
      >
        {/* <PButton icon="close" onClick={handleExit} className="exit-btn"><PorscheFont>{t("Exit")}</PorscheFont></PButton> */}
        <PButton icon="close" onClick={handleExit}><PorscheFont>{t("Exit")}</PorscheFont></PButton>
        { changedMode !== "view" && (
          <PButton variant="primary" className='next-btn' onClick={handleNextButton} ><PorscheFont>{t("Next")}</PorscheFont></PButton>
        )}
      </div>
    </>
  );
};
