import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Alert, Snackbar, Stack } from '@mui/material';
import {
  IconName, PButton,
  PFlex, PFlexItem, PGrid, PGridItem, PIcon, PSelectWrapper,
  PTable, PTableBody, PTableCell, PTableHead, PTableHeadCell, PTableHeadRow, PTableRow, PText,
  PTextFieldWrapper
} from '@porsche-design-system/components-react';

import { PorscheFont } from '../../../../assets/styles/CustomStyles';
import { PartType, WinterOrSummer } from '../../../../configs/Enums';
import { serviceNames } from '../../../../configs/Upsale';
import { OrderContext } from '../../../../contexts/OrderContext';
import { UpsaleItemsTotal } from '../../../../interfaces/Upsale';
import {
  ServiceItemDTO, TequipmentAndPorscheDriverSelectionDTO, UpsaleItemDTO,
  WorkshopAndServiceCostsDTO
} from '../../../../interfaces/UpsaleNew';
import { CategoryCommissionDTO, CommissionScalingDTO } from '../../../../interfaces/commission';
import {
  formatCurrency, formatCurrencyToDecimal, formatCurrencyWithNoUnit, getUpsaleItemsTotal
} from '../../../../mock/helper';
import { type } from '../../../../services/Constants';
import { dcscommissionScalingByUser, dcsgetUpsaleVariblesNew } from '../../../../services/data/Helpers';
import { CustomDelete } from '../../../common/CustomDelete';
import getSessionStorage from '../../../../helpers/getSessionStorage';
import { Constant } from '../../../../configs/Constants';

interface Props {
  open?: boolean;
  enableNextButton: (isValid: boolean) => void;
  disabled: boolean;
  handleModified: () => void;
}

export const CwtNew = (props: Props): JSX.Element => {
    const { open, enableNextButton, disabled, handleModified } = props;
    const inputFieldMaxLength:number = 15
  const orderContext = useContext(OrderContext);
  const { t } = useTranslation();
  const orderValues = getSessionStorage(Constant.SingleOrdDetail); 
  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState<Array<TequipmentAndPorscheDriverSelectionDTO>>([]);
  const [services, setServices] = useState<Array<WorkshopAndServiceCostsDTO>>([]);
  const [selectedTire, setSelectedTire] = useState({} as TequipmentAndPorscheDriverSelectionDTO);
  const [upsaleItems, setUpsaleItems] = useState<Array<UpsaleItemDTO>>([]);
  const [total, setTotal] = useState<UpsaleItemsTotal>({} as UpsaleItemsTotal)
  const [tireAdded, setTireAdded] = useState(false);
  const [disabledClassName, setDisabledClassName] = useState("")
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [customerShareError, setCustomerShareError] = useState(false);
  const [mode, setMode] = useState("");
  const [searchParams, setSearchParams] = useSearchParams()
  const [scalingobj, setScalingobj] = useState<CommissionScalingDTO>({} as CommissionScalingDTO);
  const [commissionObj, setCommissionObj] = useState<CategoryCommissionDTO>({} as CategoryCommissionDTO);
  const [enableWinterTyreCustomerShare, setEnableWinterTyreCustomerShare] = useState(false);

  let upsaleDetails = orderContext.getCtxUpsaleDetails();
  
  // For sprint1 hard coding this, need to get this from Order.
  const deliveryLocation = "PorscheCenter";

  const [reload, setReload] = useState("");

  useEffect(() => {
    const init = async () => {
      const apiResponse = await dcscommissionScalingByUser(
        orderContext.getCtxOrderDetails().orderCreator.id
      );
      if (apiResponse.status === type.SUCCESS) {
        setScalingobj(apiResponse.response);
      }

      const result = await dcsgetUpsaleVariblesNew("generic");

      setServices(result.services);
    };
    init();
  }, []);

  useEffect(() => {
    if (upsaleItems) {
      setTotal(getUpsaleItemsTotal(upsaleItems.filter(x => x.partType === PartType.tire)));
    }

  }, [searchValue, selectedTire, upsaleItems, scalingobj, commissionObj]);

  useEffect(() => {
    const mode = orderValues?.mode ? orderValues?.mode+'' :'';
    setMode(mode)
    if (disabled) {
      setDisabledClassName("disabled")
      if (upsaleDetails.upsaleItems) {
        // let tempItems = upsaleDetails.upsaleItems
        // tempItems = tempItems.filter(items => items.partType === PartType.tire)
        // if (tempItems.length > 0) {
        //   setUpsaleItems([tempItems[0]])
        // }
      }
    }
    else setDisabledClassName("")
    if (upsaleDetails.upsaleItems) {
      setUpsaleItems(upsaleDetails.upsaleItems);
    }
  }, [upsaleDetails])

  useEffect(() => {
    let isValid = isValidUpsale()
    orderContext.updateFormValidation(1, isValid)
    enableNextButton(isValid);
  }, []);
  
  //CompleteWinterTires
  const calculateRangeValue = (rangeValue: number, category: string) => {
    const filterConf: Array<CategoryCommissionDTO> = scalingobj.commissions.filter(x => x.commissionScalingCategoryType === category);
    if (filterConf.length > 0) {
      const conf = filterConf[0];
      
      setCommissionObj(conf);
      for (let i = 0; i < conf.vehicleCommissions.length; i++) {
        if (conf.vehicleCommissions[i].fromValue <= rangeValue && conf.vehicleCommissions[i].toValue >= rangeValue) {
          
          return conf.vehicleCommissions[i];
        }
      }
      // if  we wont find value and within category range.
      return null;
    }

  }

  const handleSuccessClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return false;
    }
    setSaveSuccess(false);
  };
  const handleErrorClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return false;
    }
    setIsError(false);
  };

  const isValidUpsale = (): boolean => {
    const errors = orderContext.getErrors();
    //
    return errors.every(error => {
      if (error.section === "upsalePaw") return false
      if (error.section === "upsaleCwt") return false
      if (error.section === "upsalePds") return false
      return true
    })
  }

  const handleSearch = async (search: string) => {
    //
    setSearchValue(search);
    if (search.length > 0) {
      const result = await dcsgetUpsaleVariblesNew(search)

      const parts = result.parts;
      //

      setSearchResult(parts);
      setServices(result.services);
    } else {
      setSearchResult([]);
    }
  }

  const handleSelect = (id: number, search: string) => {
    const selected = searchResult.filter(x => x.id === id);
    setSelectedTire(selected[0]);
    setSearchValue(search);
    setSearchResult([]);
  }

  const SearchResult = (): JSX.Element => {
    if (searchResult.length === 0) {
      return <></>;
    }

    return (
      <ul className={`autocomplete ${searchResult.length > 3 ? "autocomplete-overflow" : ""}`}>
        {searchResult.map((tds) => {
          return (
            <li key={tds.id} onClick={() => handleSelect(tds.id, tds.part ? tds.part : "")} >
              {tds.part
                ? tds.part + "  |  " + tds.serialNumber
                : ""}
            </li>
          )
        })
        }
      </ul> 
    );
  }

  const handleAddTier = () => {
    if (selectedTire.id === undefined) return null;

    let exists: Array<UpsaleItemDTO> = [];
    if (upsaleItems?.length > 0) {
      exists = upsaleItems.filter(x => (x.part.id === selectedTire.id && x.partType === PartType.tire));
    }

    // Already exists need to handle this
    if (exists.length === 0) {
      // Updating Upsale item
      let usItem = {} as UpsaleItemDTO;
      usItem.part = selectedTire;
      usItem.partType = PartType.tire;
      usItem.customerShare = 0.00;
      usItem.commission = 0.00;
      /**
      * commission calculation
      */

      let discoutPercenatge = 0.0
      
      if (usItem.customerShare  > 0 && usItem.part.priceBeforeCustomerNet) {
        usItem.discount = usItem.part.priceBeforeCustomerNet - usItem.customerShare;
        discoutPercenatge = ((usItem.part.priceBeforeCustomerNet - usItem.customerShare) / usItem.part.priceBeforeCustomerNet) * 100;
      } else if (usItem.part.priceInternal && usItem.part.priceBeforeCustomerNet) {
          if(usItem.part.partDiscount){
             usItem.discount = usItem.part.priceBeforeCustomerNet;
             discoutPercenatge = (usItem.part.priceInternal / usItem.part.priceBeforeCustomerNet) * 100;
           }else{
             usItem.discount = usItem.part.priceInternal;
             discoutPercenatge = (usItem.part.priceInternal / usItem.part.priceBeforeCustomerNet) * 100;
           }
      }

      
      if (discoutPercenatge > 0) {
        
        let vc = calculateRangeValue(discoutPercenatge, "CompleteWinterTires");
        
        let commision = 0.0;
        if (vc && vc?.payoutRefence1 === "SellingPrice" && usItem.part.priceBeforeCustomerNet) {

          commision = usItem.part.priceBeforeCustomerNet * vc.payoutValue1 / 100;
        } else if (vc && vc?.payoutRefence1 === "CustomerShare" && usItem.part.priceBeforeCustomerNet) {
          commision = commision + usItem.customerShare * vc.payoutValue1 / 100;
        } else if (vc && vc?.payoutRefence1 === "Euro") {
          commision = commision + vc.payoutValue1;
        }
        if (commissionObj.payOut2) {
          if (vc && vc?.payoutRefence2 === "SellingPrice" && vc.payoutValue2 && usItem.part.priceBeforeCustomerNet) {
            commision = commision + usItem.part.priceBeforeCustomerNet * vc.payoutValue2 / 100;
          } else if (vc && vc?.payoutRefence2 === "CustomerShare" && vc.payoutValue2 && usItem.part.priceBeforeCustomerNet) {
            commision = commision + usItem.customerShare * vc.payoutValue2 / 100;
          } else if (vc && vc?.payoutRefence2 === "Euro" && vc.payoutValue2) {
            commision = commision + vc.payoutValue2;
          }
        }
        usItem.commission = commision;
      }
      /**
       * need to calculate commision based on range refece and CompleteWinterTires
       */
      usItem.serviceItems = [];
      /**
       *  discout/selingprice (net) 
       * else 
       *   
       * 
       * 
       */

      //setTotal(getUpsaleItemsTotal(upsaleItems));
      // setUpsaleItems ( (prev) => {
      //   if(prev) {
      //     upsaleDetails.upsaleItems = [...prev, usItem];
      //     return [...prev, usItem];
      //   } else {
      //     upsaleDetails.upsaleItems = [usItem];
      //     return [usItem]
      //   }
      // });


      setSearchValue("");
      setTireAdded(true);
      // if (upsaleDetails.upsaleItems !== undefined && upsaleDetails.upsaleItems.length > 0) {
      //   upsaleDetails.upsaleItems = [...upsaleDetails.upsaleItems, usItem];
      // } else {
      //   upsaleDetails.upsaleItems = [usItem];
      // }

      upsaleItems.push(usItem);
      upsaleDetails.upsaleItems = upsaleItems;
      orderContext.updateCtxUpsaleDetails(upsaleDetails);
      setUpsaleItems(upsaleItems);

      //setSelectedTire({} as PartsDTO)
      handleModified();
      setReload(new Date().toLocaleString());
      

    }
  }

  const handleCustomerShare = (partId: number, value: string, index: number) => {
    upsaleItems.map((usItem, i) => {
      //PriceBefore customer Net active logic
    if(usItem?.part.partDiscount == true){
      if (usItem.part.id === partId && i === index) {
        usItem.customerShare = formatCurrencyToDecimal(value);
        const dom = document.getElementById(`customerShare${index}`);
        if (usItem.customerShare > 0) {
          if (usItem.part.priceBeforeCustomerNet) {
            usItem.discount = usItem.part.priceBeforeCustomerNet - usItem.customerShare;
            //
            if(usItem.discount < 0){
              usItem.discount = 0.00
            }
            if (usItem.customerShare > usItem.part.priceBeforeCustomerNet) {
              if(dom) dom.setAttribute("state", "error");
            } else {
              if(dom) dom.setAttribute("state", "none");
            }
          } else {
            usItem.discount = 0.00
          }

          if(usItem?.part?.priceInternal){
            if(usItem.customerShare <0){
              usItem.customerShare = 0
              if(dom) dom.setAttribute("state", "error");
            }
            
          }
          if(usItem?.part?.priceBeforeCustomerNet){
            if(usItem.customerShare > usItem?.part.priceBeforeCustomerNet){
               usItem.customerShare = 0
               if(dom) dom.setAttribute("state", "error");
          }
        }
        } else {
          if (usItem.customerShare < 0) {
            if(dom) dom.setAttribute("state", "error");
          } else {
            if(dom) dom.setAttribute("state", "none");
          }
          usItem.discount = usItem.part.priceBeforeCustomerNet ? usItem.part.priceBeforeCustomerNet : 0.00;
          // if(dom) dom.setAttribute("state", "none");
        }
        /**
         * commission calculation
         */
        let discoutPercenatge = 0.0;
        
        if (usItem.customerShare > 0 && usItem.part.priceBeforeCustomerNet !== undefined && usItem.part.priceBeforeCustomerNet > 0) {
          discoutPercenatge = ((usItem.part.priceBeforeCustomerNet - usItem.customerShare) / usItem.part.priceBeforeCustomerNet) * 100;
        } else if (usItem.part.priceInternal && usItem.part.priceBeforeCustomerNet !== undefined && usItem.part.priceBeforeCustomerNet > 0) {
          discoutPercenatge = (usItem.part.priceInternal / usItem.part.priceBeforeCustomerNet) * 100;
        }
        
        if (discoutPercenatge > 0) {
          
          let vc = calculateRangeValue(discoutPercenatge, "CompleteWinterTires");
          
          let commision = 0.0;
          if (vc && vc?.payoutRefence1 === "SellingPrice" && usItem.part.priceBeforeCustomerNet !== undefined ) {

            commision = usItem.part.priceBeforeCustomerNet * vc.payoutValue1 / 100;
          } else if (vc && vc?.payoutRefence1 === "CustomerShare" && usItem.part.priceBeforeCustomerNet !== undefined) {
            commision = commision + usItem.customerShare * vc.payoutValue1 / 100;
          } else if (vc && vc?.payoutRefence1 === "Euro") {
            commision = commision + vc.payoutValue1;
          }
          if (commissionObj.payOut2) {
          if (vc && vc?.payoutRefence2 === "SellingPrice" && vc.payoutValue2 && usItem.part.priceBeforeCustomerNet !== undefined) {
            commision = commision + usItem.part.priceBeforeCustomerNet * vc.payoutValue2 / 100;
          } else if (vc && vc?.payoutRefence2 === "CustomerShare" && vc.payoutValue2 && usItem.part.priceBeforeCustomerNet !== undefined) {
            commision = commision + usItem.customerShare * vc.payoutValue2 / 100;
          } else if (vc && vc?.payoutRefence2 === "Euro" && vc.payoutValue2) {
            commision = commision + vc.payoutValue2;
          }
        }
          
          usItem.commission = commision;
        }
        else{
          usItem.commission = 0;
        }
        // Newly added Update context with the modified upsale item
        upsaleDetails.upsaleItems[i] = usItem;
        orderContext.updateCtxUpsaleDetails(upsaleDetails);
      }
    };// PriceBefore customer Net active logic end

    //PriceInternal active logic
    if(usItem?.part.partDiscount == false){
      if (usItem.part.id === partId && i === index) {
        usItem.customerShare = formatCurrencyToDecimal(value);
        const dom = document.getElementById(`customerShare${index}`);
        if (usItem.customerShare > 0) {
          // if (usItem.part.priceInternal) {
          //   usItem.discount = usItem.part.priceInternal - usItem.customerShare;
            
          //   if(usItem.discount < 0){
          //     usItem.discount = 0.00
          //   }
          //   // if (usItem.customerShare > usItem.part.priceInternal) {
          //   //   if(dom) dom.setAttribute("state", "error");
          //   // } else {
          //   //   if(dom) dom.setAttribute("state", "none");
          //   // }
          // } 

          if (usItem.part?.priceInternal && usItem.part.priceBeforeCustomerNet) {
            usItem.discount = usItem.part.priceInternal - usItem.customerShare;
            //
            if(usItem.discount < 0){
              usItem.discount = 0.00
            }
            if (usItem.customerShare > usItem.part.priceBeforeCustomerNet) {
              if(dom) dom.setAttribute("state", "error");
            } else {
              if(dom) dom.setAttribute("state", "none");
            }
          }
          
          
          else {
            usItem.discount = 0.00
          }

          if(usItem?.part?.priceInternal){
            if(usItem.customerShare < 0){
              usItem.customerShare = 0
              if(dom) dom.setAttribute("state", "error");
            }
            
          }
          if(usItem?.part?.priceBeforeCustomerNet){
            if(usItem.customerShare > usItem?.part.priceBeforeCustomerNet){
               usItem.customerShare = 0
               if(dom) dom.setAttribute("state", "error");
          }
        }
        }else {
          if (usItem.customerShare < 0) {
            if(dom) dom.setAttribute("state", "error");
          } else {
            if(dom) dom.setAttribute("state", "none");
          }
          usItem.discount = usItem.part.priceInternal ? usItem.part.priceInternal : 0.00;
        }
        /**
         * commission calculation
         */
        let discoutPercenatge = 0.0;

        
        if (usItem.customerShare > 0 && usItem.part.priceInternal !== undefined && usItem.part.priceInternal > 0) {
          discoutPercenatge = ((usItem.part.priceInternal - usItem.customerShare) / usItem.part.priceInternal) * 100;
        } else if (usItem.part.priceInternal && usItem.part.priceInternal !== undefined && usItem.part.priceInternal > 0) {
          discoutPercenatge = (usItem.part.priceInternal / usItem.part.priceInternal) * 100;
        }
        

        if (discoutPercenatge > 0) {
          
          let vc = calculateRangeValue(discoutPercenatge, "CompleteWinterTires");
          
          let commision = 0.0;
          if (vc && vc?.payoutRefence1 === "SellingPrice" && usItem.part.priceBeforeCustomerNet !== undefined ) {
            commision = usItem.part.priceBeforeCustomerNet * vc.payoutValue1 / 100;
          } else if (vc && vc?.payoutRefence1 === "CustomerShare" && usItem.part.priceInternal !== undefined) {
            commision = commision + usItem.customerShare * vc.payoutValue1 / 100;
          } else if (vc && vc?.payoutRefence1 === "Euro") {
            commision = commision + vc.payoutValue1;
          }
          if (commissionObj.payOut2) {
          if (vc && vc?.payoutRefence2 === "SellingPrice" && vc.payoutValue2 && usItem.part.priceBeforeCustomerNet !== undefined) {
            commision = commision + usItem.part.priceBeforeCustomerNet * vc.payoutValue2 / 100;
          } else if (vc && vc?.payoutRefence2 === "CustomerShare" && vc.payoutValue2 && usItem.part.priceInternal !== undefined) {
            commision = commision + usItem.customerShare * vc.payoutValue2 / 100;
          } else if (vc && vc?.payoutRefence2 === "Euro" && vc.payoutValue2) {
            commision = commision + vc.payoutValue2;
          }
        }
          
          usItem.commission = commision;
        }
        else{
          usItem.commission = 0;
        }

        // Newly added Update context with the modified upsale item
        upsaleDetails.upsaleItems[i] = usItem;
        orderContext.updateCtxUpsaleDetails(upsaleDetails);
      }
    };//PriceInternal active logic end

    //when user in Draft edit mode and partDiscount is undefined
    if(usItem?.part.partDiscount == undefined){
      if (usItem.part.id === partId && i === index) {
        usItem.customerShare = formatCurrencyToDecimal(value);
        const dom = document.getElementById(`customerShare${index}`);
        if (usItem.customerShare > 0) {
          if (usItem.part.priceBeforeCustomerNet) {
            usItem.discount = usItem.part.priceBeforeCustomerNet - usItem.customerShare;
            //
            if(usItem.discount < 0){
              usItem.discount = 0.00
            }
            if (usItem.customerShare > usItem.part.priceBeforeCustomerNet) {
              if(dom) dom.setAttribute("state", "error");
            } else {
              if(dom) dom.setAttribute("state", "none");
            }
          } else {
            usItem.discount = 0.00
          }
          if(usItem?.part?.priceInternal){
            if(usItem.customerShare < 0){
              usItem.customerShare = 0
              if(dom) dom.setAttribute("state", "error");
            }
            
          }
          if(usItem?.part?.priceBeforeCustomerNet){
            if(usItem.customerShare > usItem?.part.priceBeforeCustomerNet){
               usItem.customerShare = 0
               if(dom) dom.setAttribute("state", "error");
          }
        }


        } else {
          if (usItem.customerShare < 0) {
            if(dom) dom.setAttribute("state", "error");
          } else {
            if(dom) dom.setAttribute("state", "none");
          }
          usItem.discount = usItem.part.priceInternal ? usItem.part.priceInternal : 0.00;
        }
        /**
         * commission calculation
         */
        let discoutPercenatge = 0.0;
        
        if (usItem.customerShare > 0 && usItem.part.priceBeforeCustomerNet !== undefined && usItem.part.priceBeforeCustomerNet > 0) {
          discoutPercenatge = ((usItem.part.priceBeforeCustomerNet - usItem.customerShare) / usItem.part.priceBeforeCustomerNet) * 100;
        } else if (usItem.part.priceInternal && usItem.part.priceBeforeCustomerNet !== undefined && usItem.part.priceBeforeCustomerNet > 0) {
          discoutPercenatge = (usItem.part.priceInternal / usItem.part.priceBeforeCustomerNet) * 100;
        }
        
        if (discoutPercenatge > 0) {
          
          let vc = calculateRangeValue(discoutPercenatge, "CompleteWinterTires");
          
          let commision = 0.0;
        
          if (vc && vc?.payoutRefence1 === "SellingPrice" && usItem.part.priceBeforeCustomerNet !== undefined ) {

            commision = usItem.part.priceBeforeCustomerNet * vc.payoutValue1 / 100;
          } else if (vc && vc?.payoutRefence1 === "CustomerShare" && usItem.part.priceBeforeCustomerNet !== undefined) {
            commision = commision + usItem.customerShare * vc.payoutValue1 / 100;
          } else if (vc && vc?.payoutRefence1 === "Euro") {
            commision = commision + vc.payoutValue1;
          }
          if (commissionObj.payOut2) {
          if (vc && vc?.payoutRefence2 === "SellingPrice" && vc.payoutValue2 && usItem.part.priceBeforeCustomerNet !== undefined) {
            commision = commision + usItem.part.priceBeforeCustomerNet * vc.payoutValue2 / 100;
          } else if (vc && vc?.payoutRefence2 === "CustomerShare" && vc.payoutValue2 && usItem.part.priceBeforeCustomerNet !== undefined) {
            commision = commision + usItem.customerShare * vc.payoutValue2 / 100;
          } else if (vc && vc?.payoutRefence2 === "Euro" && vc.payoutValue2) {
            commision = commision + vc.payoutValue2;
          }
        }
          
          usItem.commission = commision;
        }
        else{
          usItem.commission=0
        }
        // Newly added Update context with the modified upsale item
        upsaleDetails.upsaleItems[i] = usItem;
        orderContext.updateCtxUpsaleDetails(upsaleDetails);
      }
    };

  })
    upsaleDetails.upsaleItems = upsaleItems;
    // setCustomerShareError(!validateCustomerShare(upsaleItems));
    setTotal(getUpsaleItemsTotal(upsaleItems.filter(x => x.partType === PartType.tire)));
    setUpsaleItems(upsaleItems);
    
    handleModified();
    setReload(new Date().toLocaleString());
  }

  const validateCustomerShare = (usItems: Array<UpsaleItemDTO>) => {
    const tire = usItems.filter(x => x.partType === "tire");
    if(tire.length === 1){
      if(tire[0].discount < 0) return false;
      if(tire[0].serviceItems.length > 0) {
        if(tire[0].serviceItems[0].customerShare !== undefined &&
          tire[0].serviceItems[0].services.priceBeforeCustomerNet !== undefined &&
          tire[0].serviceItems[0].customerShare > tire[0].serviceItems[0].services.priceBeforeCustomerNet
        ) return false;
      }
      if(tire[0].serviceItems.length > 1) {
        if(tire[0].serviceItems[1].customerShare !== undefined &&
          tire[0].serviceItems[1].services.priceBeforeCustomerNet !== undefined &&
          tire[0].serviceItems[1].customerShare > tire[0].serviceItems[1].services.priceBeforeCustomerNet
        ) return false;
      }
    }
    return true;
  }

  const isWinterTirePreSelected = (row: UpsaleItemDTO) => {
    if (!upsaleDetails.upsaleItems) return false
    return upsaleDetails.upsaleItems.every(item => {
      return item.part.id === row.part.id
    })
  }

  const handleTireService = (i: number, value: string, serviceIndex: number, mainIndex: number) => {
    //TO DO Need to caculate on delivery location if service name is Mounting

    let service: Array<WorkshopAndServiceCostsDTO> = [];
    let serviceFilter: Array<WorkshopAndServiceCostsDTO> = [];
    
    
    if(value===""){
      serviceFilter=[]
      setUpsaleItems([])
      serviceIndex===0 && setEnableWinterTyreCustomerShare(false)
      upsaleDetails.upsaleItems = [];
      orderContext.updateCtxUpsaleDetails(upsaleDetails);
      return
    }else{
      serviceIndex===0 && setEnableWinterTyreCustomerShare(true)
    }

    if (value === "Mounting (Standard)") {
      serviceFilter = services.filter(x => x.serviceNameAdmin !== undefined && x.serviceNameUpsale === value && x.serviceNameAdmin?.indexOf(deliveryLocation) > -1);
    } if (value === "Mounting (Center Lock)" && deliveryLocation === "PorscheCenter") {
      serviceFilter = services.filter(x => x.serviceNameAdmin !== undefined && (x.serviceNameUpsale === value));
    } else {
      serviceFilter = services.filter(x => x.serviceNameUpsale === value);
    }

    upsaleItems.map((usItem, j) => {
      if (usItem.part.id === i && j === mainIndex) {
        if (serviceIndex) {
          const sIDTO = {} as ServiceItemDTO;
          sIDTO.services = serviceFilter[0];
          //sIDTO.winterOrSummer = WinterOrSummer.winter;
          sIDTO.customerShare = 0.00;
          sIDTO.commission = 0.00;
          //sIDTO.discount = 0.00;
          sIDTO.discount =usItem.part?.partDiscount? serviceFilter[0].priceBeforeCustomerNet :  serviceFilter[0].priceInternal;
          usItem.serviceItems[serviceIndex] = sIDTO;
        } else {
          const sIDTO = {} as ServiceItemDTO;
          sIDTO.services = serviceFilter[0];
          sIDTO.winterOrSummer = WinterOrSummer.winter;
          sIDTO.customerShare = 0.00;
          sIDTO.discount = usItem.part?.partDiscount? serviceFilter[0].priceBeforeCustomerNet :  serviceFilter[0].priceInternal;
          sIDTO.commission = 0.00;
          usItem.serviceItems = [sIDTO];

          if (value.indexOf("Mounting") > -1) {
            serviceFilter = services.filter(x => x.serviceNameUpsale === "Storage");
            const sIDTO = {} as ServiceItemDTO;
            sIDTO.services = serviceFilter[0];
            sIDTO.winterOrSummer = WinterOrSummer.summer;
            sIDTO.customerShare = 0.00;
            sIDTO.discount = usItem.part?.partDiscount? serviceFilter[0].priceBeforeCustomerNet :  serviceFilter[0].priceInternal;
            sIDTO.commission = 0.00;
            usItem.serviceItems[1] = sIDTO;
            upsaleDetails.upsaleItems[0].serviceItems = usItem.serviceItems
          }
        }
      }
    })

    // if (upsaleDetails.upsaleItems !== undefined && upsaleDetails.upsaleItems.length > 0) {
    //   //upsaleDetails.upsaleItems[0].serviceItems = upsaleDetails.upsaleItems.concat(upsaleItems);
    // } else {
    //   upsaleDetails.upsaleItems = upsaleItems;
    // }
    upsaleDetails.upsaleItems = upsaleItems;
    orderContext.updateCtxUpsaleDetails(upsaleDetails);
    if (serviceFilter.length > 0) {
      
      orderContext.removeSectionError("upsaleCwt");
      const isValid = isValidUpsale()
      orderContext.updateFormValidation(1, isValid)
      enableNextButton(isValid)
    }

    
    // upsaleDetails.upsaleItems = upsaleItems;
    // orderContext.updateCtxUpsaleDetails(upsaleDetails);

    setTotal(getUpsaleItemsTotal(upsaleItems.filter(x => x.partType === PartType.tire)));
    setUpsaleItems(upsaleItems);
    handleModified();
    setReload(new Date().toLocaleString());
  }

  const handleServiceCustomerShare = (partId: number, value: string, serviceIndex: number, mainIndex: number) => {
    upsaleItems.map((usItem, j) => {
      //PriceBefore customer Net active logic
    if(usItem?.part.partDiscount == true){
        if (usItem.part.id === partId && j === mainIndex) {
          
          usItem.serviceItems[serviceIndex].customerShare = formatCurrencyToDecimal(value);
          const dom = document.getElementById(`s0customerShare${serviceIndex}`);
          if (usItem.serviceItems[serviceIndex].customerShare > 0) {
            if (usItem.serviceItems[serviceIndex].services.priceBeforeCustomerNet !== undefined) {
              usItem.serviceItems[serviceIndex].discount = usItem.serviceItems[serviceIndex].services.priceBeforeCustomerNet
                ? usItem.serviceItems[serviceIndex].services.priceBeforeCustomerNet - usItem.serviceItems[serviceIndex].customerShare
                : usItem.serviceItems[serviceIndex].services.priceInternal;

                if(usItem.serviceItems[serviceIndex].discount < 0){
                  usItem.serviceItems[serviceIndex].discount = 0.00
                }

              if (usItem.serviceItems[serviceIndex].customerShare > usItem.serviceItems[serviceIndex].services.priceBeforeCustomerNet) {
                if(dom) dom.setAttribute("state", "error");
              } else {
                if(dom) dom.setAttribute("state", "none");
              }
            }
            if(usItem.serviceItems[serviceIndex].services.priceBeforeCustomerNet){
              if(usItem.serviceItems[serviceIndex].customerShare > usItem.serviceItems[serviceIndex].services.priceBeforeCustomerNet ){
               usItem.serviceItems[serviceIndex].customerShare = 0
               if(dom) dom.setAttribute("state", "error");
              }
            } 
   
            if(usItem.serviceItems[serviceIndex].services.priceInternal){
             if(usItem.serviceItems[serviceIndex].customerShare < 0 ){
              usItem.serviceItems[serviceIndex].customerShare = 0
              if(dom) dom.setAttribute("state", "error");
             }
           } 
          } else {
            if (usItem.serviceItems[serviceIndex].customerShare < 0) {
              if(dom) dom.setAttribute("state", "error");
            } else {
              if(dom) dom.setAttribute("state", "none");
            }
            //usItem.serviceItems[serviceIndex].services.pricingId.discountInternal = usItem.serviceItems[serviceIndex].services.pricingId.priceInternal;
            usItem.serviceItems[serviceIndex].discount = usItem.serviceItems[serviceIndex].services.priceBeforeCustomerNet;
          }
        }
    };//PriceBefore customer Net active logic End

    //PriceInternal active logic
    if(usItem?.part.partDiscount == false){
      if (usItem.part.id === partId && j === mainIndex) {
        
        usItem.serviceItems[serviceIndex].customerShare = formatCurrencyToDecimal(value);
        const dom = document.getElementById(`s0customerShare${serviceIndex}`);
        if (usItem.serviceItems[serviceIndex].customerShare > 0) {
          if (usItem.serviceItems[serviceIndex].services.priceInternal !== undefined) {
            usItem.serviceItems[serviceIndex].discount = usItem.serviceItems[serviceIndex].services.priceInternal
              ? usItem.serviceItems[serviceIndex].services.priceInternal - usItem.serviceItems[serviceIndex].customerShare
              : usItem.serviceItems[serviceIndex].services.priceBeforeCustomerNet;

              if(usItem.serviceItems[serviceIndex].discount < 0){
                  usItem.serviceItems[serviceIndex].discount = 0.00
                }

            if (usItem.serviceItems[serviceIndex].customerShare > usItem.serviceItems[serviceIndex].services.priceBeforeCustomerNet) {
              if(dom) dom.setAttribute("state", "error");
            } else {
              if(dom) dom.setAttribute("state", "none");
            }
          }
          if(usItem.serviceItems[serviceIndex].services.priceBeforeCustomerNet){
            if(usItem.serviceItems[serviceIndex].customerShare > usItem.serviceItems[serviceIndex].services.priceBeforeCustomerNet ){
             usItem.serviceItems[serviceIndex].customerShare = 0
             if(dom) dom.setAttribute("state", "error");
            }
          } 
 
          if(usItem.serviceItems[serviceIndex].services.priceInternal){
           if(usItem.serviceItems[serviceIndex].customerShare < 0 ){
            usItem.serviceItems[serviceIndex].customerShare = 0
            if(dom) dom.setAttribute("state", "error");
           }
         } 
        } else {

          if (usItem.serviceItems[serviceIndex].customerShare < 0) {
            if(dom) dom.setAttribute("state", "error");
          } else {
            if(dom) dom.setAttribute("state", "none");
          }
          //usItem.serviceItems[serviceIndex].services.pricingId.discountInternal = usItem.serviceItems[serviceIndex].services.pricingId.priceInternal;
          usItem.serviceItems[serviceIndex].discount = usItem.serviceItems[serviceIndex].services.priceInternal;
          // if(dom) dom.setAttribute("state", "none");
        }
      }
    };//PriceInternal Active logic end

    //when user in Draft edit mode and partDiscount is undefined
    if(usItem?.part.partDiscount == undefined){
      if (usItem.part.id === partId && j === mainIndex) {
        
        usItem.serviceItems[serviceIndex].customerShare = formatCurrencyToDecimal(value);
        const dom = document.getElementById(`s0customerShare${serviceIndex}`);
        if (usItem.serviceItems[serviceIndex].customerShare > 0) {
          if (usItem.serviceItems[serviceIndex].services.priceBeforeCustomerNet !== undefined) {
            usItem.serviceItems[serviceIndex].discount = usItem.serviceItems[serviceIndex].services.priceBeforeCustomerNet
              ? usItem.serviceItems[serviceIndex].services.priceBeforeCustomerNet - usItem.serviceItems[serviceIndex].customerShare
              : usItem.serviceItems[serviceIndex].services.priceInternal;

              if(usItem.serviceItems[serviceIndex].discount < 0){
                usItem.serviceItems[serviceIndex].discount = 0.00
              }

            if (usItem.serviceItems[serviceIndex].customerShare > usItem.serviceItems[serviceIndex].services.priceBeforeCustomerNet) {
              if(dom) dom.setAttribute("state", "error");
            } else {
              if(dom) dom.setAttribute("state", "none");
            }
            
          }
         if(usItem.serviceItems[serviceIndex].services.priceBeforeCustomerNet){
           if(usItem.serviceItems[serviceIndex].customerShare > usItem.serviceItems[serviceIndex].services.priceBeforeCustomerNet ){
            usItem.serviceItems[serviceIndex].customerShare = 0
            if(dom) dom.setAttribute("state", "error");
           }
         } 

         if(usItem.serviceItems[serviceIndex].services.priceInternal){
          if(usItem.serviceItems[serviceIndex].customerShare < 0 ){
           usItem.serviceItems[serviceIndex].customerShare = 0
           if(dom) dom.setAttribute("state", "error");
          }
        } 

        } else {
          if (usItem.serviceItems[serviceIndex].customerShare < 0) {
            if(dom) dom.setAttribute("state", "error");
          } else {
            if(dom) dom.setAttribute("state", "none");
          }
          //usItem.serviceItems[serviceIndex].services.pricingId.discountInternal = usItem.serviceItems[serviceIndex].services.pricingId.priceInternal;
          usItem.serviceItems[serviceIndex].discount = usItem.serviceItems[serviceIndex].services.priceInternal;
          // if(dom) dom.setAttribute("state", "none");
        }
      }
    };

    })

    upsaleDetails.upsaleItems = upsaleItems;
    // setCustomerShareError(!validateCustomerShare(upsaleItems));
    orderContext.updateCtxUpsaleDetails(upsaleDetails);
    setTotal(getUpsaleItemsTotal(upsaleItems.filter(x => x.partType === PartType.tire)));
    setUpsaleItems(upsaleItems);
    
    handleModified();
    setReload(new Date().toLocaleString());
  }

  const handleDelete = (partId: number, index: number) => {
    upsaleItems.map((usItem, i) => {
      if (usItem.part.id === partId && i === index) {
        upsaleItems.splice(i, 1);

        setTireAdded(false);
        setTotal(getUpsaleItemsTotal(upsaleItems.filter(x => x.partType === PartType.tire)));
        setUpsaleItems(upsaleItems);
        handleModified();
        setReload(new Date().toLocaleString());
      }
    })

    orderContext.setErrors({
      field: "tire",
      message: "",
      section: "upsaleCwt",
    })
    orderContext.setErrors({
      field: "winterTire",
      message: "",
      section: "upsaleCwt",
    })

    const isValid = isValidUpsale()
    orderContext.updateFormValidation(1, isValid)
    enableNextButton(isValid)
  }

  return (
    <>
      <div className="pt10">
        <PFlex>
          <PFlexItem>
            <PTextFieldWrapper label={`${t("search")}`} hideLabel={true} style={{ width: "440px" }}>
              <input
                type="search"
                name="search"
                placeholder={`${t("Search for number or name")}`}
                onChange={(e) => handleSearch(e.target.value)}
                value={searchValue}
                autoComplete="off"
                disabled={disabled}
              />
            </PTextFieldWrapper>
            <SearchResult />
          </PFlexItem>
          <PFlexItem style={{ paddingLeft: "8px" }}>
            <PButton
              icon={"add" as IconName}
              style={{
                minWidth: "112px",
                height: "40px",
              }}
              type="button"
              onClick={handleAddTier}
              disabled={upsaleItems && upsaleItems.filter(x => x.partType === PartType.tire).length > 0 ? true : false}
            >
              {t("Add Tire")}
            </PButton>
          </PFlexItem>
        </PFlex>
      </div>
      <div className="pt32">
        <PTable>
          <PTableHead>
            <PTableHeadRow>
              <PTableHeadCell className='table-column-header'><PorscheFont style={{ fontWeight: "700" }}> {t("Complete Winter Tires")} </PorscheFont></PTableHeadCell>
              <PTableHeadCell className='table-column-header' style={{ textAlign: "right" }}><PorscheFont style={{ fontWeight: "700" }}> {t("Price internal")} </PorscheFont></PTableHeadCell>
              <PTableHeadCell style={{ textAlign: "right" }}><PorscheFont style={{ fontWeight: "700" }}> {t("Selling price (net)")}</PorscheFont></PTableHeadCell>
              <PTableHeadCell style={{ textAlign: "right" }}><PorscheFont style={{ fontWeight: "700" }}> {t("Customer share")} </PorscheFont></PTableHeadCell>
              <PTableHeadCell style={{ textAlign: "right" }}><PorscheFont style={{ fontWeight: "700" }}> {t("Discount")} </PorscheFont></PTableHeadCell>
              <PTableHeadCell style={{ textAlign: "right" }}><PorscheFont style={{ fontWeight: "700" }}> {t("Commission")} </PorscheFont></PTableHeadCell>
              <PTableHeadCell hideLabel={true}></PTableHeadCell>
            </PTableHeadRow>
          </PTableHead>
          <PTableBody>
            {upsaleItems?.map((row, i) => {
              if (row.partType === "tire") {
                return (
                  <>
                    <PTableRow key={i}>
                      <PTableCell>
                        <PFlex direction={"column"}>
                          <PFlexItem>
                            <PFlex direction={"column"}>
                              <PFlexItem>
                                <PText weight='bold'>{row.part.part}</PText>
                              </PFlexItem>
                              <PFlexItem>
                                <PText >{row.part.serialNumber}</PText>
                              </PFlexItem>
                            </PFlex>
                          </PFlexItem>

                          <PFlexItem className="pt10">
                            <PFlex direction={"row"} >
                              <PFlexItem className='pr6'>
                                <PIcon className='pt8' name="wrench" aria={{ 'aria-label': 'Wrench icon' }} />
                              </PFlexItem>
                              <PFlexItem>
                                <PSelectWrapper label="Winter Tire" hideLabel={true} style={{ zIndex: "30", width: "220px" }} dropdownDirection="down">
                                  <select
                                    name="services"
                                    value={row?.serviceItems?.length > 0 ? row?.serviceItems[0]?.services?.serviceNameUpsale : ""}
                                    onChange={e => handleTireService(row.part.id, e.target.value, 0, i)}
                                    disabled={disabled}
                                  >
                                    <option key={10} value={""}>{t("Select service")}</option>
                                    {serviceNames.map((serviceName, i) => {
                                      return <option key={i} value={serviceName} selected={isWinterTirePreSelected(row)}>{t(serviceName)} </option>
                                    })

                                    }
                                  </select>
                                </PSelectWrapper>
                              </PFlexItem>
                              <PFlexItem>
                                <PText size="small" style={{ paddingLeft: "8px", paddingTop: "10px" }}>{t("Winter tires")} <span style={{ color: "#D5001B" }}>*</span></PText>
                              </PFlexItem>
                            </PFlex>
                          </PFlexItem>

                          {(row.serviceItems.length > 0) && (row?.serviceItems[0]?.services?.serviceNameUpsale.indexOf("Mounting") > -1) && (
                            <PFlexItem className="pt10">
                              <PFlex direction={"row"} >
                                <PFlexItem className='pr6'>
                                  <PIcon className='pt8' name="wrench" aria={{ 'aria-label': 'Wrench icon' }} />
                                </PFlexItem>
                                <PFlexItem>
                                  <PSelectWrapper label="Winter Tire" hideLabel={true} style={{ zIndex: "30", width: "220px" }} dropdownDirection="up" >
                                    <select
                                      name="services"
                                      value={row?.serviceItems[1] ? row?.serviceItems[1]?.services?.serviceNameUpsale : "Storage"}
                                      onChange={e => handleTireService(row.part.id, e.target.value, 1, i)}
                                      disabled={disabled}
                                    >
                                      <option key={10} value={""}>{t("Select service")}</option>
                                      {serviceNames.map((serviceName, i) => {
                                        if (!(serviceName === "Mounting (Standard)" || serviceName === "Mounting (Center Lock)")) {
                                          return <option key={i} value={serviceName}>{t(serviceName)}</option>
                                        }
                                      })
                                      }
                                    </select>
                                  </PSelectWrapper>
                                </PFlexItem>
                                <PFlexItem>
                                  <PText size="small" style={{ paddingLeft: "8px", paddingTop: "10px" }}>{t("Summer tires")} <span style={{ color: "#D5001B" }}>*</span></PText>
                                </PFlexItem>
                              </PFlex>
                            </PFlexItem>
                          )}
                        </PFlex>
                      </PTableCell>

                      <PTableCell>
                        <PFlex direction={"column"} alignItems={"flex-end"}>
                          <PFlexItem>
                            {row.part.priceInternal !== undefined ? formatCurrency(row.part.priceInternal) : "-"}
                          </PFlexItem>

                          <PFlexItem className="pt32">
                            {row?.serviceItems?.length > 0 && row?.serviceItems[0]?.services?.priceInternal !== undefined
                              ? formatCurrency(row?.serviceItems[0]?.services?.priceInternal)
                              : "-"
                            }
                          </PFlexItem>

                          {(row?.serviceItems?.length > 0) && (row?.serviceItems[0]?.services?.serviceNameUpsale.indexOf("Mounting") > -1) && (
                            <PFlexItem className="pt32">
                              {formatCurrency(row?.serviceItems[1]?.services?.priceInternal)}
                            </PFlexItem>
                          )}
                        </PFlex>
                      </PTableCell>

                      <PTableCell>
                        <PFlex direction={"column"} alignItems={"flex-end"}>
                          <PFlexItem>
                            {row.part.priceBeforeCustomerNet !== undefined ? formatCurrency(row.part.priceBeforeCustomerNet) : "-"}
                          </PFlexItem>

                          <PFlexItem className="pt32">
                            {row?.serviceItems?.length > 0 && row?.serviceItems[0]?.services?.priceBeforeCustomerNet !== undefined
                              ? formatCurrency(row?.serviceItems[0]?.services?.priceBeforeCustomerNet)
                              : "-"
                            }
                          </PFlexItem>

                          {(row?.serviceItems?.length > 0) && (row?.serviceItems[0]?.services?.serviceNameUpsale.indexOf("Mounting") > -1) && (
                            <PFlexItem className="pt32">
                              {formatCurrency(row?.serviceItems[1]?.services?.priceBeforeCustomerNet)}
                            </PFlexItem>
                          )}
                        </PFlex>
                      </PTableCell>

                      <PTableCell>
                        <PFlex direction={"column"} alignItems={"flex-end"}>
                          <PFlexItem >
                            <PTextFieldWrapper id={`customerShare${i}`} className='pt16' label={`customerShare${i}`} hideLabel={true} unit="€" unitPosition="suffix" showCharacterCount={false}>
                              <input
                                maxLength={inputFieldMaxLength}
                                type="text"
                                name={`customerShare${i}`}
                                defaultValue={formatCurrencyWithNoUnit(row.customerShare)}
                                //value={row.customerShare}
                                onChange={(e) => handleCustomerShare(row.part.id, e.target.value, i)}
                                onBlur={(e) => e.target.value = formatCurrencyWithNoUnit(row.customerShare)}
                                style={{ textAlign: "right" }}
                                disabled={disabled}
                              />
                            </PTextFieldWrapper>
                          </PFlexItem>

                          <PFlexItem className="pt10">
                          {row?.serviceItems?.length > 0 && row?.serviceItems[0]?.services?.priceBeforeCustomerNet !== undefined
                              ? 
                              <PTextFieldWrapper id={`s0customerShare0`} className="pt16" label={`s0customerShare0`} hideLabel={true} unit="€" unitPosition="suffix" showCharacterCount={false}>
                              <input
                                maxLength={inputFieldMaxLength}
                                type="text"
                                name={`s0customerShare0`}
                                defaultValue={row?.serviceItems?.length > 0
                                  ? formatCurrencyWithNoUnit(row?.serviceItems[0]?.customerShare)
                                  : "0,00"
                                }
                                onChange={(e) => handleServiceCustomerShare(row.part.id, e.target.value, 0, i)}
                                onBlur={(e) => {
                                  if(row?.serviceItems?.length > 0){
                                    e.target.value = formatCurrencyWithNoUnit(row?.serviceItems[0]?.customerShare)
                                  }
                                  
                                }}
                                style={{ textAlign: "right" }}
                                disabled={disabled }
                                

                              />
                            </PTextFieldWrapper>
                              : "-"
                            }
                          
                          </PFlexItem>

                          {(row?.serviceItems?.length > 0) && (row?.serviceItems[0]?.services?.serviceNameUpsale.indexOf("Mounting") > -1) && (
                            <PFlexItem className="pt10">
                              <PTextFieldWrapper id={`s0customerShare1`} className="pt16" label={`s0customerShare1`} hideLabel={true} unit="€" unitPosition="suffix" showCharacterCount={false}>
                                <input
                                  maxLength={inputFieldMaxLength}
                                  type="text"
                                  name={`s0customerShare1`}
                                  defaultValue={row?.serviceItems?.length > 0
                                    ? formatCurrencyWithNoUnit(row?.serviceItems[1]?.customerShare)
                                    : "0,00"
                                  }
                                  onChange={(e) => handleServiceCustomerShare(row.part.id, e.target.value, 1, i)}
                                  onBlur={(e) => {
                                    if(row?.serviceItems?.length > 1){
                                      e.target.value = formatCurrencyWithNoUnit(row?.serviceItems[1]?.customerShare)
                                    }
                                    
                                  }}
                                  style={{ textAlign: "right" }}
                                  disabled={disabled}
                                />
                              </PTextFieldWrapper>
                            </PFlexItem>
                          )}
                        </PFlex>
                      </PTableCell>

                      <PTableCell>
                        <PFlex direction={"column"}  alignItems={"flex-end"}>
                          {/* {row.part.partDiscount == true ? */}
                          <PFlexItem className='myData'>
                            {/* {row.customerShare > 0
                              ? row.part.priceBeforeCustomerNet !== undefined
                                ? formatCurrency(row.part.priceBeforeCustomerNet - row.customerShare)
                                : '-'
                              : row.part.priceInternal !== undefined
                                ? formatCurrency(row.part.priceInternal - row.customerShare)
                                : '-'
                            } */}
                            
                            {/* {(row.customerShare > 0) && (row.part.partDiscount == true) && (row.part.priceBeforeCustomerNet !== undefined)
                              ? formatCurrency(row.part.priceBeforeCustomerNet - row.customerShare) : (row.customerShare > 0) && (row.part.partDiscount == false) && (row.part.priceInternal !== undefined) ? formatCurrency(row.part.priceInternal - row.customerShare)  : '-'
                            } */}


                            {row?.discount && row?.discount < 0 ? "0.00 €" :  formatCurrency(row.discount)  }

                            {/* {row.discount != 0 ?(row.customerShare > 0) && (row.part.partDiscount == true) && (row.part.priceBeforeCustomerNet !== undefined)
                              ? formatCurrency(row.part.priceBeforeCustomerNet - row.customerShare) : (row.customerShare > 0) && (row.part.partDiscount == false) && (row.part.priceInternal !== undefined) ? formatCurrency((row.part.priceInternal - row.customerShare)<0? 0:row.part.priceInternal - row.customerShare)  : '-' : formatCurrency(0.00)} */}
                          </PFlexItem> 
                          {/* :  <PFlexItem>
                          {row.customerShare > 0 && row.part.partDiscount == false && row.part.priceInternal !== undefined
                              ? formatCurrency(row.part.priceInternal - row.customerShare) : '-'
                            }
                          </PFlexItem>} */}

                          {/* {row.part.partDiscount == true ? */}
                          <PFlexItem className="pt32 myData2">
                            {/* {row?.serviceItems?.length > 0
                              ? row?.serviceItems[0]?.customerShare > 0
                                ? row?.serviceItems[0]?.services?.priceBeforeCustomerNet !== undefined
                                  ? formatCurrency(row?.serviceItems[0]?.services?.priceBeforeCustomerNet - row?.serviceItems[0]?.customerShare)
                                  : '-'
                                : row?.serviceItems[0]?.services?.priceInternal !== undefined
                                  ? formatCurrency(row?.serviceItems[0]?.services?.priceInternal)
                                  : '-'
                              : '-'
                            } */}

                            {/* {(row?.serviceItems?.length > 0) && (row?.serviceItems[0].discount != 0) && (row?.serviceItems[0]?.customerShare > 0) ? (row?.serviceItems?.length > 0) && (row?.serviceItems[0]?.customerShare > 0) && (row.part.partDiscount == true) && (row?.serviceItems[0]?.services?.priceBeforeCustomerNet !== undefined)
                              ? formatCurrency(row?.serviceItems[0]?.services?.priceBeforeCustomerNet - row?.serviceItems[0]?.customerShare) : (row?.serviceItems?.length > 0) && (row?.serviceItems[0]?.customerShare > 0) && (row.part.partDiscount == false) && (row?.serviceItems[0]?.services?.priceInternal !== undefined) ? formatCurrency(row?.serviceItems[0]?.services?.priceInternal - row?.serviceItems[0]?.customerShare) : '-'
                            : formatCurrency(0.00)} */}
                            {/* { row?.serviceItems[0]?.discount && row?.serviceItems[0].discount < 0 && '0,00 €'  } */}
                            {row.serviceItems.length > 0 ? (row?.serviceItems[0]?.discount && row?.serviceItems[0].discount > 0) ? formatCurrencyWithNoUnit(row?.serviceItems[0].discount) + ' €':'0,00 €' : null}
                           {  }
                            {/* {formatCurrency(0.00)} */}

                          </PFlexItem>
                          {/* // : <PFlexItem>
                          // {row?.serviceItems?.length > 0 && row?.serviceItems[0]?.customerShare > 0 && row.part.partDiscount == false && row?.serviceItems[0]?.services?.priceInternal !== undefined
                          //     ? formatCurrency(row?.serviceItems[0]?.services?.priceInternal - row?.serviceItems[0]?.customerShare) : '-'
                          //   }
                          // </PFlexItem> } */}
                          
                          {(row?.serviceItems?.length > 0)  && (row?.serviceItems[0]?.services?.serviceNameUpsale.indexOf("Mounting") > -1) && (
                            <PFlexItem className="pt32">

                              {/* {row?.serviceItems?.length > 0
                                ? row?.serviceItems[1]?.customerShare > 0
                                  ? row?.serviceItems[1]?.services?.priceBeforeCustomerNet !== undefined
                                    ? formatCurrency(row?.serviceItems[1]?.services?.priceBeforeCustomerNet - row?.serviceItems[1]?.customerShare)
                                    : '-'
                                  : row?.serviceItems[1]?.services?.priceInternal !== undefined
                                    ? formatCurrency(row?.serviceItems[1]?.services?.priceInternal)
                                    : '-'
                                : '-'
                              } */}

                              {/* {(row?.serviceItems?.length > 0) && (row?.serviceItems[1].discount != 0) && (row?.serviceItems[1]?.customerShare > 0) ? (row.part.partDiscount == true) && (row?.serviceItems?.length > 0) && (row?.serviceItems[1]?.customerShare > 0) && (row?.serviceItems[1]?.services?.priceBeforeCustomerNet !== undefined) ? formatCurrency(row?.serviceItems[1]?.services?.priceBeforeCustomerNet - row?.serviceItems[1]?.customerShare) : (row.part.partDiscount == false) && (row?.serviceItems?.length > 0) && (row?.serviceItems[1]?.customerShare > 0) && (row?.serviceItems[1]?.services?.priceInternal !== undefined) ? formatCurrency(row?.serviceItems[1]?.services?.priceInternal - row?.serviceItems[1]?.customerShare) : '-' : formatCurrency(0.00)} */}
                              {row?.serviceItems[1]?.discount && row?.serviceItems[1]?.discount < 0 ? '0,00 €' : formatCurrency(row?.serviceItems[1].discount)  }
                            </PFlexItem> 
                          )}
                        </PFlex>
                      </PTableCell>

                      <PTableCell>
                        <PFlex direction={"column"} alignItems={"flex-end"}>
                          <PFlexItem>
                            {formatCurrency(row.commission)}
                          </PFlexItem>

                          <PFlexItem className="pt32">
                            {row?.serviceItems?.length > 0 && row?.serviceItems[0]?.commission !== undefined
                              ? formatCurrency(row?.serviceItems[0]?.commission)
                              : "-"
                            }
                          </PFlexItem>

                          {(row?.serviceItems?.length > 0) && (row?.serviceItems[0]?.services?.serviceNameUpsale.indexOf("Mounting") > -1) && (
                            <PFlexItem className="pt32">
                              {row?.serviceItems?.length > 0 && row?.serviceItems[1]?.commission !== undefined
                                ? formatCurrency(row?.serviceItems[1]?.commission)
                                : "0.00 €"
                              }
                            </PFlexItem>
                          )}
                        </PFlex>
                      </PTableCell>

                      <PTableCell>
                        <PFlex direction={"column"}>
                          <PFlexItem >
                            <CustomDelete
                              partId={row.part.id}
                              message={t("Delete this tire")+"?"}
                              handleDelete={(partId) => handleDelete(partId, i)}
                            />
                          </PFlexItem>
                          <PFlexItem className="pt32">
                            <br />
                          </PFlexItem>
                          {(row?.serviceItems?.length > 0) && (row?.serviceItems[0]?.services?.serviceNameUpsale.indexOf("Mounting") > -1) && (
                            <PFlexItem className="pt32">
                              <br />
                            </PFlexItem>
                          )}
                        </PFlex>
                      </PTableCell>
                    </PTableRow>
                  </>
                )
              }
            }

            )}

            {total.priceInternal > 0 && (
              <PTableRow>
                <PTableCell style={{ fontWeight: "700" }}>
                {t("Total")}
                </PTableCell>
                <PTableCell style={{ textAlign: "right", fontWeight: "700" }}>
                  {formatCurrency(total.priceInternal)}
                </PTableCell>
                <PTableCell style={{ textAlign: "right", fontWeight: "700" }}>
                  {formatCurrency(total.sellingPriceNet)}
                </PTableCell>
                <PTableCell style={{ textAlign: "right", fontWeight: "700" }}>
                  {formatCurrency(total.customerShare)}
                </PTableCell>
                <PTableCell style={{ textAlign: "right", fontWeight: "700" }}>
                  {formatCurrency(total.discount)}
                </PTableCell>
                <PTableCell style={{ textAlign: "right", fontWeight: "700" }}>
                  {formatCurrency(total.commission)}
                </PTableCell>
                <PTableCell></PTableCell>
              </PTableRow>
            )
            }
          </PTableBody>
        </PTable>
        { customerShareError && (
          <PGrid>
            {/* <PGridItem size={8}></PGridItem> */}
            <PGridItem size={12} >
              <Stack direction={"row"} style={{ paddingTop: "24px", textAlign: "center", fontSize: "16px", fontWeight: "400" }}>
                <PIcon name={"information" as IconName} color="brand"></PIcon>
                <PorscheFont style={{fontSize: "16px", color: "#D5001B", marginTop: "0px"}}>
                  {t("Customer share can not be higher than selling price.")}
                </PorscheFont>
              </Stack>
            </PGridItem>
          </PGrid>
        )}

        {upsaleItems?.length < 1 && (
          <PGrid>
            <PGridItem size={12} style={{ paddingTop: "24px", paddingBottom: "15px", textAlign: "center", fontSize: "16px", fontWeight: "400" }}>
              {t("No tires selected yet")}
            </PGridItem>
          </PGrid>
        )
        }

        {tireAdded && (
          <PGrid style={{ height: "50px" }}>
            <PGridItem size={12} style={{ paddingTop: "24px", paddingBottom: "15px", textAlign: "center", fontSize: "16px", fontWeight: "400" }}>
            </PGridItem>
          </PGrid>
        )}
      </div>

      <div className='flex30 pt20'>
        <Snackbar open={saveSuccess} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={2000} onClose={handleSuccessClose}>
          <Alert severity="success" sx={{ width: '100%' }}>
            {t("saved successfully")}!
          </Alert>
        </Snackbar>
        <Snackbar open={isError} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={2000} onClose={handleErrorClose}>
          <Alert severity="error" sx={{ width: '100%' }}>
            {t("Customer share can not be higher than selling price.")}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
}
