import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Alert, Snackbar, Stack, Tab, Tabs } from '@mui/material';
import { PButton, PFlex, PFlexItem, PModal } from '@porsche-design-system/components-react';

import { PorscheFont } from '../../../assets/styles/CustomStyles';
import { OrderContext } from '../../../contexts/OrderContext';
import { OrderDTO } from '../../../interfaces/Order';
import { ReCalculation } from '../../../interfaces/ReCalculation';
import { formatDate } from '../../../mock/helper';
import { type } from '../../../services/Constants';
import { dcsgetOrderSummary, dcssaveRecalculation } from '../../../services/data/Helpers';
import { FirstApproval } from '../firstApproval/FirstApproval';
import { ConfirmationPopup } from './ConfirmationPopup';
import { RecalculationDemoCar } from './RecalculationDemoCar';
import { RecalculationNewCar } from './RecalculationNewCar';
import { RecalculationUsedCar } from './RecalculationUsedCar';
import { ResubmitConfirmation } from './ResubmitConfirmation';
import getSessionStorage from '../../../helpers/getSessionStorage';
import { Constant } from '../../../configs/Constants';
import draftValueHandler from '../../../helpers/sessionDraftValueHandler';
import { SingleOrderDetailType } from '../../../interfaces/OrderDetails';
import CustomSnakebar from '../../common/CustomSnakebar';

export const RecalcTab = (): JSX.Element => {
  const orderValues = getSessionStorage(Constant.SingleOrdDetail);
  const [openRecalc, setOpenRecalc] = useState<boolean>(false);
  const [openError, setOpenError] = useState<boolean>(false)
  const { t } = useTranslation();
  const orderContext = useContext(OrderContext);
  const navigate = useNavigate();
  const [order, setOrder] = useState<boolean>(false);
  const [orderData, setOrderData] = useState<OrderDTO>({} as OrderDTO);
  const [creationDate, setCreationDate] = useState<string>();
  const [updatedOnDate, setUpdatedOnDate] = useState<string>();
  const [reload, setReload] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [showApproval, setShowApproval] = useState(false);
  const [id, setId] = useState(0);
  // const [saveSuccess, setSaveSuccess] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showResubmitConfirmation, setShowResubmitConfirmation] = useState(false);
  const [modified, setModified] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [recalculationStatus, setRecalculationStatus] = useState("");
  const [showAlert, setShowAlert] = useState(Constant.None)
  const [alertMessage, setAlertMessage] = useState(Constant.Savedsuccessfully)
  

  useEffect(() => {
    const init = async () => {
      const id = orderValues?.id ? orderValues?.id+'' :''
      const mode =orderValues?.mode ? orderValues?.mode+'' :''
      const isDraftVersion =draftValueHandler(orderValues?.draft)
      if (id && (mode === "view" || mode === "edit")) {
        if(isDraftVersion === "false") {
          setId(parseInt(id));
          const orderResp = await dcsgetOrderSummary(parseInt(id));
          
          if (orderResp.status===type.SUCCESS) {
            const respData = orderResp.response
            setOrder(true);
            let orderDetails = respData?.order || {};
            let upsaleDetails = respData?.upsale || {};
            //
            let calDetails = respData?.calculation
                              ? respData?.calculation 
                              : {};
            //
            let recalculation = respData?.reCalculation ? respData?.reCalculation : {};
            if(orderDetails.createdOn) {
              setCreationDate(new Date(orderDetails.createdOn).toISOString().split('T')[0])
            }

            if(orderDetails.updatedOn) {
              setUpdatedOnDate(new Date(orderDetails.updatedOn).toISOString().split('T')[0])
            }
            //orderContext.updateCtxSaveDraftOrderDetails(saveAsDraft);
            orderContext.updateCtxOrderDetails(orderDetails);
            setOrderData(orderDetails);
            orderContext.updateCtxUpsaleDetails(upsaleDetails);
            orderContext.updateCtxCalculationDraftDetails(calDetails, orderDetails?.vehicleDetails?.category?.type)
            if(recalculation?.calculationType !== undefined && calDetails) {
              let recal = {
                preCalculation: calDetails,
                reCalculation: recalculation
              } as ReCalculation;
              orderContext.updateCtxReCalculation(recal);
            }

            if(respData?.isReCalculationAllowed){
              
              const status = respData?.reCalcStatus;
              setRecalculationStatus(status);
              const isStart = orderValues?.isStart ? orderValues.isStart+'':''
              if(isStart && isStart === "yes") {
                setOpenRecalc(true)
              } else if(status !== undefined && status === "notStarted") {
                setOpenRecalc(true)
              } else {
                setOpenRecalc(false)
              }
            }
            setReload(new Date().toString())
          }
        }
      } 
    }
    init();
  }, [])

  useEffect(() => {}, [reload])

  const handleCloseRecalc = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenRecalc(false);
  }; 

  const handleApprovalClose = () => {
    setShowApproval(false);
  }

  const handleConfirmationClose =() => {
    setShowConfirmation(false);
  }

  const handleResubmitApproval = () => {
    setShowResubmitConfirmation(false);
    setShowApproval(true);
  }

  const handleResubmitConfirmationClose=()=>{
    setShowApproval(false);
    setShowResubmitConfirmation(false);
  }
  
  const handleSave = async () => {
    let order=orderContext.getCtxOrderDetails();
    let recalculation = orderContext.getCtxReCalculation();
    
    const apiResp = await dcssaveRecalculation(order.id,recalculation);
     if(apiResp.status===type.SUCCESS) {
      setModified(false)
       setShowAlert(Constant.Success)    
       setAlertMessage(Constant.Savedsuccessfully)
     }
     if(apiResp.status===type.ERROR){
       setShowAlert(Constant.Error)    
       setAlertMessage(apiResp.response)
     }
  }
  const handleExit = () =>{
    if(modified) {
      setShowConfirmation(true);
    } else {
      setShowConfirmation(false);
      let order=orderContext.getCtxOrderDetails();
      if (order.id){
        const singOrderDetails:SingleOrderDetailType = { id: order.id, mode:'view', draft: false, tab:'calculation' };
        const newWindow=  window.open(`/#/order/create`, "_self") 
        newWindow?.sessionStorage.setItem(Constant.SingleOrdDetail, JSON.stringify(singOrderDetails));
      }
    }
  }; 

  const handleSubmit = () =>{
    if(orderData.reCalcStatus === "inProgress" || orderData.reCalcStatus === "notStarted"){
      setShowApproval(true);
    } else {
      setShowResubmitConfirmation(true);
    }
  };

  const handleModified = () => {
    setModified(true);
  }

  const enableSaveButton = (enable: boolean) => {
    setIsValid(enable);
  }

  const display = () => {
    if(orderContext.getCtxOrderDetails().vehicleDetails?.category.type) {
      if(orderContext.getCtxOrderDetails().vehicleDetails?.category.type === "NewCar") {
        return <RecalculationNewCar handleModified={handleModified} enableSaveButton={enable => enableSaveButton(enable)} />;
      } else if(orderContext.getCtxOrderDetails().vehicleDetails?.category.type === "DemoCar") {
        return <RecalculationDemoCar handleModified={handleModified} enableSaveButton={enable => enableSaveButton(enable)} />
      } else if(orderContext.getCtxOrderDetails().vehicleDetails?.category.type === "UsedCar"){
        return <RecalculationUsedCar handleModified={handleModified} enableSaveButton={enable => enableSaveButton(enable)} />;
      }
    } else {
      <></>;
    }
  }

  const handleClose = ( ) => {
    setShowAlert(Constant.None)
    setAlertMessage("")
  };

  return (
  <>
    <div className="header-fixed">
      <div className="order-header">
        <PFlex>
          <PFlexItem className="header-left"></PFlexItem>
          <PFlexItem className="header-middle" style={{ paddingTop: "40px" }}>
            <img src={require("../../../assets/images/porsche1.png")} alt="" width="125px" height="66px" />
          </PFlexItem>
          <PFlexItem className="header-right">
            {orderData?.id && (
              <PFlex alignItems={{ base: 'flex-start', l: 'flex-end' }}
                direction="column"
              >
                <PFlexItem style={{ paddingTop: "16px" }}>
                  <PorscheFont className="status-title">
                    {orderData.status === "Cancelled"
                      ? "Order Cancelled"
                      : orderData.status === "Converted"
                        ? "Order Converted"
                        : orderData.orderNumber
                          ? "Order No. " + orderData.orderNumber
                          : "Order Draft"
                    }
                  </PorscheFont>
                </PFlexItem>
                {creationDate && (
                  <PFlexItem>
                    <PorscheFont
                      className="status-subtitle" style={{ paddingLeft: "125px" }}>
                      {t("Creation date")}: {formatDate(creationDate)}
                    </PorscheFont>
                  </PFlexItem>
                )}
                {updatedOnDate && (
                  <PFlexItem>
                    <PorscheFont
                      className="status-subtitle" style={{ paddingLeft: "125px" }}>
                      &ensp;  {t("Last edited")}: {formatDate(updatedOnDate)}
                    </PorscheFont>
                  </PFlexItem>
                )}
              </PFlex>
            )
            }
          </PFlexItem>
        </PFlex>
        {/* Change starting */}
        <PFlex justifyContent="space-between" >
          <PFlexItem>
            <PFlex className='flex80 pt20'>
              <div style={{ float: "left" }}>
                <Tabs
                  textColor="primary"
                  indicatorColor="secondary"
                  // onChange={handleChange}
                  // onClick={handleShowHeader}
                  value="Recalculation"
                  sx={{
                    minHeight: '24px!important',
                    "& .MuiTabs-indicator": {
                      backgroundColor: "#D5001B",
                      height: 2,
                    },
                    "& .MuiTab-root.Mui-selected": {
                      color: "#313639",
                      textTransform: "inherit",
                      fontFamily: "Porsche Next",
                      paddingBottom: "8px"
                    }
                  }}
                >
                  <Tab
                    // disabled={!tabPanel.enabled}
                    label={t('Recalculation')}
                    value="Recalculation"
                    sx={{
                      // borderLeft: "1px solid #C8CACB ",
                      padding: "6px 12px !important",
                      minHeight: '16px!important',
                      textTransform: "inherit",
                      fontFamily: "Porsche Next",
                      fontSize: "24px",
                      fontWeight: "700"
                    }}
                  />
                </Tabs>
              </div>
            </PFlex>
          </PFlexItem>
          <PFlexItem alignSelf={'center'}>
            <PFlex justifyContent='flex-end' style={{ paddingRight: '45px',marginTop:"-9px" }}>
              <PFlexItem>
                <Stack direction="row">
                  {order && (
                    <>
                          <PButton
                            variant="tertiary" icon='close'
                            // icon={"printer" as IconName}
                            className="print-btn"
                            onClick={handleExit}
                          >
                            {t('Exit recalculation')}
                          </PButton> &ensp;&ensp;&ensp;
                          { (recalculationStatus === "inProgress" || recalculationStatus === "notStarted") && (
                            <>
                              <PButton
                                variant="primary" icon='save'
                                className="print-btn"
                                onClick={handleSave}
                                disabled= {modified && isValid ? false : true}
                                //disabled= {modified ? false : true}
                              >
                                {t('Save')}
                              </PButton> &ensp;&ensp;&ensp;
                            </>
                          )}
                          
                        {/* </div> */}
                        
                      
                      {/* Action Button  */}
                      {/* {orderData && orderData.status !== "Converted" && orderData.status !== "Cancelled" && ( */}
                        <>
                          {/* <div> */}
                            <PButton
                              variant="secondary"
                              // icon={"menuLines" as IconName}
                              className="actions-btn"
                              //onClick={() => setShowApproval(true)}
                              onClick={handleSubmit}
                              disabled= {isValid ? false : true}
                              //disabled= {modified && isValid ? false : true}
                            >
                              {t('Submit')}
                            </PButton>
                          {/* </div> */}
                        </>
                    </>
                  )}
                </Stack>
              </PFlexItem>
            </PFlex>
          </PFlexItem>
        </PFlex>
      </div>
    </div>
    {id && showApproval && (
      <PModal style={{ height: "650px" }} open={showApproval} onClose={handleApprovalClose}>
        <FirstApproval orderId={id} handleClose={handleApprovalClose} resubmit={ recalculationStatus === "notStarted" || recalculationStatus === "inProgress" ? false : true}/>
      </PModal>
    )}

    {id && showConfirmation && (
      <PModal style={{ height: "650px" }} open={showConfirmation} onClose={handleConfirmationClose} disableCloseButton={true}>
        <ConfirmationPopup orderId={id} handleClose={handleConfirmationClose}/>
      </PModal>
    )}

    {id && showResubmitConfirmation && (
      <PModal style={{ height: "650px" }} open={showResubmitConfirmation} onClose={handleResubmitConfirmationClose} disableCloseButton={true}>
        <ResubmitConfirmation orderId={id} handleResubmitApproval={() => handleResubmitApproval()} handleCancel={handleResubmitConfirmationClose} /> 
      </PModal>
    )}

    {display()}
    
    <div className='flex30 pt20'>
      <Snackbar open={openRecalc} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={2000} onClose={handleCloseRecalc}>
        <Alert severity="success" sx={{ width: '100%' }}>
        {t('Recalculation started successfully')}
        </Alert>
      </Snackbar>
    

      <CustomSnakebar alertType={showAlert} alertMessage={alertMessage} handleClose={()=>handleClose()}/>

    </div>
  </>);
}