import {FilterType} from "./FilterType";

export class PFSVaue {
    static LEASINGS: string = 'S';
    static SERVICEPLUS: string = 'SP';
    static GAP: string = 'GAP';
    static CARPOLICY: string = 'CP';
    static PORSCHECARDS: string = 'CS';

    static getValue(input: string) {
        switch (input) {
            case 'Leasing S':
                return PFSVaue.LEASINGS;
            case 'Service Plus':
                return PFSVaue.SERVICEPLUS;
            case 'GAP':
                return PFSVaue.GAP;
            case 'Car Policy':
                return PFSVaue.CARPOLICY;
            case 'Porsche card S':
                return PFSVaue.PORSCHECARDS;
            default:
                return "NA";
        }
    }
}

export function haveCommonElements<T>(fDataList: string[], value: string): boolean {
    if(fDataList.length>0){
        return fDataList.some(item => item.split(FilterType.SPLIT)[1].includes(value));
    }
    return true
}

export function filterWithLikeValue<T> (fDataList: string[], value: string) : boolean {
    if(fDataList.length>0){
        return fDataList.some(item => value.includes(item.split(FilterType.SPLIT)[1])) || fDataList.some(item => item.split(FilterType.SPLIT)[1].includes(value));
    }
    return true
}

export function checkEquleValue<T> (fDataList: string[], value: string) : boolean {
        if(fDataList.length>0){
            const checkedData: string[] = [];
            fDataList.forEach(p => {
                return checkedData.push(PFSVaue.getValue(p.split(FilterType.SPLIT)[1]));
            })
            let modelVal = value.replaceAll(" ","")?.split(",")
            if(fDataList.length===1){
                return isValueInArray(checkedData,modelVal)
            } else {
                return haveSameValues(checkedData,modelVal)
            }

    }
    return true
}

// export function checkEquleValue<T> (fDataList: string[], value: string) : boolean {
//     if(fDataList.length>0){
//         const checkedData: string[] = [];
//         fDataList.forEach(p => {
//             return checkedData.push(PFSVaue.getValue(p.split(FilterType.SPLIT)[1]));
//         })
//         let modelVal = value.replaceAll(" ","")?.split(",")
//         return haveSameValues(checkedData,modelVal)
//     }
//     return true
// }

function isValueInArray(arr1: any[], arr2: any[]): boolean {
    for (const item of arr1) {
        if (arr2.includes(item)) {
            return true;
        }
    }
    return false;
}


function haveSameValues<T>(arr1: T[], arr2: T[]): boolean {
    if (arr1.length !== arr2.length) {
        return false;
    }
    const sortedArr1 = arr1.slice().sort();
    const sortedArr2 = arr2.slice().sort();

    for (let i = 0; i < sortedArr1.length; i++) {
        if (sortedArr1[i] !== sortedArr2[i]) {
            return false;
        }
    }
    return true;
}

export function filterDate(fDataList: string[], value: string): boolean {

    if(fDataList.length>0){
        const splValue = fDataList[0].replaceAll(" ","").split(FilterType.SPLIT);
        const fdate = splValue[1].split("-")[0]
        const tdate = splValue[1].split("-")[1]
        const formattedDateRange = getDateRange(formatDDate(fdate), formatDDate(tdate))
        if(formattedDateRange.length>0){
            return formattedDateRange.some(item => item===value);
        }
        return false
    }
    return true
}

function formatFDate(date:Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${day}-${month}-${year}`;
}

function formatDDate(inputDate:string) {
    const dateParts = inputDate.split('.');
    if (dateParts.length === 3) {
        const day = dateParts[0];
        const month = dateParts[1];
        const year = dateParts[2];
        return new Date(`${year}-${month}-${day}`);
    } else {
        return new Date('1999-01-01')
    }
}

function getDateRange(startDate:Date, endDate:Date) {
    const dateRange = [];
    const currentDate = new Date(startDate);

    while (currentDate <= endDate) {
        dateRange.push(formatFDate(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
    }
    return dateRange;
}

export function removeElementFromArray(inputString:string[], elementToRemove:string) {
    return inputString.filter(inputVal => inputVal !== elementToRemove);
}



export function formatMonth(inputDate:string) {
    const dateParts = inputDate.split('-');
    const year = dateParts[0];
    const month = dateParts[1];
    const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const monthName = monthNames[parseInt(month) - 1];
    return `${monthName} ${year}`;
}

export function checkAccountMonth<T> (fDataList: string[], value: string) : boolean {

    if(fDataList.length>0){
        const dtf = fDataList[0].split(FilterType.SPLIT)[1].replaceAll(" ","").split("-")
        const formattedMonthRange = generateMonthList(dtf[0],dtf[1])

        if(formattedMonthRange.length>0){
            return formattedMonthRange.some(item => item===value);
        }
        return false
    }
    return true
}

export function generateMonthList(startDate:string, endDate:string) {
    const startMonth = new Date(startDate);
    const endMonth = new Date(endDate);

    const months = [];
    const currentDate = new Date(startMonth);

    while (currentDate <= endMonth) {
        const formattedMonth = currentDate.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
        });
        months.push(formattedMonth);
        currentDate.setMonth(currentDate.getMonth() + 1);
    }

    return months;
}