import { createContext } from 'react';

import { Order } from '../services/Order';

export const OrderContext = createContext(Order);

export const OrderContextProvider = OrderContext.Provider;

export const OrderContextConsumer = OrderContext.Consumer;

