import { CalculationDraftDTO } from '../interfaces/Calculation';
import { UpsaleDetailsDTO, UpsaleItemDTO } from '../interfaces/UpsaleNew';

export const getAmountNet = (item: UpsaleItemDTO, service: boolean, i: number) => {
    if (service) {
      if (item.serviceItems && item.serviceItems.length > 0 ) {
        if (item.serviceItems[i].customerShare > 0) {
          const ret = item.serviceItems[i].services.priceBeforeCustomerNet ? item.serviceItems[i].services.priceBeforeCustomerNet : 0.00;
          if (item.partType === "part") {
            if (item.serviceItems[i].timeUnits !== undefined) {
              return ret * item?.serviceItems?.[i].timeUnits!;
            } else {
              return 0.00;
            }
          } else {
            return ret;
          }
        } else {
          const ret1 = item.serviceItems[i].services.priceInternal ? item.serviceItems[i].services.priceInternal : 0.00;
          if (item.partType === "part") {
            if (item.serviceItems[i].timeUnits !== undefined) {
              return ret1 * item?.serviceItems?.[i].timeUnits!;
            } else {
              return 0.00;
            }
          } else {
            return ret1;
          }
        }
      } else {
        return 0.00;
      }
    } else {
      if(item.customerShare > 0) {
        return item.part.priceBeforeCustomerNet ? item.part.priceBeforeCustomerNet : 0.00;
      } else {
        return item.part.priceInternal ? item.part.priceInternal : 0.00;
      }
    }
}

export const getPercent = (item: UpsaleItemDTO, service: boolean, i: number, category: string, calDraft: CalculationDraftDTO) => {
    let disPercent = 0.00;
    let dis = 0.00
    if (service) {
      const net = getAmountNet(item, service, i);
      const dis = net - item.serviceItems[i].customerShare;
      // if (dis > 0 ) {
      //   disPercent =  (dis / net) * 100;
      // }
    } else {
      const net = getAmountNet(item, service, i);
      const dis = net - item.customerShare;
      // if (net > 0) {
      //   disPercent =   (dis / net) * 100;
      // }
    }

    switch(category) {
      case "NewCar":
        if(calDraft.vehiclePrices && calDraft.vehiclePrices[2].amountNet > 0) {
          disPercent = dis / calDraft.vehiclePrices[2].amountNet * 100;
        }
        break;
      case "DemoCar":
      case "UsedCar":
        if(calDraft.vehiclePrices && calDraft.vehiclePrices[0].amountNet > 0) {
          disPercent = dis / calDraft.vehiclePrices[0].amountNet * 100;
        }
        break;
    }

    return disPercent;
  }

export const getPartTotals = (upsale: UpsaleDetailsDTO) => {
    let usDetails = upsale;
    const partsTotals = {
      amountNet: 0.00,
      customerShare: 0.00,
      discountEuro: 0.00,
      discountPercent: 0.00,
      serviceNet: 0.00,
      serviceShare: 0.00,
      serviceEuro: 0.00,
      servicePercent: 0.00,
    };
    let partsData = usDetails.upsaleItems.filter(x => x.partType === "part");
    partsData.map((item, i) => {
      partsTotals.customerShare = item.customerShare ? partsTotals.customerShare + item.customerShare : partsTotals.customerShare + 0.00;
      
      if (item.customerShare > 0) {
        partsTotals.amountNet = item.part.priceBeforeCustomerNet ? partsTotals.amountNet + item.part.priceBeforeCustomerNet : partsTotals.amountNet + 0.00;
      } else {
        partsTotals.amountNet = item.part.priceInternal ? partsTotals.amountNet + item.part.priceInternal : partsTotals.amountNet + 0.00;
      }
      
      if (item.serviceItems && item.serviceItems.length > 0) {
        partsTotals.serviceShare = + item.serviceItems[0].customerShare;
        if (item.serviceItems[0].customerShare > 0 && item.serviceItems[0].timeUnits) {
          partsTotals.serviceNet = + item.serviceItems[0].services.priceBeforeCustomerNet * item.serviceItems[0].timeUnits;
        } else if (item.serviceItems[0].timeUnits) {
          partsTotals.serviceNet = + item.serviceItems[0].services.priceInternal * item.serviceItems[0].timeUnits;
        }
      }
    })

    // if (parts && parts.length > 0) {
    //   if (partsTotals.amountNet > 0) {
    //     partsTotals.discountEuro = partsTotals.amountNet - partsTotals.customerShare;
    //     //partsTotals.discountPercent = partsTotals.discountEuro / partsTotals.amountNet * 100;
    //     switch(category) {
    //       case "NewCar":
    //         if(calDraft.vehiclePrices && calDraft.vehiclePrices[2].amountNet > 0){
    //           partsTotals.discountPercent = partsTotals.discountEuro / calDraft.vehiclePrices[2].amountNet * 100;
    //         } else {
    //           partsTotals.discountPercent = 0.00
    //         }
    //         break;
    //       case "DemoCar":
    //       case "UsedCar":
    //         if(calDraft.vehiclePrices && calDraft.vehiclePrices[0].amountNet > 0){
    //           partsTotals.discountPercent = partsTotals.discountEuro / calDraft.vehiclePrices[0].amountNet * 100;
    //         } else {
    //           partsTotals.discountPercent = 0.00
    //         }
    //         break;
    //     }
    //   }
      
    //   if(partsTotals.serviceNet > 0) {
    //     partsTotals.serviceEuro = partsTotals.serviceNet - partsTotals.serviceShare;
    //     //partsTotals.servicePercent = partsTotals.serviceEuro / partsTotals.serviceNet * 100;
    //   }
    // }

    return partsTotals;
  }