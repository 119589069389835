import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  PButton,
  PButtonGroup,
  PDivider, PFlex, PFlexItem, PGrid,
  PGridItem,
  PModal,
  PTextFieldWrapper
} from '@porsche-design-system/components-react';

import { PorscheFont } from '../../assets/styles/CustomStyles';
import { RecipientNotificationResponseDTO } from '../../interfaces/TasksAndNotifications';
import { dcsdeleteNotifications, deleteNotifications } from '../../services/data/Helpers';
import { SingleOrderDetailType } from '../../interfaces/OrderDetails';
import { Constant } from '../../configs/Constants';
import { type } from '../../services/Constants';
import CustomSnakebar from '../common/CustomSnakebar';

interface Props {
  notificationDetails: RecipientNotificationResponseDTO;
  handleClose:()=>void;
}
export const NotificationPopup = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const { notificationDetails, handleClose} = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [toggle, setToggle] = useState<boolean>(false);
  const [togglebtn, setTogglebtn] = useState<boolean>(false);
  const [toggleComment, setToggleComment] = useState<boolean>(false);
  const [toggleReassign, setToggleReassign] = useState<boolean>(false);
  const [deletePopup, setDeletePopup] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState(Constant.None);
  const [alertMessage, setAlertMessage] = useState(Constant.Savedsuccessfully)


  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  const handleActions = () => {
    setToggle(!toggle);
  };
  const handleBtnActions = () => {
    setToggle(togglebtn);
  };
  const handleModalComplete = () => {
    // setIsModalOpen(false);
    setToggleComment(!toggleComment);
  };
  const handleModalReassign = () => {
    // setIsModalOpen(false);
    setToggleReassign(!toggleReassign);
  };

  const deleteNotification = () => {};

  const handlePage = (orderId: number, isDraft: boolean) => {
    if (orderId) {
      const draftValue = isDraft? true:false
      const singOrderDetails:SingleOrderDetailType = { id: orderId, mode:'view', draft: draftValue };
      const newWindow=  window.open(`/#/order/create`, "_blank") 
      newWindow?.sessionStorage.setItem(Constant.SingleOrdDetail, JSON.stringify(singOrderDetails));
    }
  };

  const handleDeleteNo = () => {
    setDeletePopup(false)
  }

  const handleDeleteYes = async () => {
    try {
      if (notificationDetails.notification) {
        const apiResponse =  await dcsdeleteNotifications(notificationDetails.notification.id)
        if(apiResponse.status === type.SUCCESS){
          setTimeout(()=>{window.location.reload()}, 500)
        }

        if(apiResponse.status===type.ERROR){
          setShowAlert(Constant.Error)
          setAlertMessage(apiResponse.response)  
        }
      }
    } catch (e) {
    }
  }

  return (
    <>
      <div className="task-layout">
      <PorscheFont className="main-header-text">{t('Notification details')}</PorscheFont>
        <br></br>
        <PDivider style={{ marginTop: "-35x", marginBottom: "10px" }} />
        <br></br>
        <div style={{ width: "100%", backgroundColor: "#F5F5F5" }}>
          <PGrid>
            <PGridItem size={7}>
              <PFlex style={{ marginTop: "15px" }}>
                &nbsp;&nbsp;&nbsp;
                <PFlexItem className="task-font-h ">
                  {" "}
                  {t('Sent on')}{" "}
                  <span className="dcs-heading">
                    {/* {notification.sentOn} */}
                    {notificationDetails.notification?.sentOn.substring(0, 10).replaceAll("-", ".")}
                  </span>{" "}
                </PFlexItem>
                &nbsp;&nbsp;
                {/* <PDivider orientation="vertical" />
                &nbsp;&nbsp; */}
                {/* <PFlexItem className="task-font-h "> */}
                  {/* {" "} */}
                  {/* Sent by{" "} */}
                  {/* <span className="dcs-heading"> */}
                    {/* {notification.sentBy.ppnName} */}
                    {/* {notificationDetails.notification?.sentOn} */}
                  {/* </span>{" "} */}
                {/* </PFlexItem> */}
                &nbsp;&nbsp;
                <PDivider orientation="vertical" />
                &nbsp;&nbsp;
                <PFlexItem className="task-font-h ">
                  {" "}
                  {t('Sent by')}{" "}
                  <span className="dcs-heading">
                    {notificationDetails.notification?.sentBy?.name!}
                  </span>{" "}
                </PFlexItem>
                &nbsp;&nbsp;
                {/* <PDivider orientation="vertical" />&nbsp;&nbsp; */}
              </PFlex>
              <br></br>
            </PGridItem>
          </PGrid>
        </div>
        <br></br>
        <PorscheFont>{t('Subject')}</PorscheFont>
        <PGrid>
          <PGridItem size={8}>
            <PTextFieldWrapper>
              <input
                type="text"
                name="some-name"
                // value={notification.subject}
                value={t(notificationDetails.notification && notificationDetails.notification?.subject || "") as string}
                readOnly
              />
            </PTextFieldWrapper>
          </PGridItem>
        </PGrid>
        <br />
        <PorscheFont>{t('Description')}</PorscheFont>
        <PGrid>
          <PGridItem size={10}>
            <div
              className="read"
              style={{
                height: "100px",
                color: "gray",
                textAlign: "left",
                lineHeight: "25px",
                padding:"0.68rem"
              }}
            >
              {/* {notification.description} */}
              {t(notificationDetails.notification && notificationDetails.notification.description || "")}

            </div>
          </PGridItem>
          <PGridItem
            size={6}
            style={{ marginTop: "200px" }}
            onClick={deleteNotification}
          >
            <div>
              <PButton icon="delete" variant="tertiary" onClick={() => setDeletePopup(!deletePopup)}>
                {t("Delete item from list")}
              </PButton>
            </div>
          </PGridItem>
          <PGridItem offset={10} size={4} style={{ marginTop: "-40px" }}>
            <div>
              {/* <PButton
                onClick={() =>
                  handlePage(notification.order.id, notification.order.isDraft)
                }
              >
                Open order
              </PButton> */}
            <PButton
                onClick={() =>
                  notificationDetails.notification?.order 
                  ? handlePage(notificationDetails.notification.order.id, notificationDetails.notification.order.isDraft)
                  : {}
                }
              >
                {t("Open order")}
              </PButton>
           </div>
          </PGridItem>
        </PGrid>
      </div>
      <PModal
          className="overview-modal"
          open={deletePopup}
          onClose={handleDeleteNo}
        > 
          <PFlex>
            <PFlexItem style={{ marginRight: "4px", marginTop: "16px" }}>
              <PorscheFont className="dcs-heading">
                {t("Are you sure you want to delete this notification from your dashboard?")}
              </PorscheFont>
            </PFlexItem>
          </PFlex>
          <PFlex dir="rtl" style={{ marginTop: "14px" }}>
            <PFlexItem>
              <PButtonGroup>
                <PButton 
                  icon={"arrow-head-right"} 
                  onClick={handleDeleteYes}
                >
                  {t("Yes, delete notification")}
                </PButton>
                <PButton
                  variant="tertiary"
                  icon={"close"}
                  onClick={handleDeleteNo}
                >
                  <PorscheFont>
                    {t('Cancel')}
                  </PorscheFont>
                </PButton>
              </PButtonGroup>
            </PFlexItem>
          </PFlex>
        </PModal>
        <CustomSnakebar alertType={showAlert} alertMessage={alertMessage} handleClose={()=>handleClose()}/>
    </>
  );
};
